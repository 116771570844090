import { LOCALES } from "../locales";

export default {
  [LOCALES.ARABIC]: {
    hello: "مرحبًا",
    ["Size is compulsory"]:"الحجم إلزامي",
    ["Cost is compulsory"]:"التكلفة إجبارية",
    ["Kindly select the color"]:"يرجى اختيار اللون",
    ["Kindly select the clarity"]:"يرجى تحديد الوضوح",
    ["Kindly select the cut grade"]:"يرجى تحديد درجة القطع",
    ["Kindly select the shape"]:"يرجى تحديد الشكل",
    ["Please select video file only."]:"الرجاء تحديد ملف الفيديو فقط.",
    ["Add Diamond"]:"أضف الماس",
    ["Inventory #"]:"جرد #",
    ["Matched Pair Stock #"]:"مخزون الزوج المتطابق #",
    ["Stones (Parcel)"]:"الأحجار (قطعة)",
    ["Separable"]:"قابل للانفصال",
    ["Size"]:"مقاس",
    ["Cost"]:"يكلف",
    ["Carat"]:"قيراط",
    ["Shape"]:"شكل",
    ["Color"]:"لون",
    ["Fancy Codes"]:"رموز خيالية",
    ["Fancy Names"]:"أسماء خيالية",
    ["Other"]:"آخر",
    ["Clarity"]:"وضوح",
    ["Cut Grade"]:"قطع الصف",
    ["Certificates"]:"الشهادات",
    ["Certificate #"]:"شهادة #",
    ["Depth"]:"عمق",
    ["Table"]:"طاولة",
    ["Measurements"]:"قياسات",
    ["Polish"]:"تلميع",
    ["Symmetry"]:"تناظر",
    ["Fluorescence"]:"ضوئي",
    ["Girdle"]:"حزام",
    ["Culet"]:"كوليت",
    ["Culet Condition"]:"كوليت الشرط",
    ["Crown Angle"]:"زاوية التاج",
    ["Pavillion Angle"]:"زاوية الجناح",
    ["Comments"]:"تعليقات",
    ["Do not include pricing informatin in comments."]:"لا تقم بتضمين معلومات التسعير في التعليقات.",
    ["Store Location"]:"موقع المتجر",
    ["Employee Name"]:"اسم الموظف",
    ["Employee ID"]:"هوية الموظف",
    ["Out On Memo"]:"الخروج في مذكرة",
    ["Seen by Other Dealers"]:"يراها تجار آخرون",
    ["Add Video"]:"أضف فيديو",
    ["Video Url"]:"رابط الفيديو",
    ["Choose Video"]:"اختر فيديو",
    ["No file chosen"]:"لم تقم باختيار ملف",
    ["Is Lab Created"]:"تم إنشاء المعمل",
    ["Create"]:"يخلق",
    ["Cancel"]:"يلغي",

    ["BrandID"]:"BrandID",
    ["BrandName"]:"اسم العلامة التجارية",
    ["Link"]:"وصلة",
    ["Action"]:"فعل",
    ["Designer name is compulsory"]:"اسم المصمم إلزامي",
    ["Email is compulsory"]:"البريد الإلكتروني إلزامي",
    ["Manage Brand"]:"إدارة العلامة التجارية",
    ["Designer"]:"مصمم",
    ["Create Brand"]:"إنشاء علامة تجارية",
    ["If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor)."]:
    "إذا كانت شركتك تبيع منتجها تحت اسم علامة تجارية للمستهلك ، فاستخدم هذه الواجهة لتكوين معلومات العلامة التجارية. سيتم إدراج أسماء العلامات التجارية على مواقع الويب الخاصة ببائعي التجزئة في فئة التنقل (ما لم يتم تقييدها من قبل البائع).",
    ["Update Brands"]:"تحديث الماركات",
    ["Insert Brands"]:"إدراج العلامات التجارية",
    ["Submit"]:"يُقدِّم",
    ["Designer Name"]:"اسم المصمم",
    ["Designer Logo"]:"شعار المصمم",
    ["Choose File"]:"اختر ملف",
    ["No File Choosen"]:"لم تقم باختيار ملف",
    ["Use Vendor Profile Logo"]:"استخدم شعار ملف تعريف البائع",
    ["Navigation Category"]:"فئة التنقل",
    ["Fashion Designer"]:"مصمم أزياء",
    ["Watch Designer"]:"ساعة مصمم",
    ["Bridal Designer"]:"مصمم الزفاف",
    ["Showcase Image 1"]:"عرض الصورة 1",
    ["Use In Showcase"]:"استخدام في الواجهة",
    ["Showcase Image 2"]:"عرض الصورة 2",
    ["Showcase Image 3"]:"عرض الصورة 3",
    ["Designer Small Banner"]:"مصمم بانر صغير",
    ["Video URL"]:"رابط الفيديو",
    ["How To Upload Video"]:"كيفية تحميل الفيديو",
    ["URL"]:"URL",
    ["Email"]:"بريد إلكتروني",
    ["About Designer"]:"حول المصمم",
    ["Tag Line"]:"خط العلامة",
    ["You can enter up to 100 characters."]:"يمكنك إدخال ما يصل إلى 100 حرف.",
    ["Sample Showcase Preview"]:"معاينة نموذج العرض",
    ["Delete"]:"يمسح",
    ["Delete The "]:"احذف ملف",
    ["Brand"]:"ماركة",
    ["THIS CANNOT BE UNDONE"]:"هذا لا يمكن التراجع عنها",

    ["Please select image."]:"الرجاء تحديد الصورة.",
    ["Please select Image File Only"]:"الرجاء تحديد ملف الصورة فقط",
    ["Company name is compulsory"]:"اسم الشركة إلزامي",
    ["Email address is compulsory"]:"عنوان البريد الإلكتروني إلزامي",
    ["Email address is invalid"]:"عنوان البريد الإلكتروني غير صالح",
    ["Phone number is compulsory"]:"رقم الهاتف إلزامي",
    ["Phone number is invalid"]:"رقم الهاتف غير صالح",
    ["Image Name"]:"اسم الصورة",
    ["Display Order"]:"ترتيب العرض",
    ["Update"]:"تحديث",
    ["Upload"]:"رفع",
    ["Company Logo"]:"شعار الشركة",
    ["Pixels"]:"بكسل",
    ["Edit"]:"يحرر",
    ["Advertising Samples"]:"عينات الدعاية",
    ["Company Name"]:"اسم الشركة",
    ["Primary Contact Name"]:"اسم جهة الاتصال الأساسية",
    ["Primary Phone"]:"الهاتف الرئيسي",
    ["Web Site"]:"موقع إلكتروني",
    ["Primary E-mail"]:"البريد الإلكتروني الرئيسي",
    ["Since"]:"منذ",
    ["Select Language"]:"اختار اللغة",
    ["Select Currency"]:"اختر العملة",
    ["Display Video"]:"عرض الفيديو",
    ["Display Image"]:"هذه الصورة ليست للعرض",
    ["Company Bio"]:"شركة بيو",
    ["Use this Company Name,  and bio as my Brand name,  and Bio on my authorized Retailer websites."]:
    "استخدم اسم الشركة والسيرة الذاتية كاسم علامتي التجارية والسيرة الذاتية على مواقع ويب بائعي التجزئة المعتمدين.",
    ["Click here to configure your different Brand marketing message for use on your authorized Retailer websites."]:
    "انقر هنا لتهيئة رسالة تسويق العلامة التجارية المختلفة الخاصة بك لاستخدامها على مواقع ويب بائعي التجزئة المعتمدين.",
    ["Update Profile"]:"تحديث الملف",
    ["Upload Images"]:"تحميل الصور",
    ["Select File"]:"حدد ملف",
    ["No File Chosen"]:"لم تقم باختيار ملف",
    ["Select Files"]:"اختر الملفات",
    ["Choose Image"]:"اختر صورة",
    ["Are you sure you want to delete"]:"هل أنت متأكد أنك تريد حذف",
    ["THIS CAN NOT BE UNDONE"]:"هذا لا يمكن التراجع عنها",
  }
}