import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Space, Select, Button, Switch, Modal, Table, Spin } from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import adminToolsService from "../../../services/admin-tools.service";
import traslate from "../../../i18n/translate";

const initialConsoleUploadData = {
  dealerID: "",
  fTPUserName: "",
  fTPPassword: "",
  fileTypeId: "",
  addUpdate: true,
  path: "",
};
const initialValidations = {
  dealerIDVal: "",
  fTPUserNameVal: "",
  fTPPasswordVal: "",
  fileTypeVal: "",
  pathVal: "",
};
const initialFtpData = {
  userName: "",
  password: "",
  folder: "",
};
const initialEditConsoleUploadData = {
  consoleUploadID: "",
  company: "",
  dealerID: "",
  userName: "",
  password: "",
  fileTypeId: "",
  addUpdate: true,
  path: "",
  oldFileType: "",
};
const initialFtpValidations = {
  userNameVal: "",
  passwordVal: "",
  folderVal: "",
};
const initialEditvalidations = {
  editfTPUserNameVal: "",
  editfTPPasswordVal: "",
  editfileTypeVal: "",
  editpathVal: "",
};

const ConsoleUpload = () => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [getloading, setGetLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [consoleUploadData, setConsoleUploadData] = useState(
    initialConsoleUploadData
  );
  const [validations, setValidations] = useState(initialValidations);
  const [ftpData, setFtpData] = useState(initialFtpData);
  const [ftpValidations, setFtpValidations] = useState(initialFtpValidations);
  const [editConsoleUploadData, setEditConsoleUploadData] = useState(
    initialEditConsoleUploadData
  );

  const [editvalidations, setEditValidations] = useState(
    initialEditvalidations
  );
  const [delConsoleData, setDelConsoleData] = useState({
    id: "",
    dealerID: "",
  });
  const { Option } = Select;

  const [dealerList, setdealerList] = useState([]);
  const [fileTypeData, setFileTypeData] = useState([]);
  const [getconsoleUploadData, setGetConsoleUploadData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);
  const [DealerID, setDealerID] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [sortType, setSortType] = useState("asc");
  const [sortColumnName, setSortColumnName] = useState("company");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerID(loginDetials.loginDetials.responseData.dealerId);
          handleGetConsoleUploadList(
            pageNo,
            pageSize,
            "",
            sortColumnName,
            sortType
          );
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    setConsoleUploadData(initialConsoleUploadData);
    setValidations(initialValidations);
  };

  const handleCancel1 = () => {
    setVisible1(false);
    setshowPassword(false);
    setFtpData(initialFtpData);
    setFtpValidations(initialFtpValidations);
  };

  const handleCancel2 = () => {
    setVisible2(false);
    setEditValidations(initialEditvalidations);
    setEditConsoleUploadData(initialEditConsoleUploadData);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const handleGetConsoleUploadList = (
    pageNo,
    pageSize,
    searchText,
    sortColumnName,
    sortOrder
  ) => {
    let inputData = {
      pageNumber: pageNo,
      pageSize: pageSize,
      search: searchText,
      ordercol: sortColumnName,
      orderby: sortOrder,
    };
    setGetLoading(true);
    AdminToolsDataService.GetConsoleUploadList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            var dealerData = responseData[0];
            var fileTypeData = responseData[1];
            var consoleUploadData = responseData[2];
            setdealerList(dealerData);
            setFileTypeData(fileTypeData);
            setGetConsoleUploadData(
              consoleUploadData[0].consoleUploadResponses
            );
            setTotalCount(consoleUploadData[0].totalRecord);
          }
        } else {
          setGetConsoleUploadData([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setGetLoading(false);
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search `}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleReset(clearFilters, confirm);
              }}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => { },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    handleGetConsoleUploadList(1, 10, selectedKeys[0]);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    handleGetConsoleUploadList(1, 10, "");
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setConsoleUploadData({
        ...consoleUploadData,
        [name]: e,
      });
      if (name === "dealerID") {
        setValidations((prevdata) => ({
          ...prevdata,
          dealerIDVal: "",
        }));
      }
      if (name === "fileTypeId") {
        setValidations((prevdata) => ({
          ...prevdata,
          fileTypeVal: "",
        }));
      }
    } else {
      setConsoleUploadData({
        ...consoleUploadData,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "fTPUserName") {
        setValidations((prevdata) => ({
          ...prevdata,
          fTPUserNameVal: "",
        }));
      }
      if (e.target.name === "fTPPassword") {
        setValidations((prevdata) => ({
          ...prevdata,
          fTPPasswordVal: "",
        }));
      }

      if (e.target.name === "path") {
        setValidations((prevdata) => ({
          ...prevdata,
          pathVal: "",
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    const { dealerID, fTPUserName, fTPPassword, fileTypeId, addUpdate, path } =
      consoleUploadData;
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    let inputData = {
      id: 0,
      dealerID: dealerID.toString(),
      userName: fTPUserName,
      password: fTPPassword,
      fileTypeId: fileTypeId,
      addUpdate: addUpdate,
      path: path,
      loginUserId: "",
      loginUserName: "",
      ipAddress: "",
    };

    AdminToolsDataService.UpdateConsoleUpload(inputData)
      .then((response) => {
        var message = response.data.message;
        var ShowMessage = response.data.responseData.returnMessage;
        if (message === "Success") {
          if (ShowMessage == "RECORD ALREADY EXIST") {
            NotificationManager.error("RECORD ALREADY EXIST");
          } else {
            NotificationManager.success("Data Added Successfully.");
            handleGetConsoleUploadList(pageNo, pageSize, "");
          }
        } else {
          NotificationManager.error("Data Not Added.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleCancel();
  };

  const handleValidation = () => {
    const { dealerID, fTPUserName, fTPPassword, fileType, path } =
      consoleUploadData;
    const validations = {
      dealerIDVal: "",
      fTPUserNameVal: "",
      fTPPasswordVal: "",
      fileTypeVal: "",
      pathVal: "",
    };
    let isValid = true;

    if (!dealerID) {
      isValid = false;
      validations.dealerIDVal = traslate("Kindly select Dealer ID");
    }
    if (!fTPUserName.trim()) {
      isValid = false;
      validations.fTPUserNameVal = traslate("FTP Username is compulsory");
    }
    if (!fTPPassword.trim()) {
      isValid = false;
      validations.fTPPasswordVal = traslate("FTP Password is compulsory");
    } else {
      validations.fTPPasswordVal = "";
    }
    if (!fileTypeId) {
      isValid = false;
      validations.fileTypeVal = traslate("Kindly select File Type");
    }
    if (!path) {
      isValid = false;
      validations.pathVal = traslate("Path is Compulsory");
    }
    if (!isValid) {
      setValidations(validations);
    }
    return isValid;
  };

  const handleEditValidation = () => {
    const { userName, password, fileTypeId, path } = editConsoleUploadData;
    const editvalidations = {
      editfTPUserNameVal: "",
      editfTPPasswordVal: "",
      editfileTypeVal: "",
      editpathVal: "",
    };
    let isValid = true;
    if (!userName.trim()) {
      isValid = false;
      editvalidations.editfTPUserNameVal = traslate(
        "FTP Username is compulsory"
      );
    }
    if (!password.trim()) {
      isValid = false;
      editvalidations.editfTPPasswordVal = traslate(
        "FTP Password is compulsory"
      );
    }
    if (!fileTypeId) {
      isValid = false;
      editvalidations.editfileTypeVal = traslate("Kindly select File Type");
    }
    if (!path) {
      isValid = false;
      editvalidations.editpathVal = traslate("Path is Compulsory");
    }
    if (!isValid) {
      setEditValidations(editvalidations);
    }

    return isValid;
  };

  const edithandleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditConsoleUploadData({
        ...editConsoleUploadData,
        [name]: e,
      });
    } else {
      setEditConsoleUploadData({
        ...editConsoleUploadData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdate = () => {
    const {
      consoleUploadID,
      dealerID,
      userName,
      password,
      fileTypeId,
      addUpdate,
      path,
    } = editConsoleUploadData;
    const isValid = handleEditValidation();
    if (!isValid) {
      return false;
    }
    let inputData = {
      id: consoleUploadID,
      dealerID: dealerID.toString(),
      userName: userName,
      password: password,
      fileTypeId: fileTypeId,
      addUpdate: addUpdate,
      path: path,
      loginUserId: "",
      loginUserName: "",
      ipAddress: "",
    };

    AdminToolsDataService.UpdateConsoleUpload(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Data Updated Successfully.");
          handleGetConsoleUploadList(pageNo, pageSize, "");
        } else {
          NotificationManager.error("Data Not Updated.");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleCancel2();
  };

  const DeleteConsoleUploadData = () => {
    let inputData = {
      id: parseInt(delConsoleData.id),
      dealerID: parseInt(delConsoleData.dealerID),
    };

    adminToolsService
      .DeleteConsoleUpload(inputData)
      .then((response) => {
        var message = response.data.message;

        if (message === "Success") {
          NotificationManager.success("Data Deleted Successfully.");
          handleGetConsoleUploadList(pageNo, pageSize, "");
        } else {
          NotificationManager.error("Data Not Deleted.");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setVisible3(false);
    setDelConsoleData({});
  };

  const handleFtpOnchange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setFtpData({
        ...ftpData,
        [name]: e,
      });
    } else {
      setFtpData({
        ...ftpData,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "userName") {
        setFtpValidations((prevdata) => ({
          ...prevdata,
          userNameVal: "",
        }));
      }
      if (e.target.name === "password") {
        setFtpValidations((prevdata) => ({
          ...prevdata,
          passwordVal: "",
        }));
      }
    }
  };

  const handleFtpValitdation = () => {
    const { userName, password } = ftpData;
    const ftpValidations = {
      userNameVal: "",
      passwordVal: "",
    };
    let isValid = true;

    if (!userName.trim()) {
      isValid = false;
      ftpValidations.userNameVal = traslate("FTP Username is compulsory");
    }
    if (!password.trim()) {
      isValid = false;
      ftpValidations.passwordVal = traslate("Password is compulsory");
    } else {
      ftpValidations.passwordVal = "";
    }
    if (!isValid) {
      setFtpValidations(ftpValidations);
    }
    return isValid;
  };

  const handleFtpSumbit = (e) => {
    e.preventDefault();
    const isValid = handleFtpValitdation();
    if (!isValid) {
      return false;
    }
    let inputData = {
      dealerId: DealerID.toString(),
      userName: ftpData.userName,
      password: ftpData.password,
      folderName: ftpData.folder,
    };

    AdminToolsDataService.CreateFTP(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("FTP Created Successfully.");
        } else {
          NotificationManager.success("FTP Not Created.");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleCancel1();
  };

  const handleEditModal = (row) => {
    setVisible2(true);
    setEditConsoleUploadData({
      consoleUploadID: row.id,
      dealerID: row.dealerID,
      company: row.company,
      userName: row.userName,
      password: row.password,
      fileTypeId: row.fileTypeId,
      addUpdate: row.addUpdate,
      path: row.path,
    });
  };

  const handleDeleteModal = (row) => {
    setVisible3(true);
    setDelConsoleData({
      id: row.id,
      dealerID: row.dealerID,
    });
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetConsoleUploadList(page, pageSize, "");
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetConsoleUploadList(current, size, "");
  }

  const handlePassShowHide = () => {
    setshowPassword(!showPassword);
  };
  const { dealerID, fTPUserName, fTPPassword, fileTypeId, addUpdate, path } =
    consoleUploadData;

  const { dealerIDVal, fTPUserNameVal, fTPPasswordVal, fileTypeVal, pathVal } =
    validations;

  const { userName, password, folder } = ftpData;
  const { userNameVal, passwordVal } = ftpValidations;

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field == "dealerIDCompany" ? "company" : sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field == "dealerIDCompany" ? "company" : sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "company";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetConsoleUploadList(
      pagination.current,
      pagination.pageSize,
      "",
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="consupld__section magento__mapping">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading__block mb-0">
                <h4 className="workarea__heading mt-0 mb-0">
                  {traslate("Console Upload")}
                </h4>
                <div className="addcons__btndiv">
                  <button
                    className="primary-btn mobile__bottomspace"
                    onClick={() => setVisible(true)}
                  >
                    {" "}
                    <img src={Create_icon} alt="" />
                    {traslate("Add Console Upload Dealer Data")}
                  </button>
                  <button
                    className="primary-btn"
                    onClick={() => setVisible1(true)}
                  >
                    {" "}
                    <img src={Create_icon} alt="" />
                    {traslate("Create FTP")}{" "}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="consupld_tbldiv">
                <Spin spinning={getloading} size="large">
                  <Table
                    columns={[
                      {
                        title: traslate("Dealer"),
                        ...getColumnSearchProps("dealerIDCompany"),
                        dataIndex: "dealerIDCompany",
                        key: "dealerIDCompany",
                        width: 250,
                        sorter: (a, b) => a.dealerID - b.dealerID,
                      },
                      {
                        title: traslate("FTP User Name"),
                        dataIndex: "userName",
                        key: "userName",
                        width: 150,
                        sorter: (a, b) => a.userName.localeCompare(b.userName),
                      },
                      {
                        title: traslate("FTP Password"),
                        dataIndex: "password",
                        key: "password",
                        width: 100,
                        sorter: (a, b) => a.password.localeCompare(b.password),
                      },
                      {
                        title: traslate("File Type"),
                        dataIndex: "fileType",
                        key: "fileType",
                        sorter: (a, b) => a.fileType.localeCompare(b.fileType),
                        width: 120,
                      },
                      {
                        title: traslate("Path"),
                        dataIndex: "path",
                        key: "path",
                        width: 200,
                        sorter: (a, b) => a.path.localeCompare(b.path),
                      },
                      {
                        title: traslate("Add Update"),
                        dataIndex: "addUpdate",
                        key: "addUpdate",

                        sorter: (a, b) => a.addUpdate - b.addUpdate,
                        width: 100,
                        render: (text, record) => {
                          return (
                            <span>
                              {record.addUpdate.toString() == "false"
                                ? "False"
                                : record.addUpdate.toString() == "true"
                                  ? "True"
                                  : record.addUpdate.toString()}
                            </span>
                          );
                        },
                      },

                      {
                        title: traslate("Action"),
                        key: "7",
                        fixed: "right",
                        width: 100,
                        render: (index, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => {
                                  handleEditModal(row);
                                }}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    dataSource={getconsoleUploadData}
                    onChange={handleTableASCDES}
                    pagination={{
                      total: totalCount,
                      pageNo: pageNo,
                      pageSize: pageSize,
                      pageSizeOptions: [10, 20, 50, 100],
                      responsive: true,
                      showSizeChanger: true,
                      onChange: handlePageNoChange,
                      onShowSizeChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 600, y: 800 }}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={traslate("Console Upload Info")}
                className="modalconsupld__section"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={handleCancel}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleSubmit}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Dealer ID")} <span>*</span>
                              </label>
                              <Select
                                name="dealerID"
                                value={dealerID ? dealerID : null}
                                showSearch
                                placeholder=" Select Dealer ID"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleOnChange(e, "select", "dealerID")
                                }
                                className={
                                  dealerIDVal ? "border__red" : "border__grey"
                                }
                              >
                                <Option value="">
                                  {" "}
                                  {traslate("Select Dealer ID")}{" "}
                                </Option>
                                {dealerList.map((item) => {
                                  return (
                                    <Option
                                      key={item.dealerID}
                                      value={item.dealerID}
                                    >
                                      {item.dealerCompany +
                                        "(" +
                                        item.dealerID +
                                        ")"}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {dealerIDVal && (
                                  <p className="error-color-red">
                                    {dealerIDVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTP User Name")} <span>*</span>
                              </label>
                              <input
                                name="fTPUserName"
                                value={fTPUserName}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter User Name"
                                className={fTPUserNameVal && "border__red"}
                              />
                              <div>
                                {fTPUserNameVal && (
                                  <p className="error-color-red">
                                    {fTPUserNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTP Password")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Password"
                                name="fTPPassword"
                                value={fTPPassword}
                                onChange={handleOnChange}
                                className={fTPPasswordVal && "border__red"}
                              />

                              <div>
                                {fTPPasswordVal && (
                                  <p className="error-color-red">
                                    {fTPPasswordVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("File Type")} <span>*</span>
                              </label>
                              <Select
                                name="fileTypeId"
                                value={fileTypeId ? fileTypeId : null}
                                placeholder=" Select File Type"
                                onChange={(e) =>
                                  handleOnChange(e, "select", "fileTypeId")
                                }
                                className={
                                  fileTypeVal ? "border__red" : "border__grey"
                                }
                              >
                                <Option value="">
                                  {traslate("Select File Type")}
                                </Option>
                                {fileTypeData.map((item) => {
                                  return (
                                    <Option
                                      key={item.fileTypeId}
                                      value={item.fileTypeId}
                                    >
                                      {item.fileType}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {fileTypeVal && (
                                  <p className="error-color-red">
                                    {fileTypeVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Path")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Path"
                                name="path"
                                value={path}
                                onChange={handleOnChange}
                                className={pathVal && "border__red"}
                              />
                              <div>
                                {pathVal && (
                                  <p className="error-color-red">{pathVal}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>{traslate("Add Update")} </label>
                              <Switch
                                name="addUpdate"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={addUpdate}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "addUpdate")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title={traslate("Console Upload Info")}
                className="modalconsupld__section"
                centered
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={() => setVisible2(false)}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel2}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleUpdate}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 mb-4">
                            <h4>
                              {editConsoleUploadData.company} -{" "}
                              {editConsoleUploadData.dealerID}
                            </h4>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTP User Name")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter User Name"
                                onChange={edithandleOnChange}
                                name="userName"
                                value={editConsoleUploadData.userName}
                                className={
                                  editvalidations.editfTPUserNameVal &&
                                  "border__red"
                                }
                              />
                              <div>
                                {editvalidations.editfTPUserNameVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editfTPUserNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTP Password")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Password"
                                onChange={edithandleOnChange}
                                name="password"
                                value={editConsoleUploadData.password}
                                className={
                                  editvalidations.editfTPPasswordVal &&
                                  "border__red"
                                }
                              />
                              <div>
                                {editvalidations.editfTPPasswordVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editfTPPasswordVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("File Type")} <span>*</span>
                              </label>
                              <Select
                                placeholder=" Select FileType"
                                value={editConsoleUploadData.fileTypeId}
                                name="fileTypeId"
                                onChange={(e) =>
                                  edithandleOnChange(e, "select", "fileTypeId")
                                }
                                className={
                                  editvalidations.editfileTypeVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                              >
                                <Option value="">
                                  {traslate("Select File Type")}
                                </Option>
                                {fileTypeData.map((item) => {
                                  return (
                                    <Option
                                      key={item.fileTypeId}
                                      value={item.fileTypeId}
                                    >
                                      {item.fileType}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {editvalidations.editfileTypeVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editfileTypeVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Path")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Path"
                                value={editConsoleUploadData.path}
                                onChange={edithandleOnChange}
                                name="path"
                                className={
                                  editvalidations.editpathVal && "border__red"
                                }
                              />
                              <div>
                                {editvalidations.editpathVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editpathVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>{traslate("Add Update")}</label>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={editConsoleUploadData.addUpdate}
                                onChange={(e) =>
                                  edithandleOnChange(e, "select", "addUpdate")
                                }
                                name="addUpdate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title={traslate("Create FTP")}
                className="modalconsupld__section"
                centered
                visible={visible1}
                onOk={() => setVisible1(false)}
                onCancel={handleCancel1}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel1}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleFtpSumbit}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Enter User Name")} <span>*</span>
                              </label>
                              <input
                                name="userName"
                                value={userName}
                                onChange={handleFtpOnchange}
                                type="text"
                                placeholder="Enter User Name"
                                className={userNameVal && "border__red"}
                              />
                              <div>
                                {userNameVal && (
                                  <p className="error-color-red">
                                    {userNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Enter Password")} <span>*</span>
                              </label>
                              <input
                                autocomplete="new-password"
                                name="password"
                                value={password}
                                onChange={handleFtpOnchange}
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                className={passwordVal && "border__red"}
                              />
                              <span
                                class="p-viewer"
                                onClick={handlePassShowHide}
                              >
                                <i
                                  class={
                                    showPassword
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <div>
                                {passwordVal && (
                                  <p className="error-color-red">
                                    {passwordVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label> {traslate("Enter Folder Name")} </label>
                              <input
                                name="folder"
                                value={folder}
                                onChange={handleFtpOnchange}
                                type="text"
                                placeholder="Enter Folder Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title={traslate("Delete")}
                className="modalconsupld__section"
                centered
                visible={visible3}
                onOk={() => setVisible3(false)}
                onCancel={() => setVisible3(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel3}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={DeleteConsoleUploadData}
                  >
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p>
                              {traslate("Are you sure you want to delete")} "
                              {delConsoleData.dealerID}"{" "}
                              {traslate("Dealer Data")}
                            </p>
                            <h5>{traslate("THIS CAN NOT BE UNDONE")}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConsoleUpload;
