import React, { useState, useEffect } from "react";
import { Select, Tooltip, Table, Spin, Modal } from "antd";

import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import DefaultImage from "../../../assets/images/icons/sampleimage.jpg";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import DoughnutChart from "../../admin/JewelryReport/Doughnut";
import traslate from "../../../i18n/translate";
import NoReportDataIcon from "../../../assets/images/no-report-img.png";

/*Initial State for Binding Starts*/
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: [],
  price: "",
  price1: "",
  price2: ""
};
/*Initial State for Binding Ends*/
const { RangePicker } = DatePicker;
const { Option } = Select;

const GlobalJewelryChartReports = () => {
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [categoryColumnData, setCategoryColumnData] = useState([]);
  const [countCategoryColumnData, setCountCategoryColumnData] = useState([]);
  const [priceRange, setpriceRange] = useState([]);
  const [priceTotal, setPriceTotal] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [visible, setVisible] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [tableChartData, setTableChartData] = useState([]);
  const [showChartPrice, setShowChartPrice] = useState(false);
  const [showChartCategory, setShowChartCategory] = useState(false);
  const [defaultVisible, setDefaultVisible] = useState(false);
  const [key, setKey] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [categoryTotalValuePercentage, setCategoryTotalValuePercentage] =
    useState(0);
  const [priceTotalValuePercentage, setPriceTotalValuePercentage] = useState(0);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
  const [chartType, setChartType] = useState("");
  const [chartSection, setChartSection] = useState("");
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setSelectedData((prevState) => ({
            ...prevState,
            dealerID: loginDetials.loginDetials.responseData.dealerId
          }));
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
  }, []);

  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id
      };
      setShowLoader(true);
      AdminJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer
            }));
            handleGetClicksBasedOnVendor(id, responseData.forDealer);
            handleGetClicksBasedOnPrice(id, responseData.forDealer);
            handleGetTopRetailerCoulmnData(id, responseData.forDealer);
            handleGetTotalCount(id, responseData.forDealer);
            handleGetClicksBasedOnCategory(id, responseData.forDealer);
            handleGetTableChartData(id, responseData.forDealer);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetClicksBasedOnVendor = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksBasedOnVendor(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            let dealerCompany = [];
            let total = [];
            for (let i of responseData) {
              dealerCompany.push(i.dealerCompany);
              total.push(i.total);
            }
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  const handleGetClicksBasedOnPrice = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksBasedOnPrice(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            let showResp = responseData.every((element) => {
              return element.total == "0";
            });
            setShowChartPrice(showResp);
            let range = [];
            let total = [];
            for (let i of responseData) {
              range.push(i.range);
              total.push(i.total);
            }

            let totalValuePercentage = total.reduce(
              (a, b) => (a = Number(a) + Number(b)),
              0
            );

            setPriceTotal(total);
            setpriceRange(range);
            setPriceTotalValuePercentage(totalValuePercentage);
            setShowLoader(false);
            setKey(!key);
          } else {
            setShowLoader(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  const handleGetTopRetailerCoulmnData = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksBasedOnRetailer(
        initialEventState
      ).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let dealerName = [];
          let total = [];
          let showResp = responseData.every((element) => {
            return element.total == "0";
          });
          for (let i of responseData) {
            dealerName.push(i.dealerCompany);
            total.push(i.total);
          }
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  const handleGetClicksBasedOnCategory = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksBasedOnCategory(
        initialEventState
      ).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let category = [];
          let count = [];
          let showResp = responseData.every((element) => {
            return element.count == "0";
          });
          setShowChartCategory(showResp);
          for (let i of responseData) {
            category.push(i.category);
            count.push(i.count);
          }
          let totalValuePercentage = count.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );
          setCountCategoryColumnData(count);
          setCategoryColumnData(category);
          setCategoryTotalValuePercentage(totalValuePercentage);
          setShowLoader(false);
          setKey(!key);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  const handleGetTotalCount = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksCount(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            // setCountColumnData(responseData[0].totalCount);
          } else {
            setShowLoader(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  const handleGetTableChartData = (
    DealerId,
    forDealerId,
    type,
    section,
    pageNumber,
    pageSiz,
    sortColumnName,
    sortOrder
  ) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: section == "category" && type ? type : "",
        metalType: "",
        metalColorType: "",
        price: selectedData.price,
        price1: selectedData.price1.trim().toString(),
        price2: selectedData.price2.trim().toString(),
        vendor: "",
        retailer: "",
        reporttype: "Global",
        pageIndex: pageNumber ? pageNumber : 1,
        pageSize: pageSiz ? pageSiz : 10,
        sortColumnName:
          sortColumnName === "UserIPAddress"
            ? "UserIPAddress"
            : sortColumnName === "vendorName"
            ? "vendorName"
            : sortColumnName === "retailerName"
            ? "retailerName"
            : sortColumnName === "collectionNew"
            ? "collectionNew"
            : sortColumnName === "category"
            ? "Category"
            : sortColumnName === "date"
            ? "Date"
            : sortColumnName === "StyleNumber"
            ? "StyleNumber"
            : sortColumnName === "metalType"
            ? "MetalType"
            : sortColumnName === "metalColor"
            ? "MetalColor"
            : sortColumnName === "price"
            ? "Price"
            : "date",
        sortOrder: sortOrder ? sortOrder.toString() : sortType.toString()
      };
      setShowTableLoader(true);
      AdminJewelryReportService.GetTableChartData(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setTableChartData(responseData);
            setTotalCount(responseData?.[0]?.totalRecords || 0);
            setShowTableLoader(false);
          } else {
            setTotalCount(0);
            setTableChartData([]);
            setShowTableLoader(false);
          }
        }
      );
    } catch (error) {
      setTotalCount(0);
      console.log(error);
      setShowTableLoader(false);
      setSelectedData((prevState) => ({
        ...prevState,
        price: "",
        price1: "",
        price2: ""
      }));
    }
  };
  const handleGetVendorDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "global"
    };
    try {
      AdminJewelryReportService.GetVendorDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetRetailerDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "global"
    };
    try {
      AdminJewelryReportService.GetRetailerDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const changeRetailerListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        vendorIds: data
      };
      AdminJewelryReportService.ChangeRetailerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const changeVendorListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        retailerIds: data
      };
      AdminJewelryReportService.ChangeVendorList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(), //moment("2015-08-11T13:00:00.000000Z", "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format()
        toDate: moment.utc(dates[1]).toISOString()
      }));
    }
  };
  /*DatePicker Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e
        }));
      }

      if (e.length === 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e
        }));
      }

      if (e.length === 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };
  const applyFilterHandler = () => {
    handleGetClicksBasedOnVendor(selectedData.dealerID, selectedData.forDealer);
    handleGetClicksBasedOnPrice(selectedData.dealerID, selectedData.forDealer);
    handleGetTopRetailerCoulmnData(
      selectedData.dealerID,
      selectedData.forDealer
    );
    handleGetTotalCount(selectedData.dealerID, selectedData.forDealer);
    handleGetClicksBasedOnCategory(
      selectedData.dealerID,
      selectedData.forDealer
    );
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      "empty",
      "empty",
      pageNo,
      pageSize
    );
  };
  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const [sortColumnName, setSortColumnName] = useState("GT.MODIFIED");
  const [sortType, setSortType] = useState("desc");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order === "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order === "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "DESC";
      column = "date";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      chartType,
      chartSection,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  const columns = [
    {
      title: traslate("Product Image"),
      dataIndex: "imagePathNew",
      width: 90,
      render: (item, data) => (
        <>
          {data.imagePathNew ? (
            <span onClick={() => imageClickHandler(data.imagePathNew)}>
              <img
                src={data.imagePathNew}
                alt="img"
                className="product__img"
                style={{ cursor: "pointer" }}
              />
            </span>
          ) : (
            <span onClick={() => setDefaultVisible(true)}>
              <img
                src={DefaultImage}
                alt="img"
                className="product__img"
                style={{ cursor: "pointer" }}
              />
            </span>
          )}
        </>
      )
    },
    {
      title: traslate("User IP Address"),
      dataIndex: "userIPAddress",
      sorter: (a, b) => {},
      width: 90
    },
    {
      title: traslate("Vendor"),
      dataIndex: "vendorName",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Retailer"),
      dataIndex: "retailerName",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Collection"),
      dataIndex: "collectionNew",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Category"),
      dataIndex: "category",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Date"),
      dataIndex: "date",
      sorter: (a, b) => {},
      width: 120,
      render: (row, item) => {
        //

        if (Object.keys(item.date).length !== 0) {
          return (
            <React.Fragment>
              <span>{moment(item.date).format("MM-DD-YYYY, hh:mm:ss A")}</span>
            </React.Fragment>
          );
        }
      }
    },
    {
      title: traslate("Style Number"),
      dataIndex: "styleNumber",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Metal Type"),
      dataIndex: "metalType",
      sorter: (a, b) => {},
      width: 100
    },
    {
      title: traslate("Metal Color"),
      dataIndex: "metalColor",
      sorter: (a, b) => {},
      width: 100
    },
    {
      title: traslate("Price"),
      dataIndex: "price",
      sorter: (a, b) => {},
      width: 120
    }
  ];
  const tableSortHandler = (type, section) => {
    var obj = selectedData;
    if (section === "price") {
      const price = type === "Call For Price" ? type : "";
      const price1 =
        type === "None"
          ? "-"
          : type.includes("Call")
          ? ""
          : type.includes("Under")
          ? "0"
          : type.split("-")[0].split("$")[1];
      const price2 =
        type === "None"
          ? "-"
          : type.includes("Call")
          ? ""
          : type.includes("Under")
          ? type.split("$")[1]
          : type.split("-").length > 1
          ? type.split("-")[1].split("$")[1]
          : type;

      obj["price"] = price;
      obj["price1"] = price1;
      obj["price2"] = price2;

      // setSelectedData((prevState) => ({
      //   ...prevState,
      //   price,
      //   price1,
      //   price2
      // }));
    }
    else{
      obj["price"] = "";
      obj["price1"] = "";
      obj["price2"] = "";
    }
    setChartType(type);
    setChartSection(section); 
    setPageNo(1);
    setPageSize(10);
    handleGetTableChartData(
      obj.dealerID,
      obj.forDealer,
      type,
      section
    );  
  };
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Jewelry Chart Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          {dealerMemberTypeName === "Admin" ? (
            <>
              <div className="col-md-4">
                <div className="input__block">
                  <label> {traslate("Vendor(s)")} </label>
                  <Select
                    className="border__grey"
                    showSearch
                    mode="multiple"
                    showArrow
                    placeholder="Select one or more vendor"
                    optionFilterProp="children"
                    value={selectedData.vendorIds}
                    onChange={(e) => handleOnChange(e, "select", "vendors")}
                  >
                    {vendorList.map((item, key) => {
                      return (
                        <Option key={item.dealerID} value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input__block">
                  <label> {traslate("Retailer(s)")} </label>
                  <Select
                    showSearch
                    showArrow
                    className="border__grey"
                    placeholder="Select one or more retailer "
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "retailers")}
                    notFoundContent="No Data Found"
                    mode="multiple"
                    value={selectedData.retailerIds}
                  >
                    {retailerList.map((item, key) => {
                      return (
                        <Option key={key} value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </>
          ) : dealerMemberTypeName === "Vendor" ? (
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Retailer(s)")} </label>
                <Select
                  showSearch
                  showArrow
                  className="border__grey"
                  placeholder="Select one or more retailer "
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "retailers")}
                  notFoundContent="No Data Found"
                  mode="multiple"
                  value={selectedData.retailerIds}
                >
                  {retailerList.map((item, key) => {
                    return (
                      <Option key={key} value={item.dealerID}>
                        {item.dealerCompany}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          ) : (
            <div className="col-md-4">
              <div className="input__block">
                <label> {traslate("Vendor(s)")} </label>
                <Select
                  className="border__grey"
                  showSearch
                  mode="multiple"
                  showArrow
                  placeholder="Select one or more vendor"
                  optionFilterProp="children"
                  value={selectedData.vendorIds}
                  onChange={(e) => handleOnChange(e, "select", "vendors")}
                >
                  {vendorList.map((item, key) => {
                    return (
                      <Option key={item.dealerID} value={item.dealerID}>
                        {item.dealerCompany}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          <div className="col-md-4 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-1" onClick={applyFilterHandler}>
                {traslate("Apply Filter")}
              </button>
            </div>
          </div>
          <Spin spinning={showLoader}>
            <div className="col-lg-12 col-md-6">
              <div className="input__block">
                <label>
                  <b>
                    {traslate("Total Jewelry Clicks")} : {totalCount}
                  </b>
                  <Tooltip
                    placement="right"
                    title={traslate(
                      "Displays the total number of jewelry clicks through the currently specified date range above"
                    )}
                  >
                    <img src={UnionIcon} alt="" />
                  </Tooltip>
                </label>
              </div>
            </div>
            {/* )} */}
            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Category")} </b>
              </label>
              <div className="chart__section">
                {categoryColumnData !== null &&
                categoryColumnData.length >= 1 &&
                countCategoryColumnData.length >= 1 &&
                categoryTotalValuePercentage !== 0 &&
                !showChartCategory ? (
                  <DoughnutChart
                    key={key}
                    label={categoryColumnData}
                    data={countCategoryColumnData}
                    typeGetter={tableSortHandler}
                    section={"category"}
                    totalValuePercentage={categoryTotalValuePercentage}
                  />
                ) : (
                  <Spin spinning={showLoader}>
                    <div className="no-report-render">
                      <div className="img-space">
                        <img src={NoReportDataIcon} alt="no data" />
                      </div>
                      <div className="desc-space">
                        <h6 className="linkText">
                          {traslate("Sorry, No Chart Data was found")}
                        </h6>
                      </div>
                    </div>
                  </Spin>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <label>
                <b> {traslate("Jewelry Clicks Based on Price")} </b>
              </label>
              <div className="chart__section">
                {priceRange !== null &&
                priceRange.length >= 1 &&
                priceTotal.length >= 1 &&
                priceTotalValuePercentage !== 0 &&
                !showChartPrice ? (
                  <DoughnutChart
                    key={key}
                    label={priceRange}
                    data={priceTotal}
                    typeGetter={tableSortHandler}
                    section={"price"}
                    totalValuePercentage={priceTotalValuePercentage}
                  />
                ) : (
                  <div className="no-report-render">
                    <div className="img-space">
                      <img src={NoReportDataIcon} alt="no data" />
                    </div>
                    <div className="desc-space">
                      <h6 className="linkText">
                        {traslate("Sorry, No Chart Data was found")}
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Spin>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              onChange={handleTableASCDES}
              pagination={{
                total: totalCount,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                pageSize: pageSize,
                current: pageNo
              }}
              dataSource={tableChartData}
              loading={showTableLoader}
              scroll={{ y: 300, y: 800 }}
            />
          </div>
        </div>
      </div>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} className="img-fluid" />
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={defaultVisible}
        onOk={() => setDefaultVisible(false)}
        onCancel={() => setDefaultVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={DefaultImage} alt="img" className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default GlobalJewelryChartReports;
