import React, { useEffect, useState } from "react";
import { Button, Spin, Modal } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
import adminSettingsService from "../../../services/admin-settings.service";
import { useSelector } from "react-redux";
const DataMaps = () => {
  const [productIdDescriptionsData, setproductIdDescriptionsData] = useState(
    []
  );
  const [productIdDescriptionsSets, setproductIdDescriptionsSets] = useState(
    []
  );
  const [CategoryData, setCategoryData] = useState([]);
  const [CategorySets, setCategorySets] = useState([]);
  const [AttributesData, setAttributesData] = useState([]);
  const [AttributesSets, setAttributesSets] = useState([]);
  const [GemstonesData, setGemstonesData] = useState([]);
  const [GemstonesSets, setGemstonesSets] = useState([]);
  const [WatchesData, setWatchesData] = useState([]);
  const [WatchesSets, setWatchesSets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [newAttributeLoading, setNewAttributeLoading] = useState(false);
  const [newAttributevisible, setNewAttributevisible] = useState(false);
  const [key, setKey] = useState("productId");
  const [newAttributeData, setnewAttributeData] = useState({
    columnTypeId: "",
    type: "",
    key: "",
    value: ""
  });
  const [validations, setValidations] = useState({
    keyVal: "",
    valueVal: ""
  });
  const [dealerId, setDealerId] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
    handleGetProductIdDataList();
  }, []);

  const handleOnChange = (e, id) => {
    setDisableBtn(false);
    if (key === "productId") {
      const tempData = productIdDescriptionsData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setproductIdDescriptionsData(tempData);
    }
    if (key === "category") {
      const tempData = CategoryData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setCategoryData(tempData);
    }
    if (key === "Attributes") {
      const tempData = AttributesData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setAttributesData(tempData);
    }
    if (key === "Gemstones") {
      const tempData = GemstonesData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setGemstonesData(tempData);
    }
    if (key === "watches") {
      const tempData = WatchesData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setWatchesData(tempData);
    }
  };
  const handlTabChanges = (e) => {
    setKey(e);
    if (e === "productId") {
      handleGetProductIdDataList();
    }
    if (e === "category") {
      handleGetCategoryDataList();
    }
    if (e === "Attributes") {
      handleGetAttributesDataList();
    }
    if (e === "Gemstones") {
      handleGetGemStonesDataList();
    }
    if (e === "watches") {
      handleGetWatchInfoList();
    }
  };
  const handleGetProductIdDataList = () => {
    setLoading(true);
    AdminSettingsDataService.GetProductIdDataList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var tempProductIdDescriptionsSets = [
            ...new Set(responseData.map(({ headerName }) => headerName))
          ];

          setproductIdDescriptionsSets(tempProductIdDescriptionsSets);
          setproductIdDescriptionsData(responseData);
        } else {
          setproductIdDescriptionsSets([]);
          setproductIdDescriptionsData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetCategoryDataList = () => {
    setLoading(true);
    AdminSettingsDataService.GetCategoryDataList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var tempCategorySets = [
            ...new Set(responseData.map(({ headerName }) => headerName))
          ];
          setCategorySets(tempCategorySets);
          setCategoryData(responseData);
        } else {
          setCategorySets([]);
          setCategoryData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGetAttributesDataList = () => {
    setLoading(true);
    AdminSettingsDataService.GetAttributesDataList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;

        if (message === "Success") {
          var tempAttributesSets = [
            ...new Set(responseData.map(({ headerName }) => headerName))
          ];
          setAttributesSets(tempAttributesSets);
          setAttributesData(responseData);
        } else {
          setAttributesSets([]);
          setAttributesData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGetGemStonesDataList = async () => {
    setLoading(true);
    AdminSettingsDataService.GetGemStonesDataList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var tempGemstonesSets = [
            ...new Set(responseData.map(({ headerName }) => headerName))
          ];
          setGemstonesSets(tempGemstonesSets);
          setGemstonesData(responseData);
        } else {
          setGemstonesSets([]);
          setGemstonesData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGetWatchInfoList = () => {
    setLoading(true);
    AdminSettingsDataService.GetWatchInfoList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var tempWatchesSets = [
            ...new Set(responseData.map(({ headerName }) => headerName))
          ];
          setWatchesSets(tempWatchesSets);
          setWatchesData(responseData);
        } else {
          setWatchesSets([]);
          setWatchesData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveDataMaps = () => {
    setSubmitLoading(true);
    let inputData =
      key == "productId"
        ? productIdDescriptionsData
        : key == "category"
        ? CategoryData
        : key == "Attributes"
        ? AttributesData
        : key == "Gemstones"
        ? GemstonesData
        : WatchesData;
    AdminSettingsDataService.SaveDataMaps(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Record Saved Successfully.");
          if (key === "productId") {
            handleGetProductIdDataList();
          }
          if (key === "category") {
            handleGetCategoryDataList();
          }
          if (key === "Attributes") {
            handleGetAttributesDataList();
          }
          if (key === "Gemstones") {
            handleGetGemStonesDataList();
          }
          if (key === "watches") {
            handleGetWatchInfoList();
          }
          setDisableBtn(true);
        } else {
          NotificationManager.error("Record Not Saved.");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleNewOpen = (value) => {
    if (key == "category") {
      var columnTypeId = CategoryData.filter(function (item) {
        return item.headerName == value;
      })
        .map(function (item, index) {
          return item.columnTypeId;
        })
        .slice(0, 1);
    }
    if (key == "Attributes") {
      var columnTypeId = AttributesData.filter(function (item) {
        return item.headerName == value;
      })
        .map(function (item) {
          return item.columnTypeId;
        })
        .slice(0, 1);
    }

    setnewAttributeData((prevState) => ({
      ...prevState,
      columnTypeId: Number(columnTypeId),
      type: value
    }));
    setNewAttributevisible(true);
  };

  const handleNewCancel = () => {
    setNewAttributevisible(false);
    setnewAttributeData({
      type: "",
      key: "",
      value: ""
    });
    setValidations({
      keyVal: "",
      valueVal: ""
    });
  };

  const handleNewSumbit = () => {
    let isDataExist;
    if (key === "category") {
      isDataExist = CategoryData.filter(
        (x) => x.value.toLowerCase() === newAttributeData.key.toLowerCase()
      );
    } else {
      isDataExist = AttributesData.filter(
        (x) => x.value.toLowerCase() === newAttributeData.key.toLowerCase()
      );
    }
    if (isDataExist.length > 0) {
      NotificationManager.error("The key already exists.");
    } else {
      const isValid = handleValidation();
      if (!isValid) {
        return false;
      }
      let inputData = {
        dealerId: dealerId,
        type: newAttributeData.type,
        attributeName: newAttributeData.key,
        attributeValue: newAttributeData.value,
        columnTypeId: newAttributeData.columnTypeId.toString()
      };
      setNewAttributeLoading(true);
      adminSettingsService
        .InsertTierData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var returnMessage = response.data.responseData.returnMessage;
          if (message === "Success") {
            NotificationManager.success("Record Added Successfully.");
            if (key === "category") {
              handleGetCategoryDataList();
            }
            if (key === "Attributes") {
              handleGetAttributesDataList();
            }
            handleNewCancel();
          } else {
            if (responseData.returnId == 0) {
              NotificationManager.error(returnMessage);
            } else {
              NotificationManager.error("Record Not Added.");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setNewAttributeLoading(false);
        })
        .finally(() => {
          setNewAttributeLoading(false);
        });
    }
  };

  const handleValidation = () => {
    const { key, value } = newAttributeData;
    const validations = {};
    let isValid = true;

    if (!key) {
      isValid = false;
      validations.keyVal = traslate("Key is compulsory");
    }
    if (!value.trim()) {
      isValid = false;
      validations.valueVal = traslate("Value is compulsory");
    }

    if (!isValid) {
      setValidations(validations);
    }
    return isValid;
  };

  const handleNewAttributechange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setnewAttributeData({
        ...newAttributeData,
        [name]: e
      });
      setValidations((prevdata) => ({
        ...prevdata,
        valueVal: ""
      }));
    } else {
      setnewAttributeData({
        ...newAttributeData,
        [e.target.name]: e.target.value
      });
      if (e.target.name == "key") {
        setValidations((prevdata) => ({
          ...prevdata,
          keyVal: ""
        }));
      }
      if (e.target.name == "value") {
        setValidations((prevdata) => ({
          ...prevdata,
          valueVal: ""
        }));
      }
    }
  };

  return (
    <React.Fragment>
      <div className="prodetail__section form__fields">
        <div className="col-md-12">
          <div className="prod__maindiv data__maping">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-3">
                  {traslate("Default Data Map")}
                </h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="prodesc__div">
                  <div className="tabs__arrow">
                    <button class="left-button disable">
                      <i
                        class="fa fa-chevron-circle-left"
                        aria-hidden="true"
                      ></i>
                    </button>

                    <button class="right-button">
                      <i
                        class="fa fa-chevron-circle-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                  <Tabs
                    defaultActiveKey="productId"
                    id="uncontrolled-tab-example"
                    className="tab__div mb-3"
                    activeKey={key}
                    onSelect={(k) => handlTabChanges(k)}
                  >
                    <Tab
                      eventKey="productId"
                      title={traslate("Product ID & Description")}
                    >
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("Gemfind Column")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {productIdDescriptionsSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Status")}
                                  </h6>
                                  {productIdDescriptionsData
                                    .filter((x) => x.headerName == header)
                                    .map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {traslate(item.value)}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="category" title={traslate("Category")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("Gemfind Column")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {CategorySets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Categories")}

                                    <span
                                      onClick={() =>
                                        handleNewOpen(
                                          header ? header : "Categories"
                                        )
                                      }
                                      className="linkText ml-2"
                                    >
                                      {traslate("Add")}{" "}
                                      {header ? header : traslate("Categories")}
                                    </span>
                                  </h6>
                                  <div className="scroll__div">
                                    {CategoryData.filter(
                                      (x) => x.headerName == header
                                    ).map((item, i) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div" key={i}>
                                            <span className="head__txt">
                                              {traslate(item.value)}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="Attributes" title={traslate("Attributes")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("Gemfind Column")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {AttributesSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Attributes")}
                                    <span
                                      onClick={() =>
                                        handleNewOpen(
                                          header ? header : "Attributes"
                                        )
                                      }
                                      className="linkText ml-2"
                                    >
                                      {traslate("Add")}{" "}
                                      {header ? header : traslate("Attributes")}
                                    </span>
                                  </h6>
                                  <div className="scroll__div">
                                    {AttributesData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {traslate(item.value)}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="Gemstones" title={traslate("Gemstones")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("Gemfind Column")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {GemstonesSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Gemstones")}
                                  </h6>
                                  <div className="scroll__div">
                                    {GemstonesData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {traslate(item.value)}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="watches" title={traslate("Watches")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("Gemfind Column")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {WatchesSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Watches")}
                                  </h6>
                                  <div className="scroll__div">
                                    {WatchesData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {traslate(item.value)}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                  </Tabs>

                  <div className="col-md-6 mt-3">
                    <Button
                      className={disableBtn ? "disabled-btn" : "primary-btn"}
                      disabled={disableBtn == true ? true : false}
                      onClick={handleSaveDataMaps}
                      loading={submitLoading}
                    >
                      {traslate("Save Details")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={traslate("Create New Pair")}
        className="modalconsupld__section"
        centered
        visible={newAttributevisible}
        onCancel={handleNewCancel}
        width={400}
        footer={[
          <Button key="back" onClick={handleNewCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button
            loading={newAttributeLoading}
            key="submit"
            type="primary"
            onClick={handleNewSumbit}
          >
            {traslate("Save")}
          </Button>
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("Enter Key")}: <span>*</span>
                      </label>
                      <input
                        name="key"
                        value={newAttributeData.key ? newAttributeData.key : ""}
                        onChange={(e) => handleNewAttributechange(e, "", "key")}
                        type="text"
                        placeholder="Enter Key"
                        className={validations.keyVal && "border__red"}
                      />
                      <div>
                        {validations.keyVal && (
                          <p className="error-color-red">
                            {validations.keyVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("Enter Value")} : <span>*</span>
                      </label>
                      <input
                        name="value"
                        value={
                          newAttributeData.value ? newAttributeData.value : ""
                        }
                        onChange={(e) =>
                          handleNewAttributechange(e, "", "value")
                        }
                        type="text"
                        placeholder="Enter Value"
                        className={validations.valueVal && "border__red"}
                      />
                      <div>
                        {validations.valueVal && (
                          <p className="error-color-red">
                            {validations.valueVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DataMaps;
