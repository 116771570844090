import React, { useState, useEffect, Fragment } from "react";
import { Select, Checkbox, Button, Modal, Spin } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../../i18n/translate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import communityVendorService from "../../../services/community-vendor.service";
import RetailerDataService from "../../../services/retailer-retailer.service";
import { useSelector, useDispatch } from "react-redux";
import StarRatings from "react-star-ratings";
import Rectangle from "../../../assets/images/Rectangle.png";
import { useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import ManageInventory from "./ManageInventory";
import ViewInventory from "./ViewInventory";
import {
  setBindActivePageName,
  setManageInventoryVendorId,
  setVendorProfilePageName
} from "../../../actions/Community/ManageInventory";

const VendorProfile = () => {
  const preferredList = [
    {
      title: "E-Mail",
      value: "E-Mail"
    },
    {
      title: "Phone",
      value: "Phone"
    }
  ];
  const [appointmentSwitch, setappointmentSwitch] = useState(false);
  const OnRedirect = () => {
    setSelectedTab("Inventory");
  };

  const { Option } = Select;
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [dealerId, setDealerId] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [sideProfileLoading, setSideProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [retailerId, setRetailerId] = useState("");
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [company, setCompany] = useState("");
  const [content, setContent] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [page, setPage] = useState("RetailerAffirmation");
  const [checkbox, setCheckBox] = useState("");
  const [conversationData, setConversationData] = useState([]);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [conversationMsg, setConversationMsg] = useState("");
  const [tradeShowData, setTradeShowData] = useState([]);
  const [tradeDayData, setTradeDayData] = useState([]);
  const [tradeTimeData, setTradeTimeData] = useState([]);
  const [validations, setValidations] = useState({
    conversationVal: "",
    tradeshowVal: "",
    salesRepVal: "",
    appointmentDateVal: "",
    buyerNameVal: "",
    emailAddressVal: "",
    phoneNumberVal: "",
    qncVal: "",
    timeval: ""
  });
  const [appointmentData, setAppointmentData] = useState({
    dealerShowID: "",
    tradeshow: "",
    tradeDay: "",
    tradeTime: "",
    salesRep: "",
    appointmentDate: "",
    appointmentTime: "",
    buyerName: "",
    emailAddress: "",
    phoneNumber: "",
    preferred: "E-Mail",
    qnc: "",
    interested: false
  });
  const [SalesRep, setSalesRep] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [tradeShowList, setTradeShowList] = useState([]);
  const [contentAppData, setContentAppData] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [locationLoading, setLocationLoading] = useState([]);
  const [checkTypeOfInventoryPage, setCheckTypeOfInventoryPage] = useState([]);
  const [masterShowID, setMasterShowID] = useState("");
  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: `I am interested in opening a new account with ${rowDetails.designerName} and becoming an authorized reseller.By checking this box and clicking the submit button below, your name and store information will be sent to ${rowDetails.designerName}.`
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",
      note: `By checking this box and clicking the submit button below Ihere by affirm that ${
        company ? company : null
      } has an open and active account with ${
        rowDetails.designerName
      },that I am an authorized reseller of ${
        rowDetails.designerName
      },and that I am permitted to have the ir virtual inventory on my website.`
    }
  ];
  const dispatch = useDispatch();
  const loginDetials = useSelector((state) => state.loginReducer);
  const tabName = useSelector((state) => state.manageInventoryReducer.tabName);
  const reduxPageName = useSelector(
    (state) => state.manageInventoryReducer.pageName
  );
  const reduxVendorID = useSelector(
    (state) => state.manageInventoryReducer.vendorId
  );
  const location = useLocation();
  const search = useLocation().search;

  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const paramKey = new URLSearchParams(search).get("key");
  const paramIsShow = new URLSearchParams(search).get("isShow");
  const [loadingSaveConversation, setLoadingSaveConversation] = useState(false);

  useEffect(() => {
    if (loginDetials?.loginDetials?.responseData?.logInSucceeded) {
      setDealerId(loginDetials.loginDetials.responseData.dealerId);
      setCompany(loginDetials.loginDetials.responseData.dealerCompany);

      setCheckTypeOfInventoryPage(tabName);
      if (location.state) {
        setRetailerId(location.state.id);
        if (location.state.key) {
          if (location.state.id) {
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              location.state.id
            );

            GetSalesRepsList(location.state.id);
            handleGetTradeShowDropdown(location.state.id);
          }
          if (location.state.key == "Profile" && location.state.id) {
            setSelectedTab("Profile");
            dispatch(setVendorProfilePageName("Profile"));
            handleLoadVendorProfile(location.state.id);
          } else if (location.state.key == "Location" && location.state.id) {
            setSelectedTab("Location");
            dispatch(setVendorProfilePageName("Location"));
            handleLoadVendorLocation(location.state.id);
          } else if (location.state.key == "Inventory" && location.state.id) {
            setBindActivePageName(location?.state?.bindActivePageName);
            setSelectedTab("Inventory");
            dispatch(setVendorProfilePageName("Inventory"));
          } else if (location.state.key == "Conversation") {
            setSelectedTab("Conversation");
            dispatch(setVendorProfilePageName("Conversation"));
            handleLoadVendorConversation(
              loginDetials.loginDetials.responseData.dealerId,
              location.state.id
            );
          }
        }
      }

      // For Redux Store Value
      else if (paramdealerId) {
        if (paramdealerId) {
          setRetailerId(paramdealerId);
          dispatch(setManageInventoryVendorId(paramdealerId));
          if (paramKey) {
            if (paramdealerId) {
              handleLoadLeftRightSideData(
                loginDetials.loginDetials.responseData.dealerId,
                paramdealerId
              );
              handleGetTradeShowDropdown(paramdealerId);
            }
            if (paramKey == "Profile" && paramdealerId) {
              setSelectedTab("Profile");
              dispatch(setVendorProfilePageName("Profile"));
              handleLoadVendorProfile(paramdealerId);
            } else if (paramKey == "Location" && paramdealerId) {
              setSelectedTab("Location");
              dispatch(setVendorProfilePageName("Location"));
              handleLoadVendorLocation(paramdealerId);
            } else if (paramKey == "Inventory" && paramdealerId) {
              setSelectedTab("Inventory");
              dispatch(setVendorProfilePageName("Inventory"));
            } else if (paramKey == "Conversation") {
              setSelectedTab("Conversation");
              dispatch(setVendorProfilePageName("Conversation"));
              handleLoadVendorConversation(
                loginDetials.loginDetials.responseData.dealerId,
                paramdealerId
              );
            }
          }
        }
      } else {
        if (reduxVendorID) {
          setRetailerId(reduxVendorID);
          if (reduxPageName) {
            if (reduxVendorID) {
              handleLoadLeftRightSideData(
                loginDetials.loginDetials.responseData.dealerId,
                reduxVendorID
              );
              GetSalesRepsList(reduxVendorID);
              handleGetTradeShowDropdown(reduxVendorID);
            }
            if (reduxPageName == "Profile" && reduxVendorID) {
              setSelectedTab("Profile");
              handleLoadVendorProfile(reduxVendorID);
            } else if (reduxPageName == "Location" && reduxVendorID) {
              setSelectedTab("Location");
              handleLoadVendorLocation(reduxVendorID);
            } else if (reduxPageName == "Inventory" && reduxVendorID) {
              setSelectedTab("Inventory");
            } else if (reduxPageName == "Conversation") {
              setSelectedTab("Conversation");
              handleLoadVendorConversation(
                loginDetials.loginDetials.responseData.dealerId,
                reduxVendorID
              );
            }
          }
        }
      }
    }
  }, []);

  const handleGetTradeShowDropdown = (id) => {
    try {
      let inputData = {
        dealerID: id
      };
      setLoading(true);

      RetailerDataService.GetDealerSelectedShowforappointment(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setTradeShowData(responseData);
            } else {
              setTradeShowData([]);
            }
          } else {
            setLoading(false);
            setTradeShowData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetTradeDayDropdown = (id) => {
    try {
      let inputData = {
        id: id
      };
      setLoading(true);
      RetailerDataService.GetDealerSelectedTradShowDaysforappointment(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.dayList;

          if (message === "Success") {
            if (responseData) {
              setTradeDayData(responseData);
            } else {
              setTradeDayData([]);
            }
          } else {
            setLoading(false);
            setTradeDayData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetTradeTimeDropdown = (id) => {
    try {
      let inputData = {
        sTime: "8 AM",
        eTime: "12 PM",
        dealerID: retailerId,
        dealerShowID: appointmentData.tradeshow,
        date: id
      };
      setLoading(true);
      RetailerDataService.BindDropDownForTimeTradShowappointment(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            if (responseData) {
              setTradeTimeData(responseData);
            } else {
              setTradeTimeData([]);
            }
          } else {
            setLoading(false);
            setTradeTimeData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const GetSalesRepsList = (id) => {
    try {
      let inputData = {
        dealerID: id
      };
      RetailerDataService.GetSalesRepsList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSalesRep(responseData);
            } else {
              setSalesRep([]);
            }
          } else {
            setSalesRep([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadVendorConversation = (dealerid, id) => {
    try {
      let inputData = {
        vendorID: id,
        dealerID: dealerid
      };
      setConversationLoading(true);
      communityVendorService
        .LoadVendorConversation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setConversationData(responseData);
              setConversationLoading(false);
            } else {
              setConversationLoading(false);
              setConversationData([]);
            }
          } else {
            setConversationLoading(false);
            setConversationData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setConversationLoading(false);
        });
    } catch (error) {
      console.log(error);
      setConversationLoading(false);
    }
  };

  const handleLoadLeftRightSideData = (id, retailerID) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        vendorId: retailerID.toString(),
        f_Type: "1"
      };
      setSelectedColumnId(retailerID);
      setSideProfileLoading(true);
      communityVendorService
        .LoadLeftRightSideData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setRowDetails(responseData);
              setSideProfileLoading(false);
            } else {
              setRowDetails({});
              setSideProfileLoading(false);
            }
          } else {
            setRowDetails({});
            setSideProfileLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSideProfileLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSideProfileLoading(false);
    }
  };

  const handleLoadVendorProfile = (id) => {
    try {
      let inputData = {
        vendorId: id.toString()
      };
      setLoadingProfileData(true);
      communityVendorService
        .LoadVendorProfile(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setProfileData(responseData);
              setLoadingProfileData(false);
            } else {
              setProfileData([]);
              setLoadingProfileData(false);
            }
          } else {
            setProfileData({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  const handleLoadVendorLocation = (id) => {
    try {
      let inputData = {
        vendorId: id.toString()
      };
      setLocationLoading(true);
      communityVendorService
        .VendorLocationList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setLocationData(responseData);
              setLocationLoading(false);
            } else {
              setLocationData([]);
              setLocationLoading(false);
            }
          } else {
            setLocationData([]);
            setLocationLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLocationLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  const [visibleImagePreview, setVisibleImagePreview] = useState(false);

  const [visibleEmailModal, setVisibleEmailModal] = useState(false);

  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);

  const handleSendInvitationModalCancel = () => {
    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setCheckBox("");
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      1200: {
        items: 1
      }
    }
  };

  const handleTabsChange = (e) => {
    handleLoadLeftRightSideData(dealerId, retailerId);
    setValidations({
      conversationVal: "",
      tradeshowVal: "",
      salesRepVal: "",
      appointmentDateVal: "",
      buyerNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      qncVal: "",
      timeval: ""
    });
    setSelectedTab(e);
    dispatch(setVendorProfilePageName(e));
    if (e == "Profile") {
      handleLoadVendorProfile(retailerId);
    }
    if (e == "Conversation") {
      handleLoadVendorConversation(dealerId, retailerId);
    }
    if (e == "Location") {
      handleLoadVendorLocation(retailerId);
    }
    if (e == "Appointment") {
      setappointmentSwitch(!appointmentData);
      setAppointmentData({
        tradeshow: "",
        tradeDay: "",
        tradeTime: "",
        salesRep: "",
        appointmentDate: "",
        appointmentTime: "",
        buyerName: "",
        emailAddress: "",
        phoneNumber: "",
        preferred: "E-Mail",
        qnc: "",
        interested: false
      });
    }
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
  }

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleGetEmailData = () => {
    try {
      let inputData = {
        vendorID: retailerId,
        dealerID: dealerId,
        emailType: rowDetails.emailType
      };
      setEmailLoading(true);
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  const handleCheckBoxValidation = () => {
    NotificationManager.error("Please select option");
  };
  const handleNewAccountRequest = () => {
    try {
      let inputData = {
        email_Type: rowDetails.emailType,
        vendorID: retailerId,
        dealerID: dealerId
      };
      setLoading(true);

      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
            NotificationManager.success("Email has been Sent Successfully.");
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              retailerId
            );
          } else {
            setContent([]);
            NotificationManager.error("Email has not been Sent Successfully.");
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              retailerId
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: rowDetails.emailType,
        vendorID: retailerId,
        dealerID: dealerId
      };
      setLoading(true);

      communityVendorService
        .SendEmailToVendor(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;

          if (message === "Success") {
            NotificationManager.success("Email has been sent Successfully");
            setContent(responseData);
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              retailerId
            );
            setPage("RetailerAffirmation");
          } else {
            NotificationManager.success("Email has not been sent ");
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              retailerId
            );
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleValidation = () => {
    const validations = {
      conversationVal: "",
      appointmentVal: "",
      tradeshowVal: "",
      appointmentDateVal: "",
      buyerNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      qncVal: "",
      timeval: ""
    };

    let isValid = true;
    if (selectedTab == "Conversation") {
      if (!conversationMsg.trim()) {
        isValid = false;
        validations.conversationVal = traslate(
          "Conversation data is compulsory"
        );
      }
    }

    if (selectedTab == "Appointment") {
      if (!appointmentData.tradeshow) {
        isValid = false;
        validations.tradeshowVal = traslate("Trade show is compulsory");
      }
      // }
      if (!appointmentData.salesRep) {
        isValid = false;
        validations.salesRepVal = traslate("Sales rep is compulsory");
      }

      if (!appointmentData.buyerName) {
        isValid = false;
        validations.buyerNameVal = traslate("Buyer name is compulsory");
      }

      let validRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!appointmentData.emailAddress.trim()) {
        isValid = false;
        validations.emailAddressVal = traslate("Email address is compulsory");
      } else if (!appointmentData.emailAddress.match(validRegex)) {
        isValid = false;
        validations.emailAddressVal = traslate("Email address is invalid");
      } else {
        validations.emailAddressVal = "";
      }

      if (!appointmentData.phoneNumber.trim()) {
        isValid = false;
        validations.phoneNumberVal = traslate("Phone number is compulsory");
      } else if (
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes("-")) ||
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes(",")) ||
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes("+"))
      ) {
        validations.phoneNumberVal = "";
      } else if (
        !/^[ ()+]*([0-9][ ()+]*){10,15}$/.test(appointmentData.phoneNumber)
      ) {
        isValid = false;
        validations.phoneNumberVal = traslate("Phone number is invalid");
      } else {
        validations.phoneNumberVal = "";
      }
      if (!appointmentData.qnc) {
        isValid = false;
        validations.qncVal = traslate("Question is compulsory");
      }
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleConversationSave = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        vendorId: selectedColumnId.toString(),
        cMessage: conversationMsg,
        itemID: ""
      };
      setLoadingSaveConversation(true);
      communityVendorService
        .PostVendorConversation(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            setLoadingSaveConversation(false);
            NotificationManager.success("Conversation added Successfully.");
            handleLoadVendorConversation(dealerId, selectedColumnId);
          } else {
            setLoadingSaveConversation(false);
            NotificationManager.error("Conversation not Added.");
          }
        })
        .catch((error) => {
          setLoadingSaveConversation(false);
          console.log(error);
        });
    } catch (error) {
      setLoadingSaveConversation(false);
      console.log(error);
    }
    setConversationMsg("");
    setValidations({
      conversationVal: ""
    });
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAppointmentData({
        ...appointmentData,
        [name]: e
      });

      if (name == "tradeshow") {
        if (e == "") {
        } else {
          let dealerShowID = tradeShowData
            .filter((x) => x.id == e)
            .map((x) => x.masterShowID);
          setMasterShowID(dealerShowID);
        }
      }

      if (name === "tradeshow") {
        handleGetTradeDayDropdown(e);
        if (appointmentData.tradeDay) {
          setAppointmentData({
            ...appointmentData,
            tradeDay: ""
          });
        }
      }
      if (name === "tradeDay") {
        handleGetTradeTimeDropdown(e);
        if (appointmentData.tradeTime) {
          setAppointmentData({
            ...appointmentData,
            tradeTime: ""
          });
        }
      }
      if (name === "salesRep") {
        setValidations((prevdata) => ({
          ...prevdata,
          salesRepVal: ""
        }));
      }

      if (name === "appointmentTime") {
        setValidations((prevdata) => ({
          ...prevdata,
          timeval: ""
        }));
      }
    } else if (isSelect === "checked") {
      setAppointmentData({
        ...appointmentData,
        [name]: e.target.checked
      });
    } else {
      setAppointmentData({
        ...appointmentData,
        [e.target.name]: e.target.value
      });

      if (e.target.name === "tradeshow") {
        setValidations((prevdata) => ({
          ...prevdata,
          tradeshowVal: ""
        }));
      }
      if (e.target.name === "appointmentDate") {
        setValidations((prevdata) => ({
          ...prevdata,
          appointmentDateVal: ""
        }));
      }
      if (e.target.name === "buyerName") {
        setValidations((prevdata) => ({
          ...prevdata,
          buyerNameVal: ""
        }));
      }
      if (e.target.name === "emailAddress") {
        setValidations((prevdata) => ({
          ...prevdata,
          emailAddressVal: ""
        }));
      }
      if (e.target.name === "phoneNumber") {
        setValidations((prevdata) => ({
          ...prevdata,
          phoneNumberVal: ""
        }));
      }
      if (e.target.name === "qnc") {
        setValidations((prevdata) => ({
          ...prevdata,
          qncVal: ""
        }));
      }
    }
  };

  const handleEmailModal = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    setVisibleEmailModal(true);
    handleShowPopupVendorsAppointment();
  };

  const handleShowPopupVendorsAppointment = () => {
    try {
      let inputData = {
        dealerShowID: appointmentData.tradeshow,
        dealerID: dealerId.toString(),
        vendorID: selectedColumnId.toString(),
        appintmentDate: appointmentData.tradeDay,
        appointmentTime: appointmentData.tradeTime,
        buyerName: appointmentData.buyerName,
        email: appointmentData.emailAddress,
        phoneNumber: appointmentData.phoneNumber,
        questionsAndComments: appointmentData.qnc,
        boothNumber: appointmentData.tradeshow,
        preferred: appointmentData.preferred,
        localRep: appointmentData.salesRep,
        placingInventory: appointmentData.interested == true ? 1 : 0
      };
      setContentLoading(true);
      communityVendorService
        .ShowPopupVendorsAppointment(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setContentLoading(false);
            setContentAppData(responseData);
          } else {
            setContentLoading(false);
            setContentAppData([]);
          }
        })
        .catch((error) => {
          setContentLoading(false);
          console.log(error);
        });
    } catch (error) {
      setContentLoading(false);
      console.log(error);
    }
  };

  const handleSendEmailCancel = () => {
    setVisibleEmailModal(false);
  };

  const handleSendEmailDetails = () => {
    try {
      setVisibleEmailModal(false);
      let inputData = {
        dealerShowID: masterShowID
          ? masterShowID.toString()
          : ""
          ? appointmentData.tradeshow.toString()
          : "",
        dealerID: dealerId.toString(),
        vendorID: selectedColumnId.toString(),
        appintmentDate: appointmentData.tradeDay,
        appointmentTime: appointmentData.tradeTime,
        buyerName: appointmentData.buyerName,
        email: appointmentData.emailAddress,
        phoneNumber: appointmentData.phoneNumber,
        questionsAndComments: appointmentData.qnc,
        boothNumber: appointmentData.tradeshow,
        editHTML: contentAppData,
        preferred: appointmentData.preferred,
        localRep: appointmentData.salesRep,
        placingInventory: appointmentData.interested == true ? 1 : 0
      };
      setLoading(true);
      communityVendorService
        .InsertVendorsAppointment(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            setAppointmentData({
              tradeshow: "",
              tradeDay: "",
              tradeTime: "",
              salesRep: "",
              appointmentDate: "",
              appointmentTime: "",
              buyerName: "",
              emailAddress: "",
              phoneNumber: "",
              preferred: "E-Mail",
              qnc: "",
              interested: false
            });
            setLoading(false);
            NotificationManager.success("Appointement added Successfully.");
            handleLoadVendorConversation(dealerId, selectedColumnId);
          } else {
            setLoading(false);
            NotificationManager.error("Appointement not Added.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const openInNewTab = () => {
    let url = rowDetails.website;
    if (rowDetails.website.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.website.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  return (
    <div className="prodetail__section">
      <div className="form__fields">
        <div className="col-md-12">
          <div className="prod__maindiv prodmarkup__secion retailerprof__section permission__section">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-3">
                  {" "}
                  Vendor Profile{" "}
                </h4>
              </div>
              {selectedTab !== "Inventory" ? (
                <div className="col-lg-9 col-md-9">
                  <div className="prodesc__div">
                    <div className="tabs__arrow">
                      <button className="left-button disable">
                        <i
                          className="fa fa-chevron-circle-left"
                          aria-hidden="true"
                        ></i>
                      </button>

                      <button className="right-button">
                        <i
                          className="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <Tabs
                      defaultActiveKey="Profile"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="Profile" title={traslate("Profile")}>
                        <div className="tab__contentdiv">
                          <Spin spinning={loadingprofileData}>
                            <div className="col-lg-12">
                              <div className="desc__maindiv">
                                <div className="col-lg-12">
                                  <div className="top__slider">
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...options}
                                    >
                                      {profileData?.imagepaths?.map(
                                        (item, i) => {
                                          return item.imageName ? (
                                            <div className="item" key={i}>
                                              <div className="image__block">
                                                <img src={item.imageName} />
                                              </div>
                                            </div>
                                          ) : null;
                                        }
                                      )}
                                    </OwlCarousel>
                                  </div>
                                </div>
                                <div className="col-lg-12 p-3">
                                  {profileData.aboutCompany && (
                                    <div className="editor__ck">
                                      <span>
                                        {profileData.aboutCompany
                                          ? profileData.aboutCompany
                                          : ""}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Spin>
                        </div>
                      </Tab>

                      {rowDetails.vendorStatus !== "Out of Network" &&
                      rowDetails.vendorStatus !== "Profile Only" ? (
                        <Tab eventKey="Inventory" title={traslate("Inventory")}>
                          {checkTypeOfInventoryPage === "Manage" ? (
                            <ManageInventory />
                          ) : (
                            <ViewInventory />
                          )}
                        </Tab>
                      ) : null}

                      <Tab
                        eventKey="Conversation"
                        title={traslate("Conversation")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 mt-2">
                                <div className="input__block">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={
                                      conversationMsg ? conversationMsg : ""
                                    }
                                    // config={editorConfiguration}
                                    config={{
                                      toolbar: {
                                        items: [
                                          "bold",
                                          "italic",
                                          "link",
                                          "|",
                                          "fontColor"
                                        ]
                                      }
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setConversationMsg(data);
                                    }}
                                  />

                                  <div>
                                    {validations.conversationVal && (
                                      <p className="error-color-red">
                                        {validations.conversationVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <Button
                                  className="primary-btn"
                                  onClick={handleConversationSave}
                                  loading={loadingSaveConversation}
                                >
                                  {traslate("Post")}
                                </Button>
                              </div>
                              <Spin spinning={conversationLoading}>
                                <div className="col-lg-12">
                                  {conversationData.map((item, key) => {
                                    return (
                                      <div
                                        className="notedesc__maindiv"
                                        key={item.id}
                                      >
                                        <div className="date__headdiv">
                                          <div>
                                            <span>
                                              {item.cDateFormated}
                                              <b>
                                                {" @"}
                                                {item.senderName}
                                              </b>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="msg__headdiv">
                                          <div className="editor__ck">
                                            <span>
                                              {item?.messages && (
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: item?.messages
                                                  }}
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Spin>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Appointment"
                        title={traslate("Appointment")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="form__fields m-0 p-0 border-0 mh-auto">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h2 className="subheading">
                                      {traslate("Trade Show Appointment")}
                                    </h2>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Select Trade Show")}
                                        <span className="requires_txt">*</span>
                                      </label>
                                      <Select
                                        className={
                                          validations.tradeshowVal
                                            ? "border__red"
                                            : "border__grey"
                                        }
                                        placeholder="Select trade show"
                                        name="tradeshow"
                                        value={
                                          appointmentData.tradeshow
                                            ? appointmentData.tradeshow
                                            : null
                                        }
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "tradeshow"
                                          )
                                        }
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option value="">
                                          Select trade show
                                        </Option>
                                        {tradeShowData?.map((item) => {
                                          return (
                                            <Option value={item.id}>
                                              {item.showName}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                      <div>
                                        {validations.tradeshowVal && (
                                          <p className="error-color-red">
                                            {validations.tradeshowVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* select Day start */}

                                  {appointmentData.tradeshow ? (
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label> {traslate("Select Day")}</label>
                                        <Select
                                          className="border__grey"
                                          placeholder="Select Day"
                                          name="tradeDay"
                                          value={
                                            appointmentData.tradeDay
                                              ? appointmentData.tradeDay
                                              : null
                                          }
                                          showSearch
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            handleOnChange(
                                              e,
                                              "select",
                                              "tradeDay"
                                            )
                                          }
                                          // onSearch={onSearch}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          <Option value="">Select Day</Option>
                                          {tradeDayData?.map((item) => {
                                            return (
                                              <Option value={item.date}>
                                                {item.dayName}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {/* select Day end */}

                                  {/* select Time start */}
                                  {appointmentData.tradeDay ? (
                                    <div className="col-md-6">
                                      <div className="input__block">
                                        <label>
                                          {" "}
                                          {traslate("Select Time")}
                                        </label>
                                        <Select
                                          className="border__grey"
                                          placeholder="Select Time"
                                          name="tradeTime"
                                          value={
                                            appointmentData.tradeTime
                                              ? appointmentData.tradeTime
                                              : null
                                          }
                                          showSearch
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            handleOnChange(
                                              e,
                                              "select",
                                              "tradeTime"
                                            )
                                          }
                                          // onSearch={onSearch}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          <Option value="">
                                            Select trade show
                                          </Option>
                                          {tradeTimeData?.map((item) => {
                                            return (
                                              <Option value={item.value}>
                                                {item.name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {/* select time end */}

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Sales Rep")}
                                        <span className="requires_txt">*</span>
                                      </label>
                                      <Select
                                        showSearch
                                        className={
                                          validations.salesRepVal
                                            ? "border__red"
                                            : "border__grey"
                                        }
                                        placeholder="Select Sales Rep"
                                        optionFilterProp="children"
                                        name="salesRep"
                                        value={
                                          appointmentData.salesRep
                                            ? appointmentData.salesRep
                                            : null
                                        }
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "salesRep"
                                          )
                                        }
                                      >
                                        <Option value={null}>
                                          {" "}
                                          {traslate("Select Sales Rep")}{" "}
                                        </Option>
                                        {SalesRep?.map((item) => {
                                          return (
                                            <Option value={item.userId}>
                                              {item.name}{" "}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                      <div>
                                        {validations.salesRepVal && (
                                          <p className="error-color-red">
                                            {validations.salesRepVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Buyer Name")}{" "}
                                        <span className="requires_txt">*</span>
                                      </label>
                                      <input
                                        autoComplete="off"
                                        key={appointmentSwitch}
                                        type="text"
                                        placeholder="Enter Name"
                                        name="buyerName"
                                        value={
                                          appointmentData.buyerName
                                            ? appointmentData.buyerName
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.buyerNameVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.buyerNameVal && (
                                          <p className="error-color-red">
                                            {validations.buyerNameVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("E-Mail Address")}{" "}
                                        <span className="requires_txt">*</span>
                                      </label>
                                      <input
                                        autoComplete="off"
                                        key={appointmentSwitch}
                                        type="email"
                                        placeholder="Enter E-Mail Address"
                                        name="emailAddress"
                                        value={
                                          appointmentData.emailAddress
                                            ? appointmentData.emailAddress
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.emailAddressVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.emailAddressVal && (
                                          <p className="error-color-red">
                                            {validations.emailAddressVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Phone Number")}{" "}
                                        <span className="requires_txt">*</span>
                                      </label>
                                      <input
                                        autoComplete="off"
                                        key={appointmentSwitch}
                                        type="text"
                                        placeholder="Enter Phone Number"
                                        name="phoneNumber"
                                        value={
                                          appointmentData.phoneNumber
                                            ? appointmentData.phoneNumber
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.phoneNumberVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.phoneNumberVal && (
                                          <p className="error-color-red">
                                            {validations.phoneNumberVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> {traslate("Preferred")} </label>
                                      <Select
                                        autoComplete="off"
                                        key={appointmentSwitch}
                                        showSearch
                                        className="border__grey"
                                        placeholder="Preferred"
                                        optionFilterProp="children"
                                        name="preferred"
                                        value={
                                          appointmentData.preferred
                                            ? appointmentData.preferred
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "preferred"
                                          )
                                        }
                                      >
                                        {preferredList?.map((item, i) => {
                                          return (
                                            <Option value={item.value}>
                                              {item.title}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Questions")} /{" "}
                                        {traslate("Comments")}{" "}
                                        <span className="requires_txt">*</span>
                                      </label>
                                      <textarea
                                        autoComplete="off"
                                        key={appointmentSwitch}
                                        name="qnc"
                                        placeholder="Enter Questions / Comments"
                                        value={
                                          appointmentData.qnc
                                            ? appointmentData.qnc
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.qncVal && "border__red"
                                        }
                                      ></textarea>
                                      <div>
                                        {validations.qncVal && (
                                          <p className="error-color-red">
                                            {validations.qncVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="radio__block mb-4">
                                      <input
                                        key={appointmentSwitch}
                                        type="checkbox"
                                        name="interested"
                                        checked={
                                          appointmentData.interested == true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "checked",
                                            "interested"
                                          )
                                        }
                                      />
                                      <label>
                                        {traslate(
                                          "I Am Interested In Placing Your Inventory On My Website."
                                        )}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <button
                                        className="primary-btn"
                                        onClick={() => handleEmailModal()}
                                      >
                                        {traslate("Schedule")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="Location" title={traslate("Location")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <div className="col-lg-12">
                                  <Spin spinning={locationLoading}>
                                    <div className="row location__cards">
                                      {locationData.length > 0 ? (
                                        locationData.map((item, i) => {
                                          return (
                                            <div
                                              className="col-md-6"
                                              key={item}
                                            >
                                              <div className="cards__block">
                                                <div className="input__block">
                                                  <p className="subheading mt-0">
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Location Details"
                                                      )}{" "}
                                                    </b>{" "}
                                                    : {item.locationName}
                                                  </p>
                                                  <hr />
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Address"
                                                      )} :{" "}
                                                    </b>
                                                    {item.address}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate("State")} :{" "}
                                                    </b>{" "}
                                                    {item.state}
                                                  </p>
                                                  <p>
                                                    <b> {traslate("Zip")} : </b>{" "}
                                                    {item.zip}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Country"
                                                      )} :{" "}
                                                    </b>{" "}
                                                    {item.country}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Location Email"
                                                      )}{" "}
                                                      :{" "}
                                                    </b>{" "}
                                                    {item.emailID}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Location Phone"
                                                      )}{" "}
                                                      :{" "}
                                                    </b>{" "}
                                                    {item.phone}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Website"
                                                      )} :{" "}
                                                    </b>{" "}
                                                    <a
                                                      href={item.website}
                                                      className="linkText"
                                                    >
                                                      {item.website}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <span>
                                          {" "}
                                          {traslate("No record found")}{" "}
                                        </span>
                                      )}
                                    </div>
                                  </Spin>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              ) : (
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <div className="tabs__arrow">
                      <button className="left-button disable">
                        <i
                          className="fa fa-chevron-circle-left"
                          aria-hidden="true"
                        ></i>
                      </button>

                      <button className="right-button">
                        <i
                          className="fa fa-chevron-circle-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <Tabs
                      defaultActiveKey="Profile"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="Profile" title={traslate("Profile")}>
                        <div className="tab__contentdiv">
                          <Spin spinning={loadingprofileData}>
                            <div className="col-lg-12">
                              <div className="desc__maindiv">
                                <div className="col-lg-12">
                                  <div className="top__slider">
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...options}
                                    >
                                      {profileData?.imagepaths?.map(
                                        (item, i) => {
                                          return item.imageName ? (
                                            <div className="item" key={i}>
                                              <div className="image__block">
                                                <img src={item.imageName} />
                                              </div>
                                            </div>
                                          ) : null;
                                        }
                                      )}
                                    </OwlCarousel>
                                  </div>
                                </div>
                                <div className="col-lg-12 p-3">
                                  {profileData.aboutCompany && (
                                    <div className="editor__ck">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={
                                          profileData.aboutCompany
                                            ? profileData.aboutCompany
                                            : ""
                                        }
                                        config={{
                                          toolbar: []
                                        }}
                                        onChange={(e, editor) => {
                                          e.preventDefault();
                                          // }
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Spin>
                        </div>
                      </Tab>
                      {rowDetails.vendorStatus !== "Out of Network" &&
                      rowDetails.vendorStatus !== "Profile Only" ? (
                        <Tab eventKey="Inventory" title={traslate("Inventory")}>
                          {checkTypeOfInventoryPage === "Manage" ? (
                            <ManageInventory />
                          ) : (
                            <ViewInventory />
                          )}
                        </Tab>
                      ) : null}

                      <Tab
                        eventKey="Conversation"
                        title={traslate("Conversation")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 mt-2">
                                <div className="input__block">
                                  <textarea
                                    name="conversationMsg"
                                    onChange={(e) =>
                                      setConversationMsg(e.target.value)
                                    }
                                    value={conversationMsg}
                                    placeholder="Your message to the vendor."
                                  ></textarea>
                                  <p>
                                    {" "}
                                    {traslate("Write the first message ....")}
                                  </p>
                                  <div>
                                    {validations.conversationVal && (
                                      <p className="error-color-red">
                                        {validations.conversationVal}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <Button
                                  className="primary-btn"
                                  onClick={handleConversationSave}
                                >
                                  {traslate("Post")}
                                </Button>
                              </div>
                              <Spin spinning={conversationLoading}>
                                <div className="col-lg-12">
                                  {conversationData.map((item, key) => {
                                    return (
                                      <div
                                        className="notedesc__maindiv"
                                        key={item.id}
                                      >
                                        <div className="date__headdiv">
                                          <div>
                                            <span>
                                              {item.cDateFormated +
                                                "-" +
                                                item.senderName}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="msg__headdiv">
                                          <div className="editor__ck">
                                            <CKEditor
                                              editor={ClassicEditor}
                                              data={
                                                item.messages
                                                  ? item.messages
                                                  : ""
                                              }
                                              config={{
                                                toolbar: []
                                              }}
                                              onChange={(e, editor) => {
                                                e.preventDefault();
                                                // }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Spin>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="Appointment"
                        title={traslate("Appointment")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="form__fields m-0 p-0 border-0 mh-auto">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h2 className="subheading">
                                      Trade Show Appointment
                                    </h2>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> Select Trade Show</label>
                                      {tradeShowList.length !== 0 ? (
                                        <Select
                                          showSearch
                                          className={
                                            validations.tradeshowVal
                                              ? "border__red"
                                              : "border__grey"
                                          }
                                          placeholder="Select Trade Show"
                                          optionFilterProp="children"
                                          name="tradeshow"
                                          value={
                                            appointmentData.tradeshow
                                              ? appointmentData.tradeshow
                                              : null
                                          }
                                          onChange={(e) =>
                                            handleOnChange(
                                              e,
                                              "select",
                                              "tradeshow"
                                            )
                                          }
                                        >
                                          {tradeShowList?.map((item) => {
                                            return (
                                              <Option value={item.id}>
                                                {item.showName}{" "}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      ) : (
                                        <input value="No Trade Show Selected" />
                                      )}
                                      <div>
                                        {validations.tradeshowVal && (
                                          <p className="error-color-red">
                                            {validations.tradeshowVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> {traslate("Sales Rep")} </label>
                                      <Select
                                        showSearch
                                        className={
                                          validations.salesRepVal
                                            ? "border__red"
                                            : "border__grey"
                                        }
                                        placeholder="Select Sales Rep"
                                        optionFilterProp="children"
                                        name="salesRep"
                                        value={
                                          appointmentData.salesRep
                                            ? appointmentData.salesRep
                                            : null
                                        }
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "salesRep"
                                          )
                                        }
                                      >
                                        <Option value={null}>
                                          {" "}
                                          {traslate("Select Sales Rep")}{" "}
                                        </Option>
                                        {SalesRep?.map((item) => {
                                          return (
                                            <Option value={item.userId}>
                                              {item.name}{" "}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                      <div>
                                        {validations.salesRepVal && (
                                          <p className="error-color-red">
                                            {validations.salesRepVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> {traslate("Date")} </label>
                                      <input
                                        type="date"
                                        placeholder="Select Date"
                                        name="appointmentDate"
                                        value={
                                          appointmentData.appointmentDate
                                            ? appointmentData.appointmentDate
                                            : null
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.appointmentDateVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.appointmentDateVal && (
                                          <p className="error-color-red">
                                            {validations.appointmentDateVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> {traslate("Time")} </label>

                                      <Select
                                        showSearch
                                        className={
                                          validations.timeval
                                            ? "border__red"
                                            : "border__grey"
                                        }
                                        placeholder="Select Time"
                                        optionFilterProp="children"
                                        name="appointmentTime"
                                        value={
                                          appointmentData.appointmentTime
                                            ? appointmentData.appointmentTime
                                            : null
                                        }
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "appointmentTime"
                                          )
                                        }
                                      >
                                        <Option value="">
                                          {" "}
                                          {traslate("SelectTime")}{" "}
                                        </Option>
                                        <Option value="1:00">
                                          1 {traslate("AM")}
                                        </Option>
                                        <Option value="2:00">
                                          2 {traslate("AM")}
                                        </Option>
                                        <Option value="3:00">
                                          3 {traslate("AM")}
                                        </Option>
                                        <Option value="4:00">
                                          4 {traslate("AM")}
                                        </Option>
                                        <Option value="5:00">
                                          5 {traslate("AM")}
                                        </Option>
                                        <Option value="6:00">
                                          6 {traslate("AM")}
                                        </Option>
                                        <Option value="7:00">
                                          7 {traslate("AM")}
                                        </Option>
                                        <Option value="8:00">
                                          8 {traslate("AM")}
                                        </Option>
                                        <Option value="9:00">
                                          9 {traslate("AM")}
                                        </Option>
                                        <Option value="10:00">
                                          10 {traslate("AM")}
                                        </Option>
                                        <Option value="11:00">
                                          11 {traslate("AM")}
                                        </Option>
                                        <Option value="12:00">
                                          12 {traslate("AM")}
                                        </Option>
                                        <Option value="13:00">
                                          1 {traslate("PM")}
                                        </Option>
                                        <Option value="14:00">
                                          2 {traslate("PM")}
                                        </Option>
                                        <Option value="15:00">
                                          3 {traslate("PM")}
                                        </Option>
                                        <Option value="16:00">
                                          4 {traslate("PM")}
                                        </Option>
                                        <Option value="17:00">
                                          5 {traslate("PM")}
                                        </Option>
                                        <Option value="18:00">
                                          6 {traslate("PM")}
                                        </Option>
                                        <Option value="19:00">
                                          7 {traslate("PM")}
                                        </Option>
                                        <Option value="20:00">
                                          8 {traslate("PM")}
                                        </Option>
                                        <Option value="21:00">
                                          9 {traslate("PM")}
                                        </Option>
                                        <Option value="22:00">
                                          10 {traslate("PM")}
                                        </Option>
                                        <Option value="23:00">
                                          11 {traslate("PM")}
                                        </Option>
                                        <Option value="24:00">
                                          12 {traslate("PM")}
                                        </Option>
                                      </Select>
                                      <div>
                                        {validations.timeval && (
                                          <p className="error-color-red">
                                            {validations.timeval}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> {traslate("Buyer Name")} </label>
                                      <input
                                        type="text"
                                        placeholder="Enter Name"
                                        name="buyerName"
                                        value={appointmentData.buyerName}
                                        onChange={handleOnChange}
                                        className={
                                          validations.buyerNameVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.buyerNameVal && (
                                          <p className="error-color-red">
                                            {validations.buyerNameVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("E-Mail Address")}{" "}
                                      </label>
                                      <input
                                        type="email"
                                        placeholder="Enter E-Mail Address"
                                        name="emailAddress"
                                        value={
                                          appointmentData.emailAddress
                                            ? appointmentData.emailAddress
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.emailAddressVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.emailAddressVal && (
                                          <p className="error-color-red">
                                            {validations.emailAddressVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Phone Number")}{" "}
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Enter Phone Number"
                                        name="phoneNumber"
                                        value={
                                          appointmentData.phoneNumber
                                            ? appointmentData.phoneNumber
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.phoneNumberVal &&
                                          "border__red"
                                        }
                                      />
                                      <div>
                                        {validations.phoneNumberVal && (
                                          <p className="error-color-red">
                                            {validations.phoneNumberVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <label> {traslate("Preferred")} </label>
                                      <Select
                                        showSearch
                                        className="border__grey"
                                        placeholder="Preferred"
                                        optionFilterProp="children"
                                        name="preferred"
                                        value={
                                          appointmentData.preferred
                                            ? appointmentData.preferred
                                            : null
                                        }
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "preferred"
                                          )
                                        }
                                      >
                                        {preferredList?.map((item, i) => {
                                          return (
                                            <Option valur={item.value}>
                                              {item.title}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="input__block">
                                      <label>
                                        {" "}
                                        {traslate("Questions")} /{" "}
                                        {traslate("Comments")}{" "}
                                      </label>
                                      <textarea
                                        name="qnc"
                                        placeholder="Enter Questions / Comments"
                                        value={
                                          appointmentData.qnc
                                            ? appointmentData.qnc
                                            : ""
                                        }
                                        onChange={handleOnChange}
                                        className={
                                          validations.qncVal && "border__red"
                                        }
                                      ></textarea>
                                      <div>
                                        {validations.qncVal && (
                                          <p className="error-color-red">
                                            {validations.qncVal}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="radio__block mb-4">
                                      <input
                                        type="checkbox"
                                        name="interested"
                                        value={appointmentData.interested}
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "checked",
                                            "interested"
                                          )
                                        }
                                      />
                                      <label>
                                        {traslate(
                                          "I Am Interested In Placing Your Inventory On My Website."
                                        )}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="input__block">
                                      <button
                                        className="primary-btn"
                                        onClick={() => handleEmailModal()}
                                      >
                                        {traslate("Schedule")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="Location" title={traslate("Location")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <div className="col-lg-12">
                                  <Spin spinning={locationLoading}>
                                    <div className="row location__cards">
                                      {locationData.length > 0 ? (
                                        locationData.map((item, i) => {
                                          return (
                                            <div
                                              className="col-md-6"
                                              key={item}
                                            >
                                              <div className="cards__block">
                                                <div className="input__block">
                                                  <p className="subheading mt-0">
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Location Details"
                                                      )}{" "}
                                                    </b>{" "}
                                                    : {item.locationName}
                                                  </p>
                                                  <hr />
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Address"
                                                      )} :{" "}
                                                    </b>
                                                    {item.address}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate("State")} :{" "}
                                                    </b>{" "}
                                                    {item.state}
                                                  </p>
                                                  <p>
                                                    <b> {traslate("Zip")} : </b>{" "}
                                                    {item.zip}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Country"
                                                      )} :{" "}
                                                    </b>{" "}
                                                    {item.country}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Location Email"
                                                      )}{" "}
                                                      :{" "}
                                                    </b>{" "}
                                                    {item.emailID}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Location Phone"
                                                      )}{" "}
                                                      :{" "}
                                                    </b>{" "}
                                                    {item.phone}
                                                  </p>
                                                  <p>
                                                    <b>
                                                      {" "}
                                                      {traslate(
                                                        "Website"
                                                      )} :{" "}
                                                    </b>{" "}
                                                    <a
                                                      href={item.website}
                                                      className="linkText"
                                                    >
                                                      {item.website}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <span>
                                          {" "}
                                          {traslate("No record found")}{" "}
                                        </span>
                                      )}
                                    </div>
                                  </Spin>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}

              {selectedTab !== "Inventory" && (
                <div className="col-lg-3 col-md-3 mt-5">
                  <Spin spinning={sideProfileLoading}>
                    <div className="right__section mt-3">
                      <div className="profile__block">
                        <span className="linkText">
                          {rowDetails.designerName
                            ? rowDetails.designerName
                            : null}
                        </span>
                        <div className="logoImg__block p-0">
                          <img
                            src={
                              rowDetails.designerLogo
                                ? rowDetails.designerLogo
                                : null
                            }
                          />
                        </div>
                        <p className="activeFrom">
                          {traslate("Updated")} |{" "}
                          {rowDetails.modified ? rowDetails.modified : null}
                        </p>

                        <div className="star-ratings">
                          <p className="mb-0"> {traslate("Data Ratings")} </p>
                          <StarRatings
                            rating={rowDetails.ratingCount}
                            starRatedColor="#1883b8"
                            starDimension="18px"
                            starSpacing="0px"
                            numberOfStars={5}
                          />
                        </div>

                        <div>
                          <p>
                            {" "}
                            {rowDetails.status ? rowDetails.status : null}{" "}
                          </p>
                        </div>

                        {rowDetails.status == "Connected" ? null : (
                          <div className="input__block">
                            <button
                              className="primary-btn"
                              onClick={setIsModalSendInvitationVisible}
                            >
                              {rowDetails.action
                                ? rowDetails.action
                                : "Request Access"}
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="links__block">
                        <ul>
                          <li
                            onClick={() => {
                              rowDetails.status == "Connected"
                                ? localStorage.setItem(
                                    "vendorDataForCollection",
                                    rowDetails.txtDesignerName
                                  )
                                : NotificationManager.error(
                                    "Please connect this vendor"
                                  );
                            }}
                          >
                            <a
                              href={
                                // location?.state?.previousPage == "RingBuilder"
                                //   ? "/myVendorsRingBuilder"
                                //   : paramIsShow
                                //   ? "/myvendors"
                                //   : ""
                                rowDetails.status == "Connected" &&
                                "/manageCollection"
                              }
                            >
                              {rowDetails.collections
                                ? rowDetails.collections
                                : null}{" "}
                              {traslate("Collections")}{" "}
                            </a>
                          </li>
                          <li>
                            <a onClick={OnRedirect}>
                              {rowDetails.items ? rowDetails.items : null}{" "}
                              {traslate("Items")}{" "}
                            </a>
                          </li>
                        </ul>
                        <hr />
                      </div>

                      <div className="retailer__dtls mb-4">
                        <p>
                          {" "}
                          {rowDetails.dealerName
                            ? rowDetails.dealerName
                            : null}{" "}
                        </p>
                        <a href={`mailto:${rowDetails.dealerEmail}`}>
                          {rowDetails.dealerEmail
                            ? rowDetails.dealerEmail
                            : null}
                        </a>
                        <p>
                          {rowDetails.dealerPhone
                            ? rowDetails.dealerPhone
                            : null}
                        </p>
                        <span
                          className="linkText"
                          onClick={() => openInNewTab()}
                        >
                          {" "}
                          {rowDetails.website}
                        </span>
                        <hr />
                      </div>
                    </div>
                  </Spin>
                </div>
              )}

              <div className="col-lg-12">
                <Modal
                  title={traslate("Create A New Note")}
                  className="modalretailerprof__section"
                  centered
                  visible={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      // onClick={handleOk}
                      className={
                        checkbox == "" ? "disabled-btn" : "primary-btn"
                      }
                      disabled={(checkbox == "") == true ? true : false}
                      onClick={
                        checkbox == "CurrentAuthorizedReseller"
                          ? handleShowEmailWindow
                          : handleNewAccountRequest
                      }
                    >
                      {traslate("Submit")}
                    </Button>
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="editor__section">
                                <CKEditor
                                  editor={ClassicEditor}
                                  onReady={(editor) => {}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal
                  title={
                    page == "RetailerAffirmation"
                      ? traslate("Retailer Affirmation")
                      : traslate("Send Email")
                  }
                  visible={isModalSendInvitationVisible}
                  className="forgot__password"
                  onCancel={handleSendInvitationModalCancel}
                  footer={[
                    <div
                      className={
                        page == "RetailerAffirmation" ? "d-block" : "d-none"
                      }
                    >
                      <Button
                        key="submit"
                        className={
                          checkbox == "" ? "disabled-btn" : "primary-btn"
                        }
                        disabled={checkbox == "" ? true : false}
                        onClick={
                          checkbox == "CurrentAuthorizedReseller"
                            ? handleShowEmailWindow
                            : checkbox == "NewAccountRequest"
                            ? handleNewAccountRequest
                            : handleCheckBoxValidation
                        }
                      >
                        {traslate("Submit")}
                      </Button>
                    </div>,
                    <div className={page == "SendEmail" ? "d-block" : "d-none"}>
                      <Button
                        className="primary-btn"
                        key="submit"
                        onClick={handleSendEmail}
                      >
                        {traslate("Send")}
                      </Button>
                    </div>
                  ]}
                >
                  <div className="form__fields p-0 border-0 mh-auto">
                    {page == "RetailerAffirmation" ? (
                      <div>
                        {category.map((item) => {
                          return (
                            <Fragment>
                              <div className="radio__block">
                                <Checkbox
                                  key={item.value}
                                  onChange={handleCheckBoxChange}
                                  checked={item.value == checkbox}
                                  value={item.value}
                                >
                                  <label> {traslate(item.label)} </label>
                                </Checkbox>
                              </div>
                              <div>
                                <p> {item.note} </p>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="editor__section">
                        <Spin spinning={emailLoading}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={content ? content : ""}
                            onReady={(editor) => {}}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setContent(data);
                            }}
                          />
                        </Spin>
                      </div>
                    )}
                  </div>
                </Modal>

                {/* Inventory table modal */}

                <Modal
                  title={traslate("Image Preview")}
                  centered
                  visible={visibleImagePreview}
                  onOk={() => setVisibleImagePreview(false)}
                  onCancel={() => setVisibleImagePreview(false)}
                  width={400}
                >
                  <div className="form__fields mh-auto">
                    <img src={Rectangle} className="img-fluid" />
                  </div>
                </Modal>

                {/* Appointment Schedule Modal */}

                <Modal
                  className="emailTemplateEditorModal"
                  title={traslate("Send Email")}
                  centered
                  visible={visibleEmailModal}
                  onOk={() => setVisibleEmailModal(false)}
                  onCancel={() => setVisibleEmailModal(false)}
                  width={800}
                  hieght={600}
                  footer={[
                    <Button key="back" onClick={handleSendEmailCancel}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button key="Save" onClick={handleSendEmailDetails}>
                      {traslate("Save")}
                    </Button>
                  ]}
                >
                  <div className="editor__section">
                    <Spin spinning={contentLoading}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={contentAppData}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContentAppData(data);
                        }}
                      />
                    </Spin>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;
