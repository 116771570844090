import React, { useState, useEffect, Fragment } from "react";
import {
  Input,
  Select,
  Checkbox,
  Button,
  Switch,
  Table,
  Modal,
  Spin,
  Slider,
  Pagination
} from "antd";
import { Link, useLocation } from "react-router-dom";
import traslate from "../../../i18n/translate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import communityVendorService from "../../../services/community-vendor.service";
import CommunityInventoryService from "../../../services/community-inventory.service";
import RetailerDataService from "../../../services/retailer-retailer.service";
import { useSelector } from "react-redux";
import space__icon from "../../../assets/images/space__icon.png";
import StarRatings from "react-star-ratings";
import Filter_icon from "../../../assets/images/filter_icon.png";
import Info_icon from "../../../assets/images/info_icon.png";
import Close from "../../../assets/images/close.png";
import NotificationManager from "react-notifications/lib/NotificationManager";

const ManageInventory = () => {
  // inventory
  const [checked, setChecked] = useState(false);
  const [productChange, setProductChange] = useState(true);
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [visibleAll, setVisibleAll] = useState(false);
  // For bindActive
  const search = useLocation().search;
  const paramIsShow = new URLSearchParams(search).get("isShow");
  const bindActivePage = useSelector(
    (state) => state.manageInventoryReducer.bindActivePageName
  );

  function onSearch(val) {}

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setBindWhatVal("Products");
    handleGetOneVendorProduct(
      dealerId,
      vendorId,
      "Products",
      searchValue,
      ManageInventoryState.priceMax == priceValue[1] ? [0, 0] : priceValue,
      pageNo,
      pageSize
    );
    setViewItem(viewname);
  };

  /*Custom State Binding Starts*/
  const initialManageInventoryState = {
    productType: [],
    category: [],
    collection: [],
    metalType: [],
    metalColor: [],
    priceMin: 0,
    priceMax: "",
    gemstoneType: []
  };
  const { Search } = Input;
  const [ManageInventoryState, setManageInventoryState] = useState(
    initialManageInventoryState
  );
  const [ProductTypeList, setProductTypeList] = useState([]);
  const [CategoriesList, setCategoriesList] = useState([]);
  const [MetalTypeList, setMetalTypeList] = useState([]);
  const [MetalColorList, setMetalColorList] = useState([]);
  const [CollectionList, setCollectionList] = useState([]);
  const [GemstoneList, setGemstoneList] = useState([]);
  const [priceValue, setPriceValue] = useState([0, 0]);
  const [rowDetails, setRowDetails] = useState({});
  const [bindWhatVal, setBindWhatVal] = useState("ALL");
  const [imagePath, setImagePath] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortType, setSortType] = useState("asc");
  const [sortColumnName, setSortColumnName] = useState("GFInventoryID");
  const [vendorProductList, setVendorProductList] = useState([]);
  const [vendorProductDetail, setVendorProductDetail] = useState([]);
  const [selectedgfInventoryID, setSelectedgfInventoryID] = useState(0);
  const [productTotalRecords, setProductTotalRecords] = useState(0);
  const [searchValue, SetSearchValue] = useState("");
  const [activeProductIds, setActiveProductIds] = useState([]);
  const [inActiveProductIds, setInActiveProductIds] = useState([]);
  const [activeDeactiveAll, setActiveDeactiveAll] = useState("");
  const [updateFlagLoading, setUpdateFlagLoading] = useState(false);
  const [IsFirstCall, setIsFirstCall] = useState(true);
  const [visibleImagePreview, setVisibleImagePreview] = useState(false);
  const [visibleEmailModal, setVisibleEmailModal] = useState(false);
  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);
  /*Custom State Binding Ends*/

  //inventory ends
  const { Option } = Select;
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [dealerId, setDealerId] = useState("");
  const [visible, setVisible] = useState(false);
  const [sideProfileLoading, setSideProfileLoading] = useState(false);
  const [retailerId, setRetailerId] = useState("");
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [company, setCompany] = useState("");
  const [content, setContent] = useState("");
  const [emailModalContent, setEmailModalContent] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [page, setPage] = useState("RetailerAffirmation");
  const [checkbox, setCheckBox] = useState("");
  const [conversationData, setConversationData] = useState([]);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [conversationMsg, setConversationMsg] = useState("");
  const [validations, setValidations] = useState({
    conversationVal: "",
    tradeshowVal: "",
    salesRepVal: "",
    appointmentDateVal: "",
    buyerNameVal: "",
    emailAddressVal: "",
    phoneNumberVal: "",
    qncVal: "",
    timeval: ""
  });
  const [appointmentData, setAppointmentData] = useState({
    tradeshow: "",
    salesRep: "",
    appointmentDate: "",
    appointmentTime: "",
    buyerName: "",
    emailAddress: "",
    phoneNumber: "",
    preferred: "E-Mail",
    qnc: "",
    interested: false
  });
  const [SalesRep, setSalesRep] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [tradeShowList, setTradeShowList] = useState([]);
  const [contentAppData, setContentAppData] = useState("");
  const category = [
    {
      label: "Current Authorized Resellers",
      value: "CurrentAuthorizedReseller",
      note: `By checking this box and clicking the submit button below Ihere by affirm that ${
        company ? company : null
      } has an open and active account with ${
        rowDetails.DesignerName
      },that I am an authorized reseller of ${
        rowDetails.DesignerName
      },and that I am permitted to have the ir virtual inventory on my website.`
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",
      note: `I am interested in opening a new account with ${rowDetails.DesignerName} and becoming an authorized reseller.By checking this box and clicking the submit button below, your name and store information will be sent to ${rowDetails.DesignerName}.`
    }
  ];
  const loginDetials = useSelector((state) => state.loginReducer);
  const location = useLocation();
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency ? currency.charAt(currency.length - 1) : '';
  const vendorId = useSelector(
    (state) => state.manageInventoryReducer.vendorId
  );

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setCompany(loginDetials.loginDetials.responseData.dealerCompany);
          GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);
          handleGetTradeShowAppointment(
            loginDetials.loginDetials.responseData.dealerId
          );
          setRetailerId(vendorId);
          if (location.state) {
            handleGetOneVendorProduct(
              loginDetials.loginDetials.responseData.dealerId,
              location.state.id,
              bindWhatVal,
              searchValue,
              priceValue,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              location.state.id
            );
          } else {
            handleGetOneVendorProduct(
              loginDetials.loginDetials.responseData.dealerId,
              vendorId,
              bindWhatVal,
              searchValue,
              priceValue,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
            handleLoadLeftRightSideData(
              loginDetials.loginDetials.responseData.dealerId,
              vendorId
            );
          }
        }
      }
    }
  }, []);

  const handleGetTradeShowAppointment = (id) => {
    try {
      let inputData = {
        dealerID: id
      };
      communityVendorService
        .TradeShowAppointment(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setTradeShowList(responseData);
            } else {
              setTradeShowList([]);
            }
          } else {
            setTradeShowList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetSalesRepsList = (id) => {
    try {
      let inputData = {
        dealerID: id
      };
      RetailerDataService.GetSalesRepsList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSalesRep(responseData);
            } else {
              setSalesRep([]);
            }
          } else {
            setSalesRep([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadVendorConversation = (dealerid, id) => {
    try {
      let inputData = {
        vendorID: id,
        dealerID: dealerid
      };
      setConversationLoading(true);
      communityVendorService
        .LoadVendorConversation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setConversationData(responseData);
              setConversationLoading(false);
            } else {
              setConversationLoading(false);
              setConversationData([]);
            }
          } else {
            setConversationLoading(false);
            setConversationData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setConversationLoading(false);
        });
    } catch (error) {
      console.log(error);
      setConversationLoading(false);
    }
  };

  const handleLoadLeftRightSideData = (id, retailerID) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        vendorId: retailerID.toString(),
        f_Type: "1"
      };
      setSelectedColumnId(retailerID);
      setSideProfileLoading(true);
      CommunityInventoryService.LoadLeftRightSideData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setRowDetails(responseData.Table[0]);
              setSideProfileLoading(false);
            } else {
              setRowDetails({});
              setSideProfileLoading(false);
            }
          } else {
            setRowDetails({});
            setSideProfileLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSideProfileLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSideProfileLoading(false);
    }
  };

  const handleSendInvitationModalCancel = () => {
    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setCheckBox("");
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelWarning = () => {
    setVisibleAll(false);
  };

  function onSearch(val) {}
  const onSearchFunc = (value) => {
    SetSearchValue(value);
    handleGetOneVendorProduct(
      loginDetials.loginDetials.responseData.dealerId,
      vendorId,
      bindWhatVal,
      value,
      priceValue,
      1,
      10,
      sortColumnName,
      sortType
    );
    setPageNo(1);
    setPageSize(10);
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
  }

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleGetEmailData = () => {
    try {
      let inputData = {
        vendorID: retailerId,
        designerType: 3,
        dealerID: dealerId,
        emailType: rowDetails.emailType
      };
      setEmailLoading(true);
      CommunityInventoryService.GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            if (responseData) {
              setContent(responseData);
            }
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };
  const handleGeneratePopup = (dealerId) => {
    try {
      let inputData = {
        vendorID: retailerId,
        designerType: 3,
        dealerID: dealerId,
        emailType: rowDetails.emailType,
        userName: "Alex Fetanat"
      };

      CommunityInventoryService.GeneratePopup(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setEmailModalContent(responseData);
            }
          } else {
            setEmailModalContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckBoxValidation = () => {
    NotificationManager.error("Please select option");
  };
  const handleNewAccountRequest = () => {
    try {
      let inputData = {
        email_Type: rowDetails.emailType,
        vendorID: retailerId,
        dealerID: dealerId
      };

      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
            NotificationManager.success("Request sent Successfully");
          } else {
            setContent([]);
            NotificationManager.error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleSendInvitationModalCancel();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: rowDetails.emailType,
        vendorID: retailerId,
        dealerID: dealerId
      };

      communityVendorService
        .SendEmailToVendor(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;

          if (message === "Success") {
            NotificationManager.success("Email has been sent Successfully");
            setContent(responseData);
          } else {
            NotificationManager.success("Email has not been sent ");
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleSendInvitationModalCancel();
  };

  const handleValidation = () => {
    const validations = {
      conversationVal: "",
      appointmentVal: "",
      tradeshowVal: "",
      appointmentDateVal: "",
      buyerNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      qncVal: "",
      timeval: ""
    };

    let isValid = true;
    if (selectedTab == "Conversation") {
      if (!conversationMsg.trim()) {
        isValid = false;
        validations.conversationVal = traslate(
          "Conversation data is compulsory"
        );
      }
    }

    if (selectedTab == "Appointments") {
      if (tradeShowList.length !== 0) {
        if (!appointmentData.tradeshow) {
          isValid = false;
          validations.tradeshowVal = traslate("Trade show is compulsory");
        }
      }
      if (!appointmentData.salesRep) {
        isValid = false;
        validations.salesRepVal = traslate("Sales rep is compulsory");
      }
      if (!appointmentData.appointmentDate) {
        isValid = false;
        validations.appointmentDateVal = traslate(
          "Appointment date is compulsory"
        );
      }
      if (!appointmentData.buyerName) {
        isValid = false;
        validations.buyerNameVal = traslate("Buyer name is compulsory");
      }
      let validRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!appointmentData.emailAddress.trim()) {
        isValid = false;
        validations.emailAddressVal = traslate("Email address is compulsory");
      } else if (!appointmentData.emailAddress.match(validRegex)) {
        isValid = false;
        validations.emailAddressVal = traslate("Email address is invalid");
      } else {
        validations.emailAddressVal = "";
      }
      if (!appointmentData.phoneNumber.trim()) {
        isValid = false;
        validations.phoneNumberVal = traslate("Phone number is compulsory");
      } else if (
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes("-")) ||
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes(",")) ||
        (appointmentData.phoneNumber.length >= 10 &&
          appointmentData.phoneNumber.includes("+"))
      ) {
        validations.phoneNumberVal = "";
      } else if (
        !/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(
          appointmentData.phoneNumber
        )
      ) {
        isValid = false;
        validations.phoneNumberVal = traslate("Phone number is invalid");
      } else {
        validations.phoneNumberVal = "";
      }
      if (!appointmentData.qnc) {
        isValid = false;
        validations.qncVal = traslate("Question is compulsory");
      }
      if (!appointmentData.appointmentTime) {
        isValid = false;
        validations.timeval = traslate("Time is compulsory");
      }
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleSendEmailCancel = () => {
    setVisibleEmailModal(false);
  };

  const handleSendEmailDetails = () => {
    try {
      let inputData = {
        dealerShowID: "",
        dealerID: dealerId.toString(),
        vendorID: selectedColumnId.toString(),
        appintmentDate: appointmentData.appointmentDate,
        appointmentTime: appointmentData.appointmentTime,
        buyerName: appointmentData.buyerName,
        email: appointmentData.emailAddress,
        phoneNumber: appointmentData.phoneNumber,
        questionsAndComments: appointmentData.qnc,
        boothNumber: appointmentData.tradeshow,
        editHTML: contentAppData,
        preferred: appointmentData.preferred,
        localRep: appointmentData.salesRep,
        placingInventory: appointmentData.interested == true ? 1 : 0
      };
      communityVendorService
        .InsertVendorsAppointment(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Appointement added Successfully.");
            handleLoadVendorConversation(dealerId, selectedColumnId);
          } else {
            NotificationManager.error("Appointement not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const openInNewTab = () => {
    let url = rowDetails.website;
    if (rowDetails.website.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.website.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  /*Custom API Binding Starts*/

  const handleGetOneVendorProduct = (
    dealerID,
    vendorId,
    bindWhatVal,
    searchValue,
    priceValue,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    try {
      let inputData = {
        bindWhat: bindWhatVal ? bindWhatVal : "ALL",
        dealerID: dealerID ? dealerID.toString() : "",
        vendorId: vendorId ? vendorId.toString() : "",
        collection:
          ManageInventoryState.collection.length > 0
            ? ManageInventoryState.collection.toString()
            : "",
        productType:
          ManageInventoryState.productType.length > 0
            ? ManageInventoryState.productType.toString()
            : "",
        metalType:
          ManageInventoryState.metalType.length > 0
            ? ManageInventoryState.metalType.toString()
            : "",
        metalColor:
          ManageInventoryState.metalColor.length > 0
            ? ManageInventoryState.metalColor.toString()
            : "",
        category:
          ManageInventoryState.category.length > 0
            ? ManageInventoryState.category.toString()
            : "",
        style: "",
        priceMin: priceValue[0] ? priceValue[0].toString() : "0",
        priceMax: priceValue[1] ? priceValue[1].toString() : "",
        sortColumnName: sortColumnName || "GFInventoryID",
        sortOrder: sortOrder || "ASC",
        pageNo: pageNo ? pageNo : 1,
        pageSize: pageSize ? pageSize : 10,
        gemstoneType:
          ManageInventoryState.gemstoneType.length > 0
            ? ManageInventoryState.gemstoneType.toString()
            : "",
        viewBy: "1",
        pricingLink: "true",
        skUs: searchValue ? searchValue.toString() : "",
        bindActive:
          bindActivePage == "Popular"
            ? "4"
            : bindActivePage == "Recently Updated"
            ? "7"
            : bindActivePage == "Archived Vendor"
            ? "5"
            : bindActivePage == "New"
            ? "3"
            : bindActivePage == "Pending Requests"
            ? "2"
            : bindActivePage == "Out Of Network"
            ? "6"
            : bindActivePage == "Network"
            ? "00"
            : bindActivePage == ""
            ? "1"
            : "0"
      };
      setContentLoading(true);
      CommunityInventoryService.GetOneVendorProduct(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              if (responseData.hasOwnProperty("dtParentChild")) {
                setProductTypeList(responseData.dtParentChild);
              }
              if (responseData.hasOwnProperty("dtProductType")) {
                setCategoriesList(responseData.dtProductType);
              }
              if (responseData.hasOwnProperty("dtMetalType")) {
                setMetalTypeList(responseData.dtMetalType);
              }
              if (responseData.hasOwnProperty("dtMetalColor")) {
                setMetalColorList(responseData.dtMetalColor);
              }
              if (responseData.hasOwnProperty("dtCollectionNames")) {
                setCollectionList(responseData.dtCollectionNames);
              }
              if (responseData.hasOwnProperty("dtGemstoneType")) {
                setGemstoneList(responseData.dtGemstoneType);
              }
              setContentLoading(false);
              setVendorProductList(responseData.dtProducts);
              setProductTotalRecords(responseData.CountdtProducts[0].Count);
              if (responseData.dtProducts.length > 0) {
                if (responseData.dtProducts[0].IsactiveValue == true) {
                  setChecked(true);
                } else {
                  setChecked(false);
                }
              }
              let tempManageInv = ManageInventoryState;

              if (IsFirstCall) {
                setPriceValue([0, responseData?.MaxPrice?.[0].MaxPrice]);
                tempManageInv["priceMax"] = parseInt(
                  responseData?.MaxPrice?.[0]?.MaxPrice
                );
              }

              setManageInventoryState((prevState) => ({
                ...prevState,
                ...tempManageInv
              }));
              setIsFirstCall(false);
            }
          } else {
            setContentLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setContentLoading(false);
        });
    } catch (error) {
      console.log(error);
      setContentLoading(false);
    }
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;

      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "GFInventoryID";
      setSortColumnName(column);
      setSortType(type);
    }
    handleGetOneVendorProduct(
      dealerId,
      vendorId,
      bindWhatVal,
      searchValue,
      priceValue,
      pageNo,
      pageSize,
      column,
      type
    );
  };

  const onResetFilter = () => {
    let tempState = ManageInventoryState;
    tempState["productType"] = [];
    tempState["category"] = [];
    tempState["metalColor"] = [];
    tempState["metalType"] = [];
    tempState["collection"] = [];
    tempState["gemstoneType"] = [];
    setManageInventoryState((prevState) => ({
      ...prevState,
      ...tempState
    }));
    setPriceValue([
      ManageInventoryState.priceMin,
      ManageInventoryState.priceMax
    ]);
    SetSearchValue("");
    handleGetOneVendorProduct(
      dealerId,
      vendorId,
      bindWhatVal,
      "",
      [0, 0],
      1,
      10
    );
    setPageNo(1);
    setPageSize(10);
  };

  const onChangeMultiDrop = (e, name) => {
    let tempState = ManageInventoryState;
    if (e.includes("")) {
      tempState[name] = [];
      tempState[name].push("");
    } else {
      tempState[name] = e;
    }
    setManageInventoryState((prevState) => ({
      ...prevState,
      ...tempState
    }));
    setPageNo(1);
    handlePageNoChange(1, pageSize);
    handleGetOneVendorProduct(
      dealerId,
      vendorId,
      "Products",
      searchValue,
      priceValue,
      1,
      pageSize,
      sortColumnName,
      sortType
    );
  };

  const handleOpenDetailsModal = (row) => {
    handleProductChangeOpen(row.GFInventoryID, true);
  };

  const handleProductChangeOpen = (prodId, val) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        productID: prodId ? prodId.toString() : ""
      };
      CommunityInventoryService.GetOneProductDetail(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setContentLoading(false);
              setVendorProductDetail(responseData.dtProductDetail);
            }
          } else {
            setContentLoading(false);
            setVendorProductDetail(responseData.dtProducts);
          }
          if (val == false) {
          } else setVisible(true);
        })
        .catch((error) => {
          setContentLoading(false);
          console.log(error);
        });
    } catch (error) {
      setContentLoading(false);
      console.log(error);
    }
    setSelectedgfInventoryID(prodId);
    setProductChange(!productChange);
  };

  const handleProductChangeClose = () => {
    setSelectedgfInventoryID(0);
    setProductChange(false);
    setVendorProductDetail([]);
  };

  const columns4 = [
    {
      title: "",
      dataIndex: "imagepath",
      width: 60,
      render: (item, row) => {
        return (
          <React.Fragment>
            <img
              src={row.imagepath ? row.imagepath : ""}
              className="prod__img"
              width="50px"
              alt=""
              onClick={() => {
                setVisibleImagePreview(true);
                setImagePath(row.imagepath);
              }}
            />
          </React.Fragment>
        );
      }
    },
    {
      title: "",
      dataIndex: "view",
      width: 40,
      render: (item, row) => {
        return (
          <React.Fragment>
            <div
              className="infoicon__div"
              onClick={() => handleOpenDetailsModal(row)}
            >
              <i className="fa fa-info-circle linkText"></i>
            </div>
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("Active"),
      dataIndex: "IsActive",
      width: 100,
      render: (item, row) => {
        return (
          <React.Fragment>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.IsActive}
              onChange={(checked) => {
                handleToggleOnChange(checked, row.GFInventoryID);
              }}
            />
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("SKU"),
      dataIndex: "SKU",
      width: 150,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <React.Fragment>
            <span>{row.SKU}</span>
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("Category"),
      dataIndex: "CategoryFormated",
      width: 150,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <React.Fragment>
            <span>{row.CategoryFormated}</span>
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("Metal Type"),
      dataIndex: "MetalTypeFormated",
      width: 100,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <React.Fragment>
            {" "}
            <span>{row.MetalTypeFormated ? row.MetalTypeFormated : " - "}</span>
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("Metal Color"),
      dataIndex: "MetalColorFormated",
      width: 100,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <React.Fragment>
            {" "}
            <span>
              {row.MetalColorFormated ? row.MetalColorFormated : " - "}
            </span>
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("Price"),
      dataIndex: "WholesalePrice",
      width: 100,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <React.Fragment>
            {" "}
            <span>{row.WholesalePrice ? row.CurrenySymbol+ row.WholesalePrice : " - "}</span>
          </React.Fragment>
        );
      }
    }
  ];
  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetOneVendorProduct(
        dealerId,
        vendorId,
        bindWhatVal,
        searchValue,
        priceValue,
        page,
        pageSize,
        sortColumnName,
        sortType
      );
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetOneVendorProduct(
      dealerId,
      vendorId,
      bindWhatVal,
      searchValue,
      priceValue,
      pageNo,
      size,
      sortColumnName,
      sortType
    );
  }

  const handleToggleOnChange = (checked, gfInventoryID) => {
    setActiveDeactiveAll("");
    const temp = vendorProductList.map((x) => {
      if (x.GFInventoryID === gfInventoryID) {
        return { ...x, IsActive: checked };
      } else return x;
    });
    setVendorProductList(temp);
    if (checked) {
      setActiveProductIds((oldArray) => [...oldArray, gfInventoryID]);
      setInActiveProductIds((oldArray) =>
        oldArray.filter((prevState) => prevState !== gfInventoryID)
      );
    } else {
      setInActiveProductIds((oldArray) => [...oldArray, gfInventoryID]);
      setActiveProductIds((oldArray) =>
        oldArray.filter((prevState) => prevState !== gfInventoryID)
      );
    }
  };

  const handleSaveUpdateFlag = () => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        activeproductIds: activeProductIds.toString(),
        deactiveproductIds: inActiveProductIds.toString(),
        flagValue: "AC",
        vendorId: vendorId ? vendorId.toString() : "",
        productType: ""
      };
      CommunityInventoryService.SaveUpdateFlag(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Data Updated Successfully.");
            handleGetOneVendorProduct(
              dealerId,
              vendorId,
              bindWhatVal,
              searchValue,
              priceValue,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
          } else {
            NotificationManager.error("Data Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleActiveDeactiveOnChange = (e) => {
    const temp = vendorProductList.map((x) => {
      if (x.IsActive == checked) {
        return { ...x, IsActive: !checked };
      } else return x;
    });
    setVendorProductList(temp);
    setChecked(!checked);
    setActiveDeactiveAll("All");
  };

  const handleSaveUpdate = () => {
    if (activeDeactiveAll == "All") {
      setVisibleAll(true);
    } else handleSaveUpdateFlag();
  };

  const handleSaveUpdateAll = () => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        value: checked ? "1" : "0",
        vendorId: vendorId ? vendorId.toString() : "",
        productType: ""
      };
      setUpdateFlagLoading(true);
      CommunityInventoryService.SaveUpdateFlagAll(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            setVisibleAll(false);
            setUpdateFlagLoading(false);
            NotificationManager.success("Data Updated Successfully.");
            handleGetOneVendorProduct(
              dealerId,
              vendorId,
              bindWhatVal,
              searchValue,
              priceValue,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
            setActiveDeactiveAll("");
            setChecked(false);
          } else {
            setVisibleAll(false);
            setUpdateFlagLoading(false);
            NotificationManager.error("Data Not Updated.");
            setActiveDeactiveAll("");
            setChecked(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*Custom API Binding Ends*/
  return (
    <React.Fragment>
      <Spin spinning={updateFlagLoading} size="large">
        <div className="prodetail__section">
          <div className="form__fields inventory__form__fields">
            <div className="col-md-12">
              <div className="prod__maindiv prodmarkup__secion retailerprof__section permission__section">
                <div className="row">
                  <div className="col-lg-9 col-md-9">
                    <div className="prodesc__div">
                      <div className="allprod_section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="filter_section mt-0 p-0">
                              <div className="col-md-12 desktop__view">
                                <div className="filt_optndiv">
                                  <div className="gview__div">
                                    <span className="mr-2">
                                      <b>
                                        {" "}
                                        {traslate("Total")}{" "}
                                        {productTotalRecords
                                          ? productTotalRecords
                                          : "0"}{" "}
                                        {traslate("Product")}{" "}
                                      </b>
                                    </span>
                                    <span
                                      className={
                                        viewItem === "grid"
                                          ? "fa fa-th grid_icon active"
                                          : "fa fa-th grid_icon"
                                      }
                                      onClick={() => {
                                        handleViewChange("grid");
                                      }}
                                    ></span>
                                    <span
                                      className={
                                        viewItem === "list"
                                          ? "fa fa-th-list list_icon active"
                                          : "fa fa-th-list list_icon"
                                      }
                                      onClick={() => {
                                        handleViewChange("list");
                                      }}
                                    ></span>
                                  </div>

                                  <div className="search__bar mb-0">
                                    <Search
                                      placeholder="Keyword Or SKU"
                                      allowClear
                                      onChange={(e) =>
                                        SetSearchValue(e.target.value)
                                      }
                                      onSearch={onSearchFunc}
                                      style={{
                                        width: 220
                                      }}
                                      value={searchValue}
                                    />
                                  </div>
                                  <div className="drpdwn__div d-none"></div>
                                </div>
                              </div>

                              <div className="col-md-12 mobile__view">
                                <div className="filt_optndiv">
                                  <div className="gview__div mobile__bottomspace">
                                    <p className="mb-0">
                                      <span
                                        className={
                                          viewItem === "grid"
                                            ? "fa fa-th grid_icon active"
                                            : "fa fa-th grid_icon"
                                        }
                                        onClick={() => {
                                          handleViewChange("grid");
                                        }}
                                      ></span>
                                      <span
                                        className={
                                          viewItem === "list"
                                            ? "fa fa-th-list list_icon active"
                                            : "fa fa-th-list list_icon"
                                        }
                                        onClick={() => {
                                          handleViewChange("list");
                                        }}
                                      ></span>
                                    </p>

                                    <span>
                                      <b>
                                        {" "}
                                        {traslate("Total")}{" "}
                                        {productTotalRecords
                                          ? productTotalRecords
                                          : "0"}{" "}
                                        {traslate("Product")}{" "}
                                      </b>
                                    </span>
                                  </div>

                                  <div className="search__bar mb-0 w-100">
                                    <Search
                                      placeholder="Keyword Or SKU"
                                      allowClear
                                      onChange={(e) =>
                                        SetSearchValue(e.target.value)
                                      }
                                      onSearch={onSearchFunc}
                                      value={searchValue}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-3">
                                <div className="row align-items-center">
                                  <div className="col-md-4">
                                    <div className="deactivation__link">
                                      <a
                                        href="https://platform.stage.jewelcloud.com//Catalog/GuideLines/GemFind%20Product%20Deactivation%20Guide.pdf"
                                        download
                                        target="_blank"
                                      >
                                        {traslate(
                                          "Product Deactivation Guidelines"
                                        )}{" "}
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="filt_optndiv justify-content-end">
                                      <div className="filtbtn__div">
                                        <span className="select__all mobile__bottomspace">
                                          <input
                                            type="checkbox"
                                            id="sel"
                                            value={checked}
                                            checked={checked}
                                            //value={false}
                                            onChange={(e) =>
                                              handleActiveDeactiveOnChange(e)
                                            }
                                          />
                                          <label for="sel">
                                            {" "}
                                            {traslate(
                                              "Activate/Deactivate All"
                                            )}{" "}
                                            {productTotalRecords
                                              ? productTotalRecords
                                              : "0"}{" "}
                                            {traslate("Products")}
                                          </label>
                                        </span>
                                      </div>

                                      <button
                                        className="primary-btn ml-2"
                                        onClick={handleHideShowFilter}
                                      >
                                        <img src={Filter_icon} alt="" />
                                        {hideShowFilter ? (
                                          <span>
                                            {traslate("Hide Filter")}{" "}
                                            <i className="fa fa-angle-up"></i>
                                          </span>
                                        ) : (
                                          <span>
                                            {traslate("Show Filter")}{" "}
                                            <i className="fa fa-angle-down"></i>
                                          </span>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <Spin spinning={contentLoading} size="large">
                                  <div
                                    className={
                                      hideShowFilter
                                        ? "form__fields filter_inpsection"
                                        : "form__fields filter_inpsection hide"
                                    }
                                  >
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                          <div className="input__block">
                                            <label>
                                              {" "}
                                              {traslate("Product Type")}{" "}
                                            </label>
                                            <Select
                                              showSearch
                                              mode="multiple"
                                              showArrow
                                              className="border__grey"
                                              placeholder="Select Product Type"
                                              optionFilterProp="children"
                                              value={
                                                ManageInventoryState.productType
                                              }
                                              onChange={(e) =>
                                                onChangeMultiDrop(
                                                  e,
                                                  "productType"
                                                )
                                              }
                                              onSearch={onSearch}
                                              allowClear
                                            >
                                              {ProductTypeList.length > 0 &&
                                                ProductTypeList.map(
                                                  (item, i) => {
                                                    return (
                                                      <Option
                                                        value={item.ProductType}
                                                      >
                                                        {item.Text} (
                                                        {item.ProductCount})
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                            </Select>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                          <div className="input__block">
                                            <label>
                                              {" "}
                                              {traslate("Categories")}{" "}
                                            </label>
                                            <Select
                                              showSearch
                                              showArrow
                                              mode="multiple"
                                              className="border__grey"
                                              placeholder="Select Categories"
                                              optionFilterProp="children"
                                              value={
                                                ManageInventoryState.category
                                              }
                                              onChange={(e) =>
                                                onChangeMultiDrop(e, "category")
                                              }
                                              onSearch={onSearch}
                                              allowClear
                                            >
                                              {CategoriesList.length > 0 &&
                                                CategoriesList.map(
                                                  (item, i) => {
                                                    return (
                                                      <Option
                                                        value={
                                                          item.JewelryCategoryID
                                                        }
                                                      >
                                                        {item.JewelryCategory} (
                                                        {item.ProductCount})
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                            </Select>
                                          </div>
                                        </div>
                                        {paramIsShow && (
                                          <div className="col-lg-4 col-md-6">
                                            <div className="input__block">
                                              <label>
                                                {" "}
                                                {traslate("Collection")}{" "}
                                              </label>
                                              <Select
                                                showSearch
                                                showArrow
                                                mode="multiple"
                                                className="border__grey"
                                                placeholder="Select Collection"
                                                optionFilterProp="children"
                                                value={
                                                  ManageInventoryState.collection
                                                }
                                                onChange={(e) =>
                                                  onChangeMultiDrop(
                                                    e,
                                                    "collection"
                                                  )
                                                }
                                                onSearch={onSearch}
                                                allowClear
                                              >
                                                {CollectionList.length > 0 &&
                                                  CollectionList.map(
                                                    (item, i) => {
                                                      return (
                                                        <Option
                                                          value={
                                                            item.CollectionNameId
                                                          }
                                                        >
                                                          {item.CollectionNames}{" "}
                                                          ({item.ProductCount})
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </div>
                                          </div>
                                        )}

                                        <div className="col-lg-4 col-md-6">
                                          <div className="input__block">
                                            <label>
                                              {" "}
                                              {traslate("Metal Type")}{" "}
                                            </label>
                                            <Select
                                              showSearch
                                              showArrow
                                              mode="multiple"
                                              className="border__grey"
                                              placeholder="Select Metal Type"
                                              optionFilterProp="children"
                                              value={
                                                ManageInventoryState.metalType
                                              }
                                              onChange={(e) =>
                                                onChangeMultiDrop(
                                                  e,
                                                  "metalType"
                                                )
                                              }
                                              onSearch={onSearch}
                                              allowClear
                                            >
                                              {MetalTypeList.map((item, i) => {
                                                return (
                                                  <Option
                                                    value={item.MetalTypeID}
                                                  >
                                                    {item.MetalType} (
                                                    {item.ProductCount})
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6">
                                          <div className="input__block">
                                            <label>
                                              {" "}
                                              {traslate("Metal Color")}{" "}
                                            </label>
                                            <Select
                                              showSearch
                                              showArrow
                                              mode="multiple"
                                              className="border__grey"
                                              placeholder="Select Metal Color"
                                              optionFilterProp="children"
                                              value={
                                                ManageInventoryState.metalColor
                                              }
                                              onChange={(e) =>
                                                onChangeMultiDrop(
                                                  e,
                                                  "metalColor"
                                                )
                                              }
                                              onSearch={onSearch}
                                              allowClear
                                            >
                                              {MetalColorList.map((item, i) => {
                                                return (
                                                  <Option
                                                    value={item.MetalColorID}
                                                  >
                                                    {item.MetalColor} (
                                                    {item.ProductCount})
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                          <div className="input__block">
                                            <label>
                                              {" "}
                                              {traslate("Gemstone")}{" "}
                                            </label>
                                            <Select
                                              showSearch
                                              showArrow
                                              mode="multiple"
                                              className="border__grey"
                                              placeholder="Select Gemstone"
                                              optionFilterProp="children"
                                              value={
                                                ManageInventoryState.gemstoneType
                                              }
                                              onChange={(e) =>
                                                onChangeMultiDrop(
                                                  e,
                                                  "gemstoneType"
                                                )
                                              }
                                              onSearch={onSearch}
                                              allowClear
                                            >
                                              {GemstoneList.map((item, i) => {
                                                return (
                                                  <Option
                                                    value={item.GemstoneTypeID}
                                                  >
                                                    {item.GemstoneType} (
                                                    {item.ProductCount})
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6">
                                          <div className="input__block">
                                            <label> {traslate("Price")} </label>
                                            <div className="col-md-12 mt-3">
                                              <Slider
                                                range
                                                value={priceValue}
                                                min={
                                                  ManageInventoryState.priceMin
                                                    ? ManageInventoryState.priceMin
                                                    : 0
                                                }
                                                max={
                                                  ManageInventoryState.priceMax
                                                    ? ManageInventoryState.priceMax
                                                    : 0
                                                }
                                                onChange={(e) => {
                                                  setPriceValue(e);
                                                }}
                                                onAfterChange={(e) => {
                                                  handleGetOneVendorProduct(
                                                    dealerId,
                                                    vendorId,
                                                    bindWhatVal,
                                                    searchValue,
                                                    e,
                                                    1,
                                                    pageSize,
                                                    sortColumnName,
                                                    sortType
                                                  );
                                                }}
                                              />
                                              <div className="rangeSlider__values">
                                                <div className="input__block">
                                                  <input
                                                    type="text"
                                                    disabled="true"
                                                    value={
                                                      currencySymbol +
                                                      priceValue[0].toLocaleString(
                                                        "en-US"
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div>
                                                  <img
                                                    src={space__icon}
                                                    className="img-fluid space__icon"
                                                  />
                                                </div>
                                                <div className="input__block">
                                                  <input
                                                    type="text"
                                                    disabled="true"
                                                    value={
                                                      currencySymbol +
                                                      priceValue[1].toLocaleString(
                                                        "en-US"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12 filtbtn_div">
                                          <button
                                            className="primary-btn mr-2"
                                            onClick={onResetFilter}
                                          >
                                            {" "}
                                            {traslate("Reset Filters")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Spin>
                              </div>

                              {viewItem === "grid" && (
                                <React.Fragment>
                                  <Spin spinning={contentLoading} size="large">
                                    <div className="col-md-12">
                                      <hr className="mt-4 mb-4" />
                                      <div className="prod__section">
                                        <div className="row">
                                          {vendorProductList.length > 0 &&
                                            vendorProductList.map((item) => {
                                              return (
                                                <div className="col-lg-4 col-md-6 mb-4">
                                                  <div className="prod__maindiv">
                                                    <div className="chkbx__div">
                                                      <input
                                                        type="checkbox"
                                                        value={item.IsActive}
                                                        checked={item.IsActive}
                                                        onChange={(e) => {
                                                          handleToggleOnChange(
                                                            e.target.checked,
                                                            item.GFInventoryID
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="prodimg__div">
                                                      <img
                                                        src={
                                                          item.imagepath
                                                            ? item.imagepath
                                                            : ""
                                                        }
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="details__div">
                                                      <span className="style__txt">
                                                        SKU No: {item.SKU}
                                                      </span>
                                                      <span className="price__txt">
                                                        {item.WholesalePrice
                                                          ? item.CurrenySymbol + " " +
                                                            item.WholesalePrice
                                                          : " - "}
                                                      </span>
                                                    </div>
                                                    {item.GFInventoryID !=
                                                    selectedgfInventoryID ? (
                                                      <div
                                                        className="info__div"
                                                        onClick={() => {
                                                          handleProductChangeOpen(
                                                            item.GFInventoryID,
                                                            false
                                                          );
                                                        }}
                                                      >
                                                        <img
                                                          src={Info_icon}
                                                          alt=""
                                                        />
                                                      </div>
                                                    ) : item.GFInventoryID ==
                                                      selectedgfInventoryID ? (
                                                      vendorProductDetail.map(
                                                        (item) => {
                                                          return (
                                                            <div className="descr__div">
                                                              <div className="overlay__block">
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "Style"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {
                                                                      item.StyleNo
                                                                    }
                                                                  </span>
                                                                </p>
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "Collection"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {item.Coll}
                                                                  </span>
                                                                </p>
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "Metal Type"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {
                                                                      item.MetalType
                                                                    }
                                                                  </span>
                                                                </p>
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "Metal Color"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {
                                                                      item.MetalColor
                                                                    }
                                                                  </span>
                                                                </p>
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "Retail Price"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {item.RetailPrice ==
                                                                      "0" ||
                                                                    item.RetailPrice ==
                                                                      "N/A"
                                                                      ? " - "
                                                                      : item.RetailPrice
                                                                      ? item.Currency + item.CurrenySymbol +"  "+ item.RetailPrice
                                                                      : " - "}
                                                                  </span>
                                                                </p>
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "MSRP Price"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {item.MSRP ==
                                                                      "0" ||item.MSRP == "N/A"
                                                                      ? " - "
                                                                      : item.MSRP
                                                                      ? item.Currency + item.CurrenySymbol +"  "+ item.MSRP
                                                                      : " - "}
                                                                  </span>
                                                                </p>
                                                                <p className="manageInv-info-sp">
                                                                  <span>
                                                                    {" "}
                                                                    {traslate(
                                                                      "Cost Price"
                                                                    )}{" "}
                                                                  </span>
                                                                  <span>:</span>
                                                                  <span>
                                                                    {item.WholesalePrice ==
                                                                    "0" ||
                                                                    item.WholesalePrice == "N/A"
                                                                      ? " - "
                                                                      : item.WholesalePrice
                                                                      ?item.Currency + item.CurrenySymbol +"  "+ item.WholesalePrice
                                                                      : " - "}
                                                                  </span>
                                                                </p>
                                                              </div>
                                                              <div
                                                                className="close__div"
                                                                onClick={
                                                                  handleProductChangeClose
                                                                }
                                                              >
                                                                <img
                                                                  src={Close}
                                                                  alt=""
                                                                />
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    ) : (
                                                      <div className="descr__div">
                                                        <div className="overlay__block">
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "Style"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "Collection"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "Metal Type"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "Metal Color"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "Retail Price"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "MSRP Price"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                          <p className="manageInv-info-sp">
                                                            <span>
                                                              {" "}
                                                              {traslate(
                                                                "Cost Price"
                                                              )}{" "}
                                                            </span>
                                                            <span>:</span>
                                                            <span>N/A</span>
                                                          </p>
                                                        </div>
                                                        <div
                                                          className="close__div"
                                                          onClick={
                                                            handleProductChangeClose
                                                          }
                                                        >
                                                          <img
                                                            src={Close}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  </Spin>
                                </React.Fragment>
                              )}
                              {viewItem === "list" && (
                                <div className="col-md-12 mt-3">
                                  <div className="lviewprod__tbl info__tooltip__table">
                                    <Spin spinning={contentLoading}>
                                      <Table
                                        columns={columns4}
                                        dataSource={vendorProductList}
                                        scroll={{ y: 800 }}
                                        pagination={false}
                                        onChange={handleTableASCDES}
                                      />
                                    </Spin>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-12">
                                <Pagination
                                  style={{ marginTop: "30px" }}
                                  total={productTotalRecords}
                                  current={pageNo}
                                  pageSize={pageSize}
                                  pageSizeOptions={[10, 20, 50, 100]}
                                  responsive={true}
                                  showSizeChanger={true}
                                  onChange={handlePageNoChange}
                                  onShowSizeChange={handlePageSizeChange}
                                />
                              </div>

                              <div className="col-md-12">
                                <div className="save__btn mt-3">
                                  <button
                                    className="primary-btn"
                                    onClick={handleSaveUpdate}
                                  >
                                    {traslate("Save Changes")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3">
                    <Spin spinning={sideProfileLoading}>
                      <div className="right__section">
                        <div className="profile__block">
                          <span className="linkText">
                            {rowDetails.DesignerName
                              ? rowDetails.DesignerName
                              : null}
                          </span>
                          <div className="logoImg__block p-0">
                            <img src={rowDetails.DesignerLogo} />
                          </div>
                          <p className="activeFrom">
                            {traslate("Updated")} |{" "}
                            {rowDetails.Modified ? rowDetails.Modified : null}
                          </p>

                          <div className="star-ratings">
                            <p className="mb-0"> {traslate("Data Ratings")} </p>
                            <StarRatings
                              rating={
                                rowDetails.RatingCount
                                  ? rowDetails.RatingCount
                                  : 0
                              }
                              // rating={4}
                              starRatedColor="#1883b8"
                              starDimension="18px"
                              starSpacing="0px"
                              numberOfStars={5}
                            />
                          </div>

                          <div>
                            <p>
                              {" "}
                              {rowDetails.Status
                                ? rowDetails.Status
                                : null}{" "}
                            </p>
                          </div>
                          {rowDetails.Status == "Connected" ? null : (
                            <div className="input__block">
                              <button
                                className="primary-btn"
                                onClick={() => {
                                  setIsModalSendInvitationVisible(true);
                                  handleGeneratePopup(dealerId);
                                }}
                              >
                                {rowDetails.Action
                                  ? rowDetails.Action
                                  : "Request Update"}
                              </button>
                            </div>
                          )}
                        </div>

                        <div className="links__block">
                          <ul>
                            <li
                              onClick={() => {
                                rowDetails.Status == "Connected"
                                  ? localStorage.setItem(
                                      "vendorDataForCollection",
                                      rowDetails.txtDesignerName
                                    )
                                  : NotificationManager.error(
                                      "Please connect this vendor"
                                    );
                              }}
                            >
                              <Link
                                to={
                                  // location.state
                                  //   ? location.state.previousPage
                                  //     ? location.state.previousPage ==
                                  //       "RingBuilder"
                                  //       ? "/myVendorsRingBuilder"
                                  //       : "/myvendors"
                                  //     : "/myvendors"
                                  //   : "/myvendors"
                                  rowDetails.Status == "Connected" &&
                                  "/manageCollection"
                                }
                              >
                                {rowDetails.Collections
                                  ? rowDetails.Collections
                                  : null}{" "}
                                {traslate("Collections")}{" "}
                              </Link>
                            </li>
                            <li>
                              <span className="linkText">
                                {rowDetails.items1 ? rowDetails.items1 : "0"}{" "}
                                {traslate("Items")}{" "}
                              </span>
                            </li>
                          </ul>
                          <hr />
                        </div>

                        <div className="retailer__dtls mb-4">
                          <p>
                            {" "}
                            {rowDetails.DealerName
                              ? rowDetails.DealerName
                              : null}{" "}
                          </p>
                          <a href={`mailto:${rowDetails.DealerEmail}`}>
                            {rowDetails.DealerEmail
                              ? rowDetails.DealerEmail
                              : null}
                          </a>
                          <p>
                            {rowDetails.DealerPhone
                              ? rowDetails.DealerPhone
                              : null}
                          </p>
                          <span
                            className="linkText"
                            onClick={() => openInNewTab()}
                          >
                            {" "}
                            {rowDetails.website}
                          </span>
                          <hr />
                        </div>
                      </div>
                    </Spin>
                  </div>
                  <div className="col-lg-12">
                    <Modal
                      className="modalretailerprof__section"
                      centered
                      visible={visibleAll}
                      onOk={() => setVisibleAll(false)}
                      onCancel={() => setVisibleAll(false)}
                      footer={[
                        <Button key="back" onClick={handleCancelWarning}>
                          {traslate("No")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleSaveUpdateAll}
                        >
                          {traslate("Yes")}
                        </Button>
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12">
                                  <span>
                                    Warning: By Choosing "Yes" you are
                                    confirming that you would like to{" "}
                                    {checked ? "ACTIVATE" : "DEACTIVATE"} ALL of
                                    your products from this Jewelry Vendor.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      title={
                        page == "RetailerAffirmation"
                          ? traslate("Retailer Affirmation")
                          : traslate("Send Email")
                      }
                      visible={isModalSendInvitationVisible}
                      className="forgot__password"
                      onCancel={handleSendInvitationModalCancel}
                      footer={[
                        //
                        <div
                          className={
                            page == "RetailerAffirmation" ? "d-block" : "d-none"
                          }
                        >
                          <Button
                            key="submit"
                            className={
                              checkbox == "" ? "disabled-btn" : "primary-btn"
                            }
                            disabled={checkbox == "" ? true : false}
                            onClick={
                              checkbox == "CurrentAuthorizedReseller"
                                ? handleShowEmailWindow
                                : checkbox == "NewAccountRequest"
                                ? handleNewAccountRequest
                                : handleCheckBoxValidation
                            }
                          >
                            {traslate("Submit")}
                          </Button>
                        </div>,
                        <div
                          className={page == "SendEmail" ? "d-block" : "d-none"}
                        >
                          <Button
                            className="primary-btn"
                            key="submit"
                            onClick={handleSendEmail}
                          >
                            {traslate("Send")}
                          </Button>
                        </div>
                      ]}
                    >
                      <div className="form__fields p-0 border-0 mh-auto">
                        {page == "RetailerAffirmation" ? (
                          <div>
                            {category.map((item) => {
                              return (
                                <Fragment>
                                  <div className="radio__block">
                                    <Checkbox
                                      key={item.value}
                                      onChange={handleCheckBoxChange}
                                      checked={item.value == checkbox}
                                      value={item.value}
                                    >
                                      <label> {traslate(item.label)} </label>
                                    </Checkbox>
                                  </div>
                                  <div>
                                    <p> {item.note} </p>
                                  </div>
                                </Fragment>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="editor__section">
                            <Spin spinning={emailLoading}>
                              <CKEditor
                                editor={ClassicEditor}
                                data={emailModalContent}
                                onReady={(editor) => {}}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setEmailModalContent(data);
                                }}
                              />
                            </Spin>
                          </div>
                        )}
                      </div>
                    </Modal>

                    {/* Inventory table modal */}

                    <Modal
                      title={traslate("Image Preview")}
                      centered
                      visible={visibleImagePreview}
                      onOk={() => setVisibleImagePreview(false)}
                      onCancel={() => setVisibleImagePreview(false)}
                      width={400}
                      footer={false}
                    >
                      <div className="form__fields mh-auto">
                        <img src={imagePath} className="img-fluid" />
                      </div>
                    </Modal>

                    {/* Appointment Schedule Modal */}

                    <Modal
                      className="emailTemplateEditorModal"
                      title={traslate("Send Email")}
                      centered
                      visible={visibleEmailModal}
                      onOk={() => setVisibleEmailModal(false)}
                      onCancel={() => setVisibleEmailModal(false)}
                      width={800}
                      hieght={600}
                      footer={[
                        <Button key="back" onClick={handleSendEmailCancel}>
                          {traslate("Cancel")}
                        </Button>,
                        <Button key="Save" onClick={handleSendEmailDetails}>
                          {traslate("Save")}
                        </Button>
                      ]}
                    >
                      <div className="editor__section">
                        <Spin spinning={contentLoading}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={contentAppData}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setContentAppData(data);
                            }}
                          />
                        </Spin>
                      </div>
                    </Modal>

                    <Modal
                      title={traslate("View Details")}
                      className="modalvdiamond__section"
                      centered
                      visible={visible}
                      onOk={() => setVisible(false)}
                      onCancel={() => setVisible(false)}
                      width={700}
                      footer={[
                        <Button key="back" onClick={handleCancel}>
                          {traslate("Cancel")}
                        </Button>
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-5 mt-4 text-center">
                                  <div className="viewdtls__left">
                                    <img
                                      src={
                                        vendorProductDetail &&
                                        vendorProductDetail[0] &&
                                        vendorProductDetail[0].ImagePath
                                          ? vendorProductDetail[0].ImagePath
                                          : ""
                                      }
                                      className="img-fluid"
                                      alt="img-diamond"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-7 mt-4">
                                  <div className="info__div">
                                    <div className="manage__inventory">
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Product Name")}:
                                        </span>
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].ProductName
                                            ? vendorProductDetail[0].ProductName
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Product Description")}:
                                        </span>
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].ProductDesc
                                            ? vendorProductDetail[0].ProductDesc
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Style")}#:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].StyleNo
                                            ? vendorProductDetail[0].StyleNo
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Collection")}:
                                        </span>
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].Coll
                                            ? vendorProductDetail[0].Coll
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Metal Type ")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].MetalType
                                            ? vendorProductDetail[0].MetalType
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Metal Color")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].MetalColor
                                            ? vendorProductDetail[0].MetalColor
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Retail Price")} :
                                        </span>{" "}
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].RetailPrice&&
                                          vendorProductDetail[0].RetailPrice !== '0' &&
                                          vendorProductDetail[0].RetailPrice !== 'N/A'
                                            ?vendorProductDetail[0].Currency+ vendorProductDetail[0].CurrenySymbol+ " " + vendorProductDetail[0].RetailPrice
                                            : " - "}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("MSRP Price")}:{" "}
                                        </span>{" "}
                                        <span className="val__txt">
                                          {vendorProductDetail &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].MSRP &&
                                          vendorProductDetail[0].MSRP !== '0' && vendorProductDetail[0].MSRP !== 'N/A'
                                            ? vendorProductDetail[0].Currency+ vendorProductDetail[0].CurrenySymbol+ " " +  vendorProductDetail[0].MSRP
                                            : " - "}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {traslate("Cost Price")} :{" "}
                                        </span>

                                        <span className="val__txt">
                                          {vendorProductDetail?.[0] &&
                                          vendorProductDetail[0] &&
                                          vendorProductDetail[0].WholesalePrice &&
                                          vendorProductDetail[0].WholesalePrice !== '0' && vendorProductDetail[0].WholesalePrice !== 'N/A'
                                            ? vendorProductDetail?.[0]?.Currency+ vendorProductDetail?.[0]?.CurrenySymbol+ " "  +vendorProductDetail?.[0]?.WholesalePrice
                                            :" - "}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default ManageInventory;
