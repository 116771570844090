import React, { useEffect, useState } from "react";
import { Select, Button, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const Retailerlocator = () => {
  const [dealerList, setDealerList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dealerId, setDealerId] = useState(null);
  const [type, setType] = useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    handleFillDealerDropDown();
  }, []);
  const onChange = (value) => {
    setDealerId(value);
    setIsError(false);
    handleFillDealerDropDown();
    handleGetMasterlinkThemvalue(value);
  };
  const onRedioChange = (value) => {
    setType(value.target.value);
  };

  const handleGetMasterlinkThemvalue = (value) => {
    let inputData = {
      dealerID: value,
    };
    setloading(true);
    AdminToolsDataService.GetMasterlinkThemvalue(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData[0].masterLinkTheme;
        if (message === "Success") {
          setType(responseData);
          setloading(false);
        } else {
          setType(0);
          setloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };

  const handleFillDealerDropDown = () => {
    try {
      AdminToolsDataService.FillDealerDropDown()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveMasterLinkTheme = () => {
    try {
      if (!dealerId) {
        setIsError(true);
        return false;
      }
      let inputData = {
        dealerID: dealerId,
        logoImage: parseInt(type),
      };
      setSubmitLoading(true);
      AdminToolsDataService.SaveMasterLinkTheme(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success(
              "MasterLinkTheme Has Been Updated Successfully."
            );
            setType();
            setDealerId(null);
          } else {
            NotificationManager.error("MasterLinkTheme Not Updated.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="retailerloc__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {" "}
                {traslate("Admin Preferences")}{" "}
              </h4>
            </div>

            <div className="col-lg-3 col-md-6 mt-4 mobile-m-0">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("Select Dealer")} <span>*</span>
                </label>
                <Select
                  showSearch
                  className={isError ? "border__red" : "border__grey"}
                  placeholder="Select Dealer"
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={dealerId}
                  onChange={onChange}
                >
                  <Option value=""> {traslate("Select Dealer")} </Option>
                  {dealerList.map((item) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " (" + item.dealerID + ")"}
                      </Option>
                    );
                  })}
                </Select>
                {isError ? (
                  <span className="error-color-red"> {traslate("Please Select Dealer.")} </span>
                ) : null}
              </div>
            </div>
            {dealerId ? (
              <React.Fragment>
                <Spin spinning={loading} size="large">
                  <div className="col-lg-12 mt-2">
                    <div className="col-lg-12">
                      <p className="subheading">
                        {traslate("MasterLink Theme")}
                      </p>
                    </div>
                    <div className="radioinp__maindiv row">
                      <div className="col-md-2 d-flex align-items-center">
                        <input
                          type="radio"
                          id="dr"
                          name="mt"
                          className="mr-1"
                          value={0}
                          onChange={onRedioChange}
                          checked={type == 0 ? true : false}
                        />
                        <label htmlFor="dr">{traslate("Default Theme")}</label>
                      </div>
                      <div className="col-md-3 d-flex align-items-center">
                        <input
                          type="radio"
                          id="jr"
                          name="mt"
                          className="mr-1"
                          value={1}
                          onChange={onRedioChange}
                          checked={type == 1 ? true : false}
                        />
                        <label htmlFor="jr">
                          {traslate("Retailer Locater Black Theme")}
                        </label>
                      </div>
                    </div>
                  </div>
                </Spin>
                <div className="col-lg-6 mt-4">
                  <Button
                    className="primary-btn"
                    onClick={handleSaveMasterLinkTheme}
                    loading={submitLoading}
                  >
                    {traslate("Save")}
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Retailerlocator;
