import React, { useEffect, useState } from "react";
import { Select, Button } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
const { Option } = Select;

const Legacy = () => {
  const initialState = {
    addDealerAllGFLinks: null,
    removeDealerAllGFLinks: null,
    addDealerAllGFLinksLabDiamonds: null,
    removeDealerAllGFLinksLabDiamonds: null,
    errorMessage: {},
    loading: {},
  };
  const [state, setState] = useState(initialState);
  const [dealerIDList, setDealerIDList] = useState([]);

  useEffect(() => {
    handleGetGFLinkDealer();
  }, []);

  function handleGetGFLinkDealer() {
    AdminToolsDataService.GetGFLinkDealer()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setDealerIDList(responseData);
        } else {
          setDealerIDList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function handleAddGFLinksDealer() {
    const tempState = state;
    if (!tempState.addDealerAllGFLinks) {
      tempState["errorMessage"].addDealerAllGFLinks = true;
      tempState["loading"].addDealerAllGFLinks = false;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));

      return false;
    } else {
      tempState["errorMessage"].addDealerAllGFLinks = false;
      tempState["loading"].addDealerAllGFLinks = true;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
    }

    let inputData = {
      dealerID: tempState.addDealerAllGFLinks,
    };
    AdminToolsDataService.AddGFLinksDealer(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          tempState["addDealerAllGFLinks"] = null;
          NotificationManager.success(
            "Diamond Vendor Has Been Added To All Diamond Links"
          );
        } else {
          NotificationManager.success(
            "Diamond Vendor Has Not Been Added To All Diamond Links"
          );
        }
        tempState["errorMessage"].addDealerAllGFLinks = false;
        tempState["loading"].addDealerAllGFLinks = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
      })
      .catch((error) => {
        tempState["errorMessage"].addDealerAllGFLinks = true;
        tempState["loading"].addDealerAllGFLinks = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
        console.log(error);
      });
  }
  function handleAddGFLinksLabDiamondDealer() {
    const tempState = state;
    if (!tempState.addDealerAllGFLinksLabDiamonds) {
      tempState["errorMessage"].addDealerAllGFLinksLabDiamonds = true;
      tempState["loading"].addDealerAllGFLinksLabDiamonds = false;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
      return false;
    } else {
      tempState["errorMessage"].addDealerAllGFLinksLabDiamonds = false;
      tempState["loading"].addDealerAllGFLinksLabDiamonds = true;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
    }

    let inputData = {
      dealerID: tempState.addDealerAllGFLinksLabDiamonds,
    };
    AdminToolsDataService.AddGFLinksLabDiamondDealer(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          tempState["addDealerAllGFLinksLabDiamonds"] = null;
          NotificationManager.success(
            "Diamond Vendor Has Been Added To All Diamond Links"
          );
        } else {
          NotificationManager.error(
            "Diamond Vendor Has Not Been Added To All Diamond Links"
          );
        }
        tempState["errorMessage"].addDealerAllGFLinksLabDiamonds = false;
        tempState["loading"].addDealerAllGFLinksLabDiamonds = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
      })
      .catch((error) => {
        tempState["errorMessage"].addDealerAllGFLinksLabDiamonds = true;
        tempState["loading"].addDealerAllGFLinksLabDiamonds = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
        console.log(error);
      });
  }
  function handleRemoveGFLinksDealer() {
    const tempState = state;
    if (!tempState.removeDealerAllGFLinks) {
      tempState["errorMessage"].removeDealerAllGFLinks = true;
      tempState["loading"].removeDealerAllGFLinks = false;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
      return false;
    } else {
      tempState["errorMessage"].removeDealerAllGFLinks = false;
      tempState["loading"].removeDealerAllGFLinks = true;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
    }

    let inputData = {
      dealerID: tempState.removeDealerAllGFLinks,
    };
    AdminToolsDataService.RemoveGFLinksDealer(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          tempState["removeDealerAllGFLinks"] = null;
          NotificationManager.success(
            "Diamond Vendor Has Been Removed From All Diamond Links"
          );
        } else {
          NotificationManager.error(
            "Diamond Vendor Has Not Been Removed From All Diamond Links"
          );
        }
        tempState["errorMessage"].removeDealerAllGFLinks = false;
        tempState["loading"].removeDealerAllGFLinks = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function handleRemoveGFLinksLabDiamondDealer() {
    const tempState = state;
    if (!tempState.removeDealerAllGFLinksLabDiamonds) {
      tempState["errorMessage"].removeDealerAllGFLinksLabDiamonds = true;
      tempState["loading"].removeDealerAllGFLinksLabDiamonds = false;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
      return false;
    } else {
      tempState["errorMessage"].removeDealerAllGFLinksLabDiamonds = false;
      tempState["loading"].removeDealerAllGFLinksLabDiamonds = true;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
    }

    let inputData = {
      dealerID: tempState.removeDealerAllGFLinksLabDiamonds,
    };
    AdminToolsDataService.RemoveGFLinksLabDiamondDealer(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          tempState["removeDealerAllGFLinksLabDiamonds"] = null;
          NotificationManager.success(
            "Diamond Vendor Has Been Removed From All Diamond Links"
          );
        } else {
          NotificationManager.success(
            "Diamond Vendor Has Not Been Removed From All Diamond Links"
          );
        }
        tempState["errorMessage"].removeDealerAllGFLinksLabDiamonds = false;
        tempState["loading"].removeDealerAllGFLinksLabDiamonds = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
      })
      .catch((error) => {
        tempState["errorMessage"].removeDealerAllGFLinksLabDiamonds = true;
        tempState["loading"].removeDealerAllGFLinksLabDiamonds = false;
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
        console.log(error);
      });
  }

  function onChange(name, e) {
    let tempState = state;
    if (e.target) {
      tempState[name] = e.target.value;
      tempState["errorMessage"][name] = false;
    } else {
      tempState[name] = e;
      tempState["errorMessage"][name] = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  return (
    <React.Fragment>
      <div className="legacy_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Administration Tools")}{" "}
                <span>
                  {traslate(
                    "(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))"
                  )}
                </span>
              </h4>
            </div>

            <div className="col-lg-12 mt-4 mobile-m-0">
              <span>
                <b> {traslate("Add a Dealer to all GFLinks:")} </b>{" "}
                {traslate("Select the DealerID you want to")}{" "}
                <b> {traslate("Add")} </b> {traslate("to all GFLinks")}.
              </span>
            </div>

            <div className="col-lg-5 mt-2">
              <div className="row">
                <div className="col-lg-8">
                  <div className="input__block mb-0">
                    <label>
                      {traslate("Select Dealer")} <span>*</span>
                    </label>
                    <Select
                      showSearch
                      className={
                        state.errorMessage["addDealerAllGFLinks"]
                          ? "border__red"
                          : "border__grey"
                      }
                      placeholder="No Dealer Selected"
                      optionFilterProp="children"
                      notFoundContent="No Data Found"
                      value={state.addDealerAllGFLinks}
                      onChange={(e) => onChange("addDealerAllGFLinks", e)}
                    >
                      <Option value=""> No Dealer Selected </Option>
                      {dealerIDList.map((item, i) => {
                        return (
                          <Option key={i} value={item.dealerID}>
                            {item.dealerName + " (" + item.dealerID + " )"}
                          </Option>
                        );
                      })}
                    </Select>
                    {state.errorMessage["addDealerAllGFLinks"] ? (
                      <span className="error-color-red">
                        {traslate("Please Select Dealer.")}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 mt-lg-3">
                  <Button
                    className="primary-btn mt-3"
                    onClick={handleAddGFLinksDealer}
                    loading={state.loading.addDealerAllGFLinks}
                  >
                    {traslate("Add DealerID")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5 mobile-m-0">
              <span>
                <b> {traslate("Remove a Dealer from all GFLinks:")}</b>{" "}
                {traslate("Select the DealerID you want to")}{" "}
                <b>{traslate("Remove")}</b> {traslate("to all GFLinks")}.
              </span>
            </div>

            <div className="col-lg-5 mt-2">
              <div className="row">
                <div className="col-lg-8">
                  <div className="input__block mb-0">
                    <label>
                      {" "}
                      {traslate("Select Dealer")} <span>*</span>
                    </label>
                    <Select
                      showSearch
                      className={
                        state.errorMessage["removeDealerAllGFLinks"]
                          ? "border__red"
                          : "border__grey"
                      }
                      placeholder="No Dealer Selected"
                      optionFilterProp="children"
                      value={state.removeDealerAllGFLinks}
                      onChange={(e) => onChange("removeDealerAllGFLinks", e)}
                    >
                      <Option value=""> No Dealer Selected </Option>
                      {dealerIDList.map((item, j) => {
                        return (
                          <Option key={j} value={item.dealerID}>
                            {item.dealerName + "(" + item.dealerID + ")"}
                          </Option>
                        );
                      })}
                    </Select>
                    {state.errorMessage["removeDealerAllGFLinks"] ? (
                      <span className="error-color-red">
                        {traslate("Please Select Dealer.")}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 mt-lg-3">
                  <Button
                    className="primary-btn mt-3"
                    loading={state.loading.removeDealerAllGFLinks}
                    onClick={handleRemoveGFLinksDealer}
                  >
                    {traslate("Remove DealerID")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5 mobile-m-0">
              <span>
                <b> {traslate("Add a Dealer to all GFLinks Lab Diamonds:")}</b>{" "}
                {traslate("Select the DealerID you want to")}{" "}
                <b>{traslate("Add")}</b>{" "}
                {traslate("to all GFLinks Lab Diamonds")}.
              </span>
            </div>

            <div className="col-lg-5 mt-2">
              <div className="row">
                <div className="col-lg-8">
                  <div className="input__block mb-0">
                    <label>
                      {" "}
                      {traslate("Select Dealer")} <span>*</span>
                    </label>
                    <Select
                      showSearch
                      className={
                        state.errorMessage["addDealerAllGFLinksLabDiamonds"]
                          ? "border__red"
                          : "border__grey"
                      }
                      placeholder="No Dealer Selected"
                      optionFilterProp="children"
                      notFoundContent="No Data Found"
                      value={state.addDealerAllGFLinksLabDiamonds}
                      onChange={(e) =>
                        onChange("addDealerAllGFLinksLabDiamonds", e)
                      }
                    >
                      <Option value=""> No Dealer Selected </Option>
                      {dealerIDList.map((item, m) => {
                        return (
                          <Option key={m} value={item.dealerID}>
                            {item.dealerName + "(" + item.dealerID + ")"}
                          </Option>
                        );
                      })}
                    </Select>
                    {state.errorMessage["addDealerAllGFLinksLabDiamonds"] ? (
                      <span className="error-color-red">
                        {traslate("Please Select Dealer.")}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 mt-lg-3">
                  <Button
                    className="primary-btn mt-3"
                    loading={state.loading.addDealerAllGFLinksLabDiamonds}
                    onClick={handleAddGFLinksLabDiamondDealer}
                  >
                    {traslate("Add DealerID")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5 mobile-m-0">
              <span>
                <b>
                  {" "}
                  {traslate("Remove a Dealer from all GFLinks Lab Diamonds:")}
                </b>{" "}
                {traslate("Select the DealerID you want to")}{" "}
                <b> {traslate("Remove")}</b>{" "}
                {traslate("to all GFLinks Lab Diamonds")}.
              </span>
            </div>

            <div className="col-lg-5 mt-2">
              <div className="row">
                <div className="col-lg-8">
                  <div className="input__block mb-0">
                    <label>
                      {" "}
                      {traslate("Select Dealer")} <span>*</span>
                    </label>
                    <Select
                      showSearch
                      className={
                        state.errorMessage["removeDealerAllGFLinksLabDiamonds"]
                          ? "border__red"
                          : "border__grey"
                      }
                      placeholder="No Dealer Selected"
                      optionFilterProp="children"
                      value={state.removeDealerAllGFLinksLabDiamonds}
                      onChange={(e) =>
                        onChange("removeDealerAllGFLinksLabDiamonds", e)
                      }
                    >
                      <Option value=""> No Dealer Selected </Option>
                      {dealerIDList.map((item, n) => {
                        return (
                          <Option key={n} value={item.dealerID}>
                            {item.dealerName + "(" + item.dealerID + ")"}
                          </Option>
                        );
                      })}
                    </Select>
                    {state.errorMessage["removeDealerAllGFLinksLabDiamonds"] ? (
                      <span className="error-color-red">
                        {traslate("Please Select Dealer.")}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 mt-lg-3">
                  <Button
                    className="primary-btn mt-3"
                    loading={state.loading["removeDealerAllGFLinksLabDiamonds"]}
                    onClick={handleRemoveGFLinksLabDiamondDealer}
                  >
                    {traslate("Remove DealerID")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Legacy;
