import React, { useState, Fragment, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Select, Table, Switch, Tooltip, Spin } from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import StarRatings from "react-star-ratings";
import communityVendorService from "../../../services/community-vendor.service";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import {
  setManageInventoryVendorId,
  setManageTabName
} from "./../../../actions/Community/ManageInventory";

const { Option } = Select;

export const MyVendors = (props) => {
  const dispatch = useDispatch();
  const style = (
    <span>
      {" "}
      {traslate(
        "If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"
      )}
    </span>
  );

  const [switchValue, setswitchValue] = useState(true);
  const [dealerId, setDealerId] = useState("");
  const [getloading, setGetLoading] = useState(false);
  const [commVendorData, setCommVendorData] = useState([]);
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [brandType, setBrandType] = useState([]);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("dealerCompany");
  const [sortType, setSortType] = useState("asc");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleBindDataCommVendor(
            loginDetials.loginDetials.responseData.dealerId,
            pageNo,
            pageSize,
            "dealerCompany",
            "asc"
          );
        }
      }
    }
  }, []);

  const handleBindDataCommVendor = (
    id,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    let inputData = {
      dealerID: id,
      filerType: "1",
      currentPage: pageNo,
      recordOnPage: pageSize,
      searchKeyChar: "",
      searchType: "",
      sortColumnName: sortColumnName || "dealerCompany",
      sortOrder: sortOrder || "ASC",
      lstColumnFilter: []
    };
    setGetLoading(true);
    communityVendorService
      .BindDataCommVendor(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData.vendor_NetworkResponse) {
            setTotalCount(responseData.totalRecords);
            setCommVendorData(responseData.vendor_NetworkResponse);
          } else {
            setTotalCount(0);
            setCommVendorData([]);
          }
        } else {
          setCommVendorData([]);
          setTotalCount(0);
          setSelectedColumnId(0);
          setRowDetails({});
        }
      })
      .catch((error) => {
        console.log(error);
        setGetLoading(false);
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  const handleSwitch = () => {
    setswitchValue(!switchValue);
    handleUpdateInventoryByDesignerID();
  };

  const handleUpdateInventoryByDesignerID = async () => {
    let inputData = {
      designerID: selectedColumnId,
      dealerID: dealerId
    };
    communityVendorService
      .UpdateInventoryByDesignerID(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(
            "Connection Status Changed Successfully."
          );
          handleBindDataCommVendor(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType
          );
          let objRowDetails = rowDetails;
          objRowDetails.isEnable = "false";
          setRowDetails({ ...objRowDetails });
          setSelectedColumnId(0);
        } else {
          NotificationManager.error("Connection Status Not Changed.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: traslate("Vendor"),
      dataIndex: "dealerCompany",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 200
    },
    {
      title: traslate("Type"),
      dataIndex: "vendorStatus",
      width: 120,
      sorter: (a, b) => {},
      showSorterTooltip: false
    },
    {
      title: traslate("Data Rating"),
      dataIndex: "ratingCount",
      width: 160,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (index, row) => (
        <Fragment>
          <StarRatings
            rating={row.ratingCount}
            starRatedColor="#1883b8"
            starDimension="18px"
            starSpacing="0px"
            numberOfStars={5}
          />
        </Fragment>
      )
    },
    {
      title: traslate("Active Products"),
      dataIndex: "activeproducts",
      width: 100,
      // sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <React.Fragment>
            {row.totalNumber == ""
              ? ""
              : row.activeproduct + "/" + row.totalNumber}
          </React.Fragment>
        );
      }
    },
    {
      title: traslate("Updated"),
      dataIndex: "modified",
      width: 120,
      sorter: (a, b) => {},
      showSorterTooltip: false
    },
    {
      title: (
        <label className="table__label">
          {" "}
          {traslate("Accept New Collection")}
          <Tooltip placement="right" className="ml-1" title={style}>
            <img src={UnionIcon} alt="" />
          </Tooltip>
        </label>
      ),
      dataIndex: "approvedByRetailer",
      width: 100,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Switch
              name="approvedByRetailer"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.approvedByRetailer == "0" ? false : true}
              onChange={(e) => {
                handleOnChange(row, e, "approvedByRetailer");
              }}
            />
          </div>
        );
      }
    }
  ];

  const handleOnChange = (row, e, name) => {
    if (name === "approvedByRetailer") {
      const temp = commVendorData.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, approvedByRetailer: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setCommVendorData(temp);
      handleUpdateAcceptCollections(saveRow);
    }
  };

  const handleUpdateAcceptCollections = (saveRow) => {
    let inputData = {
      dealerID: dealerId.toString(),
      manuID: saveRow.dealerID.toString(),
      isGrant: saveRow.approvedByRetailer.toString()
    };
    communityVendorService
      .UpdateAcceptCollections(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          if (saveRow.approvedByRetailer == 1) {
            NotificationManager.success(
              "Accept New Collection Has Been Granted Successfully."
            );
          } else {
            NotificationManager.success(
              "Accept New Collection Has Been Denied."
            );
          }
        } else {
          NotificationManager.error(
            "Accept New Collection Has Not Been Granted Successfully."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShowDataToRightNew = (record) => {
    let inputData = {
      vendorID: record.dealerID.toString(),
      isEnable: record.isEnabled.toString(),
      f_Type: record.filerType,
      dealerID: dealerId.toString()
    };
    setSelectedColumnId(record.dealerID);
    dispatch(setManageInventoryVendorId(record.dealerID));
    setLoadingProfileData(true);
    communityVendorService
      .ShowDataToRightNew(inputData)
      .then((response) => {
        let message = response.data.message;
        var responseData = response.data.responseData[0];
        var brandData = response.data.responseData[0].brandType;
        if (brandData) {
          var data = Object.values(brandData)
            .map((value) => value)
            .filter((x) => x !== "" && x !== null);
        } else {
          var data = [];
        }
        if (message === "Success") {
          if (responseData) {
            setRowDetails(responseData);
            setBrandType(data);
          } else {
            setRowDetails({});
          }
        } else {
          setRowDetails({});
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingProfileData(false);
      })
      .finally(() => {
        setLoadingProfileData(false);
      });
  };

  const handleSelectOnChange = (value) => {
    let arr = ["0", "1", "2"];
    if (value.includes("All")) {
      setBrandType(["0", "1", "2", "All"]);
    } else if (value.length == arr.length) {
      setBrandType(["0", "1", "2", "All"]);
    } else {
      setBrandType(value);
    }
  };

  const handleSaveSelect = () => {
    let inputData = {
      dealerID: dealerId.toString(),
      vendorId: selectedColumnId.toString(),
      vLocation: brandType.includes("All")
        ? "All"
        : brandType.join(",").toString()
    };
    setLoadingProfileData(true);
    communityVendorService
      .ManageLocation(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Updated Successfully");
        } else {
          NotificationManager.error("Data Not Updated Successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingProfileData(false);
      })
      .finally(() => {
        setLoadingProfileData(false);
      });
  };

  // API Sorting
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }

    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleBindDataCommVendor(
      dealerId,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  // URL Open New Tab
  const openInNewTab = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };
  let navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/vendorProfile?isShow=true", {
      state: {
        key: "Profile",
        id: selectedColumnId
      }
    });
  };

  const handleDispatchTabName = () => {
    dispatch(setManageTabName("Manage"));
  };
  return (
    <div className="form__fields">
      <Spin spinning={getloading}>
        <div className="row magento__mapping permission__section">
          <div className="col-md-12">
            <div className="heading__block mb-3">
              <h4 className="workarea__heading mt-0 mb-0">
                {" "}
                {traslate("My Vendors")} {"(" + totalCount + ")"}
              </h4>
            </div>
          </div>
          <div
            className={
              selectedColumnId !== 0
                ? "col-lg-9 divForm vendors-section"
                : "col-lg-12"
            }
          >
            <Table
              columns={columns}
              total={totalCount}
              dataSource={commVendorData}
              scroll={{ x: 600, y: 800 }}
              onChange={handleTableASCDES}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true
              }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  handleShowDataToRightNew(record);
                }
              })}
              rowClassName={(record) =>
                record.dealerID == selectedColumnId ? "row-active" : ""
              }
            />
          </div>

          {selectedColumnId !== 0 ? (
            <div className="col-lg-3 col-md-3">
              <Spin spinning={loadingprofileData}>
                <div className="right__section">
                  <div className="profile__block">
                    <p onClick={handleRedirect} className="linkText">
                      {rowDetails.designerName ? rowDetails.designerName : null}
                    </p>

                    <div className="logoImg__block p-0">
                      <img src={rowDetails.src ? rowDetails.src : null} />
                    </div>
                    <p className="activeFrom">
                      {" "}
                      {traslate("Active")} |{" "}
                      {rowDetails.activity ? rowDetails.activity : null}{" "}
                    </p>

                    <div className="star-ratings">
                      <p className="mb-0"> {traslate("Data Ratings")} </p>
                      <StarRatings
                        rating={rowDetails.ratingCount}
                        starRatedColor="#1883b8"
                        starDimension="18px"
                        starSpacing="0px"
                        numberOfStars={5}
                      />
                    </div>
                  </div>
                  <div className="links__block">
                    <ul>
                      <li onClick={() => handleDispatchTabName()}>
                        <Link
                          to={"/vendorProfile?isShow=true"}
                          state={{
                            key: "Profile",
                            id: selectedColumnId
                          }}
                        >
                          <i
                            className="fa fa-address-book-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {traslate("View Profile")}
                        </Link>
                      </li>
                      <li onClick={() => handleDispatchTabName()}>
                        <Link
                          to={"/vendorProfile?isShow=true"}
                          state={{
                            key: "Conversation",
                            id: selectedColumnId
                          }}
                        >
                          <i
                            className="fa fa-comments-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {traslate("Conversation")}
                        </Link>
                      </li>
                      {rowDetails.vendorStatus !== "Profile Only" &&
                      rowDetails.vendorStatus !== "Out of Network" ? (
                        <li onClick={() => handleDispatchTabName()}>
                          <Link
                            to={"/vendorProfile?isShow=true"}
                            state={{
                              key: "Inventory",
                              id: selectedColumnId
                            }}
                          >
                            <i className="fa fa-diamond" aria-hidden="true"></i>{" "}
                            {traslate("Manage Inventory")}
                          </Link>
                        </li>
                      ) : null}
                      <li onClick={() => handleDispatchTabName()}>
                        <Link
                          to={"/vendorProfile?isShow=true"}
                          state={{
                            key: "Location",
                            id: selectedColumnId
                          }}
                        >
                          <i className="fa fa-globe" aria-hidden="true"></i>{" "}
                          {traslate("View Location")}
                        </Link>
                        <a href="#"></a>
                      </li>
                    </ul>
                    <hr />
                  </div>

                  <div className="bottom__block">
                    <div className="input__block">
                      <label> {traslate("Vendor Type")} </label>
                      <Select
                        placeholder="Vendor Type"
                        name="productType"
                        optionFilterProp="children"
                        mode="multiple"
                        value={brandType.includes("All") ? "All" : brandType}
                        onChange={(e) => handleSelectOnChange(e)}
                      >
                        <Option value="All"> {traslate("All")} </Option>
                        <Option disabled={brandType.includes("All")} value="2">
                          {" "}
                          {traslate("Designer")}{" "}
                        </Option>
                        <Option disabled={brandType.includes("All")} value="1">
                          {" "}
                          {traslate("Watch Brand")}{" "}
                        </Option>
                        <Option disabled={brandType.includes("All")} value="0">
                          {" "}
                          {traslate("Bridal Designer")}{" "}
                        </Option>
                      </Select>
                    </div>

                    <div className="input__block mt__1">
                      <button
                        className="primary-btn"
                        onClick={handleSaveSelect}
                      >
                        {" "}
                        {traslate("Save")}{" "}
                      </button>
                    </div>
                    {rowDetails.isEnable == "true" ? (
                      <div className="input__block">
                        <label> {traslate("Connection Status")} </label>
                        <Switch
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                          defaultChecked
                          onChange={handleSwitch}
                        />
                      </div>
                    ) : null}
                  </div>
                  <hr />
                  <div className="retailer__dtls mb-4">
                    <p>
                      {" "}
                      {rowDetails.dealerName
                        ? rowDetails.dealerName
                        : null}{" "}
                    </p>
                    <a href={`mailto:${rowDetails.dealerEmail}`}>
                      {" "}
                      {rowDetails.dealerEmail
                        ? rowDetails.dealerEmail
                        : null}{" "}
                    </a>
                    <p>
                      {" "}
                      {rowDetails.dealerPhone
                        ? rowDetails.dealerPhone
                        : null}{" "}
                    </p>
                    <a
                      onClick={() =>
                        openInNewTab(
                          rowDetails.website ? rowDetails.website : null
                        )
                      }
                    >
                      {rowDetails.website ? rowDetails.website : null}
                    </a>
                  </div>
                </div>
              </Spin>
            </div>
          ) : null}
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyVendors);
