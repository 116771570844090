import React, { useState, useEffect } from "react";
import {
  Select,
  Button,
  Switch,
  Table,
  Modal,
  Slider,
  Empty,
  Spin
} from "antd";
import emerald from "../../assets/images/emerald.jpg";
import Roundimg from "../../assets/images/Round_65x65.jpg";
import RadiantImg from "../../assets/images/Radiant.jpg";
import PrincessImg from "../../assets/images/Princess.jpg";
import PearImg from "../../assets/images/Pear.jpg";
import OvalImg from "../../assets/images/Oval.jpg";
import MarquiseImg from "../../assets/images/Marquise.jpg";
import HeartImg from "../../assets/images/Heart.jpg";
import EmeraldImg from "../../assets/images/emerald.jpg";
import CushionImg from "../../assets/images/Cushion.jpg";
import AsscherImg from "../../assets/images/Asscher.jpg";
import traslate from "../../i18n/translate";
import Filter_icon from "../../assets/images/filter_icon.png";
import AppsRingBuilderService from "../../services/apps-ringbuilder.service";
import { useSelector } from "react-redux";
import catalogDiamondService from "../../services/catalog-diamond.service";
import searchDiamondService from "../../services/search-diamond.service";
import { NotificationManager } from "react-notifications";
import DealerProfileService from "../../services/dealer-profile.service";

import space__icon from "../../assets/images/space__icon.png";
const { Option } = Select;
const DiamondSearch = (props) => {
  const initialCertInfoData = {
    name: "",
    email: "",
    phoneNumber: "",
    comments: ""
  };
  const initialCertInfoValidation = {
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    commentsVal: ""
  };
  const initialValidation = {
    depthMinVal: "",
    depthMaxVal: "",
    tableMinVal: "",
    tableMaxVal: "",
    CrownAngleMinVal: "",
    CrownAngleMaxVal: "",
    PavilionAngleMinVal: "",
    PavilionAngleMaxVal: ""
  };
  const [hideShowFilter, setHideShowFilter] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 20]);
  const [priceValue, setPriceValue] = useState([0, 1000000]);
  const [priceCaratValue, setPriceCaratValue] = useState([0, 22000]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumnName, setSortColumnName] = useState("tmpColumn Asc");
  const [sortType, setSortType] = useState("Asc");
  const [diamondList, setDiamondList] = useState([]);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});

  const [fancyColorList, setFancyColorList] = useState([]);
  const [intensityList, setIntensityList] = useState([]);
  const [overtoneList, setOverToneList] = useState([]);
  // For Apply Filter Button
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [companyLogoLoading, setcompanyLogoLoading] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState("");

  const initialState = {
    DealerID: [],
    CertificateID: [],
    Shapes: [],
    CutFrom: null,
    CutTo: null,
    CaratMin: 0,
    CaratMax: 22,
    PriceMin: 0,
    PriceMax: 1000000,
    PriceCaratMin: 0,
    PriceCaratMax: 22000,
    ClarityFrom: null,
    ClarityTo: null,
    ColorFrom: null,
    ColorTo: null,
    DepthMin: 0,
    DepthMax: 100,
    TableMin: 0,
    TableMax: 100,
    PolishFrom: null,
    PolishTo: null,
    LengthMin: null,
    LengthMax: null,
    WidthMin: null,
    WidthMax: null,
    HeigthMin: null,
    HeigthMax: null,
    SummetryFrom: null,
    SummetryTo: null,
    GirdleFrom: null,
    GirdleTo: null,
    CrownAngleMin: 0,
    CrownAngleMax: 100,
    PavilionAngleMin: 0,
    PavilionAngleMax: 100,
    CuletFrom: null,
    CuletTo: null,
    CuletCondFrom: null,
    CuletCondTo: null,
    FluorescenceFrom: null,
    FluorescenceTo: null,
    MatchPairID: null,
    hasVideo: false,
    ShapeIsSelected: {
      Round: false,
      Radiant: false,
      Princess: false,
      Pear: false,
      Oval: false,
      Marquise: false,
      Heart: false,
      Emerald: false,
      Cushion: false,
      Asscher: false,
      All: false
    },
    FancyColor: null,
    Overtone: null,
    Intensity: null
  };
  const [state, setState] = useState(initialState);

  const [dealerList, setDealerList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [cutList, setCutList] = useState([]);
  const [clarityList, setClarityList] = useState([]);
  const [emailCertificat, setEmailCertificat] = useState({});
  const [errorEmailCertificat, setErrorEmailCertificat] = useState({});
  const [bordered, setBordered] = useState(true);

  const [colorList] = useState([
    {
      colorID: null,
      colorName: "Any"
    },
    {
      colorID: 68,
      colorName: "D"
    },
    {
      colorID: 69,
      colorName: "E"
    },
    {
      colorID: 70,
      colorName: "F"
    },
    {
      colorID: 71,
      colorName: "G"
    },
    {
      colorID: 72,
      colorName: "H"
    },
    {
      colorID: 73,
      colorName: "I"
    },
    {
      colorID: 74,
      colorName: "J"
    },
    {
      colorID: 75,
      colorName: "K"
    },
    {
      colorID: 76,
      colorName: "L"
    },
    {
      colorID: 77,
      colorName: "M"
    },
    {
      colorID: 78,
      colorName: "N"
    },
    {
      colorID: 79,
      colorName: "O"
    },
    {
      colorID: 80,
      colorName: "P"
    },
    {
      colorID: 81,
      colorName: "Q"
    },
    {
      colorID: 82,
      colorName: "R"
    },
    {
      colorID: 83,
      colorName: "S"
    },
    {
      colorID: 84,
      colorName: "T"
    },
    {
      colorID: 85,
      colorName: "U"
    },
    {
      colorID: 86,
      colorName: "V"
    },
    {
      colorID: 87,
      colorName: "W"
    },
    {
      colorID: 88,
      colorName: "X"
    },
    {
      colorID: 89,
      colorName: "Y"
    },
    {
      colorID: 90,
      colorName: "Z"
    }
  ]);
  const [polishList, setPolishList] = useState([]);
  const [summetryList, setSummetryList] = useState([]);
  const [girdleList, setGirdleList] = useState([]);
  const [culetList, setCuletList] = useState([]);
  const [culetCondList, setCuletCondList] = useState([]);
  const [fluorescenceList] = useState([
    {
      fluorescenceID: 1,
      fluorescenceName: "None"
    },
    {
      fluorescenceID: 2,
      fluorescenceName: "Very Slight"
    },
    {
      fluorescenceID: 3,
      fluorescenceName: "Slight"
    },
    {
      fluorescenceID: 4,
      fluorescenceName: "Faint"
    },
    {
      fluorescenceID: 5,
      fluorescenceName: "Medium"
    },
    {
      fluorescenceID: 6,
      fluorescenceName: "Strong"
    },
    {
      fluorescenceID: 7,
      fluorescenceName: "Very Strong"
    }
  ]);
  const [matchedPairList] = useState([
    {
      matchedPairID: 1,
      matchedPairName: "Search Single Stone"
    },
    {
      matchedPairID: 2,
      matchedPairName: "Search Match Pairs"
    }
  ]);
  const [dealerId, setDealerId] = useState(0);
  const [msgData, setMsgData] = useState("");
  const [msgLoading, setMsgLoading] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [EmailLoading, setEmailLoading] = useState(false);
  const [btnValue, setBtnValue] = useState(1);
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);

  const [validation, setValidation] = useState(initialValidation);

  const [requestCertModal, setRequestMoreCertModal] = useState(false);

  const [requestInfoDetails, setRequestInfoDetails] = useState({
    intDiamondId: "",
    dealerID: ""
  });

  const [certInfoValidation, setcertInfoDataValidation] = useState(
    initialCertInfoValidation
  );

  const [certInfoData, setcertInfoData] = useState(initialCertInfoData);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol =
    currency && currency.substring(currency.lastIndexOf("-") + 1);
  const [currencySign, setCurrencySign] = useState("$");
  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        handleGetDynamicData(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  const handleCertificateOpen = (item) => {
    if (item.certlink == "") {
      showCertInfoModal();
      setRequestInfoDetails({
        intDiamondId: item.intDiamondId,
        dealerID: item.dealerID
      });
    } else {
      window.open(
        item.certlink,
        "CERTVIEW",
        "scrollbars=yes,resizable=no,width=650,height=630"
      );
    }
  };

  const showCertInfoModal = () => {
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setcertInfoData(initialCertInfoData);
    setcertInfoDataValidation(initialCertInfoValidation);
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value
    });
    if (name == "name") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: ""
      }));
    }
    if (name == "email") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: ""
      }));
    }
    if (name == "phoneNumber") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: ""
      }));
    }
  };

  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
    setcertInfoData(initialCertInfoData);
  };

  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: ""
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      certInfoValidation.nameVal = "First Name is Compulsory";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is invalid";
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      certInfoValidation.phoneNumberVal = "";
    }
    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };

  const handleSaveRequestCertificate = async () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: requestInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: requestInfoDetails.dealerID
      };

      setRequestMoreCertModalLoading(true);
      await AppsRingBuilderService.RingBuilderRequestCertificate(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData(initialCertInfoData);
            setcertInfoDataValidation(initialCertInfoValidation);
            setRequestMoreCertModalLoading(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData(initialCertInfoData);
            setcertInfoDataValidation(initialCertInfoValidation);
            setRequestMoreCertModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setcertInfoData(initialCertInfoData);
          setcertInfoDataValidation(initialCertInfoValidation);
          setRequestMoreCertModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setcertInfoData(initialCertInfoData);
      setcertInfoDataValidation(initialCertInfoValidation);
      setRequestMoreCertModalLoading(false);
    }
  };

  const handleSendFriendemail = () => {
    try {
      let inputData = {
        name: emailCertificat.Name,
        email: emailCertificat.Email,
        friendsName: emailCertificat.FriendsName,
        friendsEmail: emailCertificat.FriendsEmail,
        comments: emailCertificat.Comments,
        dealerID: parseInt(dealerId),
        dInventoryID: parseInt(selectedRowDetails.intDiamondId)
      };
      setEmailLoading(true);
      catalogDiamondService
        .SendFriendemail(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            setEmailLoading(false);
            setVisible3(false);
            NotificationManager.success("Mail sent successfully.");
          } else {
            setEmailLoading(false);
            NotificationManager.error("Mail not sent.");
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error);
        });
    } catch (error) {
      setEmailLoading(false);
      console.log(error);
    }
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat
    }));
  };

  const handleSendMsg = () => {
    try {
      let inputData = {
        dealrId: selectedRowDetails.dealerID,
        msg: msgData,
        intDiamondId: selectedRowDetails.intDiamondId.toString()
      };
      setMsgLoading(true);
      catalogDiamondService
        .SendMsg(inputData)
        .then((response) => {
          let message = response.data.message;
          setMsgLoading(false);

          if (message === "Success") {
            setVisible1(false);
            setMsgData("");
            setMsgError("");
            NotificationManager.success("Message sent sucessfully.");
          } else {
            NotificationManager.error("Message not sent.");
          }
        })
        .catch((error) => {
          setMsgLoading(false);
          console.log(error);
        });
    } catch (error) {
      setMsgLoading(false);
      console.log(error);
    }
  };
  const handleMsgValidation = () => {
    if (msgData) {
      if (msgData.trim() === "") {
        setMsgError("Please enter message.");
      } else {
        handleSendMsg();
      }
    } else {
      setMsgError("Please enter message.");
    }
  };
  const handleCancel1 = () => {
    setVisible1(false);
    setMsgData("");
    setMsgError("");
  };
  const handleOk1 = (item) => {
    setSelectedRowDetails(item);
    handleGetCompanyProfileDetails(item.dealerID);
    setMsgData("");
    setMsgError("");
    setVisible1(true);
  };

  const handleGetDealersForDiamondCatalogList = async (id, Data) => {
    try {
      let inputData = {
        type: props.type || "Mined",
        dealerId: id
      };
      await catalogDiamondService
        .GetDealersForDiamondSearch(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          handleGetCertificatesForDiamondCatalogList(id);
          handleGetDiamondList(
            id,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            "",
            Data
          );
          if (message === "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCertificatesForDiamondCatalogList = async (id) => {
    try {
      let inputData = {
        type: props.type || "Mined"
      };
      await catalogDiamondService
        .GetCertificatesForDiamondCatalogList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          handleGetAllMINEDropDownList();

          if (message === "Success") {
            setCertificateList(responseData);
          } else {
            setCertificateList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllMINEDropDownList = async () => {
    try {
      let inputData = {
        type: ""
      };
      await catalogDiamondService
        .GetAllMINEDropDownList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          handleGetAllDropdownData();
          if (message === "Success") {
            setCutList(responseData[0]);
            setClarityList(responseData[1]);
            setPolishList(responseData[2]);
            setSummetryList(responseData[3]);
            setGirdleList(responseData[4]);
            setCuletList(responseData[5]);
            setCuletCondList(responseData[6]);
          } else {
            setCutList([]);
            setClarityList([]);
            setPolishList([]);
            setSummetryList([]);
            setGirdleList([]);
            setCuletList([]);
            setCuletCondList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = (item) => {
    setSelectedRowDetails(item);
    handleGetCompanyProfileDetails(item.dealerID);
    setMsgData("");
    setMsgError("");
    setVisible1(true);
  };

  const handleGetCompanyProfileDetails = (id) => {
    let inputData = {
      dealerID: id
    };
    setcompanyLogoLoading(true);
    try {
      DealerProfileService.GetCompanyProfileDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setcompanyLogoLoading(false);
              setSelectedDealer(responseData.dealerCompany);
            }
          } else {
            setcompanyLogoLoading(false);
            setSelectedDealer("");
          }
        })
        .catch((error) => {
          setcompanyLogoLoading(false);
          console.log(error);
        });
    } catch (error) {
      setcompanyLogoLoading(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedDealer("");
    setMsgData("");
    setMsgError("");
  };

  const onChangeMultiDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));

    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));

    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
  };

  const onChange = (e, name) => {
    let tempState = state;
    tempState[name] = e.target.value;

    if (name === "DepthMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.depthMinVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.depthMinVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "DepthMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.depthMaxVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.depthMaxVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "TableMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.tableMinVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.tableMinVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "TableMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.tableMaxVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.tableMaxVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "CrownAngleMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.CrownAngleMinVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.CrownAngleMinVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "CrownAngleMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.CrownAngleMaxVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.CrownAngleMaxVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "PavilionAngleMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.PavilionAngleMinVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.PavilionAngleMinVal = "";
        setDisableBtn(false);
      }
    }
    if (name === "PavilionAngleMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.PavilionAngleMaxVal = "Invalid Range";
        setDisableBtn(true);
      } else {
        validation.PavilionAngleMaxVal = "";
        setDisableBtn(false);
      }
    }

    setValidation(validation);

    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));
  };

  const onSwitchChange = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));

    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
  };

  const onResetFilter = () => {
    let tempState = state;
    tempState.DealerID = [];
    tempState.CertificateID = [];
    tempState.CutFrom = null;
    tempState.CutTo = null;
    tempState.ClarityFrom = null;
    tempState.ClarityTo = null;
    tempState.ColorFrom = null;
    tempState.ColorTo = null;
    tempState.DepthMin = 0;
    tempState.DepthMax = 100;
    tempState.TableMin = 0;
    tempState.TableMax = 100;
    tempState.PolishFrom = null;
    tempState.PolishTo = null;
    tempState.LengthMin = null;
    tempState.LengthMax = null;
    tempState.WidthMin = null;
    tempState.WidthMax = null;
    tempState.HeigthMin = null;
    tempState.HeigthMax = null;
    tempState.SummetryFrom = null;
    tempState.SummetryTo = null;
    tempState.GirdleFrom = null;
    tempState.GirdleTo = null;
    tempState.CrownAngleMin = 0;
    tempState.CrownAngleMax = 100;
    tempState.PavilionAngleMin = 0;
    tempState.PavilionAngleMax = 100;
    tempState.CuletFrom = null;
    tempState.CuletTo = null;
    tempState.CuletCondFrom = null;
    tempState.CuletCondTo = null;
    tempState.FluorescenceFrom = null;
    tempState.FluorescenceTo = null;
    tempState.FancyColor = null;
    tempState.Intensity = null;
    tempState.Overtone = null;
    tempState.MatchPairID = null;
    tempState.hasVideo = false;
    state.ShapeIsSelected.All = false;
    state.ShapeIsSelected.Circle = false;
    state.ShapeIsSelected.Emerald = false;
    state.ShapeIsSelected.Heart = false;
    state.ShapeIsSelected.Oval = false;
    state.ShapeIsSelected.Radiant = false;
    state.ShapeIsSelected.Round = false;
    state.ShapeIsSelected.Marquise = false;
    state.ShapeIsSelected.Princess = false;
    state.ShapeIsSelected.Pear = false;
    state.ShapeIsSelected.Cushion = false;
    state.ShapeIsSelected.Asscher = false;
    setCaratSlideValue([state.CaratMin, state.CaratMax]);
    setPriceValue([state.PriceMin.toString(), state.PriceMax.toString()]);
    setPriceCaratValue([state.PriceCaratMin, state.PriceCaratMax]);
    setValidation(initialValidation);
    setDisableBtn(false);
    setIsResetFilter(true);

    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));
  };

  useEffect(() => {
    if (isResetFilter) {
      handleGetDiamondList(
        dealerId,
        pageNo,
        pageSize,
        sortColumnName,
        sortType,
        "",
        state
      );
    }
  }, [isResetFilter]);

  const onShapeClick = (shape) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
    if (shape === "All") {
      state.ShapeIsSelected.All = !state.ShapeIsSelected.All;
      state.ShapeIsSelected.Round = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Radiant = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Princess = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Pear = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Oval = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Marquise = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Heart = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Emerald = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Cushion = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Asscher = state.ShapeIsSelected.All;
    } else {
      state.ShapeIsSelected.All = false;
      state.ShapeIsSelected[shape] = !state.ShapeIsSelected[shape];
    }

    setState((prevState) => ({
      ...prevState,
      ...state
    }));
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleGetDiamondList = async (
    id,
    pageNo,
    pageSize,
    sortColumnName,
    sortType,
    dealerIds,
    responseData
  ) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
    let tempshapes = Object.keys(state.ShapeIsSelected)
      .filter((k) => state.ShapeIsSelected[k] === true)
      .map((x) => x)
      .join(",");
    let inputData = {
      dealerID: id.toString(),
      shapes: tempshapes,
      colorMin: state.ColorFrom == null ? "-1" : state.ColorFrom.toString(),
      colorMax: state.ColorTo == null ? "-1" : state.ColorTo.toString(),

      clarityMin:
        state.ClarityFrom == null ? "-1" : state.ClarityFrom.toString(),
      clarityMax: state.ClarityTo == null ? "-1" : state.ClarityTo.toString(),
      caratMin: responseData.sldCaratMin
        ? responseData.sldCaratMin.toString()
        : isResetFilter
        ? state.CaratMin.toString()
        : caratSlideValue[0].toString(),
      caratMax: responseData.sldCaratMax
        ? "0"
        : caratSlideValue[1] === 20
        ? "0" //state.CaratMax.toString()
        : caratSlideValue[1].toString(),
      priceMin: responseData.sldPriceMin
        ? responseData.sldPriceMin.toString()
        : isResetFilter
        ? "0" //state.PriceMin.toString()
        : priceValue[0].toString(),
      priceMax: responseData.sldPriceMax
        ? "0"
        : priceValue[1] === 1000000 || priceValue[1] === "1000000"
        ? "0"
        : priceValue[1].toString(),

      certificate:
        state.CertificateID.length > 0
          ? state.CertificateID.map((x) => x).join(",")
          : "%",
      cutGradeMin: state.CutFrom == null ? "-1" : state.CutFrom.toString(),
      cutGradeMax: state.CutTo == null ? "-1" : state.CutTo.toString(),
      symmetryMin:
        state.SummetryFrom == null ? "-1" : state.SummetryFrom.toString(),
      symmetryMax:
        state.SummetryTo == null ? "-1" : state.SummetryTo.toString(),
      polishMin: state.PolishFrom == null ? "-1" : state.PolishFrom.toString(),
      polishMax: state.PolishTo == null ? "-1" : state.PolishTo.toString(),
      depthMin: responseData.sldDepthMin
        ? responseData.sldDepthMin.toString()
        : state.DepthMin
        ? state.DepthMin.toString()
        : "",
      depthMax: responseData.sldDepthMax
        ? responseData.sldPriceMax.toString()
        : state.DepthMax
        ? state.DepthMax.toString()
        : "",
      tableMin: responseData.sldTableMin
        ? responseData.sldTableMin.toString()
        : state.TableMin
        ? state.TableMin.toString()
        : "",
      tableMax: responseData.sldTableMax
        ? responseData.sldTableMax.toString()
        : state.TableMax
        ? state.TableMax.toString()
        : "",
      fluorescenceMin:
        state.FluorescenceFrom == null
          ? "-1"
          : state.FluorescenceFrom.toString(),
      fluorescenceMax:
        state.FluorescenceTo == null ? "-1" : state.FluorescenceTo.toString(),
      pairCode: "",
      orderBy:
        sortColumnName == "txtStockNo"
          ? "DealerInventoryNo"
          : sortColumnName == "txtShape"
          ? "Cut"
          : sortColumnName == "fltCarat"
          ? "Size"
          : sortColumnName == "txtCulet" || sortColumnName == "txtCutGrade"
          ? "CutGrade"
          : sortColumnName == "txtColor"
          ? "Color"
          : sortColumnName == "txtClarity"
          ? "ClarityID"
          : sortColumnName == "fltRapPercent"
          ? "OffRapaport"
          : sortColumnName == "fltPrice"
          ? "FltPrice"
          : sortColumnName == "fltDepth"
          ? "Depth"
          : sortColumnName == "fltTable"
          ? "TableMeasure"
          : sortColumnName == "txtCertificate"
          ? "Certificate"
          : sortColumnName == "txtSymmetry"
          ? "Symmetry"
          : sortColumnName == "dealer"
          ? "DealerID"
          : sortColumnName == "txtFluorescence"
          ? "Flouresence"
          : sortColumnName == "txtMeasurements"
          ? "Measurements"
          : sortColumnName == "txtPolish"
          ? "Polish"
          : "tmpColumn Asc",
      orderType: sortType ? sortType : "Asc",
      pageNumber: pageNo + "",
      pageSize: pageSize + "",
      invenID: "",
      strDetailLinkURL: "dealerlink",
      did: "",
      caratPriceMin: isResetFilter
        ? state.PriceCaratMin.toString()
        : priceCaratValue[0]
        ? priceCaratValue[0].toString()
        : "0",

      caratPriceMax: isResetFilter
        ? "0"
        : priceCaratValue[1] === 22000
        ? "0"
        : priceCaratValue[1].toString(),
      polishList:
        state.PolishFrom == null && state.PolishTo == null ? "-1" : "",
      symmetryList:
        state.SummetryFrom == null && state.SummetryTo == null ? "-1" : "",
      fluorescenceList:
        state.FluorescenceFrom == null && state.FluorescenceTo == null
          ? "-1"
          : "",
      cutGradeList: state.CutFrom == null && state.CutTo == null ? "-1" : "",
      colorList: state.ColorFrom == null && state.ColorTo == null ? "-1" : "",
      clarityList:
        state.ClarityFrom == null && state.ClarityTo == null ? "-1" : "",
      sOrigin: "",
      intOptimize: 0,
      intCulet1: state.CuletFrom ? state.CuletFrom : 0,
      intCulet2: state.CuletTo ? state.CuletTo : 20,
      intCuletCondition1: state.CuletCondFrom ? state.CuletCondFrom : 0,
      intCuletCondition2: state.CuletCondTo ? state.CuletCondTo : 20,
      fltCrownAngle1: state.CrownAngleMin ? state.CrownAngleMin : 0,
      fltCrownAngle2: state.CrownAngleMax ? state.CrownAngleMax : 100,
      fltPavilionAngle1: state.PavilionAngleMin ? state.PavilionAngleMin : 0,
      fltPavilionAngle2: state.PavilionAngleMax ? state.PavilionAngleMax : 100,
      intGirdle1: state.GirdleFrom ? state.GirdleFrom : -1,
      intGirdle2: state.GirdleTo ? state.GirdleTo : -1,
      intCutGrade: 0,
      uploadDateMin: "",
      uploadDateMax: "",
      strSelectedDealers:
        state.DealerID.length > 0 ? state.DealerID.map((x) => x).join(",") : "",
      selectedDealerConunt:
        state.DealerID.length > 0 ? state.DealerID.length : 1,
      fancyColor: state.FancyColor ? state.FancyColor.toString() : "",
      intIntensity: state.Intensity ? state.Intensity : 0,
      intOvertone: state.Overtone ? state.Overtone : 1,
      overtone: "",
      overtone2: "",
      strMatchedPairs: state.MatchPairID ? state.MatchPairID.toString() : "0",
      fltWidth1: state.WidthMin ? state.WidthMin.toString() : "-1",
      fltWidth2: state.WidthMax ? state.WidthMax.toString() : "-1",
      fltHeight1: state.HeigthMin ? state.HeigthMin.toString() : "-1",
      fltHeight2: state.HeigthMax ? state.HeigthMax.toString() : "-1",
      fltLength1: state.LengthMin ? state.LengthMin.toString() : "-1",
      fltLength2: state.LengthMax ? state.LengthMax.toString() : "-1",
      strSelectedInvID: "",
      flagDD: "1",
      checkInventory: "Catalog",
      video: state.hasVideo === true ? "1" : "0",
      isComm: 0
    };
    setLoading(true);
    try {
      await searchDiamondService
        .SearchMinedDiamonds(inputData, props.type)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (message === "Success") {
              if (responseData) {
                setDiamondList(responseData);
                setTotalCount(Number(responseData[0].intTotalRecords));
                if (isResetFilter) {
                  setIsResetFilter(false);
                }
                setLoading(false);
              }
            } else {
              setDiamondList([]);
              setTotalCount(0);
              setLoading(false);
            }
          } else {
            setDiamondList([]);
            setTotalCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetDynamicData = async (id) => {
    try {
      let inputData = {
        dealerId: id.toString()
      };
      setLoading(true);
      await catalogDiamondService
        .GetDynamicData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            responseData.sldPrinceCaratMin = "0";
            responseData.sldPriceCaratMax = "22000";
            const tempState = state;
            tempState.CaratMax = parseFloat(responseData.sldCaratMax || 0);
            tempState.CaratMin = parseFloat(responseData.sldCaratMin || 0);
            tempState.PriceMax = parseFloat(responseData.sldPriceMax || 0);
            tempState.PriceMin = parseFloat(responseData.sldPriceMin || 0);
            tempState.DepthMax = parseFloat(responseData.sldDepthMax || 0);
            tempState.DepthMin = parseFloat(responseData.sldDepthMin || 0);
            tempState.TableMax = parseFloat(responseData.sldTableMax || 0);
            tempState.TableMin = parseFloat(responseData.sldTableMin || 0);
            tempState.PriceCaratMin = parseFloat(
              responseData.sldPrinceCaratMin || 0
            );
            tempState.PriceCaratMax = parseFloat(
              responseData.sldPriceCaratMax || 0
            );
            setCaratSlideValue([tempState.CaratMin, tempState.CaratMax]);
            setPriceValue([
              tempState.PriceMin.toString(),
              tempState.PriceMax.toString()
            ]);
            setCurrencySign(responseData?.currencySign || "$");
            setPriceCaratValue([
              tempState.PriceCaratMin,
              tempState.PriceCaratMax
            ]);
            setState((prevState) => ({
              ...prevState,
              ...tempState
            }));
          } else {
          }
          handleGetDealersForDiamondCatalogList(id, responseData);
        })
        .catch((error) => {
          handleGetDealersForDiamondCatalogList(id, state);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetOverToneForDiamondCatalogList = async () => {
    try {
      let inputData = {
        type: props.type || "Mined"
      };
      await catalogDiamondService
        .GetOverToneForDiamondCatalogList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            setOverToneList(responseData);
          } else {
            setOverToneList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllDropdownData = async () => {
    try {
      await catalogDiamondService
        .GetAllDropdownData()
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          handleGetOverToneForDiamondCatalogList();
          if (message === "Success") {
            if (responseData) {
              let Color = responseData.tblStoneColor;
              let diamondintensity = responseData.diamondIntensity;
              setFancyColorList(Color);
              setIntensityList(diamondintensity);
            } else {
              setFancyColorList([]);
              setIntensityList([]);
            }
          } else {
            setFancyColorList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*Diamond search custom binding ends*/

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };
  const handleOpenDetailsModal = (row) => {
    setSelectedRowDetails(row);
    setVisible(true);
  };
  const handleOpenIsEmail = () => {
    setIsEmail(true);
  };
  const handleCloseIsEmail = () => {
    setIsEmail(false);
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat
    }));
  };
  const handleEmailCertificatChange = (e) => {
    const { name, value } = e.target;
    emailCertificat[name] = value;
    errorEmailCertificat[name] = null;
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat
    }));
    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...errorEmailCertificat
    }));
  };
  const handleValidEmailCertificat = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let isValid = true;
    let tempEmailCertificat = emailCertificat;
    let tempErrorEmailCertificat = errorEmailCertificat;
    if (!tempEmailCertificat["Name"]) {
      tempErrorEmailCertificat["Name"] = traslate("Enter Name.");
      isValid = false;
    } else {
      tempErrorEmailCertificat["Name"] = null;
    }

    if (!tempEmailCertificat["Email"]) {
      tempErrorEmailCertificat["Email"] = traslate("Enter Email Address.");
      isValid = false;
    } else if (!tempEmailCertificat["Email"].trim()) {
      tempErrorEmailCertificat["Email"] = traslate("Enter Email Address.");
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["Email"] = traslate("Email is not valid.");
      isValid = false;
    }

    if (!tempEmailCertificat["FriendsName"]) {
      tempErrorEmailCertificat["FriendsName"] = traslate(
        "Enter Friend's Name."
      );
      isValid = false;
    } else {
      tempErrorEmailCertificat["FriendsName"] = null;
    }

    if (!tempEmailCertificat["FriendsEmail"]) {
      tempErrorEmailCertificat["FriendsEmail"] = traslate(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (!tempEmailCertificat["FriendsEmail"].trim()) {
      tempErrorEmailCertificat["FriendsEmail"] = traslate(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["FriendsEmail"] = traslate(
        "Email is not valid."
      );
      isValid = false;
    }

    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...tempErrorEmailCertificat
    }));
    return isValid;
  };

  const handleCertificateEmailSend = () => {
    if (handleValidEmailCertificat()) {
      handleSendFriendemail();
    }
  };

  const handleApplyFilter = () => {
    setHideShowFilter(true);
    handleGetDiamondList(dealerId, 1, 10, "tmpColumn Asc", "Asc", "", state);

    setDisableBtn(true);
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";

    let obj = {};
    let filter = "";

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "tmpColumn Asc";
      setSortColumnName(column);
      setSortType(type);
    }

    if (filters.txtStockNo) {
      obj = {
        columnName: "txtStockNo",
        columnValue: filters.txtStockNo[0],
        columnType: "Input"
      };
      filter = obj.columnValue;
    }

    handleGetDiamondList(
      dealerId,
      pagination.current,
      pagination.pageSize,
      column,
      type,
      filter,
      "",
      false,
      state
    );
  };
  const firstsetPriceSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...priceValue];
    var StrValue = e.target.value && e.target.value.replace(currencySign, "");
    var Value = StrValue.replace(/,/g, "");

    if (Value.replace(/,/g, "") > state.PriceMax) {
      e.preventDefault();
    } else if (num == 0 || num == "0") {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          newArr[0] = Value;
          setPriceValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }
      }
    }
  };
  const firstsetPriceCaratSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...priceCaratValue];
    var StrValue = e.target.value && e.target.value.replace(currencySign, "");

    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceCaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceCaratValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setPriceCaratValue(newArr);
        } else {
          newArr[0] = Value;
          setPriceCaratValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceCaratValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceCaratValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceCaratValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceCaratValue(newArr);
          }
        }
      }
    }
  };
  const firstsetCaratSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...caratSlideValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(e.target.value.replace(/,/g, ""))) > state.CaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          var dottedamount = Value.split(".");
          var amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[0] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          var dottedamount = Value.split(".");
          var amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[1] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    }
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };
  function formatMoney(amount) {
    try {
      if (amount && amount.toString().includes(".")) {
        var dottedamount = amount.split(".");
        var amount =
          Number(dottedamount[0]).toLocaleString("en-IN") +
          "." +
          dottedamount[1];
      } else {
        var amount = Number(amount).toLocaleString("en-IN");
      }
      return amount;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="vdiamond_section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="workarea__heading mt-0">
                      {props.type === "LAB"
                        ? traslate("Lab Diamond Search")
                        : traslate("Mined Diamond Search")}
                    </h4>
                  </div>
                  <div className="col-md-6 ml-auto text-right text-left-mobile mobile__bottomspace">
                    <button
                      className="primary-btn"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {traslate("Show Filter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {traslate("Hide Filter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="filter_section">
                  <div
                    className={
                      hideShowFilter
                        ? "form__fields filter_inpsection hide"
                        : "form__fields filter_inpsection"
                    }
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="input__block">
                            <label> {traslate("Shapes")} </label>
                            <div className="imgselct__div">
                              <div
                                className={
                                  state.ShapeIsSelected.Round
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Round")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Round")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Radiant
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Radiant")}
                              >
                                <img src={RadiantImg} alt="" />
                                <span> {traslate("Radiant")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Princess
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Princess")}
                              >
                                <img src={PrincessImg} alt="" />
                                <span> {traslate("Princess")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Pear ? "selected" : null
                                }
                                onClick={() => onShapeClick("Pear")}
                              >
                                <img src={PearImg} alt="" />
                                <span> {traslate("Pear")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Oval ? "selected" : null
                                }
                                onClick={() => onShapeClick("Oval")}
                              >
                                <img src={OvalImg} alt="" />
                                <span> {traslate("Oval")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Marquise
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Marquise")}
                              >
                                <img src={MarquiseImg} alt="" />
                                <span> {traslate("Marquise")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Heart
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Heart")}
                              >
                                <img src={HeartImg} alt="" />
                                <span> {traslate("Heart")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Emerald
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Emerald")}
                              >
                                <img src={EmeraldImg} alt="" />
                                <span> {traslate("Emerald")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Cushion
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Cushion")}
                              >
                                <img src={CushionImg} alt="" />
                                <span> {traslate("Cushion")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Asscher
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Asscher")}
                              >
                                <img src={AsscherImg} alt="" />
                                <span> {traslate("Asscher")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.All
                                    ? "selected all__div"
                                    : "all__div"
                                }
                                onClick={() => onShapeClick("All")}
                              >
                                <span> {traslate("All")} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Dealers")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              mode="multiple"
                              showArrow
                              placeholder="Select Dealer"
                              optionFilterProp="children"
                              onChange={(e) => onChangeMultiDrop(e, "DealerID")}
                              value={state.DealerID}
                            >
                              {dealerList.map((item, i) => {
                                return (
                                  <Option value={item.dealerID}>
                                    {item.dealerCompany}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Certificates")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              mode="multiple"
                              showArrow
                              placeholder="Select Certificate"
                              optionFilterProp="children"
                              onChange={(e) =>
                                onChangeMultiDrop(e, "CertificateID")
                              }
                              value={state.CertificateID}
                            >
                              {certificateList.map((item, i) => {
                                return (
                                  <Option value={item.certificateValue}>
                                    {item.certificateValue}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Cut")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CutFrom")}
                                  value={state.CutFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {cutList.map((item, i) => {
                                    return (
                                      <Option value={item.cutGradeId}>
                                        {item.cutGradeValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CutTo")}
                                  value={state.CutTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {cutList.map((item, i) => {
                                    return (
                                      <Option value={item.cutGradeId}>
                                        {item.cutGradeValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Carats")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={caratSlideValue}
                                min={state.CaratMin}
                                max={state.CaratMax}
                                step={0.01}
                                onChange={(e) => {
                                  setCaratSlideValue(e);
                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[0].toLocaleString(
                                      "en-US"
                                    )}
                                    onChange={(e) => {
                                      firstsetCaratSlideValue(e, 0);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[1].toLocaleString(
                                      "en-US"
                                    )}
                                    onChange={(e) => {
                                      firstsetCaratSlideValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Price")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={priceValue}
                                min={state.PriceMin}
                                max={state.PriceMax}
                                onChange={(e) => {
                                  setPriceValue(e);

                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(
                                        priceValue[0].toString()
                                        // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      )
                                    }
                                    // value={
                                    //   currencySign + formatMoney(priceValue[0])
                                    // }
                                    onChange={(e) => {
                                      firstsetPriceSlideValue(e, 0);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(
                                        priceValue[1].toString()
                                        // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      )
                                    }
                                    // value={
                                    //   currencySign + formatMoney(priceValue[1])
                                    // }
                                    onChange={(e) => {
                                      firstsetPriceSlideValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label>
                              {traslate("Price")} / {traslate("Carat")}{" "}
                            </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={priceCaratValue}
                                min={state.PriceCaratMin}
                                max={state.PriceCaratMax}
                                onChange={(e) => {
                                  setPriceCaratValue(e);

                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(priceCaratValue[0])
                                    }
                                    onChange={(e) => {
                                      firstsetPriceCaratSlideValue(e, 0);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(priceCaratValue[1])
                                    }
                                    onChange={(e) => {
                                      firstsetPriceCaratSlideValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Clarity")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "ClarityFrom")
                                  }
                                  value={state.ClarityFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {clarityList.map((item, i) => {
                                    return (
                                      <Option value={item.clarityID}>
                                        {item.claritydetails}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "ClarityTo")}
                                  value={state.ClarityTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {clarityList.map((item, i) => {
                                    return (
                                      <Option value={item.clarityID}>
                                        {item.claritydetails}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Color")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "ColorFrom")}
                                  value={state.ColorFrom}
                                >
                                  {colorList.map((item, i) => {
                                    return (
                                      <Option value={item.colorID}>
                                        {item.colorName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "ColorTo")}
                                  value={state.ColorTo}
                                >
                                  {colorList.map((item, i) => {
                                    return (
                                      <Option value={item.colorID}>
                                        {item.colorName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Depth")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.DepthMin}
                                  onChange={(e) => onChange(e, "DepthMin")}
                                  className={
                                    validation.depthMinVal && "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.depthMinVal && (
                                  <span className="error-color-red">
                                    {validation.depthMinVal}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.DepthMax}
                                  onChange={(e) => onChange(e, "DepthMax")}
                                  className={
                                    validation.depthMaxVal && "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.depthMaxVal && (
                                  <span className="error-color-red">
                                    {validation.depthMaxVal}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Table")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.TableMin}
                                  onChange={(e) => onChange(e, "TableMin")}
                                  className={
                                    validation.tableMinVal && "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.tableMinVal && (
                                  <span className="error-color-red">
                                    {validation.tableMinVal}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.TableMax}
                                  onChange={(e) => onChange(e, "TableMax")}
                                  className={
                                    validation.tableMaxVal && "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.tableMaxVal && (
                                  <span className="error-color-red">
                                    {validation.tableMaxVal}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Polish")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "PolishFrom")
                                  }
                                  value={state.PolishFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {polishList.map((item, i) => {
                                    return (
                                      <Option value={item.polishID}>
                                        {item.polishValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "PolishTo")}
                                  value={state.PolishTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {polishList.map((item, i) => {
                                    return (
                                      <Option value={item.polishID}>
                                        {item.polishValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-2">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Length(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "LengthMin")}
                                  value={state.LengthMin}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "LengthMax")}
                                  value={state.LengthMax}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Width(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "WidthMin")}
                                  value={state.WidthMin}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "WidthMax")}
                                  value={state.WidthMax}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Height(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "HeigthMin")}
                                  value={state.HeigthMin}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "HeigthMax")}
                                  value={state.HeigthMax}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Symmetry")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "SummetryFrom")
                                  }
                                  value={state.SummetryFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {summetryList.map((item, i) => {
                                    return (
                                      <Option value={item.symmetryID}>
                                        {item.symmetryValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "SummetryTo")
                                  }
                                  value={state.SummetryTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {summetryList.map((item, i) => {
                                    return (
                                      <Option value={item.symmetryID}>
                                        {item.symmetryValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Girdle")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "GirdleFrom")
                                  }
                                  value={state.GirdleFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {girdleList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondGirdleID}>
                                        {item.girdleValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "GirdleTo")}
                                  value={state.GirdleTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {girdleList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondGirdleID}>
                                        {item.girdleValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Crown Angle")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.CrownAngleMin}
                                  onChange={(e) => onChange(e, "CrownAngleMin")}
                                  className={
                                    validation.CrownAngleMinVal && "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.CrownAngleMinVal && (
                                  <span className="error-color-red">
                                    {validation.CrownAngleMinVal}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.CrownAngleMax}
                                  onChange={(e) => onChange(e, "CrownAngleMax")}
                                  className={
                                    validation.CrownAngleMaxVal && "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.CrownAngleMaxVal && (
                                  <span className="error-color-red">
                                    {validation.CrownAngleMaxVal}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Pavilion Angle")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.PavilionAngleMin}
                                  onChange={(e) =>
                                    onChange(e, "PavilionAngleMin")
                                  }
                                  className={
                                    validation.PavilionAngleMinVal &&
                                    "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.PavilionAngleMinVal && (
                                  <span className="error-color-red">
                                    {validation.PavilionAngleMinVal}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6"
                                  value={state.PavilionAngleMax}
                                  onChange={(e) =>
                                    onChange(e, "PavilionAngleMax")
                                  }
                                  className={
                                    validation.PavilionAngleMaxVal &&
                                    "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.PavilionAngleMaxVal && (
                                  <span className="error-color-red">
                                    {validation.PavilionAngleMaxVal}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Culet")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CuletFrom")}
                                  value={state.CuletFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {culetList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondCuletID}>
                                        {item.culetValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CuletTo")}
                                  value={state.CuletTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {culetList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondCuletID}>
                                        {item.culetValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Culet Condition")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "CuletCondFrom")
                                  }
                                  value={state.CuletCondFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {culetCondList.map((item, i) => {
                                    return (
                                      <Option
                                        value={item.diamondCuletConditionID}
                                      >
                                        {item.culetConditionValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "CuletCondTo")
                                  }
                                  value={state.CuletCondTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {culetCondList.map((item, i) => {
                                    return (
                                      <Option
                                        value={item.diamondCuletConditionID}
                                      >
                                        {item.culetConditionValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Fluorescence")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "FluorescenceFrom")
                                  }
                                  value={state.FluorescenceFrom}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {fluorescenceList.map((item, i) => {
                                    return (
                                      <Option value={item.fluorescenceID}>
                                        {item.fluorescenceName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "FluorescenceTo")
                                  }
                                  value={state.FluorescenceTo}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {fluorescenceList.map((item, i) => {
                                    return (
                                      <Option value={item.fluorescenceID}>
                                        {item.fluorescenceName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {props.type !== "LAB" ? (
                          <React.Fragment>
                            {/* <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Fancy Color")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Select Fancy Color"
                                  optionFilterProp="children"
                                  value={state.FancyColor}
                                  onChange={(e) =>
                                    onChangeDrop(e, "FancyColor")
                                  }
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {fancyColorList.map((item, i) => {
                                    return (
                                      <Option value={item.intColorId}>
                                        {item.txtColorName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div> */}
                            {/* <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Intensity")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Search Intensity"
                                  optionFilterProp="children"
                                  value={state.Intensity}
                                  onChange={(e) => onChangeDrop(e, "Intensity")}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {intensityList.map((item, i) => {
                                    return (
                                      <Option value={item.intensityId}>
                                        {item.intensityValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div> */}
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Overtone")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Select Overtone"
                                  optionFilterProp="children"
                                  value={state.Overtone}
                                  onChange={(e) => onChangeDrop(e, "Overtone")}
                                >
                                  {overtoneList.map((item, i) => {
                                    return (
                                      <Option value={item.overtoneID}>
                                        {item.overtoneValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Matched Pairs")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Search Matched Pairs"
                              optionFilterProp="children"
                              onChange={(e) => onChangeDrop(e, "MatchPairID")}
                              value={state.MatchPairID}
                            >
                              {matchedPairList.map((item, i) => {
                                return (
                                  <Option value={item.matchedPairID}>
                                    {item.matchedPairName}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Has Video")} </label>
                            <Switch
                              name="hasVideo"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              checked={state.hasVideo}
                              onChange={(e) => onSwitchChange(e, "hasVideo")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 filtbtn_div">
                          <button
                            className="primary-btn mr-2"
                            onClick={onResetFilter}
                          >
                            {" "}
                            {traslate("Reset Filters")}
                          </button>
                          <button
                            className={
                              disableBtn ? "disabled-btn" : "primary-btn"
                            }
                            disabled={disableBtn}
                            onClick={() => handleApplyFilter()}
                            loading={loading}
                          >
                            {" "}
                            {traslate("Apply Filters")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="options__div">
                  <div className="row">
                    <div className="col-lg-6 add__div">
                      <span className="diamnd__txt">
                        {traslate("You Have")}{" "}
                        <span className="txt__no">{totalCount}</span>{" "}
                        {traslate("Diamonds")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Table
                  className="vdiamond__table"
                  // bordered={bordered}
                  columns={[
                    {
                      title: "",
                      dataIndex: "view",
                      width: 20,
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <div
                              className="infoicon__div"
                              onClick={() => handleOpenDetailsModal(row)}
                            >
                              <i className="fa fa-info-circle"></i>
                            </div>
                          </React.Fragment>
                        );
                      }
                    },
                    {
                      title: traslate("Stock #"),
                      width: 60,
                      dataIndex: "txtStockNo",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      ellipsis: true
                    },
                    {
                      title: traslate("Shape"),
                      width: 70,
                      dataIndex: "txtShape",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      ellipsis: true,
                      render: (item, row) => {
                        const imageSource =
                          props.type === "LAB"
                            ? row.imageFileName
                            : row.imageSmallShape;
                        return (
                          <div className="shape__img">
                            {" "}
                            <div className="shape__icon">
                              <img src={imageSource} />
                            </div>{" "}
                            <span>{row.txtShape}</span>
                          </div>
                        );
                      }
                    },
                    {
                      title: traslate("Size"),
                      width: 40,
                      dataIndex: "fltCarat",
                      showSorterTooltip: false,
                      sorter: (a, b) => {}
                    },
                    {
                      title: traslate("Cut"),
                      width: 40,
                      dataIndex:
                        props.type == "LAB" ? "txtCutGrade" : "txtCulet",
                      showSorterTooltip: false,
                      sorter: (a, b) => {}
                    },
                    {
                      title: traslate("Color"),
                      width: 40,
                      dataIndex: "txtColor",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      ellipsis: true
                    },
                    {
                      title: traslate("Clarity"),
                      width: 40,
                      dataIndex: "txtClarity",
                      showSorterTooltip: false,
                      sorter: (a, b) => {}
                    },
                    {
                      title: traslate("Off %"),
                      width: 40,
                      dataIndex: "fltRapPercent",
                      showSorterTooltip: false,
                      sorter: (a, b) => {}
                    },
                    {
                      title: traslate("Cost"),
                      width: 50,
                      dataIndex: "fltPrice",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                       render: (item,row) => {
                        return(
                          <>
                            {currencySign || " "}
                            {row.fltPrice
                                    ? parseFloat(row.fltPrice).toFixed(2)
                                    : ""}
                          </>
                        );
                        
                      },
                    },
                    {
                      title: traslate("Dep."),
                      width: 40,
                      dataIndex: "fltDepth",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (item, row) => {
                        return row.fltDepth + "%";
                      }
                    },
                    {
                      title: traslate("Table"),
                      width: 40,
                      dataIndex: "fltTable",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (item, row) => {
                        return row.fltTable + "%";
                      }
                    },
                    {
                      title: traslate("Cert."),
                      width: 50,
                      dataIndex: "txtCertificate",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      render: (row, item) => {
                        return (
                          <div className="infoicon__div">
                            <span
                              className="linkText"
                              onClick={() => handleCertificateOpen(item)}
                            >
                              {item.txtCertificate}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      title: traslate("Polish"),
                      width: 40,
                      dataIndex: "txtPolish",
                      showSorterTooltip: false,
                      sorter: (a, b) => {}
                    },
                    {
                      title: traslate("Sym."),
                      width: 40,
                      dataIndex: "txtSymmetry",
                      showSorterTooltip: false,
                      sorter: (a, b) => {}
                    },
                    {
                      title: traslate("Dealer"),
                      width: 40,
                      dataIndex: "dealer",
                      render: (item, row) => {
                        return (
                          <div className="infoicon__div">
                            <span
                              onClick={() => {
                                handleOk1(row);
                              }}
                            >
                              {" "}
                              <i
                                className="fa fa-comments"
                                aria-hidden="true"
                              ></i>{" "}
                              {row.dealerID}{" "}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      title: traslate("Video"),
                      width: 60,
                      render: (item, row) => {
                        return row.videoFileName ? (
                          <i
                            onClick={() => {
                              handleOpenVideoImageModal(row, true);
                            }}
                            className="fa fa-video-camera"
                            style={{ color: "#0078d7", cursor: "pointer" }}
                          ></i>
                        ) : /* 
                        : row.imageFileName ? (
                        <i
                          className="fa fa-image linkText"
                          style={{ color: "#0078d7" }}
                          onClick={() => {
                            handleOpenVideoImageModal(row, false);
                          }}
                        ></i>
                      ) 
                      */
                        null;
                      }
                    }
                  ]}
                  loading={{ spinning: loading, size: "large" }}
                  dataSource={diamondList}
                  pagination={{
                    total: totalCount,
                    pageNo: pageNo,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    current: pageNo
                  }}
                  onChange={(pagination, filters, sorters) => {
                    handleTableASCDES(pagination, filters, sorters);
                  }}
                  rowKey={(record) => record.intDiamondId}
                />
              </div>
              <div className="col-lg-12">
                <Modal
                  title={traslate("View Details")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible}
                  onOk={handleCancel}
                  onCancel={handleCancel}
                  width={1000}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => handleOk(selectedRowDetails)}
                    >
                      {traslate("Contact Dealer")}
                    </Button>
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-2 mt-4 text-center">
                              <div className="viewdtls__left">
                                <img
                                  src={selectedRowDetails.imageBigShape}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-lg-10 mt-4">
                              <div className="info__div">
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("GemFind")}#:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.intDiamondId}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Retail Price")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      { currencySign +
                                        " " +
                                        selectedRowDetails.fltPrice}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Color")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtColor}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Depth")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltDepth + "%"}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Carat Weight")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltCarat}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Symmetry")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtSymmetry}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Measurements")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtMeasurements}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Treatment")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.treatment}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Shape")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtShape}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Clarity")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtClarity}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Table")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltTable + "%"}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Polish")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtPolish}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Crown")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltCrown}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Lab")}:
                                    </span>{" "}
                                    <span
                                      className="val__txt linkText"
                                      onClick={() =>
                                        handleCertificateOpen(
                                          selectedRowDetails
                                        )
                                      }
                                    >
                                      {selectedRowDetails.txtCertificate}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Matched Pair Stock")}#:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltCrown}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Seller")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.dealerCompany}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Stock")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtStockNo}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Phone")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.dealerPhone}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Location")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.dealerLocation}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Updated")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.modified}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* Message Send Modal */}
                <Modal
                  title={
                    <React.Fragment>
                      <h4 className="d-flex mb-0">
                        {traslate("Message to")}
                        <h4 className="linkText mx-2 mb-0">
                          {selectedDealer ? selectedDealer : ""}
                        </h4>
                      </h4>
                    </React.Fragment>
                  }
                  className="modalvdiamond__section"
                  centered
                  visible={visible1}
                  onOk={() => setVisible1(false)}
                  onCancel={() => setVisible1(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel1}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handleMsgValidation}
                      loading={msgLoading}
                    >
                      {traslate("Send")}
                    </Button>
                  ]}
                >
                  <Spin spinning={companyLogoLoading}>
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-lg-12">
                                <p>
                                  {traslate("Contact the dealer regarding")}.{" "}
                                  <a href="#">
                                    {selectedRowDetails.txtStockNo} .
                                  </a>
                                </p>
                              </div>
                              <div className="col-lg-12">
                                <div class="input__block">
                                  <textarea
                                    id=""
                                    name=""
                                    rows="6"
                                    cols="50"
                                    placeholder="Write your message here..."
                                    class=""
                                    value={msgData}
                                    onChange={(e) => {
                                      setMsgData(e.target.value);
                                      setMsgError("");
                                    }}
                                  ></textarea>
                                  {msgError ? (
                                    <div className="text-danger">
                                      {msgError}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </Modal>
                {/* Video and Image View Modal */}
                <Modal
                  title={traslate("Video/Images")}
                  className="modalconsupld__section"
                  centered
                  width={600}
                  visible={videoImageModal}
                  onCancel={() => handleCloseVideoImageModal()}
                  footer={[
                    <Button onClick={() => handleCloseVideoImageModal()}>
                      {traslate("Cancel")}
                    </Button>
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            {isVideo ? (
                              selectedRowDetails.videoFileName ? (
                                <iframe
                                  height={"400px"}
                                  title="diamond-video"
                                  src={selectedRowDetails.videoFileName}
                                ></iframe>
                              ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              )
                            ) : selectedRowDetails.imageFileName ? (
                              <img
                                src={selectedRowDetails.imageFileName}
                                alt="img-diamond"
                              />
                            ) : (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* Modal Request Certifiacate  */}

                <Modal
                  title={traslate("Request Diamond Certificate")}
                  visible={requestCertModal}
                  style={{ top: 70 }}
                  className="request_info"
                  onCancel={closeCertInfoModal}
                  footer={[
                    <Button key="back" onClick={closeCertInfoModal}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      loading={requestCertModalLoading}
                      type="primary"
                      onClick={handleSubmitRequestCert}
                    >
                      {traslate("Request")}
                    </Button>
                  ]}
                >
                  <div className="row">
                    <div class="col-md-12">
                      <div className="table__block">
                        <div className="row p-0 border-0 form__fields mh-auto">
                          <div className="col-md-6">
                            <div className="input__block">
                              <label>
                                {traslate("Name")}{" "}
                                <span className="mandatory">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                value={certInfoData.name}
                                className={
                                  certInfoValidation.nameVal && "border__red"
                                }
                                onChange={(e) =>
                                  handleCertInfoChange(e, "name", "input")
                                }
                              />
                              {certInfoValidation.nameVal && (
                                <p className="error-color-red">
                                  {certInfoValidation.nameVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input__block">
                              <label>
                                {traslate("E-Mail Address")}{" "}
                                <span className="mandatory">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                value={certInfoData.email}
                                className={
                                  certInfoValidation.emailVal && "border__red"
                                }
                                onChange={(e) =>
                                  handleCertInfoChange(e, "email", "input")
                                }
                              />
                              {certInfoValidation.emailVal && (
                                <p className="error-color-red">
                                  {certInfoValidation.emailVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input__block">
                              <label>
                                {traslate("Phone Number")}{" "}
                                <span className="mandatory">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                value={certInfoData.phoneNumber}
                                className={
                                  certInfoValidation.phoneNumberVal &&
                                  "border__red"
                                }
                                onChange={(e) =>
                                  handleCertInfoChange(
                                    e,
                                    "phoneNumber",
                                    "input"
                                  )
                                }
                              />
                              {certInfoValidation.phoneNumberVal && (
                                <p className="error-color-red">
                                  {certInfoValidation.phoneNumberVal}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input__block">
                              <label>{traslate("Comments")}</label>
                              <textarea
                                value={certInfoData.comments}
                                onChange={(e) =>
                                  handleCertInfoChange(e, "comments", "input")
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* End */}

                {/* Image zoom modal */}
                <Modal
                  title={isEmail ? "Email Diamond Certificate" : "Image"}
                  className="modalvdiamond__section image__zoommodal"
                  centered
                  visible={visible3}
                  onOk={() => setVisible3(false)}
                  onCancel={() => setVisible3(false)}
                  width={600}
                  footer={
                    isEmail
                      ? null
                      : [
                          <Button key="back"> {traslate("Print")} </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            onClick={handleOpenIsEmail}
                          >
                            {traslate("Email")}
                          </Button>
                        ]
                  }
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          {isEmail ? (
                            <React.Fragment>
                              <div className="row">
                                <div className="col-lg-12 ">
                                  <label> {traslate("Name")}: </label>
                                  <input
                                    type={"text"}
                                    name="Name"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.Name}
                                  />
                                  {errorEmailCertificat.Name ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["Name"]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 ">
                                  <label> {traslate("Email")}: </label>
                                  <input
                                    type={"text"}
                                    name="Email"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.Email}
                                  />
                                  {errorEmailCertificat.Email ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["Email"]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 ">
                                  <label> {traslate("Friend's Name")}: </label>
                                  <input
                                    type={"text"}
                                    name="FriendsName"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.FriendsName}
                                  />
                                  {errorEmailCertificat.FriendsName ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["FriendsName"]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 ">
                                  <label> {traslate("Friend's Email")}: </label>
                                  <input
                                    type={"text"}
                                    name="FriendsEmail"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.FriendsEmail}
                                  />
                                  {errorEmailCertificat.FriendsEmail ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["FriendsEmail"]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 ">
                                  <label> {traslate("Comments")}: </label>
                                  <input
                                    type={"text"}
                                    name="Comments"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.Comments}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 ">
                                  <Button
                                    type={"primary"}
                                    loading={EmailLoading}
                                    onClick={() => handleCertificateEmailSend()}
                                  >
                                    {traslate("Submit")}
                                  </Button>
                                  <Button onClick={handleCloseIsEmail}>
                                    {traslate("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            <div className="row">
                              <div className="col-lg-12 mt-4">
                                <div className="image__zoom">
                                  <img
                                    src={selectedRowDetails.imageFileName}
                                    alt="cer-img"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondSearch;
