import React, { useEffect, useState } from "react";
import { Switch, Table } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";

const BlockDesignersRetailer = () => {
  const [loading, setLoading] = useState(false);

  const [blockDesignersList, setBlockDesignersList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerId, setDealerId] = useState();
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
    handleGetBlockDesignersRetailer(pageNo, pageSize);
  }, []);

  const handleSwitch = (id, e, name) => {
    const tempBlockDesignersList = blockDesignersList.map((x) => {
      if (x.designerID === id) {
        return { ...x, isSelected: e };
      } else return x;
    });

    var item = tempBlockDesignersList.filter(
      (item) => item.designerID == id
    )[0];
    setBlockDesignersList(tempBlockDesignersList);
    handleSaveBlockDesignersRetailer(item);
  };
  const handleGetBlockDesignersRetailer = (pageNo, pageSize) => {
    try {
      let inputData = {
        pageNumber: pageNo,
        pageSize: pageSize,
      };
      setLoading(true);
      AdminToolsDataService.GetBlockDesignersRetailer(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setBlockDesignersList(responseData.blockDesignerForDemoResponses);
              setTotalCount(responseData.totalRecord);
            }
          } else {
            setBlockDesignersList([]);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSaveBlockDesignersRetailer = (item) => {
    let inputData = {
      dealerId: dealerId,
      designerID: item.designerID,
      designerName: item.designername,
      isChecked: item.isSelected.toString(),
    };

    AdminToolsDataService.SaveBlockDesignersRetailer(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Record Updated Successfully.");
          handleGetBlockDesignersRetailer(pageNo, pageSize);
        } else {
          NotificationManager.error("Record Not Updated");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetBlockDesignersRetailer(page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetBlockDesignersRetailer(current, size);
  }

  return (
    <React.Fragment>
      <div className="blockdesign_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Block Designers For Demo Retailer")}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="blockdesign_tbldiv">
                <Table
                  columns={[
                    {
                      title: traslate("Designer Name"),
                      dataIndex: "designername",
                    },
                    {
                      title: traslate("Is Blocked ?"),
                      dataIndex: "isSelected",
                      key: "isSelected",
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <div className="input__block">
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={row.isSelected}
                                onChange={(e) =>
                                  handleSwitch(row.designerID, e, "isSelected")
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 150,
                    },
                  ]}
                  onChange={(pagination, filters, sorters) => {
                    document
                      .getElementsByClassName("content__area")[0]
                      .scroll(0, 0);
                  }}
                  pagination={{
                    total: totalCount,
                    pageNo: pageNo,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    onChange: handlePageNoChange,
                    onShowSizeChange: handlePageSizeChange,
                  }}
                  dataSource={blockDesignersList}
                  scroll={{ x: 300, y: 800 }}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockDesignersRetailer;
