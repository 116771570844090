import React, { useState, useEffect } from "react";
import { Select, Button, Switch, Slider, Table, Modal, Empty } from "antd";
import emerald from "../../assets/images/emerald.jpg";
import Roundimg from "../../assets/images/Round_65x65.jpg";
import RadiantImg from "../../assets/images/Radiant.jpg";
import PrincessImg from "../../assets/images/Princess.jpg";
import PearImg from "../../assets/images/Pear.jpg";
import OvalImg from "../../assets/images/Oval.jpg";
import MarquiseImg from "../../assets/images/Marquise.jpg";
import HeartImg from "../../assets/images/Heart.jpg";
import EmeraldImg from "../../assets/images/emerald.jpg";
import CushionImg from "../../assets/images/Cushion.jpg";
import AsscherImg from "../../assets/images/Asscher.jpg";

import Blue from "../../assets/images/Blue.png";
import Pink from "../../assets/images/Pink.png";
import Yellow from "../../assets/images/Yellow.png";
import Brown from "../../assets/images/Brown.png";
import Green from "../../assets/images/Green.png";
import Gray from "../../assets/images/Gray.png";
import Black from "../../assets/images/Black.png";
import Red from "../../assets/images/Red.png";
import Purple from "../../assets/images/Purple.png";
import Chameleon from "../../assets/images/Chameleon.png";
import Violet from "../../assets/images/Violet.png";

import Filter_icon from "../../assets/images/filter_icon.png";
import CatalogDiamondDataService from "../../services/catalog-diamond.service";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import searchDiamondService from "../../services/search-diamond.service";

import space__icon from "../../assets/images/space__icon.png";
import { NotificationManager } from "react-notifications";

const ColorDiamondSearch = () => {
  const initialViewMyColorData = {
    colors: [],
    colorIsSelected: {
      Blue: false,
      Pink: false,
      Yellow: false,
      Brown: false,
      Green: false,
      Gray: false,
      Black: false,
      Red: false,
      Purple: false,
      Chameleon: false,
      Violet: false,
      All: false,
    },
    Shapes: [],
    ShapeIsSelected: {
      Round: false,
      Radiant: false,
      Princess: false,
      Pear: false,
      Oval: false,
      Marquise: false,
      Heart: false,
      Emerald: false,
      Cushion: false,
      Asscher: false,
      All: false,
    },
    permission: [],
    minCarat: 0,
    maxCarat: 20,
    fancyIntensity: "",
    overtone1: [],
    overtone2: [],
    clarityFrom: null,
    clarityTo: null,
    polishTo: null,
    polishFrom: null,
    symmetryFrom: null,
    symmetryTo: null,
    cutFrom: null,
    cutTo: null,
    minOverallPrice: 0,
    maxOverallPrice: 100000,
    minPriceperCaretRange: 0,
    maxPriceperCaretRange: 100000,
    minLWRatioMeasurements: 0,
    maxLWRatioMeasurements: 1000,
    location: null,
    eyeClean: [],
    fluorescenceIntensityFrom: null,
    fluorescenceIntensityTo: null,
    fluorescenceColor: [],
    dealers: [],
    certificates: [],
    lengthFrom: null,
    lengthTo: null,
    widthFrom: null,
    widthTo: null,
    heightFrom: null,
    heightTo: null,

    Radio: "",
    shade: [],
    centralInclusionRange: [],
    blackInclusionRange: [],
    milkyInclusionRange: [],
    girdleRangeFrom: null,
    girdleRangeTo: null,
    crownAngleRangeFrom: "",
    crownAngleRangeTo: "",
    pavilionAngleRangeFrom: "",
    pavilionAngleRangeTo: "",
    keytoSymbols: [],
    culetRangeFrom: null,
    culetRangeTo: null,
    culetConditionRangeFrom: null,
    culetConditionRangeTo: null,
    treatment: [],
    depthFrom: "",
    depthTo: "",
    tableFrom: "",
    tableTo: "",
    matchedPairs: [],
    hasVideo: false,
  };

  const [state, setState] = useState(initialViewMyColorData);

  const [
    permissionsForDiamondCatalogList,
    setPermissionsForDiamondCatalogList,
  ] = useState([]);
  const [dealersForDiamondCatalogList, setDealersForDiamondCatalogList] =
    useState([]);
  const [fancyDiamondList, setFancyDiamondList] = useState([]);
  const [
    certificatesForDiamondCatalogList,
    setCertificatesForDiamondCatalogList,
  ] = useState([]);
  const [overToneForDiamondCatalogList, setOverToneForDiamondCatalogList] =
    useState([]);
  const [fancyIntensityList, setFancyIntensityList] = useState([]);
  const [locationsForDiamondCatalogList, setLocationsForDiamondCatalogList] =
    useState([]);
  const [diamondPolishList, setDiamondPolishList] = useState([]);
  const [diamondSymmetryList, setDiamondSymmetryList] = useState([]);
  const [diamondCutGradeList, setDiamondCutGradeList] = useState([]);
  const [diamondClarityList, setClarityList] = useState([]);
  const [diamondFluorescenceColorList, setDiamondFluorescenceColorList] =
    useState([]);
  const [disableBtn, setDisableBtn] = useState(true);

  const [caratSlideValue, setCaratSlideValue] = useState([0, 20]);
  const [overallPriceValue, setOverallPriceValue] = useState([0, 100000]);
  const [pricePerCaratValue, setPricePerCaratValue] = useState([0, 100000]);
  const [lWRatioMeasurementsValue, setLRatioMeasurementsValue] = useState([
    0, 1000,
  ]);

  const { Option } = Select;
  const [hideShowFilter, setHideShowFilter] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [ViewMyColorData, setViewMyColorData] = useState(
    initialViewMyColorData
  );
  const [msgData, setMsgData] = useState("");
  const [msgLoading, setMsgLoading] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);

  const [isEmail, setIsEmail] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [emailCertificat, setEmailCertificat] = useState({});
  const [errorEmailCertificat, setErrorEmailCertificat] = useState({});
  const [EmailLoading, setEmailLoading] = useState(false);
  const [validation, setValidation] = useState({
    crownAngleRangeFromVal: "",
    crownAngleRangeToVal: "",
  });
  const [btnValue, setBtnValue] = useState(1);
  const [sortColumnName, setSortColumnName] = useState("tmpColumn Asc");
  const [sortType, setSortType] = useState("Asc");
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerId, setDealerId] = useState();
  const [currencySign, setCurrencySign] = useState("$");

  const handleSendFriendemail = () => {
    try {
      let inputData = {
        name: emailCertificat.Name,
        email: emailCertificat.Email,
        friendsName: emailCertificat.FriendsName,
        friendsEmail: emailCertificat.FriendsEmail,
        comments: emailCertificat.Comments,
        dealerID: parseInt(dealerId),
        dInventoryID: parseInt(selectedRowDetails.intDiamondId),
      };
      setEmailLoading(true);
      CatalogDiamondDataService.SendFriendemail(inputData)
        .then((response) => {
          var message = response.data.message;

          if (message === "Success") {
            setEmailLoading(false);
            setVisible3(false);
            NotificationManager.success("Mail sent successfully.");
          } else {
            setEmailLoading(false);
            NotificationManager.error("Mail not sent.");
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error);
        });
    } catch (error) {
      setEmailLoading(false);
      console.log(error);
    }
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
  };

  const handleOpenIsEmail = () => {
    setIsEmail(true);
  };
  const handleCloseIsEmail = () => {
    setIsEmail(false);
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
  };
  const handleEmailCertificatChange = (e) => {
    const { name, value } = e.target;
    emailCertificat[name] = value;
    errorEmailCertificat[name] = null;
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...errorEmailCertificat,
    }));
  };
  const handleValidEmailCertificat = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var isValid = true;
    var tempEmailCertificat = emailCertificat;
    var tempErrorEmailCertificat = errorEmailCertificat;
    if (!tempEmailCertificat["Name"]) {
      tempErrorEmailCertificat["Name"] = traslate("Enter Name.");
      isValid = false;
    } else {
      tempErrorEmailCertificat["Name"] = null;
    }

    if (!tempEmailCertificat["Email"]) {
      tempErrorEmailCertificat["Email"] = traslate("Enter Email Address.");
      isValid = false;
    } else if (!tempEmailCertificat["Email"].trim()) {
      tempErrorEmailCertificat["Email"] = traslate("Enter Email Address.");
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["Email"] = traslate("Email is not valid.");
      isValid = false;
    }

    if (!tempEmailCertificat["FriendsName"]) {
      tempErrorEmailCertificat["FriendsName"] = traslate(
        "Enter Friend's Name."
      );
      isValid = false;
    } else {
      tempErrorEmailCertificat["FriendsName"] = null;
    }

    if (!tempEmailCertificat["FriendsEmail"]) {
      tempErrorEmailCertificat["FriendsEmail"] = traslate(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (!tempEmailCertificat["FriendsEmail"].trim()) {
      tempErrorEmailCertificat["FriendsEmail"] = traslate(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["FriendsEmail"] = traslate(
        "Email is not valid."
      );
      isValid = false;
    }

    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...tempErrorEmailCertificat,
    }));
    return isValid;
  };

  const handleCertificateEmailSend = () => {
    if (handleValidEmailCertificat()) {
      handleSendFriendemail();
    }
  };

  const handleSendMsg = () => {
    try {
      let inputData = {
        dealrId: selectedRowDetails.dealerID,
        msg: msgData,
        intDiamondId: selectedRowDetails.intDiamondId,
      };
      setMsgLoading(true);
      CatalogDiamondDataService.SendMsg(inputData)
        .then((response) => {
          var message = response.data.message;

          setMsgLoading(false);

          if (message === "Success") {
            setVisible1(false);
            NotificationManager.success("Message sent sucessfully.");
          } else {
            NotificationManager.error("Message not sent.");
          }
        })
        .catch((error) => {
          setMsgLoading(false);
          console.log(error);
        });
    } catch (error) {
      setMsgLoading(false);
      console.log(error);
    }
  };

  const handleMsgValidation = () => {
    if (msgData) {
      if (msgData.trim() === "") {
        setMsgError("Please enter message.");
      } else {
        handleSendMsg();
      }
    } else {
      setMsgError("Please enter message.");
    }
  };

  const handleCancel1 = () => {
    setVisible1(false);
    setMsgData("");
    setMsgError("");
  };
  const handleOk1 = (item) => {
    setSelectedRowDetails(item);
    setMsgData("");
    setMsgError("");
    setVisible1(true);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };

  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleOnChangeRadio = (event) => {
    if (
      validation.crownAngleRangeFromVal === "" &&
      validation.crownAngleRangeToVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
    setViewMyColorData({
      ...ViewMyColorData,
      Radio: event.target.name,
    });
  };
  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (isSelect === "select") {
      setViewMyColorData({
        ...ViewMyColorData,
        [name]: e,
      });
    } else {
      setViewMyColorData({
        ...ViewMyColorData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "crownAngleRangeFrom") {
        if (e.target.value > 100 || e.target.value < 0) {
          validation.crownAngleRangeFromVal = "Invalid Range";
          setDisableBtn(true);
        } else {
          validation.crownAngleRangeFromVal = "";
          setDisableBtn(false);
        }
      }
      if (e.target.name === "crownAngleRangeTo") {
        if (e.target.value > 100 || e.target.value < 0) {
          validation.crownAngleRangeToVal = "Invalid Range";
          setDisableBtn(true);
        } else {
          validation.crownAngleRangeToVal = "";
          setDisableBtn(false);
        }
      }

      setValidation(validation);
      if (
        validation.crownAngleRangeFromVal === "" &&
        validation.crownAngleRangeToVal === ""
      ) {
        setDisableBtn(false);
        setBtnValue(1);
      } else {
        setDisableBtn(true);
        setBtnValue(0);
      }
    }
  };

  const onNumberChange = (e, name) => {
    if (
      validation.crownAngleRangeFromVal === "" &&
      validation.crownAngleRangeToVal === ""
    ) {
      setDisableBtn(false);
      setBtnValue(1);
    } else {
      setDisableBtn(true);
      setBtnValue(0);
    }
    let tempState = { ...ViewMyColorData };
    tempState[name] = e.target.value;

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleOk = () => {
    setVisible1(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancel2 = () => {
    setVisible2(false);
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  /*Custom Binding Starts*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);

        handleGetDealersForDiamondCatalogList(
          loginDetials.responseData.dealerId
        );
        handleGetFancyDiamondList(
          loginDetials.responseData.dealerId,
          pageNo,
          pageSize,
          sortColumnName,
          sortType
        );
      }
    }
  }, []);

  const handleGetFancyDiamondList = async (
    dealerId,
    pageNo,
    pageSize,
    sortColumnName,
    sortType,
    isReset
  ) => {
    setPageNo(pageNo);
    setPageSize(pageSize);
    let tempshapes = Object.keys(state.ShapeIsSelected)
      .filter((k) => state.ShapeIsSelected[k] === true)
      .map((x) => x)
      .join(",");
    let inputData = {
      dealerId: dealerId.toString(),
      shapes: tempshapes,
      colorMin: "-1",
      colorMax: "-1",
      clarityMin: isReset
        ? "-1"
        : ViewMyColorData.clarityFrom === null
        ? "-1"
        : ViewMyColorData.clarityFrom.toString(),
      clarityMax: isReset
        ? "-1"
        : ViewMyColorData.clarityTo === null
        ? "-1"
        : ViewMyColorData.clarityTo.toString(),
      caratMin: isReset ? "0" : caratSlideValue[0] + "", //"0",
      caratMax: isReset ? "20" : caratSlideValue[1] + "", //"20",
      priceMin: isReset ? "0" : overallPriceValue[0] + "",
      priceMax: isReset ? "100000" : overallPriceValue[1] + "",
      certificate: isReset ? "" : ViewMyColorData.certificates + "",
      cutGradeMin: isReset
        ? "-1"
        : ViewMyColorData.cutFrom === null
        ? "-1"
        : ViewMyColorData.cutFrom.toString(),
      cutGradeMax: isReset
        ? "-1"
        : ViewMyColorData.cutTo === null
        ? "-1"
        : ViewMyColorData.cutTo.toString(),
      symmetryMin: isReset
        ? "-1"
        : ViewMyColorData.symmetryFrom === null
        ? "-1"
        : ViewMyColorData.symmetryFrom.toString(),
      symmetryMax: isReset
        ? "-1"
        : ViewMyColorData.symmetryTo === null
        ? "-1"
        : ViewMyColorData.symmetryTo.toString(),
      polishMin: isReset
        ? "-1"
        : ViewMyColorData.polishFrom === null
        ? "-1"
        : ViewMyColorData.polishFrom.toString(),
      polishMax: isReset
        ? "-1"
        : ViewMyColorData.polishTo === null
        ? "-1"
        : ViewMyColorData.polishTo.toString(),
      depthMin: isReset ? "-1" : ViewMyColorData.depthFrom || "-1",
      depthMax: isReset ? "-1" : ViewMyColorData.depthTo || "-1",
      tableMin: isReset ? "-1" : ViewMyColorData.tableFrom || "-1",
      tableMax: isReset ? "-1" : ViewMyColorData.tableTo || "-1",
      fluorescenceMin: isReset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom === null
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom,
      fluorescenceMax: isReset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityTo === null
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityTo,
      pairCode: "",
      orderBy:
        sortColumnName == "txtStockNo"
          ? "DealerInventoryNo"
          : sortColumnName == "txtShape"
          ? "Cut"
          : sortColumnName == "fltCarat"
          ? "Size"
          : sortColumnName == "txtCulet"
          ? "CutGrade"
          : sortColumnName == "txtFancyColorMainBody"
          ? "Color"
          : sortColumnName == "txtClarity"
          ? "ClarityID"
          : sortColumnName == "fltRapPercent"
          ? "OffRapaport"
          : sortColumnName == "fltPrice"
          ? "FltPrice"
          : sortColumnName == "fltDepth"
          ? "Depth"
          : sortColumnName == "fltTable"
          ? "TableMeasure"
          : sortColumnName == "txtCertificate"
          ? "Certificate"
          : sortColumnName == "txtSymmetry"
          ? "Symmetry"
          : sortColumnName == "dealer"
          ? "DealerID"
          : sortColumnName == "txtFluorescence"
          ? "Flouresence"
          : sortColumnName == "txtMeasurements"
          ? "Measurements"
          : sortColumnName == "txtPolish"
          ? "Polish"
          : sortColumnName == "realPrice"
          ? "tmpColumn"
          : "tmpColumn",
      orderType: sortType ? sortType : "Asc",
      pageNumber: pageNo + "",
      pageSize: pageSize + "",
      invenID: "",
      strDetailLinkURL: "dealerlink",
      did: "",
      caratPriceMin: isReset ? "0" : pricePerCaratValue[0] + "", // "0",
      caratPriceMax: isReset ? "100000" : pricePerCaratValue[1] + "", // "100000",
      ratioMin: isReset ? "0" : lWRatioMeasurementsValue[0] + "", // "0",
      ratioMax: isReset ? "0" : lWRatioMeasurementsValue[1] + "", //"1000",
      polishList: isReset
        ? "-1"
        : ViewMyColorData.polishFrom === null &&
          ViewMyColorData.polishTo === null
        ? "-1"
        : "",
      symmetryList: isReset
        ? "-1"
        : ViewMyColorData.symmetryFrom === null &&
          ViewMyColorData.symmetryTo === null
        ? "-1"
        : "",
      fluorescenceList: isReset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom === null &&
          ViewMyColorData.fluorescenceIntensityTo === null
        ? "-1"
        : "",
      cutGradeList: isReset
        ? "-1"
        : ViewMyColorData.cutFrom === null && ViewMyColorData.cutTo === null
        ? "-1"
        : "",
      colorList: "-1",
      clarityList: isReset
        ? "-1"
        : ViewMyColorData.clarityFrom === null &&
          ViewMyColorData.clarityTo === null
        ? "-1"
        : "",
      sOrigin: "",
      intOptimize: 0,
      intCulet1: isReset
        ? 0
        : ViewMyColorData.culetRangeFrom === null
        ? 0
        : parseInt(ViewMyColorData.culetRangeFrom),
      intCulet2: isReset
        ? 20
        : ViewMyColorData.culetRangeTo === null
        ? 20
        : parseInt(ViewMyColorData.culetRangeTo),
      intCuletCondition1: isReset
        ? 0
        : ViewMyColorData.culetConditionRangeFrom === null
        ? 0
        : parseInt(ViewMyColorData.culetConditionRangeFrom),
      intCuletCondition2: isReset
        ? 20
        : ViewMyColorData.culetConditionRangeTo === null
        ? 20
        : parseInt(ViewMyColorData.culetConditionRangeTo),
      fltCrownAngle1: isReset
        ? 0
        : ViewMyColorData.crownAngleRangeFrom === ""
        ? 0
        : parseInt(ViewMyColorData.crownAngleRangeFrom),
      fltCrownAngle2: isReset
        ? 100
        : ViewMyColorData.crownAngleRangeTo === ""
        ? 100
        : parseInt(ViewMyColorData.crownAngleRangeTo),
      fltPavilionAngle1: isReset
        ? 0
        : ViewMyColorData.pavilionAngleRangeFrom === ""
        ? 0
        : parseInt(ViewMyColorData.pavilionAngleRangeFrom),
      fltPavilionAngle2: isReset
        ? 100
        : ViewMyColorData.pavilionAngleRangeTo === ""
        ? 100
        : parseInt(ViewMyColorData.pavilionAngleRangeTo),
      intGirdle1: isReset
        ? 0
        : ViewMyColorData.girdleRangeFrom === null
        ? 0
        : parseInt(ViewMyColorData.girdleRangeFrom),
      intGirdle2: isReset
        ? 100
        : ViewMyColorData.girdleRangeTo === null
        ? 100
        : parseInt(ViewMyColorData.girdleRangeTo),
      intCutGrade: 0,
      uploadDateMin: "",
      uploadDateMax: "",
      strSelectedDealers: isReset ? "" : ViewMyColorData.dealers + "",
      selectedDealerConunt: 1,
      fancyColor: isReset ? "" : colorData === "," ? "" : colorData,
      intIntensity: isReset
        ? -1
        : ViewMyColorData.fancyIntensity === ""
        ? -1
        : parseInt(ViewMyColorData.fancyIntensity),
      overtone: isReset ? "" : ViewMyColorData.overtone1 + "",
      overtone2: isReset ? "" : ViewMyColorData.overtone2 + "",
      strMatchedPairs: isReset ? "" : ViewMyColorData.matchedPairs.toString(),
      fltWidth1: isReset
        ? "-1"
        : ViewMyColorData.widthFrom === null
        ? "-1"
        : ViewMyColorData.widthFrom?.toString(),
      fltWidth2: isReset
        ? "-1"
        : ViewMyColorData.widthTo === null
        ? "-1"
        : ViewMyColorData.widthTo?.toString(),
      fltHeight1: isReset
        ? "-1"
        : ViewMyColorData.heightFrom === null
        ? "-1"
        : ViewMyColorData.heightFrom?.toString(),
      fltHeight2: isReset
        ? "-1"
        : ViewMyColorData.heightTo === null
        ? "-1"
        : ViewMyColorData.heightTo?.toString(),
      fltLength1: isReset
        ? "-1"
        : ViewMyColorData.lengthFrom === null
        ? "-1"
        : ViewMyColorData.lengthFrom?.toString(),
      fltLength2: isReset
        ? "-1"
        : ViewMyColorData.lengthTo === null
        ? "-1"
        : ViewMyColorData.lengthTo?.toString(),
      strSelectedInvID: "",
      flagDD: "0",
      checkInventory: "Catalog",
      country: isReset
        ? ""
        : ViewMyColorData.location === null
        ? ""
        : ViewMyColorData.location,
      shade: isReset ? "" : ViewMyColorData.shade + "",
      treatment: isReset ? "" : ViewMyColorData.treatment + "",
      keytosymbol: isReset ? "" : ViewMyColorData.keytoSymbols + "",
      fluorescenceColor: isReset ? "" : ViewMyColorData.fluorescenceColor + "",
      blackInclusion: isReset ? "" : ViewMyColorData.blackInclusionRange + "",
      centralInclusion: isReset
        ? ""
        : ViewMyColorData.centralInclusionRange + "",
      milkyInclusion: isReset ? "" : ViewMyColorData.milkyInclusionRange + "",
      eyeclean: isReset ? "" : ViewMyColorData.eyeClean + "",
      ratioType: isReset ? "" : ViewMyColorData.Radio,
      permission: isReset
        ? ""
        : ViewMyColorData.permission === null
        ? ""
        : ViewMyColorData.permission.toString(),
      video: isReset ? "0" : ViewMyColorData.hasVideo === true ? "1" : "0",
    };
    setGridLoading(true);
    try {
      await searchDiamondService
        .GetALLFancyDiamondList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setFancyDiamondList(responseData);
              if (responseData.length > 0) {
                setTotalCount(responseData[0].intTotalRecords);
              } else {
                setTotalCount(0);
              }
              setGridLoading(false);
            } else {
              setFancyDiamondList([]);
              setGridLoading(false);
            }
          } else {
            setFancyDiamondList([]);
          }
        })
        .catch((error) => {
          setGridLoading(false);
          console.log(error);
        });
    } catch (error) {
      setGridLoading(false);
      console.log(error);
    }
  };

  const handleGetPermissionsForDiamondCatalogList = async () => {
    try {
      let inputData = {
        type: "",
      };
      await CatalogDiamondDataService.GetPermissionsForDiamondCatalogList(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetOverToneForDiamondCatalogList();
          if (message === "Success") {
            if (responseData) {
              setPermissionsForDiamondCatalogList(responseData);
            } else {
              setPermissionsForDiamondCatalogList([]);
            }
          } else {
            setPermissionsForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetLocationsForDiamondCatalogList = async () => {
    try {
      let inputData = {
        type: "",
      };
      await CatalogDiamondDataService.GetLocationsForDiamondCatalogList(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setLocationsForDiamondCatalogList(responseData);
            } else {
              setLocationsForDiamondCatalogList([]);
            }
          } else {
            setLocationsForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDealersForDiamondCatalogList = async (id) => {
    try {
      let inputData = {
        type: "Fancy",
        dealerId: id,
      };
      await CatalogDiamondDataService.GetDealersForDiamondSearch(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetCertificatesForDiamondCatalogList();
          if (message === "Success") {
            if (responseData) {
              setCurrencySign(responseData?.currencySign || "$");
              setDealersForDiamondCatalogList(responseData);
            } else {
              setDealersForDiamondCatalogList([]);
            }
          } else {
            setDealersForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCertificatesForDiamondCatalogList = async () => {
    try {
      let inputData = {
        type: "",
      };
      await CatalogDiamondDataService.GetCertificatesForDiamondCatalogList(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetAllFancyDropDownList();
          if (message === "Success") {
            if (responseData) {
              setCertificatesForDiamondCatalogList(responseData);
            } else {
              setCertificatesForDiamondCatalogList([]);
            }
          } else {
            setCertificatesForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetOverToneForDiamondCatalogList = async () => {
    try {
      let inputData = {
        type: "",
      };
      await CatalogDiamondDataService.GetOverToneForDiamondCatalogList(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetLocationsForDiamondCatalogList();
          if (message === "Success") {
            if (responseData) {
              setOverToneForDiamondCatalogList(responseData);
            } else {
              setOverToneForDiamondCatalogList([]);
            }
          } else {
            setOverToneForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllFancyDropDownList = async () => {
    try {
      await CatalogDiamondDataService.GetAllFancyDropDownList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          var fancyIntensity = response.data.responseData.diamondIntensity;
          var diamondPolish = response.data.responseData.diamondPolish;
          var diamondSymmetry = response.data.responseData.diamondSymmetry;
          var diamondCutGrade = response.data.responseData.diamondCutGrade;
          var clarity = response.data.responseData.clarity;
          var diamondFluorescenceColor =
            response.data.responseData.diamondFluorescenceColor;
          handleGetPermissionsForDiamondCatalogList();
          if (message === "Success") {
            if (responseData) {
              setFancyIntensityList(fancyIntensity);
              setDiamondPolishList(diamondPolish);
              setDiamondSymmetryList(diamondSymmetry);
              setDiamondCutGradeList(diamondCutGrade);
              setClarityList(clarity);
              setDiamondFluorescenceColorList(diamondFluorescenceColor);
            } else {
              setFancyIntensityList([]);
              setDiamondPolishList([]);
            }
          } else {
            setFancyIntensityList([]);
            setDiamondPolishList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilters = () => {
    setHideShowFilter(true);
    handleGetFancyDiamondList(
      loginDetials.responseData.dealerId,
      1,
      10,
      sortColumnName,
      sortType,
      false
    );
    setDisableBtn(true);
  };

  //Initial Watch Data

  const resetFilters = () => {
    setViewMyColorData(initialViewMyColorData);
    setCaratSlideValue([ViewMyColorData.minCarat, ViewMyColorData.maxCarat]);
    setOverallPriceValue([
      ViewMyColorData.minOverallPrice,
      ViewMyColorData.maxOverallPrice,
    ]);
    setPricePerCaratValue([
      ViewMyColorData.minPriceperCaretRange,
      ViewMyColorData.maxPriceperCaretRange,
    ]);
    setLRatioMeasurementsValue([
      ViewMyColorData.minLWRatioMeasurements,
      ViewMyColorData.maxLWRatioMeasurements,
    ]);
    setDisableBtn(false);
    handleGetFancyDiamondList(1, 10, sortColumnName, sortType, "reset");
  };

  const onShapeClick = (shape) => {
    setDisableBtn(false);

    if (shape === "All") {
      ViewMyColorData.ShapeIsSelected.All =
        !ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Round =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Radiant =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Princess =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Pear =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Oval =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Marquise =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Heart =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Emerald =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Cushion =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Asscher =
        ViewMyColorData.ShapeIsSelected.All;
    } else {
      ViewMyColorData.ShapeIsSelected.All = false;
      ViewMyColorData.ShapeIsSelected[shape] =
        !ViewMyColorData.ShapeIsSelected[shape];
    }
    setViewMyColorData((prevState) => ({
      ...prevState,
      ...ViewMyColorData,
    }));
  };

  const onColorClick = (color) => {
    setDisableBtn(false);

    if (color === "All") {
      ViewMyColorData.colorIsSelected.All =
        !ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Blue =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Pink =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Yellow =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Brown =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Green =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Gray =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Black =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Red = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Purple =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Chameleon =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Violet =
        ViewMyColorData.colorIsSelected.All;
    } else {
      ViewMyColorData.colorIsSelected.All = false;
      ViewMyColorData.colorIsSelected[color] =
        !ViewMyColorData.colorIsSelected[color];
    }

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...ViewMyColorData,
    }));
  };

  var shapesObj = ViewMyColorData.ShapeIsSelected;
  var shapesKeys = Object.keys(shapesObj);
  var filteredShape = shapesKeys.filter(function (key) {
    return shapesObj[key];
  });
  var shapesData = filteredShape + ",";

  var colorObj = ViewMyColorData.colorIsSelected;
  var colorKeys = Object.keys(colorObj);
  var filteredColor = colorKeys.filter(function (key) {
    return colorObj[key];
  });
  var colorData = filteredColor + ",";

  /*Converting into String Format ends*/

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[.0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleCertificateOpen = (url) => {
    window.open(
      url,
      "CERTVIEW",
      "scrollbars=yes,resizable=no,width=650,height=630"
    );
  };
  const handleOpenDetailsModal = (row) => {
    setSelectedRowDetails(row);
    setVisible(true);
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";

    var obj = {};
    var filter = "";

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "tmpColumn Asc";
      setSortColumnName(column);
      setSortType(type);
    }

    if (filters.txtStockNo) {
      obj = {
        columnName: "txtStockNo",
        columnValue: filters.txtStockNo[0],
        columnType: "Input",
      };
      filter = obj.columnValue;
    }

    handleGetFancyDiamondList(
      dealerId,
      pagination.current,
      pagination.pageSize,
      column,
      type,
      filter,
      "",
      false
    );
  };

  const filterOptionForDropdown = (input, option) => {
    const inputValue = typeof input === "number" ? input.toString() : input;
    let childrenValue = "";

    if (typeof option?.children === "number") {
      childrenValue = option.children.toString();
    } else if (typeof option?.children === "string") {
      childrenValue = option.children.toLowerCase();
    } else if (option?.children?.[1]?.props?.id) {
      childrenValue = option.children[1].props.id.toLowerCase();
    }

    return (
      childrenValue &&
      inputValue &&
      childrenValue.includes(inputValue.toLowerCase())
    );
  };
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };
  const firstsetOverAllSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...overallPriceValue];
    var StrValue = e.target.value && e.target.value.replace(currencySign, "");
    var Value = StrValue.replace(/,/g, "");
    if (Value.replace(/,/g, "") > ViewMyColorData.maxOverallPrice) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setOverallPriceValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setOverallPriceValue(newArr);
        } else {
          newArr[0] = Value;
          setOverallPriceValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setOverallPriceValue(newArr);
      } else {
        if (newArr[1] == 0) {
          newArr[1] = Value;
          setOverallPriceValue(newArr);
        } else {
          newArr[1] = Value;
          setOverallPriceValue(newArr);
        }
      }
    }
  };
  const firstsetCaratSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...caratSlideValue];
    let StrValue = e.target.value?.replace("$", "");
    let Value = StrValue.replace(/,/g, "");
    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      ViewMyColorData.maxCarat
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          let dottedamount = Value.split(".");
          let amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[0] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          let dottedamount = Value.split(".");
          let amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[1] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    }
  };
  const firstsetPricePerCaratValueSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...pricePerCaratValue];

    var StrValue = e.target.value && e.target.value.replace(currencySign, "");
    var Value = StrValue.replace(/,/g, "");

    if (
      Number(parseFloat(Value.replace(/,/g, ""))) >
      ViewMyColorData.maxPriceperCaretRange
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPricePerCaratValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setPricePerCaratValue(newArr);
        } else {
          newArr[0] = Value;
          setPricePerCaratValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPricePerCaratValue(newArr);
      } else {
        if (newArr[1] == 0) {
          newArr[1] = Value;
          setPricePerCaratValue(newArr);
        } else {
          newArr[1] = Value;
          setPricePerCaratValue(newArr);
        }
      }
    }
  };
  const firstsetlWRatioMeasurementsValueSlideValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...lWRatioMeasurementsValue];

    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      ViewMyColorData.maxLWRatioMeasurements
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setLRatioMeasurementsValue(newArr);
      } else {
        newArr[0] = e.target.value.replace(/,0/g, "").replace(0, "");
        setLRatioMeasurementsValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setLRatioMeasurementsValue(newArr);
      } else {
        newArr[1] = e.target.value.replace(/,0/g, "").replace(0, "");
        setLRatioMeasurementsValue(newArr);
      }
    }
  };
  function formatMoney(amount) {
    try {
      if (amount?.toString().includes(".")) {
        var dottedamount = amount.split(".");
        var amount =
          Number(dottedamount[0]).toLocaleString("en-IN") +
          "." +
          dottedamount[1];
      } else {
        var amount = Number(amount).toLocaleString("en-IN");
      }
      return amount;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="vdiamond_section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="workarea__heading mt-0">
                      {traslate("Color Diamond Search")}
                    </h4>
                  </div>
                  <div className="col-md-6 ml-auto text-right text-left-mobile">
                    <button
                      className="primary-btn mobile__bottomspace"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {traslate("Show Filter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {traslate("Hide Filter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="filter_section">
                  <div
                    className={
                      hideShowFilter
                        ? "form__fields filter_inpsection hide"
                        : "form__fields filter_inpsection"
                    }
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="input__block">
                            <label> {traslate("Colors")} </label>
                            <div className="imgselct__div">
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Blue
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Blue")}
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Blue")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Pink
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Pink")}
                              >
                                <img src={Pink} alt="" />
                                <span> {traslate("Pink")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Yellow
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Yellow")}
                              >
                                <img src={Yellow} alt="" />
                                <span> {traslate("Yellow")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Brown
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Brown")}
                              >
                                <img src={Brown} alt="" />
                                <span> {traslate("Brown")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Green
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Green")}
                              >
                                <img src={Green} alt="" />
                                <span> {traslate("Green")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Gray
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Gray")}
                              >
                                <img src={Gray} alt="" />
                                <span> {traslate("Gray")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Black
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Black")}
                              >
                                <img src={Black} alt="" />
                                <span> {traslate("Black")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Red
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Red")}
                              >
                                <img src={Red} alt="" />
                                <span> {traslate("Red")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Purple
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Purple")}
                              >
                                <img src={Purple} alt="" />
                                <span> {traslate("Purple")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Chameleon
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Chameleon")}
                              >
                                <img src={Chameleon} alt="" />
                                <span> {traslate("Chameleon")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.Violet
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onColorClick("Violet")}
                              >
                                <img src={Violet} alt="" />
                                <span> {traslate("Violet")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.colorIsSelected.All
                                    ? "selected all__div"
                                    : "all__div"
                                }
                                onClick={() => onColorClick("All")}
                              >
                                <span> {traslate("All")} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="input__block">
                            <label> {traslate("Shapes")} </label>
                            <div className="imgselct__div">
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Round
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Round")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Round")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Radiant
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Radiant")}
                              >
                                <img src={RadiantImg} alt="" />
                                <span> {traslate("Radiant")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Princess
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Princess")}
                              >
                                <img src={PrincessImg} alt="" />
                                <span> {traslate("Princess")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Pear
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Pear")}
                              >
                                <img src={PearImg} alt="" />
                                <span> {traslate("Pear")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Oval
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Oval")}
                              >
                                <img src={OvalImg} alt="" />
                                <span> {traslate("Oval")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Marquise
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Marquise")}
                              >
                                <img src={MarquiseImg} alt="" />
                                <span> {traslate("Marquise")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Heart
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Heart")}
                              >
                                <img src={HeartImg} alt="" />
                                <span> {traslate("Heart")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Emerald
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Emerald")}
                              >
                                <img src={EmeraldImg} alt="" />
                                <span> {traslate("Emerald")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Cushion
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Cushion")}
                              >
                                <img src={CushionImg} alt="" />
                                <span> {traslate("Cushion")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.Asscher
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Asscher")}
                              >
                                <img src={AsscherImg} alt="" />
                                <span> {traslate("Asscher")} </span>
                              </div>
                              <div
                                className={
                                  ViewMyColorData.ShapeIsSelected.All
                                    ? "selected all__div"
                                    : "all__div"
                                }
                                onClick={() => onShapeClick("All")}
                              >
                                <span> {traslate("All")} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Permission")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              mode="multiple"
                              showArrow={true}
                              placeholder="Select Permissions"
                              name="permission"
                              value={
                                ViewMyColorData.permission
                                  ? ViewMyColorData.permission
                                  : []
                              }
                              defaultvalue=""
                              optionFilterProp="children"
                              onChange={(e) =>
                                handleOnChange(e, "select", "permission")
                              }
                            >
                              {permissionsForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.value}>
                                      {item.text}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Carats")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={caratSlideValue}
                                min={ViewMyColorData.minCarat}
                                max={ViewMyColorData.maxCarat}
                                onChange={(e) => {
                                  setCaratSlideValue(e);
                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[0].toLocaleString(
                                      "en-US"
                                    )}
                                    onChange={(e) => {
                                      firstsetCaratSlideValue(e, 0);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[1].toLocaleString(
                                      "en-US"
                                    )}
                                    onChange={(e) => {
                                      firstsetCaratSlideValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Fancy Intensity")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="fancyIntensity"
                              value={
                                ViewMyColorData.fancyIntensity
                                  ? ViewMyColorData.fancyIntensity
                                  : ""
                              }
                              optionFilterProp="children"
                              onChange={(e) =>
                                handleOnChange(e, "select", "fancyIntensity")
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value={""}> {traslate("Any")} </Option>
                              {fancyIntensityList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option
                                      value={item.intensityId}
                                      key={item.intensityId}
                                    >
                                      {item.intensityValue}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Overtone 1")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Overtone 1"
                              name="overtone1"
                              value={
                                ViewMyColorData.overtone1
                                  ? ViewMyColorData.overtone1
                                  : []
                              }
                              optionFilterProp="children"
                              onChange={(e) =>
                                handleOnChange(e, "select", "overtone1")
                              }
                            >
                              {overToneForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option
                                      value={item.overtoneValue}
                                      key={item.overtoneID}
                                    >
                                      {item.overtoneValue}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Overtone 2")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              value={
                                ViewMyColorData.overtone2
                                  ? ViewMyColorData.overtone2
                                  : []
                              }
                              placeholder="Select Category"
                              name="overtone2"
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "overtone2")
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {overToneForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option
                                      value={item.overtoneValue}
                                      key={item.overtoneID}
                                    >
                                      {item.overtoneValue}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Clarity")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="clarityFrom"
                                  value={
                                    ViewMyColorData.clarityFrom
                                      ? ViewMyColorData.clarityFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "clarityFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondClarityList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          // value={item.claritydetails} change from backend
                                          value={item.clarityID}
                                          key={item.clarityID}
                                        >
                                          {item.claritydetails}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="clarityTo"
                                  value={
                                    ViewMyColorData.clarityTo
                                      ? ViewMyColorData.clarityTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "clarityTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondClarityList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.clarityID}
                                          key={item.clarityID}
                                        >
                                          {item.claritydetails}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Polish")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="polishFrom"
                                  value={
                                    ViewMyColorData.polishFrom
                                      ? ViewMyColorData.polishFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "polishFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondPolishList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.polishID}
                                          // value={item.polishValue} change from backend
                                          key={item.polishID}
                                        >
                                          {item.polishValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="polishTo"
                                  value={
                                    ViewMyColorData.polishTo
                                      ? ViewMyColorData.polishTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "polishTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondPolishList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          // value={item.polishValue}
                                          value={item.polishID}
                                          key={item.polishID}
                                        >
                                          {item.polishValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Symmetry")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="symmetryFrom"
                                  value={
                                    ViewMyColorData.symmetryFrom
                                      ? ViewMyColorData.symmetryFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "symmetryFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondSymmetryList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          // value={item.symmetryValue} change from backend
                                          value={item.symmetryID}
                                          key={item.symmetryID}
                                        >
                                          {item.symmetryValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="symmetryTo"
                                  value={
                                    ViewMyColorData.symmetryTo
                                      ? ViewMyColorData.symmetryTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "symmetryTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondSymmetryList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          // value={item.symmetryValue}
                                          value={item.symmetryID}
                                          key={item.symmetryID}
                                        >
                                          {item.symmetryValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Cut")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="cutFrom"
                                  value={
                                    ViewMyColorData.cutFrom
                                      ? ViewMyColorData.cutFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "cutFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondCutGradeList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.cutGradeId}
                                          // value={item.cutGradeValue} change from backend
                                          key={item.cutGradeId}
                                        >
                                          {item.cutGradeValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="cutTo"
                                  value={
                                    ViewMyColorData.cutTo
                                      ? ViewMyColorData.cutTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "cutTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {diamondCutGradeList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          // value={item.cutGradeValue}
                                          value={item.cutGradeId}
                                          key={item.cutGradeId}
                                        >
                                          {item.cutGradeValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Overall Price")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={overallPriceValue}
                                min={ViewMyColorData.minOverallPrice}
                                max={ViewMyColorData.maxOverallPrice}
                                onChange={(e) => {
                                  setOverallPriceValue(e);

                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(overallPriceValue[0])
                                    }
                                    onChange={(e) => {
                                      firstsetOverAllSlideValue(e, 0);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(overallPriceValue[1])
                                    }
                                    onChange={(e) => {
                                      firstsetOverAllSlideValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Price Per Carat Range")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={pricePerCaratValue}
                                min={ViewMyColorData.minPriceperCaretRange}
                                max={ViewMyColorData.maxPriceperCaretRange}
                                onChange={(e) => {
                                  setPricePerCaratValue(e);

                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(pricePerCaratValue[0])
                                    }
                                    onChange={(e) => {
                                      firstsetPricePerCaratValueSlideValue(
                                        e,
                                        0
                                      );
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      currencySign +
                                      formatMoney(pricePerCaratValue[1])
                                    }
                                    onChange={(e) => {
                                      firstsetPricePerCaratValueSlideValue(
                                        e,
                                        1
                                      );
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Location")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="location"
                              optionFilterProp="children"
                              value={
                                ViewMyColorData.location
                                  ? ViewMyColorData.location
                                  : null
                              }
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "location")
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option> {traslate("Any")} </Option>
                              {locationsForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option
                                      value={item.threeLetterISOCode}
                                      key={item.countryID}
                                    >
                                      {item.name}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Eye Clean")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Eye Clean"
                              name="eyeClean"
                              value={
                                ViewMyColorData.eyeClean
                                  ? ViewMyColorData.eyeClean
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "eyeClean")
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0"> {traslate("Any")} </Option>
                              <Option value="1"> {traslate("Yes")} </Option>
                              <Option value="2">
                                {" "}
                                {traslate("Borderline")}{" "}
                              </Option>
                              <Option value="3"> {traslate("No")} </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Fluorescence Intensity")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="fluorescenceIntensityFrom"
                                  value={
                                    ViewMyColorData.fluorescenceIntensityFrom
                                      ? ViewMyColorData.fluorescenceIntensityFrom
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "fluorescenceIntensityFrom"
                                    )
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("None")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Very Slight")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Slight")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Faint")}{" "}
                                  </Option>
                                  <Option value="5">
                                    {" "}
                                    {traslate("Medium")}{" "}
                                  </Option>
                                  <Option value="6">
                                    {" "}
                                    {traslate("Strong")}{" "}
                                  </Option>
                                  <Option value="7">
                                    {" "}
                                    {traslate("Very Strong")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="fluorescenceIntensityTo"
                                  value={
                                    ViewMyColorData.fluorescenceIntensityTo
                                      ? ViewMyColorData.fluorescenceIntensityTo
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "fluorescenceIntensityTo"
                                    )
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("None")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Very Slight")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Slight")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Faint")}{" "}
                                  </Option>
                                  <Option value="5">
                                    {" "}
                                    {traslate("Medium")}{" "}
                                  </Option>
                                  <Option value="6">
                                    {" "}
                                    {traslate("Strong")}{" "}
                                  </Option>
                                  <Option value="7">
                                    {" "}
                                    {traslate("Very Strong")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Fluorescence Color")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Fluorescence Color"
                              name="fluorescenceColor"
                              value={
                                ViewMyColorData.fluorescenceColor
                                  ? ViewMyColorData.fluorescenceColor
                                  : null
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "fluorescenceColor")
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {diamondFluorescenceColorList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option
                                      value={item.fluorescenceValue}
                                      key={item.fluorescenceID}
                                    >
                                      {item.fluorescenceValue}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Dealers")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Select Dealers"
                              name="dealers"
                              value={
                                ViewMyColorData.dealers
                                  ? ViewMyColorData.dealers
                                  : null
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "dealers")
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {/* <Option> {traslate("Any")} </Option> */}
                              {dealersForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option
                                      value={item.dealerID}
                                      key={item.dealerID}
                                    >
                                      {item.dealerCompany}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Certificates")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Select Certificates"
                              name="certificates"
                              value={
                                ViewMyColorData.certificates
                                  ? ViewMyColorData.certificates
                                  : null
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "certificates")
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {/* <Option> {traslate("Any")} </Option> */}
                              {certificatesForDiamondCatalogList.map(
                                (item, index) => {
                                  return (
                                    <React.Fragment>
                                      <Option
                                        value={item.certificateValue}
                                        key={`${item.certificateValue}-${index}`}
                                      >
                                        {item.certificateValue}
                                      </Option>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-2">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Length(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="lengthFrom"
                                  value={
                                    ViewMyColorData.lengthFrom
                                      ? ViewMyColorData.lengthFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "lengthFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="lengthTo"
                                  value={
                                    ViewMyColorData.lengthTo
                                      ? ViewMyColorData.lengthTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "lengthTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Width(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="widthFrom"
                                  value={
                                    ViewMyColorData.widthFrom
                                      ? ViewMyColorData.widthFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "widthFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="widthTo"
                                  value={
                                    ViewMyColorData.widthTo
                                      ? ViewMyColorData.widthTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "widthTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Height(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="heightFrom"
                                  value={
                                    ViewMyColorData.heightFrom
                                      ? ViewMyColorData.heightFrom
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "heightFrom")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="heightTo"
                                  value={
                                    ViewMyColorData.heightTo
                                      ? ViewMyColorData.heightTo
                                      : null
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "heightTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option> {traslate("Any")} </Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label>
                              {" "}
                              {traslate("L/W Ratio Measurements")}{" "}
                            </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={lWRatioMeasurementsValue}
                                min={ViewMyColorData.minLWRatioMeasurements}
                                max={ViewMyColorData.maxLWRatioMeasurements}
                                onChange={(e) => {
                                  setLRatioMeasurementsValue(e);

                                  {
                                    btnValue == 1
                                      ? setDisableBtn(false)
                                      : setDisableBtn(true);
                                  }
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={lWRatioMeasurementsValue[0].toLocaleString(
                                      "en-US"
                                    )}
                                    onChange={(e) => {
                                      firstsetlWRatioMeasurementsValueSlideValue(
                                        e,
                                        0
                                      );
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={lWRatioMeasurementsValue[1].toLocaleString(
                                      "en-US"
                                    )}
                                    onChange={(e) => {
                                      firstsetlWRatioMeasurementsValueSlideValue(
                                        e,
                                        1
                                      );
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("LW Ratio")} </label>
                            <div className="radio__div">
                              <div className="radio__block mb-0">
                                <input
                                  type="radio"
                                  id="lwRatioAny"
                                  name="Any"
                                  value={ViewMyColorData.Radio}
                                  checked={
                                    ViewMyColorData.Radio === "Any"
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnChangeRadio}
                                />
                                <label for="any"> {traslate("Any")} </label>
                              </div>
                              <div className="radio__block mb-0">
                                <input
                                  type="radio"
                                  id="lwRatioSquare"
                                  name="Square"
                                  value={ViewMyColorData.Radio}
                                  checked={
                                    ViewMyColorData.Radio === "Square"
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnChangeRadio}
                                />
                                <label for="sqr"> {traslate("Square")} </label>
                              </div>
                              <div className="radio__block mb-0">
                                <input
                                  type="radio"
                                  id="lwRatioRec"
                                  name="Rectangle"
                                  value={ViewMyColorData.Radio}
                                  checked={
                                    ViewMyColorData.Radio === "Rectangle"
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnChangeRadio}
                                />
                                <label for="rec">
                                  {" "}
                                  {traslate("Rectangle")}{" "}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Shade")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Shade"
                              name="shade"
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "shade")
                              }
                              value={
                                ViewMyColorData.shade
                                  ? ViewMyColorData.shade
                                  : null
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0"> {traslate("Any")} </Option>
                              <Option value="1"> {traslate("None")} </Option>
                              <Option value="2">
                                {" "}
                                {traslate("No Yellow")}{" "}
                              </Option>
                              <Option value="3">
                                {" "}
                                {traslate("No Green")}{" "}
                              </Option>
                              <Option value="4"> {traslate("No Grey")} </Option>
                              <Option value="5">
                                {" "}
                                {traslate("No Black")}{" "}
                              </Option>
                              <Option value="6"> {traslate("No Pink")} </Option>
                              <Option value="7"> {traslate("No Blue")} </Option>
                              <Option value="8">
                                {" "}
                                {traslate("No Brown")}{" "}
                              </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label>
                              {" "}
                              {traslate("Central Inclusion Range")}{" "}
                            </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Central Inclusion Range"
                              name="centralInclusionRange"
                              value={
                                ViewMyColorData.centralInclusionRange
                                  ? ViewMyColorData.centralInclusionRange
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "select",
                                  "centralInclusionRange"
                                )
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0"> {traslate("Any")} </Option>
                              <Option value="1"> {traslate("None")} </Option>
                              <Option value="2"> {traslate("Light")} </Option>
                              <Option value="3"> {traslate("Medium")} </Option>
                              <Option value="4"> {traslate("Heavy")} </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Black Inclusion Range")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Black Inclusion Range"
                              name="blackInclusionRange"
                              value={
                                ViewMyColorData.blackInclusionRange
                                  ? ViewMyColorData.blackInclusionRange
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "select",
                                  "blackInclusionRange"
                                )
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0"> {traslate("Any")} </Option>
                              <Option value="1"> {traslate("None")} </Option>
                              <Option value="2"> {traslate("Light")} </Option>
                              <Option value="3"> {traslate("Medium")} </Option>
                              <Option value="4"> {traslate("Heavy")} </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Milky Inclusion Range")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Milky Inclusion Range"
                              name="milkyInclusionRange"
                              value={
                                ViewMyColorData.milkyInclusionRange
                                  ? ViewMyColorData.milkyInclusionRange
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "select",
                                  "milkyInclusionRange"
                                )
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0"> {traslate("Any")} </Option>
                              <Option value="1"> {traslate("None")} </Option>
                              <Option value="2"> {traslate("Light")} </Option>
                              <Option value="3"> {traslate("Medium")} </Option>
                              <Option value="4"> {traslate("Heavy")} </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Girdle Range")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="girdleRangeFrom"
                                  value={
                                    ViewMyColorData.girdleRangeFrom
                                      ? ViewMyColorData.girdleRangeFrom
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "girdleRangeFrom"
                                    )
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("Extremely Thin")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Very Thin")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Thin")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Slightly Thin")}{" "}
                                  </Option>
                                  <Option value="5">
                                    {" "}
                                    {traslate("Medium")}{" "}
                                  </Option>
                                  <Option value="6">
                                    {" "}
                                    {traslate("Slightly Thick")}{" "}
                                  </Option>
                                  <Option value="7">
                                    {" "}
                                    {traslate("Thick")}{" "}
                                  </Option>
                                  <Option value="8">
                                    {" "}
                                    {traslate("Very Thick")}{" "}
                                  </Option>
                                  <Option value="9">
                                    {" "}
                                    {traslate("Extremely Thick")}{" "}
                                  </Option>
                                  <Option value="10">
                                    {" "}
                                    {traslate("Extra Thin")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="girdleRangeTo"
                                  value={
                                    ViewMyColorData.girdleRangeTo
                                      ? ViewMyColorData.girdleRangeTo
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "girdleRangeTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("Extremely Thin")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Very Thin")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Thin")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Slightly Thin")}{" "}
                                  </Option>
                                  <Option value="5">
                                    {" "}
                                    {traslate("Medium")}{" "}
                                  </Option>
                                  <Option value="6">
                                    {" "}
                                    {traslate("Slightly Thick")}{" "}
                                  </Option>
                                  <Option value="7">
                                    {" "}
                                    {traslate("Thick")}{" "}
                                  </Option>
                                  <Option value="8">
                                    {" "}
                                    {traslate("Very Thick")}{" "}
                                  </Option>
                                  <Option value="9">
                                    {" "}
                                    {traslate("Extremely Thick")}{" "}
                                  </Option>
                                  <Option value="10">
                                    {" "}
                                    {traslate("Extra Thin")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Crown Angle % Range")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="0"
                                  name="crownAngleRangeFrom"
                                  value={ViewMyColorData.crownAngleRangeFrom}
                                  onChange={(e) =>
                                    handleOnChange(e, "crownAngleRangeFrom")
                                  }
                                  className={
                                    validation.crownAngleRangeFromVal &&
                                    "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.crownAngleRangeFromVal && (
                                  <span className="error-color-red">
                                    {validation.crownAngleRangeFromVal}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="100"
                                  name="crownAngleRangeTo"
                                  value={ViewMyColorData.crownAngleRangeTo}
                                  onChange={(e) =>
                                    handleOnChange(e, "crownAngleRangeTo")
                                  }
                                  className={
                                    validation.crownAngleRangeToVal &&
                                    "border__red"
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                                {validation.crownAngleRangeToVal && (
                                  <span className="error-color-red">
                                    {validation.crownAngleRangeToVal}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Key to Symbols")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Key to Symbols"
                              name="keytoSymbols"
                              value={
                                ViewMyColorData.keytoSymbols
                                  ? ViewMyColorData.keytoSymbols
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "keytoSymbols")
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0"> {traslate("Any")} </Option>
                              <Option value="1">
                                {" "}
                                {traslate("Bearding")}{" "}
                              </Option>
                              <Option value="2">
                                {" "}
                                {traslate("Brown Patch of Color")}{" "}
                              </Option>
                              <Option value="3"> {traslate("Bruise")} </Option>
                              <Option value="4"> {traslate("Cavity")} </Option>
                              <Option value="5"> {traslate("Chip")} </Option>
                              <Option value="6">
                                {" "}
                                {traslate("Cleavage")}{" "}
                              </Option>
                              <Option value="7"> {traslate("Cloud")} </Option>
                              <Option value="8"> {traslate("Crystal")} </Option>
                              <Option value="9">
                                {" "}
                                {traslate("Crystal Surface")}{" "}
                              </Option>
                              <Option value="10">
                                {" "}
                                {traslate("Etch Channel")}{" "}
                              </Option>
                              <Option value="11">
                                {" "}
                                {traslate("Extra Facet")}{" "}
                              </Option>
                              <Option value="12">
                                {" "}
                                {traslate("Feather")}{" "}
                              </Option>
                              <Option value="13">
                                {" "}
                                {traslate("Flux Remnant")}{" "}
                              </Option>
                              <Option value="14">
                                {" "}
                                {traslate("Indented Natural")}{" "}
                              </Option>
                              <Option value="15">
                                {" "}
                                {traslate("Internal Graining")}{" "}
                              </Option>
                              <Option value="16">
                                {" "}
                                {traslate("Internal Inscription")}{" "}
                              </Option>
                              <Option value="17">
                                {" "}
                                {traslate("Internal Laser Drilling")}{" "}
                              </Option>
                              <Option value="18"> {traslate("Knot")} </Option>
                              <Option value="19">
                                {" "}
                                {traslate("Laser Drill Hole")}{" "}
                              </Option>
                              <Option value="20">
                                {" "}
                                {traslate("Manufacturing Remnant")}{" "}
                              </Option>
                              <Option value="21">
                                {" "}
                                {traslate("Minor Details of Polish")}{" "}
                              </Option>
                              <Option value="22">
                                {" "}
                                {traslate("Natural")}{" "}
                              </Option>
                              <Option value="23"> {traslate("Needly")} </Option>
                              <Option value="24">
                                {" "}
                                {traslate("No Inclusion")}{" "}
                              </Option>
                              <Option value="25">
                                {" "}
                                {traslate("Pinpoint")}{" "}
                              </Option>
                              <Option value="26">
                                {" "}
                                {traslate("Reflecting Surface Graining")}{" "}
                              </Option>
                              <Option value="27">
                                {" "}
                                {traslate("Surface Graining")}{" "}
                              </Option>
                              <Option value="28">
                                {" "}
                                {traslate("Twinning Wisp")}{" "}
                              </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Culet Range")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetRangeFrom"
                                  value={
                                    ViewMyColorData.culetRangeFrom
                                      ? ViewMyColorData.culetRangeFrom
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "culetRangeFrom"
                                    )
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("None")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Small")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Very Small")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Medium")}{" "}
                                  </Option>
                                  <Option value="5">
                                    {" "}
                                    {traslate("Large")}{" "}
                                  </Option>
                                  <Option value="6">
                                    {" "}
                                    {traslate("Very Large")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetRangeTo"
                                  value={
                                    ViewMyColorData.culetRangeTo
                                      ? ViewMyColorData.culetRangeTo
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "culetRangeTo")
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("None")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Small")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Very Small")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Medium")}{" "}
                                  </Option>
                                  <Option value="5">
                                    {" "}
                                    {traslate("Large")}{" "}
                                  </Option>
                                  <Option value="6">
                                    {" "}
                                    {traslate("Very Large")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Culet Condition Range")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetConditionRangeFrom"
                                  value={
                                    ViewMyColorData.culetConditionRangeFrom
                                      ? ViewMyColorData.culetConditionRangeFrom
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "culetConditionRangeFrom"
                                    )
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("None")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Abraded")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Chipped")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Pointed")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetConditionRangeTo"
                                  value={
                                    ViewMyColorData.culetConditionRangeTo
                                      ? ViewMyColorData.culetConditionRangeTo
                                      : "0"
                                  }
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "culetConditionRangeTo"
                                    )
                                  }
                                  filterOption={filterOptionForDropdown}
                                >
                                  <Option value="0"> {traslate("Any")} </Option>
                                  <Option value="1">
                                    {" "}
                                    {traslate("None")}{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    {traslate("Abraded")}{" "}
                                  </Option>
                                  <Option value="3">
                                    {" "}
                                    {traslate("Chipped")}{" "}
                                  </Option>
                                  <Option value="4">
                                    {" "}
                                    {traslate("Pointed")}{" "}
                                  </Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Treatment")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Treatment"
                              name="treatment"
                              value={
                                ViewMyColorData.treatment
                                  ? ViewMyColorData.treatment
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "treatment")
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0">
                                {" "}
                                {traslate("Laser Drilled")}{" "}
                              </Option>
                              <Option value="1">
                                {" "}
                                {traslate("Clarity Enhanced")}{" "}
                              </Option>
                              <Option value="2">
                                {" "}
                                {traslate("Color Enhanced")}{" "}
                              </Option>
                              <Option value="3">
                                {" "}
                                {traslate("Irradiated")}{" "}
                              </Option>
                              <Option value="4"> {traslate("HPHT")} </Option>
                              <Option value="5"> {traslate("Other")} </Option>
                              <Option value="6"> {traslate("Any")} </Option>
                              <Option value="7"> {traslate("None")} </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Depth")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="0"
                                  name="depthFrom"
                                  value={ViewMyColorData.depthFrom}
                                  onChange={(e) => {
                                    // e.target.value = e.target.value.slice(0, 3);
                                    if (e.target.value <= 100) {
                                      onNumberChange(e, "depthFrom");
                                    }
                                  }}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="100"
                                  name="depthTo"
                                  value={ViewMyColorData.depthTo}
                                  onChange={(e) => {
                                    // e.target.value = e.target.value.slice(0, 3);
                                    if (e.target.value <= 100) {
                                      onNumberChange(e, "depthTo");
                                    }
                                  }}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Table")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="0"
                                  name="tableFrom"
                                  value={ViewMyColorData.tableFrom}
                                  onChange={(e) => {
                                    // e.target.value = e.target.value.slice(0, 3);
                                    if (e.target.value <= 100) {
                                      onNumberChange(e, "tableFrom");
                                    }
                                  }}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-2 text-center">
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="100"
                                  name="tableTo"
                                  value={ViewMyColorData.tableTo}
                                  onChange={(e) => {
                                    // e.target.value = e.target.value.slice(0, 3);
                                    if (e.target.value <= 100) {
                                      onNumberChange(e, "tableTo");
                                    }
                                  }}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Matched Pairs")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Search All Stones"
                              name="matchedPairs"
                              value={
                                ViewMyColorData.matchedPairs
                                  ? ViewMyColorData.matchedPairs
                                  : []
                              }
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "matchedPairs")
                              }
                              filterOption={filterOptionForDropdown}
                            >
                              <Option value="0">
                                {" "}
                                {traslate("Search All Stones")}{" "}
                              </Option>
                              <Option value="1">
                                {" "}
                                {traslate("Search Single Stone")}{" "}
                              </Option>
                              <Option value="2">
                                {" "}
                                {traslate("Search Match Pairs")}{" "}
                              </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Has Video")} </label>
                            <Switch
                              name="hasVideo"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              checked={ViewMyColorData.hasVideo}
                              // onChange={(e) => (e, "select", "visibility")}
                              onChange={(e) =>
                                handleOnChange(e, "select", "hasVideo")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 filtbtn_div">
                          <button
                            type="submit"
                            className="primary-btn mr-2"
                            onClick={resetFilters}
                          >
                            {" "}
                            {traslate("Reset Filters")}
                          </button>

                          <Button
                            className={
                              disableBtn ? "disabled-btn" : "primary-btn"
                            }
                            disabled={disableBtn}
                            onClick={applyFilters}
                          >
                            {traslate("Apply Filters")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="options__div">
                  <div className="row">
                    <div className="col-lg-6 add__div">
                      <span className="diamnd__txt">
                        {traslate("You Have")}{" "}
                        <span className="txt__no">{totalCount}</span>{" "}
                        {traslate("Diamonds")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                {/*there is a search filter in react for table*/}
                <Table
                  className="vdiamond__table"
                  columns={[
                    {
                      title: "",
                      dataIndex: "view",
                      width: 20,
                      render: (item, row) => {
                        return (
                          <React.Fragment>
                            <div
                              className="infoicon__div"
                              onClick={() => handleOpenDetailsModal(row)}
                            >
                              <i className="fa fa-info-circle"></i>
                            </div>
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      title: traslate("Stock #"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtStockNo",
                      key: "txtStockNo",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) =>
                      //   a.txtStockNo.localeCompare(b.txtStockNo),
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 60,
                    },
                    {
                      title: traslate("Shape"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtShape",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.txtShape.localeCompare(b.txtShape),
                      key: "txtShape",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 70,
                      render: (item, row) => {
                        return (
                          <div className="shape__img">
                            {" "}
                            <div className="shape__icon">
                              <img src={row.imageSmallShape} />
                            </div>{" "}
                            <span>{row.txtShape}</span>
                          </div>
                        );
                      },
                    },
                    {
                      title: traslate("Size"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "fltCarat",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) =>
                      //   a.txtStockNo.localeCompare(b.txtStockNo),
                      key: "fltCarat",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 40,
                    },
                    {
                      title: traslate("Color"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtFancyColorMainBody",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.txtColor.localeCompare(b.txtColor),
                      key: "txtFancyColorMainBody",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 40,
                    },
                    {
                      title: traslate("Clarity"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtClarity",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) =>
                      //   a.txtClarity.localeCompare(b.txtClarity),
                      key: "txtClarity",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 40,
                    },
                    {
                      title: traslate("Polish"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtPolish",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.txtPolish.localeCompare(b.txtPolish),
                      key: "txtPolish",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 40,
                    },
                    {
                      title: traslate("Price(Cost)"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "realPrice",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.realPrice.localeCompare(b.realPrice),
                      key: "realPrice",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 50,
                      ellipsis: true,
                      render: (item,row) => {
                        return(
                          <>
                            {row?.currency || " "}
                            {row.realPrice
                                    ? parseFloat(row.realPrice).toFixed(2)
                                    : ""}
                          </>
                        );
                        
                      },
                    },
                    {
                      title: traslate("Dep."),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "fltDepth",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.fltDepth.localeCompare(b.fltDepth),
                      key: "fltDepth",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 40,
                    },
                    {
                      title: traslate("Table"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "fltTable",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.fltTable.localeCompare(b.fltTable),
                      key: "fltTable",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 40,
                    },
                    {
                      title: traslate("Seller"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "dealerID",
                      key: "dealerID",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 50,
                      render: (row, item) => {
                        return (
                          <div className="infoicon__div">
                            <span onClick={() => handleOk1(item)}>
                              {" "}
                              <i
                                className="fa fa-comments"
                                aria-hidden="true"
                              ></i>{" "}
                              {item.dealerID}{" "}
                            </span>
                          </div>
                        );
                      },
                    },
                    {
                      title: traslate("Location"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "location",
                      showSorterTooltip: false,
                      sorter: (a, b) => {},
                      // sorter: (a, b) => a.location.localeCompare(b.location),
                      key: "location",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 50,
                    },
                    {
                      title: traslate("Video"),
                      width: 60,
                      render: (item, row) => {
                        return row.videoFileName ? (
                          <i
                            onClick={() => {
                              handleOpenVideoImageModal(row, true);
                            }}
                            className="fa fa-video-camera"
                            style={{ color: "#0078d7", cursor: "pointer" }}
                          ></i>
                        ) : /* row.imageFileName ? (
                          <i
                            className="fa fa-image linkText"
                            style={{ color: "#0078d7" }}
                            onClick={() => {
                              handleOpenVideoImageModal(row, false);
                            }}
                          ></i>
                        ) */ null;
                      },
                    },
                  ]}
                  // scroll={{ x: 1200, y: 800 }}
                  dataSource={fancyDiamondList}
                  loading={gridLoading}
                  pagination={{
                    total: totalCount,
                    pageNo: pageNo,
                    current: pageNo,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                  }}
                  onChange={(pagination, filters, sorters) => {
                    document
                      .getElementsByClassName("content__area")[0]
                      .scroll(0, 0);
                    handleTableASCDES(pagination, filters, sorters);
                  }}
                />
              </div>
              <div className="col-lg-12">
                <Modal
                  title={traslate("View Details")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  width={1000}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      {traslate("Contact Dealer")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-2 mt-4 text-center">
                              <div className="viewdtls__left">
                                <img
                                  src={selectedRowDetails.imageBigShape}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-lg-10 mt-4">
                              <div className="info__div">
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("GemFind")}#:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.intDiamondId}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Retail Price")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.currency} {selectedRowDetails.realPrice?parseFloat(selectedRowDetails.realPrice).toFixed(2):""}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Color")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtFancyColorMainBody}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Depth")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltDepth + "%"}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Carat Weight")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltCarat}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Symmetry")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtSymmetry}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Measurements")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtMeasurements}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Treatment")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.treatment}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Shape")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtShape}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Clarity")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtClarity}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Table")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltTable + "%"}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Polish")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtPolish}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Crown")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltCrown}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Lab")}:
                                    </span>{" "}
                                    <span
                                      className="val__txt linkText"
                                      onClick={() =>
                                        handleCertificateOpen(
                                          selectedRowDetails.certlink
                                        )
                                      }
                                    >
                                      {selectedRowDetails.txtCertificate}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Matched Pair Stock")}#:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.fltCrown}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Seller")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.dealerCompany}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Stock")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.txtStockNo}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Phone")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.dealerPhone}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Location")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.dealerLocation}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Updated")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      {selectedRowDetails.modified}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={
                    <React.Fragment>
                      <h3>
                        {"Message to " + selectedRowDetails.dealerCompany}
                      </h3>
                    </React.Fragment>
                  }
                  className="modalvdiamond__section"
                  centered
                  visible={visible1}
                  onOk={() => setVisible1(false)}
                  onCancel={() => setVisible1(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel1}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handleMsgValidation}
                      loading={msgLoading}
                    >
                      {traslate("Send")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <p>
                                {traslate("Contact the dealer regarding")}.{" "}
                                <a href="#">
                                  {selectedRowDetails.txtStockNo} .
                                </a>
                              </p>
                            </div>
                            <div className="col-lg-12">
                              <div class="input__block">
                                <textarea
                                  id=""
                                  name=""
                                  rows="6"
                                  cols="50"
                                  placeholder="Write your message here..."
                                  class=""
                                  value={msgData}
                                  onChange={(e) => {
                                    setMsgData(e.target.value);
                                    setMsgError("");
                                  }}
                                ></textarea>
                                {msgError ? (
                                  <div className="text-danger">{msgError}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={traslate("Video")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible2}
                  onOk={() => setVisible2(false)}
                  onCancel={() => setVisible2(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel2}>
                      {traslate("Close")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <iframe
                                id="VideoIframesrc"
                                src="https://certimage.s3-accelerate.amazonaws.com/V360_viewers/v4.0/Vision360.html?surl=https://certimage.s3-accelerate.amazonaws.com/V360/_v4.0/&amp;d=YG-2493&amp;sv=0,1,2,3,4,5"
                                class="VideoIframe"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={traslate("Video/Images")}
                  className="modalconsupld__section"
                  centered
                  width={600}
                  visible={videoImageModal}
                  onCancel={() => handleCloseVideoImageModal()}
                  footer={[
                    <Button onClick={() => handleCloseVideoImageModal()}>
                      {traslate("Cancel")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            {isVideo ? (
                              selectedRowDetails.videoFileName ? (
                                <iframe
                                  height={"400px"}
                                  title="diamond-video"
                                  src={selectedRowDetails.videoFileName}
                                ></iframe>
                              ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              )
                            ) : selectedRowDetails.imageFileName ? (
                              <img
                                src={selectedRowDetails.imageFileName}
                                alt="img-diamond"
                              />
                            ) : (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* Image zoom modal */}
                <Modal
                  title={isEmail ? "Email Diamond Certificate" : "Image"}
                  className="modalvdiamond__section image__zoommodal"
                  centered
                  visible={visible3}
                  onOk={() => setVisible3(false)}
                  onCancel={() => setVisible3(false)}
                  width={600}
                  footer={
                    isEmail
                      ? null
                      : [
                          <Button key="back"> {traslate("Print")} </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            onClick={handleOpenIsEmail}
                          >
                            {traslate("Email")}
                          </Button>,
                        ]
                  }
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          {isEmail ? (
                            <React.Fragment>
                              <div className="row">
                                <div className="col-lg-12 input__block">
                                  <label> {traslate("Name")}: </label>
                                  <input
                                    type={"text"}
                                    name="Name"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.Name}
                                  />
                                  {errorEmailCertificat.Name ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["Name"]}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="col-lg-12 input__block">
                                  <label> {traslate("Email")}: </label>
                                  <input
                                    type={"text"}
                                    name="Email"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.Email}
                                  />
                                  {errorEmailCertificat.Email ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["Email"]}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="col-lg-12 input__block">
                                  <label> {traslate("Friend's Name")}: </label>
                                  <input
                                    type={"text"}
                                    name="FriendsName"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.FriendsName}
                                  />
                                  {errorEmailCertificat.FriendsName ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["FriendsName"]}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="col-lg-12 input__block">
                                  <label> {traslate("Friend's Email")}: </label>
                                  <input
                                    type={"text"}
                                    name="FriendsEmail"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.FriendsEmail}
                                  />
                                  {errorEmailCertificat.FriendsEmail ? (
                                    <div className="text-danger">
                                      {errorEmailCertificat["FriendsEmail"]}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="col-lg-12 input__block">
                                  <label> {traslate("Comments")}: </label>
                                  <input
                                    type={"text"}
                                    name="Comments"
                                    onChange={(e) =>
                                      handleEmailCertificatChange(e)
                                    }
                                    value={emailCertificat.Comments}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12 ant-modal-footer ">
                                  <Button
                                    type={"primary"}
                                    loading={EmailLoading}
                                    onClick={() => handleCertificateEmailSend()}
                                  >
                                    {traslate("Submit")}
                                  </Button>
                                  <Button onClick={handleCloseIsEmail}>
                                    {traslate("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            <div className="row">
                              <div className="col-lg-12 mt-4">
                                <div className="image__zoom">
                                  <img
                                    src={selectedRowDetails.imageFileName}
                                    alt="cer-img"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ColorDiamondSearch;
