import React, { useState, useEffect } from "react";
import { Select, Table, Button, Pagination, Input } from "antd";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import traslate from "../../../i18n/translate";
import { NotificationManager } from "react-notifications";

const CampaignData = () => {
  const initialState = {
    ComposeID: null
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [campaignDropList, setcampaignDropList] = useState([]);
  const [campaignTableList, setcampaignTableList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchCampaign, setSearchCampaign] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { Option } = Select;
  const { Search } = Input;

  useEffect(() => {
    // handleGetCampaignList();
    handleGetCampaignMailingList(pageNo, pageSize, searchCampaign);
  }, []);

  function onSearch(val) {
    setPageNo(1);
    setPageSize(10);
    handleGetCampaignMailingList(1, 10, val);
    setSearchCampaign(val);
  }

  const onChangeDrop = (e, name) => {
    setcampaignTableList([]);
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState
    }));
    handleMailingListByCampaignName();
  };

  const handleGetCampaignList = () => {
    setLoading(true);

    AdminCampaignsService.GetCampaignList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var data = JSON.parse(responseData);
          setcampaignDropList(data.items);
          setcampaignTableList(data.items);
        } else {
          setcampaignDropList([]);
          setcampaignTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetCampaignMailingList = (page, size, val) => {
    let inputData = {
      pageno: page,
      pagesize: size,
      search: val
    };
    setLoading(true);
    AdminCampaignsService.GetCampaignMailingListV2(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          let list = response?.data?.responseData?.campaignMailStatuslist;
          list.forEach((item) => {
            item.key = item.campaignID;
          });
          if (list) {
            setcampaignTableList(list);
            setLoading(false);
            setTotalCount(response.data.responseData.totalrecords);
          } else {
            setLoading(false);
          }
        } else {
          NotificationManager.error(message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleMailingListByCampaignName = () => {
    setLoading(true);
    let inputData = {
      campaignID: state.ComposeID
    };
    AdminCampaignsService.GetMailingListByCampaignName(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var data = JSON.parse(responseData);
          setcampaignTableList(data.items);
        } else {
          setcampaignTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  const handleSendCampaignMails = () => {
    setLoading(true);
    let inputData = {
      campaignID: selectedRowKeys.join(",")
    };
    AdminCampaignsService.PostSendCampaignMails(inputData)
      .then((response) => {
        let message = response.data.message;
        let showMessage = response.data.responseData;
        if (message === "Success") {
          NotificationManager.success(showMessage);
          handleGetCampaignMailingList(pageNo, pageSize, searchCampaign);
          setSelectedRowKeys([]);
        } else {
          NotificationManager.error(showMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetCampaignMailingList(page, pageSize, searchCampaign);
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetCampaignMailingList(current, size, searchCampaign);
  }
  const onRowSelectChange = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  return (
    <React.Fragment>
      <div className="campaign__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Campaigns")}
              </h4>
            </div>

            <div className="col-lg-12 mt-3 mobile-m-0">
              <div className="row">
                {/* <div className="col-lg-3 col-md-6"> */}
                {/* <div className="input__block">
                    <label> {traslate("Select Campaign")} </label>
                    <Select
                      showSearch
                      className="border__grey"
                      placeholder="Select Campaign"
                      optionFilterProp="children"
                      value={state.ComposeID}
                      onChange={(e) => onChangeDrop(e, "ComposeID")}
                      onSearch={onSearch}
                      filterOption={(input, option) => {
                        if (
                          option?.children &&
                          typeof option?.children == "string" &&
                          input
                        )
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          );
                      }}
                    >
                      <Option value=""> {traslate("Select Campaign")} </Option>
                      {campaignDropList.map((item, i) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  </div> */}
                {/* </div> */}
                {/* <div className="col-lg-3"> */}
                {/* <div className="input__block"> */}
                {/* <input
                        name="campaignName"
                        value={searchCampaign}
                        onChange={(e) => setSearchCampaign(e.target.value)}
                        type="text"
                        placeholder="Search campaign"
                      /> */}
                <Search
                  placeholder="Search"
                  allowClear
                  onSearch={(e) => onSearch(e)}
                  style={{
                    width: 300
                  }}
                  className="mb-4"
                />
                {/* </div>
                  </div> */}
                <div className="col-lg-8 text-right mobile-mt-0 text-left-mobile addedit__btndiv col-lg-2">
                  <Button
                    className={
                      selectedRowKeys.length > 0
                        ? "primary-btn"
                        : "disabled-btn"
                    }
                    onClick={() => handleSendCampaignMails()}
                    disabled={selectedRowKeys.length > 0 ? false : true}
                  >
                    {traslate("Run Campaign")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="campaign_tbldiv">
                {/* <Table
                  const
                  columns={[
                    {
                      title: traslate("Campaign Name"),

                      dataIndex: "name",
                      key: "name",
                      fixed: "left",
                      width: 160,
                    },
                    {
                      title: traslate("Submitted"),
                      dataIndex: "submitted_count",
                      width: 120,
                      key: "submitted_count",
                    },
                    {
                      title: traslate("Delivered"),
                      width: 120,
                      dataIndex: "delivered_count",
                      key: "delivered_count",
                    },
                    {
                      title: traslate("Total Opens"),
                      width: 120,
                      dataIndex: "opened_count",
                      key: "opened_count",
                    },
                    {
                      title: traslate("Total Clicks"),
                      width: 120,
                      dataIndex: "clicked_count",
                      key: "clicked_count",
                    },
                    {
                      title: traslate("Created"),
                      width: 300,
                      dataIndex: "created_at",
                      key: "created_at",
                    },
                  ]}
                  loading={loading}
                  dataSource={campaignTableList}
                  scroll={{ x: 600, y: 800 }}
                /> */}
                <Table
                  const
                  columns={[
                    {
                      title: traslate("Campaign Name"),
                      dataIndex: "campaignName",
                      key: "campaignName"
                    },
                    {
                      title: traslate("Group Name"),
                      dataIndex: "dealerGroupName",
                      key: "dealerGroupName"
                    },
                    {
                      title: traslate("Template Name"),
                      dataIndex: "templateName",
                      key: "templateName"
                    },
                    {
                      title: traslate("Email Status"),
                      dataIndex: "emailStatus",
                      key: "emailStatus"
                    }
                  ]}
                  loading={loading}
                  dataSource={campaignTableList}
                  pagination={false}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: onRowSelectChange,
                    hideDefaultSelections: true,
                    getCheckboxProps: (record) => ({
                      disabled: record.emailStatus === "Completed",
                      className:
                        record.emailStatus === "Completed"
                          ? "hide-checkbox"
                          : ""
                    })
                  }}
                />
                <div className="col-md-12 mt-3" style={{ textAlign: "end" }}>
                  <Pagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={totalCount}
                    onChange={handlePageNoChange}
                    onShowSizeChange={handlePageSizeChange}
                    showSizeChanger="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignData;
