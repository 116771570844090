import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Select, Switch, Tooltip, Spin } from "antd";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";
import { NotificationManager } from "react-notifications";
import MasterLinkService from "../../../services/apps-masterLink.service";
const { Option } = Select;

export const MasterLinkOptions = (props) => {
  /*React Binding Starts*/
  const [ViewOptionData, setViewOptionData] = useState({
    InternalUseLink: true,
    StickyHeaderHeight: "",
    platinumEducation: true,
    RingSizeGuideURL: "",
    ShowBreadcrumbs: true,
    CreateProfileText: "",
    SortProductsByPrice: "1",
    CollectionFiltering: true,
    ScheduleViewing: true,
    DesignerTileLayout: true,
    EnabledBackToTop: true,
    ShowAddress: true,
    CallForPriceText: "",
    StartingAtPriceText: "",
    ShowCallForPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    FacebookAppID: "",
    FacebookLikePageURL: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    Instagramshare: true,
    MasterlinkDefaultTitle: "",
    MasterlinkMetaKeywords: "",
    MasterlinkMetaDescription: "",
    ShoppingCartURL: "",
    ShowViewCart: true,
    isShowEcommerce: false,
  });

  const handleOnChange = (e, isSelect, name) => {
    if (name == "GSTTaxValue") {
      const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
      if (!rx_live.test(e.target.value)) {
        return false;
      }
    }
    if (isSelect === "select") {
      setDisableBtn(false);
      setViewOptionData({
        ...ViewOptionData,
        [name]: e,
      });
    } else {
      setDisableBtn(false);
      setViewOptionData({
        ...ViewOptionData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onNumberKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  /*API Binding Starts*/
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerId, setDealerId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetMasterLinkOptionPreferences(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);
  const handleGetMasterLinkOptionPreferences = (id) => {
    let inputData = {
      dealerID: id,
      type: "MasterLink",
    };
    setLoading(true);
    try {
      MasterLinkService.GetMasterLinkOptionPreferences(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              if (responseData.objData1.length > 0) {
                setViewOptionData((prevState) => ({
                  ...prevState,
                  MasterlinkDefaultTitle: responseData.objData1[0].defaultTitle,
                  MasterlinkMetaKeywords: responseData.objData1[0].metaKeywords,
                  MasterlinkMetaDescription:
                    responseData.objData1[0].metaDescription,
                  isShowEcommerce: responseData.objData1[0].isShowEcommerce,
                }));
              }
              if (responseData.objData3.length > 0) {
                setViewOptionData((prevState) => ({
                  ...prevState,
                  PinterestShare: responseData.objData3[0].showPinterestShare,
                  TwitterShare: responseData.objData3[0].showTwitterShare,
                  FacebookShare: responseData.objData3[0].showFacebookShare,
                  FacebookLike: responseData.objData3[0].showFacebookLike,
                  Instagramshare: responseData.objData3[0].showInstagramShare,
                }));
              }

              if (responseData.objData2.length > 0) {
                setViewOptionData((prevState) => ({
                  ...prevState,
                  InternalUseLink: responseData.objData2[0].internalLink,
                  StickyHeaderHeight:
                    responseData.objData2[0].stickyHeaderHeight,
                  platinumEducation:
                    responseData.objData2[0].isPlatinumEducation,
                  RingSizeGuideURL: responseData.objData2[0].ringSizeGuideURL,
                  ShowBreadcrumbs:
                    responseData.objData2[0].showVerticalBreadCrum,
                  CreateProfileText: responseData.objData2[0].profileText,
                  SortProductsByPrice:
                    responseData.objData2[0].productSortBy.toString(),
                  CollectionFiltering:
                    responseData.objData2[0].collectionFiltering,
                  ScheduleViewing: responseData.objData2[0].isScheduleViewing,
                  DesignerTileLayout:
                    responseData.objData2[0].designerThemeOption,
                  EnabledBackToTop: responseData.objData2[0].enabledBackToTop,
                  ShowAddress: responseData.objData2[0].showAddresses,
                  CallForPriceText: responseData.objData2[0].callForPriceText,
                  StartingAtPriceText:
                    responseData.objData2[0].startingAtPriceText,
                  ShowCallForPrice: responseData.objData2[0].showCallforPrice,
                  GSTTax: responseData.objData2[0].showGstPrice,
                  GSTTaxValue: responseData.objData2[0].taxRate,
                  FacebookAppID: responseData.objData2[0].facebookAppID,
                  FacebookLikePageURL: responseData.objData2[0].faceBookURL,
                  ShoppingCartURL: responseData.objData2[0].shoppingCartURL,
                  ShowViewCart: responseData.objData2[0].showshoppingcartlink,
                }));
              }
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId.toString(),
        shoppingCartURL: ViewOptionData.ShoppingCartURL.toString(),
        facebookAppID: ViewOptionData.FacebookAppID,
        internalLink: ViewOptionData.InternalUseLink,
        showCallforPrice: ViewOptionData.ShowCallForPrice,
        isPlatinumEducation: ViewOptionData.platinumEducation,
        showAdvanceDiamondSearch: true, // extra field
        showAdvanceRingBuilder: true, // extra field
        showVerticalBreadCrum: ViewOptionData.ShowBreadcrumbs,
        profileText: ViewOptionData.CreateProfileText,
        taxRate: ViewOptionData.GSTTaxValue.toString(),
        showGstPrice: ViewOptionData.GSTTax,
        showShoppingcartlink: ViewOptionData.ShowViewCart,
        collectionFiltering: ViewOptionData.CollectionFiltering,
        isScheduleViewing: ViewOptionData.ScheduleViewing,
        showAddresses: ViewOptionData.ShowAddress,
        designerThemeOption: ViewOptionData.DesignerTileLayout,
        enabledBackToTop: ViewOptionData.EnabledBackToTop,
        productSortBy: ViewOptionData.SortProductsByPrice.toString(),
        newCategoryStructure: true, // extra field
        faceBookURL: ViewOptionData.FacebookLikePageURL,
        callForPriceText: ViewOptionData.CallForPriceText,
        startingAtPriceText: ViewOptionData.StartingAtPriceText,
        ringSizeGuideURL: ViewOptionData.RingSizeGuideURL,
        defaultTitle: ViewOptionData.MasterlinkDefaultTitle,
        metaKeywords: ViewOptionData.MasterlinkMetaKeywords,
        metaDescription: ViewOptionData.MasterlinkMetaDescription,
        stickyHeaderHeight: ViewOptionData.StickyHeaderHeight.toString(),
        type: "MasterLink",
        showPinterestShare: ViewOptionData.PinterestShare,
        showTwitterShare: ViewOptionData.TwitterShare,
        showFacebookShare: ViewOptionData.FacebookShare,
        showFacebookLike: ViewOptionData.FacebookLike,
        showGooglePlus: true, // extra field
        showInstagramShare: ViewOptionData.Instagramshare,
      };
      setLoading(true);
      MasterLinkService.SaveMasterLinkData(inputData)
        .then((response) => {
          let message = response.data.message;
          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success("Updated Successfully.");
            handleGetMasterLinkOptionPreferences(dealerId);
          } else {
            NotificationManager.error("Data not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setDisableBtn(true);
  };

  /*API Binding Ends*/
  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-3">
              {" "}
              {traslate("Options")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="row ">
          <div className="col-md-12">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Setup")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Internal Use Link?")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              `Allows you to look up vendor/product details directly from your website , by using a 'For Internal Use Only' link.`
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="InternalUseLink"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.InternalUseLink}
                          onChange={(e) =>
                            handleOnChange(e, "select", "InternalUseLink")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Sticky Header Height")}
                          <Tooltip
                            placement="right"
                            title={traslate("Enter a Sticky Header Height.")}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          onKeyPress={onNumberKeyPressEvent}
                          name="StickyHeaderHeight"
                          className="mr-2"
                          value={ViewOptionData.StickyHeaderHeight}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <label htmlFor="h1"> {traslate("Display")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Platinum Education")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Provides a link to copy for platinum education in Ring Builder."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="platinumEducation"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.platinumEducation}
                          onChange={(e) =>
                            handleOnChange(e, "select", "platinumEducation")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Ring Size Guide URL")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Provides a link to copy for ring sizing guides provided by GemFind."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="RingSizeGuideURL"
                          className="mr-2"
                          value={ViewOptionData.RingSizeGuideURL}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Breadcrumbs")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Shows navigation path a customer took to get to page they're on."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowBreadcrumbs"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowBreadcrumbs}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowBreadcrumbs")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          "{traslate("Create Profile")}" {traslate("text")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Provide alternate text for user registration Button."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="CreateProfileText"
                          className="mr-2"
                          value={ViewOptionData.CreateProfileText}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Sort Products by Price Initially By")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Allow Customers to Sort Product By Price."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          className="border__grey"
                          placeholder="Price: Low - High"
                          showSearch
                          name="SortProductsByPrice"
                          value={ViewOptionData.SortProductsByPrice}
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "SortProductsByPrice")
                          }
                        >
                           <Option value="0">
                            {" "}
                            {traslate("Please Select")}{" "}
                          </Option>
                          <Option value="1">
                            {" "}
                            {traslate("Price: Low - High")}{" "}
                          </Option>
                          <Option value="2">
                            {" "}
                            {traslate("Price: High - Low")}{" "}
                          </Option>
                        </Select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Collection Filtering")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "When navigating Designer Landing pages - show collections instead of categories."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="CollectionFiltering"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.CollectionFiltering}
                          onChange={(e) =>
                            handleOnChange(e, "select", "CollectionFiltering")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Schedule Viewing")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Allow Customers to schedule a meeting with you."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ScheduleViewing"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ScheduleViewing}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ScheduleViewing")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Designer Tile Layout")}
                          <Tooltip
                            placement="right"
                            title={traslate("Show Back to Top Button.")}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="DesignerTileLayout"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.DesignerTileLayout}
                          onChange={(e) =>
                            handleOnChange(e, "select", "DesignerTileLayout")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Enabled Back To Top")}
                          <Tooltip placement="right">
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="EnabledBackToTop"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.EnabledBackToTop}
                          onChange={(e) =>
                            handleOnChange(e, "select", "EnabledBackToTop")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Addresses")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Allow address to show on detailpage"
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowAddress"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowAddress}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowAddress")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Price")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          "{traslate("Call For Price")}" {traslate("Text")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="CallForPriceText"
                          className="mr-2"
                          value={ViewOptionData.CallForPriceText}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          "{traslate("Starting At Price")}" {traslate("Text")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="StartingAtPriceText"
                          className="mr-2"
                          value={ViewOptionData.StartingAtPriceText}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show")} "{traslate("Call For Price")}"{" "}
                          {traslate("instead of the Price")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Hides the retail price for products using Price Type 1,2,or 3 ."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="ShowCallForPrice"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.ShowCallForPrice}
                          onChange={(e) =>
                            handleOnChange(e, "select", "ShowCallForPrice")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Apply GST Tax")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Toggle the ability to apply international tax."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="GSTTax"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.GSTTax}
                          onChange={(e) =>
                            handleOnChange(e, "select", "GSTTax")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("GST Tax Value")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Set the value when applying the GST Tax option."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          maxLength="2"
                          name="GSTTaxValue"
                          className="mr-2"
                          value={
                            ViewOptionData.GSTTaxValue
                              ? ViewOptionData.GSTTaxValue
                              : ""
                          }
                          onChange={(e) => handleOnChange(e, "", "GSTTaxValue")}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div>
                    <label> {traslate("Social")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Facebook App ID")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Connect MasterLink to your Facebook page, using your Facebook App ID."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="FacebookAppID"
                          className="mr-2"
                          value={ViewOptionData.FacebookAppID}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Facebook Like Page URL")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Allow Like access to your facebook account by providing the URL."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="FacebookLikePageURL"
                          className="mr-2"
                          value={ViewOptionData.FacebookLikePageURL}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Pinterest Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Pinterest “Pin it” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="PinterestShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.PinterestShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "PinterestShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Twitter Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Twitter “Tweet” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="TwitterShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.TwitterShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "TwitterShare")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Share” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="FacebookShare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.FacebookShare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "FacebookShare")
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Facebook Like")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Facebook “Like” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="FacebookLike"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.FacebookLike}
                          onChange={(e) =>
                            handleOnChange(e, "select", "FacebookLike")
                          }
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Show Instagram Share")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Choose whether to display the Instagram “Share” button or not."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Switch
                          name="Instagramshare"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={ViewOptionData.Instagramshare}
                          onChange={(e) =>
                            handleOnChange(e, "select", "Instagramshare")
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div>
                    <label> {traslate("SEO")} </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Masterlink Default Title")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter a title for search engine optimization."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="MasterlinkDefaultTitle"
                          className="mr-2"
                          value={ViewOptionData.MasterlinkDefaultTitle}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Masterlink Meta Keywords")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter a Keywords for search engine optimization."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="MasterlinkMetaKeywords"
                          className="mr-2"
                          value={ViewOptionData.MasterlinkMetaKeywords}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="input__block">
                        <label>
                          {traslate("Masterlink Meta Description")}
                          <Tooltip
                            placement="right"
                            title={traslate(
                              "Enter a description for search engine optimization."
                            )}
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="MasterlinkMetaDescription"
                          className="mr-2"
                          value={ViewOptionData.MasterlinkMetaDescription}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {ViewOptionData?.isShowEcommerce ? (
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <div>
                      <label> {traslate("E-Commerce")} </label>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Alternate Shopping Cart")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            name="ShoppingCartURL"
                            className="mr-2"
                            value={ViewOptionData.ShoppingCartURL}
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input__block">
                          <label>
                            {traslate("Show")} "{traslate("View Cart")}"{" "}
                            {traslate("Button")}
                            <Tooltip
                              placement="right"
                              title={traslate(
                                "Enable the View Cart button for customers to review their products selections."
                              )}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Switch
                            name="ShowViewCart"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={ViewOptionData.ShowViewCart}
                            onChange={(e) =>
                              handleOnChange(e, "select", "ShowViewCart")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
            </Accordion>
            <div className="col-lg-12 mt-4">
              <button
                className={disableBtn ? "disabled-btn" : "primary-btn"}
                disabled={disableBtn}
                loading={submitLoading}
                onClick={handleSubmit}
              >
                {" "}
                {traslate("Update Settings")}{" "}
              </button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MasterLinkOptions);
