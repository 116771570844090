import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Input,
  Checkbox,
  Button,
  Table,
  Modal,
  Switch,
  Space,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import traslate from "../../../i18n/translate";
import StarRatings from "react-star-ratings";
import communityVendorService from "../../../services/community-vendor.service";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NotificationManager } from "react-notifications";
import PendantBuilderService from "../../../services/apps-pendantBuilder.service";
import { setBindActivePageName } from "../../../actions/Community/ManageInventory";

export const MyNetworkPendantBuilder = (props) => {
  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [commVendorData, setCommVendorData] = useState([]);
  const [dealerId, setDealerId] = useState("");
  const [state, setState] = useState({
    PageNumber: 1,
    NoOfRow: 10,
  });
  const [dealerCompany, setDealerCompany] = useState("");
  const [row, setRow] = useState([]);
  const [page, setPage] = useState("RetailerAffirmation");
  const [ConnectionStatus, setConnectionStatus] = useState(true);
  const [emailLoading, setEmailLoading] = useState(false);
  const [content, setContent] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [checkbox, setCheckBox] = useState("");
  const [sortColumnName, setSortColumnName] = useState("dealerCompany");
  const [sortType, setSortType] = useState("asc");
  const filterValuesRef = useRef({ search: [] });
  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: `I am interested in opening a new account with ${row.dealerCompany} and becoming an authorized reseller.By checking this box and clicking the submit button below, your name and store information will be sent to ${row.dealerCompany}.`,
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",
      note: `By checking this box and clicking the submit button below Ihere by affirm that ${
        dealerCompany ? dealerCompany : null
      } has an open and active account with ${
        row.dealerCompany
      },that I am an authorized reseller of ${
        row.dealerCompany
      },and that I am permitted to have the ir virtual inventory on my website.`,
    },
  ];
  const [disableBtn, setDisableBtn] = useState(true);

  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleBindDataCommVendor(
            loginDetials.loginDetials.responseData.dealerId,
            filterValuesRef?.current?.search
          );
        }
      }
    }
  }, []);

  const handleOnChange = (e) => {
    setConnectionStatus(!ConnectionStatus);
    handleUpdateInventoryByDesignerID();
  };

  const handleUpdateInventoryByDesignerID = () => {
    try {
      let inputData = {
        designerID: selectedColumnId,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .UpdateInventoryByDesignerID(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            let tempRowDetails = rowDetails;
            tempRowDetails.isEnable = "False";
            setRowDetails(tempRowDetails);
            NotificationManager.success(
              "Connection Status Changed Successfully."
            );
            handleBindDataCommVendor(
              dealerId,
              filterValuesRef?.current?.search
            );

            setLoading(false);
          } else {
            NotificationManager.error("Connection Status not changed.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  const columns = [
    {
      title: traslate("Vendor"),
      dataIndex: "dealerCompany",
      ...getColumnSearchProps("dealerCompany"),
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },

    {
      title: traslate("Status"),
      dataIndex: "vendorStatus",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: traslate("Data Rating"),
      dataIndex: "ratingCount",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 140,
      render: (index, row) => (
        <StarRatings
          rating={Number(row.ratingCount)}
          starRatedColor="#1883b8"
          starDimension="18px"
          starSpacing="0px"
          numberOfStars={5}
        />
      ),
    },
    {
      title: traslate("Items"),
      dataIndex: "totalNumber",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: traslate("Updated"),
      dataIndex: "modified",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 120,
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      width: 150,
      render: (index, row) => {
        return (
          <Fragment>
            {row.action == "Send Invitation" ? (
              <a
                className="link__txt"
                onClick={() => {
                  showSendInvitationModal(row);
                }}
                alt=""
              >
                {" "}
                {traslate("Send Invitation")}{" "}
              </a>
            ) : row.action == "Connected" ? (
              <span> {traslate("Connected")} </span>
            ) : row.action == "Request Access" ? (
              <a
                className="link__txt"
                onClick={() => {
                  showSendInvitationModal(row);
                }}
                alt=""
              >
                {" "}
                {traslate("Request Access")}{" "}
              </a>
            ) : row.action == "Re-Activate" ? (
              <span onClick={(e) => handleReActiveCollection(e, row)} alt="">
                {" "}
                {traslate("Re-Activate")}{" "}
              </span>
            ) : row.action == "Re-ActivateReEnableVenor," ? (
              <span onClick={(e) => handleReActiveCollection(e, row)} alt="">
                {" "}
                {traslate("Re-Activate")}{" "}
              </span>
            ) : row.action == "ResubmitCancel" ? (
              <div className="action__btns">
                <div className="mx-2">
                  <span
                    onClick={() => {
                      showSendInvitationModal(row);
                    }}
                    alt=""
                  >
                    {" "}
                    {traslate("Resubmit")}
                  </span>
                </div>
                <div className="mx-2">
                  <span
                    onClick={() => {
                      handleCancelRetailerRequest(row);
                    }}
                    alt=""
                  >
                    {traslate("Cancel")}
                  </span>
                </div>
              </div>
            ) : row.action == "SendRequest" ? (
              <a
                className="link__txt"
                onClick={() => {
                  showSendInvitationModal(row);
                }}
                alt=""
              >
                {" "}
                {traslate("Request Access")}{" "}
              </a>
            ) : null}
          </Fragment>
        );
      },
    },
  ];

  const showSendInvitationModal = (row) => {
    setRow(row);
    setIsModalSendInvitationVisible(true);
  };

  const handleSendInvitationModalCancel = () => {
    setDisableBtn(true);
    setCheckBox("");

    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setContent("");
  };

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId,
        type: "PendantBuilder",
      };
      setLoading(true);
      communityVendorService
        .SendEmailToVendor(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;

          if (message === "Success") {
            setContent(responseData);
            handleBindDataCommVendor(
              dealerId,
              filterValuesRef?.current?.search
            );
            setDisableBtn(true);
            NotificationManager.success("Email has been Sent Successfully.");
          } else {
            setContent([]);
            setTotalCount(0);
            setDisableBtn(true);
            NotificationManager.error("Email has not been Sent Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setDisableBtn(true);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDisableBtn(true);
    }
    handleSendInvitationModalCancel();
  };

  const handleNewAccountRequest = () => {
    try {
      let inputData = {
        email_Type: row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
            handleBindDataCommVendor(
              loginDetials.loginDetials.responseData.dealerId,
              filterValuesRef?.current?.search
            );
            NotificationManager.success("Email has been Sent Successfully.");
          } else {
            setContent([]);
            NotificationManager.error("Email has not been Sent.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleGetEmailData = () => {
    try {
      let inputData = {
        vendorID: row.dealerID,
        dealerID: dealerId,
        emailType: row.emailType,
      };
      setEmailLoading(true);
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  const handleCancelRetailerRequest = (row) => {
    try {
      let inputData = {
        vendorID: row.dealerID,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .CancelRetailerRequest(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Request Cancelled Sucessfully");
            handleBindDataCommVendor(
              dealerId,
              filterValuesRef?.current?.search
            );
          } else {
            NotificationManager.success("Request Cancelled Failed");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleReActiveCollection = (e, row) => {
    try {
      let inputData = {
        vendorID: Number(row.dealerID),
        dealerID: Number(dealerId),
        flg: 0,
      };
      setLoading(true);
      communityVendorService
        .ReActiveCollection(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Collection Re-actived Sucessfully");
            handleBindDataCommVendor(
              dealerId,
              filterValuesRef?.current?.search
            );
            setSelectedColumnId(0);
          } else {
            NotificationManager.success("Collection Not Re-actived");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleBindDataCommVendor = (id, search = [], column, type) => {
    let inputData = {
      dealerID: id,
      filerType: props.type == "Archived Vendors" ? "5" : "00",
      currentPage: state.PageNumber,
      recordOnPage: state.NoOfRow,
      searchKeyChar: "",
      searchType: "",
      lstColumnFilter: search,
      sortColumnName: column ? column : sortColumnName || "dealerCompany",
      sortOrder: type ? type : sortType || "ASC",
    };
    setLoading(true);
    PendantBuilderService.PendantBuilder_BindDataCommVendor(inputData)
      .then((response) => {
        setLoading(false);
        let message = response.data.message;
        if (message === "Success") {
          if (response.data.responseData) {
            var responseData =
              response.data.responseData.vendor_NetworkResponse;
            var TotalCount = response.data.responseData.totalRecords;
            setCommVendorData(responseData);
            setTotalCount(TotalCount);
          } else {
            setCommVendorData([]);
            setTotalCount(0);
            selectedColumnId(0);
            setRowDetails({});
          }
        } else {
          setCommVendorData([]);
          setTotalCount(0);
          setTotalCount(0);
          selectedColumnId(0);
          setRowDetails({});
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  function handleCheckBoxChange(checkedValues) {
    setDisableBtn(false);
    setCheckBox(checkedValues.target.value);
  }
  const dispatch = useDispatch();
  const handleShowDataToRightNew = (record) => {
    try {
      let inputData = {
        vendorID: record.dealerID.toString(),
        isEnable: record.isEnabled.toString(),
        f_Type: record.filerType,
        dealerID: dealerId.toString(),
      };
      setSelectedColumnId(record.dealerID);
      setLoadingProfileData(true);
      dispatch(setBindActivePageName("Network"));
      communityVendorService
        .ShowDataToRightNew(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setRowDetails({ ...responseData });
              setLoadingProfileData(false);
            } else {
              setRowDetails({});
              setLoadingProfileData(false);
            }
          } else {
            setRowDetails({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  // API Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    state.PageNumber = pagination?.current;
    state.NoOfRow = pagination?.pageSize;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    var type = "";
    var column = "";
    var filter = [];
    var obj = {};

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    if (filters.dealerCompany) {
      obj = {
        columnName: "dealerCompany",
        columnValue: filters.dealerCompany[0],
        columnType: "Input",
      };
      filter.push(obj);
    }
    filterValuesRef.current = { search: filter, column, type };
    handleBindDataCommVendor(dealerId, filter, column, type);
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  const openInNewTab = () => {
    let url = rowDetails.website;
    if (rowDetails.website.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.website.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping permission__section">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-0">
              {" "}
              {props.type
                ? traslate(props.type)
                : traslate("Network Vendors")}{" "}
              {"(" + totalCount + ")"}
            </h4>
          </div>
        </div>

        <div
          className={
            selectedColumnId !== 0
              ? "col-lg-9 divForm vendors-section"
              : "col-lg-12"
          }
        >
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={commVendorData}
              onChange={handleTableASCDES}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: state.PageNumber,
                pageSize: state.NoOfRow,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  handleShowDataToRightNew(record);
                },
              })}
              rowClassName={(record) =>
                record.dealerID == selectedColumnId ? "row-active" : ""
              }
            />
          </Spin>
        </div>
        {selectedColumnId !== 0 ? (
          <div className="col-lg-3 col-md-3">
            <Spin spinning={loadingprofileData}>
              <div className="right__section">
                <div className="profile__block">
                  <Link
                    to={"/vendorProfile"}
                    state={{
                      key: "Profile",
                      id: selectedColumnId,
                    }}
                  >
                    <span className="linkText">
                      {rowDetails.designerName ? rowDetails.designerName : null}
                    </span>
                  </Link>
                  <div className="logoImg__block p-0">
                    <img src={rowDetails.src ? rowDetails.src : null} />
                  </div>
                  <p className="activeFrom">
                    {" "}
                    {rowDetails.vendorStatus
                      ? rowDetails.vendorStatus
                      : null} |{" "}
                    {rowDetails.activity ? rowDetails.activity : null}{" "}
                  </p>

                  <div className="star-ratings">
                    <p className="mb-0"> {traslate("Data Ratings")} </p>
                    <StarRatings
                      rating={rowDetails.ratingCount}
                      starRatedColor="#1883b8"
                      starDimension="18px"
                      starSpacing="0px"
                      numberOfStars={5}
                    />
                  </div>
                </div>
                <div className="links__block">
                  <ul>
                    <li>
                      <Link
                        to={"/vendorProfile"}
                        state={{
                          key: "Profile",
                          id: selectedColumnId,
                        }}
                      >
                        <i
                          className="fa fa-address-book-o"
                          aria-hidden="true"
                        ></i>{" "}
                        {traslate("View Profile")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/vendorProfile"}
                        state={{
                          key: "Conversation",
                          id: selectedColumnId,
                        }}
                      >
                        <i className="fa fa-comments-o" aria-hidden="true"></i>{" "}
                        {traslate("Conversation")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/vendorProfile"}
                        state={{
                          key: "Inventory",
                          id: selectedColumnId,
                        }}
                      >
                        <i className="fa fa-diamond" aria-hidden="true"></i>{" "}
                        {traslate("View Inventory")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/vendorProfile"}
                        state={{
                          key: "Location",
                          id: selectedColumnId,
                        }}
                      >
                        <i className="fa fa-globe" aria-hidden="true"></i>{" "}
                        {traslate("Manage Location")}
                      </Link>
                      <a href="#"></a>
                    </li>
                  </ul>
                  <hr />
                </div>
                <div className="retailer__dtls mb-4">
                  <p>
                    {" "}
                    {rowDetails.dealerName ? rowDetails.dealerName : null}{" "}
                  </p>
                  <a href={`mailto:${rowDetails.dealerEmail}`}>
                    {" "}
                    {rowDetails.dealerEmail
                      ? rowDetails.dealerEmail
                      : null}{" "}
                  </a>
                  <p>
                    {" "}
                    {rowDetails.dealerPhone
                      ? rowDetails.dealerPhone
                      : null}{" "}
                  </p>
                  <span className="linkText" onClick={() => openInNewTab()}>
                    {rowDetails.website ? rowDetails.website : null}
                  </span>
                  <hr />
                </div>
                {rowDetails.isEnable == "true" ? (
                  <div className="input__block">
                    <label> {traslate("Connection Status")} </label>
                    <Switch
                      name="ConnectionStatus"
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      checked={true}
                      // onChange={handleSwitch}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                ) : null}
              </div>
            </Spin>
          </div>
        ) : null}

        <Modal
          title={
            page == "RetailerAffirmation"
              ? traslate("Retailer Affirmation")
              : traslate("Send Email")
          }
          visible={isModalSendInvitationVisible}
          className="forgot__password"
          onCancel={handleSendInvitationModalCancel}
          maskClosable={false}
          footer={[
            //
            <div
              className={page == "RetailerAffirmation" ? "d-block" : "d-none"}
            >
              <Button
                key="submit"
                className={
                  disableBtn == true
                    ? "primary-btn disabled-btn"
                    : "primary-btn"
                }
                disabled={disableBtn == true ? true : false}
                onClick={
                  checkbox == "CurrentAuthorizedReseller"
                    ? handleShowEmailWindow
                    : handleNewAccountRequest
                }
              >
                {traslate("Submit")}
              </Button>
            </div>,
            <div className={page == "SendEmail" ? "d-block" : "d-none"}>
              <Button
                className="primary-btn"
                key="submit"
                onClick={handleSendEmail}
              >
                {traslate("Send")}
              </Button>
            </div>,
          ]}
        >
          <div className="form__fields p-0 border-0 mh-auto">
            {page == "RetailerAffirmation" ? (
              <div>
                {category.map((item) => {
                  return (
                    <Fragment key={item.value}>
                      <div className="radio__block">
                        <Checkbox
                          key={item.value}
                          onChange={handleCheckBoxChange}
                          checked={item.value == checkbox}
                          value={item.value}
                        >
                          <label> {traslate(item.label)} </label>
                        </Checkbox>
                      </div>
                      <div>
                        <p> {item.note} </p>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            ) : (
              <div className="editor__section">
                <Spin spinning={emailLoading}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </Spin>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default MyNetworkPendantBuilder;
