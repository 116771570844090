import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Select, Button, Table, Modal, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const PendingSync = (props) => {
  const [data, setData] = useState({
    dealerID: "",
  });
  const [dealerList, setDealerList] = useState([]);
  const [pendingJewelrySyncList, setPendingJewelrySyncList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discardData, setDiscardData] = useState({
    id: 0,
    dealerId: 0,
  });
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);
  const [isDiscardAllModalVisible, setIsDiscardAllModalVisible] =
    useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleFillDealerDropDown(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (data.dealerID) {
      handleGetPendingJewelrySyncList(data.dealerID, pageNo, pageSize);
    }
  }, [data.dealerID]);

  const handleGetPendingJewelrySyncList = (id, pageNo, pageSize) => {
    let inputData = {
      pageNumber: pageNo,
      pageSize: pageSize,
      dealerMember_Request: {
        dealerID: id,
      },
    };

    setLoading(true);
    AdminToolsDataService.GetPendingJewelrySyncList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message === "Success") {
          setPendingJewelrySyncList(
            responseData[0].dealer_PendingJewelryListResponce
          );
          setTotalCount(responseData.totalRecord);
        } else {
          setPendingJewelrySyncList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFillDealerDropDown = () => {
    AdminToolsDataService.FillDealerDropDown()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setDealerList(responseData);
        } else {
          setDealerList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setData({
        ...data,
        [name]: e,
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleRunSyncForce = () => {
    let inputData = {
      dealerID: data.dealerID,
    };
    setLoading(true);
    AdminToolsDataService.RunSyncForce(inputData)
      .then((response) => {
        var message = response.data.message;
        setLoading(false);
        if (message === "Success") {
          NotificationManager.success("RunSyncForce Carried Successfully");
        } else {
          NotificationManager.error("RunSyncForce Carried Unsuccessfull");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscardItem = () => {
    const id = discardData.id;
    const dealerID = discardData.dealerId;
    const all = 0;
    handleDiscardItemApi(id, dealerID, all);
  };

  const handleDiscardAllItem = () => {
    const id = 0;
    const dealerID = data.dealerID;
    const all = 1;
    handleDiscardItemApi(id, dealerID, all);
  };

  const handleDiscardItemApi = (id, dealerID, all) => {
    let inputData = {
      id: id,
      dealerId: dealerID,
      all: all,
    };
    setLoading(true);
    AdminToolsDataService.UpdateDiscardStatus(inputData)
      .then((response) => {
        var message = response.data.message;
        setLoading(false);
        if (message === "Success") {
          NotificationManager.success("Discard Carried Successfully");
          handleGetPendingJewelrySyncList(dealerID, pageNo, pageSize);
          handleDiscardAllCancel();
          handleDiscardCancel();
        } else {
          NotificationManager.error("Discard Carried Unsuccessfull");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDiscardModal = (row) => {
    setIsDiscardModalVisible(true);
    setDiscardData({
      id: row.jewelryCSVHistoryID,
      dealerId: row.dealerId,
    });
  };
  const handleDiscardCancel = () => {
    setIsDiscardModalVisible(false);
    setDiscardData({});
  };

  const showDiscardAllModal = () => {
    setIsDiscardAllModalVisible(true);
  };
  const handleDiscardAllCancel = () => {
    setIsDiscardAllModalVisible(false);
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetPendingJewelrySyncList(page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetPendingJewelrySyncList(current, size);
  }

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 class="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("Pending Syncs")}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input__block mobile__bottomspace">
              <label> {traslate("Select Dealer")} </label>
              <Select
                showSearch
                className="border__grey"
                optionFilterProp="children"
                placeholder="Select Dealer"
                name="dealerID"
                value={data.dealerID ? data.dealerID : null}
                onChange={(e) => handleOnChange(e, "select", "dealerID")}
              >
                {dealerList.map((item) => {
                  return (
                    <Option value={item.dealerID}>
                      {item.dealerCompany + "(" + item.dealerID + ")"}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="col-lg-8 text-right mt-4 mobile-mt-0 text-left-mobile">
            {!pendingJewelrySyncList?.length == 0 ? (
              <button
                className="primary-btn mr-2"
                onClick={showDiscardAllModal}
              >
                {traslate("Discard All")}
              </button>
            ) : null}
            <button className="primary-btn" onClick={handleRunSyncForce}>
              {traslate("Run Valid Image Application")}
            </button>
          </div>
        </div>
        {data.dealerID ? (
          <div className="col-md-12 divForm mobile__topspace">
            <Spin spinning={loading} size="large">
              <Table
                columns={[
                  {
                    title: traslate("Dealer ID"),
                    dataIndex: "dealerId",
                    width: 100,
                  },
                  {
                    title: traslate("Dealer Company"),
                    dataIndex: "dealerCompany",
                  },
                  {
                    title: traslate("Sync ID"),
                    dataIndex: "jewelryCSVHistoryID",
                    width: 100,
                  },
                  {
                    title: traslate("Type"),
                    dataIndex: "typeName",
                    width: 100,
                  },
                  {
                    title: traslate("Status"),
                    dataIndex: "status",
                    width: 100,
                  },
                  {
                    title: traslate("Discard"),
                    fixed: "right",
                    key: "7",
                    width: 100,
                    render: (index, row) => (
                      <div>
                        <div>
                          <div onClick={() => showDiscardModal(row)}>
                            <span className="linkText">
                              {traslate("Discard")}
                            </span>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                locale={{ emptyText: "No Pending Syncs Found" }}
                dataSource={pendingJewelrySyncList}
                onChange={(pagination, filters, sorters) => {
                  document
                    .getElementsByClassName("content__area")[0]
                    .scroll(0, 0);
                }}
                pagination={{
                  total: totalCount,
                  pageNo: pageNo,
                  pageSize: pageSize,
                  pageSizeOptions: [10, 20, 50, 100],
                  responsive: true,
                  showSizeChanger: true,
                  onChange: handlePageNoChange,
                  onShowSizeChange: handlePageSizeChange,
                }}
                scroll={{ y: 800 }}
              />
            </Spin>
          </div>
        ) : null}
      </div>
      <Modal
        title={traslate("Discard")}
        className="modal__addcolor"
        centered
        visible={isDiscardModalVisible}
        onOk={() => setIsDiscardModalVisible(false)}
        onCancel={() => setIsDiscardModalVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={handleDiscardCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleDiscardItem}>
            {traslate("Discard")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="subheading">
                      {traslate("Are you sure you want to discard ?")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={traslate("Discard All")}
        className="modal__addcolor"
        centered
        visible={isDiscardAllModalVisible}
        onOk={() => setIsDiscardAllModalVisible(false)}
        onCancel={() => setIsDiscardAllModalVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={handleDiscardAllCancel}>
            {traslate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleDiscardAllItem}>
            {traslate("Discard All")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="subheading">
                      {traslate("Are you sure you want to discard all ?")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PendingSync);
