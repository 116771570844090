import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import CatalogJewelryDataService from "../../../services/catalog-jewelry.service";
import { useSelector } from "react-redux";
import traslate from "../../../i18n/translate";

const DiamondUploadHistory = () => {
  const [dataUploadList, setDataUploadList] = useState([]);
  const [imageUploadList, setImageUploadList] = useState([]);
  const [diamondUploadList, setDiamondUploadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Diamond");
  const [dealerId, setDealerId] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [loginUser, setLoginUser] = useState({});
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
  const [vendorStatusID, setVendorStatusID] = useState(0);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        setDealerMemberTypeName(loginDetials.responseData.dealerMemberTypeName);
        handleGetDiamondUploadList(
          loginDetials.responseData.dealerId,
          pageNo,
          pageSize
        );
        setLoginUser(loginDetials.responseData);
      }
    }
  }, []);
  const handleTabsChange = (e) => {
    setSelectedTab(e);
    if (e === "DataUpload") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetDataUploadList(dealerId, 1, 10);
    }
    if (e === "ImageUpload") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetImageUploadList(dealerId, 1, 10);
    }
    if (e === "Diamond") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetDiamondUploadList(dealerId, 1, 10);
    }
  };
  const handleGetDataUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "Jewelry",
      };
      CatalogJewelryDataService.GetDataUploadList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setDataUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setDataUploadList([]);
              setTotalPage(0);
            }
          } else {
            setDataUploadList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetImageUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "JewelryImage",
      };
      CatalogJewelryDataService.GetImageUploadList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setImageUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setImageUploadList([]);
              setTotalPage(0);
            }
          } else {
            setImageUploadList([]);
            setTotalPage(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetDiamondUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "Diamond",
      };
      CatalogJewelryDataService.GetDiamondUploadList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setDiamondUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setDiamondUploadList([]);
              setTotalPage(0);
            }
          } else {
            setDiamondUploadList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDownloadReport = (row, type) => {
    try {
      let inputData = {
        summaryId: row.summaryID,
        dealerId: row.dealerID,
        type: type,
      };
      setLoading(true);
      CatalogJewelryDataService.DownloadReport(inputData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let monthNo = new Date().getMonth() + 1;

          let filename =
            loginUser.dealerCompany +
            // "(" +
            // loginUser.dealerId +
            // ")" +
            // row.summaryID +
            "_" +
            // new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            monthNo +
            "-" +
            new Date().getDate() +
            "-" +
            new Date().getFullYear() +
            ".csv";
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleRefershUpload = () => {
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, pageNo, pageSize);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, pageNo, pageSize);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, pageNo, pageSize);
    }
  };
  const handlePageNoChange = (page, pageSize) => {
    setPageNo(page);
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, page, pageSize);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, page, pageSize);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, page, pageSize);
    }
  };
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, current, size);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, current, size);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, current, size);
    }
  };

  const handleDownload = (url, fileName) => {
    window.open(url, "_blank");
  };

  return (
    <div className="prodetail__section">
      {vendorStatusID == 3 ? (
        <>{traslate("Please Contact Gemfind to get Access.")}</>
      ) : (
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {traslate("Diamond Upload History")}{" "}
                  </h4>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      {dealerMemberTypeName !== "VendorDiamond" && (
                        <Tab
                          eventKey="DataUpload"
                          title={traslate("Data Upload")}
                        >
                          <div className="tabs__content">
                            <Table
                              columns={[
                                {
                                  title: traslate("Date"),
                                  dataIndex: "dateOnly",
                                  width: 100,
                                },
                                {
                                  title: traslate("Time"),
                                  dataIndex: "timeOnly",
                                  width: 100,
                                },
                                {
                                  title: traslate("File"),
                                  dataIndex: "fileName",
                                  width: 300,
                                  render: (item, row) => {
                                    return (
                                      <React.Fragment>
                                        {row.downloadLink == null ? (
                                          <span>{row.fileName}</span>
                                        ) : (
                                          <p className="linkText">
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  row.downloadLink,
                                                  row.fileName
                                                )
                                              }
                                            >
                                              {" "}
                                              {row.fileName}
                                            </span>
                                          </p>
                                        )}
                                      </React.Fragment>
                                    );
                                  },
                                },
                                {
                                  title: traslate("Type"),
                                  dataIndex: "type",
                                  width: 120,
                                },
                                {
                                  title: traslate("Status"),
                                  dataIndex: "status",
                                  width: 100,
                                },
                                {
                                  title: traslate("Records"),
                                  dataIndex: "records",
                                  width: 100,
                                },
                                {
                                  title: traslate("Report"),
                                  dataIndex: "report",
                                  width: 100,
                                  render: (item, row) => {
                                    return (
                                      <React.Fragment>
                                        {row.status != "Completed" ? (
                                          "InProcess"
                                        ) : (
                                          <a
                                            href="#"
                                            onClick={() => {
                                              handleDownloadReport(
                                                row,
                                                "Jewelry"
                                              );
                                            }}
                                          >
                                            {traslate("Download")}
                                          </a>
                                        )}
                                      </React.Fragment>
                                    );
                                  },
                                },
                              ]}
                              dataSource={dataUploadList}
                              loading={loading}
                              scroll={{ x: 600, y: 800 }}
                              pagination={{
                                current: pageNo,
                                pageSize: pageSize,
                                pageSizeOptions: [10, 20, 50, 100],
                                responsive: true,
                                showSizeChanger: true,
                                total: totalPage,
                                onChange: handlePageNoChange,
                                onShowSizeChange: handlePageSizeChange,
                              }}
                            />
                          </div>
                        </Tab>
                      )}
                      {dealerMemberTypeName !== "VendorDiamond" && (
                        <Tab
                          eventKey="ImageUpload"
                          title={traslate("Image Upload")}
                        >
                          <Table
                            columns={[
                              {
                                title: traslate("Date"),
                                dataIndex: "dateOnly",
                                width: 100,
                              },
                              {
                                title: traslate("Time"),
                                dataIndex: "timeOnly",
                                width: 100,
                              },
                              {
                                title: traslate("File"),
                                dataIndex: "fileName",
                                width: 300,
                                render: (item, row) => {
                                  return (
                                    <React.Fragment>
                                      {row.downloadLink == null ? (
                                        <span>{row.fileName}</span>
                                      ) : (
                                        <p className="linkText">
                                          <span
                                            onClick={() =>
                                              handleDownload(
                                                row.downloadLink,
                                                row.fileName
                                              )
                                            }
                                          >
                                            {" "}
                                            {row.fileName}
                                          </span>
                                        </p>
                                      )}
                                    </React.Fragment>
                                  );
                                },
                              },
                              {
                                title: traslate("Status"),
                                dataIndex: "status",
                                width: 120,
                              },
                              {
                                title: traslate("Records"),
                                dataIndex: "records",
                                width: 100,
                              },
                              {
                                title: traslate("Report"),
                                dataIndex: "report",
                                width: 100,
                                render: (item, row) => {
                                  return (
                                    <React.Fragment>
                                      {row.status != "Completed" ? (
                                        "InProcess"
                                      ) : (
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleDownloadReport(row, "Image");
                                          }}
                                        >
                                          {traslate("Download")}
                                        </a>
                                      )}
                                    </React.Fragment>
                                  );
                                },
                              },
                            ]}
                            dataSource={imageUploadList}
                            loading={loading}
                            scroll={{ x: 600, y: 800 }}
                            pagination={{
                              current: pageNo,
                              pageSize: pageSize,
                              pageSizeOptions: [10, 20, 50, 100],
                              responsive: true,
                              showSizeChanger: true,
                              total: totalPage,
                              onChange: handlePageNoChange,
                              onShowSizeChange: handlePageSizeChange,
                            }}
                          />
                        </Tab>
                      )}
                      <Tab
                        eventKey="Diamond"
                        title={traslate("Diamond Upload")}
                      >
                        <Table
                          columns={[
                            {
                              title: traslate("Date"),
                              dataIndex: "dateOnly",
                              width: 80,
                            },
                            {
                              title: traslate("Time"),
                              dataIndex: "timeOnly",
                              width: 80,
                            },

                            {
                              title: traslate("File"),
                              dataIndex: "fileName",
                              width: 400,
                              render: (item, row) => {
                                return (
                                  <React.Fragment>
                                    {row.downloadLink == null ? (
                                      <span>{row.fileName}</span>
                                    ) : (
                                      <p className="linkText">
                                        <span
                                          onClick={() =>
                                            handleDownload(
                                              row.downloadLink,
                                              row.fileName
                                            )
                                          }
                                        >
                                          {" "}
                                          {row.fileName}
                                        </span>
                                      </p>
                                    )}
                                  </React.Fragment>
                                );
                              },
                            },
                            {
                              title: traslate("Type"),
                              dataIndex: "type",
                              width: 100,
                            },
                            {
                              title: traslate("Status"),
                              dataIndex: "status",
                              width: 80,
                            },
                            {
                              title: traslate("Records"),
                              dataIndex: "records",
                              width: 130,
                            },
                            {
                              title: traslate("Report"),
                              dataIndex: "report",
                              width: 80,
                              render: (item, row) => {
                                return (
                                  <React.Fragment>
                                    {row.status != "Completed" ? (
                                      "InProcess"
                                    ) : (
                                      <a
                                        href="#"
                                        onClick={() => {
                                          handleDownloadReport(row, "Diamond");
                                        }}
                                      >
                                        {traslate("Download")}
                                      </a>
                                    )}
                                  </React.Fragment>
                                );
                              },
                            },
                          ]}
                          dataSource={diamondUploadList}
                          loading={loading}
                          scroll={{ x: 600, y: 800 }}
                          pagination={{
                            current: pageNo,
                            pageSize: pageSize,
                            pageSizeOptions: [10, 20, 50, 100],
                            responsive: true,
                            showSizeChanger: true,
                            total: totalPage,
                            onChange: handlePageNoChange,
                            onShowSizeChange: handlePageSizeChange,
                          }}
                        />
                      </Tab>
                    </Tabs>

                    <div className="col-md-6 mt-3">
                      <button
                        className="primary-btn"
                        onClick={handleRefershUpload}
                      >
                        <i
                          className="fa fa-refresh"
                          style={{ color: "white", marginRight: "10px" }}
                        ></i>
                        {traslate("Update History")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiamondUploadHistory;
