import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Button,
  Modal,
  Table,
  Spin,
  Checkbox,
  Pagination,
  Popconfirm,
  Input,
} from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
const { Search } = Input;

const { Option } = Select;
const AddCampaignPage = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validations, setValidations] = useState({
    nameVal: "",
    vendorVal: "",
    subjectVal: "",
    bodyVal: "",
    contentVal: "",
  });

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);
  const [campaignList, setCampaignList] = useState([]);
  const [memberList, setMemeberList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [campaignData, setCampaignData] = useState({
    name: "",
    vendor: "",
    subject: "",
    body: "",
    content: "",
  });
  const [campaignDetails, setCampaignDetails] = useState({
    campaignName: "",
    groupName: "",
    templateName: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditCampaign, setIsEditCampaign] = useState(false);

  const [dealerId, setDealerId] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [checkAll, setCheckAll] = useState(false);
  const [checkboxData, setCheckboxData] = useState([]);
  const [mailFormIdData, setMailFormIdData] = useState([]);
  const [checkCount, setCheckCount] = useState(0);
  const [campaignDropdownList, setCampaignDropdownList] = useState(null);

  const [searchCampaign, setSearchCampaign] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        // handleCampaignfromgrid();
        // handleMemebershipdropdowen();
        handleGetCampaignList(pageNo, pageSize, searchCampaign);
        handleGetCampaignDropDownList();
      }
    }
  }, []);

  const handleCancel = () => {
    // setVisible(false);
    // setCampaignData({
    //   name: "",
    //   vendor: "",
    //   subject: "",
    //   body: "",
    //   content: "",
    // });
    // setValidations({
    //   nameVal: "",
    //   vendorVal: "",
    //   subjectVal: "",
    //   bodyVal: "",
    //   contentVal: "",
    // });
    setVisible(false);
    setIsEditCampaign(false);
    let tempData = campaignDetails;
    tempData["campaignName"] = "";
    tempData["groupName"] = "";
    tempData["templateName"] = "";
    setCampaignDetails(tempData);
  };

  function onSearch(val) {
    setPageNo(1);
    setPageSize(10);
    handleGetCampaignList(1, 10, val);
    setSearchCampaign(val);
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setCampaignDetails({
        ...campaignDetails,
        [name]: e,
      });
    } else {
      setCampaignDetails({
        ...campaignDetails,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleValidation = () => {
    const { name, vendor, subject, body, content } = campaignData;

    const validations = {
      nameVal: "",
      vendorVal: "",
      subjectVal: "",
      bodyVal: "",
      contentVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      validations.nameVal = traslate("Name is Compulsory.");
    }
    if (!vendor) {
      isValid = false;
      validations.vendorVal = traslate("Vendor is Compulsory.");
    }
    if (!subject.trim()) {
      isValid = false;
      validations.subjectVal = traslate("Subject is Compulsory.");
    }
    if (!body.trim()) {
      isValid = false;
      validations.bodyVal = traslate("Body is Compulsory.");
    }
    if (!content) {
      isValid = false;
      validations.contentVal = traslate("Content is Compulsory.");
    }

    setValidations(validations);

    return isValid;
  };

  const handleCampaignfromgrid = () => {
    try {
      setLoading(true);
      AdminCampaignsService.Campaignfromgrid()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setCampaignList(responseData);
            var tempId = [
              ...new Set(responseData.map(({ mailFormId }) => mailFormId)),
            ];
            setMailFormIdData(tempId);
            setLoading(false);
          } else {
            setCampaignList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleMemebershipdropdowen = () => {
    try {
      setLoading(true);
      AdminCampaignsService.Memebershipdropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setMemeberList(responseData);
          } else {
            setMemeberList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCampaignmailform = () => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputdata = {
        sendFrom: campaignData.name,
        dealerid: campaignData.vendor,
        subject: campaignData.subject,
        mailContent: campaignData.content,
        body: campaignData.body,
      };
      setBtnLoading(true);
      AdminCampaignsService.Campaignmailform(inputdata)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setBtnLoading(false);
            setVisible(false);
            NotificationManager.success("Data Added Successfully.");
            setCampaignData({
              name: "",
              vendor: "",
              subject: "",
              body: "",
              content: "",
            });
            handleCampaignfromgrid();
          } else {
            setBtnLoading(false);
            setVisible(false);
            NotificationManager.error("Data Not Added Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          setVisible(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
      setVisible(false);
    }
  };

  const handleSaveCampaingMailing = () => {
    // var Id = selectedRows.map(a => a.mailFormId)
    try {
      let inputdata = {
        campaignList: checkboxData ? checkboxData.join(",").toString() : "",
        dealerid: dealerId ? dealerId : "",
      };
      setEmailLoading(true);
      AdminCampaignsService.SaveCampaingMailing(inputdata)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setEmailLoading(false);
            NotificationManager.success("Mail Has Been Sent Successfully.");
            setCheckboxData([]);
          } else {
            setEmailLoading(false);
            NotificationManager.error("Mail Has Not Been Sent");
            setCheckboxData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  // RowSelection

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      if (selectedRows.length > 0) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
      let result = selectedRows.map((a) => a.mailFormId);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //   },
  // }

  const handleCancelModal = () => {
    setEmailModalVisible(false);
  };

  // useEffect(() => {
  //   if (checkboxData.length == mailFormIdData.length && checkCount == 1) {
  //     setCheckAll(true);
  //   } else {
  //     setCheckAll(false);
  //   }
  //   setCheckCount(1);
  // }, [checkboxData]);

  const handleCheckChange = (e, id) => {
    // setBtnDisabled(false);
    if (e.target.checked == true) {
      let data = checkboxData;
      // data.push(id)
      // setCheckboxData(data);
      // console.log(checkboxData)
      setCheckboxData((data) => [id, ...data]);
      // if(data.length == mailFormIdData.length){
      //   setCheckAll(true);

      // }
      // else{
      //   setCheckAll(false);

      // }
    } else {
      let data = checkboxData;

      data = data.filter((e) => e !== id);
      setCheckboxData(data);
      // if(data.length == mailFormIdData.length){
      //   setCheckAll(true);
      // }
      // else{
      //   setCheckAll(false);
      // }
    }
  };
  const onCheckAllChange = (e, id) => {
    // setBtnDisabled(false);
    if (e.target.checked == true) {
      setCheckAll(true);
      setCheckboxData(id);
    } else {
      // setBtnDisabled(true);
      setCheckAll(false);
      setCheckboxData([]);
    }
  };

  const handleGetCampaignList = (page, size, val) => {
    let inputData = {
      pageno: page,
      pagesize: size,
      search: val,
    };
    setLoading(true);
    AdminCampaignsService.GetCampaignListV2(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          let list = response?.data?.responseData?.campaignDealerGroupslist;
          if (list) {
            setCampaignList(list);
            setLoading(false);
            setTotalCount(response?.data?.responseData?.totalrecords);
          }
        } else {
          NotificationManager.error(message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetCampaignList(page, pageSize, searchCampaign);
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetCampaignList(current, size, searchCampaign);
  }

  const handleEditCampaign = (row) => {
    setVisible(true);
    setSelectedRows(row);
    let tempData = campaignDetails;
    tempData["campaignName"] = row.campaignName;
    tempData["groupName"] = row.dealerGroupID;
    tempData["templateName"] = row.templateID;
    setCampaignDetails(tempData);
    setIsEditCampaign(true);
  };

  const handleGetCampaignDropDownList = (page, size) => {
    setLoading(true);
    AdminCampaignsService.GetCampaignDropDownList()
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          let list = response?.data?.responseData;
          if (list) {
            setCampaignDropdownList(list);
          }
        } else {
          setCampaignDropdownList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddOrEditCampaign = () => {
    if (isEditCampaign) {
      let tempData = selectedRows;
      tempData["campaignName"] = campaignDetails.campaignName;
      tempData["dealerGroupID"] = campaignDetails.groupName;
      tempData["templateID"] = campaignDetails.templateName;
      handleCreateUpdateDeleteCampaign("edit", selectedRows);
    } else {
      handleCreateUpdateDeleteCampaign("add");
    }
  };

  const handleCreateUpdateDeleteCampaign = (flagStatus, row) => {
    if (
      (campaignDetails.campaignName == "" ||
        campaignDetails.groupName == "" ||
        campaignDetails.templateName == "") &&
      flagStatus !== "delete"
    ) {
      NotificationManager.error("Fields should not be empty");
      return false;
    } else {
      let inputData = {
        campaignID: row ? row.campaignID : "",
        campaignName: row ? row.campaignName : campaignDetails.campaignName,
        dealerGroupID: row ? row.dealerGroupID : campaignDetails.groupName,
        templateID: row ? row.templateID : campaignDetails.templateName,
        flag: flagStatus,
        dealerID: dealerId?.toString(),
      };
      setSubmitLoading(true);

      AdminCampaignsService.CreateUpdateDeleteCampaignMaster(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            if (
              response?.data?.responseData == "Campaign Name Already Exists"
            ) {
              NotificationManager.error(response.data.responseData);
              setSubmitLoading(false);
            } else {
              NotificationManager.success(response.data.responseData);
              setSubmitLoading(false);
              handleGetCampaignList(pageNo, pageSize);
              let tempData = campaignDetails;
              tempData["campaignName"] = "";
              tempData["groupName"] = "";
              tempData["templateName"] = "";
              setCampaignDetails(tempData);
              setVisible(false);
              setIsEditCampaign(false);
            }
          } else {
            NotificationManager.error(message);
            setSubmitLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("Add Campaign")}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-3 mobile-m-0">
              <div className="row">
                <div className="col-lg-6">
                  <Search
                    placeholder="Search"
                    allowClear
                    onSearch={(e) => onSearch(e)}
                    style={{
                      width: 300,
                    }}
                    className="mb-4"
                  />
                </div>
                <div className="col-lg-6 d-flex justify-content-end mobile-justify-content-start">
                  {/* <div>
                    <div className="addedit__btndiv mr-2">
                      <Button
                        className={
                          checkboxData.length > 0
                            ? "primary-btn"
                            : "primary-btn disabled-btn"
                        }
                        disabled={checkboxData.length > 0 ? false : true}
                        onClick={handleSaveCampaingMailing}
                        // onClick={()=>setEmailModalVisible(true)}
                        loading={emailLoading}
                      >
                        <i className="fa fa-envelope mr-1"></i>{" "}
                        {traslate("Send Email")}
                      </Button>
                    </div>
                  </div> */}
                  <div>
                    <div className="addedit__btndiv">
                      <Button
                        className="primary-btn"
                        onClick={() => setVisible(true)}
                      >
                        {" "}
                        <img src={Create_icon} /> {traslate("Add")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="tradeshow_tbldiv">
                <Spin spinning={loading}>
                  <Table
                    columns={[
                      {
                        title: (
                          <div className="filtbtn__div">
                            <span className="select__all">
                              <Checkbox
                                onChange={(e) =>
                                  onCheckAllChange(e, mailFormIdData)
                                }
                                checked={checkAll}
                              />
                            </span>
                          </div>
                        ),
                        dataIndex: "selectedDealerID",
                        width: 150,
                        render: (item, row) => {
                          return (
                            <div className="action__btns">
                              <Checkbox
                                onChange={(e) =>
                                  handleCheckChange(e, row.mailFormId)
                                }
                                checked={
                                  checkboxData.find(
                                    (id) => id == row.mailFormId
                                  )
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          );
                        },
                      },
                      {
                        title: traslate("Dealer Name"),
                        dataIndex: "dealerName",
                      },
                      {
                        title: traslate("Send From"),
                        dataIndex: "sendFrom",
                      },
                      {
                        title: traslate("Subject"),
                        dataIndex: "subject",
                      },
                      {
                        title: traslate("Mail Content"),
                        dataIndex: "mailContent",
                      },
                      {
                        title: traslate("Body"),
                        dataIndex: "body",
                      },

                      // {
                      //   title: "Content",
                      //   dataIndex: "action",
                      //   render: (item, row) => {
                      //     return (
                      //       <div className="action__btns">
                      //         <div className="image__block">
                      //           <img
                      //             src={Edit_icon}
                      //             onClick={() =>
                      //               handleGetShowsDetailsByID(row.id)
                      //             }
                      //             alt=""
                      //           />
                      //         </div>
                      //         <div className="image__block">
                      //           <img
                      //             src={Delete_iconbl}
                      //             onClick={() => handleOpenDeleteModal(row.id)}
                      //             alt=""
                      //           />
                      //         </div>
                      //       </div>
                      //     );
                      //   },
                      //   width: 100,
                      // },
                    ]}
                    pagination={{
                      responsive: true,
                      showSizeChanger: true,
                    }}
                    dataSource={campaignList}
                    scroll={{ x: 600, y: 800 }}
                    loading={loading}
                    // rowSelection={rowSelection}
                    // rowKey={(record) => record.mailFormId}
                  />
                </Spin>
              </div>
            </div> */}

            <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="tradeshow_tbldiv">
                <Spin spinning={loading}>
                  <Table
                    columns={[
                      {
                        title: traslate("Campaign Name"),
                        dataIndex: "campaignName",
                      },
                      {
                        title: traslate("Group Name"),
                        dataIndex: "dealerGroupName",
                      },
                      {
                        title: traslate("Template Name"),
                        dataIndex: "templateName",
                      },
                      {
                        title: traslate("Actions"),
                        dataIndex: "save",
                        render: (text, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => handleEditCampaign(row)}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <Popconfirm
                                title={traslate(
                                  "Are you sure you want to delete ?"
                                )}
                                onConfirm={() =>
                                  handleCreateUpdateDeleteCampaign(
                                    "delete",
                                    row
                                  )
                                }
                                placement="left"
                              >
                                <img src={Delete_iconbl} alt="" />
                              </Popconfirm>
                            </div>
                          </div>
                        ),
                        width: 100,
                      },
                    ]}
                    pagination={false}
                    dataSource={campaignList}
                    scroll={{ x: 600, y: 800 }}
                  />
                  <div className="col-md-12 mt-3" style={{ textAlign: "end" }}>
                    <Pagination
                      current={pageNo}
                      pageSize={pageSize}
                      total={totalCount}
                      onChange={handlePageNoChange}
                      onShowSizeChange={handlePageSizeChange}
                      showSizeChanger="true"
                    />
                  </div>
                </Spin>
              </div>
            </div>

            {/* <div className="col-lg-12">
              <Modal
                title={traslate("Add Campaign")}
                className="modal__tradeshow"
                centered
                visible={visible}
                onOk={handleCampaignmailform}
                onCancel={() => handleCancel()}
                width={500}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    loading={btnLoading}
                    key="submit"
                    type="primary"
                    onClick={handleCampaignmailform}
                  >
                    {traslate("Submit")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div class="col-lg-12"></div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>{traslate("Name")} </label>
                              <input
                                name="name"
                                value={campaignData.name}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Name"
                                className={validations.nameVal && "border__red"}
                              />
                              {validations.nameVal && (
                                <p className="error-color-red">
                                  {validations.nameVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>{traslate("Vendor")} </label>
                              <Select
                                name="vendor"
                                // className="border__grey"
                                className={
                                  validations.vendorVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                                showSearch
                                placeholder="Select Start Time"
                                optionFilterProp="children"
                                value={campaignData.vendor}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "vendor")
                                }
                              >
                                <Option value="">Select </Option>
                                {memberList &&
                                  memberList.map((item, i) => {
                                    return (
                                      <Option value={item.dealerid}>
                                        {item.dealerName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {validations.vendorVal && (
                                <p className="error-color-red">
                                  {validations.vendorVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>{traslate("Subject")} </label>
                              <input
                                name="subject"
                                value={campaignData.subject}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Subject"
                                className={
                                  validations.subjectVal && "border__red"
                                }
                                autoComplete="off"
                              />
                              {validations.subjectVal && (
                                <p className="error-color-red">
                                  {validations.subjectVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block text__box">
                              <label>{traslate("Body")} </label>
                              <textarea
                                name="body"
                                value={campaignData.body}
                                onChange={handleOnChange}
                                className={validations.bodyVal && "border__red"}
                              />
                              {validations.bodyVal && (
                                <p className="error-color-red">
                                  {validations.bodyVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block text__box">
                              <label>{traslate("Content")} </label>
                              <textarea
                                name="content"
                                value={campaignData.content}
                                onChange={handleOnChange}
                                className={
                                  validations.contentVal && "border__red"
                                }
                              />
                              {validations.contentVal && (
                                <p className="error-color-red">
                                  {validations.contentVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div> */}

            <div className="col-lg-12">
              <Modal
                title={traslate(
                  isEditCampaign ? "Edit Campaign" : "Add Campaign"
                )}
                className="modal__tradeshow"
                centered
                visible={visible}
                onOk={handleAddOrEditCampaign}
                onCancel={handleCancel}
                width={500}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    loading={submitLoading}
                    key="submit"
                    type="primary"
                    onClick={handleAddOrEditCampaign}
                  >
                    {traslate(isEditCampaign ? "Save" : "Submit")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div class="col-lg-12"></div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {traslate("Campaign Name")}
                                <span>*</span>{" "}
                              </label>
                              <input
                                name="campaignName"
                                value={campaignDetails.campaignName}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Name"
                                className={validations.nameVal && "border__red"}
                              />
                              {validations.nameVal && (
                                <p className="error-color-red">
                                  {validations.nameVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {traslate("Group Name")} <span>*</span>
                              </label>
                              <Select
                                name="groupName"
                                // className="border__grey"
                                className={
                                  validations.vendorVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                                showSearch
                                placeholder="Select Start Time"
                                optionFilterProp="children"
                                value={campaignDetails.groupName}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "groupName")
                                }
                              >
                                <Option value="">Select </Option>
                                {campaignDropdownList &&
                                  campaignDropdownList?.campaignDealerGroupslist?.map(
                                    (item, i) => {
                                      return (
                                        <Option value={item.dealerGroupID}>
                                          {item.dealerGroupName}
                                        </Option>
                                      );
                                    }
                                  )}
                              </Select>
                              {validations.vendorVal && (
                                <p className="error-color-red">
                                  {validations.vendorVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {traslate("Template Name")}
                                <span>*</span>{" "}
                              </label>
                              <Select
                                name="templateName"
                                className={
                                  validations.vendorVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                                showSearch
                                placeholder="Select Start Time"
                                optionFilterProp="children"
                                value={campaignDetails.templateName}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "templateName")
                                }
                              >
                                <Option value="">Select </Option>
                                {campaignDropdownList &&
                                  campaignDropdownList?.campaignTemplatelist?.map(
                                    (item, i) => {
                                      return (
                                        <Option value={item.templateID}>
                                          {item.templateName}
                                        </Option>
                                      );
                                    }
                                  )}
                              </Select>
                              {validations.vendorVal && (
                                <p className="error-color-red">
                                  {validations.vendorVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCampaignPage;
