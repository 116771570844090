import React, { useState, useEffect } from "react";
import { Select, Modal, Spin, Button } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";

export const EmailTemplate = (props) => {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState("");
  const [dealerId, setDealerId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerList, setDealerList] = useState([]);
  const [typeName, setTypeName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isCommunityEmails, setIsCommunityEmails] = useState(true);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
    handleFillDealerDropDown();
  }, []);

  const onChange = (value) => {
    setDealerId(value);
    handleGetEmailDealerStatus(value);
  };

  const handleFillDealerDropDown = () => {
    try {
      AdminToolsDataService.FillDealerDropDown()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetEmailTemplatesById = (name) => {
    let inputParams = {
      DealerID: dealerId,
      TypeName: name,
    };
    setContent("");
    setTypeName(name);
    setVisible(true);
    setLoading(true);
    AdminSettingsDataService.GetEmailTemplatesById(inputParams)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData[0]) {
            setContent(responseData[0].emailContent);
          }
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSaveEmailTemplate = () => {
    let inputParams = {
      dealerID: dealerId,
      typeName: typeName,
      eMailContent: content,
    };
    AdminSettingsDataService.SaveEmailTemplate(inputParams)
      .then((response) => {
        var message = response.data.message;
        if (message == "RECORD UPDATED SUCESSFULLY") {
          handleCloseModal();
          NotificationManager.success(
            "Email template has been saved successfully."
          );
        } else {
          NotificationManager.error("Email template has not been updated.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetEmailDealerStatus = (id) => {
    let inputParams = {
      dealerID: id,
    };
    setPageLoading(true);
    AdminSettingsDataService.GetEmailDealerStatus(inputParams)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setIsCommunityEmails(responseData);
        } else {
          setIsCommunityEmails(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const handleCloseModal = () => {
    setContent("");
    setVisible(false);
  };
  return (
    <div className="form__fields">
      <Spin spinning={pageLoading}>
        <div className="row">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0 mb-3">
              {" "}
              {traslate("Email Templates")}{" "}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("All Dealers")} </label>
              <Select
                className="border__grey"
                showSearch
                placeholder="Select Dealer"
                optionFilterProp="children"
                notFoundContent="No Data Found"
                value={dealerId}
                onChange={onChange}
              >
                <Option value={0}>--{traslate("All Dealer")}--</Option>
                {dealerList.map((item) => {
                  return (
                    <Option value={item.dealerID}>
                      {item.dealerCompany + " (" + item.dealerID + " )"}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>

        <div className="emailTemplate">
          <div className="row">
            {dealerId == 0 ? (
              <div className="col-md-4 mb-4">
                <div className="emailDetailBlock">
                  <h1> {traslate("Registration Emails")} </h1>
                  <ul>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("NewAccount")
                        }
                      >
                        {traslate("New Account")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("ForgotPassword")
                        }
                      >
                        {traslate("Forgot Password")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("ResetPassword")
                        }
                      >
                        {traslate("Reset Password")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("ForgotUserName")
                        }
                      >
                        {traslate("Forgot UserName")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("lockedAccount")
                        }
                      >
                        {traslate("Account Locked")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}

            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Retailer Emails")} </h1>
                <ul>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("InviteNewRetailer")
                      }
                    >
                      {traslate("Invite New Retailer")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {dealerId == 0 || isCommunityEmails ? (
              <div className="col-md-4 mb-4">
                <div className="emailDetailBlock">
                  <h1> {traslate("Community Emails")} </h1>
                  <ul>
                    <li>
                      <a onClick={() => handleGetEmailTemplatesById("Active")}>
                        {traslate("Retailer Request e-Mail to")} "
                        {traslate("ACTIVE")}" {traslate("GF Members")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("CommunityManaged")
                        }
                      >
                        {traslate("Retailer e-Mail to")} "
                        {traslate("COMMUNITY MANAGED")}"{" "}
                        {traslate("Non GF Members")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("ProfileOnly")
                        }
                      >
                        {traslate("Retailer e-Mail to")} "
                        {traslate("PROFILE ONLY")}" {traslate("Non GF Members")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("LimitedAccount")
                        }
                      >
                        {traslate("Retailer e-Mail to")} "
                        {traslate("LIMITED ACCOUNT")}" {traslate("GF Members")}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleGetEmailTemplatesById("Decline")}>
                        {traslate("Vendor Decline eMail")}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => handleGetEmailTemplatesById("Accept")}>
                        {traslate("Vendor Accept eMail")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("UpdateProductData")
                        }
                      >
                        {traslate("Request to Update Product Data")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("VendorAppointment")
                        }
                      >
                        {traslate("Vendor Appointment")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          handleGetEmailTemplatesById("InviteNewVendor")
                        }
                      >
                        {traslate("Invite New Vendor")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}

            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Contact Emails (MasterLink)")}</h1>
                <ul>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustomerRegistration")
                      }
                    >
                      {traslate("Customer Registration")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustNewAppointments")
                      }
                    >
                      {traslate("New Appointments")}
                    </a>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Inquires (Request More Info)")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "CustMoreInfoRetailer"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("CustMoreInfoSender")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Send Email (Email To Friend)")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("ToFriendInquires")
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("CustInquires")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("ToSenderInquires")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "ToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("ToRetailerDropAHint")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustNewFavoriteList")
                      }
                    >
                      {traslate("New Favorite List")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustNewFavoriteItem")
                      }
                    >
                      {traslate("New Favorite Items Added")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustConversations")
                      }
                    >
                      {traslate("Conversations")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustResetPassword")
                      }
                    >
                      {traslate("Customer Reset Password")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CustResetPasswordNew")
                      }
                    >
                      {traslate("Customer Reset Password (New)")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Diamond Search Emails")} </h1>
                <ul>
                  <li>
                    <div>
                      <h2> {traslate("Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "DSToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("DSToSenderInquires")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Email To Friend")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("DSToFriendEmailF")
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("DSToRetailerEmailF")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("DSToSenderEmailF")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "DSToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "DSToRetailerDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Schedule View")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "DLToScheduleViewDiamondDetail"
                              )
                            }
                          >
                            {traslate("Diamond Detail")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("DiamondCompare")
                      }
                    >
                      {traslate("Diamond Compare")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("DiamondCustomerCompare")
                      }
                    >
                      {traslate("Diamond Customer Compare")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Fancy Color Diamond Search Emails")} </h1>
                <ul>
                  <li>
                    <div>
                      <h2> {traslate("Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "FCToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("FCToSenderInquires")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Email To Friend")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("FCToFriendEmailF")
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("FCToRetailerEmailF")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("FCToSenderEmailF")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "FCToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "FCToRetailerDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Schedule View")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "DLToScheduleViewColorDiamondDetail"
                              )
                            }
                          >
                            {traslate("Color Diamond Detail")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Ring Builder Emails")} </h1>
                <ul>
                  <li>
                    <div>
                      <h2> {traslate("Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("RBToSenderInquires")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Email To Friend")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("RBToFriendEmailF")
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("RBToRetailerEmailF")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("RBToSenderEmailF")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRetailerDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint (Infinity)")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRecipientDropAHintInfinity"
                              )
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRetailerDropAHintInfinity"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToSenderDropAHintInfinity  "
                              )
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Buy Now (Infinity)")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRetailerBuyNowInfinity"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToSenderBuyNowInfinity"
                              )
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Schedule View")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToScheduleViewSetting"
                              )
                            }
                          >
                            {traslate("Setting Detail")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToScheduleViewDiamondDetail"
                              )
                            }
                          >
                            {traslate("Diamond Detail")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToScheduleViewComplete"
                              )
                            }
                          >
                            {traslate("Complete Ring Specifications")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Pendant Builder Emails")} </h1>
                <ul>
                  <li>
                    <div>
                      <h2> {traslate("Diamond Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBDiamondToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBDiamondToSenderInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("PBToRetailerEmailF")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Email To Friend")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("PBToFriendEmailF")
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("PBToRetailerEmailF")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("PBToSenderEmailF")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBToRetailerDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h2> {traslate("Schedule View")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBToScheduleViewDiamondDetail"
                              )
                            }
                          >
                            {traslate("Diamond Detail")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "PBToScheduleViewComplete"
                              )
                            }
                          >
                            {traslate("Complete Pendant Setting")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Stud Builder Emails")} </h1>
                <ul>
                  <li>
                    <div>
                      <h2> {traslate("Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToSenderInquires")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Email To Friend")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToFriendEmailF")
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToRetailerEmailF")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToSenderEmailF")
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBToRetailerDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRecipientDropAHintInfinity"
                              )
                            }
                          >
                            {traslate("Request More Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "RBToRetailerDropAHintInfinity"
                              )
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div>
                      <h2> {traslate("Schedule View")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToScheduleView")
                            }
                          >
                            {traslate("SB To ScheduleView")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBToScheduleViewComplete"
                              )
                            }
                          >
                            {traslate("SB Complete Earring To ScheduleView")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Diamond Request More Info")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToRetailerInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToSenderInquires"
                              )
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Diamond Email To Friend")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToFriendEmailF"
                              )
                            }
                          >
                            {traslate("Send Mail to Friend")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToRetailerEmailF"
                              )
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToSenderEmailF"
                              )
                            }
                          >
                            {traslate("Send Mail to Sender")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Diamond Drop A Hint")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToRecipientDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail To Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById(
                                "SBDiamondToRetailerDropAHint"
                              )
                            }
                          >
                            {traslate("Send Mail To Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h2> {traslate("Store")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToSenderStore")
                            }
                          >
                            {traslate("Send Mail to Recipient")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("SBToRetailerStore")
                            }
                          >
                            {traslate("Send Mail to Retailer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("Big Emails")} </h1>
                <ul>
                  <li>
                    <div>
                      <h2> {traslate("Email To Customer")} </h2>
                      <ul>
                        <li>
                          <a
                            onClick={() =>
                              handleGetEmailTemplatesById("BGCustomermail")
                            }
                          >
                            {traslate("Send Mail to Customer")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="emailTemplateEditorModal"
          title={traslate("Send Email")}
          centered
          visible={visible}
          onOk={handleSaveEmailTemplate}
          onCancel={handleCloseModal}
          width={800}
          hieght={600}
          footer={[
            <Button key="back" onClick={handleCloseModal}>
              {traslate("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleSaveEmailTemplate}
            >
              {traslate("Save")}
            </Button>,
          ]}
        >
          <div className="editor__section">
            <Spin spinning={loading}>
              <CKEditor
                editor={ClassicEditor}
                data={content ? content : ""}
                id="editor"
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </Spin>
          </div>
        </Modal>
      </Spin>
    </div>
  );
};
export default EmailTemplate;
