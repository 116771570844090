import React, { useEffect, useState } from "react";
import { Table, Spin, Modal, Button } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { useSelector } from "react-redux";
import traslate from "../../i18n/translate";
import { useLocation } from "react-router-dom";
import Eye_icon from "../../assets/images/icons/eye_icon.svg";

const UploadHistory = () => {
  const location = useLocation();
  const [dataUploadList, setDataUploadList] = useState([]);
  const [imageUploadList, setImageUploadList] = useState([]);
  const [diamondUploadList, setDiamondUploadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("DataUpload");
  const [dealerId, setDealerId] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [loginUser, setLoginUser] = useState({});
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [dealerMemberTypeName, setdealerMemberTypeName] = useState("");
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const [isUploadedDataModal, setIsUploadedDataModal] = useState(false);
  const [uploadListDetial, setUploadListDetial] = useState([]);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        if (location.state && location.state.key == "ImageUpload") {
          setSelectedTab("ImageUpload");
          handleGetImageUploadList(
            loginDetials.responseData.dealerId,
            pageNo,
            pageSize
          );
        } else {
          setDealerId(loginDetials.responseData.dealerId);
          setdealerMemberTypeName(
            loginDetials.responseData.dealerMemberTypeName
          );
          handleGetDataUploadList(
            loginDetials.responseData.dealerId,
            pageNo,
            pageSize
          );
          setLoginUser(loginDetials.responseData);
        }
      }
    }
  }, []);
  const handleTabsChange = (e) => {
    setSelectedTab(e);
    if (e === "DataUpload") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetDataUploadList(dealerId, 1, 10);
    }
    if (e === "ImageUpload") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetImageUploadList(dealerId, 1, 10);
    }
    if (e === "Diamond") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetDiamondUploadList(dealerId, 1, 10);
    }
  };
  const handleGetDataUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "Jewelry"
      };
      CatalogJewelryDataService.GetDataUploadList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setDataUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setDataUploadList([]);
              setTotalPage(0);
            }
          } else {
            setDataUploadList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetImageUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "JewelryImage"
      };
      CatalogJewelryDataService.GetImageUploadList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setImageUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setImageUploadList([]);
              setTotalPage(0);
            }
          } else {
            setImageUploadList([]);
            setTotalPage(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetDiamondUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: ""
      };
      CatalogJewelryDataService.GetDiamondUploadList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setDiamondUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setDiamondUploadList([]);
              setTotalPage(0);
            }
          } else {
            setDiamondUploadList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDownloadReportSummary = (row, type) => {
    try {
      let inputData = {
        summaryId: row.summaryID,
        dealerId: row.dealerID,
        type: type
      };
      setDownloadLoader(true);
      CatalogJewelryDataService.DownloadReportSummary(inputData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var filename =
            loginUser.dealerCompany +
            "(" +
            loginUser.dealerId +
            ")" +
            row.summaryID +
            "_" +
            new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            ".csv";
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          setDownloadLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloadLoader(false);
        });
    } catch (error) {
      console.log(error);
      setDownloadLoader(false);
    }
  };
  const handleDownloadReport = (row, type) => {
    try {
      let inputData = {
        summaryId: row.summaryID,
        dealerId: row.dealerID,
        type: type
      };
      setDownloadLoader(true);
      CatalogJewelryDataService.DownloadReport(inputData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var filename =
            loginUser.dealerCompany +
            "(" +
            loginUser.dealerId +
            ")" +
            row.summaryID +
            "_" +
            new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            ".csv";
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          setDownloadLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloadLoader(false);
        });
    } catch (error) {
      console.log(error);
      setDownloadLoader(false);
    }
  };
  
  const handleRefershUpload = () => {
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, pageNo, pageSize);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, pageNo, pageSize);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, pageNo, pageSize);
    }
  };
  const handlePageNoChange = (page, pageSize) => {
    setPageNo(page);
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, page, pageSize);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, page, pageSize);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, page, pageSize);
    }
  };
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, current, size);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, current, size);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, current, size);
    }
  };
  const handleDownload = (url, fileName) => {
    window.open(url, "_blank");
  };
  const handleUploadedDataModalOpen = (rowData) => {
    setIsUploadedDataModal(true);
    handleGetUploadListDetails(dealerId, pageNo, pageSize, rowData.summaryID);
  };
  const handleUploadedDataModalClose = () => {
    setIsUploadedDataModal(false);
  };
  const handleGetUploadListDetails = (id, no, size, uploadID) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "Jewelry",
        JewerlryDataUploadID: uploadID
      };
      CatalogJewelryDataService.GetDataUploadListDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData) {
              setUploadListDetial(responseData[0]);
            } else {
              setUploadListDetial([]);
            }
          } else {
            setUploadListDetial([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="prodetail__section">
        {vendorStatusID == 3 ? (
          <>{traslate("Please Contact Gemfind to get Access.")}</>
        ) : (
          <div className="form__fields">
            <div className="col-md-12">
              <div className="prod__maindiv data__maping">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="workarea__heading mt-0 mb-3">
                      {" "}
                      {traslate("Upload History")}{" "}
                    </h4>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <Spin spinning={downloadLoader}>
                      <div className="prodesc__div">
                        <Tabs
                          id="uncontrolled-tab-example"
                          className="tab__div mb-3"
                          onSelect={(e) => {
                            handleTabsChange(e);
                          }}
                          activeKey={selectedTab}
                        >
                          <Tab
                            eventKey="DataUpload"
                            title={traslate("Data Upload")}
                          >
                            <div className="tabs__content">
                              <Table
                                columns={[
                                  {
                                    title: traslate("Date"),
                                    dataIndex: "dateOnly",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Time"),
                                    dataIndex: "timeOnly",
                                    width: 100
                                  },
                                  {
                                    title: traslate("File"),
                                    dataIndex: "fileName",
                                    width: 300,
                                    render: (item, row) => {
                                      return (
                                        <React.Fragment>
                                          {row.downloadLink == null ? (
                                            <span>{row.fileName}</span>
                                          ) : (
                                            <p className="linkText">
                                              <span
                                                onClick={() =>
                                                  handleDownload(
                                                    row.downloadLink,
                                                    row.fileName
                                                  )
                                                }
                                              >
                                                {" "}
                                                {row.fileName}
                                              </span>
                                            </p>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  },
                                  {
                                    title: traslate("Type"),
                                    dataIndex: "type",
                                    width: 120
                                  },
                                  {
                                    title: traslate("Status"),
                                    dataIndex: "status",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Records"),
                                    dataIndex: "records",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Report"),
                                    dataIndex: "report",
                                    width: 100,
                                    render: (item, row) => {
                                      return (
                                        <React.Fragment>
                                          {row.status != "Completed" ? (
                                            "InProcess"
                                          ) : (
                                            <a
                                              href="#"
                                              onClick={() => {
                                                handleDownloadReportSummary(
                                                  row,
                                                  "Jewelry"
                                                );
                                              }}
                                            >
                                              {traslate("Download")}
                                            </a>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  },
                                  {
                                    title: traslate("Action"),
                                    dataIndex: "action",
                                    width: 50,
                                    render: (index, row) => (
                                      <div className="action__btns">
                                        {row.showdetails === "true" && (
                                          <div className="image__block">
                                            <img
                                              src={Eye_icon}
                                              alt="img"
                                              onClick={() =>
                                                handleUploadedDataModalOpen(row)
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )
                                  }
                                ]}
                                dataSource={dataUploadList}
                                loading={loading}
                                scroll={{ x: 600, y: 800 }}
                                pagination={{
                                  current: pageNo,
                                  pageSize: pageSize,
                                  pageSizeOptions: [10, 20, 50, 100],
                                  responsive: true,
                                  showSizeChanger: true,
                                  total: totalPage,
                                  onChange: handlePageNoChange,
                                  onShowSizeChange: handlePageSizeChange
                                }}
                              />
                            </div>
                          </Tab>
                          <Tab
                            eventKey="ImageUpload"
                            title={traslate("Image Upload")}
                          >
                            <Table
                              columns={[
                                {
                                  title: traslate("Date"),
                                  dataIndex: "dateOnly",
                                  width: 100
                                },
                                {
                                  title: traslate("Time"),
                                  dataIndex: "timeOnly",
                                  width: 100
                                },
                                {
                                  title: traslate("File"),
                                  dataIndex: "fileName",
                                  width: 300,
                                  render: (item, row) => {
                                    return (
                                      <React.Fragment>
                                        {row.downloadLink == null ? (
                                          <span>{row.fileName}</span>
                                        ) : (
                                          <p className="linkText">
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  row.downloadLink,
                                                  row.fileName
                                                )
                                              }
                                            >
                                              {" "}
                                              {row.fileName}
                                            </span>
                                          </p>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                },
                                {
                                  title: traslate("Status"),
                                  dataIndex: "status",
                                  width: 100
                                },
                                {
                                  title: traslate("Records"),
                                  dataIndex: "records",
                                  width: 100
                                },
                                {
                                  title: traslate("Report"),
                                  dataIndex: "report",
                                  width: 100,
                                  render: (item, row) => {
                                    return (
                                      <React.Fragment>
                                        {row.status != "Completed" ? (
                                          "InProcess"
                                        ) : (
                                          <a
                                            href="#"
                                            onClick={() => {
                                              handleDownloadReportSummary(
                                                row,
                                                "Image"
                                              );
                                            }}
                                          >
                                            {traslate("Download")}
                                          </a>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                }
                              ]}
                              dataSource={imageUploadList}
                              loading={loading}
                              scroll={{ x: 600, y: 800 }}
                              pagination={{
                                current: pageNo,
                                pageSize: pageSize,
                                pageSizeOptions: [10, 20, 50, 100],
                                responsive: true,
                                showSizeChanger: true,
                                total: totalPage,
                                onChange: handlePageNoChange,
                                onShowSizeChange: handlePageSizeChange
                              }}
                            />
                          </Tab>
                          {dealerMemberTypeName == "VendorDiamond" ||
                          dealerMemberTypeName == "Retailer" ? (
                            <Tab
                              eventKey="Diamond"
                              title={traslate("Diamond Upload")}
                            >
                              <Table
                                columns={[
                                  {
                                    title: traslate("Date"),
                                    dataIndex: "dateOnly",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Time"),
                                    dataIndex: "timeOnly",
                                    width: 100
                                  },
                                  {
                                    title: traslate("File"),
                                    dataIndex: "fileName",
                                    width: 300,
                                    render: (item, row) => {
                                      return (
                                        <React.Fragment>
                                          {row.downloadLink == null ? (
                                            <span>{row.fileName}</span>
                                          ) : (
                                            <p className="linkText">
                                              <span
                                                onClick={() =>
                                                  handleDownload(
                                                    row.downloadLink,
                                                    row.fileName
                                                  )
                                                }
                                              >
                                                {" "}
                                                {row.fileName}
                                              </span>
                                            </p>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  },
                                  {
                                    title: traslate("Type"),
                                    dataIndex: "type",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Status"),
                                    dataIndex: "status",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Records"),
                                    dataIndex: "records",
                                    width: 100
                                  },
                                  {
                                    title: traslate("Report"),
                                    dataIndex: "report",
                                    width: 100,
                                    render: (item, row) => {
                                      return (
                                        <React.Fragment>
                                          {row.status != "Completed" ? (
                                            "InProcess"
                                          ) : (
                                            <a
                                              href="#"
                                              onClick={() => {
                                                handleDownloadReportSummary(
                                                  row,
                                                  "Diomond"
                                                );
                                              }}
                                            >
                                              {traslate("Download")}
                                            </a>
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                ]}
                                dataSource={diamondUploadList}
                                loading={loading}
                                scroll={{ x: 600, y: 800 }}
                                pagination={{
                                  current: pageNo,
                                  pageSize: pageSize,
                                  pageSizeOptions: [10, 20, 50, 100],
                                  responsive: true,
                                  showSizeChanger: true,
                                  total: totalPage,
                                  onChange: handlePageNoChange,
                                  onShowSizeChange: handlePageSizeChange
                                }}
                              />
                            </Tab>
                          ) : null}
                        </Tabs>

                        <div className="col-md-6 mt-3">
                          <button
                            className="primary-btn"
                            onClick={handleRefershUpload}
                          >
                            {traslate("Update History")}
                          </button>
                        </div>
                      </div>
                    </Spin>
                    {/* Show uploaded data modal */}
                    <Modal
                      title={traslate("Upload History Details")}
                      visible={isUploadedDataModal}
                      className="upl_show_details"
                      onCancel={handleUploadedDataModalClose}
                      maskClosable={false}
                      footer={[
                        <div className="d-block">
                          <Button
                            className="primary-btn"
                            onClick={handleUploadedDataModalClose}
                          >
                            {traslate("Close")}
                          </Button>
                        </div>
                      ]}
                    >
                      <div className="form__fields p-0 border-0 mh-auto upload_card">
                        <div>
                          <Table
                            columns={[
                              {
                                title: traslate("Date"),
                                dataIndex: "dateOnly",
                                width: 100
                              },
                              {
                                title: traslate("Time"),
                                dataIndex: "timeOnly",
                                width: 100
                              },
                              {
                                title: traslate("File"),
                                dataIndex: "fileName",
                                width: 180,
                                render: (item, row) => {
                                  return (
                                    <React.Fragment>
                                      {row.downloadLink == null ? (
                                        <span>{row.fileName}</span>
                                      ) : (
                                        <p className="linkText">
                                          <span
                                            onClick={() =>
                                              handleDownload(
                                                row.downloadLink,
                                                row.fileName
                                              )
                                            }
                                          >
                                            {row.fileName}
                                          </span>
                                        </p>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              },
                              {
                                title: traslate("Type"),
                                dataIndex: "type",
                                width: 120
                              },
                              {
                                title: traslate("Status"),
                                dataIndex: "status",
                                width: 100
                              },
                              {
                                title: traslate("Records"),
                                dataIndex: "records",
                                width: 100
                              },
                              {
                                title: traslate("Action"),
                                dataIndex: "action",
                                width: 100,
                                render: (item, row) => {
                                  return (
                                    <React.Fragment>
                                      {row.status != "Completed" ? (
                                        "InProcess"
                                      ) : (
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleDownloadReport(
                                              row,
                                              "Jewelry"
                                            );
                                          }}
                                        >
                                          {traslate("Download")}
                                        </a>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              }
                            ]}
                            dataSource={uploadListDetial}
                            loading={loading}
                            pagination={false}
                            // scroll={{ x: 600, y: 800 }}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UploadHistory;
