import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    ["Tools"]: "Tools",
    ["Administration Tools"]: "Administration Tools",
    ["Application Permission"]: "Application Permission",
    ["Report Permissions"]: "Report Permissions",
    ["Client Data Maps"]: "Client Data Maps",
    ["Client Status"]: "Client Status",
    ["Create New Client"]: "Create New Client",
    ["3rd Party Diamond Imports"]: "3rd Party Diamond Imports",
    ["SOLR Core Client"]: "SOLR Core Client",
    ["Vendor Rating"]: "Vendor Rating",
    ["Limits"]: "Limits",
    ["Legacy"]: "Legacy",
    ["Console Upload"]: "Console Upload",
    ["Open Source Mapping"]: "Open Source Mapping",
    ["Pending Syncs"]: "Pending Syncs",
    ["Transfer ZZ.Gemfind"]: "Transfer ZZ.Gemfind",
    ["Login Preferences"]: "Login Preferences",
    ["Retailer Locator"]: "Retailer Locator",
    ["Block Designers For Demo"]: "Block Designers For Demo",
    ["Settings"]: "Settings",
    ["Setting MarkUp"]: "Setting MarkUp",
    ["Permissions"]: "Permissions",
    ["Email Template"]: "Email Template",
    ["Data Maps"]: "Data Maps",
    ["Metal Market"]: "Metal Market",
    ["Trade Shows"]: "Trade Shows",
    ["Add Color"]: "Add Color",
    ["Api Access Token"]: "Api Access Token",
    ["Campaigns"]: "Campaigns",
    ["Mailing List"]: "Mailing List",
    ["Compose"]: "Compose",
    ["Report"]: "Report",
    ["Global Events"]: "Global Events",
    ["Syncs"]: "Syncs",
    ["Tools Reports"]: "Tools Reports",
    ["Clients Reports"]: "Clients Reports",
    ["Permission Reports"]: "Permission Reports",
    ["Data Related"]: "Data Related",
    ["Jewelry Log Reports"]: "Jewelry Log Reports",
    ["Diamond Report"]: "Diamond Report",
    ["Diamond Clicks"]: "Diamond Clicks",
    ["Extended Diamond Chart"]: "Extended Diamond Chart",
    ["Diamond Clarity Report"]: "Diamond Clarity Report",
    ["Diamond Color Report"]: "Diamond Color Report",
    ["Diamond Cut Report"]: "Diamond Cut Report",
    ["Diamond Shape Report"]: "Diamond Shape Report",
    ["Diamond Certificate Report"]: "Diamond Certificate Report",
    ["Diamond Size Report"]: "Diamond Size Report",
    ["Jewelry Report"]: "Jewelry Report",
    ["Jewelry Chart Report"]: "Jewelry Chart Report",
    ["Jewelry Category Report"]: "Jewelry Category Report",
    ["Jewelry Metal Type Report"]: "Jewelry Metal Type Report",
    ["Jewelry Price Report"]: "Jewelry Price Report",
    ["Jewelry Retailer Clicks Report"]: "Jewelry Retailer Clicks Report",
    ["Jewelry Vendor Clicks Report"]: "Jewelry Vendor Clicks Report",
    ["Catalog"]: "Catalog",
    ["Diamond"]: "Diamond",
    ["Mined Diamond"]: "Mined Diamond",
    ["Color Diamond"]: "Color Diamond",
    ["Lab Diamond"]: "Lab Diamond",
    ["Fancy Diamond"]: "Fancy Diamond",
    ["Default Filter Setting"]: "Default Filter Setting",
    ["Upload History"]: "Upload History",
    ["Add Individual Diamond"]: "Add Individual Diamond",
    ["Add Diamond"]: "Add Diamond",
    ["Upload Diamond File"]: "Upload Diamond File",
    ["Diamond Mapping"]: "Diamond Mapping",
    ["Manage FTP Credentials"]: "Manage FTP Credentials",
    ["Download Product File"]: "Download Product File",
    ["Jewelry"]: "Jewelry",
    ["Catalog List"]: "Catalog List",
    ["View My Product"]: "View My Product",
    ["Add Product"]: "Add Product",
    ["Edit Product"]: "Edit Product",
    ["Upload Product File"]: "Upload Product File",
    ["Manage Collection"]: "Manage Collection",
    ["Product Markup"]: "Product Markup",
    ["Jewelry Markup"]: "Jewelry Markup",
    ["Add Collection"]: "Add Collection",
    ["Data Mapping"]: "Data Mapping",
    ["Essential"]: "Essential",
    ["Item Identity"]: "Item Identity",
    ["Configurable Products"]: "Configurable Products",
    ["Item Details"]: "Item Details",
    ["Item Pricing"]: "Item Pricing",
    ["Relational Pricing"]: "Relational Pricing",
    ["Cost Basis Pricing"]: "Cost Basis Pricing",
    ["Item-Promo Specials"]: "Item-Promo Specials",
    ["Item Media"]: "Item Media",
    ["Item Sizing"]: "Item Sizing",
    ["Gemstone Details"]: "Gemstone Details",
    ["Semi-Mounts & Ring Builder"]: "Semi-Mounts & Ring Builder",
    ["Watch Details"]: "Watch Details",
    ["Additional Details"]: "Additional Details",
    ["Purchase Order"]: "Purchase Order",
    ["Purchase Order History"]: "Purchase Order History",
    ["Update Purchase Order"]: "Update Purchase Order",
    ["Marketing"]: "Marketing",
    ["Upload Marketing Material"]: "Upload Marketing Material",
    ["Appointment"]: "Appointment",
    ["Apps"]: "Apps",
    ["Diamond Links"]: "Diamond Links",
    ["Application Preview"]: "Application Preview",
    ["Dealers"]: "Dealers",
    ["Mined Diamond MarkUp"]: "Mined Diamond MarkUp",
    ["Fancy Color MarkUp"]: "Fancy Color MarkUp",
    ["Lab Grown MarkUp"]: "Lab Grown MarkUp",
    ["Available Filter Settings"]: "Available Filter Settings",
    ["Initial Filter Settings"]: "Initial Filter Settings",
    ["Set Initial Filter Settings"]: "Set Initial Filter Settings",
    ["Options"]: "Options",
    ["Option"]: "Option",
    ["Shipping"]: "Shipping",
    ["Navigation"]: "Navigation",
    ["Shopping Cart Integration"]: "Shopping Cart Integration",
    ["Shopping Cart Configuration"]: "Shopping Cart Configuration",
    ["API Endpoint and Authentication"]: "API Endpoint and Authentication",
    ["API Endpoint Authentication"]: "API Endpoint Authentication",
    ["Ring Builder"]: "Ring Builder",
    ["Vendors"]: "Vendors",
    ["Archived Vendors"]: "Archived Vendors",
    ["Archived Vendor"]: "Archived Vendor",
    ["Manage Vendors"]: "Manage Vendors",
    ["Setting Vendors"]: "Setting Vendors",
    ["Avaliable Filter Settings"]: "Avaliable Filter Settings",
    ["Diamond Markup"]: "Diamond Markup",
    ["Setting Markup"]: "Setting Markup",
    ["Master Link"]: "Master Link",
    ["E-mail Templates"]: "E-mail Templates",
    ["Stud Builder"]: "Stud Builder",
    ["Shopping Cart Integrations"]: "Shopping Cart Integrations",
    ["Setting Markup"]: "Setting Markup",
    ["Website Sync"]: "Website Sync",
    ["Sync History"]: "Sync History",
    ["OutBound Mapping"]: "OutBound Mapping",
    ["FTP Details"]: "FTP Details",
    ["Watch Customizer"]: "Watch Customizer",
    ["Time Pieces"]: "Time Pieces",
    ["Pendant Builder"]: "Pendant Builder",
    ["Search Feature"]: "Search Feature",
    ["Diamond Search"]: "Diamond Search",
    ["Mined Diamond Search"]: "Mined Diamond Search",
    ["Color Diamond Search"]: "Color Diamond Search",
    ["Lab Diamond Search"]: "Lab Diamond Search",
    ["Watches Search"]: "Watches Search",
    ["Jewelry Search"]: "Jewelry Search",
    ["Community"]: "Community",
    ["Vendor Manager"]: "Vendor Manager",
    ["My Vendors"]: "My Vendors",
    ["Network"]: "Network",
    ["File should be less than 2Gb."]: "File should be less than 2Gb.",
    ["Out Of Network"]: "Out Of Network",
    ["New"]: "New Vendor",
    ["Recently Updated"]: "Recently Updated",
    ["Invite Your Vendors"]: "Invite Your Vendors",
    ["Invite Your Retailers"]: "Invite Your Retailers",
    ["Popular"]: "Popular Vendor",
    ["Archived"]: "Archived",
    ["Pending Requests"]: "Pending Requests",
    ["Retailer"]: "Retailer",
    ["Jewelry Data & Permissions"]: "Jewelry Data & Permissions",
    ["Pending Jewelry Request"]: "Pending Jewelry Request",
    ["Diamond Data & Permission"]: "Diamond Data & Permission",
    ["Pending Diamond Request"]: "Pending Diamond Request",
    ["Ring Builder Retailer & Permission"]:
      "Ring Builder Retailer & Permission",
    ["Pending Ring Builder Request"]: "Pending Ring Builder Request",
    ["Retailer Profile"]: "Retailer Profile",
    ["Jewelry Chart Reports"]: "Jewelry Chart Reports",
    ["Help"]: "Help",
    ["Report An Issue"]: "Report An Issue",
    ["Suggest A New Feature"]: "Suggest A New Feature",
    ["Watch Training Videos"]: "Watch Training Videos",

    ["Edit Profile"]: "Edit Profile",
    ["Manage User"]: "Manage User",
    ["Manage Account"]: "Manage Account",
    ["Manage Language"]: "Manage Language",
    ["Manage Currency"]: "Manage Currency",
    ["Logout"]: "Logout",
    ["Top Menu"]: "Top Menu",
    ["Dashboard"]: "Dashboard",
    ["Contact"]: "Contact",
    ["Contacts"]: "Contacts",
    ["Admin"]: "Admin",

    ["Conversations"]: "Conversations",
    ["Terms of Service"]: "Terms of Service",
    ["Privacy Statement"]: "Privacy Statement",
    ["Copyright 2021 GemFind"]: "Copyright 2021 GemFind",

    ["-Server Up-Time: Uptime info not available - please try reloading this page"]:
      "-Server Up-Time: Uptime info not available - please try reloading this page",
    ["JewelCloud Speed Test"]: "JewelCloud Speed Test",
    ["-RapNet Prices Updated:"]: "-RapNet Prices Updated:",
    ["Client Tools"]: "Client Tools",
    ["Select Client Type"]: "Select Client Type",
    ["Client Name"]: "Client Name",
    ["Selected Client Tools"]: "Selected Client Tools",
    ["Name"]: "Name",
    ["Phone"]: "Phone",
    ["Type"]: "Type",
    ["Verified"]: "Verified",
    ["Approved Apps"]: "Approved Apps",
    ["Users"]: "Users",
    ["Username"]: "Username",
    ["Password"]: "Password",
    ["Edit"]: "Edit",
    ["View Profile"]: "View Profile",
    ["Download Inventory"]: "Download Inventory",
    ["Impersonate JewelCloud"]: "Impersonate JewelCloud",
    ["Return to JewelCloud as Dealer 720"]:
      "Return to JewelCloud as Dealer 720",
    ["Updated"]: "Updated",
    ["Data Rating"]: "Data Rating",
    ["Location"]: "Location",
    ["Active"]: "Active",
    ["Product Loves"]: "Product Loves",
    ["Collections"]: "Collections",
    ["Items"]: "Items",
    ["Contact Name"]: "Contact Name",
    ["Vendor tags"]: "Vendor tags",
    ["No tag to display"]: "No tag to display",
    ["Brands"]: "Brands",
    ["Reset Password"]: "Reset Password",
    ["User Name"]: "User Name",
    ["Confirm Password"]: "Confirm Password",

    ["App Activation Control"]: "App Activation Control",
    ["Select Dealer"]: "Select Dealer",
    ["Diamond Link"]: "Diamond Link",
    ["Diamond Search Links"]: "Diamond Search Links",
    ["DS Ecommerce"]: "DS Ecommerce",
    ["DS SSL"]: "DS SSL",
    ["DS Show PoweredBy"]: "DS Show PoweredBy",
    ["DS LabGrownDiamonds"]: "DS LabGrownDiamonds",
    ["Jewel Cloud"]: "Jewel Cloud",
    ["API"]: "API",
    ["Direct feed"]: "Direct feed",
    ["Windows App"]: "Windows App",
    ["ML Legacy"]: "ML Legacy",
    ["ML2"]: "ML2",
    ["ML3"]: "ML3",
    ["ML Ecommerce"]: "ML Ecommerce",
    ["ML SSL"]: "ML SSL",
    ["ML Show PoweredBy"]: "ML Show PoweredBy",
    ["RB Ecommerce"]: "RB Ecommerce",
    ["RB SSL"]: "RB SSL",
    ["RB Show PoweredBy"]: "RB Show PoweredBy",
    ["Tryon"]: "Tryon",
    ["SB Ecommerce"]: "SB Ecommerce",
    ["SB SSL"]: "SB SSL",
    ["SB Show PoweredBy"]: "SB Show PoweredBy",
    ["PB Ecommerce"]: "PB Ecommerce",
    ["PB SSL"]: "PB SSL",
    ["PB Show PoweredBy"]: "PB Show PoweredBy",
    ["Gemfind.TV"]: "Gemfind.TV",
    ["Purchase Order(PO)"]: "Purchase Order(PO)",
    ["Bridal/Fashion Separation"]: "Bridal/Fashion Separation",
    ["Diamond Group Discount"]: "Diamond Group Discount",
    ["POS System"]: "POS System",
    ["Submit"]: "Submit",

    ["Reports Permissions"]: "Reports Permissions",
    ["All"]: "All",
    ["Diamond Dealers"]: "Diamond Dealers",
    ["Retailers"]: "Retailers",
    ["Diamond Reports"]: "Diamond Reports",
    ["Jewelry Reports"]: "Jewelry Reports",
    ["Global Diamond Reports"]: "Global Diamond Reports",
    ["Global Jewelry Reports"]: "Global Jewelry Reports",

    ["Client Data Mapping"]: "Client Data Mapping",
    ["Client Data Maps"]: "Client Data Maps",
    ["Dealer ID"]: "Dealer ID",
    ["Dealer Company"]: "Dealer Company",
    ["Website Address"]: "Website Address",
    ["Upload"]: "Upload",
    ["InBound Data Map"]: "InBound Data Map",
    ["Outgoing Data Map"]: "Outgoing Data Map",
    ["OutBound Sync"]: "OutBound Sync",
    ["Set Default Mapping"]: "Set Default Mapping",

    ["Search"]: "Search",
    ["Reset"]: "Reset",
    ["InActive"]: "InActive",
    ["Permission Type"]: "Permission Type",
    ["Account Type"]: "Account Type",
    ["VendorDiamond"]: "VendorDiamond",
    ["Vendor"]: "Vendor",
    ["Vendor Status"]: "Vendor Status",
    ["Profile Only"]: "Profile Only",
    ["Retailer Status"]: "Retailer Status",
    ["Product Type"]: "Product Type",
    ["Action"]: "Action",
    ["Cancel"]: "Cancel",
    ["Save"]: "Save",
    ["Update Client Status Information"]: "Update Client Status Information",
    ["Update Date on which Client Name is Created"]:
      "Update Date on which Client Name is Created",

    ["Add New Client"]: "Add New Client",
    ["Company Name"]: "Company Name",
    ["Street Address"]: "Street Address",
    ["City"]: "City",
    ["State"]: "State",
    ["Zip Code"]: "Zip Code",
    ["Country"]: "Country",
    ["Email Address"]: "Email Address",
    ["Phone Number"]: "Phone Number",
    ["Generate Password"]: "Generate Password",
    ["Create Client"]: "Create Client",

    ["Enable 3rd Party Diamond Imports"]: "Enable 3rd Party Diamond Imports",
    ["Dealer Name"]: "Dealer Name",
    ["Enabled"]: "Enabled",

    ["Add New Retailer To Core"]: "Add New Retailer To Core",
    ["Select Core"]: "Select Core",
    ["Enter Retailer ID"]: "Enter Retailer ID",
    ["Add"]: "Add",
    ["id"]: "Id",
    ["Core Name"]: "Core Name",
    ["Retailer ID"]: "Retailer ID",
    ["Retailer Name"]: "Retailer Name",

    ["Vendor Ratings"]: "Vendor Ratings",
    ["Vendor ID"]: "Vendor ID",
    ["Vendor Name"]: "Vendor Name",
    ["System Ratings"]: "System Ratings",
    ["Manual Ratings"]: "Manual Ratings",
    ["Modified Date"]: "Modified Date",
    ["Save Vendor Rating"]: "Save Vendor Rating",

    ["Limit Retailer To Diamond Dealer"]: "Limit Retailer To Diamond Dealer",
    ["Limit Diamond Dealer To Retailer"]: "Limit Diamond Dealer To Retailer",
    ["Limit Retailer To Vendor"]: "Limit Retailer To Vendor",
    ["Limit Vendor To Retailer"]: "Limit Vendor To Retailer",
    ["Apply Filter"]: "Apply Filter",
    ["Rules"]: "Rules",
    ["Selected"]: "Selected",
    ["Delete"]: "Delete",
    ["Create"]: "Create",
    ["Download"]: "Download",
    ["Rule Type"]: "Rule Type",
    ["Vendor/Dealer"]: "Vendor/Dealer",
    ["Created Date"]: "Created Date",
    ["Next"]: "Next",
    ["Diamond Dealer"]: "Diamond Dealer",
    ["Are you sure to delete this Rule"]: "Are you sure to delete this Rule",
    ["THIS CAN NOT BE UNDONE"]: "THIS CAN NOT BE UNDONE",
    ["Delete Limit Rule"]: "Delete Limit Rule",
    ["Dealer Markup"]: "Dealer Markup",

    ["(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))"]:
      "(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))",
    ["Add a Dealer to all GFLinks:"]: "Add a Dealer to all GFLinks:",
    ["Select the DealerID you want to"]: "Select the DealerID you want to",
    ["to all GFLinks"]: "to all GFLinks",
    ["Remove a Dealer from all GFLinks:"]: "Remove a Dealer from all GFLinks:",
    ["Remove"]: "Remove",
    ["Add a Dealer to all GFLinks Lab Diamonds:"]:
      "Add a Dealer to all GFLinks Lab Diamonds:",
    ["to all GFLinks Lab Diamonds"]: "to all GFLinks Lab Diamonds",
    ["Remove a Dealer from all GFLinks Lab Diamonds:"]:
      "Remove a Dealer from all GFLinks Lab Diamonds:",
    ["Add DealerID"]: "Add DealerID",
    ["Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks."]:
      "Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks.",
    ["Remove DealerID"]: "Remove DealerID",
    ["Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds."]:
      "Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds.",
    ["Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds."]:
      "Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds.",

    ["Add Console Upload Dealer Data"]: "Add Console Upload Dealer Data",
    ["Create FTP"]: "Create FTP",
    ["Dealer"]: "Dealer",
    ["FTP User Name"]: "FTP User Name",
    ["FTP Password"]: "FTP Password",
    ["File Type"]: "File Type",
    ["Path"]: "Path",
    ["Add Update"]: "Add Update",
    ["Console Upload Info"]: "Console Upload Info",
    ["Console Upload Edit Info"]: "Console Upload Edit Info",
    ["Enter User Name"]: "Enter User Name",
    ["Enter Password"]: "Enter Password",
    ["Enter Folder Name"]: "Enter Folder Name",
    ["Are you sure you want to delete"]: "Are you sure you want to delete",
    ["Are You Sure You Want To Delete This Markup ?"]:
      "Are You Sure You Want To Delete This Markup ?",
    ["Are you sure you want to remove this override?"]:
      "Are you sure you want to remove this override?",
    ["Dealer Data"]: "Dealer Data",
    ["Dealer Mapping"]: "Dealer Mapping",

    ["Magento Mapping"]: "Magento Mapping",
    ["API URL"]: "API URL",
    ["API Key"]: "API Key",
    ["IsActive"]: "IsActive",
    ["Edit Megento Mapping Info"]: "Edit Megento Mapping Info",
    ["Edit Open Source Mapping Info"]: "Edit Open Source Mapping Info",
    ["Port No"]: "Port No",
    ["Delete Dealer Mapping"]: "Delete Dealer Mapping",
    ["Megento Mapping Info"]: "Megento Mapping Info",
    ["Open Source Mapping Info"]: "Open Source Mapping Info",

    ["Discard All"]: "Discard All",
    ["Run Valid Image Application"]: "Run Valid Image Application",
    ["Sync ID"]: "Sync ID",
    ["Status"]: "Status",
    ["Discard"]: "Discard",

    ["Source JC ID"]: "Source JC ID",
    ["Destination JC ID"]: "Destination JC ID",
    ["Replace All"]: "Replace All",
    ["Add & Update"]: "Add & Update",
    ["Transfer"]: "Transfer",
    ["Are you sure you want to move data from"]:
      "Are you sure you want to move data from",
    ["account to"]: "account to",
    ["account ? - You have select"]: "account ? - You have select",
    ["Option"]: "Option",

    ["Select Designer for New"]: "Select Designer for New",
    ["Note"]: "Note",
    ["This will feature a specific vendor for the"]:
      "This will feature a specific vendor for the",
    ["New to JewelCloud"]: "New to JewelCloud",
    ["section on the login page"]: "section on the login page",
    ["Upload Logo Image for Designers"]: "Upload Logo Image for Designers",
    ["Choose Image"]: "Choose Image",
    ["No File Choosen"]: "No File Choosen",
    ["Pixels"]: "Pixels",
    ["Png Format"]: "Png Format",
    ["Select Designer"]: "Select Designer",
    ["Upload Image"]: "Upload Image",
    ["Select Designer To Show on Login Page"]:
      "Select Designer To Show on Login Page",

    ["Admin Preferences"]: "Admin Preferences",
    ["MasterLink Theme"]: "MasterLink Theme",
    ["Default Theme"]: "Default Theme",
    ["Retailer Locater Black Theme"]: "Retailer Locater Black Theme",

    ["Block Designers For Demo Retailer"]: "Block Designers For Demo Retailer",
    ["Designer Name"]: "Designer Name",
    ["Is Blocked ?"]: "Is Blocked ?",
    ["Update Selection"]: "Update Selection",

    ["Add New"]: "Add New",
    ["Page Number"]: "Page Number",
    ["1 Tier PageName"]: "1 Tier PageName",
    ["2 Tier PageName"]: "2 Tier PageName",
    ["3 Tier PageName"]: "3 Tier PageName",
    ["Vendor Super"]: "Vendor Super",
    ["Vendor Admin"]: "Vendor Admin",
    ["Vendor User"]: "Vendor User",
    ["Vendor-Diamond Super"]: "Vendor-Diamond Super",
    ["Vendor-Diamond Admin"]: "Vendor-Diamond Admin",
    ["Vendor-Diamond User"]: "Vendor-Diamond User",
    ["Retailer Super"]: "Retailer Super",
    ["Retailer Admin"]: "Retailer Admin",
    ["Retailer User"]: "Retailer User",
    ["Admin Super"]: "Admin Super",
    ["Admin Admin"]: "Admin Admin",
    ["Admin User"]: "Admin User",
    ["Combined Super"]: "Combined Super",
    ["Combined Admin"]: "Combined Admin",
    ["Combined User"]: "Combined User",
    ["Organization Super"]: "Organization Super",

    ["Email Templates"]: "Email Templates",
    ["All Dealers"]: "All Dealers",
    ["Registration Emails"]: "Registration Emails",
    ["New Account"]: "New Account",
    ["Forgot Password"]: "Forgot Password",
    ["Forgot UserName"]: "Forgot UserName",
    ["Account Locked"]: "Account Locked",
    ["Retailer Emails"]: "Retailer Emails",
    ["Invite New Retailer"]: "Invite New Retailer",
    ["Community Emails"]: "Community Emails",
    ["Retailer Request e-Mail to"]: "Retailer Request e-Mail to",
    ["ACTIVE"]: "ACTIVE",
    ["GF Members"]: "GF Members",
    ["Retailer e-Mail to"]: "Retailer e-Mail to",
    ["COMMUNITY MANAGED"]: "COMMUNITY MANAGED",
    ["Non GF Members"]: "Non GF Members",
    ["PROFILE ONLY"]: "PROFILE ONLY",
    ["LIMITED ACCOUNT"]: "LIMITED ACCOUNT",
    ["Vendor Decline eMail"]: "Vendor Decline eMail",
    ["Vendor Accept eMail"]: "Vendor Accept eMail",
    ["Request to Update Product Data"]: "Request to Update Product Data",
    ["Vendor Appointment"]: "Vendor Appointment",
    ["Invite New Vendor"]: "Invite New Vendor",
    ["Contact Emails (MasterLink)"]: "Contact Emails (MasterLink)",
    ["Customer Registration"]: "Customer Registration",
    ["New Appointments"]: "New Appointments",
    ["Inquires (Request More Info)"]: "Inquires (Request More Info)",
    ["Send Mail to Retailer"]: "Send Mail to Retailer",
    ["Send Mail to Sender"]: "Send Mail to Sender",
    ["Send Email (Email To Friend)"]: "Send Email (Email To Friend)",
    ["Send Mail to Friend"]: "Send Mail to Friend",
    ["Drop A Hint"]: "Drop A Hint",
    ["Send Mail to Recipient"]: "Send Mail to Recipient",
    ["New Favorite List"]: "New Favorite List",
    ["New Favorite Items Added"]: "New Favorite Items Added",
    ["Customer Reset Password"]: "Customer Reset Password",
    ["Customer Reset Password (New)"]: "Customer Reset Password (New)",
    ["Diamond Search Emails"]: "Diamond Search Emails",
    ["Request More Info"]: "Request More Info",
    ["Email To Friend"]: "Email To Friend",
    ["Schedule View"]: "Schedule View",
    ["Diamond Detail"]: "Diamond Detail",
    ["Diamond Compare"]: "Diamond Compare",
    ["Diamond Customer Compare"]: "Diamond Customer Compare",
    ["Fancy Color Diamond Search Emails"]: "Fancy Color Diamond Search Emails",
    ["Color Diamond Detail"]: "Color Diamond Detail",
    ["Ring Builder Emails"]: "Ring Builder Emails",
    ["Drop A Hint (Infinity)"]: "Drop A Hint (Infinity)",
    ["Buy Now (Infinity)"]: "Buy Now (Infinity)",
    ["Setting Detail"]: "Setting Detail",
    ["Complete Ring Specifications"]: "Complete Ring Specifications",
    ["Pendant Builder Emails"]: "Pendant Builder Emails",
    ["Diamond Request More Info"]: "Diamond Request More Info",
    ["Complete Pendant Setting"]: "Complete Pendant Setting",
    ["Stud Builder Emails"]: "Stud Builder Emails",
    ["Request More Retailer"]: "Request More Retailer",
    ["SB To ScheduleView"]: "SB To ScheduleView",
    ["SB Complete Earring To ScheduleView"]:
      "SB Complete Earring To ScheduleView",
    ["Diamond Email To Friend"]: "Diamond Email To Friend",
    ["Store"]: "Store",
    ["Big Emails"]: "Big Emails",
    ["Email To Customer"]: "Email To Customer",
    ["Send Mail to Customer"]: "Send Mail to Customer",
    ["Send Email"]: "Send Email",

    ["Default Data Map"]: "Default Data Map",
    ["Product ID & Description"]: "Product ID & Description",
    ["Gemfind Column"]: "Gemfind Column",
    ["Active Status"]: "Active Status",
    ["Category"]: "Category",
    ["Categories"]: "Categories",
    ["Attributes"]: "Attributes",
    ["Gemstones"]: "Gemstones",
    ["Watches"]: "Watches",
    ["Save Details"]: "Save Details",
    ["Create"]: "Create",

    ["Manage Current Metal Market"]: "Manage Current Metal Market",
    ["Current Metal Price"]: "Current Metal Price",
    ["Silver"]: "Silver",
    ["Gold"]: "Gold",
    ["Platinum"]: "Platinum",
    ["Palladium"]: "Palladium",
    ["Enter New Metal Type"]: "Enter New Metal Type",

    ["Trade Show"]: "Trade Show",
    ["Trade Shows"]: "Trade Shows",
    ["Show"]: "Show",
    ["Dates"]: "Dates",
    ["Add Trade Show"]: "Add Trade Show",
    ["Edit Trade Show"]: "Edit Trade Show",
    ["JPG Format"]: "JPG Format",
    ["Show Name"]: "Show Name",
    ["Website"]: "Website",
    ["Show Start Time"]: "Show Start Time",
    ["Show End Time"]: "Show End Time",
    ["AM"]: "AM",
    ["Closed"]: "Closed",
    ["PM"]: "PM",
    ["Show Start Dates"]: "Show Start Dates",
    ["Show End Dates"]: "Show End Dates",
    ["Delete Trade Show"]: "Delete Trade Show",
    ["Are You Sure Want to delete"]: "Are You Sure Want to delete",

    ["Sr. No."]: "Sr. No.",
    ["Color Name"]: "Color Name",
    ["Edit Color"]: "Edit Color",

    ["API Type"]: "API Type",
    ["Update"]: "Update",

    ["Mailing Lists"]: "Mailing Lists",
    ["Alias Address"]: "Alias Address",
    ["Number Of Recipients"]: "Number Of Recipients",
    ["Created"]: "Created",
    ["Recipients"]: "Recipients",
    ["Sync"]: "Sync",
    ["Recipient Address"]: "Recipient Address",
    ["Client"]: "Client",

    ["Select Campaign"]: "Select Campaign",
    ["Campaign Name"]: "Campaign Name",
    ["Submitted"]: "Submitted",
    ["Delivered"]: "Delivered",
    ["Total Opens"]: "Total Opens",
    ["Total Clicks"]: "Total Clicks",

    ["Select Compose"]: "Select Compose",
    ["From Name"]: "From Name",
    ["From Email"]: "From Email",
    ["Type Your Subject"]: "Type Your Subject",
    ["Send"]: "Send",

    ["From Date"]: "From Date",
    ["To Date"]: "To Date",
    ["Type"]: "Type",
    ["Initiated"]: "Initiated",
    ["Complete"]: "Complete",

    ["Sync Type"]: "Sync Type",
    ["Show Full Syncs"]: "Show Full Syncs",
    ["Show Update Syncs"]: "Show Update Syncs",
    ["Show Delete Syncs"]: "Show Delete Syncs",
    ["Show Price Syncs"]: "Show Price Syncs",
    ["Sync Status"]: "Sync Status",
    ["Show Success Syncs"]: "Show Success Syncs",
    ["Show Failed Syncs"]: "Show Failed Syncs",
    ["Show Pending Syncs"]: "Show Pending Syncs",

    ["Mastelink"]: "Mastelink",
    ["Watch Builder"]: "Watch Builder",
    ["Version"]: "Version",

    ["Diamonds"]: "Diamonds",
    ["Open Requests"]: "Open Requests",
    ["Last Updated"]: "Last Updated",
    ["Days Old"]: "Days Old",
    ["Details"]: "Details",

    ["Metal Types"]: "Metal Types",
    ["Metal Colors"]: "Metal Colors",
    ["Diamond Pairs"]: "Diamond Pairs",
    ["Default Mapping Values"]: "Default Mapping Values",
    ["Heirachy"]: "Heirachy",

    ["Count"]: "Count",
    ["Last Upload Date"]: "Last Upload Date",

    ["Select Diamond"]: "Select Diamond",
    ["Top Diamond Attribute Values"]: "Top Diamond Attribute Values",
    ["Recent Diamond Attribute Searches"]: "Recent Diamond Attribute Searches",
    ["Depth"]: "Depth",
    ["Table"]: "Table",
    ["Measurements"]: "Measurements",
    ["Date"]: "Date",
    ["Shape"]: "Shape",
    ["Size"]: "Size",
    ["Color"]: "Color",
    ["Cut"]: "Cut",
    ["Clarity"]: "Clarity",
    ["Price"]: "Price",
    ["UsersIPAddress"]: "UsersIPAddress",
    ["Day & Time"]: "Day & Time",
    ["Certificates"]: "Certificates",
    ["Retail Price"]: "Retail Price",
    ["Cost"]: "Cost",

    ["Extended Diamond Chart Reports"]: "Extended Diamond Chart Reports",
    ["Vendor(s)"]: "Vendor(s)",
    ["Retailer(s)"]: "Retailer(s)",
    ["Diamond Cut Searches"]: "Diamond Cut Searches",
    ["Diamond Shape Searches"]: "Diamond Shape Searches",
    ["Diamond Clarity Searches"]: "Diamond Clarity Searches",
    ["Diamond Certificate Searches"]: "Diamond Certificate Searches",
    ["Displays the total number of jewelry clicks through the currently specified date range above"]:
      "Displays the total number of jewelry clicks through the currently specified date range above",

    ["Download CSV"]: "Download CSV",
    ["Total Jewelry Clicks"]: "Total Jewelry Clicks",
    ["Jewelry Clicks Based on Category"]: "Jewelry Clicks Based on Category",
    ["Jewelry Clicks Based on Price"]: "Jewelry Clicks Based on Price",
    ["Jewelry Clicks Based on Vendor"]: "Jewelry Clicks Based on Vendor",

    ["Product Image"]: "Product Image",
    ["User IP Address"]: "User IP Address",
    ["Collection"]: "Collection",
    ["Style Number"]: "Style Number",
    ["Metal Type"]: "Metal Type",
    ["Metal Color"]: "Metal Color",

    ["Show Filter"]: "Show Filter",
    ["Hide Filter"]: "Hide Filter",
    ["Shapes"]: "Shapes",
    ["Round"]: "Round",
    ["Oval"]: "Oval",
    ["Circel"]: "Circel",
    ["Heart"]: "Heart",
    ["Emarald"]: "Emarald",
    ["Radiant"]: "Radiant",
    ["Caret"]: "Caret",
    ["Carats"]: "Carats",
    ["Carat"]: "Carat",
    ["Polish"]: "Polish",
    ["Length(mm)"]: "Length(mm)",
    ["Width(mm)"]: "Width(mm)",
    ["height(mm)"]: "height(mm)",
    ["Symmetry"]: "Symmetry",
    ["Girdle"]: "Girdle",
    ["Crown Angle"]: "Crown Angle",
    ["Pavilion Angle"]: "Pavilion Angle",
    ["Culet"]: "Culet",
    ["Culet Condition"]: "Culet Condition",
    ["Fluorescence"]: "Fluorescence",
    ["Fancy Color"]: "Fancy Color",
    ["Intensity"]: "Intensity",
    ["Overtone"]: "Overtone",
    ["Matched Pairs"]: "Matched Pairs",
    ["Has Video"]: "Has Video",
    ["Reset Filters"]: "Reset Filters",
    ["Apply Filters"]: "Apply Filters",
    ["You Have"]: "You Have",
    ["View"]: "View",
    ["Print"]: "Print",
    ["View Details"]: "View Details",
    ["Contact Dealer"]: "Contact Dealer",
    ["Message to B&H Diamonds"]: "Message to B&H Diamonds",
    ["Contact the dealer regarding"]: "Contact the dealer regarding",
    ["GemFind"]: "GemFind",
    ["Carat Weight"]: "Carat Weight",
    ["Treatment"]: "Treatment",
    ["Crown"]: "Crown",
    ["Lab"]: "Lab",
    ["Matched Pair Stock"]: "Matched Pair Stock",
    ["Seller"]: "Seller",
    ["Stock"]: "Stock",
    ["Stock #"]: "Stock #",
    ["Off %"]: "Off %",
    ["Flour"]: "Flour",
    ["Dep."]: "Dep.",
    ["Measure"]: "Measure",
    ["Cert."]: "Cert.",
    ["Sym"]: "Sym",
    ["Image/Video"]: "Image/Video",
    ["Video"]: "Video",
    ["Close"]: "Close",

    ["Colors"]: "Colors",
    ["Blue"]: "Blue",
    ["Orange"]: "Orange",
    ["Green"]: "Green",
    ["Yellow"]: "Yellow",
    ["Red"]: "Red",
    ["Maroon"]: "Maroon",
    ["Permission"]: "Permission",
    ["Fancy Intensity"]: "Fancy Intensity",
    ["Overtone 1"]: "Overtone 1",
    ["Overtone 2"]: "Overtone 2",
    ["Overall Price"]: "Overall Price",
    ["Price per Caret Range"]: "Price per Caret Range",
    ["Eye Clean"]: "Eye Clean",
    ["Fluorescence Intensity"]: "Fluorescence Intensity",
    ["Fluorescence Color"]: "Fluorescence Color",
    ["L/W Ratio Measurements"]: "L/W Ratio Measurements",
    ["LW Ratio"]: "LW Ratio",
    ["Any"]: "Any",
    ["Square"]: "Square",
    ["Rectangle"]: "Rectangle",
    ["Shade"]: "Shade",
    ["Central Inclusion Range"]: "Central Inclusion Range",
    ["Black Inclusion Range"]: "Black Inclusion Range",
    ["Milky Inclusion Range"]: "Milky Inclusion Range",
    ["Girdle Range"]: "Girdle Range",
    ["Crown Angle % Range"]: "Crown Angle % Range",
    ["Pavilion Angle % Range"]: "Pavilion Angle % Range",
    ["Key to Symbols"]: "Key to Symbols",
    ["Culet Range"]: "Culet Range",
    ["Culet Condition Range"]: "Culet Condition Range",
    ["price(Cost)"]: "price(Cost)",

    ["There are"]: "There are",
    ["results for your search"]: "results for your search",
    ["Material Type"]: "Material Type",
    ["Gender"]: "Gender",
    ["Material Color"]: "Material Color",
    ["Gemstone"]: "Gemstone",
    ["Retaile Stock #"]: "Retaile Stock #",
    ["Retail"]: "Retail",
    ["Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."]:
      "Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies.",
    ["Please select a vendor before choosing a collection."]:
      "Please select a vendor before choosing a collection.",
    ["Retail Stock #"]: "Retail Stock #",
    ["$ cost"]: "$ cost",
    ["$ Retail"]: "$ Retail",

    ["Store Name"]: "Store Name",
    ["Retailer Type"]: "Retailer Type",
    ["Requested Date"]: "Requested Date",
    ["Request Date"]: "Request Date",
    ["My Retailer"]: "My Retailer",
    ["My Retailers"]: "My Retailers",
    ["Jewelry API"]: "Jewelry API",
    ["All Retailer"]: "All Retailer",
    ["All Retailers"]: "All Retailers",

    ["Profile"]: "Profile",
    ["Conversation"]: "Conversation",
    ["Notes"]: "Notes",
    ["Appointments"]: "Appointments",
    ["Time"]: "Time",
    ["Buyer Name"]: "Buyer Name",

    ["Action Request"]: "Action Request",

    ["Activity"]: "Activity",
    ["Customer"]: "Customer",

    ["Load More"]: "Load More",

    ["View My Mined Diamond"]: "View My Mined Diamond",
    ["Are you sure to delete this diamond"]:
      "Are you sure to delete this diamond",

    ["View My Lab Diamond"]: "View My Lab Diamond",

    ["View My Color Diamond"]: "View My Color Diamond",

    ["Diamond Upload History"]: "Diamond Upload History",
    ["Data Upload"]: "Data Upload",
    ["Image Upload"]: "Image Upload",
    ["File"]: "File",
    ["Records"]: "Records",

    ["Inventory #"]: "Inventory #",
    ["Matched Pair Stock #"]: "Matched Pair Stock #",
    ["Stones (Parcel)"]: "Stones (Parcel)",
    ["Separable"]: "Separable",
    ["Fancy Codes"]: "Fancy Codes",
    ["Fancy Names"]: "Fancy Names",
    ["Other"]: "Other",
    ["Cut Grade"]: "Cut Grade",
    ["Certificate #"]: "Certificate #",
    ["Pavillion Angle"]: "Pavillion Angle",
    ["Comments"]: "Comments",
    ["Store Location"]: "Store Location",
    ["Employee Name"]: "Employee Name",
    ["Employee ID"]: "Employee ID",
    ["Out On Memo"]: "Out On Memo",
    ["Seen by Other Dealers"]: "Seen by Other Dealers",
    ["Add Video"]: "Add Video",

    ["Upload Your Data File"]: "Upload Your Data File",
    ["Add Individual"]: "Add Individual",
    ["Upload Certs"]: "Upload Certs",
    ["Download Data"]: "Download Data",
    ["Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours."]:
      "Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours.",
    ["Choose File"]: "Choose File",
    ["Xls/Txt/Csv Format"]: "Xls/Txt/Csv Format",
    ["Xls/Txt/Csv/Json Format"]: "Xls/Txt/Csv/Json Format",
    ["Upload Data"]: "Upload Data",
    ["Upload Diamond Images & Videos"]: "Upload Diamond Images & Videos",
    ["Jpg/Png Format"]: "Jpg/Png Format",
    ["Jpg/Png/Zip Format"]: "Jpg/Png/Zip Format",
    ["Compress images together & upload in a .zip format."]:
      "Compress images together & upload in a .zip format.",
    ["Download our image guidelines documentation"]:
      "Download our image guidelines documentation",
    ["Import Data From 3rd Party Provider"]:
      "Import Data From 3rd Party Provider",
    ["Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit"]:
      "Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit",
    ["Add new diamonds in bulk or make mass item updates"]:
      "Add new diamonds in bulk or make mass item updates",
    ["First time importing?"]: "First time importing?",
    ["Import Your Content"]: "Import Your Content",
    ["Choose & upload your data or connect to a 3rd party"]:
      "Choose & upload your data or connect to a 3rd party",
    ["Wait For Confirmation"]: "Wait For Confirmation",
    ["You will receive an e-mail confirmation when your content has been uploaded."]:
      "You will receive an e-mail confirmation when your content has been uploaded.",
    ["Inventory with Dealer Stock # not included in the upload will be deleted."]:
      "Inventory with Dealer Stock # not included in the upload will be deleted.",
    ["Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."]:
      "Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched.",

    ["Select Data file to be"]: "Select Data file to be",
    ["Mapped"]: "Mapped",
    ["Upload File"]: "Upload File",
    ["Reset the data map"]: "Reset the data map",
    ["View / Edit the map"]: "View / Edit the map",
    ["Manage FTP settings"]: "Manage FTP settings",
    ["Generate File"]: "Generate File",
    ["GF Column ID"]: "GF Column ID",
    ["Your Column"]: "Your Column",

    ["Manage FTP Credential"]: "Manage FTP Credential",
    ["FTP URL"]: "FTP URL",

    ["Request Access"]: "Request Access",
    ["Your Diamonds"]: "Your Diamonds",
    ["Connected Diamonds"]: "Connected Diamonds",
    ["Total Diamonds"]: "Total Diamonds",
    ["Lab Diamonds"]: "Lab Diamonds",
    ["Color Diamond Count"]: "Color Diamond Count",
    ["Your Products"]: "Your Products",
    ["Connected Products"]: "Connected Products",
    ["Active Products"]: "Active Products",
    ["EDGE"]: "EDGE",
    ["Import Type"]: "Import Type",

    ["Update History"]: "Update History",

    ["Create Product"]: "Create Product",
    ["More Actions"]: "More Actions",
    ["Total"]: "Total",
    ["Product"]: "Product",
    ["Select All"]: "Select All",
    ["Images"]: "Images",
    ["Style No"]: "Style No",
    ["GFinventory No"]: "GFinventory No",
    ["Product Name"]: "Product Name",
    ["Wholesale Price"]: "Wholesale Price",
    ["Gemfind#"]: "Gemfind#",
    ["Feature"]: "Feature",
    ["Are you sure you want to delete this Product(s)?"]:
      "Are you sure you want to delete this Product(s)?",

    ["Add Image"]: "Add Image",
    ["OR"]: "OR",
    ["Load Image From Server"]: "Load Image From Server",
    ["Load Image"]: "Load Image",
    ["Retail Description"]: "Retail Description",
    ["Style #"]: "Style #",
    ["This must be a unique item number identifying the particular item or the"]:
      "This must be a unique item number identifying the particular item or the",
    ["parent"]: "parent",
    ["item which has many variations - if this is a style root then you will want to set this iem as a"]:
      "item which has many variations - if this is a style root then you will want to set this iem as a",
    ["configurable product"]: "configurable product",
    ["to configure the variables such as carat weight, metal type, diamond quality, length etc."]:
      "to configure the variables such as carat weight, metal type, diamond quality, length etc.",
    ["Dealer Stock #"]: "Dealer Stock #",
    ["This is a unique identification number of this particular item."]:
      "This is a unique identification number of this particular item.",
    ["Period"]: "Period",
    ["Visibility"]: "Visibility",

    ["Additional"]: "Additional",
    ["Qty On Hand"]: "Qty On Hand",
    ["Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory."]:
      "Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory.",
    ["Display Order"]: "Display Order",
    ["This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive."]:
      "This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive.",
    ["Setting"]: "Setting",
    ["Finishing Technique"]: "Finishing Technique",
    ["Clasp Type"]: "Clasp Type",
    ["Chain Type"]: "Chain Type",
    ["Back Finding"]: "Back Finding",
    ["Additional Information"]: "Additional Information",
    ["Additional Information 2"]: "Additional Information 2",

    ["Sizing"]: "Sizing",
    ["General"]: "General",
    ["Weight"]: "Weight",
    ["grams"]: "grams",
    ["dwt"]: "dwt",
    ["Thickness"]: "Thickness",
    ["mm"]: "mm",
    ["inches"]: "inches",
    ["Width"]: "Width",
    ["Dimensions"]: "Dimensions",
    ["Unit Of Measure"]: "Unit Of Measure",
    ["Finger Size"]: "Finger Size",
    ["Stock Finger Size"]: "Stock Finger Size",
    ["Min Finger Size"]: "Min Finger Size",
    ["Max Finger Size"]: "Max Finger Size",
    ["Finger Size Increment"]: "Finger Size Increment",
    ["Price Type"]: "Price Type",
    ["Additional Price"]: "Additional Price",

    ["Pricing"]: "Pricing",
    ["Wholesale Price Method"]: "Wholesale Price Method",
    ["'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'"]:
      "'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'",
    ["Wholesale Base Price"]: "Wholesale Base Price",
    ["Base Metal Market"]: "Base Metal Market",
    ["Price Factor"]: "Price Factor",
    ["Calculate"]: "Calculate",
    ["Relational Price"]: "Relational Price",
    ["Relational Metal Market"]: "Relational Metal Market",
    ["Metal Labor Code"]: "Metal Labor Code",
    ["Other Labor Code"]: "Other Labor Code",
    ["Metal Factor Code"]: "Metal Factor Code",
    ["GPM Code"]: "GPM Code",
    ["Retail Price Method"]: "Retail Price Method",
    ["MSRP"]: "MSRP",
    ["To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At."]:
      "To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At.",

    ["Related"]: "Related",
    ["Matching Styles"]: "Matching Styles",
    ["Matching Sku's are items from the same ensamble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas."]:
      "Matching Sku's are items from the same ensamble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas.",
    ["Up Sell Styles"]: "Up Sell Styles",
    ["Up sell Sku's are items that are related by at slightly higher price points."]:
      "Up sell Sku's are items that are related by at slightly higher price points.",
    ["Grouped Product Styles"]: "Grouped Product Styles",
    ["There is no content."]: "There is no content.",

    ["Media"]: "Media",
    ["Choose Video"]: "Choose Video",
    ["Load Video From URL"]: "Load Video From URL",
    ["Load Video"]: "Load Video",
    ["Add Video Type"]: "Add Video Type",
    ["Label"]: "Label",

    ["Bullet Point 1"]: "Bullet Point 1",
    ["Bullet Point 2"]: "Bullet Point 2",
    ["Bullet Point 3"]: "Bullet Point 3",
    ["Bullet Point 4"]: "Bullet Point 4",
    ["Bullet Point 5"]: "Bullet Point 5",
    ["Discount A"]: "Discount A",
    ["Qty 1"]: "Qty 1",
    ["Qty 2"]: "Qty 2",
    ["Qty 3"]: "Qty 3",
    ["Qty 4"]: "Qty 4",
    ["Qty 5"]: "Qty 5",
    ["Retailer Brand Name"]: "Retailer Brand Name",
    ["Secondary Metal Type"]: "Secondary Metal Type",
    ["Amazon Product"]: "Amazon Product",

    ["Total Diamond Weight"]: "Total Diamond Weight",
    ["Total Gemstone Weight"]: "Total Gemstone Weight",
    ["Gemstone Type"]: "Gemstone Type",
    ["Dimension Unit Of Measure"]: "Dimension Unit Of Measure",
    ["Number Of Gemstones"]: "Number Of Gemstones",
    ["Gemstone Shape"]: "Gemstone Shape",
    ["Gemstone Origin"]: "Gemstone Origin",
    ["Gemstone Carat Weight"]: "Gemstone Carat Weight",
    ["Gemstone Dimensions"]: "Gemstone Dimensions",
    ["Gemstone Quality"]: "Gemstone Quality",

    ["Watch"]: "Watch",
    ["Band Material"]: "Band Material",
    ["Number Type"]: "Number Type",
    ["Band Type"]: "Band Type",
    ["Case Material"]: "Case Material",
    ["Case Shape"]: "Case Shape",
    ["Comes Packaged In"]: "Comes Packaged In",
    ["Crystal Type"]: "Crystal Type",
    ["Dial Color"]: "Dial Color",
    ["Manufacture Date"]: "Manufacture Date",
    ["Display Type"]: "Display Type",
    ["Certification"]: "Certification",
    ["Movement"]: "Movement",
    ["Energy"]: "Energy",

    ["Save Product"]: "Save Product",
    ["Cancel Product"]: "Cancel Product",

    ["Upload Your Images"]: "Upload Your Images",
    ["Please wait while file is Uploading.."]:
      "Please wait while file is Uploading..",
    ["Backgrounds should be white or transparent."]:
      "Backgrounds should be white or transparent.",
    ["Images should be larger than 500 x 500 pixels."]:
      "Images should be larger than 500 x 500 pixels.",
    ["Products should occupy more than 80% of canvas."]:
      "Products should occupy more than 80% of canvas.",
    ["Questions? View the"]: "Questions? View the",
    ["Image Guidelines"]: "Image Guidelines",
    ["Help Doc"]: "Help Doc",
    ["Your File is InProgress"]: "Your File is InProgress",
    ["Please click here to see history"]: "Please click here to see history",
    ["Load Images From My Server"]: "Load Images From My Server",
    ["If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple"]:
      "If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple",
    ["Jewelry Import"]: "Jewelry Import",
    ["Add new jewelry items in bulk or make mass item updates."]:
      "Add new jewelry items in bulk or make mass item updates.",
    ["Map Your Content"]: "Map Your Content",
    ["Match your categories with the JewelCloud industry standard making your content compatible with our network and applications."]:
      "Match your categories with the JewelCloud industry standard making your content compatible with our network and applications.",
    ["Required if your data file is not in our format."]:
      "Required if your data file is not in our format.",
    ["Download Templates"]: "Download Templates",
    ["Required Fields Form"]: "Required Fields Form",
    ["Simple Product Forms"]: "Simple Product Forms",
    ["Ring Builder Data Form"]: "Ring Builder Data Form",
    ["Configurable Product Form"]: "Configurable Product Form",
    ["Help Docs"]: "Help Docs",
    ["Image Guideline"]: "Image Guideline",
    ["Progress"]: "Progress",
    ["Ok"]: "Ok",
    ["Uploaded"]: "Uploaded",
    ["Uploading file"]: "Uploading file",

    ["Create Collection"]: "Create Collection",
    ["All Collections"]: "All Collections",
    ["House Brand Collections"]: "House Brand Collections",
    ["Vendor Collections"]: "Vendor Collections",
    ["Vendor Extensions"]: "Vendor Extensions",
    ["Collection Name"]: "Collection Name",
    ["Brand Name"]: "Brand Name",
    ["Override"]: "Override",
    ["Enable"]: "Enable",
    ["Markup Override"]: "Markup Override",
    ["Disable"]: "Disable",
    ["Percentage"]: "Percentage",
    ["Delete Collection"]: "Delete Collection",
    ["Are you sure , you want to delete this Collection."]:
      "Are you sure , you want to delete this Collection.",

    ["content not provided."]: "content not provided.",
    ["General Settings"]: "General Settings",
    ["Currency"]: "Currency",
    ["Rounding"]: "Rounding",
    ["Default Markup"]: "Default Markup",
    ["Markup"]: "Markup",
    ["Mrk"]: "Mrk",
    ["Markup By Price Range"]: "Markup By Price Range",
    ["Markup By Size Range"]: "Markup By Size Range",
    ["Min Price"]: "Min Price",
    ["Max Price"]: "Max Price",
    ["Mark Up"]: "Mark Up",
    ["Min Size"]: "Min Size",
    ["Max Size"]: "Max Size",

    ["Overrides"]: "Overrides",
    ["Save Pricing"]: "Save Pricing",

    ["Edit Collection"]: "Edit Collection",
    ["Create A Collection"]: "Create A Collection",
    ["Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand"]:
      "Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand",
    ["View The Training Video"]: "View The Training Video",
    ["Collection Description"]: "Collection Description",
    ["Collection Display Order"]: "Collection Display Order",
    ["Navigation Category"]: "Navigation Category",
    ["Bridal"]: "Bridal",
    ["Fashion"]: "Fashion",
    ["Giftware"]: "Giftware",
    ["Save Collection"]: "Save Collection",

    ["Import Mapping"]: "Import Mapping",
    ["Export Mapping"]: "Export Mapping",
    ["Add New Column"]: "Add New Column",
    ["Attribute"]: "Attribute",
    ["JewelCloud defined column name."]: "JewelCloud defined column name.",
    ["This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."]:
      "This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal.",
    ["Requirement"]: "Requirement",
    ["Data uploads will fail if these columns do not contain a value."]:
      "Data uploads will fail if these columns do not contain a value.",
    ["Your Attribute"]: "Your Attribute",
    ["Non Mapped"]: "Non Mapped",
    ["Accepted Values"]: "Accepted Values",
    ["Required"]: "Required",
    ["Example"]: "Example",
    ["Field Type"]: "Field Type",
    ["Configurable"]: "Configurable",
    ["Definition"]: "Definition",
    ["Remaining"]: "Remaining",
    ["Drag & Drop Your Attributes"]: "Drag & Drop Your Attributes",
    ["Match Your attributes with the jewel cloud industry standard"]:
      "Match Your attributes with the jewel cloud industry standard",
    ["Attribute Value Map"]: "Attribute Value Map",
    ["Match Your Variables to the jewelCloud Industry Standard"]:
      "Match Your Variables to the jewelCloud Industry Standard",
    ["Your sheet just needs your column headers."]:
      "Your sheet just needs your column headers.",
    [".xls | .txt | .csv"]: ".xls | .txt | .csv",
    [".csv"]: ".csv",
    ["Choose Format"]: "Choose Format",
    ["No content"]: "No content",
    ["Comma"]: "Comma",
    ["Tab"]: "Tab",
    ["Upload Sheet"]: "Upload Sheet",
    ["Import Mapping Template"]: "Import Mapping Template",
    ["Edge TPW"]: "Edge TPW",
    ["Arms"]: "Arms",
    ["Edge EDT"]: "Edge EDT",
    ["Load File"]: "Load File",
    ["Attribute A New Mappable Column"]: "Attribute A New Mappable Column",
    ["Add A New Mappable Column"]: "Add A New Mappable Column",
    ["Add A Single New Column Header."]: "Add A Single New Column Header.",
    ["Column ID"]: "Column ID",
    ["Column Name"]: "Column Name",
    ["Image Upload Settings"]: "Image Upload Settings",
    ["Import Multiple"]: "Import Multiple",
    ["True"]: "True",
    ["False"]: "False",
    ["Select Delimiter"]: "Select Delimiter",
    ["( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images."]:
      "( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images.",
    ["Add Price Type Override"]: "Add Price Type Override",
    ["Enforce MSRP"]: "Enforce MSRP",
    ["SuggestedRetail Price & Allow Retail Markups"]:
      "SuggestedRetail Price & Allow Retail Markups",
    ["Allow Retail Markups"]: "Allow Retail Markups",
    ["Call For Price"]: "Call For Price",
    ["Prices Starting at"]: "Prices Starting at",
    ["Allow price type override"]: "Allow price type override",
    ["Reset The Map"]: "Reset The Map",
    ["Yikes...Go Back"]: "Yikes...Go Back",
    ["Remove This Mapping"]: "Remove This Mapping",
    ["Remove This Appointment"]: "Remove This Appointment",
    ["Are your sure you want to reset the Data Map?"]:
      "Are your sure you want to reset the Data Map?",
    ["Are your sure you want to remove this mapping?"]:
      "Are your sure you want to remove this mapping?",
    ["Are your sure you want to remove this appointment?"]:
      "Are your sure you want to remove this appointment?",
    ["THERE IS NO UNDO"]: "THERE IS NO UNDO",

    ["Diamond Chart Reports"]: "Diamond Chart Reports",
    ["Export to PDF"]: "Export to PDF",
    ["Diamond Clicks for GemFind (720)"]: "Diamond Clicks for GemFind (720)",
    ["Events"]: "Events",
    ["Download PDF"]: "Download PDF",
    ["IPAddress"]: "IPAddress",
    ["Lab Grow Diamond"]: "Lab Grow Diamond",
    ["Round to the nearest 1"]: "Round to the nearest 1",

    ["Diamond Cut Grade Report"]: "Diamond Cut Grade Report",

    ["Location Activity"]: "Location Activity",
    ["Types"]: "Types",

    ["Accept New Collection"]: "Accept New Collection",
    ["If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"]:
      "If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections",

    ["Retailer Affirmation"]: "Retailer Affirmation",
    ["Current Authorized Reseller"]: "Current Authorized Reseller",
    ["By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website."]:
      "By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website.",
    ["New Account Request"]: "New Account Request",
    ["I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp."]:
      "I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp.",

    ["Invite Your Vendors"]: "Invite Your Vendors",
    ["E-Mail Address"]: "E-Mail Address",
    ["Send Invitation"]: "Send Invitation",
    ["Last Requested"]: "Last Requested",

    ["SKU"]: "SKU",
    ["InHouse"]: "InHouse",
    ["Measurement"]: "Measurement",
    ["Cert"]: "Cert",
    ["Mined"]: "Mined",
    ["Princess"]: "Princess",
    ["Pear"]: "Pear",
    ["Marquise"]: "Marquise",
    ["Ideal"]: "Ideal",
    ["Excellent"]: "Excellent",
    ["Very Good"]: "Very Good",
    ["Good"]: "Good",
    ["Fair"]: "Fair",
    ["Diamond Preview"]: "Diamond Preview",
    ["Sample Image"]: "Sample Image",
    ["Stock Number"]: "Stock Number",
    ["Add To Compare"]: "Add To Compare",
    ["SIMILAR DIAMONDS"]: "SIMILAR DIAMONDS",
    ["Compare"]: "Compare",
    ["Fancy Colored"]: "Fancy Colored",
    ["D"]: "D",
    ["E"]: "E",
    ["F"]: "F",
    ["G"]: "G",
    ["I"]: "I",
    ["J"]: "J",
    ["K"]: "K",
    ["L"]: "L",
    ["M"]: "M",
    ["N"]: "N",
    ["O"]: "O",
    ["P"]: "P",
    ["Lab Growm"]: "Lab Growm",
    ["No Diamonds Listed In That Range"]: "No Diamonds Listed In That Range",
    ["Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs."]:
      "Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs.",
    ["First Name"]: "First Name",
    ["Last Name"]: "Last Name",
    ["Add Comments Here"]: "Add Comments Here",
    ["Request"]: "Request",
    ["Please enter the details of the type of diamond you are looking for and your contact information."]:
      "Please enter the details of the type of diamond you are looking for and your contact information.",
    ["Your information will be submitted to one of our diamond specialists that will contact you."]:
      "Your information will be submitted to one of our diamond specialists that will contact you.",
    ["Advance"]: "Advance",
    ["Use your diamond comparison to save all the details of the diamonds you are considering and view them all together"]:
      "Use your diamond comparison to save all the details of the diamonds you are considering and view them all together",
    ["This makes it easy to choose the right diamond."]:
      "This makes it easy to choose the right diamond.",

    ["Mined Diamonds"]: "Mined Diamonds",
    ["Lab Grown"]: "Lab Grown",
    ["Color Diamonds"]: "Color Diamonds",

    ["ID"]: "ID",
    ["Certs"]: "Certs",
    ["Method"]: "Method",
    ["Custom Method"]: "Custom Method",
    ["In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%"]:
      "In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%",
    ["Markup By Vendor"]: "Markup By Vendor",
    ["Markup By PriceRange With Certs"]: "Markup By PriceRange With Certs",
    ["Markup By SizeRange With Certs"]: "Markup By SizeRange With Certs",
    ["CL"]: "CL",
    ["Update Markup"]: "Update Markup",
    ["GIA"]: "GIA",
    ["AGS"]: "AGS",
    ["EGL"]: "EGL",
    ["IGI"]: "IGI",

    ["Enable Diamonds with"]: "Enable Diamonds with",
    ["Video Only"]: "Video Only",
    ["Enable Dynamic Range"]: "Enable Dynamic Range",
    ["EGL Belgium"]: "EGL Belgium",
    ["EGL International"]: "EGL International",
    ["EGL Israel"]: "EGL Israel",
    ["EGL South Africa"]: "EGL South Africa",
    ["EGL USA"]: "EGL USA",
    ["HRD"]: "HRD",
    ["GCAL"]: "GCAL",
    ["None"]: "None",

    ["FNT"]: "FNT",
    ["MED"]: "MED",
    ["ST"]: "ST",
    ["ALL"]: "ALL",

    ["Configurable Attribute"]: "Configurable Attribute",
    ["Shipping Charge"]: "Shipping Charge",
    ["Update Settings"]: "Update Settings",

    ["Standard Search"]: "Standard Search",
    ["Advanced Search"]: "Advanced Search",
    ["Fancy Colored Search"]: "Fancy Colored Search",
    ["Lab Grown Search"]: "Lab Grown Search",
    ["Request a Diamond"]: "Request a Diamond",
    ["Compare Diamonds"]: "Compare Diamonds",
    ["Left Navigation for MasterLink"]: "Left Navigation for MasterLink",
    ["Loose Diamonds"]: "Loose Diamonds",
    ["Same Window"]: "Same Window",
    ["New Window"]: "New Window",
    ["Inframe"]: "Inframe",
    ["Our Signature Diamonds"]: "Our Signature Diamonds",
    ["Best Value Diamonds"]: "Best Value Diamonds",
    ["Private Reserve Diamonds"]: "Private Reserve Diamonds",
    ["HeartStar Diamonds"]: "HeartStar Diamonds",

    ["Change this value to set the TITLE at the top of the search results page. Leave blank for default value."]:
      "Change this value to set the TITLE at the top of the search results page. Leave blank for default value.",
    ["Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value."]:
      "Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value.",
    ["Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only)."]:
      "Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only).",
    ["Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page."]:
      "Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page.",
    ["Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.",
    ["Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none)."]:
      "Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none).",
    ["Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.",
    ["Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value."]:
      "Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value.",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      "By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page.",
    ["You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade.",
    ["Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly.",
    ["Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form."]:
      "Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form.",
    ["Choose whether to use the interactive Macromedia Flash search form instead of the regular search form."]:
      "Choose whether to use the interactive Macromedia Flash search form instead of the regular search form.",
    ["Choose whether to use show popup of diamond details on the diamond inveotry page."]:
      "Choose whether to use show popup of diamond details on the diamond inveotry page.",
    ["Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page."]:
      "Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page.",
    ["Choose whether to link diamond search with new ring builder."]:
      "Choose whether to link diamond search with new ring builder.",
    ["Choose whether to display Ring Builder image at the right column of new diamond link."]:
      "Choose whether to display Ring Builder image at the right column of new diamond link.",
    ["Search Page Title"]: "Search Page Title",
    ["Diamond Detail page Title"]: "Diamond Detail page Title",
    ["Hyperlink #1 TEXT at bottom of search results page"]:
      "Hyperlink #1 TEXT at bottom of search results page",
    ["Hyperlink #1 URL at bottom"]: "Hyperlink #1 URL at bottom",
    ["Hyperlink #2 TEXT at bottom"]: "Hyperlink #2 TEXT at bottom",
    ["Hyperlink #2 URL at bottomof search results page"]:
      "Hyperlink #2 URL at bottomof search results page",
    ["Hyperlink #3 TEXT at bottom of search results page"]:
      "Hyperlink #3 TEXT at bottom of search results page",
    ["Hyperlink #3 URL at bottom of search results page"]:
      "Hyperlink #3 URL at bottom of search results page",
    ["Let users search for MATCHED PAIRS"]:
      "Let users search for MATCHED PAIRS",
    ["Show Logo on Results Page"]: "Show Logo on Results Page",
    ["Show Diamond Education on Results Page"]:
      "Show Diamond Education on Results Page",
    ["Results page image URL"]: "Results page image URL",
    ["Results page image hyperlink URL"]: "Results page image hyperlink URL",
    ["Diamond details page image URL"]: "Diamond details page image URL",
    ["Alternate diamond details link text"]:
      "Alternate diamond details link text",
    ["Enhanced details in search results"]:
      "Enhanced details in search results",
    ["Search By Cut Grade"]: "Search By Cut Grade",
    ["Number of diamonds displayed per page"]:
      "Number of diamonds displayed per page",
    ["Show odd diamond cuts on search form"]:
      "Show odd diamond cuts on search form",
    ["Dynamic Flash search form"]: "Dynamic Flash search form",
    ["Show popup on Diamond inventory display page"]:
      "Show popup on Diamond inventory display page",
    ["Show Share Button on Details Page (for New GF Link only)"]:
      "Show Share Button on Details Page (for New GF Link only)",
    ["Use New Ringbuilder"]: "Use New Ringbuilder",
    ["Show Ring Builder Image (for New GF Link only)"]:
      "Show Ring Builder Image (for New GF Link only)",

    ["Generate API Key to get data"]: "Generate API Key to get data",
    ["Generate"]: "Generate",
    ["API Documentation"]: "API Documentation",

    ["Markup="]: "Markup=",
    ["Markups"]: "Markups",

    ["Setting #"]: "Setting #",
    ["Vendor #"]: "Vendor #",
    ["Metal"]: "Metal",
    ["$ Price"]: "$ Price",
    ["$ Markup"]: "$ Markup",

    ["Setup"]: "Setup",
    ["Ring Builder URL"]: "Ring Builder URL",
    ["Enter the URL of this tool on your site to enable social sharing and dynamic sizing."]:
      "Enter the URL of this tool on your site to enable social sharing and dynamic sizing.",
    ["Internal Use Link"]: "Internal Use Link",
    ["Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting."]:
      "Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting.",
    ["Display"]: "Display",
    ["RingSize Type"]: "RingSize Type",
    ["Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.)."]:
      "Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.).",
    ["American"]: "American",
    ["British"]: "British",
    ["Roman"]: "Roman",
    ["Europe"]: "Europe",
    ["Diamond Initially Sort Result On"]: "Diamond Initially Sort Result On",
    ["Select a diamond attribute that you would like to initially sort the diamonds by."]:
      "Select a diamond attribute that you would like to initially sort the diamonds by.",
    ["Ascending"]: "Ascending",
    ["Descending"]: "Descending",
    ["Default Metal Type on Setting page"]:
      "Default Metal Type on Setting page",
    ["14K White Gold"]: "14K White Gold",
    ["14K Yellow Gold"]: "14K Yellow Gold",
    ["Show Advanced Search Options By Default"]:
      "Show Advanced Search Options By Default",
    ["Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc.",
    ["Rings - Show Schedule A Viewing"]: "Rings - Show Schedule A Viewing",
    ["Choose Whether to display the link which allows the customer the ability to schedule an appointment."]:
      "Choose Whether to display the link which allows the customer the ability to schedule an appointment.",
    ["Show Addresses"]: "Show Addresses",
    ["Choose Whether to display your company address and contact information within the RingBuilder."]:
      "Choose Whether to display your company address and contact information within the RingBuilder.",
    ["Show Center Stone Size"]: "Show Center Stone Size",
    ["Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings."]:
      "Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings.",
    ["Show Price"]: "Show Price",
    ["Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”."]:
      "Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”.",
    ["Apply GST Tax"]: "Apply GST Tax",
    ["Toggle the ability to apply international tax."]:
      "Toggle the ability to apply international tax.",
    ["GST Tax Value"]: "GST Tax Value",
    ["Set the value when applying the GST Tax option."]:
      "Set the value when applying the GST Tax option.",
    ["Social"]: "Social",
    ["Show Pinterest Share"]: "Show Pinterest Share",
    ["Choose whether to display the Pinterest “Pin it” button or not."]:
      "Choose whether to display the Pinterest “Pin it” button or not.",
    ["Show Twitter Share"]: "Show Twitter Share",
    ["Choose whether to display the Twitter “Tweet” button or not."]:
      "Choose whether to display the Twitter “Tweet” button or not.",
    ["Show Facebook Share"]: "Show Facebook Share",
    ["Choose whether to display the Facebook “Share” button or not."]:
      "Choose whether to display the Facebook “Share” button or not.",
    ["Show Facebook Like"]: "Show Facebook Like",
    ["Choose whether to display the Facebook “Like” button or not."]:
      "Choose whether to display the Facebook “Like” button or not.",
    ["Show Google Plus"]: "Show Google Plus",
    ["Choose whether to display the Google “G+1” button or not."]:
      "Choose whether to display the Google “G+1” button or not.",
    ["Choose whether to display the Instagram “Share” button or not."]:
      "Choose whether to display the Instagram “Share” button or not.",
    ["E-Commerce"]: "E-Commerce",
    ["Show Add To Cart Button"]: "Show Add To Cart Button",
    ["Alternate Shopping Cart"]: "Alternate Shopping Cart",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value.",
    ["Show Buy Setting Only"]: "Show Buy Setting Only",
    ["Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond."]:
      "Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond.",
    ["Show Buy Diamond Only"]: "Show Buy Diamond Only",
    ["Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring."]:
      "Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring.",
    ["View Cart"]: "View Cart",
    ["Button"]: "Button",
    ["Choose Whether to display the PayPal shopping cart's view button or Not"]:
      "Choose Whether to display the PayPal shopping cart's view button or Not",

    ["No content."]: "No content.",
    ["Page Alignment"]: "Page Alignment",
    ["Center"]: "Center",
    ["Left"]: "Left",
    ["Right"]: "Right",
    ["Show Social Sharing Buttons"]: "Show Social Sharing Buttons",
    ["Show View In Store"]: "Show View In Store",
    ["Show Pop Up On Diamond Search"]: "Show Pop Up On Diamond Search",
    ["Diamond Search Result Page Size"]: "Diamond Search Result Page Size",
    ["Rings - Search Result Page Size"]: "Rings - Search Result Page Size",
    ["Rings - Initially Sort Result On"]: "Rings - Initially Sort Result On",
    ["Setting Number"]: "Setting Number",

    ["Send Email To Friend"]: "Send Email To Friend",
    ["Send Email To Retailer"]: "Send Email To Retailer",
    ["Send Email To Sender"]: "Send Email To Sender",
    ["Request A Diamond Email"]: "Request A Diamond Email",
    ["Request A Diamond"]: "Request A Diamond",
    ["Send Mail to Request A Diamond"]: "Send Mail to Request A Diamond",

    ["Wishlist"]: "Wishlist",
    ["Bridal Registry"]: "Bridal Registry",
    ["Shopping Cart"]: "Shopping Cart",
    ["Show Store Hours"]: "Show Store Hours",
    ["Website URL"]: "Website URL",
    ["Google Analytic ID"]: "Google Analytic ID",
    ["Show Only MSRP Prices"]: "Show Only MSRP Prices",
    ["Masterlink Theme"]: "Masterlink Theme",
    ["Show View"]: "Show View",
    ["New MasterLink Use In Iframe?"]: "New MasterLink Use In Iframe?",
    ["Login URL"]: "Login URL",
    ["Show WholeSale Price Internal Use Link?"]:
      "Show WholeSale Price Internal Use Link?",
    ["Show Address/Phone Details?"]: "Show Address/Phone Details?",
    ["Diamond Search / Ringbuilder load from Masterlink?"]:
      "Diamond Search / Ringbuilder load from Masterlink?",
    ["Diamond Image URL"]: "Diamond Image URL",
    ["Vendor Details open or closed by default in new masterlink"]:
      "Vendor Details open or closed by default in new masterlink",
    ["Show Additional PopUp in new masterlink"]:
      "Show Additional PopUp in new masterlink",
    ["Shopping Cart page message"]: "Shopping Cart page message",
    ["Hearts On Fire Link"]: "Hearts On Fire Link",
    ["Show Setting Name at Setting Grid"]: "Show Setting Name at Setting Grid",
    ["Show Ring Builder with One Step"]: "Show Ring Builder with One Step",
    ["Show Default Ring Size"]: "Show Default Ring Size",
    ["Shop Diamonds"]: "Shop Diamonds",
    ["Shop Bridal & Engagement"]: "Shop Bridal & Engagement",
    ["Shop Designers"]: "Shop Designers",
    ["Shop Jewelry"]: "Shop Jewelry",
    ["Shop Watches"]: "Shop Watches",

    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."]:
      "Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond.",
    ["Sticky Header Height"]: "Sticky Header Height",
    ["Enter a Sticky Header Height."]: "Enter a Sticky Header Height.",
    ["Platinum Education"]: "Platinum Education",
    ["Provides a link to copy for platinum education in Ring Builder."]:
      "Provides a link to copy for platinum education in Ring Builder.",
    ["Ring Size Guide URL"]: "Ring Size Guide URL",
    ["Provides a link to copy for ring sizing guides provided by GemFind."]:
      "Provides a link to copy for ring sizing guides provided by GemFind.",
    ["Show Breadcrumbs"]: "Show Breadcrumbs",
    ["Shows navigation path a customer took to get to page they're on."]:
      "Shows navigation path a customer took to get to page they're on.",
    ["Create Profile"]: "Create Profile",
    ["text"]: "text",
    ["Provide alternate text for user registration Button."]:
      "Provide alternate text for user registration Button.",
    ["Sort Products by Price Initially By"]:
      "Sort Products by Price Initially By",
    ["Allow Customers to Sort Product By Price."]:
      "Allow Customers to Sort Product By Price.",
    ["Price: Low - High"]: "Price: Low - High",
    ["Price: High - Low"]: "Price: High - Low",
    ["Collection Filtering"]: "Collection Filtering",
    ["When navigating Designer Landing pages - show collections instead of categories."]:
      "When navigating Designer Landing pages - show collections instead of categories.",
    ["Schedule Viewing"]: "Schedule Viewing",
    ["Allow Customers to schedule a meeting with you."]:
      "Allow Customers to schedule a meeting with you.",
    ["Designer Tile Layout"]: "Designer Tile Layout",
    ["Show Back to Top Button."]: "Show Back to Top Button.",
    ["Enabled Back To Top"]: "Enabled Back To Top",
    ["Allow address to show on detailpage"]:
      "Allow address to show on detailpage",
    ["Text"]: "Text",
    ["(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."]:
      "(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability).",
    ["Starting At Price"]: "Starting At Price",
    ["Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."]:
      "Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart).",
    ["instead of the Price"]: "instead of the Price",
    ["Hides the retail price for products using Price Type 1,2,or 3 ."]:
      "Hides the retail price for products using Price Type 1,2,or 3 .",
    ["Facebook App ID"]: "Facebook App ID",
    ["Connect MasterLink to your Facebook page, using your Facebook App ID."]:
      "Connect MasterLink to your Facebook page, using your Facebook App ID.",
    ["Facebook Like Page URL"]: "Facebook Like Page URL",
    ["Allow Like access to your facebook account by providing the URL."]:
      "Allow Like access to your facebook account by providing the URL.",
    ["Allow Customers to post your products to their Pinterest boards."]:
      "Allow Customers to post your products to their Pinterest boards.",
    ["Allow Customers to share your products to their Twitter account."]:
      "Allow Customers to share your products to their Twitter account.",
    ["Allow Customers to share your products to their Facebook page."]:
      "Allow Customers to share your products to their Facebook page.",
    ["Allow Customers to like your products directly from your website."]:
      "Allow Customers to like your products directly from your website.",
    ["SEO"]: "SEO",
    ["Masterlink Default Title"]: "Masterlink Default Title",
    ["Enter a title for search engine optimization."]:
      "Enter a title for search engine optimization.",
    ["Masterlink Meta Keywords"]: "Masterlink Meta Keywords",
    ["Enter a Keywords for search engine optimization."]:
      "Enter a Keywords for search engine optimization.",
    ["Masterlink Meta Description"]: "Masterlink Meta Description",
    ["Enter a description for search engine optimization."]:
      "Enter a description for search engine optimization.",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."]:
      "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page.",
    ["Enable the View Cart button for customers to review their products selections."]:
      "Enable the View Cart button for customers to review their products selections.",

    ["New Favourite List"]: "New Favourite List",
    ["New Favourite Items Added"]: "New Favourite Items Added",

    ["Create Your Own Diamond Studs in 3 easy steps with Stud Builder"]:
      "Create Your Own Diamond Studs in 3 easy steps with Stud Builder",
    ["Select Your Diamonds"]: "Select Your Diamonds",
    ["Choose Your Mounting"]: "Choose Your Mounting",
    ["Complete Your Earrings"]: "Complete Your Earrings",
    ["Standard"]: "Standard",
    ["Add To Stud"]: "Add To Stud",
    ["Advanced"]: "Advanced",

    ["Diamonds Markup"]: "Diamonds Markup",

    ["Diamond Drop A Hint"]: "Diamond Drop A Hint",
    ["Stud Builder URL"]: "Stud Builder URL",
    ["Internal Use Link?"]: "Internal Use Link?",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."]:
      "Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair.",
    ["Only Diamond Search"]: "Only Diamond Search",
    ["Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."]:
      "Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes.",
    ["Show Dealer SKU In Diamond Search"]: "Show Dealer SKU In Diamond Search",
    ["Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."]:
      "Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370).",
    ["Choose whether to display the link which allows the customer the ability to schedule an appointment."]:
      "Choose whether to display the link which allows the customer the ability to schedule an appointment.",
    ["Allow address to show on detailpage."]:
      "Allow address to show on detailpage.",
    ["Choose whether to display “Call for Price” instead of the retail price or not."]:
      "Choose whether to display “Call for Price” instead of the retail price or not.",
    ["Shopping Cart Url"]: "Shopping Cart Url",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value.",
    ["Choose whether to display the “Add to Cart” button or not."]:
      "Choose whether to display the “Add to Cart” button or not.",
    ["Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not."]:
      "Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not.",

    ["Generate Sync"]: "Generate Sync",
    ["Generate Full Sync"]: "Generate Full Sync",
    ["File Name"]: "File Name",
    ["Folder"]: "Folder",

    ["Enter FTP Detail for SYNC File Below"]:
      "Enter FTP Detail for SYNC File Below",
    ["User Id"]: "User Id",
    ["Is SSL?"]: "Is SSL?",

    ["Configure & Customize Your"]: "Configure & Customize Your",
    ["Pre-Owned Rolex Watch"]: "Pre-Owned Rolex Watch",
    ["E-Mail A Friend"]: "E-Mail A Friend",
    ["Print Details"]: "Print Details",
    ["Have a question regarding this item? Our specialist are available to assist you. | Call us:"]:
      "Have a question regarding this item? Our specialist are available to assist you. | Call us:",
    ["Items Details"]: "Items Details",
    ["STEP 1 | Size & Bracelet Type"]: "STEP 1 | Size & Bracelet Type",
    ["Choose Watch Type"]: "Choose Watch Type",
    ["Men's"]: "Men's",
    ["Ladies"]: "Ladies",
    ["Mid Size"]: "Mid Size",
    ["Choose Bracelet Style"]: "Choose Bracelet Style",
    ["Jubilee"]: "Jubilee",
    ["Oyster"]: "Oyster",
    ["President"]: "President",
    ["Choose Bracelet Metal"]: "Choose Bracelet Metal",
    ["Stainless Steel"]: "Stainless Steel",
    ["18K Y/SS"]: "18K Y/SS",
    ["18K Yellow"]: "18K Yellow",
    ["18K White"]: "18K White",
    ["STEP 2 | Bezel Type"]: "STEP 2 | Bezel Type",
    ["Choose Bezel Type"]: "Choose Bezel Type",
    ["Choose Bezel Metal"]: "Choose Bezel Metal",
    ["Choose Bezel Gemstone Type"]: "Choose Bezel Gemstone Type",
    ["Choose Bezel Gemstone Shape"]: "Choose Bezel Gemstone Shape",
    ["Choose Bezel Diamond Quality"]: "Choose Bezel Diamond Quality",
    ["STEP 3 | Dial Type"]: "STEP 3 | Dial Type",
    ["Choose Dial Type"]: "Choose Dial Type",
    ["Marker Type"]: "Marker Type",
    ["Custom Dial Colors"]: "Custom Dial Colors",
    ["Specifications"]: "Specifications",
    ["Size & Bracelet Type (STEP 1)"]: "Size & Bracelet Type (STEP 1)",
    ["SKU#"]: "SKU#",
    ["Watch Type"]: "Watch Type",
    ["Men"]: "Men",
    ["Bracelet Style"]: "Bracelet Style",
    ["Bracelet Metal"]: "Bracelet Metal",
    ["Bezel Type (STEP 2)"]: "Bezel Type (STEP 2)",
    ["Custom Bezel Type"]: "Custom Bezel Type",
    ["Custom Bezel Metal"]: "Custom Bezel Metal",
    ["Custom Bezel Gemstones"]: "Custom Bezel Gemstones",
    ["Custom Bezel Gemstone Shape"]: "Custom Bezel Gemstone Shape",
    ["Custom Bezel Diamond Quality"]: "Custom Bezel Diamond Quality",
    ["Total Carat Weight"]: "Total Carat Weight",
    ["Dial Type (STEP 3)"]: "Dial Type (STEP 3)",
    ["Dial Type"]: "Dial Type",
    ["Custom Dial Color"]: "Custom Dial Color",
    ["Your Name"]: "Your Name",
    ["Your E-Mail Address"]: "Your E-Mail Address",
    ["Your Company Name"]: "Your Company Name",
    ["Your Phone Number"]: "Your Phone Number",
    ["Your Store Phone Number"]: "Your Store Phone Number",
    ["Write A Personal Message"]: "Write A Personal Message",
    ["Hint Recipient's Name"]: "Hint Recipient's Name",
    ["Hint Recipient's E-Mail"]: "Hint Recipient's E-Mail",
    ["Reason For This Gift"]: "Reason For This Gift",
    ["Gift Deadline"]: "Gift Deadline",
    ["Add A Personal Message Here"]: "Add A Personal Message Here",
    ["Friend E-Mail Address"]: "Friend E-Mail Address",
    ["Subject"]: "Subject",
    ["Schedule"]: "Schedule",
    ["Your Email"]: "Your Email",
    ["Appointment Date"]: "Appointment Date",
    ["Add A Message Here"]: "Add A Message Here",
    ["Get Directions"]: "Get Directions",

    ["Watch Customizer Options"]: "Watch Customizer Options",
    ["Watch Customizer URL"]: "Watch Customizer URL",
    ["Update Setting"]: "Update Setting",

    ["Symetry"]: "Symetry",
    ["Choose Your Setting"]: "Choose Your Setting",
    ["Complete Your Pendant"]: "Complete Your Pendant",
    ["Add To Your Pendant"]: "Add To Your Pendant",

    ["Pendant Builder URL"]: "Pendant Builder URL",
    ["Enter a URL."]: "Enter a URL.",
    ["Show Advanced Search As Default"]: "Show Advanced Search As Default",
    ["Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc.",
    ["Choose whether to display the “View Cart” button or not."]:
      "Choose whether to display the “View Cart” button or not.",

    ["Total items"]: "Total items",
    ["Total Cost"]: "Total Cost",

    ["Purchase order date"]: "Purchase order date",
    ["Order Confirm Date"]: "Order Confirm Date",
    ["Sale Per"]: "Sale Per",

    ["Confirm"]: "Confirm",
    ["Quantity"]: "Quantity",
    ["Order Date"]: "Order Date",

    ["Folder Path"]: "Folder Path",
    ["Choose File To Upload"]: "Choose File To Upload",

    ["Filter By Trade Show"]: "Filter By Trade Show",
    ["Trade Show 1"]: "Trade Show 1",
    ["Trade Show 2"]: "Trade Show 2",
    ["Trade Show 3"]: "Trade Show 3",
    ["Buyer"]: "Buyer",

    ["Are you sure to update Permission?"]:
      "Are you sure to update Permission?",
    ["Report Permission Control"]: "Report Permission Control",
    ["Update Date on which"]: "Update Date on which",
    ["Is Created"]: "Is Created",
    ["Select Vendor Status"]: "Select Vendor Status",
    ["Select Status"]: "Select Status",
    ["Company name is compulsory"]: "Company name is compulsory",
    ["Street address is compulsory"]: "Street address is compulsory",
    ["City is compulsory"]: "City is compulsory",
    ["City is invalid"]: "City is invalid",
    ["State is compulsory"]: "State is compulsory",
    ["State is invalid"]: "State is invalid",
    ["Zip code is compulsory"]: "Zip code is compulsory",
    ["Zip code is invalid"]: "Zip code is invalid",
    ["Kindly select the country"]: "Kindly select the country",
    ["Contact name is compulsory"]: "Contact name is compulsory",
    ["Email address is compulsory"]: "Email address is compulsory",
    ["Email address is invalid"]: "Email address is invalid",
    ["Phone number is compulsory"]: "Phone number is compulsory",
    ["Phone number is invalid"]: "Phone number is invalid",
    ["Username is compulsory"]: "Username is compulsory",
    ["Password is compulsory"]: "Password is compulsory",
    ["Password should have a spe"]: "Password should have a spe",
    ["Kindly select the permission type"]: "Kindly select the permission type",
    ["Kindly select the retailer status"]: "Kindly select the retailer status",
    ["Kindly select the vendor status"]: "Kindly select the vendor status",
    ["RetailerCore 1"]: "RetailerCore 1",
    ["RetailerCore 2"]: "RetailerCore 2",
    ["RetailerCore 3"]: "RetailerCore 3",
    ["RetailerCore 4"]: "RetailerCore 4",
    ["RetailerCore 5"]: "RetailerCore 5",
    ["RetailerCore 6"]: "RetailerCore 6",
    ["RetailerCore 7"]: "RetailerCore 7",
    ["RetailerCore 8"]: "RetailerCore 8",
    ["RetailerCore 9"]: "RetailerCore 9",
    ["RetailerCore 10"]: "RetailerCore 10",
    ["RetailerCore 11"]: "RetailerCore 11",
    ["RetailerCore 12"]: "RetailerCore 12",
    ["RetailerCore 13"]: "RetailerCore 13",
    ["Retailer Id is compulsory"]: "Retailer Id is compulsory",
    ["Retailer Id is Invalid"]: "Retailer Id is Invalid",
    ["Kindly select the core"]: "Kindly select the core",
    ["Limit Type is compulsory"]: "Limit Type is compulsory",
    ["Kindly select the retailer"]: "Kindly select the retailer",
    ["Kindly select the Diamond Dealer"]: "Kindly select the Diamond Dealer",
    ["Kindly select the Vendor"]: "Kindly select the Vendor",
    ["No Dealer Selected"]: "No Dealer Selected",
    ["Please Select Dealer."]: "Please Select Dealer.",
    ["Select Dealer ID"]: "Select Dealer ID",
    ["Select File Type"]: "Select File Type",
    ["Kindly select Dealer ID"]: "Kindly select Dealer ID",
    ["FTP Username is compulsory"]: "FTP Username is compulsory",
    ["FTP Password is compulsory"]: "FTP Password is compulsory",
    ["Kindly select File Type"]: "Kindly select File Type",
    ["Path is Compulsory"]: "Path is Compulsory",
    ["Dealer ID is compulsory"]: "Dealer ID is compulsory",
    ["Api Url is compulsory"]: "Api Url is compulsory",
    ["Api Key is compulsory"]: "Api Key is compulsory",
    ["Are you sure you want to discard ?"]:
      "Are you sure you want to discard ?",
    ["Are you sure you want to discard all ?"]:
      "Are you sure you want to discard all ?",
    ["Enter valid DealerID"]: "Enter valid DealerID",
    ["All Designer"]: "All Designer",
    ["Please select designer."]: "Please select designer.",
    ["Please select image."]: "Please select image.",
    ["View Inquiry"]: "View Inquiry",
    ["Contacts"]: "Contacts",
    ["Export"]: "Export",
    ["Jewelry Retailer Click Report"]: "Jewelry Retailer Click Report",
    ["Jewelry Vendor Click Report"]: "Jewelry Vendor Click Report",
    ["Global Diamond"]: "Global Diamond",
    ["Extended Diamond Chart Report"]: "Extended Diamond Chart Report",
    ["Global Jewelry"]: "Global Jewelry",
    ["Jewelry Category Reports"]: "Jewelry Category Reports",
    ["Jewelry Metal Type Reports"]: "Jewelry Metal Type Reports",
    ["Page number is compulsory"]: "Page number is compulsory",
    ["First Tier Page Name is compulsory"]:
      "First Tier Page Name is compulsory",
    ["Second Tier Page Name is compulsory"]:
      "Second Tier Page Name is compulsory",
    ["Third Tier Page Name is compulsory"]:
      "Third Tier Page Name is compulsory",
    [".JPG | .JPEG | .PNG Format"]: ".JPG | .JPEG | .PNG Format",
    ["Select Start Time"]: "Select Start Time",
    ["Select End Time"]: "Select End Time",
    ["Please select the image"]: "Please select the image",
    ["ShowName is compulsory"]: "ShowName is compulsory",
    ["Website is compulsory"]: "Website is compulsory",
    ["Kindly select the Show Start Time"]: "Kindly select the Show Start Time",
    ["Kindly select the Show End Time"]: "Kindly select the Show End Time",
    ["Kindly select the Show Start Date"]: "Kindly select the Show Start Date",
    ["Kindly select the Show End Date"]: "Kindly select the Show End Date",
    ["Color Name is compulsory"]: "Color Name is compulsory",
    ["Select"]: "Select",
    ["Please select compose."]: "Please select compose.",
    ["Please enter name."]: "Please enter name.",
    ["Please enter email."]: "Please enter email.",
    ["Email is not valid."]: "Email is not valid.",
    ["Please enter subject."]: "Please enter subject.",
    ["Please enter summary."]: "Please enter summary.",
    ["Option 1"]: "Option 1",
    ["Option 2"]: "Option 2",
    ["GIA Website"]: "GIA Website",
    ["Yes"]: "Yes",
    ["Borderline"]: "Borderline",
    ["No"]: "No",
    ["Very Slight"]: "Very Slight",
    ["Slight"]: "Slight",
    ["Faint"]: "Faint",
    ["Medium"]: "Medium",
    ["Strong"]: "Strong",
    ["Very Strong"]: "Very Strong",
    ["No Yellow"]: "No Yellow",
    ["No Green"]: "No Green",
    ["No Grey"]: "No Grey",
    ["No Black"]: "No Black",
    ["No Pink"]: "No Pink",
    ["No Blue"]: "No Blue",
    ["No Brown"]: "No Brown",
    ["Light"]: "Light",
    ["Heavy"]: "Heavy",
    ["Extremely Thin"]: "Extremely Thin",
    ["Very Thin"]: "Very Thin",
    ["Thin"]: "Thin",
    ["Slightly Thin"]: "Slightly Thin",
    ["Slightly Thick"]: "Slightly Thick",
    ["Thick"]: "Thick",
    ["Very Thick"]: "Very Thick",
    ["Extremely Thick"]: "Extremely Thick",
    ["Extra Thin"]: "Extra Thin",
    ["Bearding"]: "Bearding",
    ["Brown Patch of Color"]: "Brown Patch of Color",
    ["Bruise"]: "Bruise",
    ["Cavity"]: "Cavity",
    ["Chip"]: "Chip",
    ["Cleavage"]: "Cleavage",
    ["Cloud"]: "Cloud",
    ["Crystal"]: "Crystal",
    ["Crystal Surface"]: "Crystal Surface",
    ["Etch Channel"]: "Etch Channel",
    ["Extra Facet"]: "Extra Facet",
    ["Feather"]: "Feather",
    ["Flux Remnant"]: "Flux Remnant",
    ["Indented Natural"]: "Indented Natural",
    ["Internal Graining"]: "Internal Graining",
    ["Internal Inscription"]: "Internal Inscription",
    ["Internal Laser Drilling"]: "Internal Laser Drilling",
    ["Knot"]: "Knot",
    ["Laser Drill Hole"]: "Laser Drill Hole",
    ["Manufacturing Remnant"]: "Manufacturing Remnant",
    ["Minor Details of Polish"]: "Minor Details of Polish",
    ["Natural"]: "Natural",
    ["Needly"]: "Needly",
    ["No Inclusion"]: "No Inclusion",
    ["Pinpoint"]: "Pinpoint",
    ["Reflecting Surface Graining"]: "Reflecting Surface Graining",
    ["Surface Graining"]: "Surface Graining",
    ["Twinning Wisp"]: "Twinning Wisp",
    ["Small"]: "Small",
    ["Very Small"]: "Very Small",
    ["Large"]: "Large",
    ["Very Large"]: "Very Large",
    ["Abraded"]: "Abraded",
    ["Chipped"]: "Chipped",
    ["Pointed"]: "Pointed",
    ["Laser Drilled"]: "Laser Drilled",
    ["Clarity Enhanced"]: "Clarity Enhanced",
    ["Color Enhanced"]: "Color Enhanced",
    ["Irradiated"]: "Irradiated",
    ["HPHT"]: "HPHT",
    ["Search All Stones"]: "Search All Stones",
    ["Search Single Stone"]: "Search Single Stone",
    ["Search Match Pairs"]: "Search Match Pairs",
    ["Emerald"]: "Emerald",
    ["SI1"]: "SI1",
    ["VG"]: "VG",
    ["B&H Diamonds"]: "B&H Diamonds",
    ["Chicago, IL"]: "Chicago, IL",
    ["Category 1"]: "Category 1",
    ["Category 2"]: "Category 2",
    ["Category 3"]: "Category 3",
    ["Material 1"]: "Material 1",
    ["Material 2"]: "Material 2",
    ["Material 3"]: "Material 3",
    ["Child"]: "Child",
    ["Mens"]: "Mens",
    ["Teen"]: "Teen",
    ["Unisex"]: "Unisex",
    ["Womens"]: "Womens",
    ["Vendor 1"]: "Vendor 1",
    ["Vendor 2"]: "Vendor 2",
    ["Abc"]: "Abc",
    ["Xyz"]: "Xyz",
    ["Tom"]: "Tom",
    ["Gemstone 1"]: "Gemstone 1",
    ["Gemstone 2"]: "Gemstone 2",
    ["Gemstone 3"]: "Gemstone 3",
    ["Collection 1"]: "Collection 1",
    ["Collection 2"]: "Collection 2",
    ["Collection 3"]: "Collection 3",
    ["Collection 4"]: "Collection 4",
    ["Sales Rep"]: "Sales Rep",
    ["Jewelry Access"]: "Jewelry Access",
    ["RingBuilder Access"]: "RingBuilder Access",
    ["Hide Price"]: "Hide Price",
    ["Auto Sync"]: "Auto Sync",
    ["Data & Permissions"]: "Data & Permissions",
    ["Make Appointment"]: "Make Appointment",
    ["Store Notes"]: "Store Notes",
    ["Disconnect Retailer"]: "Disconnect Retailer",
    ["Conversation data is compulsory"]: "Conversation data is compulsory",
    ["Note Title is compulsory"]: "Note Title is compulsory",
    ["Note Description"]: "Note Description",
    ["Appointment data is compulsory"]: "Appointment data is compulsory",
    ["Access"]: "Access",
    ["Avatar"]: "Avatar",
    ["Job Title"]: "Job Title",
    ["E-Mail"]: "E-Mail",
    ["Phone No."]: "Phone No.",
    ["Locations"]: "Locations",
    ["Store Bio"]: "Store Bio",
    ["Years In Business"]: "Years In Business",
    ["Create A New Note"]: "Create A New Note",
    ["Edit Note"]: "Edit Note",
    ["Monday"]: "Monday",
    ["Tuesday"]: "Tuesday",
    ["December"]: "December",
    ["October"]: "October",
    ["July"]: "July",
    ["Bhavin Patel"]: "Bhavin Patel",
    ["Started a"]: "Started a",
    ["with your store."]: "with your store.",
    ["Create Appointment"]: "Create Appointment",
    ["Calendars"]: "Calendars",
    ["Calendar"]: "Calendar",
    ["Day"]: "Day",
    ["Engagement"]: "Engagement",
    ["Cleaning"]: "Cleaning",
    ["Repair"]: "Repair",
    ["Wedding"]: "Wedding",
    ["Select Date"]: "Select Date",
    ["Select Calendar"]: "Select Calendar",
    ["Select Contact"]: "Select Contact",
    ["ABC"]: "ABC",
    ["John"]: "John",
    ["XYZ"]: "XYZ",
    ["User"]: "User",
    ["Set Day"]: "Set Day",
    ["Thursday"]: "Thursday",
    ["Friday"]: "Friday",
    ["Saturday"]: "Saturday",
    ["Set Time"]: "Set Time",
    ["Enter Name."]: "Enter Name.",
    ["Enter Email Address."]: "Enter Email Address.",
    ["Enter Friend's Name."]: "Enter Friend's Name.",
    ["Enter Friend's Email Address."]: "Enter Friend's Email Address.",
    ["Search all"]: "Search all",
    ["Inventory is compulsory"]: "Inventory is compulsory",
    ["Matched Pair Stock is compulsory"]: "Matched Pair Stock is compulsory",
    ["Stones are compulsory"]: "Stones are compulsory",
    ["Size is compulsory"]: "Size is compulsory",
    ["Cost is compulsory"]: "Cost is compulsory",
    ["Kindly select the shape"]: "Kindly select the shape",
    ["Kindly select the color"]: "Kindly select the color",
    ["Kindly select the fancy codes"]: "Kindly select the fancy codes",
    ["Kindly select the fancy names"]: "Kindly select the fancy names",
    ["Other is compulsory"]: "Other is compulsory",
    ["Kindly select the clarity"]: "Kindly select the clarity",
    ["Kindly select the cut grade"]: "Kindly select the cut grade",
    ["Kindly select the certificate"]: "Kindly select the certificate",
    ["Kindly select the certificate number"]:
      "Kindly select the certificate number",
    ["Depth is compulsory"]: "Depth is compulsory",
    ["Table is compulsory"]: "Table is compulsory",
    ["Measurements are compulsory"]: "Measurements are compulsory",
    ["Kindly select the polish value"]: "Kindly select the polish value",
    ["Kindly select the symmetry"]: "Kindly select the symmetry",
    ["Kindly select the fluorescence"]: "Kindly select the fluorescence",
    ["Kindly select the girdle"]: "Kindly select the girdle",
    ["Kindly select the culet"]: "Kindly select the culet",
    ["Kindly select the culet condition"]: "Kindly select the culet condition",
    ["Crown Angle is compulsory"]: "Crown Angle is compulsory",
    ["Pavillion Angle is compulsory"]: "Pavillion Angle is compulsory",
    ["Comments are compulsory"]: "Comments are compulsory",
    ["Store Location is compulsory"]: "Store Location is compulsory",
    ["Employee Name is compulsory"]: "Employee Name is compulsory",
    ["Employee ID is compulsory"]: "Employee ID is compulsory",
    ["Employee ID is Invalid"]: "Employee ID is Invalid",
    ["Please select file."]: "Please select file.",
    ["Option 3"]: "Option 3",
    ["Golden"]: "Golden",
    ["FB"]: "FB",
    ["FBGNY"]: "FBGNY",
    ["Brown"]: "Brown",
    ["FL"]: "FL",
    ["VVS1"]: "VVS1",
    ["Excellent 1"]: "Excellent 1",
    ["Strong Blue"]: "Strong Blue",
    ["Medium Blue"]: "Medium Blue",
    ["Extremely"]: "Extremely",
    ["Do not include pricing informatin in comments."]:
      "Do not include pricing informatin in comments.",
    ["Video Url"]: "Video Url",
    ["No file chosen"]: "No file chosen",
    ["Please select delimiter."]: "Please select delimiter.",
    ["Diamond Import"]: "Diamond Import",
    ["RAPNET"]: "RAPNET",
    ["IDEX"]: "IDEX",
    ["POLYGON"]: "POLYGON",
    ["DIRECT LINK"]: "DIRECT LINK",
    ["Use Rapnet Instant Inventory Service."]:
      "Use Rapnet Instant Inventory Service.",
    ["Standard Diamond Form"]: "Standard Diamond Form",
    ["Diamond Importing"]: "Diamond Importing",
    ["Alphanumeric"]: "Alphanumeric",
    ["ProductName1"]: "ProductName1",
    ["Short title for the product. This will be displayed on product pages. If listing child products, please include color and size in the name to differentiate."]:
      "Short title for the product. This will be displayed on product pages. If listing child products, please include color and size in the name to differentiate.",
    ["Accessories"]: "Accessories",
    ["Tiaras"]: "Tiaras",
    ["Womans Fashion Bag"]: "Womans Fashion Bag",
    ["Atia 81"]: "Atia 81",
    ["Fashion Earrings"]: "Fashion Earrings",
    ["Chandelier"]: "Chandelier",
    ["Charms"]: "Charms",
    ["Beads"]: "Beads",
    ["Bracelets"]: "Bracelets",
    ["Product Name1.xls"]: "Product Name1.xls",
    ["Delivery Time"]: "Delivery Time",
    ["hours"]: "hours",
    ["Featured"]: "Featured",
    ["Product name is compulsory"]: "Product name is compulsory",
    ["Style Number is compulsory"]: "Style Number is compulsory",
    ["Retail Description is compulsory"]: "Retail Description is compulsory",
    ["Retail Price is compulsory"]: "Retail Price is compulsory",
    ["Kindly select the Material Type"]: "Kindly select the Material Type",
    ["Kindly select the Category"]: "Kindly select the Category",
    ["Kindly select all the necessary Field from essential"]:
      "Kindly select all the necessary Field from essential",
    ["Fixed"]: "Fixed",
    ["WholeSale"]: "WholeSale",
    ["Diamond Upload"]: "Diamond Upload",
    ["Please select image file only."]: "Please select image file only.",
    ["Please select zip file only."]: "Please select zip file only.",
    ["Please select .csv|xls|txt|json file only."]:
      "Please select .csv|xls|txt|json file only.",
    ["Please select currency."]: "Please select currency.",
    ["Please select rounding."]: "Please select rounding.",
    ["Please enter default markup."]: "Please enter default markup.",
    ["Please enter default markup value less then 100%."]:
      "Please enter default markup value less then 100%.",
    ["Select Currency"]: "Select Currency",
    ["Select Rounding"]: "Select Rounding",
    ["Collection name is compulsory"]: "Collection name is compulsory",
    ["Kindly select the Brand Name"]: "Kindly select the Brand Name",
    ["Need to select atleast one category"]:
      "Need to select atleast one category",
    ["Overnight Mountings Ring Builder"]: "Overnight Mountings Ring Builder",
    ["Data Ratings"]: "Data Ratings",
    ["Victor"]: "Victor",
    ["Manage Inventory"]: "Manage Inventory",
    ["Manage Location"]: "Manage Location",
    ["Vendor Type"]: "Vendor Type",
    ["Designer"]: "Designer",
    ["Watch Brand"]: "Watch Brand",
    ["Bridal Designer"]: "Bridal Designer",
    ["Connection Status"]: "Connection Status",
    ["Vendor Profile"]: "Vendor Profile",
    ["Why Choose Overnight?"]: "Why Choose Overnight?",
    ["Inventory"]: "Inventory",
    ["Page"]: "Page",
    ["of"]: "of",
    ["pages"]: "pages",
    ["per page"]: "per page",
    ["Product Deactivation Guidelines"]: "Product Deactivation Guidelines",
    ["Activate/Deactivate All"]: "Activate/Deactivate All",
    ["Products"]: "Products",
    ["Jack"]: "Jack",
    ["Lucy"]: "Lucy",
    ["One"]: "One",
    ["Two"]: "Two",
    ["Male"]: "Male",
    ["Female"]: "Female",
    ["Save Changes"]: "Save Changes",
    ["Trade Show Appointment"]: "Trade Show Appointment",
    ["Preferred"]: "Preferred",
    ["Questions"]: "Questions",
    ["I Am Interested In Placing Your Inventory On My Website."]:
      "I Am Interested In Placing Your Inventory On My Website.",
    ["Connected"]: "Connected",
    ["Request Update"]: "Request Update",
    ["Image Preview"]: "Image Preview",
    ["By Size"]: "By Size",
    ["By Price"]: "By Price",
    ["United States - Dollar - $"]: "United States - Dollar - $",
    ["Great Britan - Pound - £"]: "Great Britan - Pound - £",
    ["Australia - Dollar - $"]: "Australia - Dollar - $",
    ["size"]: "size",
    ["Custom"]: "Custom",
    ["US Dollars"]: "US Dollars",
    ["Great Britain Pound"]: "Great Britain Pound",
    ["Apex Diamonds"]: "Apex Diamonds",
    ["Asscher"]: "Asscher",
    ["Cushion"]: "Cushion",
    ["Best Diamonds"]: "Best Diamonds",
    ["Signature Diamonds"]: "Signature Diamonds",
    ["Normal Diamonds"]: "Normal Diamonds",
    ["Diamond Link URL"]: "Diamond Link URL",
    ["Automatically Accept New Diamond Dealers"]:
      "Automatically Accept New Diamond Dealers",
    ["When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."]:
      "When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account.",
    ["Sort Search Results Initially By"]: "Sort Search Results Initially By",
    ["Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."]:
      "Change this value to modify the initial sorting field of the search results. Leave blank for default value (none).",
    ["Order Search Results Initially By"]: "Order Search Results Initially By",
    ["Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."]:
      "Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none).",
    ["Show In House Diamonds Column with SKU (for New GF Link Only)"]:
      "Show In House Diamonds Column with SKU (for New GF Link Only)",
    ["Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."]:
      "Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page.",
    ["Show In House Diamonds First"]: "Show In House Diamonds First",
    ["Choose whether to display in house diamonds first in the search result."]:
      "Choose whether to display in house diamonds first in the search result.",
    ["Show GIA report check link"]: "Show GIA report check link",
    ["When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."]:
      "When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image.",
    ["Show Advance options as Default in Diamond Search"]:
      "Show Advance options as Default in Diamond Search",
    ["Show Certificate in Diamond Search"]:
      "Show Certificate in Diamond Search",
    ["Choose whether to display a link to the diamond certificate or not."]:
      "Choose whether to display a link to the diamond certificate or not.",
    ["Show EGL Certificate Variants"]: "Show EGL Certificate Variants",
    ["Choose whether to display EGL Certificate Variants or not."]:
      "Choose whether to display EGL Certificate Variants or not.",
    ["Show Request Certificate"]: "Show Request Certificate",
    ["When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."]:
      "When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form.",
    ["Disable Certificate Links in Diamond Search"]:
      "Disable Certificate Links in Diamond Search",
    ["Hide certificates and show only request form."]:
      "Hide certificates and show only request form.",
    ["Show Color Items out of Range"]: "Show Color Items out of Range",
    ["Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."]:
      "Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’.",
    ["Show Clarity Items out of Range"]: "Show Clarity Items out of Range",
    ["Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."]:
      "Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”.",
    ["Accept Total Price for Diamond"]: "Accept Total Price for Diamond",
    ["When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."]:
      "When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price.",
    ["Choose whether to display the link which allows the customer the ability to Drop a Hint."]:
      "Choose whether to display the link which allows the customer the ability to Drop a Hint.",
    ["Email A Friend"]: "Email A Friend",
    ["Choose whether to display the link which allows the customer the ability to Email A Friend."]:
      "Choose whether to display the link which allows the customer the ability to Email A Friend.",
    ["Show Origin On Diamond Search"]: "Show Origin On Diamond Search",
    ["Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."]:
      "Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created).",
    ["Show vendor comments on details page"]:
      "Show vendor comments on details page",
    ["Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."]:
      "Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results.",
    ["Show Contact Number"]: "Show Contact Number",
    ["Choose whether to use show contact Number or not."]:
      "Choose whether to use show contact Number or not.",
    ["Choose whether to use show addresses or not."]:
      "Choose whether to use show addresses or not.",
    ["Alternate diamond details link URL"]:
      "Alternate diamond details link URL",
    ["Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value.",
    ["Use Custom Detail URL for Diamonds?"]:
      "Use Custom Detail URL for Diamonds?",
    ["Choose whether to enable or disable the “Alternate diamond details link URL” option."]:
      "Choose whether to enable or disable the “Alternate diamond details link URL” option.",
    ["Show Diamond Prices"]: "Show Diamond Prices",
    ["You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."]:
      "You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed.",
    ["Markup Your Own Inventory"]: "Markup Your Own Inventory",
    ["Choose whether to apply markup on diamond inventory uploaded by you."]:
      "Choose whether to apply markup on diamond inventory uploaded by you.",
    ["Show Price Per Carat"]: "Show Price Per Carat",
    ["Choose whether to display the price per carat value on the diamond details page “Specifications” tab."]:
      "Choose whether to display the price per carat value on the diamond details page “Specifications” tab.",
    ["Show Instagram Share"]: "Show Instagram Share",
    ["Choose whether to display Instagram Share or not."]:
      "Choose whether to display Instagram Share or not.",
    ["Call to Action Button"]: "Call to Action Button",

    ["AddToCart Button"]: "AddToCart Button",
    ["Schedule a Viewing"]: "Schedule a Viewing",
    ["Alternate Shopping Cart URL"]: "Alternate Shopping Cart URL",

    ["Show AddToCart Button On Search Page"]:
      "Show AddToCart Button On Search Page",
    ["Choose whether to display the “Add to Cart” button on Search Page or not."]:
      "Choose whether to display the “Add to Cart” button on Search Page or not.",
    ["Request Diamond"]: "Request Diamond",
    ["Compare Diamond"]: "Compare Diamond",
    ["Select Option"]: "Select Option",
    ["Ring Builder Version I"]: "Ring Builder Version I",
    ["Ring Builder Version II"]: "Ring Builder Version II",
    ["Ring Builder Version III"]: "Ring Builder Version III",
    ["Ring Builder Version IV"]: "Ring Builder Version IV",
    ["Ring Builder Version V"]: "Ring Builder Version V",
    ["Earrings"]: "Earrings",
    ["18k Yellow Gold"]: "18k Yellow Gold",
    ["18k Rose Gold"]: "18k Rose Gold",
    ["AUD"]: "AUD",
    ["GBP (Pound)"]: "GBP (Pound)",
    ["USD"]: "USD",
    ["Items View"]: "Items View",
    ["List View"]: "List View",
    ["We offer over 50,000 mountings and Semi-mounts to choose from. Overnight has one of the largest"]:
      "We offer over 50,000 mountings and Semi-mounts to choose from. Overnight has one of the largest",
    ["In Stock"]: "In Stock",
    ["inventories in the industry. All of our"]:
      "inventories in the industry. All of our",
    ["mountings and Semi-mounts can be returned within 10 business days for a full refund. Our entire line is offered in Sterling, 10k, 14k, 18k, 950 Platinum and 950 Palladium. All gold mountings and semi mounts can be ordered in colors White, Yellow or Pink. Castings available within 3 business days, out of stock Polished mountings available within 3-5 business days, and semi mount and finished goods are available within 5-7 business days. Any one of our 50,000+ styles can be ordered as"]:
      "mountings and Semi-mounts can be returned within 10 business days for a full refund. Our entire line is offered in Sterling, 10k, 14k, 18k, 950 Platinum and 950 Palladium. All gold mountings and semi mounts can be ordered in colors White, Yellow or Pink. Castings available within 3 business days, out of stock Polished mountings available within 3-5 business days, and semi mount and finished goods are available within 5-7 business days. Any one of our 50,000+ styles can be ordered as",
    ["Authentix"]: "Authentix",
    ["Samples. Any style can be modified in any way to meet your customers needs. Overnight's talented"]:
      "Samples. Any style can be modified in any way to meet your customers needs. Overnight's talented",
    ["custom crafted department"]: "custom crafted department",
    ["can create anything as a new model using CAD and Hand Carved. We employ some of the most talented setters in the industry that can set your stone(s). We offer 7 different diamond qualities including black diamonds and Champagne diamonds. Our customer service department is extremely knowledgeable in all aspects of jewelry and will assist you in a friendly and professional manner. Overnight's product development team is always creating new styles from basics to cutting edge fashion designs ensuring that you will find what you are looking for. All of our Mountings are proudly manufactured in NY and go through a stringent quality control process before leaving our facility, ensuring that you get a great product in a timely manner. Overnight reserves the right to refuse or recall any product information, images or content being used on customer websites and catalogs"]:
      "can create anything as a new model using CAD and Hand Carved. We employ some of the most talented setters in the industry that can set your stone(s). We offer 7 different diamond qualities including black diamonds and Champagne diamonds. Our customer service department is extremely knowledgeable in all aspects of jewelry and will assist you in a friendly and professional manner. Overnight's product development team is always creating new styles from basics to cutting edge fashion designs ensuring that you will find what you are looking for. All of our Mountings are proudly manufactured in NY and go through a stringent quality control process before leaving our facility, ensuring that you get a great product in a timely manner. Overnight reserves the right to refuse or recall any product information, images or content being used on customer websites and catalogs",
    ["Please Select Manufacturer"]: "Please Select Manufacturer",
    ["Please select markup"]: "Please select markup",
    ["Please Enter Min Range"]: "Please Enter Min Range",
    ["Please Enter Max Range"]: "Please Enter Max Range",
    ["Please Enter Markup"]: "Please Enter Markup",
    ["Malaysia - Riggit - $"]: "Malaysia - Riggit - $",
    ["New Zealand - Dollar - $"]: "New Zealand - Dollar - $",
    ["Canadian - Dollar - $"]: "Canadian - Dollar - $",
    ["Singapore - Dollar - S$"]: "Singapore - Dollar - S$",
    ["Chinese - Yuan - ¥"]: "Chinese - Yuan - ¥",
    ["Korean - Won - ₩"]: "Korean - Won - ₩",
    ["Denmark - Danish Krone - kr."]: "Denmark - Danish Krone - kr.",
    ["South African - Rand - R"]: "South African - Rand - R",
    ["Europe - Euro - €"]: "Europe - Euro - €",
    ["Swiss - Franc - CHf"]: "Swiss - Franc - CHf",
    ["Swedish Krona - Sweden - SEK"]: "Swedish Krona - Sweden - SEK",
    ["Diamond Link Shopping Cart Configuration Instructions"]:
      "Diamond Link Shopping Cart Configuration Instructions",
    ["We will be sending parameters to the page on your website as below url"]:
      "We will be sending parameters to the page on your website as below url",
    ["Example Url"]: "Example Url",
    ["To Get Diamond Detail"]: "To Get Diamond Detail",
    ["Click Here to access API to get diamond detail and checkout how it works online."]:
      "Click Here to access API to get diamond detail and checkout how it works online.",
    ["to access API to get diamond detail and checkout how it works online."]:
      "to access API to get diamond detail and checkout how it works online.",
    ["Click Here"]: "Click Here",
    ["Below is the list of parameters with respective possible values which can be supplied to each parameter."]:
      "Below is the list of parameters with respective possible values which can be supplied to each parameter.",
    ["DiamondId"]: "DiamondId",
    ["Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)"]:
      "Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)",
    ["Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."]:
      "Pass GemFind # which is assigned to your account. For Example, 720 for GemFind.",
    ["You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website."]:
      "You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website.",
    ["DealerLink"]: "DealerLink",
    ["Ring Builder Shopping Cart Configuration Instructions"]:
      "Ring Builder Shopping Cart Configuration Instructions",
    ["To Get Setting Detail"]: "To Get Setting Detail",
    ["to access API to get setting detail and checkout how it works online."]:
      "to access API to get setting detail and checkout how it works online.",
    ["SettingId"]: "SettingId",
    ["Pass setting number of setting. (SettingID from the query string parameter as shown on example url)"]:
      "Pass setting number of setting. (SettingID from the query string parameter as shown on example url)",
    ["Masterlink Shopping cart integration instructions"]:
      "Masterlink Shopping cart integration instructions",
    ["To Get Product Detail"]: "To Get Product Detail",
    ["You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart."]:
      "You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart.",
    ["JewelryID"]: "JewelryID",
    ["Pass JewelryID # for which you want to get data."]:
      "Pass JewelryID # for which you want to get data.",
    ["Stud Builder Shopping cart integration instructions"]:
      "Stud Builder Shopping cart integration instructions",
    ["There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones"]:
      "There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones",
    ["To Get Stud Mounting Detail"]: "To Get Stud Mounting Detail",
    ["to access API to get Stud Mounting detail and checkout how it works online."]:
      "to access API to get Stud Mounting detail and checkout how it works online.",
    ["Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)"]:
      "Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)",
    ["Jewelry Category"]: "Jewelry Category",
    ["Designer Category"]: "Designer Category",
    ["Bridal Category"]: "Bridal Category",
    ["SKU Prefix"]: "SKU Prefix",
    ["Category Mapping"]: "Category Mapping",
    ["Column Mapping"]: "Column Mapping",
    ["GemFind Category"]: "GemFind Category",
    ["Website Category"]: "Website Category",
    ["Dealer Stock Number"]: "Dealer Stock Number",
    ["Product Description"]: "Product Description",
    ["Designers Mapping"]: "Designers Mapping",
    ["GemFind Designers"]: "GemFind Designers",
    ["Bangles"]: "Bangles",
    ["Big & important Bracelets"]: "Big & important Bracelets",
    ["Chain Bracelets"]: "Chain Bracelets",
    ["Necklaces"]: "Necklaces",
    ["Chains"]: "Chains",
    ["Sync Products To Your Website"]: "Sync Products To Your Website",
    ["Completed"]: "Completed",
    ["Sync Products"]: "Sync Products",
    ["Force Full Sync"]: "Force Full Sync",
    ["Detail"]: "Detail",
    ["Total Products Processed"]: "Total Products Processed",
    ["Out Going Data Map"]: "Out Going Data Map",
    ["API Url"]: "API Url",
    ["Enable Sync"]: "Enable Sync",
    ["Attrubute Mapping"]: "Attrubute Mapping",
    ["Create XML"]: "Create XML",
    ["Notifications"]: "Notifications",
    ["registered with the website"]: "registered with the website",
    ["Jewelry vendors"]: "Jewelry vendors",
    ["Active Jewelry"]: "Active Jewelry",
    ["Diamond Vendors"]: "Diamond Vendors",
    ["Active Diamonds"]: "Active Diamonds",
    ["Contact Us"]: "Contact Us",
    ["Favorites"]: "Favorites",
    ["New & Recently Updated Vendors"]: "New & Recently Updated Vendors",
    ["View More"]: "View More",
    ["Quick Links"]: "Quick Links",
    ["View My Retailers"]: "View My Retailers",
    ["Update Inventory"]: "Update Inventory",
    ["Invite A Retailer"]: "Invite A Retailer",
    ["Manage Shows"]: "Manage Shows",
    ["Ratings"]: "Ratings",
    ["The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated."]:
      "The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated.",
    ["For details on how you can improve your score, please click here to view this document."]:
      "For details on how you can improve your score, please click here to view this document.",
    ["Import Your Data"]: "Import Your Data",
    ["Upload Your Jewelry & Diamonds"]: "Upload Your Jewelry & Diamonds",
    ["Import Diamonds"]: "Import Diamonds",
    ["Upload Diamond"]: "Upload Diamond",
    ["Import Jewelry"]: "Import Jewelry",
    ["Upload Jewelry"]: "Upload Jewelry",
    ["Video/Images"]: "Video/Images",
    ["Email"]: "Email",
    ["Friend's Name"]: "Friend's Name",
    ["Friend's Email"]: "Friend's Email",
    ["Buyer Name is compulsory"]: "Buyer Name is compulsory",
    ["Book Appointment"]: "Book Appointment",
    ["Appointment Description"]: "Appointment Description",
    ["Gemfind"]: "Gemfind",
    ["Your recipient will recieve the details on your selected diamonds"]:
      "Your recipient will recieve the details on your selected diamonds",
    ["Message"]: "Message",
    ["Please select video file only."]: "Please select video file only.",
    ["Is Lab Created"]: "Is Lab Created",
    ["Please Enter Link"]: "Please Enter Link",
    ["Please Enter Text"]: "Please Enter Text",
    ["View /Edit Mapping Columns"]: "View /Edit Mapping Columns",
    ["Preparing Your Data"]: "Preparing Your Data",
    ["Resubmit"]: "Resubmit",
    ["Re-Activate"]: "Re-Activate",
    ["Fancy Color Diamond Link Emails"]: "Fancy Color Diamond Link Emails",
    ["Diamond Link Emails"]: "Diamond Link Emails",
    ["Time pieces"]: "Time pieces",
    ["Click Here for GFLINK ECommerce Link Administration"]:
      "Click Here for GFLINK ECommerce Link Administration",
    ["View/Update Your Online Dealer Inventory List"]:
      "View/Update Your Online Dealer Inventory List",
    ["Change Inventory Mark-Up Values for Markup BY PRICE"]:
      "Change Inventory Mark-Up Values for Markup BY PRICE",
    ["Update Search Page Results Appearance"]:
      "Update Search Page Results Appearance",
    ["Update Search Page Results Preferences"]:
      "Update Search Page Results Preferences",
    ["For Advanced Users"]: "For Advanced Users",
    ["Change Your Shipping Charge"]: "Change Your Shipping Charge",
    ["Your GFLINK Addresses are"]: "Your GFLINK Addresses are",
    ["ADMIN NOTE"]: "ADMIN NOTE",
    ["Your GFLink addresses MUST contain the correct domain name, http://platform.stage.jewelcloud.com. Using http://platform.stage.jewelcloud.com or any variation of gemfind.com is NOT correct and no longer functions. Please make sure your GFWatchLink URL is correct. Use the URLs below. The links below display your inventory"]:
      "Your GFLink addresses MUST contain the correct domain name, http://platform.stage.jewelcloud.com. Using http://platform.stage.jewelcloud.com or any variation of gemfind.com is NOT correct and no longer functions. Please make sure your GFWatchLink URL is correct. Use the URLs below. The links below display your inventory",
    ["Marked Up"]: "Marked Up",
    ["a % determined by the Watch'S PRICE."]:
      "a % determined by the Watch'S PRICE.",
    ["Search Only"]: "Search Only",
    ["Watch Configurator"]: "Watch Configurator",
    ["Procedure to implement Watch Configurator: To integrate the Watch Configurator directly into one of your web pages on your website, you will need to follow the general procedure below. This procedure will vary depending upon the technology your website uses, and your web developer will need to make adjustments accordingly. This will require custom website development work on your end. You basically have two choices when it comes to seamlessly including Watch Configurator in your website: FRAMEs or IFRAMEs."]:
      "Procedure to implement Watch Configurator: To integrate the Watch Configurator directly into one of your web pages on your website, you will need to follow the general procedure below. This procedure will vary depending upon the technology your website uses, and your web developer will need to make adjustments accordingly. This will require custom website development work on your end. You basically have two choices when it comes to seamlessly including Watch Configurator in your website: FRAMEs or IFRAMEs.",
    ["FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch Configurator may easily be included as one of the FRAME contents. This is one of the simplest methods of embedding results, however it is not as attractive as the other methods and may not be a feasible option for you if you do not already use FRAMEs. Scrollbars may be prgrammatically eliminated for a cleaner look."]:
      "FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch Configurator may easily be included as one of the FRAME contents. This is one of the simplest methods of embedding results, however it is not as attractive as the other methods and may not be a feasible option for you if you do not already use FRAMEs. Scrollbars may be prgrammatically eliminated for a cleaner look.",
    ["IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding to accomodate Netscape) and place it on your page where you want the Watch Configurator to apear. This will look better than a FRAME defined as part of a FRAMESET. Some attention to the size of the IFRAME must be paid. The IFRAME window is not programmatically 'resizable' once it has been defined on a page, therefore you must create the IFRAME with a HEIGHT and WIDTH that will accommodate the entire contents of the Watch Configurator. The number of results/rows returned by the Watch Configurator is constant and will ensure a constant height (and fit) for your IFRAME window."]:
      "IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding to accomodate Netscape) and place it on your page where you want the Watch Configurator to apear. This will look better than a FRAME defined as part of a FRAMESET. Some attention to the size of the IFRAME must be paid. The IFRAME window is not programmatically 'resizable' once it has been defined on a page, therefore you must create the IFRAME with a HEIGHT and WIDTH that will accommodate the entire contents of the Watch Configurator. The number of results/rows returned by the Watch Configurator is constant and will ensure a constant height (and fit) for your IFRAME window.",
    ["Search by GEMFIND INVENTORY ID #"]: "Search by GEMFIND INVENTORY ID #",
    ["Enter GEMFIND ID # and CLICK 'GO'"]: "Enter GEMFIND ID # and CLICK 'GO'",
    ["Go"]: "Go",
    ["Search by Dealer's Inventory #"]: "Search by Dealer's Inventory #",
    ["Enter Dealer Inventory # and CLICK 'GO'"]:
      "Enter Dealer Inventory # and CLICK 'GO'",
    ["A & M Diamonds"]: "A & M Diamonds",
    ["A & W Gems"]: "A & W Gems",
    ["A & F Gems"]: "A & F Gems",
    ["Pendant Builder Shopping cart integration instructions"]:
      "Pendant Builder Shopping cart integration instructions",
    ["To Get Pendant Mounting Detail"]: "To Get Pendant Mounting Detail",
    ["Please Enter Password."]: "Please Enter Password.",
    ["Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"]:
      "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character",
    ["Please Enter Confirm Password."]: "Please Enter Confirm Password.",
    ["Password And Confirm Password Do Not Match."]:
      "Password And Confirm Password Do Not Match.",
    ["Server Up-Time"]: "Server Up-Time",
    ["Uptime info not available - please try reloading this page"]:
      "Uptime info not available - please try reloading this page",
    ["Select Client Name"]: "Select Client Name",
    ["Active - No Profile"]: "Active - No Profile",
    ["Select Type"]: "Select Type",
    ["Select Retailer Status"]: "Select Retailer Status",
    ["API Url is compulsory"]: "API Url is compulsory",
    ["API Key is compulsory"]: "API Key is compulsory",
    ["Provide Proper DealerID"]: "Provide Proper DealerID",
    ["account ?"]: "account ?",
    ["You have select"]: "You have select",
    ["Please select .png file only."]: "Please select .png file only.",
    ["Please Select Correct Image Size."]: "Please Select Correct Image Size.",
    ["All Dealer"]: "All Dealer",
    ["Please Enter Only Alphabet."]: "Please Enter Only Alphabet.",
    ["Please Select Only Images."]: "Please Select Only Images.",
    ["API Access Token"]: "API Access Token",
    ["Set Initial Filter Settings"]: "Set Initial Filter Settings",
    ["Set Your Available Diamonds"]: "Set Your Available Diamonds",
    ["This will control default diamond filters when users initially visit your DiamondLink."]:
      "This will control default diamond filters when users initially visit your DiamondLink.",
    ["This will control which diamonds are available in your DiamondLink."]:
      "This will control which diamonds are available in your DiamondLink.",
    ["Lab Setting"]: "Lab Setting",
    ["Mined Setting"]: "Mined Setting",
    ["Ct."]: "Ct.",
    ["Please select zip file only were in Compress images together & upload in a .zip format."]:
      "Please select zip file only were in Compress images together & upload in a .zip format.",
    ["Password does not match"]: "Password does not match",
    ["Name is compulsory"]: "Name is compulsory",
    ["Name is invalid"]: "Name is invalid",
    ["Email is compulsory"]: "Email is compulsory",
    ["Confirm Password is compulsory"]: "Confirm Password is compulsory",
    ["Logins"]: "Logins",
    ["User Role Detail"]: "User Role Detail",
    ["New User"]: "New User",
    ["Add New User"]: "Add New User",
    ["Access Level"]: "Access Level",
    ["Edit User"]: "Edit User",
    ["Delete User"]: "Delete User",
    ["Last Login"]: "Last Login",
    ["User Since"]: "User Since",
    ["Select Account type"]: "Select Account type",
    ["Company 1"]: "Company 1",
    ["Company 2"]: "Company 2",
    ["Company 3"]: "Company 3",
    ["Apply"]: "Apply",
    ["Language Selection for Platform and Tools"]:
      "Language Selection for Platform and Tools",
    ["Default Language Is"]: "Default Language Is",
    ["Select Language"]: "Select Language",
    ["Currency Selection for Platform and Tools"]:
      "Currency Selection for Platform and Tools",
    ["Default Currency Is"]: "Default Currency Is",
    ["Please select Image File Only"]: "Please select Image File Only",
    ["Email Address is compulsory"]: "Email Address is compulsory",
    ["Email Address is Invalid"]: "Email Address is Invalid",
    ["Image Name"]: "Image Name",
    ["Company Detail"]: "Company Detail",
    ["Web Site"]: "Web Site",
    ["Company Bio"]: "Company Bio",
    ["Update Profile"]: "Update Profile",
    ["Advertising Samples"]: "Advertising Samples",
    ["Social Integrations Settings"]: "Social Integrations Settings",
    ["Website's Facebook App ID Setup"]: "Website's Facebook App ID Setup",
    ["Masterlink Facebook App Setup"]: "Masterlink Facebook App Setup",
    ["MasterLink Facebook canvas App ID"]: "MasterLink Facebook canvas App ID",
    ["MasterLink Facebook canvas App URL"]:
      "MasterLink Facebook canvas App URL",
    ["RingBuilder Facebook App Setup"]: "RingBuilder Facebook App Setup",
    ["RingBuilder Facebook canvas App ID"]:
      "RingBuilder Facebook canvas App ID",
    ["RingBuilder Facebook canvas App URL"]:
      "RingBuilder Facebook canvas App URL",
    ["StudBuilder Facebook App Setup"]: "StudBuilder Facebook App Setup",
    ["StudBuilder Facebook canvas App ID"]:
      "StudBuilder Facebook canvas App ID",
    ["StudBuilder Facebook canvas App URL"]:
      "StudBuilder Facebook canvas App URL",
    ["PendantBuilder Facebook App Setup"]: "PendantBuilder Facebook App Setup",
    ["PendantBuilder Facebook canvas App ID"]:
      "PendantBuilder Facebook canvas App ID",
    ["PendantBuilder Facebook canvas App URL"]:
      "PendantBuilder Facebook canvas App URL",
    ["Diamondlink Facebook App Setup"]: "Diamondlink Facebook App Setup",
    ["DiamondLink Facebook canvas App ID"]:
      "DiamondLink Facebook canvas App ID",
    ["DiamondLink Facebook canvas App URL"]:
      "DiamondLink Facebook canvas App URL",
    ["Basic Information"]: "Basic Information",
    ["Notiﬁcation Settings"]: "Notiﬁcation Settings",
    ["Choose what you want to be notiﬁed about and where."]:
      "Choose what you want to be notiﬁed about and where.",
    ["Inquiries"]: "Inquiries",
    ["New Favorites List"]: "New Favorites List",
    ["New Favorites"]: "New Favorites",
    ["Vendor Requests"]: "Vendor Requests",
    ["Send Upload Report"]: "Send Upload Report",
    ["Purchase Orders"]: "Purchase Orders",
    ["Sales Orders"]: "Sales Orders",
    ["Ignore blank uploads"]: "Ignore blank uploads",
    ["SMTP Settings"]: "SMTP Settings",
    ["Send e-mails from your own address."]:
      "Send e-mails from your own address.",
    ["Mail Server"]: "Mail Server",
    ["Port Number"]: "Port Number",
    ["Server Requires Authentication"]: "Server Requires Authentication",
    ["Use SSL"]: "Use SSL",
    ["Test Your SMTP Settings"]: "Test Your SMTP Settings",
    ["TEST"]: "TEST",
    ["Add Map Here"]: "Add Map Here",
    ["Location 1"]: "Location 1",
    ["Address"]: "Address",
    ["Zip"]: "Zip",
    ["Location E-Mail"]: "Location E-Mail",
    ["Location Phone"]: "Location Phone",
    ["Update Location"]: "Update Location",
    ["Hours of Operation"]: "Hours of Operation",
    ["Sun"]: "Sun",
    ["Mon"]: "Mon",
    ["Tues"]: "Tues",
    ["Wed"]: "Wed",
    ["Thu"]: "Thu",
    ["Fri"]: "Fri",
    ["Sat"]: "Sat",
    ["Location QR Code"]: "Location QR Code",
    ["QR Code Generator"]: "QR Code Generator",
    ["View Inventory"]: "View Inventory",
    ["Please Enter Min Price."]: "Please Enter Min Price.",
    ["Please Enter Max Price."]: "Please Enter Max Price.",
    ["Please Enter Markup."]: "Please Enter Markup.",
    ["Confirmation"]: "Confirmation",
    ["Scheduled Reminder"]: "Scheduled Reminder",
    ["Tier Name is Compulsory"]: "Tier Name is Compulsory",
    ["Tier Value is Compulsory"]: "Tier Value is Compulsory",
    ["First Tier Value is Compulsory"]: "First Tier Value is Compulsory",
    ["Selecting Any Field is compulsory"]: "Selecting Any Field is compulsory",
    ["Tier Type"]: "Tier Type",
    ["Tier One"]: "Tier One",
    ["Tier Two"]: "Tier Two",
    ["Enter Tier Name"]: "Enter Tier Name",
    ["View Location"]: "View Location",
    ["Country is compulsory"]: "Country is compulsory",
    ["Country is invalid"]: "Country is invalid",
    ["Contact name is invalid"]: "Contact name is invalid",
    ["Add to Cart Report"]: "Add to Cart Report",
    ["Wish List Report"]: "Wish List Report",
    ["Please Enter Message."]: "Please Enter Message.",
    ["View My"]: "View My",
    ["Attribute is compulsory"]: "Attribute is compulsory",
    ["Please Select csv file only"]: "Please Select csv file only",
    ["Reset Mapping"]: "Reset Mapping",
    ["Vary Pricing By Finger Size"]: "Vary Pricing By Finger Size",
    ["Schedule Reminder"]: "Schedule Reminder",
    ["Select Finger Size"]: "Select Finger Size",
    ["Video Type"]: "Video Type",
    ["This option will override the data."]:
      "This option will override the data.",
    ["Load Image From URL"]: "Load Image From URL",
    ["Trade show is compulsory"]: "Trade show is compulsory",
    ["Sales rep is compulsory"]: "Sales rep is compulsory",
    ["Appointment date is compulsory"]: "Appointment date is compulsory",
    ["Buyer name is compulsory"]: "Buyer name is compulsory",
    ["Question is compulsory"]: "Question is compulsory",
    ["Time is compulsory"]: "Time is compulsory",
    ["Write the first message ...."]: "Write the first message ....",
    ["Post"]: "Post",
    ["Select Sales Rep"]: "Select Sales Rep",
    ["Location Details"]: "Location Details",
    ["Location Email"]: "Location Email",
    ["Sunday"]: "Sunday",
    ["AM to"]: "AM to",
    ["Wednesday"]: "Wednesday",
    ["No record found"]: "No record found",
    ["Select 1 Tier PageName"]: "Select 1 Tier PageName",
    ["Select 2 Tier PageName"]: "Select 2 Tier PageName",
    ["Select Location"]: "Select Location",
    ["Use"]: "Use",
    ["Select File"]: "Select File",
    ["Upload Images"]: "Upload Images",
    ["Use this Company Name,  and bio as my Brand name,  and Bio on my authorized Retailer websites."]:
      "Use this Company Name,  and bio as my Brand name,  and Bio on my authorized Retailer websites.",
    ["Click here to configure your different Brand marketing message for use on your authorized Retailer websites."]:
      "Click here to configure your different Brand marketing message for use on your authorized Retailer websites.",
    ["Since"]: "Since",
    ["Primary E-mail"]: "Primary E-mail",
    ["Primary Phone"]: "Primary Phone",
    ["Primary Contact Name"]: "Primary Contact Name",
    ["Company Logo"]: "Company Logo",
    ["Phone no is invalid"]: "Phone no is invalid",
    ["Change"]: "Change",
    ["Product Details"]: "Product Details",
    ["Jewelery Characteristics"]: "Jewelery Characteristics",
    ["SKU #"]: "SKU #",
    ["Style"]: "Style",
    ["Cost ($)"]: "Cost ($)",
    ["MSRP ($)"]: "MSRP ($)",
    ["Width (mm)"]: "Width (mm)",
    ["Thickness (mm)"]: "Thickness (mm)",
    ["Length (in)"]: "Length (in)",
    ["Weight (gm)"]: "Weight (gm)",
    ["Last Updated On"]: "Last Updated On",
    ["Description"]: "Description",
    ["Dealer Info"]: "Dealer Info",
    ["Dealer Information"]: "Dealer Information",
    ["Company"]: "Company",
    ["Contact Person"]: "Contact Person",
    ["Fax"]: "Fax",
    ["E-mail"]: "E-mail",
    ["Export All"]: "Export All",
    ["Please select .csv|xls|txt file only."]:
      "Please select .csv|xls|txt file only.",
    ["Lab Grown Diamond"]: "Lab Grown Diamond",
    ["Warning: Changing or modifying your"]:
      "Warning: Changing or modifying your",
    ["will cause the"]: "will cause the",
    ["to be reset: Click Yes to proceed"]: "to be reset: Click Yes to proceed",
    ["In Frame"]: "In Frame",
    ["Update Filters"]: "Update Filters",
    ["Invalid range"]: "Invalid range",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price and Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      "By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price and Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page.",
    ["You may choose to search by Cut Grade if desired. Table and Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "You may choose to search by Cut Grade if desired. Table and Depth searching is enabled as well as they are directly related to Cut Grade.",
    ["Please Select Default Markup"]: "Please Select Default Markup",
    ["Min Range should be less than 10 digit"]:
      "Min Range should be less than 10 digit",
    ["Max Range should be less than 10 digit"]:
      "Max Range should be less than 10 digit",
    ["Max Price should be greater than Min Price"]:
      "Max Price should be greater than Min Price",
    ["Cut/Shape"]: "Cut/Shape",
    ["MaxPrice Should be greater than MinPrice"]:
      "MaxPrice Should be greater than MinPrice",
    ["Please enter Max price up to two decimal places"]:
      "Please enter Max price up to two decimal places",
    ["Choose Whether you would like a particular metal type to be set as the default when viewing a setting in RingBuilder."]:
      "Choose Whether you would like a particular metal type to be set as the default when viewing a setting in RingBuilder.",
    ["Choose Whether to display the “Add to Cart” button or not."]:
      "Choose Whether to display the “Add to Cart” button or not.",
    ["14k White Gold"]: "14k White Gold",
    ["14k Yellow Gold"]: "14k Yellow Gold",
    ["14k Rose Gold"]: "14k Rose Gold",
    ["18k White Gold"]: "18k White Gold",
    ["Certificate"]: "Certificate",
    ["Negative Values is not Accepted"]: "Negative Values is not Accepted",
    ["Choose whether to display tool alignment as Center, Left, or Right position."]:
      "Choose whether to display tool alignment as Center, Left, or Right position.",
    ["Choose whether to display the share button (FaceBook, Twitter, etc.) on the diamond details page."]:
      "Choose whether to display the share button (FaceBook, Twitter, etc.) on the diamond details page.",
    ["Choose whether to show In Store diamond on the diamond inventory page."]:
      "Choose whether to show In Store diamond on the diamond inventory page.",
    ["Choose whether to use the popup of diamond details on the diamond inventory page."]:
      "Choose whether to use the popup of diamond details on the diamond inventory page.",
    ["Choose the number of setting displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "Choose the number of setting displayed on each page of the search results. For values other than shown, you must contact GemFind directly.",
    ["Choose whether to display settings based on the sort order of Price, Metal Type, or Style Number."]:
      "Choose whether to display settings based on the sort order of Price, Metal Type, or Style Number.",
    ["Choose whether to display sorting results in Ascending or Descending order."]:
      "Choose whether to display sorting results in Ascending or Descending order.",
    ["Approve"]: "Approve",
    ["Disapprove"]: "Disapprove",
    ["Connected and Pending Request Details"]:
      "Connected and Pending Request Details",
    ["Hierarchy"]: "Hierarchy",
    ["Date Range"]: "Date Range",
    ["MasterLink"]: "MasterLink",
    ["RingBuilder"]: "RingBuilder",
    ["StudBuilder"]: "StudBuilder",
    ["PendantBuilder"]: "PendantBuilder",
    ["WatchBuilder"]: "WatchBuilder",
    ["No Data"]: "No Data",
    ["Date Added"]: "Date Added",
    ["Diamond Charts"]: "Diamond Charts",
    ["UserIPAddress"]: "UserIPAddress",
    ["RetailerPrice"]: "RetailerPrice",
    ["Select View"]: "Select View",
    ["Available Online Only"]: "Available Online Only",
    ["Best Seller"]: "Best Seller",
    ["Clearance / Sale"]: "Clearance / Sale",
    ["Close Out/ Discontinue"]: "Close Out/ Discontinue",
    ["Add Campaign"]: "Add Campaign",
    ["Name is Compulsory."]: "Name is Compulsory.",
    ["Vendor is Compulsory."]: "Vendor is Compulsory.",
    ["Subject is Compulsory."]: "Subject is Compulsory.",
    ["Body is Compulsory."]: "Body is Compulsory.",
    ["Content is Compulsory."]: "Content is Compulsory.",
    ["Send From"]: "Send From",
    ["Mail Content"]: "Mail Content",
    ["Body"]: "Body",
    ["Content"]: "Content",
    ["Enter Name"]: "Enter Name",
    ["Change Password"]: "Change Password",
    ["Email Preferences"]: "Email Preferences",
    ["Social Integrations"]: "Social Integrations",
    ["Brand"]: "Brand",
    ["Solr Core Clients"]: "Solr Core Clients",
    ["Add color"]: "Add color",
    ["Extended Diamond Charts"]: "Extended Diamond Charts",
    ["Marketing List"]: "Marketing List",
    ["Api Endpoint and Authentication"]: "Api Endpoint and Authentication",
    ["Manage Vendor"]: "Manage Vendor",
    ["MarkUp"]: "MarkUp",
    ["Departments"]: "Departments",
    ["Manage vendor"]: "Manage vendor",
    ["My Vendor"]: "My Vendor",
    ["Network Vendor"]: "Network Vendor",
    ["Data"]: "Data",
    ["Import"]: "Import",
    ["Connection"]: "Connection",
    ["New Vendor"]: "New Vendor",
    ["Pending Request"]: "Pending Request",
    ["Popular Vendor"]: "Popular Vendor",
    ["Retailer Group"]: "Retailer Group",
    ["Manage Dealers"]: "Manage Dealers",
    ["Add Jewelry"]: "Add Jewelry",
    ["Jewelry MarkUp"]: "Jewelry MarkUp",
    ["Master Link Settings"]: "Master Link Settings",
    ["All Product"]: "All Product",
    ["My Product"]: "My Product",
    ["Shared Product"]: "Shared Product",
    ["Customers"]: "Customers",
    ["Customer Info"]: "Customer Info",
    ["Messages"]: "Messages",
    ["Registered:"]: "Registered:",
    ["Other Info"]: "Other Info",
    ["Most Active"]: "Most Active",
    ["Recent Conversations"]: "Recent Conversations",
    ["Contact Tags"]: "Contact Tags",
    ["Enter Tag Name"]: "Enter Tag Name",
    ["Top Designers"]: "Top Designers",
    ["New Customers"]: "New Customers",
    ["New Arrivals"]: "New Arrivals",
    ["Note Cannot Be Blank."]: "Note Cannot Be Blank.",
    ["Data Cannot Be Blank."]: "Data Cannot Be Blank.",
    ["Tag Cannot Be Blank."]: "Tag Cannot Be Blank.",
    ["Please Select Contact."]: "Please Select Contact.",
    ["Please Select Calendar."]: "Please Select Calendar.",
    ["Please Select Time."]: "Please Select Time.",
    ["Please Select Day."]: "Please Select Day.",
    ["Time is Compulsory."]: "Time is Compulsory.",
    ["Day is Compulsory."]: "Day is Compulsory.",
    ["Reply"]: "Reply",
    ["Edit Appointment"]: "Edit Appointment",
    ["Delete Appointment"]: "Delete Appointment",
    ["Approved Users"]: "Approved Users",
    ["Pending Users"]: "Pending Users",
    ["Reject"]: "Reject",
    ["Create New Pair"]: "Create New Pair",
    ["Enter Key"]: "Enter Key",
    ["Enter Value"]: "Enter Value",
    ["Jewelry Clicks Based on Retailer"]: "Jewelry Clicks Based on Retailer",
    ["Diamond Color Searches"]: "Diamond Color Searches",
    ["Diamond Size Searches"]: "Diamond Size Searches",
    ["Diamond CutGrade Searches"]: "Diamond CutGrade Searches",
    ["Diamond Price"]: "Diamond Price",
    ["Add New Email"]: "Add New Email",
    ["Enter Alias Address"]: "Enter Alias Address",
    ["Add New Recipient"]: "Add New Recipient",
    ["Jewelry Clicks Based on Metal Type"]:
      "Jewelry Clicks Based on Metal Type",
    ["Group Discount Name"]: "Group Discount Name",
    ["Discount Value"]: "Discount Value",
    ["Company Name (User Name)"]: "Company Name (User Name)",
    ["Group Name"]: "Group Name",
    ["Add Retailer"]: "Add Retailer",
    ["BrandGroup"]: "BrandGroup",
    ["Role"]: "Role",
    ["Address2"]: "Address2",
    ["Select GroupName"]: "Select GroupName",
    ["Retailer Number"]: "Retailer Number",
    ["Sales Person"]: "Sales Person",
    ["Price Group"]: "Price Group",
    ["Telephone"]: "Telephone",
    ["FTP Location"]: "FTP Location",
    ["FTP User Password"]: "FTP User Password",
    ["Update Detail"]: "Update Detail",
    ["Attribute Detail"]: "Attribute Detail",
    ["Mapping Help Docs"]: "Mapping Help Docs",
    ["Please Map Column First"]: "Please Map Column First",
    ["No information available for selected user."]:
      "No information available for selected user.",
    ["Display Video"]: "Display Video",
    ["Display Image"]: "Display Image",
    ["No File Chosen"]: "No File Chosen",
    ["Kindly select the Country"]: "Kindly select the Country",
    ["Enter Valid Dealer Id"]: "Enter Valid Dealer Id",
    ["Dealer Not Found"]: "Dealer Not Found",
    ["Send Mail To Retailer"]: "Send Mail To Retailer",
    ["Send Mail To Recipient"]: "Send Mail To Recipient",
    ["Both the entered password should match"]:
      "Both the entered password should match",
    ["New Password"]: "New Password",
    ["Change password"]: "Change password",
    ["BrandID"]: "BrandID",
    ["BrandName"]: "BrandName",
    ["Link"]: "Link",
    ["Manage Brand"]: "Manage Brand",
    ["Create Brand"]: "Create Brand",
    ["Insert Brand"]: "Insert Brand",
    ["Use Vendor Profile Logo"]: "Use Vendor Profile Logo",
    ["Fashion Designer"]: "Fashion Designer",
    ["Watch Designer"]: "Watch Designer",
    ["Showcase Image 1"]: "Showcase Image 1",
    ["Showcase Image 2"]: "Showcase Image 2",
    ["Showcase Image 3"]: "Showcase Image 3",
    ["Use In Showcase"]: "Use In Showcase",
    ["Designer Small Banner"]: "Designer Small Banner",
    ["Video URL"]: "Video URL",
    ["How To Upload Video"]: "How To Upload Video",
    ["URL"]: "URL",
    ["About Designer"]: "About Designer",
    ["Tag Line"]: "Tag Line",
    ["You can enter up to 100 characters."]:
      "You can enter up to 100 characters.",
    ["Shows"]: "Shows",
    ["Las Vegas Antique Jewelry"]: "Las Vegas Antique Jewelry",
    ["Booth #(s)"]: "Booth #(s)",
    ["Notes & Show Specials"]: "Notes & Show Specials",
    ["Save Show Details"]: "Save Show Details",
    ["User Avatar"]: "User Avatar",
    ["Current Password"]: "Current Password",
    ["Confirm New Password"]: "Confirm New Password",
    ["Location is compulsory"]: "Location is compulsory",
    ["Actions"]: "Actions",
    ["Reorder"]: "Reorder",
    ["PO Name is Compulsory"]: "PO Name is Compulsory",
    ["PO Notes is Compulsory"]: "PO Notes is Compulsory",
    ["Worksheet"]: "Worksheet",
    ["Use this to build and manage your products before sending a purchase order."]:
      "Use this to build and manage your products before sending a purchase order.",
    ["View All"]: "View All",
    ["Delete the"]: "Delete the",
    ["Cancel The"]: "Cancel The",
    ["Order"]: "Order",
    ["Generate PO"]: "Generate PO",
    ["PO Name"]: "PO Name",
    ["Purchase Order Summery"]: "Purchase Order Summery",
    ["Create RO"]: "Create RO",
    ["Shipping Address"]: "Shipping Address",
    ["Manufacturer Address"]: "Manufacturer Address",
    ["Order Notes"]: "Order Notes",
    ["4 Tier PageName"]: "4 Tier PageName",
    ["Add New Tier"]: "Add New Tier",
    ["Kind"]: "Kind",
    ["Modified"]: "Modified",
    ["Upload Media"]: "Upload Media",
    ["Add Folder"]: "Add Folder",
    ["Are You Sure You Want To Delete This Folder ?"]:
      "Are You Sure You Want To Delete This Folder ?",
    ["THIS CAN NOT BE UNDO"]: "THIS CAN NOT BE UNDO",
    ["Upload Your Media File"]: "THIS CAN NOT BE UNDO",
    ["Share marketing materials across your reatailer network."]:
      "Share marketing materials across your reatailer network.",
    ["Please Select Folder"]: "Please Select Folder",
    ["Total Mined Diamonds"]: "Total Mined Diamonds",
    ["Total LabGrown Diamonds"]: "Total LabGrown Diamonds",
    ["Total Color Diamonds"]: "Total Color Diamonds",
    ["Update List"]: "Update List",
    ["Our Signature Diamond"]: "Our Signature Diamond",
    ["Network Vendors"]: "Network Vendors",
    ["Stud Builder Email"]: "Stud Builder Email",
    ["Add to Worksheet"]: "Add to Worksheet",
    ["PriceMethod"]: "PriceMethod",
    ["Page Not access Please Contect to admin."]:
      "Page Not access Please Contect to admin.",
    ["Customer Profile"]: "Customer Profile",
    ["Add Details"]: "Add Details",
    ["Message Cannot Be Blank."]: "Message Cannot Be Blank.",
    ["Enter E-mail"]: "Enter E-mail",
    ["Top Designer"]: "Top Designer",
    ["Top Designer"]: "Top Designer",
    ["Select Trade Show"]: "Select Trade Show",
    ["I am interested in opening a new account with"]:
      "I am interested in opening a new account with",
    ["Pendant Builder Shopping cart configuration instructions"]:
      "Pendant Builder Shopping cart configuration instructions",
    ["Select Files"]: "Select Files",
    ["Insert Brands"]: "Insert Brands",
    ["Designer Logo"]: "Designer Logo",
    ["Update Brands"]: "Update Brands",
    ["Sample Showcase Preview"]: "Sample Showcase Preview",
    ["THIS CANNOT BE UNDONE"]: "THIS CANNOT BE UNDONE",
    ["If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor)."]:
      "If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor).",
    ["Delete The "]: "Delete The ",
    ["Brand "]: "Brand ",
    ["Fluor."]: "Fluor.",
    ["Measure."]: "Measure.",
    ["Sym."]: "Sym.",
    ["Brand Name"]: "Brand Name",
    ["Brand ID"]: "Brand ID",
    ["Advertisement Status"]: "Advertisement Status",
    ["Company name is Taken"]: "Company name is Taken",
    ["No Data Available."]: "No Data Available.",
    ["No Data Found"]: "No Data Found",
    ["Folder Name"]: "Folder Name",
    ["Reports"]: "Reports",
    ["Sorry, No Chart Data was found"]: "Sorry, No Chart Data was found",
    ["Diamond Price Based Add To Cart Report"]:
      "Diamond Price Based Add To Cart Report",
    ["Diamond Price Based Wish List Report"]:
      "Diamond Price Based Wish List Report",
    ["Diamond Vendor based Add To Cart Report"]:
      "Diamond Vendor based Add To Cart Report",
    ["Diamond Vendor based Wish List Report"]:
      "Diamond Vendor based Wish List Report",
    ["Jewelry Price Based Add To Cart Report"]:
      "Jewelry Price Based Add To Cart Report",
    ["Jewelry Price Based Wish List Report"]:
      "Jewelry Price Based Wish List Report",
    ["Jewelery Vendor based Add To Cart Report"]:
      "Jewelery Vendor based Add To Cart Report",
    ["Jewelery Vendor based Wish List Report"]:
      "Jewelery Vendor based Wish List Report",
    ["Updated Date"]: "Updated Date",
    ["MarkUp Overrride"]: "MarkUp Overrride",
    ["Percent"]: "Percent",
    ["This shows the collection on your own MasterLink, for preview purposes"]:
      "This shows the collection on your own MasterLink, for preview purposes",
    ["This shares the collection with your connected retailers"]:
      "This shares the collection with your connected retailers",
    ["Please Contact Gemfind to get Access."]:
      "Please Contact Gemfind to get Access.",
    ["Assecher"]: "Assecher",
    ["Request Certificate"]: "Request Certificate",
    ["The report for this diamond is not viewable online."]:
      "The report for this diamond is not viewable online.",
    ["Please contact us by telephone or the form below if you are interested in receiving more information about this diamond."]:
      "Please contact us by telephone or the form below if you are interested in receiving more information about this diamond.",
    ["Message to"]: "Message to",
    ["Your diamonds are deleted.However it will take some moments to remove them from cache.Deleted diamonds will be removed from this page as soon cache gets refreshed."]:
      "Your diamonds are deleted.However it will take some moments to remove them from cache.Deleted diamonds will be removed from this page as soon cache gets refreshed.",
      ["Your jewellery(s) are deleted.However it will take some moments to remove them from cache.Deleted jewellery(s) will be removed from this page as soon cache gets refreshed."]:
      "Your jewellery(s) are deleted.However it will take some moments to remove them from cache.Deleted jewellery(s) will be removed from this page as soon cache gets refreshed.",
      ["Not Authorized"]: "Not Authorized",
    ["Not deleted as those are not your diamonds."]:
      "Not deleted as those are not your diamonds.",
    ["Diamond List"]: "Diamond List",
    ["Pink"]: "Pink",
    ["Gray"]: "Gray",
    ["Black"]: "Black",
    ["Purple"]: "Purple",
    ["Chameleon"]: "Chameleon",
    ["Violet"]: "Violet",
    ["No items found"]: "No items found",
    ["reset"]: "reset",
    ["your filters"]: "your filters",
    ["Add To WorkSheet"]: "Add To WorkSheet",
    ["Add Products"]: "Add Products",
    ["WorkSheet Name"]: "WorkSheet Name",
    ["View WorkSheet"]: "View WorkSheet",
    ["Upload Certificates"]: "Upload Certificates",
    ["Zip Format"]: "Zip Format",
    ["No Data To Display"]: "No Data To Display",
    ["Customer added"]: "Customer added",
    ["as favorite through"]: "as favorite through",
    ["Create WorkSheet"]: "Create WorkSheet",
    ["Delete Worksheet"]: "Delete Worksheet",
    ["Worksheet Name"]: "Worksheet Name",
    ["Please Connect the Vendor for more information."]:
      "Please Connect the Vendor for more information.",
    ["Item Count"]: "Item Count",
    ["Reorder PO"]: "Reorder PO",
    ["View Order"]: "View Order",
    ["Cancel Order"]: "Cancel Order",
    ["T"]: "T",
    ["Total Item"]: "Total Item",
    ["SubTotal"]: "SubTotal",
    ["PO Total"]: "PO Total",
    ["Vendor to provide shipping/insurance info."]:
      "Vendor to provide shipping/insurance info.",
    ["Diamond Retailers"]: "Diamond Retailers",
    ["Jewelry Retailer & Permissions"]: "Jewelry Retailer & Permissions",
    ["Blocked Products"]: "Blocked Products",
    ["Product Restriction"]: "Product Restriction",
    ["Collection Access"]: "Collection Access",
    ["Product Access"]: "Product Access",
    ["Manage Retailer Access To Your Inventory"]:
      "Manage Retailer Access To Your Inventory",
    ["Set permissions for retailers to display your virtual inventory on their website."]:
      "Set permissions for retailers to display your virtual inventory on their website.",
    ["Auto Approval"]: "Auto Approval",
    ["Automatically approve retailers requesting to open an account."]:
      "Automatically approve retailers requesting to open an account.",
    ["Filter By Sales Rep"]: "Filter By Sales Rep",
    ["From"]: "From",
    ["To"]: "To",
    ["Upload a datasheet with the column header of StyleNumber. Include one SKU per row of each product you want to block from your chosen connected retailers. Those retailers selected for these SKU's, will not be given access to those products."]:
      "Upload a datasheet with the column header of StyleNumber. Include one SKU per row of each product you want to block from your chosen connected retailers. Those retailers selected for these SKU's, will not be given access to those products.",
    ["Connected Retailers"]: "Connected Retailers",
    ["Schedule New"]: "Schedule New",
    ["Print Current List"]: "Print Current List",
    ["Pending"]: "Pending",
    ["No notes added"]: "No notes added",
    ["Connected Vendors List"]: "Connected Vendors List",
    ["Run Campaign"]:"Run Campaign",
    ["Upload History Details"]:"Upload History Details"
  },
};
