import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Spin,
  Space,
  Modal
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../i18n/translate";
import RetailerLogo from "../../assets/images/retailer-logo.jpeg";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import communityVendorService from "../../services/community-vendor.service";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Moment from "moment";
import Conversations from "../contacts/conversation";
import Appointment from "../dashboard/Appointment";
import BlockedProduct from "../dashboard/BlockedProduct";
import ConversationsDashboard from "../dashboard/ConversationsDashboard";
import AppointmentsDashboard from "../dashboard/AppointmentsDashboard";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  setVendorConversationCount,
  setVendorConversationCountSent,
  setVendorConversationCountUnRead,
  setVendorAppointmentCount
} from "../../actions/Contact/Contact";

const JewelryRetailerPermission = (props) => {
  const [tableKey, setTableKey] = useState(false);
  const ContactDetails = useSelector((state) => state.contactReducer);
  const [switchValue, setswitchValue] = useState(true);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [SalesRep, setSalesRep] = useState([]);
  const [SalesRepId, setSalesRepId] = useState("");
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [disconnectSwitch, setDisconnectSwitch] = useState(true);
  const [filterDataObj, setFilterdata] = useState([]);
  const [jewelryList, setJewelryList] = useState([]);
  const [allJewelryList, setAlljewelryList] = useState([]);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [selectedTab, setSelectedTab] = useState("myretailer");
  const [switchCase, setSwitchCase] = useState(false);
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [sortColumnName, setSortColumnName] = useState("dealerCompany");
  const [sortType, setSortType] = useState("asc");
  const [statusColumn, setStatusColumn] = useState([]);
  const [statusColumn1, setStatusColumn1] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [productRestrictionData, setProductRestrictionData] = useState([]);
  const [dealersCollections, setDealersCollections] = useState([]);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState("");
  const [selectedProductsToRestrict, setSelectedProductsToRestrict] = useState(
    []
  );
  const [isAutoApproval, setIsAutoApproval] = useState(false);
  const [pageNameType, setpageNameType] = useState("Retailer");
  const [conservationCount, setConservationCount] = useState(0);
  const [appointmentcount, setAppointmentCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const searchInput = useRef(null);
  const [conversationCommonCount, setConversationCommonCount] = useState({});
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
  const [firstRowId, setFirstRowId] = useState("");
  const [showBtn, setShowBtn] = useState(false);

  // Request Modal

  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);
  const [page, setPage] = useState("RetailerAffirmation");
  const [disableBtn, setDisableBtn] = useState(true);
  const [emailLoading, setEmailLoading] = useState(false);
  const [content, setContent] = useState("");
  const [row, setRow] = useState([]);
  const [dealerCompany, setDealerCompany] = useState("");
  const [key, setKey] = useState(true);
  const [checkbox, setCheckBox] = useState("");
  const [rowlength, setRowLength] = useState(false);
  const [profileKey, setProfileKey] = useState(false);
  const [tableFilters, setTableFilters] = useState(null);
  const showSendInvitationModal = (row) => {
    setRow(row);
    setIsModalSendInvitationVisible(true);
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
    setDisableBtn(false);
  }

  const handleSendInvitationModalCancel = () => {
    setCheckBox("");
    setDisableBtn(false);
    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setContent("");
    setKey(!key);
  };

  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: ` By checking this box and clicking the submit button below I here by affirm that ${
        dealerCompany ? dealerCompany : null
      } has an open and active account with ${
        row.dealerCompany
      },that I am an authorized reseller of ${
        row.dealerCompany
      },and that I am permitted to have the ir virtual inventory on my website.`
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",

      note: `I am interested in opening a new account with ${row.dealerCompany} and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to ${row.dealerCompany}.`
    }
  ];

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: "Invitation", //row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId
      };
      setLoading(true);
      communityVendorService
        .SendEmailToVendor(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          if (message === "Success") {
            // NotificationManager.success("Email has been Sent Successfully.");
            handleRequestAccess();
          } else {
            NotificationManager.error("Email has not been Sent Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleNewAccountRequest = () => {
    let isVender =
      loginDetials.loginDetials.responseData.dealerMemberTypeName == "Vendor";
    try {
      let inputData = {
        email_Type: "Invitation", //row.emailType,
        vendorID: isVender ? dealerId : row.dealerID,
        dealerID: isVender ? row.dealerID : dealerId,
        swap: true
      };
      setLoading(true);
      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          var message = response.data.message;

          if (message === "Success") {
            handleRequestAccess();
            // NotificationManager.success("Email has been Sent Successfully.");
            setLoading(false);
          } else {
            NotificationManager.error("Email has not been Sent Successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleGetEmailData = () => {
    let isVender =
      loginDetials.loginDetials.responseData.dealerMemberTypeName == "Vendor";
    try {
      let inputData = {
        vendorID: isVender ? dealerId : row.dealerID,
        dealerID: isVender ? row.dealerID : dealerId,
        swap: true,
        // vendorID: row.dealerID,

        // dealerID: dealerId,
        emailType: "Invitation" // row.emailType,
      };
      setEmailLoading(true);
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            setContent(responseData);
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
          setDealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
          if (props.type) {
          } else {
            if (
              loginDetials.loginDetials.responseData.dealerMemberTypeName ==
              "VendorDiamond"
            ) {
              handleGetMyRetailerListVendor(
                loginDetials.loginDetials.responseData.dealerId,
                "dealerCompany",
                "asc"
              );
            } else {
              handleGetMyRetailerList(
                loginDetials.loginDetials.responseData.dealerId,
                "dealerCompany",
                "asc"
              );
            }

            handleGetConversionCount(
              loginDetials.loginDetials.responseData.dealerId
            );
            handleGetRetailerAppointmentData(
              loginDetials.loginDetials.responseData.dealerId
            );
            GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);
            handleGetAutoAppprovalStatus(
              loginDetials.loginDetials.responseData.dealerId
            );
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (ContactDetails) {
      setConservationCount(ContactDetails.contactConversationCount);
      setAppointmentCount(ContactDetails.contactAppointmentCount);
      setpageNameType(
        ContactDetails.jewelryTabName
          ? ContactDetails.jewelryTabName
          : "Retailer"
      );
    }
  }, [ContactDetails]);

  const handleTabsChange = (e) => {
    setTableKey(!tableKey);
    setSelectedTab(e);
    setSortColumnName("dealerCompany");
    setSortType("asc");

    var ColumnName = "dealerCompany";
    var SortName = "asc";
    if (e == "myretailer") {
      if (dealerMemberTypeName == "VendorDiamond") {
        handleGetMyRetailerListVendor(dealerId, ColumnName, SortName);
      } else {
        handleGetMyRetailerList(dealerId, ColumnName, SortName);
      }
    }
    if (e == "allretailer") {
      handleGetALLRetailerList(
        dealerId,
        pageNo,
        pageSize,
        ColumnName,
        SortName
      );
    }
  };

  const handleGetMyRetailerList = (id, sortColumnName, sortOrder, filters) => {
    try {
      let inputData = {
        dealerID: id,
        sales: "",
        sortColumnName: sortColumnName || "dealerCompany",
        sortOrder: sortOrder || "ASC",
        lstColumnFilter: filters || []
      };
      setLoading(true);
      RetailerDataService.GetMyRetailerList(inputData)
        .then((response) => {
          var message = response?.data?.message;
          var responseData = response.data.responseData;
          var FirstRowId =
            response?.data?.responseData?.myRetailerResponse?.[0]?.dealerID;
          var showBtn =
            response?.data?.responseData?.myRetailerResponse?.[0]?.showBtn;
          if (message === "Success") {
            if (responseData?.myRetailerResponse) {
              setJewelryList(responseData.myRetailerResponse);
              setFirstRowId(FirstRowId);
              setShowBtn(showBtn);
              handleGetRetailerProfile(FirstRowId, showBtn, id);
              var salesRepList = responseData.salesRapelistvalue.filter(
                (obj) => obj.userId !== ""
              );
              salesRepList.forEach(function (data) {
                data["text"] = data["name"];
                data["value"] = data["name"];
                delete data["name"];
                delete data["name"];
              });
              setStatusColumn(salesRepList);
              setRowLength(true);
            } else {
              setRowLength(false);
              setRowDetails({});
              setJewelryList([]);
            }
          } else {
            setRowLength(false);
            setRowDetails({});
            setJewelryList([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          setRowLength(false);
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setRowLength(false);
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetMyRetailerListVendor = (
    id,
    sortColumnName,
    sortOrder,
    filters
  ) => {
    try {
      let inputData = {
        dealerID: id,
        sales: "",
        sortColumnName: sortColumnName || "dealerCompany",
        sortOrder: sortOrder || "ASC",
        lstColumnFilter: filters || []
      };
      setLoading(true);
      RetailerDataService.GetmyRetailerListVendorDiamond(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRowId =
            response.data.responseData?.pendingResponse?.[0]?.dealerID;
          var showBtn =
            response.data?.responseData?.pendingResponse?.[0]?.showBtn;
          if (message === "Success") {
            setJewelryList(responseData?.pendingResponse || []);
            handleGetRetailerProfile(FirstRowId, showBtn, id);
            var salesRepList = responseData.salrapelistallreat.filter(
              (obj) => obj.userId !== ""
            );
            salesRepList.forEach(function (data) {
              data["text"] = data["name"];
              data["value"] = data["name"];
              delete data["name"];
              delete data["name"];
            });
            setStatusColumn(salesRepList);
            if (responseData?.pendingResponse?.length > 0) {
              setRowLength(true);
            } else {
              setRowLength(false);
            }
          } else {
            setRowLength(false);
            setJewelryList([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          setRowLength(false);
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setRowLength(false);
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetALLRetailerList = (
    id,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    filters
  ) => {
    try {
      let inputData = {
        dealerID: id,
        sales: "",
        sortColumnName: sortColumnName || "dealerCompany",
        sortOrder: sortOrder || "ASC",
        lstColumnFilter: filters || [],
        pageSize: pageSize,
        pageIndex: pageNo,
        isDiamondVendor: dealerMemberTypeName == "VendorDiamond" ? true : false
      };
      setLoading(true);
      RetailerDataService.GetALLRetailerList(inputData)
        .then((response) => {
          var message = response?.data?.message;
          var responseData = response?.data?.responseData;

          if (message === "Success") {
            if (responseData) {
              var FirstRowId = responseData?.pendingResponse?.[0]?.dealerID;
              var showBtn = responseData?.pendingResponse?.[0]?.showBtn;
              var totalRecord = responseData?.totalRecord;

              // For Issue in Sales Rep List
              if (responseData?.pendingResponse) {
                setAlljewelryList(responseData?.pendingResponse);
                if (responseData?.pendingResponse?.[0]) {
                  handleGetRetailerProfile(FirstRowId, showBtn, dealerId);
                }
                responseData?.salrapelistallreat?.forEach(function (data) {
                  data["text"] = data["name"];
                  data["value"] = data["name"];
                  delete data["name"];
                  delete data["name"];
                });
                setStatusColumn1(responseData?.salrapelistallreat);
              }

              setTotalCount(totalRecord);
              setLoading(false);
              if (responseData?.pendingResponse?.length > 0) {
                setRowLength(true);
              } else {
                setRowLength(false);
              }
            }
          } else {
            setRowLength(false);
            setAlljewelryList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          setRowLength(false);
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setRowLength(false);
      console.log(error);
      setLoading(false);
    }
  };

  function onSearch(val) {}

  const handleSwitch = () => {
    setDisconnectSwitch(false);
    try {
      let inputData = {
        designerID: Number(dealerId),
        dealerID: Number(rowDetails.dealerID)
      };
      communityVendorService
        .UpdateInventoryByDesignerID(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message === "Success") {
            setProfileKey(!profileKey);
            NotificationManager.success("Retailer Has Been Disconnected.");
            if (selectedTab == "myretailer") {
              if (dealerMemberTypeName == "VendorDiamond") {
                handleGetMyRetailerListVendor(
                  dealerId,
                  sortColumnName,
                  sortType
                );
              } else {
                handleGetMyRetailerList(dealerId, sortColumnName, sortType);
              }

              setSelectedColumnId(0);
              setDisconnectSwitch(true);
            }
            if (selectedTab == "allretailer") {
              handleGetALLRetailerList(
                dealerId,
                pageNo,
                pageSize,
                sortColumnName,
                sortType
              );
              setDisconnectSwitch(true);
            }
          } else {
            setProfileKey(!profileKey);
            NotificationManager.error(
              "Retailer Has Not Been Not Disconnected."
            );
            setLoading(false);
            setDisconnectSwitch(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnableDisableIsRestricted = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerid: saveRow.dealerID.toString(),
        type: saveRow.isrestricted == "1" ? true : false
      };
      RetailerDataService.EnableDisableIsRestricted(inputData)
        .then((response) => {
          var message = response.data.message;
          // setLoading(true);
          if (message === "Success") {
            if (saveRow.isrestricted == "1") {
              NotificationManager.success(
                "Jewelry Access Has Been Granted Successfully."
              );
            } else {
              NotificationManager.success("Jewelry Access Has Been Denied.");
            }
            if (dealerMemberTypeName == "VendorDiamond") {
              handleGetMyRetailerListVendor(dealerId, sortColumnName, sortType);
            } else {
              handleGetMyRetailerList(dealerId, sortColumnName, sortType);
            }
          } else {
            NotificationManager.error(
              "Jewelry Access Has Not  Been Granted Successfully."
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnableDisablePrice = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerid: saveRow.dealerID.toString(),
        type: saveRow.pricedisabled === "1" ? true : false
      };
      RetailerDataService.EnableDisablePrice(inputData)
        .then((response) => {
          var message = response.data.message;
          // setLoading(true);
          if (message === "Success") {
            if (saveRow.pricedisabled === "1") {
              NotificationManager.success("Hide Price Activated successfully.");
            } else {
              NotificationManager.success(
                "Hide Price Deactivated Successfully."
              );
            }
            // handleGetMyRetailerList(dealerId, sortColumnName, sortType);
          } else {
            NotificationManager.error("Hide Price Has Not Been Activated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableMarkup = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerid: saveRow.dealerID.toString(),
        type: saveRow.markuprestricted === "1" ? true : false
      };
      RetailerDataService.EnableDisableMarkup(inputData)
        .then((response) => {
          var message = response.data.message;
          // setLoading(true);
          if (message === "Success") {
            if (saveRow.markuprestricted === "1") {
              NotificationManager.success(
                "Enforce MSRP Activated successfully."
              );
            } else {
              NotificationManager.success(
                "Enforce MSRP Deactivated Successfully."
              );
            }
            // handleGetMyRetailerList(dealerId, sortColumnName, sortType);
          } else {
            NotificationManager.error("Enforce MSRP Has Not Activated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableRingBuilder = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerid: saveRow.dealerID.toString(),
        type: saveRow.ringbuilder === "1" ? true : false
      };
      RetailerDataService.EnableDisableRingBuilder(inputData)
        .then((response) => {
          var message = response.data.message;
          // setLoading(true);
          if (message === "Success") {
            if (saveRow.ringbuilder === "1") {
              NotificationManager.success(
                "RingBuilder Access Has Been Granted Successfully."
              );
            } else {
              NotificationManager.success(
                "RingBuilder Access Has Been Denied."
              );
            }

            // handleGetMyRetailerList(dealerId, sortColumnName, sortType);
          } else {
            NotificationManager.error(
              "RingBuilder Access Has Not Been Granted Successfully."
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableIsAutoPermission = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerid: saveRow.dealerID.toString(),
        type: saveRow.isautopermission === "1" ? true : false
      };
      RetailerDataService.EnableDisableIsAutoPermission(inputData)
        .then((response) => {
          var message = response.data.message;
          // setLoading(true);
          if (message === "Success") {
            if (saveRow.isautopermission === "1") {
              NotificationManager.success("Auto Sync Activated successfully.");
            } else {
              NotificationManager.success(
                "Auto Sync Deactivated Successfully."
              );
            }

            // handleGetMyRetailerList(dealerId, sortColumnName, sortType);
          } else {
            NotificationManager.error("Auto Sync Has Not Been Activated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisablePurchaseOrder = (saveRow) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        retailerid: saveRow.dealerID.toString(),
        type: saveRow.purchaseoder === "1" ? true : false
      };
      RetailerDataService.EnableDisablePurchaseOrder(inputData)
        .then((response) => {
          var message = response.data.message;
          // setLoading(true);
          if (message === "Success") {
            if (saveRow.purchaseoder === "1") {
              NotificationManager.success(
                "Purchase Order Access Has Been Granted Successfully."
              );
            } else {
              NotificationManager.success(
                "Purchase Order Access Has Been Denied."
              );
            }

            // handleGetMyRetailerList(dealerId, sortColumnName, sortType);
          } else {
            NotificationManager.error(
              "Purchase Order Access Has Not Been Granted Successfully."
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (row, e, name) => {
    if (name === "isrestricted") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, isrestricted: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      handleEnableDisableIsRestricted(saveRow);
    }
    if (name === "pricedisabled") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, pricedisabled: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      handleEnableDisablePrice(saveRow);
    }
    if (name === "markuprestricted") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, markuprestricted: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);

      handleEnableDisableMarkup(saveRow);
    }
    if (name === "ringbuilder") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, ringbuilder: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);

      handleEnableDisableRingBuilder(saveRow);
    }
    if (name === "isautopermission") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, isautopermission: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);

      handleEnableDisableIsAutoPermission(saveRow);
    }
    if (name === "purchaseoder") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, purchaseoder: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);

      handleEnableDisablePurchaseOrder(saveRow);
    }
  };

  const handleGetRetailerProfile = (id, showBtn, dealerId) => {
    try {
      let inputData = {
        retailerID: id,
        showBtn: showBtn,
        dealerId: dealerId
      };
      setSelectedColumnId(id);
      setLoadingProfileData(true);
      RetailerDataService.GetRetailerProfile(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setSalesRepId(
                responseData.salesUserId ? responseData.salesUserId : ""
              );
              setRowDetails(responseData);
              setLoadingProfileData(false);
              setTimeout(() => {
                handleBindRightSideDataForPermission(id, dealerId);
              }, 500);
            } else {
              setRowDetails({});
              setLoadingProfileData(false);
              setDealersCollections([]);
            }
          } else {
            setRowDetails({});
            setLoadingProfileData(false);
            setDealersCollections([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  // End

  const columnAllVendorJewelry = [
    {
      title: traslate("Store Nameee"),
      dataIndex: "dealerCompany",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (index, row) => {
        return (
          <p>
            <span className="linkText mb-0">{row.dealerCompany}</span>
          </p>
        );
      }
    },
    {
      title: traslate("Status"),
      dataIndex: "status"
    },

    {
      title: traslate("City"),
      dataIndex: "dealerCity",
      showSorterTooltip: false,
      width: 100,
      sorter: (a, b) => {}
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("PhoneNo"),
      dataIndex: "dealerPhone",
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Sales Rep"),
      dataIndex: "salesReps",
      filters: statusColumn1,
      onFilter: (value, record) => record.salesReps === value,
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Request Date"),
      dataIndex: "reqdDate",
      showSorterTooltip: false,
      sorter: (a, b) => {}
    }
    // {
    //   title: traslate("Request Date"),
    //   dataIndex: "reqdDate",
    //   showSorterTooltip: false,
    //   sorter: (a, b) => {},
    // },
    // {
    //   title: traslate("Action"),
    //   render: (index, row) => {
    //     return (
    //       <p>
    //         {row.status != "Connected" ? (
    //           <span
    //             className="linkText mb-0"
    //             onClick={() => handleRequestAccess(row)}
    //           >
    //             {traslate("Request Access")}
    //           </span>
    //         ) : (
    //           <span>{traslate("Connected")}</span>
    //         )}
    //       </p>
    //     );
    //   },
    // },
  ];

  const columnAllJewelry = [
    {
      title: traslate("Store Name"),
      dataIndex: "dealerCompany",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (index, row) => {
        return (
          <p>
            <span
              className="linkText mb-0"
              // onClick={() => handleRequestAccess(row)}
            >
              {row.dealerCompany}
            </span>
          </p>
        );
      }
    },
    {
      title: traslate("Status"),
      dataIndex: "status"
    },

    {
      title: traslate("City"),
      dataIndex: "dealerCity",
      showSorterTooltip: false,
      width: 100,
      sorter: (a, b) => {}
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("PhoneNo"),
      dataIndex: "dealerPhone",
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Sales Rep"),
      dataIndex: "salesReps",
      filters: statusColumn1,
      onFilter: (value, record) => record.salesReps === value,
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Request Date"),
      dataIndex: "reqdDate",
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Action"),
      width: 120,
      render: (index, row) => {
        return (
          <p>
            {row.status != "Connected" ? (
              <span
                className="linkText mb-0"
                // onClick={() => handleRequestAccess(row)}
                onClick={() => {
                  showSendInvitationModal(row);
                }}
              >
                {traslate("Request Access")}
              </span>
            ) : (
              <span>{traslate("Connected")}</span>
            )}
          </p>
        );
      }
    }
  ];

  const filterData = (SalesRep) => (formatter) =>
    [
      ...new Set(
        SalesRep.map((item) => ({
          text: formatter(item),
          value: formatter(item)
        }))
      )
    ];

  // var filterData = [...new Set(jewelryList.map((item, i) => item.salesReps))];

  var NewSalesRep = [{ userId: "", name: "Sales Rep" }, ...SalesRep];

  const columnJewelryVendor = [
    {
      title: traslate("Store Name"),
      dataIndex: "dealerCompany",
      // sorter: (a, b) => a.dealerCompany.length - b.dealerCompany.length,
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (index, row) => {
        return (
          <p>
            <span
              className="linkText mb-0"
              // onClick={() => handleRequestAccess(row)}
            >
              {row.dealerCompany}
            </span>
          </p>
        );
      }
    },
    {
      title: traslate("Retailer Type"),
      dataIndex: "retailerType",
      render: (item, row) => {
        return <span>{traslate("Diamond")}</span>;
      },
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("City"),
      dataIndex: "dealerCity",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      width: 100
      // sorter: (a, b) => a.dealerCity.length - b.dealerCity.length,
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
      // sorter: (a, b) => a.dealerState.length - b.dealerState.length,
      // sorter: (a, b) => a.dealerState.localeCompare(b.dealerState),
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Request Date"),
      dataIndex: "reqdDate",
      // sorter: (a, b) => a.reqdDate.length - b.reqdDate.length,
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <div>
            {row.reqdDate == "" ? null : (
              <p>{Moment(row.reqdDate).format("MM/DD/YYYY ")}</p>
            )}
          </div>
        );
      }
    },
    {
      title: traslate("Sales Rep"),
      dataIndex: "salesReps",
      filters: statusColumn,
      onFilter: (value, record) => record.salesReps === value,
      // onFilter: (value, record) => record.salesReps.indexOf(value) === 0,
      // filters: statusColumn,
      showSorterTooltip: false,
      // onFilter: (value, record) => record.salesReps === value,
      sorter: (a, b) => {}
    }
  ];

  const columnJewelry = [
    {
      title: traslate("Store Name"),
      dataIndex: "dealerCompany",
      width: 120,
      // sorter: (a, b) => a.dealerCompany.length - b.dealerCompany.length,
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (index, row) => {
        return (
          <p>
            <span
              className="linkText mb-0"
              // onClick={() => handleRequestAccess(row)}
            >
              {row.dealerCompany}
            </span>
          </p>
        );
      }
    },
    {
      title: traslate("Retailer Type"),
      dataIndex: "retailerType",
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    {
      title: traslate("City"),
      dataIndex: "dealerCity",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      width: 100
      // sorter: (a, b) => a.dealerCity.length - b.dealerCity.length,
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
      width: 120,
      // sorter: (a, b) => a.dealerState.length - b.dealerState.length,
      // sorter: (a, b) => a.dealerState.localeCompare(b.dealerState),
      showSorterTooltip: false,
      sorter: (a, b) => {}
    },
    // {
    //   title: traslate("Request Date"),
    //   dataIndex: "reqdDate",
    //   // sorter: (a, b) => a.reqdDate.length - b.reqdDate.length,
    //   showSorterTooltip: false,
    //   render: (item, row) => {
    //     return <p>{Moment(row.reqdDate).format("MM/DD/YYYY ")}</p>;
    //   },
    //   sorter: (a, b) => {},
    // },
    {
      title: traslate("Request Date"),
      dataIndex: "reqdDate",
      width: 120,
      // sorter: (a, b) => a.reqdDate.length - b.reqdDate.length,
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <div>
            {row.reqdDate == "" ? null : (
              <p>{Moment(row.reqdDate).format("MM/DD/YYYY ")}</p>
            )}
          </div>
        );
      }
    },
    {
      title: traslate("Sales Rep"),
      dataIndex: "salesReps",
      width: 130,
      filters: statusColumn,
      onFilter: (value, record) => record.salesReps === value,
      // onFilter: (value, record) => record.salesReps.indexOf(value) === 0,
      // filters: statusColumn,
      showSorterTooltip: false,
      // onFilter: (value, record) => record.salesReps === value,
      sorter: (a, b) => {}
    },
    {
      title: traslate("Jewelry Access"),
      dataIndex: "isrestricted",
      width: 70,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Switch
              name="isrestricted"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.isrestricted == "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "isrestricted")}
            />
          </div>
        );
      }
    },
    // {
    //   title: traslate("RingBuilder Access"),
    //   dataIndex: "ringbuilder",
    //   render: (item, row) => {
    //     return (
    //       <div
    //         className="action__btns"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           e.preventDefault();
    //         }}
    //       >
    //         <Switch
    //           name="ringbuilder"
    //           checkedChildren="Yes"
    //           unCheckedChildren="No"
    //           checked={row.ringbuilder === "1" ? true : false}
    //           onChange={(e) => handleOnChange(row, e, "ringbuilder")}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      title: traslate("Purchase Order"),
      dataIndex: "purchaseoder",
      width: 70,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Switch
              name="purchaseoder"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.purchaseoder === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "purchaseoder")}
            />
          </div>
        );
      }
    },
    {
      title: traslate("Hide Price"),
      dataIndex: "pricedisabled",
      width: 70,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Switch
              name="pricedisabled"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.pricedisabled === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "pricedisabled")}
            />
          </div>
        );
      }
    },
    {
      title: traslate("Enforce MSRP"),
      dataIndex: "markuprestricted",
      width: 70,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Switch
              name="markuprestricted"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.markuprestricted === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "markuprestricted")}
            />
          </div>
        );
      }
    },
    {
      title: traslate("Auto Sync"),
      dataIndex: "isautopermission",
      width: 70,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Switch
              name="isautopermission"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.isautopermission === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "isautopermission")}
            />
          </div>
        );
      }
    }
  ];

  const data1 = [
    {
      key: "1",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      JewelryAccess: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      RingBuilderAccess: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      PurchaseOrder: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      HidePrice: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      EnforceMSRP: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      AutoSync: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      )
    }
  ];

  const columnsDiamond = [
    {
      title: traslate("Store Name"),
      dataIndex: "storename"
    },
    {
      title: traslate("Retailer Type"),
      dataIndex: "retailertype"
    },
    {
      title: traslate("City"),
      dataIndex: "city",
      width: 100
    },
    {
      title: traslate("State"),
      dataIndex: "state"
    },
    {
      title: traslate("Requested Date"),
      dataIndex: "requesteddate"
    },
    {
      title: traslate("Jewelry"),
      dataIndex: "jewelry"
    },
    {
      title: traslate("Purchase Order"),
      dataIndex: "porder"
    },
    {
      title: traslate("Jewelry API"),
      dataIndex: "jewelryapi"
    }
  ];

  const columns = [
    {
      title: traslate("Store Name"),
      dataIndex: "storename",
      key: "storename"
    },
    {
      title: traslate("Retailer Type"),
      dataIndex: "retailertype",
      key: "retailertype"
    },
    {
      title: traslate("City"),
      dataIndex: "city",
      key: "city",
      width: 100
    },
    {
      title: traslate("State"),
      dataIndex: "state",
      key: "state"
    },
    {
      title: traslate("Requested Date"),
      dataIndex: "requesteddate",
      key: "requesteddate"
    },
    {
      title: traslate("Jewelry"),
      dataIndex: "jewelry",
      key: "jewelry",
      render: (item, row) => {
        return <Checkbox checked={row.jewelry} />;
      }
    },
    {
      title: traslate("Purchase Order"),
      dataIndex: "porder",
      key: "porder",
      render: (item, row) => {
        return <Checkbox checked={row.porder} />;
      }
    },
    {
      title: traslate("Jewelry API"),
      dataIndex: "jewelryapi",
      key: "porder",
      render: (item, row) => {
        return <Checkbox checked={row.jewelryapi} />;
      }
    }
  ];

  const GetSalesRepsList = (id) => {
    try {
      let inputData = {
        dealerID: id
      };
      RetailerDataService.GetSalesRepsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSalesRep(responseData);
            } else {
              setSalesRep([]);
            }
          } else {
            setSalesRep([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSelectChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setSalesRepId({
        ...SalesRepId,
        [name]: e
      });
      if (e == null || e == "") {
        handleSaveSalesReps("");
      } else {
        handleSaveSalesReps(e);
      }
    } else {
      setSalesRepId({
        ...SalesRepId,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSaveSalesReps = (id) => {
    try {
      let inputData = {
        dealerID: dealerId,
        retailerID: rowDetails.dealerID,
        salesRepId: id
      };
      RetailerDataService.SaveSalesReps(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Sales Rep Added Successfully");
            if (selectedTab == "myretailer") {
              if (dealerMemberTypeName == "VendorDiamond") {
                handleGetMyRetailerListVendor(
                  dealerId,
                  sortColumnName,
                  sortType
                );
              } else {
                handleGetMyRetailerList(dealerId, sortColumnName, sortType);
              }
            }
            if (selectedTab == "allretailer") {
              handleGetALLRetailerList(
                dealerId,
                pageNo,
                pageSize,
                sortColumnName,
                sortType
              );
            }
          } else {
            NotificationManager.success("Sales Rep Not Added");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Sorting API

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }

    //search and filter
    if (filters.salesReps) {
      filters.salesReps.forEach((element) => {
        obj = {
          columnName: "SalesReps",
          columnValue: element,
          columnType: "CheckBox"
        };
        filter.push(obj);
      });
    } else {
    }

    if (selectedTab == "myretailer") {
      if (dealerMemberTypeName == "VendorDiamond") {
        handleGetMyRetailerListVendor(dealerId, column, type, filter);
      } else {
        handleGetMyRetailerList(dealerId, column, type, filter);
      }
    } else {
      setPageNo(pagination.current);
      setPageSize(pagination.pageSize);
      handleGetALLRetailerList(
        dealerId,
        pagination.current,
        pagination.pageSize,
        column,
        type,
        filter
      );
    }
    setTableFilters(filter);
  };

  const handleAutoApproval = async (dealerId, action) => {
    let inputData = {
      dealerId,
      action
    };

    try {
      await RetailerDataService.Setglobalpermission(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Approval Changed Successfully");
            handleGetAutoAppprovalStatus(dealerId);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry! Could Not Change Approval");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchChange = (e) => {
    let action = e == true ? "Yes" : "No";
    setIsAutoApproval(e);
    handleAutoApproval(dealerId, action);
  };

  const handleBindRightSideDataForPermission = async (retailerID, id) => {
    let inputData = {
      dealerId: id ? id : dealerId,
      retailerID
    };
    try {
      await RetailerDataService.BindRightSideDataForPermission(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            let productRestrictionData = responseData.restrictedProducts;
            let dealersCollections = responseData.dealersCollections;
            var data = productRestrictionData
              .filter((x) => x.show == "0")
              .map((x) => x.gfInventoryID);
            if (productRestrictionData) {
              setProductRestrictionData(productRestrictionData);
            }
            setSelectedProductsToRestrict(data);
            if (dealersCollections) {
              const saveRow = dealersCollections
                .filter((item) => item.isassigned == "1")
                .map((item) => {
                  return item.collectionid;
                });

              setDealersCollections(dealersCollections);
              setSelectedCollectionIds(saveRow + "");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setDealersCollections([]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCollectionId = (e, dealer) => {
    let temp = dealersCollections.map((item) => {
      if (item.collectionid == dealer.collectionid) {
        return { ...item, isassigned: e.target.checked == true ? "1" : "0" };
      } else return item;
    });

    const saveRow = temp
      .filter((item) => item.isassigned == "1")
      .map((item) => {
        return item.collectionid;
      });
    setDealersCollections(temp);
    setSelectedCollectionIds(saveRow + "");
  };

  const handleUpdateCollection = async () => {
    let inputData = {
      retailer_ID: selectedColumnId,
      collectionID: dealersCollections,
      dealerID: dealerId + ""
    };

    try {
      await RetailerDataService.UpdateCollection(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;

          if (msg == "Success") {
            NotificationManager.success(
              "Collection Access Updated Successfully"
            );
            setSelectedCollectionIds("");
          } else {
            NotificationManager.error("Could Not Update Collection Access");
          }
        })
        .catch((error) => {
          NotificationManager.error("Could Not Update Collection Access");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Sorry We have an error");
    }
  };

  const handleCollectionSubmit = () => {
    handleUpdateCollection();
    setVisible(false);
  };

  const handleCancelCollectionModal = (record) => {
    setVisible(false);
    setSelectedCollectionIds("");
    handleBindRightSideDataForPermission(selectedColumnId, dealerId);
  };

  const handleRestrictProductChange = (e) => {
    setSelectedProductsToRestrict(e);
  };

  const handleRestrictedProductSubmit = async () => {
    let inputData = {
      dealerId: dealerId,
      retailerID: selectedColumnId,
      gfInventoryID: selectedProductsToRestrict + ""
    };
    try {
      await RetailerDataService.DisableProductbyVendor(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(
              "Product Restriction Done Successfully"
            );

            handleBindRightSideDataForPermission(selectedColumnId, dealerId);
          } else {
            NotificationManager.error("Could Not Restrict Product");
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Could Not Restrict Product");
        });
    } catch (error) {
      NotificationManager.error("Sorry! There was an error");
      console.log(error);
    }
  };

  const handleRequestAccess = async () => {
    let inputData = {
      dealerID: dealerId.toString(),
      reatilerID: row.dealerID
    };

    setLoading(true);

    try {
      await RetailerDataService.SendReailerRequest(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            setIsModalSendInvitationVisible(false);
            NotificationManager.success("Request Sent");
            setLoading(false);
            var ColumnName = "dealerCompany";
            var SortName = "asc";
            handleGetALLRetailerList(
              dealerId,
              pageNo,
              pageSize,
              ColumnName,
              SortName
            );
            setPage("RetailerAffirmation");
          } else {
            NotificationManager.error("Cannot Send Request");
            setLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry! We had an error");
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Sorry! We had an error");
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const openInNewTab = () => {
    var url = rowDetails.websiteAddress;
    if (rowDetails.websiteAddress.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.websiteAddress.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const handleGetAutoAppprovalStatus = async (id) => {
    let inputData = {
      dealerId: id
    };
    try {
      await RetailerDataService.GetDealerPermission(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            if (responseData) {
              let status = responseData.isChecked;
              let isAutoApproval = status == "True" ? true : false;
              setIsAutoApproval(isAutoApproval);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const handleGetConversionCount = (dealerId) => {
    try {
      //
      let inputData = {
        vendorId: dealerId
      };
      AdminDashboardDataService.GetConversionCount(inputData)
        .then((response) => {
          //
          var message = response.data.message;
          var responseData = response.data.responseData;
          // var allConvCount = responseData.allCount; setConversationCommonCount

          if (message === "Success") {
            if (responseData) {
              dispatch(setVendorConversationCount(responseData.allCount));
              dispatch(setVendorConversationCountSent(responseData.allSent));
              dispatch(
                setVendorConversationCountUnRead(responseData.allUnReadCount)
              );
              setConversationCommonCount(responseData);
            } else {
              setConversationCommonCount({});
            }
          } else {
            setConversationCommonCount({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRetailerAppointmentData = (
    id,
    salesRep,
    tradeShowId,
    fromDate,
    toDate
  ) => {
    try {
      let inputData = {
        dealerId: id.toString(),
        salesRep: salesRep ? salesRep : "",
        tradeShowId: tradeShowId ? tradeShowId : "",
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
        orderBy: ""
      };

      AdminDashboardDataService.GetRetailerAppointmentData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var initialRecord = responseData[0];

          if (message === "Success") {
            if (responseData) {
              setAppointmentCount(responseData.length);
              dispatch(setVendorAppointmentCount(responseData.length));
            } else {
              setAppointmentCount(0);
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefresh = () => {
    setSortColumnName("dealerCompany");
    setSortType("asc");

    var ColumnName = "dealerCompany";
    var SortName = "asc";

    if (selectedTab == "myretailer") {
      if (dealerMemberTypeName == "VendorDiamond") {
        handleGetMyRetailerListVendor(dealerId, ColumnName, SortName);
      } else {
        handleGetMyRetailerList(dealerId, ColumnName, SortName);
      }
    }
    if (selectedTab == "allretailer") {
      handleGetALLRetailerList(
        dealerId,
        pageNo,
        pageSize,
        ColumnName,
        SortName,
        tableFilters
      );
    }
  };

  return (
    <React.Fragment>
      <div className="catalog__list dashbo_maindiv">
        <div className="catalog__topnav">
          <ul>
            {dealerMemberTypeName == "VendorDiamond" ? (
              <li onClick={() => setpageNameType("Retailer")}>
                {" "}
                <p className="topNavLinks">{traslate("Diamond Retailers")}</p>
              </li>
            ) : (
              <li onClick={() => setpageNameType("Retailer")}>
                {" "}
                <p className="topNavLinks">
                  {traslate("Jewelry Retailer & Permissions")}
                </p>
              </li>
            )}

            <li onClick={() => setpageNameType("ConversationsOnly")}>
              {" "}
              <p className="topNavLinks">
                {traslate("Conversations")}{" "}
                <span>
                  {/* {Object.keys(conversationCommonCount).length !== 0
                    ? conversationCommonCount.allCount
                    : 0} */}
                  {ContactDetails.vendorConversionCountUnRead}
                </span>
              </p>
            </li>
            <li onClick={() => setpageNameType("AppointmentsOnly")}>
              {" "}
              <p className="topNavLinks">
                {traslate("Appointments")}{" "}
                <span>
                  {appointmentcount
                    ? appointmentcount
                    : ContactDetails.vendorAppointmentCount}
                  {/* {appointmentcount ? appointmentcount : 0} */}
                </span>
              </p>
            </li>
            {dealerMemberTypeName == "Vendor" && (
              <li onClick={() => setpageNameType("BlockedProducts")}>
                {" "}
                <p className="topNavLinks">{traslate("Blocked Products")} </p>
              </li>
            )}
          </ul>
        </div>
      </div>

      {pageNameType == "Retailer" && (
        <div className="prodetail__section mt-5">
          <div className="form__fields">
            <div className="col-md-12">
              <div className="prod__maindiv prodmarkup__secion permission__section">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="workarea__heading mt-0 mb-3">
                      {" "}
                      {/* {props.type
                        ? traslate("Diamond")
                        : traslate("Jewelry")}{" "}
                      {traslate("Retailer & Permissions")} */}
                      {dealerMemberTypeName == "VendorDiamond"
                        ? "Diamond Retailers"
                        : "Jewelry Retailer & Permissions"}
                      {/* {traslate("Jewelry Data & Permissions")}{" "} */}
                    </h4>
                  </div>

                  <div className="col-lg-9 col-md-12 position-relative">
                    <div className="jrper__refresh">
                      <Button
                        type="primary"
                        onClick={() => {
                          handleRefresh();
                        }}
                      >
                        {traslate("Refresh")}
                      </Button>
                    </div>
                    <div className="prodesc__div">
                      <Tabs
                        defaultActiveKey="myretailer"
                        id="uncontrolled-tab-example"
                        className="tab__div mb-3"
                        onSelect={(e) => {
                          handleTabsChange(e);
                        }}
                        activeKey={selectedTab}
                      >
                        <Tab
                          eventKey="myretailer"
                          title={
                            "My Retailers" +
                            " " +
                            "(" +
                            jewelryList.length +
                            ")"
                          }
                        >
                          <div className="tab__contentdiv">
                            <div className="col-lg-12">
                              <div className="desc__maindiv">
                                <div className="col-lg-12">
                                  <Spin spinning={loading} size="large">
                                    {/* <Table
                                 columns={
                                   props.type ? columnsDiamond : columnJewelry
                                 }
                                 className="d-none"
                                 dataSource={MyRetailerData}
                                 scroll={{ x: 600 }}
                               /> */}
                                    <Table
                                      key={tableKey}
                                      columns={
                                        props.type
                                          ? columnsDiamond
                                          : dealerMemberTypeName ==
                                            "VendorDiamond"
                                          ? columnJewelryVendor
                                          : columnJewelry
                                      }
                                      dataSource={
                                        props.type ? data1 : jewelryList
                                      }
                                      onChange={handleTableASCDES}
                                      onRow={(record, recordIndex) => ({
                                        onClick: (event) => {
                                          handleGetRetailerProfile(
                                            record.dealerID,
                                            record.showBtn,
                                            dealerId
                                          );
                                        }
                                      })}
                                      rowClassName={(record) =>
                                        record.dealerID == selectedColumnId
                                          ? "row-active"
                                          : ""
                                      }
                                      loading={loading}
                                      scroll={
                                        props.type || jewelryList.length > 0
                                          ? { x: 800, y: 400 }
                                          : {}
                                      }
                                      pagination={{
                                        showSizeChanger: true,
                                        responsive: true
                                      }}
                                      className="mw-auto myreatailer"
                                    />
                                  </Spin>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab
                          eventKey="allretailer"
                          title={traslate("All Retailers")}
                        >
                          <div className="tab__contentdiv">
                            <div className="desc__maindiv">
                              <div className="col-lg-12">
                                <Spin spinning={loading} size="large">
                                  <Table
                                    columns={
                                      props.type
                                        ? columnsDiamond
                                        : dealerMemberTypeName ==
                                          "VendorDiamond"
                                        ? columnAllVendorJewelry
                                        : columnAllJewelry
                                    }
                                    dataSource={
                                      props.type ? data1 : allJewelryList
                                    }
                                    onChange={handleTableASCDES}
                                    onRow={(record, recordIndex) => ({
                                      onClick: (event) => {
                                        handleGetRetailerProfile(
                                          record.dealerID,
                                          record.showBtn,
                                          dealerId
                                        );
                                      }
                                    })}
                                    rowClassName={(record) =>
                                      record.dealerID == selectedColumnId
                                        ? "row-active"
                                        : ""
                                    }
                                    loading={loading}
                                    scroll={{
                                      x: 600,
                                      y: 400
                                    }}
                                    pagination={{
                                      showSizeChanger: true,
                                      responsive: true,
                                      pageNo: pageNo,
                                      pageSize: pageSize,
                                      pageSizeOptions: [10, 20, 50, 100],
                                      total: totalCount
                                    }}
                                  />
                                </Spin>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>

                  <div
                    key={profileKey}
                    className="col-lg-3 col-md-12"
                    // className={
                    //   rowlength == true ? "col-lg-3 col-md-12" : "d-none"
                    // }
                  >
                    <Spin spinning={loadingprofileData}>
                      {Object.keys(rowDetails).length > 0 ? (
                        <div className="right__section overflow__section">
                          <div className="profile__block">
                            <span href="#" className="retailer__name">
                              {rowDetails.dealerCompany
                                ? rowDetails.dealerCompany
                                : null}
                            </span>
                            <Link
                              to={"/retailerProfile"}
                              state={{
                                key: "Profile",
                                id: selectedColumnId
                              }}
                            >
                              <div className="logoImg__block p-0">
                                <img src={rowDetails.companylogo} />
                              </div>
                            </Link>
                            {dealerMemberTypeName != "VendorDiamond" &&
                            selectedTab == "myretailer" ? (
                              <div className="input__block product-restriction ">
                                <label className="mt-1">
                                  {" "}
                                  {traslate("Product Restriction")}
                                </label>

                                <Select
                                  showSearch
                                  mode="multiple"
                                  // placeholder="Select Skus"
                                  value={selectedProductsToRestrict}
                                  onChange={handleRestrictProductChange}
                                >
                                  {productRestrictionData.map((product) => {
                                    return (
                                      <Option value={product.gfInventoryID}>
                                        {product.styleNumber}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                <div className="text-right">
                                  <button
                                    className="ant-btn ant-btn-default primary-btn mt-2"
                                    onClick={handleRestrictedProductSubmit}
                                  >
                                    {traslate("Save")}
                                  </button>
                                </div>
                              </div>
                            ) : null}

                            <hr />
                            {dealerMemberTypeName != "VendorDiamond" &&
                            selectedTab == "myretailer" ? (
                              <div className="links__block">
                                <ul>
                                  <li>
                                    <a onClick={() => setVisible(true)}>
                                      {" "}
                                      {traslate("Collection Access")}
                                    </a>
                                  </li>
                                  <li>
                                    <Link to="/blockedProduct">
                                      <a> {traslate("Product Access")}</a>
                                    </Link>
                                  </li>
                                </ul>
                                <hr />
                              </div>
                            ) : null}
                            <p className="activeFrom">
                              {traslate("Active")} |{" "}
                              {rowDetails.activity ? rowDetails.activity : null}
                            </p>
                          </div>

                          {/* <div className="retailer__dtls mb-4">
<p>
  {rowDetails.dealerName ? rowDetails.dealerName : null}
</p>
<p>
  {rowDetails.dealerEmail
    ? rowDetails.dealerEmail
    : null}
</p>
<p>
  {rowDetails.dealerPhone
    ? rowDetails.dealerPhone
    : null}
</p>
<a href={rowDetails.websiteAddress}>
  {rowDetails.websiteAddress
    ? rowDetails.websiteAddress
    : null}
</a>
<hr />
</div> */}

                          <div className="links__block">
                            <ul>
                              <li>
                                <Link
                                  to={"/retailerProfile"}
                                  state={{
                                    key: "Profile",
                                    id: selectedColumnId,

                                    page:
                                      rowDetails.showBtn == true
                                        ? "ShowAccess"
                                        : "DonotshowAccess"
                                  }}
                                >
                                  <i
                                    class="fa fa-address-book-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {traslate("View Profile")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"/retailerProfile"}
                                  state={{
                                    key: "Conversation",
                                    id: selectedColumnId,
                                    page:
                                      rowDetails.retailerStatusID == 0
                                        ? "ShowAccess"
                                        : "DonotshowAccess"
                                  }}
                                >
                                  <i
                                    class="fa fa-comments-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {traslate("Conversation")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"/makeAppoitments"}
                                  state={{
                                    key: "Appointments",
                                    id: selectedColumnId,

                                    page:
                                      rowDetails.retailerStatusID == 0
                                        ? "ShowAccess"
                                        : "DonotshowAccess"
                                  }}
                                >
                                  <i
                                    class="fa fa-calendar-check-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {traslate("Make Appointment")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"/retailerProfile"}
                                  state={{
                                    key: "Notes",
                                    id: selectedColumnId,
                                    page:
                                      rowDetails.retailerStatusID == 0
                                        ? "ShowAccess"
                                        : "DonotshowAccess"
                                  }}
                                >
                                  <i
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {traslate("Store Notes")}
                                </Link>
                              </li>
                            </ul>
                            <hr />
                          </div>

                          <div className="bottom__block">
                            <div className="input__block">
                              <label> {traslate("Sales Rep")} </label>
                              <Select
                                placeholder="Select Sales Rep"
                                value={SalesRepId ? SalesRepId : null}
                                name="userId"
                                showSearch
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleOnSelectChange(e, "select", "userId")
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="null">Sales Rep</Option>
                                {SalesRep.map((item) => {
                                  return (
                                    <Option value={item.userId}>
                                      {item.name}{" "}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>

                            {dealerMemberTypeName != "VendorDiamond" &&
                            selectedTab == "myretailer" ? (
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Connected Retailer")}{" "}
                                </label>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  defaultChecked
                                  name="disconnectSwitch"
                                  checked={disconnectSwitch}
                                  onChange={handleSwitch}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="retailer__dtls mb-4">
                            <p>
                              {rowDetails.dealerName
                                ? rowDetails.dealerName
                                : null}
                            </p>
                            <a href={`mailto:${rowDetails.dealerEmail}`}>
                              {rowDetails.dealerEmail
                                ? rowDetails.dealerEmail
                                : null}
                            </a>
                            <p>
                              {rowDetails.dealerPhone
                                ? rowDetails.dealerPhone
                                : null}
                            </p>
                            <span
                              className="linkText"
                              onClick={() => openInNewTab()}
                            >
                              {" "}
                              {rowDetails.websiteAddress}
                            </span>

                            <hr />
                          </div>
                          {dealerMemberTypeName != "VendorDiamond" &&
                          selectedTab == "myretailer" ? (
                            <>
                              <div className="manage-retailer-access-desc">
                                <h4>
                                  {traslate(
                                    "Manage Retailer Access To Your Inventory"
                                  )}
                                </h4>
                                <p>
                                  {traslate(
                                    "Set permissions for retailers to display your virtual inventory on their website."
                                  )}
                                </p>
                              </div>

                              <hr />
                            </>
                          ) : null}

                          {dealerMemberTypeName != "VendorDiamond" &&
                          selectedTab == "myretailer" ? (
                            <div className="manage-retailer-access-desc">
                              <h4>{traslate("Auto Approval")}</h4>
                              <div className="input__block mb-2">
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  onChange={handleSwitchChange}
                                  checked={isAutoApproval}
                                />
                              </div>
                              <p>
                                {traslate(
                                  "Automatically approve retailers requesting to open an account."
                                )}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="right__section overflow__section">
                          <p className="no_data_found">No Data Found</p>
                        </div>
                      )}
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {pageNameType == "ConversationsOnly" && (
        <ConversationsDashboard
          pageNameType={pageNameType}
          conversationCommonCount={conversationCommonCount}
        />
      )}

      {pageNameType == "AppointmentsOnly" && (
        <AppointmentsDashboard pageNameType={pageNameType} />
      )}
      {pageNameType == "BlockedProducts" && <BlockedProduct />}
      <Modal
        title={traslate("Collection Access")}
        centered
        visible={visible}
        onOk={() => handleCollectionSubmit()}
        onCancel={() => handleCancelCollectionModal()}
        width={700}
      >
        <div className="row">
          {dealersCollections.length > 0 ? (
            dealersCollections.map((dealer) => {
              return (
                <div className="col-md-4">
                  <div className="radio__block">
                    <input
                      type="checkbox"
                      checked={dealer.isassigned == "1" ? true : false}
                      onChange={(e) => handleChangeCollectionId(e, dealer)}
                    />
                    <label>
                      {dealer.collection + " " + "(" + dealer.total + ")"}{" "}
                    </label>
                  </div>
                </div>
              );
            })
          ) : (
            <h4 className="text-center">{traslate("No Data")}</h4>
          )}

          {/* <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>A. Link for Forevermark (31)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>A. Link for Forevermark (31)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>Ja-Collection (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>Ja-Collection (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>Ja-Collection (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>test123 (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>test123 (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>test123 (0)</label>
            </div>
          </div> */}
        </div>
      </Modal>

      {/* Request Access Modal */}
      <Modal
        title={
          page == "RetailerAffirmation"
            ? traslate("Retailer Affirmation")
            : traslate("Send Email")
        }
        visible={isModalSendInvitationVisible}
        className="forgot__password"
        onCancel={handleSendInvitationModalCancel}
        footer={[
          //
          <div className={page == "RetailerAffirmation" ? "d-block" : "d-none"}>
            <Button
              key="submit"
              className={checkbox == "" ? "disabled-btn" : "primary-btn"}
              disabled={(checkbox == "") == true ? true : false}
              onClick={
                checkbox == "CurrentAuthorizedReseller"
                  ? handleShowEmailWindow
                  : handleNewAccountRequest
              }
            >
              {traslate("Submit")}
            </Button>
          </div>,
          <div className={page == "SendEmail" ? "d-block" : "d-none"}>
            <Button
              loading={loading}
              className="primary-btn"
              key="submit"
              // onClick={handleSendEmail}
              onClick={handleSendEmail}
            >
              {traslate("Send")}
            </Button>
          </div>
        ]}
        key={key}
      >
        <div className="form__fields p-0 border-0 mh-auto">
          {page == "RetailerAffirmation" ? (
            <div>
              {category.map((item) => {
                return (
                  <Fragment>
                    <div className="radio__block">
                      <Checkbox
                        key={item.value}
                        onChange={handleCheckBoxChange}
                        checked={(item.value == checkbox) == "" ? "" : checkbox}
                        value={item.value}
                        id={item.value}
                      >
                        <label htmlFor={item.value}>
                          {" "}
                          {traslate(item.label)}{" "}
                        </label>
                      </Checkbox>
                    </div>
                    <div>
                      <p> {item.note} </p>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <div className="editor__section">
              <Spin spinning={emailLoading}>
                <CKEditor
                  editor={ClassicEditor}
                  data={content}
                  // config={editorConfiguration}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                />
              </Spin>
            </div>
          )}
        </div>
      </Modal>

      {/* End */}
    </React.Fragment>
  );
};

export default JewelryRetailerPermission;
