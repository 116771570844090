import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]: {
    hello: "Hola",
    ["Tools"]: "Instrumentos",
    ["Administration Tools"]: "Herramientas de administración",
    ["Application Permission"]: "Permiso de aplicación",
    ["Report Permissions"]: "Informe de permisos",
    ["Client Data Maps"]: "Mapas de datos de clientes",
    ["Client Status"]: "Estado del cliente",
    ["Create New Client"]: "Crear nueva cliente",
    ["3rd Party Diamond Imports"]: "Importaciones de diamantes de terceros",
    ["SOLR Core Client"]: "Cliente principal de SOLR",
    ["Vendor Rating"]: "Calificación de proveedor",
    ["Limits"]: "Límites",
    ["Legacy"]: "Legado",
    ["Console Upload"]: "Carga de consola",
    ["Open Source Mapping"]: "Mapeo de código abierto",
    ["Pending Syncs"]: "Sincronización pendiente",
    ["Transfer ZZ.Gemfind"]: "Transferir ZZ.Gemfind",
    ["Login Preferences"]: "Preferencias de inicio de sesión",
    ["Retailer Locator"]: "Localizador de minoristas",
    ["Block Designers For Demo"]: "Diseñadores de bloques para demostración",
    ["Settings"]: "Ajustes",
    ["Permissions"]: "permisos",
    ["Email Template"]: "Plantilla de correo electrónico",
    ["Data Maps"]: "Mapas de datos",
    ["Metal Market"]: "Mercado de metales",
    ["Trade Shows"]: "Ferias",
    ["Add Color"]: "Agregar color",
    ["Api Access Token"]: "Token de acceso API",
    ["Campaigns"]: "Campañas",
    ["Mailing List"]: "Lista de correo",
    ["Compose"]: "Componer",
    ["Report"]: "Reporte",
    ["Global Events"]: "Eventos globales",
    ["Syncs"]: "sincroniza",
    ["Tools Reports"]: "Herramientas Informes",
    ["Clients Reports"]: "Informes de clientes",
    ["Permission Reports"]: "Informes de permisos",
    ["Data Related"]: "Datos relacionados",
    ["Jewelry Log Reports"]: "Informes de registro de joyería",
    ["Diamond Report"]: "Informe de diamantes",
    ["Diamond Clicks"]: "Clics de diamantes",
    ["Extended Diamond Chart"]: "Gráfico de diamantes extendido",
    ["Diamond Clarity Report"]: "Informe de claridad del diamante",
    ["Diamond Color Report"]: "Informe de color de diamante",
    ["Diamond Cut Report"]: "Informe de corte de diamante",
    ["Diamond Shape Report"]: "Informe de forma de diamante",
    ["Diamond Certificate Report"]: "Informe de certificado de diamantes",
    ["Diamond Size Report"]: "Informe de tamaño de diamante",
    ["Jewelry Report"]: "Informe de joyería",
    ["Jewelry Chart Report"]: "Informe de gráfico de joyería",
    ["Jewelry Category Report"]: "Informe de categoría de joyería",
    ["Jewelry Metal Type Report"]: "Informe de tipo de metal de joyería",
    ["Jewelry Price Report"]: "Informe de precios de joyas",
    ["Jewelry Retailer Clicks Report"]: "Informe de clics de minoristas de joyería",
    ["Jewelry Vendor Clicks Report"]: "Informe de clics de proveedores de joyería",
    ["Catalog"]: "Catalogar",
    ["Diamond"]: "Diamante",
    ["Mined Diamond"]: "Diamante minado",
    ["Color Diamond"]: "Diamante de color",
    ["Lab Diamond"]: "diamante de laboratorio",
    ["Upload History"]: "Subir historial",
    ["Add Individual Diamond"]: "Agregar diamante individual",
    ["Add Diamond"]: "Agregar diamante",
    ["Upload Diamond File"]: "Subir archivo de diamante",
    ["Diamond Mapping"]: "Mapeo de diamantes",
    ["Manage FTP Credentials"]: "Administrar credenciales de FTP",
    ["Download Product File"]: "Descargar ficha de producto",
    ["Jewelry"]: "Joyas",
    ["Catalog List"]: "Lista de catálogo",
    ["View My Product"]: "Ver mi producto",
    ["Add Product"]: "Agregar producto",
    ["Edit Product"]: "Editar producto",
    ["Upload Product File"]: "Cargar archivo de producto",
    ["Manage Collection"]: "Administrar colección",
    ["Product Markup"]: "Marcado del producto",
    ["Add Collection"]: "Añadir colección",
    ["Data Mapping"]: "Mapeo de datos",
    ["Essential"]: "Básica",
    ["Item Identity"]: "Identidad del artículo",
    ["Configurable Products"]: "Productos configurables",
    ["Item Details"]: "detalles del artículo",
    ["Item Pricing"]: "Precio del artículo",
    ["Relational Pricing"]: "Precios relacionales",
    ["Cost Basis Pricing"]: "Precios basados ​​en costos",
    ["Item-Promo Specials"]: "Artículos-promociones especiales",
    ["Item Media"]: "Elemento multimedia",
    ["Item Sizing"]: "Tamaño del artículo",
    ["Gemstone Details"]: "Detalles de piedras preciosas",
    ["Semi-Mounts & Ring Builder"]: "Semi-Monturas y Constructor de Anillos",
    ["Watch Details"]: "Ver detalles",
    ["Additional Details"]: "Detalles adicionales",
    ["Purchase Order"]: "Orden de compra",
    ["Purchase Order History"]: "Historial de órdenes de compra",
    ["Update Purchase Order"]: "Actualizar orden de compra",
    ["Marketing"]: "Marketing",
    ["Upload Marketing Material"]: "Subir material de marketing",
    ["Appointment"]: "Cita",
    ["Apps"]: "aplicaciones",
    ["Diamond Links"]: "Enlaces de diamantes",
    ["Application Preview"]: "Vista previa de la aplicación",
    ["Dealers"]: "Distribuidores",
    ["Mined Diamond Markup"]: "Marcado de diamantes extraídos",
    ["Fancy Color Markup"]: "Marcado de color elegante",
    ["Lab Grown Markup"]: "Marcado desarrollado en laboratorio",
    ["Available Filter Settings"]: "Configuraciones de filtro disponibles",
    ["Initial Filter Settings"]: "Configuración de filtro inicial",
    ["Options"]: "Opciones",
    ["Shipping"]: "Transporte",
    ["Navigation"]: "Navegación",
    ["Shopping Cart Integration"]: "Integración del carrito de compras",
    ["Shopping Cart Configuration"]: "Configuración del carrito de compras",
    ["Api Endpoint And Authentication"]: "Api Endpoint y autenticación",
    ["Ring Builder"]: "Constructor de anillos",
    ["Vendors"]: "Vendedores",
    ["Archived Vendors"]: "Proveedores archivados",
    ["Manage Vendors"]: "Administrar proveedores",
    ["Diamond Markup"]: "Marcado de diamantes",
    ["Setting Markups"]: "Configuración de marcas",
    ["Master Link"]: "Enlace maestro",
    ["E-mail Templates"]: "Plantillas de correo electrónico",
    ["Stud Builder"]: "Constructor de montantes",
    ["Shopping Cart Integrations"]: "Integraciones del carrito de compras",
    ["Setting Markup"]: "Configuración de marcado",
    ["Website Sync"]: "Sincronización del sitio web",
    ["Sync History"]: "Historial de sincronización",
    ["OutBound Mapping"]: "Mapeo de salida",
    ["FTP Details"]: "Detalles FTP",
    ["Watch Customizer"]: "Ver Personalizador",
    ["Time Pieces"]: "piezas de tiempo",
    ["Pendant Builder"]: "Constructor colgante",
    ["Search Feature"]: "Función de búsqueda",
    ["Diamond Search"]: "Búsqueda de diamantes",
    ["Color Diamond Search"]: "Búsqueda de diamantes de colores",
    ["Lab Diamond Search"]: "Búsqueda de diamantes de laboratorio",
    ["Watches Search"]: "Búsqueda de relojes",
    ["Jewelry Search"]: "Búsqueda de joyas",
    ["Community"]: "Comunidad",
    ["Vendor Manager"]: "Gerente de proveedores",
    ["My Vendors"]: "Mis vendedores",
    ["Network"]: "La red",
    ["Out Of Network"]: "Fuera de la red",
    ["New"]: "Nueva",
    ["Recently Updated"]: "Recientemente actualizado",
    ["Invite Your Vendors"]: "Invita a tus proveedores",
    ["Popular"]: "Popular",
    ["Archived"]: "Archivada",
    ["Pending Requests"]: "solicitudes pendientes",
    ["Retailer"]: "Detallista",
    ["Jewelry Data & Permissions"]: "Permisos y datos de joyería",
    ["Pending Jewelry Request"]: "Solicitud de joyería pendiente",
    ["Diamond Data & Permission"]: "Datos y permisos de diamantes",
    ["Pending Diamond Request"]: "Solicitud de diamante pendiente",
    ["Ring Builder Retailer & Permission"]: "Permiso y minorista de Ring Builder",
    ["Pending Ring Builder Request"]: "Solicitud de creador de anillos pendiente",
    ["Retailer Profile"]: "Perfil del minorista",
    ["Jewelry Chart Reports"]: "Informes de gráficos de joyería",
    ["Help"]: "Ayuda",
    ["Report An Issue"]: "Reportar un problema",
    ["Suggest A New Feature"]: "Sugerir una nueva función",
    ["Watch Training Videos"]: "Ver vídeos de formación",

    ["Edit Profile"]: "Editar perfil",
    ["Manage User"]: "Administrar usuario",
    ["Manage Account"]: "Administrar cuenta",
    ["Manage Language"]: "Administrar idioma",
    ["Manage Currency"]: "Administrar moneda",
    ["Logout"]: "Cerrar sesión",
    ["Top Menu"]: "Menu principal",
    ["Dashboard"]: "Tablero",
    ["Contact"]: "Contacto",
    ["Contacts"]: "Contactos",
    ["Admin"]: "Administrador",

    ["Conversations"]: "Conversaciones",
    ["Terms of Service"]: "Términos de servicio",
    ["Privacy Statement"]: "Declaracion de privacidad",
    ["Copyright 2021 GemFind"]: "Derechos de autor 2021 GemFind",

    ["-Server Up-Time: Uptime info not available - please try reloading this page"]:
      "-Tiempo de actividad del servidor: la información de tiempo de actividad no está disponible; intente volver a cargar esta página",
    ["JewelCloud Speed Test"]: "Prueba de velocidad de JewelCloud",
    ["-RapNet Prices Updated:"]: "-Precios de RapNet actualizados:",
    ["Client Tools"]: "Herramientas del cliente",
    ["Select Client Type"]: "Seleccionar tipo de cliente",
    ["Client Name"]: "nombre del cliente",
    ["Selected Client Tools"]: "Herramientas de cliente seleccionadas",
    ["Name"]: "Nombre",
    ["Phone"]: "Teléfono",
    ["Type"]: "Escribe",
    ["Verified"]: "Verificada",
    ["Approved Apps"]: "Aplicaciones aprobadas",
    ["Users"]: "Usuarias",
    ["Username"]: "Nombre de usuario",
    ["Password"]: "Clave",
    ["Edit"]: "Editar",
    ["View Profile"]: "Ver perfil",
    ["Download Inventory"]: "Descargar inventario",
    ["Impersonate JewelCloud"]: "Suplantar a JewelCloud",
    ["Return to JewelCloud as Dealer 720"]: "Regresar a JewelCloud como Dealer 720",
    ["Updated"]: "Actualizada",
    ["Data Rating"]: "Clasificación de datos",
    ["Location"]: "Ubicación",
    ["Active"]: "Activa",
    ["Product Loves"]: "Producto ama",
    ["Collections"]: "Colecciones",
    ["Items"]: "Elementos",
    ["Contact Name"]: "Nombre de contacto",
    ["Vendor tags"]: "Etiquetas de proveedor",
    ["No tag to display"]: "No hay etiqueta para mostrar",
    ["Brands"]: "Marcas",
    ["Reset Password"]: "Restablecer la contraseña",
    ["User Name"]: "Nombre de usuario",
    ["Confirm Password"]: "Confirmar contraseña",

    ["App Activation Control"]: "Control de activación de aplicaciones",
    ["Select Dealer"]: "Seleccionar distribuidor",
    ["Diamond Link"]: "Enlace de diamante",
    ["Diamond Search Links"]: "Enlaces de búsqueda de diamantes",
    ["DS Ecommerce"]: "Comercio electrónico de DS",
    ["DS SSL"]: "DS SSL",
    ["DS Show PoweredBy"]: "DS Show Desarrollado por",
    ["DS LabGrownDiamonds"]: "DS LabGrownDiamonds",
    ["Jewel Cloud"]: "Nube de joya",
    ["API"]: "API",
    ["Direct feed"]: "alimentación directa",
    ["Windows App"]: "Aplicación de Windows",
    ["ML Legacy"]: "Legado de aprendizaje automático",
    ["ML2"]: "ML2",
    ["ML3"]: "ML3",
    ["ML Ecommerce"]: "Comercio electrónico de aprendizaje automático",
    ["ML SSL"]: "SSL de aprendizaje automático",
    ["ML Show PoweredBy"]: "Programa de aprendizaje automático Desarrollado por",
    ["RB Ecommerce"]: "Comercio electrónico RB",
    ["RB SSL"]: "RB SSL",
    ["RB Show PoweredBy"]: "Show de RB Desarrollado por",
    ["Tryon"]: "Pruebatelo",
    ["SB Ecommerce"]: "Comercio electrónico SB",
    ["SB SSL"]: "SB SSL",
    ["SB Show PoweredBy"]: "SB Show Desarrollado por",
    ["PB Ecommerce"]: "Comercio electrónico de PB",
    ["PB SSL"]: "PB SSL",
    ["PB Show PoweredBy"]: "PB Show Desarrollado por",
    ["Gemfind.TV"]: "Gemfind.TV",
    ["Purchase Order(PO)"]: "Orden de compra (PO)",
    ["Bridal/Fashion Separation"]: "Separación nupcial/moda",
    ["Diamond Group Discount"]: "Descuento de grupo de diamantes",
    ["POS System"]: "sistema de punto de venta",
    ["Submit"]: "Enviar",

    ["Reports Permissions"]: "Permisos de informes",
    ["All"]: "Todos",
    ["Diamond Dealers"]: "Distribuidores de diamantes",
    ["Retailers"]: "minoristas",
    ["Diamond Reports"]: "Informes de diamantes",
    ["Jewelry Reports"]: "Informes de joyería",
    ["Global Diamond Reports"]: "Informes globales de diamantes",
    ["Global Jewelry Reports"]: "Informes globales de joyería",

    ["Client Data Mapping"]: "Asignación de datos del cliente",
    ["Dealer ID"]: "Identificación del distribuidor",
    ["Dealer Company"]: "Empresa distribuidora",
    ["Website Address"]: "Dirección web",
    ["Upload"]: "Subir",
    ["InBound Data Map"]: "Mapa de datos entrantes",
    ["Outgoing Data Map"]: "Mapa de datos salientes",
    ["OutBound Sync"]: "Sincronización saliente",
    ["Set Default Mapping"]: "Establecer asignación predeterminada",

    ["Search"]: "Búsqueda",
    ["Reset"]: "Reiniciar",
    ["InActive"]: "Inactiva",
    ["Permission Type"]: "Tipo de permiso",
    ["Account Type"]: "Tipo de cuenta",
    ["VendorDiamond"]: "ProveedorDiamante",
    ["Vendor"]: "Vendedor",
    ["Vendor Status"]: "Estado del proveedor",
    ["Profile Only"]: "Solo perfil",
    ["Retailer Status"]: "Estado del minorista",
    ["Product Type"]: "tipo de producto",
    ["Action"]: "Acción",
    ["Cancel"]: "Cancelar",
    ["Save"]: "Ahorrar",
    ["Update Client Status Information"]: "Actualizar información de estado del cliente",
    ["Update Date on which Client Name is Created"]: "Fecha de actualización en la que se crea el nombre del cliente",

    ["Add New Client"]: "Agregar nueva cliente",
    ["Company Name"]: "nombre de empresa",
    ["Street Address"]: "Dirección",
    ["City"]: "Ciudad",
    ["State"]: "Estado",
    ["Zip Code"]: "Código postal",
    ["Country"]: "País",
    ["Email Address"]: "Dirección de correo electrónico",
    ["Phone Number"]: "Número de teléfono",
    ["Generate Password"]: "Generar contraseña",
    ["Create Client"]: "Crear cliente",

    ["Enable 3rd Party Diamond Imports"]: "Habilitar importaciones de diamantes de terceros",
    ["Dealer Name"]: "Nombre del comerciante",
    ["Enabled"]: "Activada",

    ["Add New Retailer To Core"]: "Agregar nuevo minorista al núcleo",
    ["Select Core"]: "Seleccionar núcleo",
    ["Enter Retailer ID"]: "Ingrese la identificación del minorista",
    ["Add"]: "Agregar",
    ["id"]: "identificación",
    ["Core Name"]: "Nombre del núcleo",
    ["Retailer ID"]: "Identificación del minorista",
    ["Retailer Name"]: "Nombre del minorista",

    ["Vendor Ratings"]: "Calificaciones de proveedores",
    ["Vendor ID"]: "Identificación del proveedor",
    ["Vendor Name"]: "Nombre del vendedor",
    ["System Ratings"]: "Calificaciones del sistema",
    ["Manual Ratings"]: "Calificaciones manuales",
    ["Modified Date"]: "Fecha de modificación",
    ["Save Vendor Rating"]: "Guardar calificación de proveedor",

    ["Limit Retailer To Diamond Dealer"]: "Limitar minorista a distribuidor de diamantes",
    ["Limit Diamond Dealer To Retailer"]: "Limitar distribuidor de diamantes a minorista",
    ["Limit Retailer To Vendor"]: "Limitar a la minorista a la vendedora",
    ["Limit Vendor To Retailer"]: "Limitar proveedor a minorista",
    ["Apply Filter"]: "Aplicar filtro",
    ["Rules"]: "Normas",
    ["Selected"]: "Seleccionada",
    ["Delete"]: "Borrar",
    ["Create"]: "Crear",
    ["Download"]: "Descargar",
    ["Rule Type"]: "Tipo de regla",
    ["Vendor/Dealer"]: "Vendedor / Distribuidor",
    ["Created Date"]: "Fecha de creación",
    ["Next"]: "Próxima",
    ["Diamond Dealer"]: "Distribuidora de diamantes",
    ["Are you sure to delete this Rule"]: "¿Estás segura de eliminar esta regla?",
    ["THIS CAN NOT BE UNDONE"]: "ESTO NO SE PUEDE DESHACER",
    ["Delete Limit Rule"]: "Eliminar regla de límite",
    ["Dealer Markup"]: "Marcado del distribuidor",

    ["(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))"]:
      "(Herramientas de administración de bases de datos para DealerID 720 (Alex) y 712 (Morgan))",
    ["Add a Dealer to all GFLinks:"]: "Agregue un distribuidor a todos los GFLinks:",
    ["Select the DealerID you want to"]: "Seleccione el ID de distribuidor que desea",
    ["to all GFLinks"]: "a todos los GFLinks",
    ["Remove a Dealer from all GFLinks:"]: "Eliminar un distribuidor de todos los GFLinks:",
    ["Remove"]: "Remover",
    ["Add a Dealer to all GFLinks Lab Diamonds:"]: "Agregue un distribuidor a todos los diamantes de laboratorio de GFLinks:",
    ["to all GFLinks Lab Diamonds"]: "a todos los diamantes de laboratorio de GFLinks",
    ["Remove a Dealer from all GFLinks Lab Diamonds:"]: "Eliminar un distribuidor de todos los diamantes de laboratorio de GFLinks:",
    ["Add DealerID"]: "Agregar ID de distribuidor",
    ["Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks."]:
      "Eliminar un distribuidor de todos los GFLinks: seleccione el ID de distribuidor que desea eliminar de todos los GFLinks.",
    ["Remove DealerID"]: "Eliminar ID de distribuidor",
    ["Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds."]:
      "Agregue un distribuidor a todos los diamantes de laboratorio de GFLinks: seleccione el ID de distribuidor que desea agregar a todos los diamantes de laboratorio de GFLinks.",
    ["Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds."]:
      "Eliminar un distribuidor de todos los diamantes de laboratorio de GFLinks: seleccione el ID de distribuidor que desea eliminar de todos los diamantes de laboratorio de GFLinks.",

    ["Add Console Upload Dealer Data"]: "Agregar consola Cargar datos del distribuidor",
    ["Create FTP"]: "Crear FTP",
    ["Dealer"]: "Distribuidora",
    ["FTP User Name"]: "Nombre de usuario FTP",
    ["FTP Password"]: "Contraseña FTP",
    ["File Type"]: "Tipo de archivo",
    ["Path"]: "Sendero",
    ["Add Update"]: "Agregar actualización",
    ["Console Upload Info"]: "Información de carga de la consola",
    ["Console Upload Edit Info"]: "Carga de consola Editar información",
    ["Enter User Name"]: "Introduzca su nombre de usuario",
    ["Enter Password"]: "Introducir la contraseña",
    ["Enter Folder Name"]: "Introduzca el nombre de la carpeta",
    ["Are you sure you want to delete"]: "¿Estás segura de que quieres eliminar?",
    ["Dealer Data"]: "Datos del distribuidor",
    ["Dealer Mapping"]: "Mapeo de distribuidores",

    ["Magento Mapping"]: "Mapeo Magento",
    ["API URL"]: "URL de la API",
    ["API Key"]: "Clave API",
    ["IsActive"]: "Está activo",
    ["Edit Megento Mapping Info"]: "Editar información de mapeo de Megento",
    ["Edit Open Source Mapping Info"]: "Editar información de mapeo de código abierto",
    ["Port No"]: "Número de puerto",
    ["Delete Dealer Mapping"]: "Eliminar asignación de distribuidores",
    ["Megento Mapping Info"]: "Información de mapeo de Megento",
    ["Open Source Mapping Info"]: "Información de mapeo de código abierto",

    ["Discard All"]: "Descartar todo",
    ["Run Valid Image Application"]: "Ejecutar aplicación de imagen válida",
    ["Sync ID"]: "ID de sincronización",
    ["Status"]: "Estado",
    ["Discard"]: "Desechar",

    ["Source JC ID"]: "ID de fuente JC",
    ["Destination JC ID"]: "ID de JC de destino",
    ["Replace All"]: "Reemplaza todo",
    ["Add & Update"]: "Agregar y actualizar",
    ["Transfer"]: "Transferir",
    ["Are you sure you want to move data from"]: "¿Estás segura de que quieres mover datos de?",
    ["account to"]: "cuenta a",
    ["account ? - You have select"]: "cuenta ? - Has seleccionado",
    ["Option"]: "Opción",

    ["Select Designer for New"]: "Seleccionar diseñadora para nueva",
    ["Note"]: "Nota",
    ["This will feature a specific vendor for the"]: "Esto contará con un proveedor específico para el",
    ["New to JewelCloud"]: "Nueva en JewelCloud",
    ["section on the login page"]: "sección en la página de inicio de sesión",
    ["Upload Logo Image for Designers"]: "Cargar imagen de logotipo para diseñadoras",
    ["Choose Image"]: "Elegir imagen",
    ["No File Choosen"]: "Ningún archivo elegido",
    ["Pixels"]: "Píxeles",
    ["Png Format"]: "formato png",
    ["Select Designer"]: "Seleccionar diseñadora",
    ["Upload Image"]: "Cargar imagen",
    ["Select Designer To Show on Login Page"]: "Seleccione la diseñadora para mostrar en la página de inicio de sesión",

    ["Admin Preferences"]: "Preferencias de administrador",
    ["MasterLink Theme"]: "Tema MasterLink",
    ["Default Theme"]: "Tema predeterminado",
    ["Retailer Locater Black Theme"]: "Localizador de minoristas Tema negro",

    ["Block Designers For Demo Retailer"]: "Diseñadores de bloques para minoristas de demostración",
    ["Designer Name"]: "Nombre del diseñador",
    ["Is Blocked ?"]: "¿Está bloqueada?",
    ["Update Selection"]: "Actualizar selección",

    ["Add New"]: "Agregar nueva",
    ["Page Number"]: "Número de página",
    ["1 Tier PageName"]: "Nombre de página de 1 nivel",
    ["2 Tier PageName"]: "Nombre de página de 2 niveles",
    ["3 Tier PageName"]: "Nombre de página de 3 niveles",
    ["Vendor Super"]: "Súper vendedor",
    ["Vendor Admin"]: "Administrador de proveedores",
    ["Vendor User"]: "Usuario vendedora",
    ["Vendor-Diamond Super"]: "Proveedor-Diamante Super",
    ["Vendor-Diamond Admin"]: "Administrador de proveedor-diamante",
    ["Vendor-Diamond User"]: "Proveedor-Usuario Diamante",
    ["Retailer Super"]: "Súper minorista",
    ["Retailer Admin"]: "Administrador de minoristas",
    ["Retailer User"]: "Usuario Minorista",
    ["Admin Super"]: "Súper administrador",
    ["Admin Admin"]: "administrador administrador",
    ["Admin User"]: "Usuario administradora",
    ["Combined Super"]: "Súper combinado",
    ["Combined Admin"]: "Administrador combinado",
    ["Combined User"]: "Usuario combinada",
    ["Organization Super"]: "Organización Súper",

    ["Email Templates"]: "Plantillas de correo electrónico",
    ["All Dealers"]: "Todas las distribuidoras",
    ["Registration Emails"]: "Correos electrónicos de registro",
    ["New Account"]: "Nueva cuenta",
    ["Forgot Password"]: "Has olvidado tu contraseña",
    ["Forgot UserName"]: "Olvidó su nombre de usuario",
    ["Account Locked"]: "Cuenta bloqueada",
    ["Retailer Emails"]: "Correos electrónicos de minoristas",
    ["Invite New Retailer"]: "Invitar a una nueva minorista",
    ["Community Emails"]: "Correos electrónicos de la comunidad",
    ["Retailer Request e-Mail to"]: "Solicitud de minorista por correo electrónico a",
    ["ACTIVE"]: "ACTIVA",
    ["GF Members"]: "Miembros del FM",
    ["Retailer e-Mail to"]: "Correo electrónico del minorista a",
    ["COMMUNITY MANAGED"]: "GESTIONADO POR LA COMUNIDAD",
    ["Non GF Members"]: "No miembros de GF",
    ["PROFILE ONLY"]: "SOLO PERFIL",
    ["LIMITED ACCOUNT"]: "CUENTA LIMITADA",
    ["Vendor Decline eMail"]: "Correo electrónico de rechazo del proveedor",
    ["Vendor Accept eMail"]: "Proveedor Aceptar correo electrónico",
    ["Request to Update Product Data"]: "Solicitud de actualización de datos de productos",
    ["Vendor Appointment"]: "Cita de proveedor",
    ["Invite New Vendor"]: "Invitar a nuevo vendedor",
    ["Contact Emails (MasterLink)"]: "Correos electrónicos de contacto (MasterLink)",
    ["Customer Registration"]: "Registro de cliente",
    ["New Appointments"]: "Nuevas Citas",
    ["Inquires (Request More Info)"]: "Consultas (Solicitar más información)",
    ["Send Mail to Retailer"]: "Enviar correo al minorista",
    ["Send Mail to Sender"]: "Enviar correo al remitente",
    ["Send Email (Email To Friend)"]: "Enviar correo electrónico (correo electrónico a una amiga)",
    ["Send Mail to Friend"]: "Enviar correo a un amigo",
    ["Drop A Hint"]: "Darle pistas",
    ["Send Mail to Recipient"]: "Enviar correo a la destinataria",
    ["New Favorite List"]: "Nueva lista de favoritos",
    ["New Favorite Items Added"]: "Nuevos artículos favoritos agregados",
    ["Customer Reset Password"]: "Cliente restablecer contraseña",
    ["Customer Reset Password (New)"]: "Cliente restablecer contraseña (nuevo)",
    ["Diamond Search Emails"]: "Correos electrónicos de búsqueda de diamantes",
    ["Request More Info"]: "Solicitar más información",
    ["Email To Friend"]: "Enviar a una amiga",
    ["Schedule View"]: "Vista de programación",
    ["Diamond Detail"]: "Detalle de diamantes",
    ["Diamond Compare"]: "Comparación de diamantes",
    ["Diamond Customer Compare"]: "Comparación de clientes de diamantes",
    ["Fancy Color Diamond Search Emails"]: "Correos electrónicos de búsqueda de diamantes de colores elegantes",
    ["Color Diamond Detail"]: "Detalle de diamantes de color",
    ["Ring Builder Emails"]: "Correos electrónicos del Creador de anillos",
    ["Drop A Hint (Infinity)"]: "Suelta una pista (infinito)",
    ["Buy Now (Infinity)"]: "Comprar ahora (Infinito)",
    ["Setting Detail"]: "Detalle de ajuste",
    ["Complete Ring Specifications"]: "Especificaciones completas del anillo",
    ["Pendant Builder Emails"]: "Correos electrónicos del constructor de colgantes",
    ["Diamond Request More Info"]: "Diamante Solicitar más información",
    ["Complete Pendant Setting"]: "Configuración colgante completa",
    ["Stud Builder Emails"]: "Correos electrónicos de Stud Builder",
    ["Request More Retailer"]: "Solicitar más minorista",
    ["SB To ScheduleView"]: "SB a ScheduleView",
    ["SB Complete Earring To ScheduleView"]: "SB Pendiente Completo A ScheduleView",
    ["Diamond Email To Friend"]: "Correo electrónico de diamante a un amigo",
    ["Store"]: "Tienda",
    ["Big Emails"]: "Grandes correos electrónicos",
    ["Email To Customer"]: "Correo electrónico a la cliente",
    ["Send Mail to Customer"]: "Enviar correo a la cliente",
    ["Send Email"]: "Enviar correo electrónico",

    ["Default Data Map"]: "Mapa de datos predeterminado",
    ["Product ID & Description"]: "Identificación y descripción del producto",
    ["Gemfind Column"]: "Columna de búsqueda de gemas",
    ["Active Status"]: "Estado activo",
    ["Category"]: "Categoría",
    ["Categories"]: "Categorías",
    ["Attributes"]: "Atributos",
    ["Gemstones"]: "piedras preciosas",
    ["Watches"]: "Relojes",
    ["Save Details"]: "Guardar detalles",
    ["Create"]: "Crear",

    ["Manage Current Metal Market"]: "Administrar el mercado de metales actual",
    ["Current Metal Price"]: "Precio actual del metal",
    ["Silver"]: "Plata",
    ["Gold"]: "Oro",
    ["Platinum"]: "Platino",
    ["Palladium"]: "Paladio",
    ["Enter New Metal Type"]: "Introducir nuevo tipo de metal",

    ["Trade Show"]: "Feria",
    ["Show"]: "Espectáculo",
    ["Dates"]: "fechas",
    ["Add Trade Show"]: "Agregar feria comercial",
    ["Edit Trade Show"]: "Editar feria comercial",
    ["JPG Format"]: "Formato JPG",
    ["Show Name"]: "Mostrar nombre",
    ["Website"]: "Sitio web",
    ["Show Start Time"]: "Mostrar hora de inicio",
    ["Show End Time"]: "Mostrar hora de finalización",
    ["AM"]: "SOY",
    ["Closed"]: "Cerrada",
    ["PM"]: "PM",
    ["Show Start Dates"]: "Mostrar fechas de inicio",
    ["Show End Dates"]: "Mostrar fechas de finalización",
    ["Delete Trade Show"]: "Eliminar feria comercial",
    ["Are You Sure Want to delete"]: "¿Estás segura de que quieres eliminar?",

    ["Sr. No."]: "No Señor.",
    ["Color Name"]: "Nombre del color",
    ["Edit Color"]: "Editar color",

    ["API Type"]: "Tipo de API",
    ["Update"]: "Actualizar",

    ["Mailing Lists"]: "Listas de correo",
    ["Alias Address"]: "Dirección de alias",
    ["Number Of Recipients"]: "Número de destinatarias",
    ["Created"]: "Creada",
    ["Recipients"]: "Destinatarias",
    ["Sync"]: "sincronizar",
    ["Recipient Address"]: "Dirección de la destinataria",
    ["Client"]: "Cliente",

    ["Select Campaign"]: "Seleccionar campaña",
    ["Campaign Name"]: "Nombre de campaña",
    ["Submitted"]: "Presentada",
    ["Delivered"]: "Entregada",
    ["Total Opens"]: "Aperturas totales",
    ["Total Clicks"]: "Total de clics",

    ["Select Compose"]: "Seleccione Redactar",
    ["From Name"]: "De nombre",
    ["From Email"]: "Desde el e-mail",
    ["Type Your Subject"]: "Escribe tu asunto",
    ["Send"]: "Enviar",

    ["From Date"]: "Partir de la fecha",
    ["To Date"]: "Hasta la fecha",
    ["Type"]: "Escribe",
    ["Initiated"]: "Iniciado",
    ["Complete"]: "Completa",

    ["Sync Type"]: "Tipo de sincronización",
    ["Show Full Syncs"]: "Mostrar sincronizaciones completas",
    ["Show Update Syncs"]: "Mostrar sincronizaciones de actualización",
    ["Show Delete Syncs"]: "Mostrar eliminar sincronizaciones",
    ["Show Price Syncs"]: "Mostrar sincronizaciones de precios",
    ["Sync Status"]: "Estado de sincronización",
    ["Show Success Syncs"]: "Mostrar sincronizaciones exitosas",
    ["Show Failed Syncs"]: "Mostrar sincronizaciones fallidas",
    ["Show Pending Syncs"]: "Mostrar sincronizaciones pendientes",

    ["Mastelink"]: "Mastelink",
    ["Watch Builder"]: "Generador de relojes",
    ["Version"]: "Versión",

    ["Diamonds"]: "Diamantes",
    ["Open Requests"]: "Solicitudes abiertas",
    ["Last Updated"]: "Última actualización",
    ["Days Old"]: "Viejos días",
    ["Details"]: "Detalles",

    ["Metal Types"]: "Tipos de metales",
    ["Metal Colors"]: "Colores Metálicos",
    ["Diamond Pairs"]: "pares de diamantes",
    ["Default Mapping Values"]: "Valores de asignación predeterminados",
    ["Heirachy"]: "Jerarquía",

    ["Count"]: "Contar",
    ["Last Upload Date"]: "Última fecha de carga",

    ["Select Diamond"]: "Seleccionar diamante",
    ["Top Diamond Attribute Values"]: "Principales valores de atributos de diamantes",
    ["Recent Diamond Attribute Searches"]: "Búsquedas recientes de atributos de diamantes",
    ["Depth"]: "Profundidad",
    ["Table"]: "Mesa",
    ["Measurements"]: "Mediciones",
    ["Date"]: "Fecha",
    ["Shape"]: "Forma",
    ["Size"]: "Tamaño",
    ["Color"]: "Color",
    ["Cut"]: "Corte",
    ["Clarity"]: "Claridad",
    ["Price"]: "Precio",
    ["UsersIPAddress"]: "UsuariosIPAddress",
    ["Day & Time"]: "Día y hora",
    ["Certificates"]: "Certificados",
    ["Retail Price"]: "Precio al por menor",
    ["Cost"]: "Costo",

    ["Extended Diamond Chart Reports"]: "Informes extendidos de gráficos de diamantes",
    ["Vendor(s)"]: "Vendedores)",
    ["Retailer(s)"]: "Minorista(s)",
    ["Diamond Cut Searches"]: "Búsquedas de corte de diamante",
    ["Diamond Shape Searches"]: "Búsquedas de forma de diamante",
    ["Diamond Clarity Searches"]: "Búsquedas de claridad de diamantes",
    ["Diamond Certificate Searches"]: "Búsquedas de certificados de diamantes",
    ["Displays the total number of jewelry clicks through the currently specified date range above"]:
      "Muestra el número total de clics en joyas durante el rango de fechas especificado actualmente arriba",

    ["Download CSV"]: "Descargar CSV",
    ["Total Jewelry Clicks"]: "Clics totales en joyas",
    ["Jewelry Clicks Based on Category"]: "Clics en joyas según la categoría",
    ["Jewelry Clicks Based on Price"]: "Clics en joyas según el precio",
    ["Jewelry Clicks Based on Vendor"]: "Clics en joyas según el proveedor",

    ["Product Image"]: "Imagen del producto",
    ["User IP Address"]: "Dirección IP del usuario",
    ["Collection"]: "Recopilación",
    ["Style Number"]: "Número de estilo",
    ["Metal Type"]: "Tipo de metal",
    ["Metal Color"]: "Color metálico",

    ["Show Filter"]: "Mostrar filtro",
    ["Hide Filter"]: "Ocultar filtro",
    ["Shapes"]: "formas",
    ["Round"]: "Redonda",
    ["Oval"]: "Oval",
    ["Circel"]: "Circulo",
    ["Heart"]: "Corazón",
    ["Emarald"]: "Esmeralda",
    ["Radiant"]: "Radiante",
    ["Caret"]: "Signo de intercalación",
    ["Carats"]: "Quilates",
    ["Polish"]: "Polaca",
    ["Length(mm)"]: "Longitud (mm)",
    ["Width(mm)"]: "Ancho (mm)",
    ["height(mm)"]: "altura (mm)",
    ["Symmetry"]: "Simetría",
    ["Girdle"]: "Faja",
    ["Crown Angle"]: "Ángulo de la corona",
    ["Pavilion Angle"]: "Ángulo del pabellón",
    ["Culet"]: "Culet",
    ["Culet Condition"]: "Condición de Culet",
    ["Fluorescence"]: "Fluorescencia",
    ["Fancy Color"]: "Color de lujo",
    ["Intensity"]: "Intensidad",
    ["Overtone"]: "Armónica",
    ["Matched Pairs"]: "Pares emparejados",
    ["Has Video"]: "Tiene vídeo",
    ["Reset Filters"]: "Restablecer filtros",
    ["Apply Filters"]: "Aplicar filtros",
    ["You Have"]: "Tú tienes",
    ["View"]: "Vista",
    ["Print"]: "Impresión",
    ["View Details"]: "Ver detalles",
    ["Contact Dealer"]: "Comuníquese con el distribuidor",
    ["Message to B&H Diamonds"]: "Mensaje a B&H Diamantes",
    ["Contact the dealer regarding"]: "Póngase en contacto con el distribuidor con respecto a",
    ["GemFind"]: "GemFind",
    ["Carat Weight"]: "Peso en quilates",
    ["Treatment"]: "Tratamiento",
    ["Crown"]: "Corona",
    ["Lab"]: "Laboratorio",
    ["Matched Pair Stock"]: "Stock de pares emparejados",
    ["Seller"]: "Vendedor",
    ["Stock"]: "Valores",
    ["Stock #"]: "Valores #",
    ["Off %"]: "Apagado %",
    ["Flour"]: "Harina",
    ["Dep."]: "dep.",
    ["Measure"]: "Medida",
    ["Cert."]: "Cert.",
    ["Sym"]: "Sim",
    ["Image/Video"]: "Imagen/Vídeo",
    ["Video"]: "Video",
    ["Close"]: "Cerca",

    ["Colors"]: "Colores",
    ["Blue"]: "Azul",
    ["Orange"]: "Naranja",
    ["Green"]: "Verde",
    ["Yellow"]: "Amarilla",
    ["Red"]: "Roja",
    ["Maroon"]: "Granate",
    ["Permission"]: "Permiso",
    ["Fancy Intensity"]: "Intensidad de fantasía",
    ["Overtone 1"]: "Entonado 1",
    ["Overtone 2"]: "Entonado 2",
    ["Overall Price"]: "Precio total",
    ["Price per Caret Range"]: "Precio por rango de intercalación",
    ["Eye Clean"]: "limpieza de ojos",
    ["Fluorescence Intensity"]: "Intensidad fluorescente",
    ["Fluorescence Color"]: "Color de fluorescencia",
    ["L/W Ratio Measurements"]: "Mediciones de relación L/W",
    ["LW Ratio"]: "Relación LW",
    ["Any"]: "Ningún",
    ["Square"]: "Cuadrada",
    ["Rectangle"]: "Rectángulo",
    ["Shade"]: "Sombra",
    ["Central Inclusion Range"]: "Rango de inclusión central",
    ["Black Inclusion Range"]: "Rango de inclusión negra",
    ["Milky Inclusion Range"]: "Rango de inclusión lechosa",
    ["Girdle Range"]: "Gama de fajas",
    ["Crown Angle % Range"]: "Ángulo de corona % Rango",
    ["Pavilion Angle % Range"]: "Pabellón Ángulo % Rango",
    ["Key to Symbols"]: "Clave de los símbolos",
    ["Culet Range"]: "Gama Culet",
    ["Culet Condition Range"]: "Rango de condición de culet",
    ["price(Cost)"]: "precio de coste)",

    ["There are"]: "Existen",
    ["results for your search"]: "resultados de tu busqueda",
    ["Material Type"]: "tipo de material",
    ["Gender"]: "Género",
    ["Material Color"]: "Material Color",
    ["Gemstone"]: "Piedra preciosa",
    ["Retaile Stock #"]: "Número de existencias al por menor",
    ["Retail"]: "Venta minorista",
    ["Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."]:
      "El género no es un campo obligatorio para los datos del proveedor, por lo que si lo deja en blanco, la mayoría de los resultados del producto se considerarán Damas.",
    ["Please select a vendor before choosing a collection."]: "Seleccione un proveedor antes de elegir una colección.",
    ["Retail Stock #"]: "Número de existencias al por menor",
    ["$ cost"]: "$ costo",
    ["$ Retail"]: "$ al por menor",

    ["Store Name"]: "Nombre de la tienda",
    ["Retailer Type"]: "Tipo de minorista",
    ["Requested Date"]: "Fecha solicitada",
    ["Request Date"]: "Fecha de solicitud",
    ["My Retailer"]: "Mi distribuidor",
    ["My Retailers"]: "Mis minoristas",
    ["Jewelry API"]: "API de joyería",
    ["All Retailer"]: "Todos los minoristas",
    ["All Retailers"]: "Todos los minoristas",

    ["Profile"]: "Perfil",
    ["Conversation"]: "Conversación",
    ["Notes"]: "notas",
    ["Appointments"]: "Equipo",
    ["Time"]: "Tiempo",
    ["Buyer Name"]: "Nombre del comprador",

    ["Action Request"]: "Solicitud de acción",

    ["Activity"]: "Actividad",
    ["Customer"]: "Cliente",

    ["Load More"]: "Carga más",

    ["View My Mined Diamond"]: "Ver mi diamante extraído",
    ["Are you sure to delete this diamond"]: "¿Estás segura de eliminar este diamante?",

    ["View My Lab Diamond"]: "Ver mi diamante de laboratorio",

    ["View My Color Diamond"]: "Ver Mi Color Diamante",

    ["Diamond Upload History"]: "Historial de carga de diamantes",
    ["Data Upload"]: "Carga de datos",
    ["Image Upload"]: "Carga de imagen",
    ["File"]: "Expediente",
    ["Records"]: "Registros",

    ["Inventory #"]: "Inventario #",
    ["Matched Pair Stock #"]: "Número de inventario del par emparejado",
    ["Stones (Parcel)"]: "Piedras (Paquete)",
    ["Separable"]: "Separable",
    ["Fancy Codes"]: "Códigos de lujo",
    ["Fancy Names"]: "Nombres elegantes",
    ["Other"]: "Otra",
    ["Cut Grade"]: "Grado de corte",
    ["Certificate #"]: "Certificado #",
    ["Pavillion Angle"]: "Ángulo del pabellón",
    ["Comments"]: "Comentarios",
    ["Store Location"]: "Ubicación de la tienda",
    ["Employee Name"]: "Nombre de empleado",
    ["Employee ID"]: "ID de empleado",
    ["Out On Memo"]: "fuera en memorándum",
    ["Seen by Other Dealers"]: "Visto por otras distribuidoras",
    ["Add Video"]: "Añadir video",

    ["Upload Your Data File"]: "Cargue su archivo de datos",
    ["Add Individual"]: "Agregar individuo",
    ["Upload Certs"]: "Cargar certificados",
    ["Download Data"]: "Descargar datos",
    ["Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours."]:
      "Por favor, mantenga su inventario actualizado. Su inventario de diamantes se eliminará si no se actualiza al menos cada 48 horas.",
    ["Choose File"]: "Elija el archivo",
    ["Xls/Txt/Csv Format"]: "Formato Xls/Txt/Csv",
    ["Xls/Txt/Csv/Json Format"]: "Formato Xls/Txt/Csv/Json",
    ["Upload Data"]: "Subir datos",
    ["Upload Diamond Images & Videos"]: "Cargar imágenes y videos de diamantes",
    ["Jpg/Png Format"]: "Formato jpg/png",
    ["Compress images together & upload in a .zip format."]: "Comprima imágenes juntas y cárguelas en formato .zip.",
    ["Download our image guidelines documentation"]: "Descargue nuestra documentación de pautas de imagen",
    ["Import Data From 3rd Party Provider"]: "Importar datos de un proveedor externo",
    ["Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit"]:
      "Las cargas de diamantes están limitadas a 50 000 diamantes para garantizar la velocidad y la confiabilidad del sistema. Los archivos de diamantes con más de 50,000 diamantes se eliminarán, así que revise a sus proveedores de diamantes para asegurarse de que está dentro del límite de diamantes.",
    ["Add new diamonds in bulk or make mass item updates"]: "Agregue nuevos diamantes a granel o realice actualizaciones masivas de artículos",
    ["First time importing?"]: "Importando por primera vez?",
    ["Import Your Content"]: "Importa tu contenido",
    ["Choose & upload your data or connect to a 3rd party"]: "Elija y cargue sus datos o conéctese a un tercero",
    ["Wait For Confirmation"]: "Espere la confirmación",
    ["You will receive an e-mail confirmation when your content has been uploaded."]:
      "Recibirá una confirmación por correo electrónico cuando se haya cargado su contenido.",
    ["Inventory with Dealer Stock # not included in the upload will be deleted."]:
      "Se eliminará el inventario con número de inventario del distribuidor no incluido en la carga.",
    ["Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."]:
      "Se agregará el inventario con el número de inventario del nuevo distribuidor y se actualizará el número de inventario del distribuidor existente. El número de inventario del distribuidor no incluido permanecerá intacto.",

    ["Select Data file to be"]: "Seleccione el archivo de datos para ser",
    ["Mapped"]: "Mapeada",
    ["Upload File"]: "Subir archivo",
    ["Reset the data map"]: "Restablecer el mapa de datos",
    ["View / Edit the map"]: "Ver/Editar el mapa",
    ["Manage FTP settings"]: "Administrar la configuración de FTP",
    ["Generate File"]: "Generar archivo",
    ["GF Column ID"]: "ID de columna GF",
    ["Your Column"]: "Tu columna",

    ["Manage FTP Credential"]: "Administrar Credencial FTP",
    ["FTP URL"]: "URL FTP",

    ["Request Access"]: "Solicitar acceso",
    ["Your Diamonds"]: "tus diamantes",
    ["Connected Diamonds"]: "Diamantes conectados",
    ["Total Diamonds"]: "Diamantes totales",
    ["Lab Diamonds"]: "diamantes de laboratorio",
    ["Color Diamond Count"]: "Número de diamantes de color",
    ["Your Products"]: "Tus productos",
    ["Connected Products"]: "Productos conectados",
    ["Active Products"]: "Productos Activos",
    ["EDGE"]: "BORDE",
    ["Import Type"]: "Tipo de importación",

    ["Update History"]: "Historial de actualizaciones",

    ["Create Product"]: "Crear producto",
    ["More Actions"]: "Mas acciones",
    ["Total"]: "Total",
    ["Product"]: "Producto",
    ["Select All"]: "Seleccionar todo",
    ["Images"]: "Imágenes",
    ["Style No"]: "Sin estilo",
    ["GFinventory No"]: "GFinventario No",
    ["Product Name"]: "nombre del producto",
    ["Wholesale Price"]: "Precio al por mayor",
    ["Gemfind#"]: "Gemfind#",
    ["Feature"]: "Rasgo",
    ["Are you sure you want to delete this Product(s)?"]: "¿Está seguro de que desea eliminar este(s) Producto(s)?",

    ["Add Image"]: "Añadir imagen",
    ["OR"]: "O",
    ["Load Image From Server"]: "Cargar imagen desde el servidor",
    ["Load Image"]: "Cargar imagen",
    ["Retail Description"]: "Descripción minorista",
    ["Style #"]: "Estilo #",
    ["This must be a unique item number identifying the particular item or the"]:
      "Debe ser un número de artículo único que identifique el artículo en particular o el",
    ["parent"]: "madre",
    ["item which has many variations - if this is a style root then you will want to set this iem as a"]:
      "elemento que tiene muchas variaciones: si se trata de una raíz de estilo, querrá configurar este elemento como un elemento",
    ["configurable product"]: "producto configurable",
    ["to configure the variables such as carat weight, metal type, diamond quality, length etc."]:
      "para configurar las variables como el peso en quilates, el tipo de metal, la calidad del diamante, la longitud, etc.",
    ["Dealer Stock #"]: "Número de inventario del distribuidor",
    ["This is a unique identification number of this particular item."]: "Este es un número de identificación único de este artículo en particular.",
    ["Period"]: "Período",
    ["Visibility"]: "Visibilidad",

    ["Additional"]: "Adicional",
    ["Qty On Hand"]: "Cantidad disponible",
    ["Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory."]:
      "Pregúntenos cómo podemos establecer una conexión automática a su sistema de inventario y realizar un seguimiento dinámico de la disponibilidad de su inventario virtual.",
    ["Display Order"]: "Orden de visualización",
    ["This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive."]:
      "Esto se refiere al orden en que aparecerá el elemento dentro de su categoría: primero el número más bajo. Los artículos con el mismo número se ordenan en función del menos costoso al más costoso.",
    ["Setting"]: "Ajuste",
    ["Finishing Technique"]: "Técnica de acabado",
    ["Clasp Type"]: "Tipo de cierre",
    ["Chain Type"]: "Tipo de cadena",
    ["Back Finding"]: "Volver a encontrar",
    ["Additional Information"]: "Información Adicional",
    ["Additional Information 2"]: "Información adicional 2",

    ["Sizing"]: "Dimensionamiento",
    ["General"]: "General",
    ["Weight"]: "Peso",
    ["grams"]: "gramos",
    ["dwt"]: "TPM",
    ["Thickness"]: "Espesor",
    ["mm"]: "milímetro",
    ["inches"]: "pulgadas",
    ["Width"]: "Ancho",
    ["Dimensions"]: "Dimensiones",
    ["Unit Of Measure"]: "Unidad de medida",
    ["Finger Size"]: "Tamaño del dedo",
    ["Stock Finger Size"]: "Tamaño de dedo común",
    ["Min Finger Size"]: "Tamaño mínimo del dedo",
    ["Max Finger Size"]: "Tamaño máximo de dedo",
    ["Finger Size Increment"]: "Incremento del tamaño del dedo",
    ["Price Type"]: "Tipo de precio",
    ["Additional Price"]: "Precio adicional",

    ["Pricing"]: "Precios",
    ["Wholesale Price Method"]: "Método de precio al por mayor",
    ["'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'"]:
      "'Manual' significa que debe establecer el precio manualmente. 'Relacional' significa que el sistema GF utiliza un 'Factor de precio relacional' para ajustar el precio en función de los precios actuales del mercado de metales. 'Base de costo' significa que proporciona el desglose de componentes del artículo y ajusta los factores de precio global para volver a calcular el precio cada día en función de las condiciones actuales del mercado de metales. Si el campo de precio mayorista O los campos de precio minorista están en blanco, entonces el sistema mostrará ' Llamar para preguntar el precio'",
    ["Wholesale Base Price"]: "Precio base mayorista",
    ["Base Metal Market"]: "Mercado de metales comunes",
    ["Price Factor"]: "factor de precio",
    ["Calculate"]: "Calcular",
    ["Relational Price"]: "Precio relacional",
    ["Relational Metal Market"]: "Mercado Relacional de Metales",
    ["Metal Labor Code"]: "Código Laboral Metalúrgico",
    ["Other Labor Code"]: "Otro Código Laboral",
    ["Metal Factor Code"]: "Código de factor de metal",
    ["GPM Code"]: "Código GPM",
    ["Retail Price Method"]: "Método de precio minorista",
    ["MSRP"]: "MSRP",
    ["To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At."]:
      "Para mejorar la calidad de los datos, háganos saber cómo desea que se administren los precios de este producto. 1 - Hacer cumplir el MSRP. 2 - Proporcione un 'Precio de venta sugerido' y permita márgenes de venta al por menor. 3 - Permitir márgenes comerciales. 4 - Consultar precio. 5 - Precios desde.",

    ["Related"]: "Relacionada",
    ["Matching Styles"]: "Estilos coincidentes",
    ["Matching Sku's are items from the same ensamble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas."]:
      "Los Sku a juego son artículos del mismo conjunto, como un anillo, pendientes y un colgante a juego. Ingrese la identificación del distribuidor o el número de estilo separados por comas.",
    ["Up Sell Styles"]: "Estilos de venta ascendente",
    ["Up sell Sku's are items that are related by at slightly higher price points."]:
      "Los Sku de venta superior son artículos que están relacionados con puntos de precio ligeramente más altos.",
    ["Grouped Product Styles"]: "Estilos de productos agrupados",
    ["There is no content."]: "No hay contenido.",

    ["Media"]: "Medios de comunicación",
    ["Choose Video"]: "Elija vídeo",
    ["Load Video From URL"]: "Cargar video desde URL",
    ["Load Video"]: "Cargar vídeo",
    ["Add Video Type"]: "Agregar tipo de video",
    ["Label"]: "Etiqueta",

    ["Bullet Point 1"]: "Punto de viñeta 1",
    ["Bullet Point 2"]: "viñeta 2",
    ["Bullet Point 3"]: "Punto de viñeta 3",
    ["Bullet Point 4"]: "Punto de viñeta 4",
    ["Bullet Point 5"]: "Punto de viñeta 5",
    ["Discount A"]: "Descuento A",
    ["Qty 1"]: "Cantidad 1",
    ["Qty 2"]: "Cantidad 2",
    ["Qty 3"]: "Cantidad 3",
    ["Qty 4"]: "Cantidad 4",
    ["Qty 5"]: "Cantidad 5",
    ["Retailer Brand Name"]: "Nombre de la marca del minorista",
    ["Secondary Metal Type"]: "Tipo de metal secundario",
    ["Amazon Product"]: "Producto de Amazon",

    ["Total Diamond Weight"]: "Peso total del diamante",
    ["Total Gemstone Weight"]: "Peso total de piedras preciosas",
    ["Gemstone Type"]: "Tipo de piedra preciosa",
    ["Dimension Unit Of Measure"]: "Dimensión Unidad de medida",
    ["Number Of Gemstones"]: "Número de piedras preciosas",
    ["Gemstone Shape"]: "forma de piedra preciosa",
    ["Gemstone Origin"]: "Origen de la piedra preciosa",
    ["Gemstone Carat Weight"]: "Peso en quilates de piedras preciosas",
    ["Gemstone Dimensions"]: "Dimensiones de las piedras preciosas",
    ["Gemstone Quality"]: "Calidad de la piedra preciosa",

    ["Watch"]: "Reloj",
    ["Band Material"]: "Material de la banda",
    ["Number Type"]: "Tipo de número",
    ["Band Type"]: "Tipo de banda",
    ["Case Material"]: "Material de la Caja",
    ["Case Shape"]: "Forma de la caja",
    ["Comes Packaged In"]: "Viene empaquetado en",
    ["Crystal Type"]: "Tipo de cristal",
    ["Dial Color"]: "Color de la esfera",
    ["Manufacture Date"]: "Fecha de fabricacion",
    ["Display Type"]: "Tipo de visualización",
    ["Certification"]: "Certificación",
    ["Movement"]: "Movimienot",
    ["Energy"]: "Energía",

    ["Save Product"]: "Guardar producto",
    ["Cancel Product"]: "Cancelar producto",

    ["Upload Your Images"]: "Sube tus imágenes",
    ["Please wait while file is Uploading.."]: "Espere mientras se carga el archivo..",
    ["Backgrounds should be white or transparent."]: "Los fondos deben ser blancos o transparentes.",
    ["Images should be larger than 500 x 500 pixels."]: "Las imágenes deben tener más de 500 x 500 píxeles.",
    ["Products should occupy more than 80% of canvas."]: "Los productos deben ocupar más del 80% del lienzo.",
    ["Questions? View the"]: "¿Preguntas? Ver el",
    ["Image Guidelines"]: "Pautas de imagen",
    ["Help Doc"]: "Documento de ayuda",
    ["Your File is InProgress"]: "Su archivo está en progreso",
    ["Please click here to see history"]: "Por favor haga clic aquí para ver la historia",
    ["Load Images From My Server"]: "Cargar imágenes desde mi servidor",
    ["If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple"]:
      "Si desea agregar imágenes de joyería directamente desde un servidor como: https://www.gemfind.com/zoomimages/80785.jpg, marque esta casilla como marcada. También verifique que también haya establecido la columna IsImageFromUrl en su archivo. Si configuró 1 en esta columna, la imagen se cargará directamente desde el servidor y si configuró 0, imagepath se tratará como simple",
    ["Jewelry Import"]: "Importación de joyas",
    ["Add new jewelry items in bulk or make mass item updates."]: "Agregue nuevos artículos de joyería a granel o realice actualizaciones masivas de artículos.",
    ["Map Your Content"]: "Mapea tu contenido",
    ["Match your categories with the JewelCloud industry standard making your content compatible with our network and applications."]:
      "Haga coincidir sus categorías con el estándar de la industria JewelCloud haciendo que su contenido sea compatible con nuestra red y aplicaciones.",
    ["Required if your data file is not in our format."]: "Obligatorio si su archivo de datos no está en nuestro formato.",
    ["Download Templates"]: "Descargar plantillas",
    ["Required Fields Form"]: "Formulario de campos obligatorios",
    ["Simple Product Forms"]: "Formularios de productos simples",
    ["Ring Builder Data Form"]: "Formulario de datos del creador de anillos",
    ["Configurable Product Form"]: "Formulario de producto configurable",
    ["Help Docs"]: "Documentos de ayuda",
    ["Image Guideline"]: "Guía de imagen",
    ["Progress"]: "Progreso",
    ["Ok"]: "OK",
    ["Uploaded"]: "subido",
    ["Uploading file"]: "Cargando archivo",

    ["Create Collection"]: "Crear colección",
    ["All Collections"]: "Todas las colecciones",
    ["House Brand Collections"]: "Colecciones de marca de la casa",
    ["Vendor Collections"]: "Colecciones de proveedores",
    ["Vendor Extensions"]: "Extensiones de proveedor",
    ["Collection Name"]: "Nombre de la colección",
    ["Brand Name"]: "Nombre de la marca",
    ["Override"]: "Anular",
    ["Enable"]: "Habilitar",
    ["Markup Override"]: "Anulación de marcado",
    ["Disable"]: "Deshabilitar",
    ["Percentage"]: "Porcentaje",
    ["Delete Collection"]: "Eliminar colección",
    ["Are you sure , you want to delete this Collection."]: "¿Está seguro de que desea eliminar esta colección?",

    ["content not provided."]: "contenido no proporcionado.",
    ["General Settings"]: "Configuración general",
    ["Currency"]: "Divisa",
    ["Rounding"]: "Redondeo",
    ["Default Markup"]: "Marcado predeterminado",
    ["Markup"]: "Margen",
    ["Mrk"]: "Señor",
    ["Markup By Price Range"]: "Marcado por rango de precio",
    ["Markup By Size Range"]: "Marcado por rango de tamaño",
    ["Min Price"]: "Precio mínimo",
    ["Max Price"]: "Precio máximo",
    ["Mark Up"]: "Margen",
    ["Min Size"]: "Tamaño mínimo",
    ["Max Size"]: "Tamaño máximo",

    ["Overrides"]: "Anulaciones",
    ["Save Pricing"]: "Guardar precios",

    ["Edit Collection"]: "Editar colección",
    ["Create A Collection"]: "Crear una colección",
    ["Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand"]:
      "Artículos que agregas para el uso personal de tu tienda. Las colecciones se pueden agregar a un proveedor de JewelCloud como una extensión de su perfil oficial o su propia marca de la casa",
    ["View The Training Video"]: "Ver el video de capacitación",
    ["Collection Description"]: "Descripción de la colección",
    ["Collection Display Order"]: "Orden de visualización de la colección",
    ["Navigation Category"]: "Categoría de navegación",
    ["Bridal"]: "Nupcial",
    ["Fashion"]: "Moda",
    ["Giftware"]: "artículos de regalo",
    ["Save Collection"]: "Guardar colección",

    ["Import Mapping"]: "Importar asignación",
    ["Export Mapping"]: "Exportar asignación",
    ["Add New Column"]: "Agregar nueva columna",
    ["Attribute"]: "Atributo",
    ["JewelCloud defined column name."]: "Nombre de columna definido por JewelCloud.",
    ["This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."]:
      "Esto define qué tipo de datos está permitido. Etiquetas fijas significa que debe usar un valor aprobado de JewelCloud. Numeric solo puede contener números y decimales.",
    ["Requirement"]: "Requisito",
    ["Data uploads will fail if these columns do not contain a value."]: "La carga de datos fallará si estas columnas no contienen un valor.",
    ["Your Attribute"]: "tu atributo",
    ["Non Mapped"]: "No mapeada",
    ["Accepted Values"]: "Valores aceptados",
    ["Required"]: "Requerida",
    ["Example"]: "Ejemplo",
    ["Feild Type"]: "Tipo de campo",
    ["Configurable"]: "Configurable",
    ["Definition"]: "Definición",
    ["Remaining"]: "Restante",
    ["Drag & Drop Your Attributes"]: "Arrastra y suelta tus atributos",
    ["Match Your attributes with the jewel cloud industry standard"]: "Haga coincidir sus atributos con el estándar de la industria de la nube joya",
    ["Attribute Value Map"]: "Mapa de valor de atributo",
    ["Match Your Variables to the jewelCloud Industry Standard"]: "Haga coincidir sus variables con el estándar de la industria jewelCloud",
    ["Your sheet just needs your column headers."]: "Su hoja solo necesita los encabezados de sus columnas.",
    [".xls | .txt | .csv"]: ".xls | .txt | .csv",
    ["Choose Format"]: "Elija formato",
    ["No content"]: "Sin contenido",
    ["Comma"]: "Coma",
    ["Tab"]: "Pestaña",
    ["Upload Sheet"]: "Cargar hoja",
    ["Import Mapping Template"]: "Importar plantilla de mapeo",
    ["Edge TPW"]: "Borde TPW",
    ["Arms"]: "Brazos",
    ["Edge EDT"]: "Borde EDT",
    ["Load File"]: "Cargar archivo",
    ["Attribute A New Mappable Column"]: "Atribuir una nueva columna asignable",
    ["Add A Single New Column Header."]: "Agregue un solo encabezado de columna nuevo.",
    ["Column ID"]: "Id. de columna",
    ["Column Name"]: "Nombre de columna",
    ["Image Upload Settings"]: "Configuración de carga de imágenes",
    ["Import Multiple"]: "Importar varios",
    ["True"]: "Verdadero",
    ["False"]: "Falsa",
    ["Select Delimiter"]: "Seleccionar delimitador",
    ["( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images."]:
      "( |(signo de tubería) ,(coma) ) Utilice este delimitador único para separar las imágenes.",
    ["Add Price Type Override"]: "Agregar anulación de tipo de precio",
    ["Enforce MSRP"]: "Hacer cumplir MSRP",
    ["SuggestedRetail Price & Allow Retail Markups"]: "Precio minorista sugerido y márgenes de beneficio minorista permitidos",
    ["Allow Retail Markups"]: "Permitir márgenes comerciales",
    ["Call For Price"]: "Llamar para preguntar el precio",
    ["Prices Starting at"]: "Precios desde",
    ["Allow price type override"]: "Permitir anulación de tipo de precio",
    ["Reset The Map"]: "Restablecer el mapa",
    ["Yikes...Go Back"]: "Vaya... Volver",
    ["Are your sure you want to reset the Data Map?"]: "¿Está seguro de que desea restablecer el mapa de datos?",
    ["THERE IS NO UNDO"]: "NO HAY DESHACER",

    ["Diamond Chart Reports"]: "Informes de gráficos de diamantes",
    ["Export to PDF"]: "Exportar a PDF",
    ["Diamond Clicks for GemFind (720)"]: "Clics de diamantes para GemFind (720)",
    ["Events"]: "Eventos",
    ["Download PDF"]: "Descargar PDF",
    ["IPAddress"]: "Dirección IP",
    ["Lab Grow Diamond"]: "laboratorio crecer diamante",
    ["Round to the nearest 1"]: "Redondea al 1 más cercano",

    ["Diamond Cut Grade Report"]: "Informe de grado de corte de diamante",

    ["Location Activity"]: "Actividad de ubicación",
    ["Types"]: "Tipos",

    ["Accept New Collection"]: "Aceptar nueva colección",
    ["If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"]:
      "",

    ["Retailer Affirmation"]: "Afirmación de minorista",
    ["Current Authorized Reseller"]: "Revendedor autorizada actual",
    ["By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website."]:
      "Al marcar esta casilla y hacer clic en el botón Enviar a continuación, afirmo que GemFind tiene una cuenta abierta y activa con A & D Gem Corp, que soy un revendedor autorizado de A & D Gem Corp y que tengo permiso para tener su cuenta virtual inventario en mi sitio web.",
    ["New Account Request"]: "Solicitud de nueva cuenta",
    ["I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp."]:
      "Estoy interesado en abrir una nueva cuenta con A & D Gem Corp y convertirme en un revendedor autorizado. Al marcar esta casilla y hacer clic en el botón Enviar a continuación, su nombre e información de la tienda se enviarán a A & D Gem Corp.",

    ["Invite Your Vendors"]: "Invita a tus proveedores",
    ["E-Mail Address"]: "Dirección de correo electrónico",
    ["Send Invitation"]: "Enviar invitacion",
    ["Last Requested"]: "Última solicitud",

    ["SKU"]: "SKU",
    ["InHouse"]: "Interna",
    ["Measurement"]: "Medición",
    ["Cert"]: "certificado",
    ["Mined"]: "Minada",
    ["Princess"]: "Princesa",
    ["Pear"]: "Pera",
    ["Marquise"]: "Marquesa",
    ["Ideal"]: "Ideal",
    ["Excellent"]: "Excelente",
    ["Very Good"]: "Muy buena",
    ["Good"]: "Buena",
    ["Fair"]: "Justa",
    ["Diamond Preview"]: "Vista previa de diamantes",
    ["Sample Image"]: "Imagen de muestra",
    ["Stock Number"]: "Número de inventario",
    ["Add To Compare"]: "Añadir a comparar",
    ["SIMILAR DIAMONDS"]: "DIAMANTES SIMILARES",
    ["Compare"]: "Comparar",
    ["Fancy Colored"]: "Color de lujo",
    ["D"]: "D",
    ["E"]: "mi",
    ["F"]: "F",
    ["G"]: "GRAMO",
    ["I"]: "yo",
    ["J"]: "j",
    ["K"]: "k",
    ["L"]: "L",
    ["M"]: "METRO",
    ["N"]: "norte",
    ["O"]: "O",
    ["P"]: "PAGS",
    ["Lab Growm"]: "",
    ["No Diamonds Listed In That Range"]: "No hay diamantes listados en ese rango",
    ["Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs."]:
      "Por favor, introduce tu información de contacto. Uno de nuestros especialistas en diamantes se comunicará con usted y lo ayudará con sus necesidades.",
    ["First Name"]: "Primer nombre",
    ["Last Name"]: "Apellido",
    ["Add Comments Here"]: "Añadir comentarios aquí",
    ["Request"]: "Solicitud",
    ["Please enter the details of the type of diamond you are looking for and your contact information."]:
      "Ingrese los detalles del tipo de diamante que está buscando y su información de contacto.",
    ["Your information will be submitted to one of our diamond specialists that will contact you."]:
      "Su información será enviada a uno de nuestros especialistas en diamantes que se pondrá en contacto con usted.",
    ["Advance"]: "Ventaja",
    ["Use your diamond comparison to save all the details of the diamonds you are considering and view them all together"]:
      "Use su comparación de diamantes para guardar todos los detalles de los diamantes que está considerando y verlos todos juntos",
    ["This makes it easy to choose the right diamond."]: "Esto facilita la elección del diamante correcto.",

    ["Mined Diamonds"]: "diamantes extraídos",
    ["Lab Grown"]: "cultivado en laboratorio",
    ["Color Diamonds"]: "Diamantes de colores",

    ["ID"]: "IDENTIFICACIÓN",
    ["Certs"]: "Certificados",
    ["Method"]: "Método",
    ["Custom Method"]: "Método personalizado",
    ["In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%"]:
      "Con el fin de proteger la integridad del diamante y la industria de la joyería, el margen de beneficio mínimo en el enlace Diamond no debe ser inferior al 7 %.",
    ["Markup By Vendor"]: "Marcado por la vendedora",
    ["Markup By PriceRange With Certs"]: "Marcado por PriceRange con certificados",
    ["Markup By SizeRange With Certs"]: "Marcado por rango de tamaño con certificados",
    ["CL"]: "CL",
    ["Update Markup"]: "Actualizar marcado",
    ["GIA"]: "GIA",
    ["AGS"]: "AGS",
    ["EGL"]: "EGL",
    ["IGI"]: "IGI",

    ["Enable Diamonds with"]: "Habilitar Diamantes con",
    ["Video Only"]: "Solo video",
    ["Enable Dynamic Range"]: "Habilitar rango dinámico",
    ["EGL Belgium"]: "EGL Bélgica",
    ["EGL International"]: "EGL Internacional",
    ["EGL Israel"]: "EGL Israel",
    ["EGL South Africa"]: "EGL Sudáfrica",
    ["EGL USA"]: "EE.UU. EGL",
    ["HRD"]: "DDH",
    ["GCAL"]: "GCAL",
    ["None"]: "Ninguna",

    ["FNT"]: "FNT",
    ["MED"]: "MEDICINA",
    ["ST"]: "S T",
    ["ALL"]: "TODOS",

    ["Configurable Attribute"]: "Atributo configurable",
    ["Shipping Charge"]: "Costo de envío",
    ["Update Settings"]: "Ajustes de actualización",

    ["Standard Search"]: "Busqueda estandar",
    ["Advanced Search"]: "Búsqueda Avanzada",
    ["Fancy Colored Search"]: "Búsqueda de colores de lujo",
    ["Lab Grown Search"]: "Búsqueda desarrollada en laboratorio",
    ["Request a Diamond"]: "Solicitar un Diamante",
    ["Compare Diamonds"]: "Comparar diamantes",
    ["Left Navigation for MasterLink"]: "Navegación izquierda para MasterLink",
    ["Loose Diamonds"]: "Diamantes Sueltos",
    ["Same Window"]: "La misma ventana",
    ["New Window"]: "Nueva ventana",
    ["Inframe"]: "En el marco de",
    ["Our Signature Diamonds"]: "Nuestros diamantes característicos",
    ["Best Value Diamonds"]: "Diamantes de mejor valor",
    ["Private Reserve Diamonds"]: "Diamantes de reserva privada",
    ["HeartStar Diamonds"]: "Diamantes HeartStar",

    ["Change this value to set the TITLE at the top of the search results page. Leave blank for default value."]:
      "Cambie este valor para establecer el TÍTULO en la parte superior de la página de resultados de búsqueda. Dejar en blanco para el valor predeterminado.",
    ["Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value."]:
      "Cambie este valor para establecer el TÍTULO en la parte superior de la página de detalles del diamante. Deje en blanco para el valor predeterminado.",
    ["Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer el TEXTO del enlace #1 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer la URL del enlace n.° 1 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer el TEXTO del enlace n.º 2 en la parte inferior de la página de resultados de búsqueda. Deje en blanco el valor predeterminado (ninguno)",
    ["Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer la URL para el enlace n.º 2 en la parte inferior de la página de resultados de búsqueda. Deje en blanco el valor predeterminado (ninguno)",
    ["Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer el TEXTO del enlace #3 en la parte inferior de la página de resultados de búsqueda. Deje en blanco para el valor predeterminado (ninguno)",
    ["Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer la URL del enlace n.° 3 en la parte inferior de la página de resultados de búsqueda. Deje en blanco el valor predeterminado (ninguno)",
    ["Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only)."]:
      "Cambie este valor para permitir que sus usuarios de GFLink busquen pares de piedras coincidentes además de piedras individuales (también TODAS las piedras). Deje en blanco el valor predeterminado (solo piedras individuales)",
    ["Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page."]:
      "Utilice esta opción para Habilitar (Sí) o Deshabilitar (No) la visualización del logotipo (es decir, la URL de la IMAGEN DE LA PÁGINA DE RESULTADOS) en la parte superior de la página de resultados de búsqueda.",
    ["Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "Cambie este valor para modificar la URL asociada con la imagen que se muestra en la parte superior de la página de resultados de búsqueda. Deje en blanco el valor predeterminado (ninguno). Si envía su imagen a GemFind para colocarla en un servidor de GemFind, puede ingresar el nombre del archivo solamente",
    ["Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none)."]:
      "Cambie este valor para modificar el hipervínculo real asociado con la imagen que se muestra en la parte superior de la página de resultados de búsqueda. Deje en blanco el valor predeterminado (ninguno)",
    ["Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "Cambie este valor para modificar la URL asociada con la imagen que se muestra en el medio de la página de detalles de diamantes predeterminada. Deje en blanco el valor predeterminado (ninguno). Si envía su imagen a GemFind para colocarla en un servidor de GemFind, puede colocar solo en el nombre del archivo",
    ["Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value."]:
      "Cambie este valor para modificar el texto visible del hipervínculo real a los detalles del diamante. Deje en blanco para el valor predeterminado",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      "De forma predeterminada, su búsqueda mostrará los detalles básicos del diamante (corte, color, claridad, tamaño, precio y certificado) en la página de resultados de búsqueda. Puede optar por mostrar información más detallada (tabla, profundidad, medidas), así como el información básica en la página de resultados de búsqueda. Independientemente del método que seleccione, los detalles completos de cada diamante siempre están disponibles al hacer clic en 'Más información' en la página de resultados de búsqueda",
    ["You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "Puede optar por buscar por grado de corte si lo desea. La búsqueda de tabla y profundidad está habilitada y están directamente relacionadas con el grado de corte",
    ["Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "Elija la cantidad de diamantes que se muestran en cada página de los resultados de búsqueda. Para valores distintos a los que se muestran, debe comunicarse con GemFind directamente",
    ["Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form."]:
      "Elija si desea mostrar cortes de diamantes más oscuros (asscher, antiguo minero) en el formulario de búsqueda",
    ["Choose whether to use the interactive Macromedia Flash search form instead of the regular search form."]:
      "Elija si desea utilizar el formulario de búsqueda interactivo de Macromedia Flash en lugar del formulario de búsqueda normal",
    ["Choose whether to use show popup of diamond details on the diamond inveotry page."]:
      "Elige si deseas mostrar la ventana emergente de los detalles del diamante en la página de inventario de diamantes",
    ["Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page."]:
      "Elija si mostrar el botón de compartir (Face Book, Twitter, Digg, etc.) en la página de detalles del diamante.",
    ["Choose whether to link diamond search with new ring builder."]: "Elija si vincular la búsqueda de diamantes con el nuevo creador de anillos",
    ["Choose whether to display Ring Builder image at the right column of new diamond link."]:
      "Elige si mostrar la imagen de Ring Builder en la columna derecha del nuevo enlace de diamante",
    ["Search Page Title"]: "Título de la página de búsqueda",
    ["Diamond Detail page Title"]: "Título de la página de detalles del diamante",
    ["Hyperlink #1 TEXT at bottom of search results page"]: "Hipervínculo #1 TEXTO en la parte inferior de la página de resultados de búsqueda",
    ["Hyperlink #1 URL at bottom"]: "Hipervínculo #1 URL en la parte inferior",
    ["Hyperlink #2 TEXT at bottom"]: "Hipervínculo #2 TEXTO en la parte inferior",
    ["Hyperlink #2 URL at bottomof search results page"]: "Hipervínculo #2 URL en la parte inferior de la página de resultados de búsqueda",
    ["Hyperlink #3 TEXT at bottom of search results page"]: "Hipervínculo #3 TEXTO en la parte inferior de la página de resultados de búsqueda",
    ["Hyperlink #3 URL at bottom of search results page"]: "Hipervínculo #3 URL en la parte inferior de la página de resultados de búsqueda",
    ["Let users search for MATCHED PAIRS"]: "Permitir que los usuarios busquen PAREJAS COINCIDENTES",
    ["Show Logo on Results Page"]: "Mostrar logotipo en la página de resultados",
    ["Show Diamond Education on Results Page"]: "Mostrar información sobre diamantes en la página de resultados",
    ["Results page image URL"]: "URL de la imagen de la página de resultados",
    ["Results page image hyperlink URL"]: "URL del hipervínculo de la imagen de la página de resultados",
    ["Diamond details page image URL"]: "URL de la imagen de la página de detalles del diamante",
    ["Alternate diamond details link text"]: "Texto de enlace de detalles de diamantes alternativos",
    ["Enhanced details in search results"]: "Detalles mejorados en los resultados de búsqueda",
    ["Search By Cut Grade"]: "Buscar por grado de corte",
    ["Number of diamonds displayed per page"]: "Número de diamantes mostrados por página",
    ["Show odd diamond cuts on search form"]: "Mostrar cortes de diamantes impares en el formulario de búsqueda",
    ["Dynamic Flash search form"]: "Formulario de búsqueda de Flash dinámico",
    ["Show popup on Diamond inventory display page"]: "Mostrar ventana emergente en la página de visualización del inventario de diamantes",
    ["Show Share Button on Details Page (for New GF Link only)"]: "Mostrar el botón Compartir en la página de detalles (solo para New GF Link)",
    ["Use New Ringbuilder"]: "Usar nuevo Ringbuilder",
    ["Show Ring Builder Image (for New GF Link only)"]: "Mostrar imagen de Ring Builder (solo para New GF Link)",

    ["Generate API Key to get data"]: "Generar clave API para obtener datos",
    ["Generate"]: "Generar",
    ["API Documentation"]: "Documentación API",

    ["Markup="]: "Marcado=",
    ["Markups"]: "Marcas",

    ["Setting #"]: "Ajuste #",
    ["Vendor #"]: "Vendedor #",
    ["Metal"]: "Metal",
    ["$ Price"]: "$ Precio",
    ["$ Markup"]: "Marcado $",

    ["Setup"]: "Configuración",
    ["Ring Builder URL"]: "URL del creador de anillos",
    ["Enter the URL of this tool on your site to enable social sharing and dynamic sizing."]:
      "Ingrese la URL de esta herramienta en su sitio para habilitar el intercambio social y el tamaño dinámico",
    ["Internal Use Link"]: "Enlace de uso interno",
    ["Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting."]:
      "Elija Clima para mostrar el enlace Solo para uso interno en la parte inferior de la página Detalles de la configuración, que le permitirá buscar el número de stock del proveedor y la información de contacto para la configuración visualizada",
    ["Display"]: "Monitor",
    ["RingSize Type"]: "Tipo de tamaño de anillo",
    ["Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.)."]:
      "Elija qué formato de tamaño de anillo le gustaría mostrar. (Americano: 1, 1,5, 2, etc. o británico: A, A.5, B, B.5, etc.)",
    ["American"]: "Americano",
    ["British"]: "Británico",
    ["Roman"]: "romano",
    ["Europe"]: "Europa",
    ["Diamond Initially Sort Result On"]: "Diamante ordenar inicialmente el resultado en",
    ["Select a diamond attribute that you would like to initially sort the diamonds by."]:
      "Seleccione un atributo de diamante por el que le gustaría ordenar inicialmente los diamantes",
    ["Ascending"]: "Ascendente",
    ["Descending"]: "Descendente",
    ["Default Metal Type on Setting page"]: "Tipo de metal predeterminado en la página de configuración",
    ["14K White Gold"]: "Oro blanco de 14 quilates",
    ["14K Yellow Gold"]: "Oro amarillo de 14 quilates",
    ["Show Advanced Search Options By Default"]: "Mostrar opciones de búsqueda avanzada por defecto",
    ["Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "Elija si mostrar el filtrado opcional avanzado de forma predeterminada (polaco, simetría, fluorescencia, certificados... etc",
    ["Rings - Show Schedule A Viewing"]: "Timbres - Mostrar programar una visita",
    ["Choose Whether to display the link which allows the customer the ability to schedule an appointment."]:
      "Elija si desea mostrar el enlace que le permite al cliente programar una cita",
    ["Show Addresses"]: "Mostrar direcciones",
    ["Choose Whether to display your company address and contact information within the RingBuilder."]:
      "Elija si mostrar la dirección de su empresa y la información de contacto dentro de RingBuilder",
    ["Show Center Stone Size"]: "Mostrar tamaño de piedra central",
    ["Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings."]:
      "Advertencia: Deshabilitar esta opción eliminará la restricción de rango de quilates para la configuración del anillo y puede causar un aumento en los precios de costo de lo que se indica para la configuración del anillo seleccionada. Para obtener más información, haga un seguimiento con los montajes nocturnos",
    ["Show Price"]: "Mostrar precio",
    ["Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”."]:
      "Elija si desea mostrar los precios para la configuración del timbre o no. Si elige NO, en su lugar se mostrará Pedir precio",
    ["Apply GST Tax"]: "Aplicar impuesto GST",
    ["Toggle the ability to apply international tax."]: "Alternar la capacidad de aplicar impuestos internacionales",
    ["GST Tax Value"]: "Valor fiscal GST",
    ["Set the value when applying the GST Tax option."]: "Establecer el valor al aplicar la opción de Impuesto GST",
    ["Social"]: "Social",
    ["Show Pinterest Share"]: "Mostrar compartir en Pinterest",
    ["Choose whether to display the Pinterest “Pin it” button or not."]: "Elige si mostrar o no el botón Pin it de Pinterest.",
    ["Show Twitter Share"]: "Mostrar Twitter Compartir",
    ["Choose whether to display the Twitter “Tweet” button or not."]: "Elige si mostrar o no el botón Tweet de Twitter.",
    ["Show Facebook Share"]: "Mostrar Facebook Share",
    ["Choose whether to display the Facebook “Share” button or not."]: "Elige si mostrar o no el botón Compartir de Facebook.",
    ["Show Facebook Like"]: "Mostrar Me gusta de Facebook",
    ["Choose whether to display the Facebook “Like” button or not."]: "Elige si mostrar o no el botón Me gusta de Facebook",
    ["Show Google Plus"]: "Mostrar Google Plus",
    ["Choose whether to display the Google “G+1” button or not."]: "Elige si mostrar o no el botón G+1 de Google",
    ["E-Commerce"]: "Comercio electrónico",
    ["Show Add To Cart Button"]: "Mostrar botón Agregar al carrito",
    ["Alternate Shopping Cart"]: "Carrito de la compra alternativo",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Cambie este valor para modificar el hipervínculo real al carrito de compras. Esto podría cambiarse para vincular a su propia página de carrito de compras. Se pasan parámetros de detalle de configuración y diamantes adicionales en la URL cuando este valor está presente. Deje en blanco para el valor predeterminado ",
    ["Show Buy Setting Only"]: "Mostrar configuración de compra solamente",
    ["Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond."]:
      "Elija si mostrar o no el botón Comprar engaste solamente Esto le permite al cliente usar RingBuilder para comprar solo el engaste del anillo en lugar del anillo completo con diamante",
    ["Show Buy Diamond Only"]: "Mostrar solo comprar diamantes",
    ["Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring."]:
      "Elija si mostrar o no el botón Comprar solo diamantes Esto le permite al cliente usar RingBuilder para comprar solo el diamante en lugar del anillo completo",
    ["View Cart"]: "Ver carrito",
    ["Button"]: "Botón",
    ["Choose Whether to display the PayPal shopping cart's view button or Not"]:
      "Elija si mostrar o no el botón de vista del carrito de compras de PayPal",

    ["No content."]: "Sin contenido",
    ["Page Alignment"]: "Alineación de página",
    ["Center"]: "Centro",
    ["Left"]: "Izquierda",
    ["Right"]: "Derecha",
    ["Show Social Sharing Buttons"]: "Mostrar botones para compartir en redes sociales",
    ["Show View In Store"]: "Mostrar vista en la tienda",
    ["Show Pop Up On Diamond Search"]: "Mostrar ventana emergente en la búsqueda de diamantes",
    ["Diamond Search Result Page Size"]: "Tamaño de página de resultados de búsqueda de diamantes",
    ["Rings - Search Result Page Size"]: "Anillos - Tamaño de página de resultados de búsqueda",
    ["Rings - Initially Sort Result On"]: "Timbres - Ordenar resultado inicialmente en",
    ["Setting Number"]: "Número de configuración",

    ["Send Email To Friend"]: "Enviar correo electrónico a un amigo",
    ["Send Email To Retailer"]: "Enviar correo electrónico al minorista",
    ["Send Email To Sender"]: "Enviar correo electrónico al remitente",
    ["Request A Diamond Email"]: "Solicitar un correo electrónico de diamante",
    ["Request A Diamond"]: "Solicitar un diamante",
    ["Send Mail to Request A Diamond"]: "Enviar correo para solicitar un diamante",

    ["Wishlist"]: "Lista de deseos",
    ["Bridal Registry"]: "Registro nupcial",
    ["Shopping Cart"]: "Carrito de compras",
    ["Show Store Hours"]: "Mostrar horario de la tienda",
    ["Website URL"]: "URL del sitio web",
    ["Google Analytic ID"]: "Mostrar solo precios MSRP",
    ["Show Only MSRP Prices"]: "",
    ["Masterlink Theme"]: "Tema de enlace maestro",
    ["Show View"]: "Mostrar vista",
    ["New MasterLink Use In Iframe?"]: "¿Nuevo uso de MasterLink en Iframe?",
    ["Login URL"]: "URL de inicio de sesión",
    ["Show WholeSale Price Internal Use Link?"]: "¿Mostrar enlace de uso interno de precio mayorista?",
    ["Show Address/Phone Details?"]: "¿Mostrar detalles de dirección/teléfono?",
    ["Diamond Search / Ringbuilder load from Masterlink?"]: "¿Búsqueda de diamantes/carga de Ringbuilder desde Masterlink?",
    ["Diamond Image URL"]: "URL de imagen de diamante",
    ["Vendor Details open or closed by default in new masterlink"]: "Detalles del proveedor abiertos o cerrados de forma predeterminada en el nuevo enlace maestro",
    ["Show Additional PopUp in new masterlink"]: "Mostrar ventana emergente adicional en el nuevo enlace maestro",
    ["Shopping Cart page message"]: "Mensaje de la página del carrito de compras",
    ["Hearts On Fire Link"]: "Corazones en llamas",
    ["Show Setting Name at Setting Grid"]: "Mostrar nombre de configuración en la cuadrícula de configuración",
    ["Show Ring Builder with One Step"]: "Mostrar Ring Builder con un solo paso",
    ["Show Default Ring Size"]: "Mostrar tamaño de anillo predeterminado",
    ["Shop Diamonds"]: "Comprar diamantes",
    ["Shop Bridal & Engagement"]: "Comprar novia y compromiso",
    ["Shop Designers"]: "Diseñadores de tiendas",
    ["Shop Jewelry"]: "Comprar joyería",
    ["Shop Watches"]: "Comprar relojes",

    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."]:
      "Elija si desea mostrar el enlace Solo para uso interno en la parte inferior de la página Detalles del diamante, que le permitirá buscar el número de stock del proveedor y la información de contacto del diamante visto",
    ["Sticky Header Height"]: "Altura del encabezado fijo",
    ["Enter a Sticky Header Height."]: "Ingrese una altura fija de encabezado",
    ["Platinum Education"]: "Educación Platino",
    ["Provides a link to copy for platinum education in Ring Builder."]: "Proporciona un enlace para copiar para la educación platino en Ring Builder",
    ["Ring Size Guide URL"]: "URL de la guía de tallas de anillos",
    ["Provides a link to copy for ring sizing guides provided by GemFind."]: "Proporciona un enlace para copiar las guías de tallas de anillos proporcionadas por GemFind",
    ["Show Breadcrumbs"]: "Mostrar migas de pan",
    ["Shows navigation path a customer took to get to page they're on."]: "Muestra la ruta de navegación que tomó un cliente para llegar a la página en la que está",
    ["Create Profile"]: "Crear perfil",
    ["text"]: "texto",
    ["Provide alternate text for user registration Button."]: "Proporcionar texto alternativo para el botón de registro de usuario.",
    ["Sort Products by Price Initially By"]: "Ordenar productos por precio inicialmente por",
    ["Allow Customers to Sort Product By Price."]: "Permitir que los clientes ordenen el producto por precio",
    ["Price: Low - High"]: "Precio: Bajo - Alto",
    ["Price: High - Low"]: "Precio: Alto - Bajo",
    ["Collection Filtering"]: "Filtrado de colecciones",
    ["When navigating Designer Landing pages - show collections instead of categories."]:
      "Al navegar por las páginas de destino del diseñador, muestre colecciones en lugar de categorías",
    ["Schedule Viewing"]: "Visualización programada",
    ["Allow Customers to schedule a meeting with you."]: "Permitir a los clientes programar una reunión con usted",
    ["Designer Tile Layout"]: "Diseño de mosaico de diseñador",
    ["Show Back to Top Button."]: "Mostrar botón Volver al principio",
    ["Enabled Back To Top"]: "Activado Volver arriba",
    ["Allow address to show on detailpage"]: "Permitir que la dirección se muestre en la página de detalles",
    ["Text"]: "Texto",
    ["(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."]:
      "(Cuando los precios se han desactivado, de manera predeterminada, se mostrará Pedir precio. Esta opción le permite reemplazar Pedir precio con un texto alternativo (por ejemplo, Pedir disponibilidad)",
    ["Starting At Price"]: "A partir del precio",
    ["Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."]:
      "Proporcione un texto de prefijo (incluirá el precio) cuando un producto use el Tipo de precio 5 (los productos con el Tipo de precio Precio inicial (5) no se pueden agregar al carrito de compras)",
    ["instead of the Price"]: "en lugar del Precio",
    ["Hides the retail price for products using Price Type 1,2,or 3 ."]: "Oculta el precio minorista de los productos que utilizan el tipo de precio 1, 2 o 3",
    ["Facebook App ID"]: "ID de la aplicación de Facebook",
    ["Connect MasterLink to your Facebook page, using your Facebook App ID."]:
      "Conecte MasterLink a su página de Facebook, utilizando su ID de aplicación de Facebook",
    ["Facebook Like Page URL"]: "URL de la página Me gusta de Facebook",
    ["Allow Like access to your facebook account by providing the URL."]: "Permita el acceso Me gusta a su cuenta de Facebook proporcionando la URL",
    ["Allow Customers to post your products to their Pinterest boards."]: "Permita que los clientes publiquen sus productos en sus tableros de Pinterest",
    ["Allow Customers to share your products to their Twitter account."]: "Permita que los clientes compartan sus productos en su cuenta de Twitter",
    ["Allow Customers to share your products to their Facebook page."]: "Permita que los clientes compartan sus productos en su página de Facebook",
    ["Allow Customers to like your products directly from your website."]: "Permita que a los clientes les gusten sus productos directamente desde su sitio web",
    ["SEO"]: "SEO",
    ["Masterlink Default Title"]: "Título predeterminado del enlace maestro",
    ["Enter a title for search engine optimization."]: "Ingrese un título para la optimización del motor de búsqueda",
    ["Masterlink Meta Keywords"]: "Metapalabras clave de enlace maestro",
    ["Enter a Keywords for search engine optimization."]: "Ingrese una palabra clave para la optimización del motor de búsqueda",
    ["Masterlink Meta Description"]: "Meta descripción del enlace maestro",
    ["Enter a description for search engine optimization."]: "Ingrese una descripción para la optimización del motor de búsqueda",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."]:
      "Cambie este valor para modificar el hipervínculo real al carrito de compras. Esto podría cambiarse para vincular a su propia página de carrito de compras",
    ["Enable the View Cart button for customers to review their products selections."]:
      "Habilite el botón Ver carrito para que los clientes revisen sus selecciones de productos",

    ["New Favourite List"]: "Nueva lista de favoritos",
    ["New Favourite Items Added"]: "Nuevos artículos favoritos agregados",

    ["Create Your Own Diamond Studs in 3 easy steps with Stud Builder"]: "Cree sus propios montantes de diamante en 3 sencillos pasos con Stud Builder",
    ["Select Your Diamonds"]: "Seleccione sus diamantes",
    ["Choose Your Mounting"]: "Elige tu montaje",
    ["Complete Your Earrings"]: "Completa tus pendientes",
    ["Standard"]: "Estándar",
    ["Add To Stud"]: "Añadir a Stud",
    ["Advanced"]: "Avanzado",

    ["Diamonds Markup"]: "Marcado de diamantes",

    ["Diamond Drop A Hint"]: "Diamante deja una pista",
    ["Stud Builder URL"]: "URL del creador de vigas",
    ["Internal Use Link?"]: "¿Enlace de uso interno?",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."]:
      "Elija si desea mostrar el enlace Solo para uso interno en la parte inferior de la página Detalles del artículo, que le permitirá buscar el número de inventario del proveedor y la información de contacto para el par de diamantes visto",
    ["Only Diamond Search"]: "Solo búsqueda de diamantes",
    ["Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."]:
      "Elija si SOLO mostrar la parte de búsqueda de diamantes de Stud Builder que empareja diamantes de atributos similares",
    ["Show Dealer SKU In Diamond Search"]: "Mostrar SKU de distribuidor en búsqueda de diamantes",
    ["Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."]:
      "Elija si desea mostrar el número de inventario del distribuidor/proveedor en lugar del número de SKU exclusivo predeterminado de GemFind (p. ej., SKU n.º 108545370)",
    ["Choose whether to display the link which allows the customer the ability to schedule an appointment."]:
      "Elija si mostrar el enlace que permite al cliente programar una cita.",
    ["Allow address to show on detailpage."]: "Permitir que la dirección se muestre en la página de detalles",
    ["Choose whether to display “Call for Price” instead of the retail price or not."]:
      "Elige si deseas mostrar Precio de llamada en lugar del precio de venta al público o no",
    ["Shopping Cart Url"]: "URL del carrito de compras",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Elige si mostrar o no el botón Agregar al carrito",
    ["Choose whether to display the “Add to Cart” button or not."]: "",
    ["Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not."]:
      "Elija si mostrar o no el botón 'Ver carrito' del carrito de compras de PayPal.",

    ["Generate Sync"]: "Generar sincronización",
    ["Generate Full Sync"]: "Generar sincronización completa",
    ["File Name"]: "Nombre del archivo",
    ["Folder"]: "Carpeta",

    ["Enter FTP Detail for SYNC File Below"]: "Ingrese los detalles de FTP para el archivo SYNC a continuación",
    ["User Id"]: "Identificación de usuario",
    ["Is SSL?"]: "¿Es SSL?",

    ["Configure & Customize Your"]: "Configurar y personalizar su",
    ["Pre-Owned Rolex Watch"]: "Reloj Rolex de segunda mano",
    ["E-Mail A Friend"]: "Escribe un correo a un amigo",
    ["Print Details"]: "Imprimir detalles",
    ["Have a question regarding this item? Our specialist are available to assist you. | Call us:"]:
      "¿Tiene alguna pregunta sobre este artículo? Nuestros especialistas están disponibles para ayudarlo. | Llámenos:",
    ["Items Details"]: "Detalles de los artículos",
    ["STEP 1 | Size & Bracelet Type"]: "PASO 1 | Tamaño y tipo de pulsera",
    ["Choose Watch Type"]: "Elegir tipo de reloj",
    ["Men's"]: "De los hombres",
    ["Ladies"]: "Damas",
    ["Mid Size"]: "Tamaño medio",
    ["Choose Bracelet Style"]: "Elegir estilo de pulsera",
    ["Jubilee"]: "Jubileo",
    ["Oyster"]: "Ostra",
    ["President"]: "Presidente",
    ["Choose Bracelet Metal"]: "Elegir pulsera de metal",
    ["Stainless Steel"]: "Acero inoxidable",
    ["18K Y/SS"]: "18K Y/SS",
    ["18K Yellow"]: "Amarillo 18K",
    ["18K White"]: "Blanco 18K",
    ["STEP 2 | Bezel Type"]: "PASO 2 | Tipo de bisel",
    ["Choose Bezel Type"]: "Elegir tipo de bisel",
    ["Choose Bezel Metal"]: "Elegir bisel metálico",
    ["Choose Bezel Gemstone Type"]: "Elegir tipo de gema de bisel",
    ["Choose Bezel Gemstone Shape"]: "Elija la forma de la piedra preciosa del bisel",
    ["Choose Bezel Diamond Quality"]: "Elegir calidad de diamante de bisel",
    ["STEP 3 | Dial Type"]: "PASO 3 | Tipo de marcación",
    ["Choose Dial Type"]: "Elegir tipo de marcación",
    ["Marker Type"]: "Tipo de marcador",
    ["Custom Dial Colors"]: "Colores de marcación personalizados",
    ["Specifications"]: "Especificaciones",
    ["Size & Bracelet Type (STEP 1)"]: "Tamaño y tipo de pulsera (PASO 1)",
    ["SKU#"]: "SKU#",
    ["Watch Type"]: "Tipo de reloj",
    ["Men"]: "Hombres",
    ["Bracelet Style"]: "Estilo de pulsera",
    ["Bracelet Metal"]: "Pulsera Metálica",
    ["Bezel Type (STEP 2)"]: "Tipo de bisel (PASO 2)",
    ["Custom Bezel Type"]: "Tipo de bisel personalizado",
    ["Custom Bezel Metal"]: "Bisel metálico personalizado",
    ["Custom Bezel Gemstones"]: "Piedras preciosas de bisel personalizadas",
    ["Custom Bezel Gemstone Shape"]: "Forma de piedra preciosa de bisel personalizada",
    ["Custom Bezel Diamond Quality"]: "Calidad de diamante de bisel personalizado",
    ["Total Carat Weight"]: "Peso total en quilates",
    ["Dial Type (STEP 3)"]: "Tipo de marcación (PASO 3)",
    ["Dial Type"]: "Tipo de marcación",
    ["Custom Dial Color"]: "Color de marcación personalizado",
    ["Your Name"]: "Su nombre",
    ["Your E-Mail Address"]: "Su dirección de correo electrónico",
    ["Your Company Name"]: "El nombre de tu compañía",
    ["Your Phone Number"]: "Su número de teléfono",
    ["Your Store Phone Number"]: "El número de teléfono de su tienda",
    ["Write A Personal Message"]: "Escribir un mensaje personal",
    ["Hint Recipient's Name"]: "Sugerencia del nombre del destinatario",
    ["Hint Recipient's E-Mail"]: "Sugerencia de correo electrónico del destinatario",
    ["Reason For This Gift"]: "Motivo de este regalo",
    ["Gift Deadline"]: "Fecha límite de regalo",
    ["Add A Personal Message Here"]: "Agregar un mensaje personal aquí",
    ["Friend E-Mail Address"]: "Dirección de correo electrónico del amigo",
    ["Subject"]: "Tema",
    ["Schedule"]: "Calendario",
    ["Your Email"]: "Tu correo electrónico",
    ["Appointment Date"]: "Día de la cita",
    ["Add A Message Here"]: "Agregar un mensaje aquí",
    ["Get Directions"]: "Obtener las direcciones",

    ["Watch Customizer Options"]: "Opciones del personalizador de relojes",
    ["Watch Customizer URL"]: "Ver URL personalizador",
    ["Update Setting"]: "Ajuste de actualización",

    ["Symetry"]: "Simetría",
    ["Choose Your Setting"]: "Elija su configuración",
    ["Complete Your Pendant"]: "Complete su colgante",
    ["Add To Your Pendant"]: "Agregar a su colgante",

    ["Pendant Builder URL"]: "URL del creador de colgantes",
    ["Enter a URL."]: "Ingrese una URL",
    ["Show Advanced Search As Default"]: "Mostrar búsqueda avanzada como predeterminada",
    ["Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "Elija si desea mostrar el filtrado opcional avanzado de forma predeterminada (polaco, simetría, fluorescencia, certificados... etc",
    ["Choose whether to display the “View Cart” button or not."]: "Elige si mostrar o no el botón Ver carrito",

    ["Total items"]: "Articulos totales",
    ["Total Cost"]: "Coste total",

    ["Purchase order date"]: "Fecha de orden de compra",
    ["Order Confirm Date"]: "Fecha de confirmación del pedido",
    ["Sale Per"]: "Venta por",

    ["Confirm"]: "Confirmar",
    ["Quantity"]: "Cantidad",
    ["Order Date"]: "Fecha de orden",

    ["Folder Path"]: "Ruta de la carpeta",
    ["Choose File To Upload"]: "Escoge un archivo para cargar",

    ["Filter By Trade Show"]: "Filtrar por feria comercial",
    ["Trade Show 1"]: "Exposición comercial 1",
    ["Trade Show 2"]: "Exposición comercial 2",
    ["Trade Show 3"]: "Exposición comercial 3",
    ["Buyer"]: "Comprador",

    ["Are you sure to update Permission?"]: "¿Está seguro de actualizar el permiso?",
    ["Report Permission Control"]: "Control de permisos de informes",
    ["Update Date on which"]: "Fecha de actualización en la que",
    ["Is Created"]: "Es creado",
    ["Select Vendor Status"]: "Seleccionar estado del proveedor",
    ["Select Status"]: "Seleccionar estado",
    ["Company name is compulsory"]: "El nombre de la empresa es obligatorio",
    ["Street address is compulsory"]: "La dirección de la calle es obligatoria",
    ["City is compulsory"]: "La ciudad es obligatoria",
    ["City is invalid"]: "La ciudad no es válida",
    ["State is compulsory"]: "El estado es obligatorio",
    ["State is invalid"]: "El estado no es válido",
    ["Zip code is compulsory"]: "El código postal es obligatorio",
    ["Zip code is invalid"]: "El código postal no es válido",
    ["Kindly select the country"]: "Por favor seleccione el país",
    ["Contact name is compulsory"]: "El nombre de contacto es obligatorio",
    ["Email address is compulsory"]: "La dirección de correo electrónico es obligatoria",
    ["Email address is invalid"]: "Dirección de correo electrónico es inválida",
    ["Phone number is compulsory"]: "El número de teléfono es obligatorio",
    ["Phone number is invalid"]: "El número de teléfono no es válido",
    ["Username is compulsory"]: "El nombre de usuario es obligatorio",
    ["Password is compulsory"]: "La contraseña es obligatoria",
    ["Password should have a spe"]: "La contraseña debe tener una spe",
    ["Kindly select the permission type"]: "Por favor seleccione el tipo de permiso",
    ["Kindly select the retailer status"]: "Por favor, seleccione el estado del minorista",
    ["Kindly select the vendor status"]: "Por favor, seleccione el estado del proveedor",
    ["RetailerCore 1"]: "RetailerCore 1",
    ["RetailerCore 2"]: "RetailerCore 2",
    ["RetailerCore 3"]: "RetailerCore 3",
    ["RetailerCore 4"]: "RetailerCore 4",
    ["RetailerCore 5"]: "RetailerCore 5",
    ["RetailerCore 6"]: "RetailerCore 6",
    ["RetailerCore 7"]: "RetailerCore 7",
    ["RetailerCore 8"]: "RetailerCore 8",
    ["RetailerCore 9"]: "RetailerCore 9",
    ["RetailerCore 10"]: "RetailerCore 10",
    ["RetailerCore 11"]: "RetailerCore 11",
    ["RetailerCore 12"]: "RetailerCore 12",
    ["RetailerCore 13"]: "RetailerCore 13",
    ["Retailer Id is compulsory"]: "La identificación del minorista es obligatoria",
    ["Retailer Id is Invalid"]: "El ID del minorista no es válido",
    ["Kindly select the core"]: "Por favor seleccione el núcleo",
    ["Limit Type is compulsory"]: "El tipo de límite es obligatorio",
    ["Kindly select the retailer"]: "Por favor, seleccione el minorista",
    ["Kindly select the Diamond Dealer"]: "Por favor, seleccione el distribuidor de diamantes",
    ["Kindly select the Vendor"]: "Por favor, seleccione el proveedor",
    ["No Dealer Selected"]: "Ningún distribuidor seleccionado",
    ["Please Select Dealer."]: "Seleccione el distribuidor",
    ["Select Dealer ID"]: "Seleccione ID de distribuidor",
    ["Select File Type"]: "Seleccionar tipo de archivo",
    ["Kindly select Dealer ID"]: "Por favor, seleccione ID de distribuidor",
    ["FTP Username is compulsory"]: "El nombre de usuario de FTP es obligatorio",
    ["FTP Password is compulsory"]: "La contraseña de FTP es obligatoria",
    ["Kindly select File Type"]: "Por favor, seleccione Tipo de archivo",
    ["Path is Compulsory"]: "La ruta es obligatoria",
    ["Dealer ID is compulsory"]: "La identificación del distribuidor es obligatoria",
    ["Api Url is compulsory"]: "Api Url es obligatoria",
    ["Api Key is compulsory"]: "La clave API es obligatoria",
    ["Are you sure you want to discard ?"]: "¿Estás seguro de que quieres descartar?",
    ["Are you sure you want to discard all ?"]: "¿Estás seguro de que quieres descartar todo?",
    ["Enter valid DealerID"]: "Ingrese ID de distribuidor válido",
    ["All Designer"]: "Todos los diseñadores",
    ["Please select designer."]: "Seleccione diseñador.",
    ["Please select image."]: "Seleccione la imagen",
    ["View Inquiry"]: "Ver consulta",
    ["Contacts"]: "Contactos",
    ["Export"]: "Exportar",
    ["Jewelry Retailer Click Report"]: "Informe de clic de minorista de joyería",
    ["Jewelry Vendor Click Report"]: "Informe de clic de proveedor de joyería",
    ["Global Diamond"]: "Diamante mundial",
    ["Extended Diamond Chart Report"]: "Informe ampliado del gráfico de diamantes",
    ["Global Jewelry"]: "Joyería Global",
    ["Jewelry Category Reports"]: "Informes de categorías de joyería",
    ["Jewelry Metal Type Reports"]: "Informes de tipo de metal de joyería",
    ["Page number is compulsory"]: "El número de página es obligatorio",
    ["First Tier Page Name is compulsory"]: "El nombre de la página de primer nivel es obligatorio",
    ["Second Tier Page Name is compulsory"]: "El nombre de la página de segundo nivel es obligatorio",
    ["Third Tier Page Name is compulsory"]: "El nombre de la página de tercer nivel es obligatorio",
    [".JPG | .JPEG | .PNG Format"]: ".JPG | .JPEG | Formato .PNG",
    ["Select Start Time"]: "Seleccionar hora de inicio",
    ["Select End Time"]: "Seleccionar hora de finalización",
    ["Please select the image"]: "Seleccione la imagen",
    ["ShowName is compulsory"]: "ShowName es obligatorio",
    ["Website is compulsory"]: "El sitio web es obligatorio",
    ["Kindly select the Show Start Time"]: "Por favor, seleccione la hora de inicio del espectáculo",
    ["Kindly select the Show End Time"]: "Por favor, seleccione la hora de finalización del espectáculo",
    ["Kindly select the Show Start Date"]: "Por favor, seleccione la fecha de inicio de la demostración",
    ["Kindly select the Show End Date"]: "Por favor, seleccione Mostrar fecha de finalización",
    ["Color Name is compulsory"]: "El nombre del color es obligatorio",
    ["Select"]: "Seleccione",
    ["Please select compose."]: "Seleccione redactar.",
    ["Please enter name."]: "Por favor ingrese el nombre",
    ["Please enter email."]: "Ingrese el correo electrónico",
    ["Email is not valid."]: "El correo no es válido",
    ["Please enter subject."]: "Ingrese el asunto.",
    ["Please enter summary."]: "Ingrese el resumen",
    ["Option 1"]: "Opcion 1",
    ["Option 2"]: "Opcion 2",
    ["GIA Website"]: "Sitio web de GIA",
    ["Yes"]: "Sí",
    ["Borderline"]: "Límite",
    ["No"]: "No",
    ["Very Slight"]: "Muy leve",
    ["Slight"]: "Ligero",
    ["Faint"]: "Tenue",
    ["Medium"]: "Medio",
    ["Strong"]: "Fuerte",
    ["Very Strong"]: "Fuerte",
    ["No Yellow"]: "Sin amarillo",
    ["No Green"]: "Sin verde",
    ["No Grey"]: "Sin gris",
    ["No Black"]: "Sin negro",
    ["No Pink"]: "Sin rosa",
    ["No Blue"]: "Sin azul",
    ["No Brown"]: "No Marrón",
    ["Light"]: "Luz",
    ["Heavy"]: "Pesado",
    ["Extremely Thin"]: "Extremadamente delgado",
    ["Very Thin"]: "Muy delgado",
    ["Thin"]: "Delgada",
    ["Slightly Thin"]: "Ligeramente delgado",
    ["Slightly Thick"]: "Ligeramente grueso",
    ["Thick"]: "Grueso",
    ["Very Thick"]: "Muy grueso",
    ["Extremely Thick"]: "Extremadamente grueso",
    ["Extra Thin"]: "Extrafino",
    ["Bearding"]: "Barba",
    ["Brown Patch of Color"]: "Parche de color marrón",
    ["Bruise"]: "Moretón",
    ["Cavity"]: "Cavidad",
    ["Chip"]: "Chip",
    ["Cleavage"]: "Escote",
    ["Cloud"]: "Nube",
    ["Crystal"]: "Cristal",
    ["Crystal Surface"]: "Superficie de cristal",
    ["Etch Channel"]: "Etch Channel",
    ["Extra Facet"]: "Faceta adicional",
    ["Feather"]: "Pluma",
    ["Flux Remnant"]: "Remanente de flujo",
    ["Indented Natural"]: "Sangría natural",
    ["Internal Graining"]: "Granulado interno",
    ["Internal Inscription"]: "Inscripción Interna",
    ["Internal Laser Drilling"]: "Perforación láser interna",
    ["Knot"]: "Nudo",
    ["Laser Drill Hole"]: "Agujero perforado con láser",
    ["Manufacturing Remnant"]: "Remanente de fabricación",
    ["Minor Details of Polish"]: "Detalles menores del polaco",
    ["Natural"]: "Natural",
    ["Needly"]: "Necesariamente",
    ["No Inclusion"]: "Sin inclusión",
    ["Pinpoint"]: "Identificar",
    ["Reflecting Surface Graining"]: "Grano de superficie reflectante",
    ["Surface Graining"]: "Granulado superficial",
    ["Twinning Wisp"]: "Twining Wisp",
    ["Small"]: "Pequeña",
    ["Very Small"]: "Muy pequeña",
    ["Large"]: "Largo",
    ["Very Large"]: "Muy grande",
    ["Abraded"]: "Rasgado",
    ["Chipped"]: "Astillado",
    ["Pointed"]: "Puntiagudo",
    ["Laser Drilled"]: "Perforado con láser",
    ["Clarity Enhanced"]: "Claridad mejorada",
    ["Color Enhanced"]: "Color mejorado",
    ["Irradiated"]: "Irradiado",
    ["HPHT"]: "HPHT",
    ["Search All Stones"]: "Buscar todas las piedras",
    ["Search Single Stone"]: "Buscar piedra única",
    ["Search Match Pairs"]: "Buscar pares de coincidencias",
    ["Emerald"]: "Esmeralda",
    ["SI1"]: "SI1",
    ["VG"]: "VG",
    ["B&H Diamonds"]: "Diamantes B&H",
    ["Chicago, IL"]: "Chicago, IL",
    ["Category 1"]: "Categoría 1",
    ["Category 2"]: "Categoría 2",
    ["Category 3"]: "Categoría 3",
    ["Material 1"]: "Material 1",
    ["Material 2"]: "Material 2",
    ["Material 3"]: "Material 3",
    ["Child"]: "Niño",
    ["Mens"]: "De los hombres",
    ["Teen"]: "Adolescente",
    ["Unisex"]: "Unisex",
    ["Womens"]: "De las mujeres",
    ["Vendor 1"]: "Vendedor 1",
    ["Vendor 2"]: "Vendedor 2",
    ["Abc"]: "A B C",
    ["Xyz"]: "Xyz",
    ["Tom"]: "Tomás",
    ["Gemstone 1"]: "Piedra preciosa 1",
    ["Gemstone 2"]: "Piedra preciosa 2",
    ["Gemstone 3"]: "Piedra preciosa 3",
    ["Collection 1"]: "Colección 1",
    ["Collection 2"]: "Colección 2",
    ["Collection 3"]: "Colección 3",
    ["Collection 4"]: "Colección 4",
    ["Sales Rep"]: "Representante de ventas",
    ["Jewelry Access"]: "Acceso a joyas",
    ["RingBuilder Access"]: "Acceso a RingBuilder",
    ["Hide Price"]: "Ocultar precio",
    ["Auto Sync"]: "Sincronización automática",
    ["Data & Permissions"]: "Datos y permisos",
    ["Make Appointment"]: "Concertar cita",
    ["Store Notes"]: "Notas de la tienda",
    ["Disconnect Retailer"]: "Desconectar minorista",
    ["Conversation data is compulsory"]: "Los datos de la conversación son obligatorios",
    ["Note Title is compulsory"]: "El título de la nota es obligatorio",
    ["Note Description"]: "Descripción de la nota",
    ["Appointment data is compulsory"]: "Los datos de la cita son obligatorios",
    ["Access"]: "Acceso",
    ["Avatar"]: "Avatar",
    ["Job Title"]: "Título profesional",
    ["E-Mail"]: "Email",
    ["Phone No."]: "Telefono no.",
    ["Locations"]: "Ubicaciones",
    ["Store Bio"]: "Biografía de la tienda",
    ["Years In Business"]: "Años en negocios",
    ["Create A New Note"]: "Crear una nueva nota",
    ["Edit Note"]: "Editar nota",
    ["Monday"]: "Lunes",
    ["Tuesday"]: "Martes",
    ["December"]: "Diciembre",
    ["October"]: "Octubre",
    ["July"]: "Julio",
    ["Bhavin Patel"]: "Bhavin Patel",
    ["Started a"]: "Comenzó un",
    ["with your store."]: "con tu tienda",
    ["Create Appointment"]: "Crear cita",
    ["Calendars"]: "Calendarios",
    ["Calendar"]: "Calendario",
    ["Day"]: "Día",
    ["Engagement"]: "Compromiso",
    ["Cleaning"]: "Limpieza",
    ["Repair"]: "Reparar",
    ["Wedding"]: "Boda",
    ["Select Date"]: "Seleccione fecha",
    ["Select Calendar"]: "Seleccionar calendario",
    ["Select Contact"]: "Seleccione un contacto",
    ["ABC"]: "A B C",
    ["John"]: "John",
    ["XYZ"]: "XYZ",
    ["User"]: "Usuario",
    ["Set Day"]: "Establecer día",
    ["Thursday"]: "Jueves",
    ["Friday"]: "Viernes",
    ["Saturday"]: "Sábado",
    ["Set Time"]: "Fijar tiempo",
    ["Enter Name."]: "Ingrese su nombre",
    ["Enter Email Address."]: "Introduzca la dirección de correo electrónico",
    ["Enter Friend's Name."]: "Ingrese el nombre del amigo",
    ["Enter Friend's Email Address."]: "Ingrese la dirección de correo electrónico de su amigo",
    ["Search all"]: "Busca todo",
    ["Inventory is compulsory"]: "El inventario es obligatorio",
    ["Matched Pair Stock is compulsory"]: "El inventario de pares combinados es obligatorio",
    ["Stones are compulsory"]: "Las piedras son obligatorias",
    ["Size is compulsory"]: "La talla es obligatoria",
    ["Cost is compulsory"]: "El costo es obligatorio",
    ["Kindly select the shape"]: "Por favor seleccione la forma",
    ["Kindly select the color"]: "Por favor seleccione el color",
    ["Kindly select the fancy codes"]: "Por favor seleccione los códigos elegantes",
    ["Kindly select the fancy names"]: "Por favor seleccione los nombres elegantes",
    ["Other is compulsory"]: "Otro es obligatorio",
    ["Kindly select the clarity"]: "Por favor seleccione la claridad",
    ["Kindly select the cut grade"]: "Por favor, seleccione el grado de corte",
    ["Kindly select the certificate"]: "Por favor seleccione el certificado",
    ["Kindly select the certificate number"]: "Por favor, seleccione el número de certificado",
    ["Depth is compulsory"]: "La profundidad es obligatoria",
    ["Table is compulsory"]: "La tabla es obligatoria",
    ["Measurements are compulsory"]: "Las medidas son obligatorias",
    ["Kindly select the polish value"]: "Por favor, seleccione el valor de pulido",
    ["Kindly select the symmetry"]: "Por favor seleccione la simetría",
    ["Kindly select the fluorescence"]: "Por favor seleccione la fluorescencia",
    ["Kindly select the girdle"]: "Por favor seleccione la faja",
    ["Kindly select the culet"]: "Por favor seleccione el culet",
    ["Kindly select the culet condition"]: "Por favor, seleccione la condición de culet",
    ["Crown Angle is compulsory"]: "El ángulo de corona es obligatorio",
    ["Pavillion Angle is compulsory"]: "El ángulo del pabellón es obligatorio",
    ["Comments are compulsory"]: "Los comentarios son obligatorios",
    ["Store Location is compulsory"]: "La ubicación de la tienda es obligatoria",
    ["Employee Name is compulsory"]: "El nombre del empleado es obligatorio",
    ["Employee ID is compulsory"]: "La identificación del empleado es obligatoria",
    ["Employee ID is Invalid"]: "El ID de empleado no es válido",
    ["Please select file."]: "Seleccione el archivo",
    ["Option 3"]: "Opción 3",
    ["Golden"]: "Dorado",
    ["FB"]: "FB",
    ["FBGNY"]: "FBGNY",
    ["Brown"]: "Marrón",
    ["FL"]: "FL",
    ["VVS1"]: "VVS1",
    ["Excellent 1"]: "Excelente 1",
    ["Strong Blue"]: "Azul fuerte",
    ["Medium Blue"]: "Azul medio",
    ["Extremely"]: "Extremadamente",
    ["Do not include pricing informatin in comments."]: "No incluir información de precios en los comentarios",
    ["Video Url"]: "URL del vídeo",
    ["No file chosen"]: "Ningún archivo elegido",
    ["Please select delimiter."]: "Seleccione el delimitador",
    ["Diamond Import"]: "Importación de diamantes",
    ["RAPNET"]: "RAPNET",
    ["IDEX"]: "IDEX",
    ["POLYGON"]: "POLÍGONO",
    ["DIRECT LINK"]: "ENLACE DIRECTO",
    ["Use Rapnet Instant Inventory Service."]: "Usar el servicio de inventario instantáneo de Rapnet.",
    ["Standard Diamond Form"]: "Forma de diamante estándar",
    ["Diamond Importing"]: "Importación de diamantes",
    ["Alphanumeric"]: "Alfanumérico",
    ["ProductName1"]: "NombreProducto1",
    ["Short title for the product. This will be displayed on product pages. If listing child products, please include color and size in the name to differentiate."]:
      "Título corto para el producto. Esto se mostrará en las páginas del producto. Si enumera productos secundarios, incluya el color y el tamaño en el nombre para diferenciarlos",
    ["Accessories"]: "Accesorios",
    ["Tiaras"]: "Tiaras",
    ["Womans Fashion Bag"]: "Bolso de moda para mujer",
    ["Atia 81"]: "Atia 81",
    ["Fashion Earrings"]: "Pendientes de moda",
    ["Chandelier"]: "Candelabro",
    ["Charms"]: "Encantos",
    ["Beads"]: "Cuentas",
    ["Bracelets"]: "Pulseras",
    ["Product Name1.xls"]: "Nombre del producto1.xls",
    ["Delivery Time"]: "El tiempo de entrega",
    ["hours"]: "horas",
    ["Featured"]: "Presentado",
    ["Product name is compulsory"]: "El nombre del producto es obligatorio",
    ["Retail Description is compulsory"]: "La descripción minorista es obligatoria",
    ["Retail Price is compulsory"]: "El precio de venta al público es obligatorio",
    ["Kindly select the Material Type"]: "Por favor, seleccione el tipo de material",
    ["Kindly select the Category"]: "Por favor, seleccione la categoría",
    ["Kindly select all the necessary Field from essential"]: "Por favor, seleccione todos los campos necesarios de los esenciales",
    ["Fixed"]: "Fijado",
    ["WholeSale"]: "Venta al por mayor",
    ["Diamond Upload"]: "Carga de diamante",
    ["Please select image file only."]: "Por favor seleccione solo archivo de imagen",
    ["Please select zip file only."]: "Seleccione solo el archivo zip",
    ["Please select .csv|xls|txt|json file only."]: "Seleccione solo el archivo .csv|xls|txt|json.",
    ["Please select currency."]: "",
    ["Please select rounding."]: "",
    ["Please enter default markup."]: "",
    ["Please enter default markup value less then 100%."]: "Ingrese el marcado predeterminado",
    ["Select Currency"]: "Seleccione el tipo de moneda",
    ["Select Rounding"]: "Seleccionar redondeo",
    ["Collection name is compulsory"]: "El nombre de la colección es obligatorio",
    ["Kindly select the Brand Name"]: "Por favor, seleccione el nombre de la marca",
    ["Need to select atleast one category"]: "Necesita seleccionar al menos una categoría",
    ["Overnight Mountings Ring Builder"]: "Generador de anillos de montaje durante la noche",
    ["Data Ratings"]: "Calificaciones de datos",
    ["Victor"]: "Víctor",
    ["Manage Inventory"]: "Administrar inventario",
    ["Manage Location"]: "Gestionar ubicación",
    ["Vendor Type"]: "Tipo de proveedor",
    ["Designer"]: "Diseñador",
    ["Watch Brand"]: "Marca del reloj",
    ["Bridal Designer"]: "Diseñador nupcial",
    ["Connection Status"]: "Estado de conexión",
    ["Vendor Profile"]: "Perfil del proveedor",
    ["Why Choose Overnight?"]: "¿Por qué elegir durante la noche?",
    ["Inventory"]: "Inventario",
    ["Page"]: "Página",
    ["of"]: "de",
    ["pages"]: "páginas",
    ["per page"]: "por página",
    ["Product Deactivation Guidelines"]: "Pautas de desactivación de productos",
    ["Activate/Deactivate All"]: "Activar/Desactivar Todo",
    ["Products"]: "Productos",
    ["Jack"]: "Jacobo",
    ["Lucy"]: "Lucía",
    ["One"]: "Una",
    ["Two"]: "Dos",
    ["Male"]: "Masculino",
    ["Female"]: "Femenino",
    ["Save Changes"]: "Guardar cambios",
    ["Trade Show Appointment"]: "Cita de la feria comercial",
    ["Preferred"]: "Preferido",
    ["Questions"]: "Preguntas",
    ["I Am Interested In Placing Your Inventory On My Website."]: "Estoy interesado en colocar su inventario en mi sitio web",
    ["Connected"]: "Conectado",
    ["Request Update"]: "Solicitar actualización",
    ["Image Preview"]: "Vista previa de la imagen",
    ["By Size"]: "Por tamaño",
    ["By Price"]: "Por precio",
    ["United States - Dollar - $"]: "Estados Unidos - Dólar - $",
    ["Great Britan - Pound - £"]: "Gran Bretaña - Libra - £",
    ["Australia - Dollar - $"]: "Australia - Dólar - $",
    ["size"]: "Talla",
    ["Custom"]: "Disfraz",
    ["US Dollars"]: "Dólares estadounidenses",
    ["Great Britain Pound"]: "Libra de Gran Bretaña",
    ["Apex Diamonds"]: "Diamantes de vértice",
    ["Asscher"]: "Ascher",
    ["Cushion"]: "Almohadón",
    ["Best Diamonds"]: "Mejores diamantes",
    ["Signature Diamonds"]: "Diamantes exclusivos",
    ["Normal Diamonds"]: "Diamantes normales",
    ["Diamond Link URL"]: "URL de enlace de diamante",
    ["Automatically Accept New Diamond Dealers"]: "Aceptar automáticamente nuevos distribuidores de diamantes",
    ["When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."]:
      "Cuando un nuevo distribuidor de diamantes se une a GemFind, se agregará automáticamente a su lista de distribuidores de diamantes conectada. Cambie esta opción a 'No', si prefiere que los distribuidores de diamantes no se agreguen automáticamente a su cuenta",
    ["Sort Search Results Initially By"]: "Ordenar resultados de búsqueda inicialmente por",
    ["Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."]:
      "Cambiar este valor para modificar el campo de clasificación inicial de los resultados de búsqueda. Dejar en blanco para el valor predeterminado (ninguno)",
    ["Order Search Results Initially By"]: "Ordenar resultados de búsqueda inicialmente por",
    ["Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."]:
      "Cambie este valor para modificar el orden de clasificación inicial del campo de clasificación inicial. Deje en blanco para el valor predeterminado (ninguno)",
    ["Show In House Diamonds Column with SKU (for New GF Link Only)"]: "Mostrar la columna In House Diamonds con SKU (solo para New GF Link)",
    ["Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."]:
      "Elija si desea mostrar las columnas de diamantes internos en el resultado de la búsqueda. También mostrará el SKU interno en la ventana emergente de resultados y en la página de detalles",
    ["Show In House Diamonds First"]: "Mostrar primero los diamantes de la casa",
    ["Choose whether to display in house diamonds first in the search result."]:
      "Elija si desea mostrar los diamantes de la casa primero en el resultado de la búsqueda",
    ["Show GIA report check link"]: "Mostrar enlace de verificación del informe GIA",
    ["When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."]:
      "Cuando esté habilitado, mostrará un enlace al certificado de diamantes GIA en el sitio web de GIA, cuando esté desactivado, mostrará un enlace a la imagen del certificado GIA",
    ["Show Advance options as Default in Diamond Search"]: "Mostrar opciones avanzadas como predeterminadas en la búsqueda de diamantes",
    ["Show Certificate in Diamond Search"]: "Mostrar certificado en búsqueda de diamantes",
    ["Choose whether to display a link to the diamond certificate or not."]: "Elija si desea mostrar un enlace al certificado de diamantes o no",
    ["Show EGL Certificate Variants"]: "Mostrar variantes de certificados EGL",
    ["Choose whether to display EGL Certificate Variants or not."]: "Elija si mostrar o no las variantes del certificado EGL",
    ["Show Request Certificate"]: "Mostrar certificado de solicitud",
    ["When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."]:
      "Cuando Mostrar el enlace de verificación del informe GIA está establecido en NO, esta opción le permite elegir si mostrar o no el enlace Solicitar certificado Este enlace mostrará la imagen del certificado GIA (si está disponible) en lugar de la cert. en el sitio web de GIA, y si Desactivar enlaces de certificados en la búsqueda de diamantes está establecido en SÍ, este enlace mostrará un formulario emergente Solicitar más información",
    ["Disable Certificate Links in Diamond Search"]: "Deshabilitar enlaces de certificados en la búsqueda de diamantes",
    ["Hide certificates and show only request form."]: "Ocultar certificados y mostrar solo formulario de solicitud",
    ["Show Color Items out of Range"]: "Mostrar elementos de color fuera de rango",
    ["Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."]:
      "Elija si el último botón de filtro de color de diamante mostrado también mostrará cualquier color restante de ese tipo o superior, hasta la 'Z'",
    ["Show Clarity Items out of Range"]: "Mostrar elementos de claridad fuera de rango",
    ["Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."]:
      "Elija si el último botón de filtro Claridad del diamante mostrado también mostrará las claridades restantes de ese tipo o superior, hasta I3",
    ["Accept Total Price for Diamond"]: "Aceptar el precio total del diamante",
    ["When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."]:
      "Al cargar diamantes en JewelCloud con una hoja de datos, el proceso predeterminado para calcular el precio de costo es multiplicar el costo por quilate por el valor en quilates. Cuando esta opción se establece en SÍ, los precios en la hoja de datos de diamantes cargada se aceptarán como costo total precio",
    ["Choose whether to display the link which allows the customer the ability to Drop a Hint."]:
      "Elija si mostrar el enlace que le permite al cliente dejar una pista",
    ["Email A Friend"]: "Escribe un correo a un amigo",
    ["Choose whether to display the link which allows the customer the ability to Email A Friend."]:
      "Elija si desea mostrar el enlace que le permite al cliente enviar un correo electrónico a un amigo",
    ["Show Origin On Diamond Search"]: "Mostrar origen en la búsqueda de diamantes",
    ["Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."]:
      "Elija si mostrar el menú desplegable Origen dentro de los filtros de búsqueda avanzada. (Ej. Todos, canadienses, creados en laboratorio)",
    ["Show vendor comments on details page"]: "Mostrar comentarios del proveedor en la página de detalles",
    ["Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."]:
      "Elige si quieres mostrar los comentarios realizados por los vendedores de diamantes en la página de detalles de cada piedra en los resultados de la búsqueda de diamantes",
    ["Show Contact Number"]: "Mostrar número de contacto",
    ["Choose whether to use show contact Number or not."]: "Elige si quieres mostrar el número de contacto o no",
    ["Choose whether to use show addresses or not."]: "Elige si quieres mostrar direcciones o no",
    ["Alternate diamond details link URL"]: "URL de enlace de detalles de diamantes alternativos",
    ["Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Cambie este valor para modificar el hipervínculo real a los detalles del diamante. Esto podría cambiarse para vincular a su propia página de detalles del diamante o a su propio carrito de compras. Los parámetros adicionales de detalles del diamante se pasan en la URL cuando este valor está presente. Dejar en blanco para el valor predeterminado",
    ["Use Custom Detail URL for Diamonds?"]: "¿Usar URL de detalles personalizados para diamantes?",
    ["Choose whether to enable or disable the “Alternate diamond details link URL” option."]:
      "Elija si desea habilitar o deshabilitar la opción URL de enlace de detalles de diamantes alternativos",
    ["Show Diamond Prices"]: "Mostrar precios de diamantes",
    ["You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."]:
      "Si lo desea, puede optar por no mostrar los precios de los diamantes en los resultados de búsqueda. El campo de rango de precios en el formulario de búsqueda no se muestra cuando no se muestran los precios",
    ["Markup Your Own Inventory"]: "Marque su propio inventario",
    ["Choose whether to apply markup on diamond inventory uploaded by you."]:
      "Elija si desea aplicar el marcado en el inventario de diamantes cargado por usted",
    ["Show Price Per Carat"]: "Mostrar precio por quilate",
    ["Choose whether to display the price per carat value on the diamond details page “Specifications” tab."]:
      "Elige si mostrar el precio por quilate en la pestaña Especificaciones de la página de detalles del diamante",
    ["Show Instagram Share"]: "Mostrar Compartir Instagram",
    ["Choose whether to display Instagram Share or not."]: "Elige si mostrar Instagram Share o no",
    ["Call to Action Button"]: "Botón de llamada a la acción",

    ["AddToCart Button"]: "Botón Añadir al carrito",
    ["Schedule a Viewing"]: "Programar una visita",
    ["Alternate Shopping Cart URL"]: "URL alternativa del carrito de compras",

    ["Show AddToCart Button On Search Page"]: "Mostrar botón AddToCart en la página de búsqueda",
    ["Choose whether to display the “Add to Cart” button on Search Page or not."]:
      "Elige si deseas mostrar o no el botón Agregar al carrito en la página de búsqueda",
    ["Request Diamond"]: "Solicitar diamante",
    ["Compare Diamond"]: "Comparar diamante",
    ["Select Option"]: "Seleccionar opción",
    ["Ring Builder Version I"]: "Ring Builder Versión I",
    ["Ring Builder Version II"]: "Ring Builder Versión II",
    ["Ring Builder Version III"]: "Ring Builder Versión III",
    ["Ring Builder Version IV"]: "Ring Builder Versión IV",
    ["Ring Builder Version V"]: "Ring Builder Versión V",
    ["Earrings"]: "Aretes",
    ["18k Yellow Gold"]: "Oro amarillo de 18 k",
    ["18k Rose Gold"]: "Oro rosa de 18 quilates",
    ["AUD"]: "EUR",
    ["GBP (Pound)"]: "GBP (Libra)",
    ["USD"]: "DÓLAR ESTADOUNIDENSE",
    ["Items View"]: "Vista de elementos",
    ["List View"]: "Vista de la lista",
    ["We offer over 50,000 mountings and Semi-mounts to choose from. Overnight has one of the largest"]:
      "Ofrecemos más de 50.000 montajes y Semi-montajes para elegir. Overnight tiene uno de los más grandes",
    ["In Stock"]: "En stock",
    ["inventories in the industry. All of our"]: "inventarios en la industria. Todos nuestros",
    ["mountings and Semi-mounts can be returned within 10 business days for a full refund. Our entire line is offered in Sterling, 10k, 14k, 18k, 950 Platinum and 950 Palladium. All gold mountings and semi mounts can be ordered in colors White, Yellow or Pink. Castings available within 3 business days, out of stock Polished mountings available within 3-5 business days, and semi mount and finished goods are available within 5-7 business days. Any one of our 50,000+ styles can be ordered as"]:
      "las monturas y las semimonturas se pueden devolver dentro de los 10 días hábiles para obtener un reembolso completo. Nuestra línea completa se ofrece en Sterling, 10k, 14k, 18k, 950 Platinum y 950 Palladium. Todas las monturas y semimonturas doradas se pueden pedir en colores Blanco, amarillo o rosa. Las piezas fundidas están disponibles en 3 días hábiles, se agotan. Los montajes pulidos están disponibles en 3 a 5 días hábiles, y los productos terminados y semimontados están disponibles en 5 a 7 días hábiles. Cualquiera de nuestros más de 50,000 estilos puede ser ordenado como",
    ["Authentix"]: "Authentix",
    ["Samples. Any style can be modified in any way to meet your customers needs. Overnight's talented"]:
      "Muestras. Cualquier estilo se puede modificar de cualquier manera para satisfacer las necesidades de sus clientes. Talentosos de la noche a la mañana",
    ["custom crafted department"]: "departamento personalizado",
    ["can create anything as a new model using CAD and Hand Carved. We employ some of the most talented setters in the industry that can set your stone(s). We offer 7 different diamond qualities including black diamonds and Champagne diamonds. Our customer service department is extremely knowledgeable in all aspects of jewelry and will assist you in a friendly and professional manner. Overnight's product development team is always creating new styles from basics to cutting edge fashion designs ensuring that you will find what you are looking for. All of our Mountings are proudly manufactured in NY and go through a stringent quality control process before leaving our facility, ensuring that you get a great product in a timely manner. Overnight reserves the right to refuse or recall any product information, images or content being used on customer websites and catalogs"]:
      "puede crear cualquier cosa como un nuevo modelo utilizando CAD y tallado a mano. Empleamos a algunos de los engastadores más talentosos de la industria que pueden engastar su(s) piedra(s). Ofrecemos 7 calidades de diamantes diferentes, incluidos los diamantes negros y los diamantes Champagne. Nuestro cliente El departamento de servicio tiene un gran conocimiento en todos los aspectos de la joyería y lo asistirá de manera amigable y profesional. El equipo de desarrollo de productos de Overnight siempre está creando nuevos estilos, desde lo básico hasta diseños de moda de vanguardia, lo que garantiza que encontrará lo que está buscando. Todos nuestros montajes se fabrican con orgullo en Nueva York y pasan por un estricto proceso de control de calidad antes de salir de nuestras instalaciones, lo que garantiza que obtenga un excelente producto de manera oportuna. Overnight se reserva el derecho de rechazar o retirar cualquier información, imagen o contenido del producto que se esté utilizando sitios web y catálogos de clientes",
    ["Please Select Manufacturer"]: "Seleccione el fabricante",
    ["Please select markup"]: "Seleccione el marcado",
    ["Please Enter Min Range"]: "Ingrese el rango mínimo",
    ["Please Enter Max Range"]: "Ingrese el rango máximo",
    ["Please Enter Markup"]: "Ingrese el marcado",
    ["Malaysia - Riggit - $"]: "Malasia - Riggit - $",
    ["New Zealand - Dollar - $"]: "Nueva Zelanda - Dólar - $",
    ["Canadian - Dollar - $"]: "Dólar canadiense - $",
    ["Singapore - Dollar - S$"]: "Singapur - Dólar - S$",
    ["Chinese - Yuan - ¥"]: "Chino - Yuan - ¥",
    ["Korean - Won - ₩"]: "Coreano - Ganado - ₩",
    ["Denmark - Danish Krone - kr."]: "Dinamarca - Corona danesa - kr",
    ["South African - Rand - R"]: "Sudafricano - Rand - R",
    ["Europe - Euro - €"]: "Europa - Euro - €",
    ["Swiss - Franc - CHf"]: "Suizo - Franco - CHf",
    ["Swedish Krona - Sweden - SEK"]: "Corona sueca - Suecia - SEK",
    ["Diamond Link Shopping Cart Configuration Instructions"]: "Instrucciones de configuración del carrito de compras Diamond Link",
    ["We will be sending parameters to the page on your website as below url"]:
      "Enviaremos parámetros a la página de su sitio web como se muestra a continuación",
    ["Example Url"]: "Url de ejemplo",
    ["To Get Diamond Detail"]: "Para obtener detalles de diamantes",
    ["Click Here to access API to get diamond detail and checkout how it works online."]:
      "Haga clic aquí para acceder a la API para obtener detalles de diamantes y verificar cómo funciona en línea",
    ["to access API to get diamond detail and checkout how it works online."]:
      "para acceder a la API para obtener detalles de diamantes y verificar cómo funciona en línea",
    ["Click Here"]: "Haga clic aquí",
    ["Below is the list of parameters with respective possible values which can be supplied to each parameter."]:
      "A continuación se muestra la lista de parámetros con los respectivos valores posibles que se pueden proporcionar a cada parámetro",
    ["DiamondId"]: "IdDiamante",
    ["Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)"]:
      "Pase el número de diamante de diamante. (Obtendrá esto como DiamondID en la cadena de consulta de la URL como se muestra arriba)",
    ["Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."]:
      "Pase GemFind # que está asignado a su cuenta. Por ejemplo, 720 para GemFind",
    ["You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website."]:
      "Obtendrá una respuesta en formato XML de la respuesta de la API. Deberá analizar y usar esos datos para crear un diamante como producto en su sitio web",
    ["DealerLink"]: "DealerLink",
    ["Ring Builder Shopping Cart Configuration Instructions"]: "Instrucciones de configuración del carrito de compras de Ring Builder",
    ["To Get Setting Detail"]: "Para obtener detalles de configuración",
    ["to access API to get setting detail and checkout how it works online."]:
      "para acceder a la API para obtener detalles de configuración y verificar cómo funciona en línea",
    ["SettingId"]: "Configuración Id",
    ["Pass setting number of setting. (SettingID from the query string parameter as shown on example url)"]:
      "Pasar el número de configuración de configuración. (ID de configuración del parámetro de cadena de consulta como se muestra en la URL de ejemplo)",
    ["Masterlink Shopping cart integration instructions"]: "Instrucciones de integración del carrito de compras de Masterlink",
    ["To Get Product Detail"]: "Para obtener detalles del producto",
    ["You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart."]:
      "Puede integrar su propio carrito de compras con los productos de los diseñadores de gemfind a través de la API. Puede usar esta API de modo que el botón Agregar al carrito de gemfind agregue el producto en el carrito de compras de su sitio web",
    ["JewelryID"]: "JewelryID",
    ["Pass JewelryID # for which you want to get data."]: "Pasar JewelryID # para el que desea obtener datos",
    ["Stud Builder Shopping cart integration instructions"]: "Instrucciones de integración del carrito de compras de Stud Builder",
    ["There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones"]:
      "Habrá dos ID de diamantes separados por comas, por lo que deberá obtener los detalles del diamante dos veces una vez para cada diamante, ya que el perno prisionero tiene un par de piedras",
    ["To Get Stud Mounting Detail"]: "Para obtener el detalle del montaje de pernos",
    ["to access API to get Stud Mounting detail and checkout how it works online."]:
      "para acceder a la API para obtener detalles de montaje de pernos y verificar cómo funciona en línea",
    ["Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)"]:
      "Pase JewelryID # para el que desea obtener datos. (DesignerProductID del parámetro de cadena de consulta como se muestra en la URL de ejemplo)",
    ["Jewelry Category"]: "Categoría de joyería",
    ["Designer Category"]: "Categoría de diseñador",
    ["Bridal Category"]: "Categoría nupcial",
    ["SKU Prefix"]: "Prefijo SKU",
    ["Category Mapping"]: "Asignación de categorías",
    ["Column Mapping"]: "Asignación de columnas",
    ["GemFind Category"]: "Categoría GemFind",
    ["Website Category"]: "Categoría del sitio web",
    ["Dealer Stock Number"]: "Número de inventario del distribuidor",
    ["Product Description"]: "Descripción del producto",
    ["Designers Mapping"]: "Asignación de diseñadores",
    ["GemFind Designers"]: "Diseñadores de GemFind",
    ["Bangles"]: "Brazaletes",
    ["Big & important Bracelets"]: "Brazaletes grandes e importantes",
    ["Chain Bracelets"]: "Brazaletes de cadena",
    ["Necklaces"]: "Collares",
    ["Chains"]: "Cadenas",
    ["Sync Products To Your Website"]: "Sincronizar productos con su sitio web",
    ["Completed"]: "Completado",
    ["Sync Products"]: "Productos de sincronización",
    ["Force Full Sync"]: "Forzar sincronización completa",
    ["Detail"]: "Detalle",
    ["Total Products Processed"]: "Total de productos procesados",
    ["Out Going Data Map"]: "Mapa de datos de salida",
    ["API Url"]: "URL API",
    ["Enable Sync"]: "Habilitar sincronización",
    ["Attrubute Mapping"]: "Asignación de atributos",
    ["Create XML"]: "Crear XML",
    ["Notifications"]: "Notificaciones",
    ["registered with the website"]: "registrado en el sitio web",
    ["Jewelry vendors"]: "Vendedores de joyas",
    ["Active Jewelry"]: "Joyería activa",
    ["Diamond Vendors"]: "Vendedores de diamantes",
    ["Active Diamonds"]: "Diamantes activos",
    ["Contact Us"]: "Contáctenos",
    ["Favorites"]: "Favoritos",
    ["New & Recently Updated Vendors"]: "Proveedores nuevos y actualizados recientemente",
    ["View More"]: "Ver más",
    ["Quick Links"]: "Enlaces rápidos",
    ["View My Retailers"]: "Ver mis minoristas",
    ["Update Inventory"]: "Actualizar inventario",
    ["Invite A Retailer"]: "Invitar a un minorista",
    ["Manage Shows"]: "Gestionar programas",
    ["Ratings"]: "Calificaciones",
    ["The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated."]:
      "La calificación de estrellas se basa en 7 áreas clave. Imágenes, Nombre del producto, Descripción del producto, Categorías/Colecciones, Atributos, Precios y Última actualización.",
    ["For details on how you can improve your score, please click here to view this document."]:
      "Para obtener detalles sobre cómo puede mejorar su puntaje, haga clic aquí para ver este documento",
    ["Import Your Data"]: "",
    ["Upload Your Jewelry & Diamonds"]: "Sube tus joyas y diamantes",
    ["Import Diamonds"]: "Importar diamantes",
    ["Upload Diamond"]: "Subir diamante",
    ["Import Jewelry"]: "Importar joyas",
    ["Upload Jewelry"]: "Subir joyas",
    ["Video/Images"]: "Video/Imágenes",
    ["Email"]: "Correo electrónico",
    ["Friend's Name"]: "Nombre del amigo",
    ["Friend's Email"]: "Correo electrónico de amigos",
    ["Buyer Name is compulsory"]: "El nombre del comprador es obligatorio",
    ["Book Appointment"]: "Reservar una cita",
    ["Appointment Description"]: "Descripción de la cita",
    ["Gemfind"]: "Buscar gemas",
    ["Your recipient will recieve the details on your selected diamonds"]: "Su destinatario recibirá los detalles de los diamantes seleccionados",
    ["Message"]: "Mensaje",
    ["Please select video file only."]: "Seleccione solo el archivo de video.",
    ["Is Lab Created"]: "Es un laboratorio creado",
    ["Please Enter Link"]: "Ingrese el enlace",
    ["Please Enter Text"]: "Ingrese el texto",
    ["View /Edit Mapping Columns"]: "Ver/Editar columnas de asignación",
    ["Preparing Your Data"]: "Preparando sus datos",
    ["Resubmit"]: "Reenviar",
    ["Re-Activate"]: "Reactivar",
    ["Fancy Color Diamond Link Emails"]: "Correos electrónicos de enlaces de diamantes de colores elegantes",
    ["Diamond Link Emails"]: "Correos electrónicos de enlace de diamante",
    ["Time pieces"]: "piezas de tiempo",
    ["Click Here for GFLINK ECommerce Link Administration"]: "Haga clic aquí para la administración de enlaces de comercio electrónico de GFLINK",
    ["View/Update Your Online Dealer Inventory List"]: "Ver/actualizar su lista de inventario de distribuidores en línea",
    ["Change Inventory Mark-Up Values for Markup BY PRICE"]: "Cambiar valores de marcado de inventario para marcado POR PRECIO",
    ["Update Search Page Results Appearance"]: "Actualizar la apariencia de los resultados de la página de búsqueda",
    ["Update Search Page Results Preferences"]: "Actualizar las preferencias de resultados de la página de búsqueda",
    ["For Advanced Users"]: "Para usuarios avanzados",
    ["Change Your Shipping Charge"]: "Cambiar su cargo de envío",
    ["Your GFLINK Addresses are"]: "Sus direcciones de GFLINK son",
    ["ADMIN NOTE"]: "NOTA ADMINISTRATIVA",
    ["Your GFLink addresses MUST contain the correct domain name, http://platform.stage.jewelcloud.com. Using http://platform.stage.jewelcloud.com or any variation of gemfind.com is NOT correct and no longer functions. Please make sure your GFWatchLink URL is correct. Use the URLs below. The links below display your inventory"]:
      "Sus direcciones de GFLink DEBEN contener el nombre de dominio correcto, http://platform.stage.jewelcloud.com. Usar http://platform.stage.jewelcloud.com o cualquier variación de gemfind.com NO es correcto y ya no funciona . Asegúrese de que su URL de GFWatchLink sea correcta. Utilice las URL a continuación. Los enlaces a continuación muestran su inventario",
    ["Marked Up"]: "Marcado",
    ["a % determined by the Watch'S PRICE."]: "un % determinado por el PRECIO del reloj",
    ["Search Only"]: "Buscar solo",
    ["Watch Configurator"]: "Configurador de relojes",
    ["Procedure to implement Watch Configurator: To integrate the Watch Configurator directly into one of your web pages on your website, you will need to follow the general procedure below. This procedure will vary depending upon the technology your website uses, and your web developer will need to make adjustments accordingly. This will require custom website development work on your end. You basically have two choices when it comes to seamlessly including Watch Configurator in your website: FRAMEs or IFRAMEs."]:
      "Procedimiento para implementar Watch Configurator: para integrar Watch Configurator directamente en una de sus páginas web en su sitio web, deberá seguir el procedimiento general a continuación. Este procedimiento variará según la tecnología que use su sitio web y su desarrollador web tendrá que hacer los ajustes correspondientes. Esto requerirá un trabajo de desarrollo de sitio web personalizado por su parte. Básicamente, tiene dos opciones cuando se trata de incluir Watch Configurator en su sitio web: FRAME o IFRAME",
    ["FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch Configurator may easily be included as one of the FRAME contents. This is one of the simplest methods of embedding results, however it is not as attractive as the other methods and may not be a feasible option for you if you do not already use FRAMEs. Scrollbars may be prgrammatically eliminated for a cleaner look."]:
      "FRAME: Si su sitio usa FRAME en un FRAMESET, entonces Watch Configurator puede incluirse fácilmente como uno de los contenidos de FRAME. Este es uno de los métodos más simples de incrustar resultados, sin embargo, no es tan atractivo como los otros métodos y puede no ser una opción factible para usted si aún no usa FRAME. Las barras de desplazamiento pueden eliminarse programáticamente para una apariencia más limpia",
    ["IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding to accomodate Netscape) and place it on your page where you want the Watch Configurator to apear. This will look better than a FRAME defined as part of a FRAMESET. Some attention to the size of the IFRAME must be paid. The IFRAME window is not programmatically 'resizable' once it has been defined on a page, therefore you must create the IFRAME with a HEIGHT and WIDTH that will accommodate the entire contents of the Watch Configurator. The number of results/rows returned by the Watch Configurator is constant and will ensure a constant height (and fit) for your IFRAME window."]:
      "IFRAME / LAYERs: defina un IFRAME flotante (incluya la codificación LAYER para adaptarse a Netscape) y colóquelo en su página donde desea que aparezca el configurador de relojes. Esto se verá mejor que un FRAME definido como parte de un FRAMESET. Atención a se debe pagar el tamaño del IFRAME. La ventana del IFRAME no es 'redimensionable' programáticamente una vez que se ha definido en una página, por lo tanto, debe crear el IFRAME con un ALTO y un ANCHO que acomodará todo el contenido del Watch Configurator. el número de resultados/filas devueltos por Watch Configurator es constante y garantizará una altura (y ajuste) constante para su ventana IFRAME",
    ["Search by GEMFIND INVENTORY ID #"]: "Buscar por GEMFIND INVENTARIO ID #",
    ["Enter GEMFIND ID # and CLICK 'GO'"]: "Ingrese el número de ID de GEMFIND y HAGA CLIC EN 'IR'",
    ["Go"]: "Vamos",
    ["Search by Dealer's Inventory #"]: "Buscar por número de inventario del distribuidor",
    ["Enter Dealer Inventory # and CLICK 'GO'"]: "Ingrese el número de inventario del distribuidor y HAGA CLIC EN 'IR'",
    ["A & M Diamonds"]: "Diamantes A & M",
    ["A & W Gems"]: "Gemas A & W",
    ["A & F Gems"]: "Gemas A & F",
    ["Pendant Builder Shopping cart integration instructions"]: "Instrucciones de integración del carrito de compras de Pendant Builde",
    ["To Get Pendant Mounting Detail"]: "Para obtener detalles de montaje colgante",
    ["Please Enter Password."]: "Por favor, ingrese contraseña",
    ["Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"]:
      "La contraseña debe contener un mínimo de 6 caracteres, al menos una letra mayúscula, un número y un carácter especial",
    ["Please Enter Confirm Password."]: "Ingrese Confirmar Contraseña.",
    ["Password And Confirm Password Do Not Match."]: "Contraseña y Confirmar contraseña no coinciden",
    ["Server Up-Time"]: "Tiempo de actividad del servidor",
    ["Uptime info not available - please try reloading this page"]: "Información de tiempo de actividad no disponible; intente volver a cargar esta página",
    ["Select Client Name"]: "Seleccione el nombre del cliente",
    ["Active - No Profile"]: "Activo - Sin perfil",
    ["Select Type"]: "Seleccione tipo",
    ["Select Retailer Status"]: "Seleccione el estado del minorista",
    ["API Url is compulsory"]: "La URL de la API es obligatoria",
    ["API Key is compulsory"]: "La clave API es obligatoria",
    ["Provide Proper DealerID"]: "Proporcionar ID de distribuidor adecuado",
    ["account ?"]: "cuenta ?",
    ["You have select"]: "Has seleccionado",
    ["Please select .png file only."]: "Por favor seleccione solo archivo .png.",
    ["Please Select Correct Image Size."]: "Seleccione el tamaño de imagen correcto",
    ["All Dealer"]: "Todos los distribuidores",
    ["Please Enter Only Alphabet."]: "Ingrese solo el alfabeto",
    ["Please Select Only Images."]: "Seleccione solo imágenes.",
    ["API Access Token"]: "Token de acceso a la API",
    ["Set Initial Filter Settings"]: "Establecer configuración de filtro inicial",
    ["Set Your Available Diamonds"]: "Establezca sus diamantes disponibles",
    ["This will control default diamond filters when users initially visit your DiamondLink."]:
      "Esto controlará los filtros de diamantes predeterminados cuando los usuarios visiten inicialmente su DiamondLink",
    ["This will control which diamonds are available in your DiamondLink."]: "Esto controlará qué diamantes están disponibles en su DiamondLink",
    ["Lab Setting"]: "Configuración de laboratorio",
    ["Mined Setting"]: "Configuración minada",
    ["Ct."]: "Ct.",
    ["Please select zip file only were in Compress images together & upload in a .zip format."]:
      "Seleccione el archivo zip que solo estaban en Comprimir imágenes juntas y subirlas en formato .zip",
    ["Password does not match"]: "Las contraseñas no coinciden",
    ["Name is compulsory"]: "El nombre es obligatorio",
    ["Name is invalid"]: "El nombre no es válido",
    ["Email is compulsory"]: "El correo electrónico es obligatorio",
    ["Confirm Password is compulsory"]: "Confirmar contraseña es obligatorio",
    ["Logins"]: "Inicios de sesión",
    ["User Role Detail"]: "Detalle de la función del usuario",
    ["New User"]: "Nueva usuaria",
    ["Add New User"]: "Añadir nuevo usuario",
    ["Access Level"]: "Nivel de acceso",
    ["Edit User"]: "Editar usuario",
    ["Delete User"]: "Borrar usuario",
    ["Last Login"]: "Último acceso",
    ["User Since"]: "Usuario desde",
    ["Select Account type"]: "Seleccione el tipo de cuenta",
    ["Company 1"]: "Empresa 1",
    ["Company 2"]: "Compañía 2",
    ["Company 3"]: "Compañía 3",
    ["Apply"]: "Aplicar",
    ["Language Selection for Platform and Tools"]: "Selección de idioma para plataforma y herramientas",
    ["Default Language Is"]: "El idioma predeterminado es",
    ["Select Language"]: "Seleccione el idioma",
    ["Currency Selection for Platform and Tools"]: "Selección de moneda para plataforma y herramientas",
    ["Default Currency Is"]: "La moneda predeterminada es",
    ["Please select Image File Only"]: "Seleccione solo archivo de imagen",
    ["Email Address is compulsory"]: "La dirección de correo electrónico es obligatoria",
    ["Email Address is Invalid"]: "Dirección de correo electrónico es inválida",
    ["Image Name"]: "Nombre de la imágen",
    ["Company Detail"]: "Detalle de la empresa",
    ["Web Site"]: "Sitio web",
    ["Company Bio"]: "Biografía de la empresa",
    ["Update Profile"]: "Actualización del perfil",
    ["Advertising Samples"]: "Muestras Publicitarias",
    ["Social Integrations Settings"]: "Configuración de integraciones sociales",
    ["Website's Facebook App ID Setup"]: "Configuración de ID de la aplicación de Facebook del sitio web",
    ["Masterlink Facebook App Setup"]: "Configuración de la aplicación Masterlink Facebook",
    ["MasterLink Facebook canvas App ID"]: "ID de aplicación de lienzo de MasterLink Facebook",
    ["MasterLink Facebook canvas App URL"]: "URL de la aplicación de lienzo de MasterLink Facebook",
    ["RingBuilder Facebook App Setup"]: "Configuración de la aplicación de Facebook RingBuilder",
    ["RingBuilder Facebook canvas App ID"]: "ID de la aplicación de lienzo de RingBuilder Facebook",
    ["RingBuilder Facebook canvas App URL"]: "URL de la aplicación de lienzo de Facebook de RingBuilder",
    ["StudBuilder Facebook App Setup"]: "Configuración de la aplicación de Facebook StudBuilder",
    ["StudBuilder Facebook canvas App ID"]: "ID de aplicación de lienzo de Facebook de StudBuilder",
    ["StudBuilder Facebook canvas App URL"]: "URL de la aplicación de lienzo de Facebook de StudBuilder",
    ["PendantBuilder Facebook App Setup"]: "Configuración de la aplicación de Facebook PendantBuilder",
    ["PendantBuilder Facebook canvas App ID"]: "Id. de la aplicación de lienzo de Facebook de PendantBuilder",
    ["PendantBuilder Facebook canvas App URL"]: "URL de la aplicación de lienzo de Facebook de PendantBuilder",
    ["Diamondlink Facebook App Setup"]: "Configuración de la aplicación de Facebook Diamondlink",
    ["DiamondLink Facebook canvas App ID"]: "ID de la aplicación de lienzo de Facebook de DiamondLink",
    ["DiamondLink Facebook canvas App URL"]: "URL de la aplicación de lienzo de Facebook de DiamondLink",
    ["Basic Information"]: "Información básica",
    ["Notiﬁcation Settings"]: "Configuración de notificaciones",
    ["Choose what you want to be notiﬁed about and where."]: "Elige de qué quieres que te notifiquen y dónde",
    ["Inquiries"]: "Consultas",
    ["New Favorites List"]: "Nueva lista de favoritos",
    ["New Favorites"]: "Nuevos favoritos",
    ["Vendor Requests"]: "Solicitudes de proveedores",
    ["Send Upload Report"]: "Enviar informe de carga",
    ["Purchase Orders"]: "Ordenes de compra",
    ["Sales Orders"]: "Ordenes de venta",
    ["Ignore blank uploads"]: "Ignorar cargas en blanco",
    ["SMTP Settings"]: "Configuración SMTP",
    ["Send e-mails from your own address."]: "Enviar correos electrónicos desde su propia dirección.",
    ["Mail Server"]: "Servidor de correo",
    ["Port Number"]: "Número de puerto",
    ["Server Requires Authentication"]: "El servidor requiere autenticación",
    ["Use SSL"]: "Usar SSL",
    ["Test Your SMTP Settings"]: "Pruebe su configuración SMTP",
    ["TEST"]: "PRUEBA",
    ["Add Map Here"]: "Añadir Mapa Aquí",
    ["Location 1"]: "Ubicación 1",
    ["Address"]: "Dirección",
    ["Zip"]: "Cremallera",
    ["Location E-Mail"]: "Correo electrónico de ubicación",
    ["Location Phone"]: "Ubicación Teléfono",
    ["Update Location"]: "Actualizar ubicación",
    ["Hours of Operation"]: "Horas de operación",
    ["Sun"]: "Sol",
    ["Mon"]: "Lun",
    ["Tues"]: "martes",
    ["Wed"]: "Casarse",
    ["Thu"]: "Jue",
    ["Fri"]: "Vie",
    ["Sat"]: "Se sentó",
    ["Location QR Code"]: "Código QR de ubicación",
    ["QR Code Generator"]: "Generador de códigos QR",
    ["View Inventory"]: "Ver inventario",
    ["Please Enter Min Price."]: "Ingrese el precio mínimo.",
    ["Please Enter Max Price."]: "Ingrese el precio máximo.",
    ["Please Enter Markup."]: "Ingrese el marcado.",
    ["Confirmation"]: "Confirmación",
    ["Scheduled Reminder"]: "Recordatorio programado",
    ["Tier Name is Compulsory"]: "El nombre del nivel es obligatorio",
    ["Tier Value is Compulsory"]: "El valor del nivel es obligatorio",
    ["First Tier Value is Compulsory"]: "El valor del primer nivel es obligatorio",
    ["Selecting Any Field is compulsory"]: "Seleccionar Cualquier Campo es obligatorio",
    ["Tier Type"]: "Tipo de nivel",
    ["Tier One"]: "Nivel uno",
    ["Tier Two"]: "Nivel dos",
    ["Enter Tier Name"]: "Ingrese el nombre del nivel",
    ["View Location"]: "Ver ubicación",
    ["Country is compulsory"]: "El país es obligatorio",
    ["Country is invalid"]: "El país no es válido",
    ["Contact name is invalid"]: "El nombre de contacto no es válido",
    ["Add to Cart Report"]: "Agregar al informe del carrito",
    ["Wish List Report"]: "Informe de lista de deseos",
    ["Please Enter Message."]: "Por favor ingrese el mensaje.",
    ["View My"]: "Ver mi",
    ["Attribute is compulsory"]: "El atributo es obligatorio",
    ["Please Select csv file only"]: "Seleccione solo el archivo csv",
    ["Reset Mapping"]: "Restablecer asignación",
    ["Vary Pricing By Finger Size"]: "Varíe los precios según el tamaño del dedo",
    ["Schedule Reminder"]: "Recordatorio de programación",
    ["Select Finger Size"]: "Seleccionar tamaño de dedo",
    ["Video Type"]: "Tipo de vídeo",
    ["This option will override the data."]: "Esta opción anulará los datos.",
    ["Load Image From URL"]: "Cargar imagen desde URL",
    ["Trade show is compulsory"]: "La feria es obligatoria",
    ["Sales rep is compulsory"]: "El representante de ventas es obligatorio",
    ["Appointment date is compulsory"]: "La fecha de la cita es obligatoria.",
    ["Buyer name is compulsory"]: "El nombre del comprador es obligatorio",
    ["Question is compulsory"]: "La pregunta es obligatoria",
    ["Time is compulsory"]: "El tiempo es obligatorio",
    ["Write the first message ...."]: "Escribe el primer mensaje....",
    ["Post"]: "Correo",
    ["Select Sales Rep"]: "Seleccionar representante de ventas",
    ["Location Details"]: "Detalles de ubicación",
    ["Location Email"]: "Ubicación Correo electrónico",
    ["Sunday"]: "Domingo",
    ["AM to"]: "Yo igual",
    ["Wednesday"]: "miércoles",
    ["No record found"]: "Ningún record fue encontrado",
    ["Select 1 Tier PageName"]: "Seleccione 1 nombre de página de nivel",
    ["Select 2 Tier PageName"]: "Seleccionar nombre de página de 2 niveles",
    ["Select Location"]: "Seleccionar ubicación",
    ["Use"]: "Usar",
    ["Select File"]: "Seleccione Archivo",
    ["Upload Images"]: "Subir imágenes",
    ["Use this Company Name, Tagline and bio as my Brand name, tagline and Bio on my authorized Retailer websites."]:
      "Usar este nombre de empresa, eslogan y biografía como mi marca, eslogan y biografía en los sitios web de mis minoristas autorizados.",
    ["Click here to configure your different Brand marketing message for use on your authorized Retailer websites."]:
      "Haga clic aquí para configurar su mensaje de marketing de marca diferente para su uso en los sitios web de sus minoristas autorizados.",
    ["Since"]: "Ya que",
    ["Primary E-mail"]: "Correo electrónico principal",
    ["Primary Phone"]: "Teléfono Principal",
    ["Primary Contact Name"]: "Nombre de contacto principal",
    ["Company Logo"]: "Logo de la compañía",
    ["Phone no is invalid"]: "El número de teléfono no es válido",
    ["Change"]: "Cambio",
    ["Product Details"]: "Detalles de producto",
    ["Jewelery Characteristics"]: "Características de la joyería",
    ["SKU #"]: "SKU #",
    ["Style"]: "Estilo",
    ["Cost ($)"]: "Costo ($)",
    ["MSRP ($)"]: "MSRP ($)",
    ["Width (mm)"]: "Ancho (mm)",
    ["Thickness (mm)"]: "Espesor (mm)",
    ["Length (in)"]: "Longitud (pulgadas)",
    ["Weight (gm)"]: "Peso (g)",
    ["Last Updated On"]: "Ultima actualización en",
    ["Description"]: "Descripción",
    ["Dealer Info"]: "Información del distribuidor",
    ["Dealer Information"]: "Información del distribuidor",
    ["Company"]: "Compañía",
    ["Contact Person"]: "Persona de contacto",
    ["Fax"]: "Fax",
    ["E-mail"]: "Correo electrónico",
    ["Export All"]: "Exportar todo",
    ["Lab Grown Diamond"]: "Diamante cultivado en laboratorio",
    ["Warning: Changing or modifying your"]: "Advertencia: Cambiar o modificar su",
    ["will cause the"]: "hará que el",
    ["to be reset: Click Yes to proceed"]: "para restablecer: haga clic en Sí para continuar",
    ["In Frame"]: "En el marco de",
    ["Update Filters"]: "Actualizar filtros",
    ["Invalid range"]: "Rango no válido",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price and Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]: "De manera predeterminada, su búsqueda mostrará los detalles básicos del diamante (corte, color, claridad, tamaño, precio y certificado) en la página de resultados de búsqueda. Puede optar por mostrar información más detallada (tabla, profundidad, medidas), así como la información básica en la página de resultados de búsqueda. Independientemente del método que seleccione, los detalles completos de cada diamante siempre están disponibles al hacer clic en Más información en la página de resultados de búsqueda.",
    ["You may choose to search by Cut Grade if desired. Table and Depth searching is enabled as well as they are directly related to Cut Grade."]: "Puede optar por buscar por grado de corte si lo desea. La búsqueda de tabla y profundidad está habilitada y están directamente relacionadas con el grado de corte.",
    ["Please Select Default Markup"]: "Seleccione el marcado predeterminado",
    ["Min Range should be less than 10 digit"]: "El rango mínimo debe ser inferior a 10 dígitos",
    ["Max Range should be less than 10 digit"]: "El rango máximo debe ser inferior a 10 dígitos",
    ["Max Price should be greater than Min Price"]: "El precio máximo debe ser mayor que el precio mínimo",
    ["Cut/Shape"]: "Cortar/Formar",
    ["MaxPrice Should be greater than MinPrice"]: "MaxPrice debe ser mayor que MinPrice",
    ["Please enter Max price up to two decimal places"]: "Ingrese el precio máximo hasta dos decimales",
    ["Choose Whether you would like a particular metal type to be set as the default when viewing a setting in RingBuilder."]: "Elija si desea que un tipo de metal en particular se establezca como predeterminado al visualizar una configuración en RingBuilder.",
    ["Choose Whether to display the “Add to Cart” button or not."]: "Elija si mostrar o no el botón Agregar al carrito",
    ["14k White Gold"]: "Oro blanco de 14k",
    ["14k Yellow Gold"]: "Oro amarillo de 14k",
    ["14k Rose Gold"]: "Oro rosa de 14k",
    ["18k White Gold"]: "Oro Blanco 18k",
    ["Certificate"]: "Certificado",
    ["Negative Values is not Accepted"]: "No se aceptan valores negativos",
    ["Choose whether to display tool alignment as Center, Left, or Right position."]: "Elija si desea mostrar la alineación de la herramienta como posición Centro, Izquierda o Derecha",
    ["Choose whether to display the share button (FaceBook, Twitter, etc.) on the diamond details page."]: "Elija si mostrar el botón de compartir (FaceBook, Twitter, etc.) en la página de detalles del diamante.",
    ["Choose whether to show In Store diamond on the diamond inventory page."]: "Elija si desea mostrar el diamante en la tienda en la página de inventario de diamantes.",
    ["Choose whether to use the popup of diamond details on the diamond inventory page."]: "Elija si desea utilizar la ventana emergente de detalles de diamantes en la página de inventario de diamantes.",
    ["Choose the number of setting displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]: "Elija el número de configuración que se muestra en cada página de los resultados de búsqueda. Para valores distintos a los que se muestran, debe comunicarse directamente con GemFind.",
    ["Choose whether to display settings based on the sort order of Price, Metal Type, or Style Number."]: "Elija si mostrar la configuración según el orden de clasificación de Precio, Tipo de metal o Número de estilo",
    ["Choose whether to display sorting results in Ascending or Descending order."]: "Elija si desea mostrar los resultados de clasificación en orden ascendente o descendente",
    ["Approve"]: "Aprobar",
    ["Disapprove"]: "Desaprobar",
    ["Connected and Pending Request Details"]: "Detalles de solicitudes conectadas y pendientes",
    ["Hierarchy"]: "Jerarquía",
    ["Date Range"]: "Rango de fechas",
    ["MasterLink"]: "Enlace maestro",
    ["RingBuilder"]: "constructor de anillos",
    ["StudBuilder"]: "constructor de espárragos",
    ["PendantBuilder"]: "colganteconstructor",
    ["WatchBuilder"]: "WatchBuilder",
    ["No Data"]: "Sin datos",
    ["Date Added"]: "Fecha Agregada",
    ["Diamond Charts"]: "Gráficos de diamantes",
    ["UserIPAddress"]: "Dirección IP de usuario",
    ["RetailerPrice"]: "MinoristaPrecio",
    ["Select View"]: "Seleccione Ver",
    ["Available Online Only"]: "Disponible solo en línea",
    ["Best Seller"]: "Mejor vendedora",
    ["Clearance / Sale"]: "Venta de liquidación",
    ["Close Out/ Discontinue"]: "Cerrar/ Descontinuar",
    ["Add Campaign"]:"Agregar campaña",
    ["Name is Compulsory."]:"El nombre es obligatorio.",
    ["Vendor is Compulsory."]:"El vendedor es obligatorio.",
    ["Subject is Compulsory."]:"El tema es obligatorio.",
    ["Body is Compulsory."]:"El cuerpo es Obligatorio.",
    ["Content is Compulsory."]:"El contenido es obligatorio.",
    ["Send From"]:"Enviado desde",
    ["Mail Content"]:"Contenido del correo",
    ["Body"]:"Cuerpo",
    ["Content"]:"Contenido",
    ["Change Password"]:"Cambia la contraseña",
    ["Email Preferences"]:"Preferencias de correo electrónico",
    ["Social Integrations"]:"Integraciones sociales",
    ["Brand"]:"Marca",
    ["Solr Core Clients"]:"Clientes principales de Solr",
    ["Add color"]:"Añadir color",
    ["Extended Diamond Charts"]:"Gráficos de diamantes extendidos",
    ["Marketing List"]:"Lista de mercadeo",
    ["API Endpoint and Authentication"]:"Punto final de API y autenticación",
    ["Api Endpoint and Authentication"]:"Api Endpoint y autenticación",
    ["Manage Vendor"]:"Administrar proveedor",
    ["MarkUp"]:"Margen",
    ["Fancy Diamond"]:"Diamante de lujo",
    ["Departments"]:"Departamentos",
    ["Manage vendor"]:"Administrar proveedor",
    ["Setting Vendors"]:"Configuración de proveedores",
    ["My Vendor"]:"Mi vendedor",
    ["Network Vendor"]:"Proveedor de red",
    ["Data"]:"Datos",
    ["Import"]:"Importar",
    ["Connection"]:"Conexión",
    ["Archived Vendor"]:"Proveedor archivado",
    ["New Vendor"]:"Nueva vendedora",
    ["Pending Request"]:"Solicitud pendiente",
    ["Popular Vendor"]:"Vendedor popular",
    ["Retailer Group"]:"Grupo de minoristas",
    ["Manage Dealers"]:"Administrar distribuidores",
    ["Add Jewelry"]:"Añadir joyas",
    ["Jewelry MarkUp"]:"Margen de joyería",
    ["Master Link Settings"]:"Configuración de enlace maestro",
    ["All Product"]:"Todo el producto",
    ["My Product"]:"Mi producto",
    ["Shared Product"]:"Producto compartido",
    ["Customers"]:"Clientes",
    ["Customer Info"]:"Información del cliente",
    ["Messages"]:"Mensajes",
    ["Registered:"]:"Registrada:",
    ["Other Info"]:"Otra información",
    ["Most Active"]:"Más activa",
    ["Recent Conversations"]:"Conversaciones recientes",
    ["Contact Tags"]:"Etiquetas de contacto",
    ["Enter Tag Name"]:"Ingrese el nombre de la etiqueta",
    ["Top Designers"]:"Mejores diseñadoras",
    ["New Customers"]:"Nuevas clientas",
    ["New Arrivals"]:"Los recién llegados",
    ["Note Cannot Be Blank."]:"La nota no puede estar en blanco.",
    ["Data Cannot Be Blank."]:"Los datos no pueden estar en blanco.",
    ["Tag Cannot Be Blank."]:"La etiqueta no puede estar en blanco.",
    ["Please Select Contact."]:"Seleccione Contacto.",
    ["Please Select Calendar."]:"Seleccione Calendario.",
    ["Please Select Time."]:"Seleccione Hora.",
    ["Please Select Day."]:"Seleccione el día.",
    ["Time is Compulsory."]:"El tiempo es obligatorio.",
    ["Day is Compulsory."]:"El día es Obligatorio.",
    ["Reply"]:"Respuesta",
    ["Edit Appointment"]:"Editar cita",
    ["Delete Appointment"]:"Eliminar cita",
    ["Approved Users"]:"Usuarias aprobadas",
    ["Pending Users"]:"Usuarios pendientes",
    ["Reject"]:"Rechazar",
    ["Create New Pair"]:"Crear nuevo par",
    ["Enter Key"]:"Introducir clave",
    ["Enter Value"]:"Introducir valor",
    ["Jewelry Clicks Based on Retailer"]:"Clics en joyas según el minorista",
    ["Diamond Color Searches"]:"Búsquedas de colores de diamantes",
    ["Diamond Size Searches"]:"Búsquedas de tamaño de diamante",
    ["Diamond CutGrade Searches"]:"Búsquedas de grado de corte de diamante",
    ["Diamond Price"]:"Precio del diamante",
    ["Add New Email"]:"Agregar nuevo correo electrónico",
    ["Enter Alias Address"]:"Ingrese la dirección de alias",
    ["Add New Recipient"]:"Agregar nueva destinataria",
    ["Jewelry Clicks Based on Metal Type"]:"Clics en joyas según el tipo de metal",
    ["Group Discount Name"]:"Nombre de descuento de grupo",
    ["Discount Value"]:"Valor de descuento",
    ["Company Name (User Name)"]:"Nombre de la empresa (Nombre de usuario)",
    ["Group Name"]:"Nombre del grupo",
    ["Add Retailer"]:"Agregar minorista",
    ["BrandGroup"]:"MarcaGrupo",
    ["Role"]:"Role",
    ["Address2"]:"Dirección 2",
    ["Select GroupName"]:"Seleccionar nombre de grupo",
    ["Retailer Number"]:"Número de minorista",
    ["Sales Person"]:"Vendedor",
    ["Price Group"]:"Precio grupal",
    ["Telephone"]:"Teléfono",
    ["FTP Location"]:"Ubicación FTP",
    ["FTP User Password"]:"Contraseña de usuario FTP",
    ["Update Detail"]:"Actualizar detalle",
    ["Attribute Detail"]:"Detalle de atributo",
    ["Mapping Help Docs"]:"Documentos de ayuda de mapeo",
    ["Please Map Column First"]:"Primero la columna del mapa",
    ["No information available for selected user."]:"No hay información disponible para el usuario seleccionado.",
    ["Display Video"]:"Mostrar vídeo",
    ["Display Image"]:"Mostrar imagen",
    ["No File Chosen"]:"Ningún archivo elegido",
    ["Kindly select the Country"]:"Por favor seleccione el País",
    ["Enter Valid Dealer Id"]:"Ingrese una identificación de distribuidor válida",
    ["Dealer Not Found"]:"Distribuidora no encontrada",
    ["Send Mail To Retailer"]:"Enviar correo a la minorista",
    ["Send Mail To Recipient"]:"Enviar correo a la destinataria",
    ["Both the entered password should match"]:"Tanto la contraseña ingresada debe coincidir",
    ["New Password"]:"Nueva contraseña",
    ["Change password"]:"Cambia la contraseña",
    ["BrandID"]:"ID de marca",
    ["BrandName"]:"Nombre de la marca",
    ["Link"]:"Enlace",
    ["Manage Brand"]:"Administrar marca",
    ["Create Brand"]:"Crear marca",
    ["Insert Brand"]:"Insertar marca",
    ["Use Vendor Profile Logo"]:"Usar logotipo de perfil de proveedor",
    ["Fashion Designer"]:"Diseñador de moda",
    ["Watch Designer"]:"Diseñadora de relojes",
    ["Showcase Image 1"]:"Imagen de escaparate 1",
    ["Showcase Image 2"]:"Imagen de escaparate 2",
    ["Showcase Image 3"]:"Imagen de escaparate 3",
    ["Use In Showcase"]:"Usar en escaparate",
    ["Designer Small Banner"]:"Pancarta pequeña de diseñador",
    ["Video URL"]:"URL del vídeo",
    ["How To Upload Video"]:"Cómo subir videos",
    ["URL"]:"URL",
    ["About Designer"]:"Sobre la diseñadora",
    ["Tag Line"]:"línea de etiqueta",
    ["You can enter up to 100 characters."]:"Puede introducir hasta 100 caracteres.",
    ["Shows"]:"espectáculos",
    ["Las Vegas Antique Jewelry"]:"Joyería antigua de Las Vegas",
    ["Booth #(s)"]:"Stand #(s)",
    ["Notes & Show Specials"]:"Notas y Espectáculos Especiales",
    ["Save Show Details"]:"Guardar mostrar detalles",
    ["User Avatar"]:"Avatar de usuario",
    ["Current Password"]:"contraseña actual",
    ["Confirm New Password"]:"Confirmar nueva contraseña",
    ["Location is compulsory"]:"La ubicación es obligatoria",
    ["Actions"]:"Comportamiento",
    ["Reorder"]:"Reordenar",
    ["PO Name is Compulsory"]:"El nombre de la orden de compra es obligatorio",
    ["PO Notes is Compulsory"]:"Las notas de orden de compra son obligatorias",
    ["Worksheet"]:"Hoja de cálculo",
    ["Use this to build and manage your products before sending a purchase order."]:"Úselo para crear y administrar sus productos antes de enviar una orden de compra.",
    ["View All"]:"Ver todo",
    ["Delete the"]:"Borrar el",
    ["Cancel The"]:"cancelar el",
    ["Order"]:"Ordenar",
    ["Generate PO"]:"Generar orden de compra",
    ["PO Name"]:"Nombre de la orden de compra",
    ["Purchase Order Summery"]:"Resumen de orden de compra",
    ["Create RO"]:"Crear RO",
    ["Shipping Address"]:"Dirección de Envío",
    ["Manufacturer Address"]:"Dirección del fabricante",
    ["Order Notes"]:"pedidos",
    ["4 Tier PageName"]:"Nombre de página de 4 niveles",
    ["Add New Tier"]:"Agregar nuevo nivel",
    ["Kind"]:"Amable",
    ["Modified"]:"Modificada",
    ["Upload Media"]:"Subir medios",
    ["Add Folder"]:"Agregar carpeta",
    ["Are You Sure You Want To Delete This Folder ?"]:"¿Estás segura de que quieres eliminar esta carpeta?",
    ["THIS CAN NOT BE UNDO"]:"ESTO NO SE PUEDE DESHACER",
    ["Upload Your Media File"]:"Cargue su archivo multimedia",
    ["Share marketing materials across your reatailer network."]:"Comparta materiales de marketing en su red de minoristas.",
    ["Please Select Folder"]:"Seleccione la carpeta",
    ["Total Mined Diamonds"]:"Total de diamantes extraídos",
    ["Total LabGrown Diamonds"]:"Total de diamantes cultivados en laboratorio",
    ["Total Color Diamonds"]:"Diamantes de color total",
    ["Update List"]:"Lista de actualizacion",
    ["Our Signature Diamond"]: "Nuestro diamante característico",
    ["Network Vendors"]: "Proveedores de red",
    ["Stud Builder Email"]: "Correo electrónico del constructor de montantes",
    ["Add to Worksheet"]: "Agregar a la hoja de trabajo",
    ["PriceMethod"]: "PrecioMétodo",
    ["Page Not access Please Contect to admin."]: "No se accede a la página. Por favor, póngase en contacto con el administrador.",
    ["Customer Profile"]: "Perfil de cliente",
    ["Add Details"]: "Agregar detalles",
    ["Message Cannot Be Blank."]: "El mensaje no puede estar en blanco.",
    ["Enter E-mail"]: "Ingrese correo electrónico",
    ["Top Designer"]: "Diseñadora superior",
    ["Select Trade Show"]: "Seleccionar feria comercial",
    ["Select Trade Show"]: "Seleccionar feria comercial",
    ["I am interested in opening a new account with"]: "Estoy interesado en abrir una nueva cuenta con",
    ["Pendant Builder Shopping cart configuration instructions"]: "Instrucciones de configuración del carrito de compras Pendant Builder",
    ["Select Files"]: "Selecciona archivos",
    ["Insert Brands"]: "Insertar Marcas",
    ["Designer Logo"]: "Logotipo del diseñador",
    ["Update Brands"]: "Actualizar marcas",
    ["Sample Showcase Preview"]: "Vista previa de escaparate de muestra",
    ["THIS CANNOT BE UNDONE"]: "ESTO NO SE PUEDE DESHACER",
    ["If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor)."]: "Si su empresa vende su producto bajo una marca de consumo, use esta interfaz para configurar la información de la marca. Las marcas se incluirán en los sitios web de los minoristas en la navegación por categorías (a menos que esté restringido por el proveedor).",
    ["Delete The "]: "Eliminar la",
    ["Brand "]: "Marca",
    ["Fluor."]:"Flúor.",
    ["Measure."]:"Medida.",
    ["Sym."]:"Sim.",
    ["Brand Name"]:"Nombre de la marca",
    ["Brand ID"]:"identificación de la marca",
    ["Advertisement Status"]:"Estado del anuncio",
    ["Company name is Taken"]:"El nombre de la empresa está tomado",
    ["No Data Available."]:"Datos no disponibles.",
    ["No Data Found"]:"Datos no encontrados",
    ["Folder Name"]:"Nombre de la carpeta",
    ["Reports"]:"Informes",
    ["Sorry, No Chart Data was found"]:"Lo sentimos, no se encontraron datos del gráfico",
    ["Diamond Price Based Add To Cart Report"]:"Informe de agregar al carrito basado en el precio del diamante",
    ["Diamond Price Based Wish List Report"]:"Informe de lista de deseos basado en el precio del diamante",
    ["Diamond Vendor based Add To Cart Report"]:"Informe de agregar al carrito basado en proveedores de diamantes",
    ["Diamond Vendor based Wish List Report"]:"Informe de lista de deseos basado en proveedores de diamantes",
    ["Jewelry Price Based Add To Cart Report"]:"Reporte de agregar al carrito basado en el precio de la joyería",
    ["Jewelry Price Based Wish List Report"]:"Informe de lista de deseos basado en el precio de la joyería",
    ["Jewelery Vendor based Add To Cart Report"]:"Informe de agregar al carrito basado en el proveedor de joyería",
    ["Jewelery Vendor based Wish List Report"]:"Informe de lista de deseos basado en proveedores de joyería",
    ["Updated Date"]:"Fecha actualizada",
    ["MarkUp Overrride"]:"Anulación de marcado",
    ["Percent"]:"Por ciento",
    ["This shows the collection on your own MasterLink, for preview purposes"]:"Esto muestra la colección en su propio MasterLink, con fines de vista previa",
    ["This shares the collection with your connected retailers"]:"Esto comparte la colección con sus minoristas conectados",
    ["Please Contact Gemfind to get Access."]:"Comuníquese con Gemfind para obtener acceso.",
    ["Assecher"]:"Asesor",
    ["Request Certificate"]:"Solicitar Certificado",
    ["The report for this diamond is not viewable online."]:"El informe de este diamante no se puede ver en línea.",
    ["Please contact us by telephone or the form below if you are interested in receiving more information about this diamond."]:
    "Póngase en contacto con nosotros por teléfono o mediante el siguiente formulario si está interesado en recibir más información sobre este diamante.",
    ["Message to"]:"Mensaje para",
    ["Your diamonds are deleted.However it will take some moments to remove them from cache.Deleted diamonds will be removed from this page as soon cache gets refreshed."]:
    "Sus diamantes se eliminan. Sin embargo, tomará algunos minutos eliminarlos del caché. Los diamantes eliminados se eliminarán de esta página tan pronto como se actualice el caché.",
    ["Not Authorized"]:"No autorizado",
    ["Not deleted as those are not your diamonds."]:"No eliminado porque esos no son sus diamantes.",
    ["Diamond List"]:"Lista de diamantes",
    ["Pink"]:"Rosa",
    ["Gray"]:"Gris",
    ["Black"]:"Negro",
    ["Purple"]:"Morado",
    ["Chameleon"]:"Camaleón",
    ["Violet"]:"Violeta",
    ["No items found"]:"No se encontraron artículos",
    ["reset"]:"reiniciar",
    ["your filters"]:"tus filtros",
    ["Add To WorkSheet"]:"Agregar a la hoja de trabajo",
    ["Add Products"]:"Añadir productos",
    ["WorkSheet Name"]:"Nombre de la hoja de trabajo",
    ["View WorkSheet"]:"Ver hoja de trabajo",
    ["Carat"]:"Quilate",
    ["Upload Certificates"]:"Subir certificados",
    ["Zip Format"]:"formato zip",
    ["No Data To Display"]:"No hay información para mostrar",
    ["Customer added"]:"Cliente agregado",
    ["as favorite through"]:"como favorito a través",
    ["Create WorkSheet"]:"Crear hoja de trabajo",
    ["Delete Worksheet"]:"Eliminar hoja de trabajo",
    ["Worksheet Name"]:"Nombre de la hoja de trabajo",
    ["Please Connect the Vendor for more information."]:"Conectar al proveedor para obtener más información.",
    ["Item Count"]:"Recuento de elementos",
    ["Reorder PO"]:"Reordenar orden de compra",
    ["View Order"]:"Ver pedido",
    ["Cancel Order"]:"Cancelar orden",
    ["T"]:"T",
    ["Total Item"]:"Artículo total",
    ["SubTotal"]:"Total parcial",
    ["PO Total"]:"Orden de compra total",
    ["Vendor to provide shipping/insurance info."]:"Proveedor para proporcionar información de envío/seguro.",
    ["Diamond Retailers"]:"Minoristas de diamantes",
    ["Jewelry Retailer & Permissions"]:"Minorista de joyería y permisos",
    ["Blocked Products"]:"Productos bloqueados",
    ["Product Restriction"]:"Restricción de productos",
    ["Collection Access"]:"Acceso a la colección",
    ["Product Access"]:"Acceso al producto",
    ["Manage Retailer Access To Your Inventory"]:"Administre el acceso del minorista a su inventario",
    ["Set permissions for retailers to display your virtual inventory on their website."]:
    "Establezca permisos para que los minoristas muestren su inventario virtual en su sitio web.",
    ["Auto Approval"]:"Aprobación automática",
    ["Automatically approve retailers requesting to open an account."]:"Aprueba automáticamente a los minoristas que solicitan abrir una cuenta.",
    ["Filter By Sales Rep"]:"Filtrar por representante de ventas",
    ["From"]:"De",
    ["To"]:"A",
    ["Upload a datasheet with the column header of StyleNumber. Include one SKU per row of each product you want to block from your chosen connected retailers. Those retailers selected for these SKU's, will not be given access to those products."]:
    "Cargue una hoja de datos con el encabezado de columna de StyleNumber. Incluya un SKU por fila de cada producto que desee bloquear de los minoristas conectados elegidos. Los minoristas seleccionados para estos SKU no tendrán acceso a esos productos.",
    ["Connected Retailers"]:"Minoristas conectados",
    ["Schedule New"]:"Horario Nuevo",
    ["Print Current List"]:"Imprimir lista actual",
    ["Pending"]:"Pendiente",
    ["No notes added"]:"No se agregaron notas",
    ["Connected Vendors List"]:"Lista de proveedores conectados",
    ["Run Campaign"] :"Run Campaign",
    ["Upload History Details"]:"Upload History Details"
  },
};
