import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Select, Spin, Button, Modal } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
const { Option } = Select;

const ApplicationPermissions = () => {
  const initialState = {
    dealerId: null,
    posSystem: null,
    appActivationControl: {},
    membershipType: "",
  };
  const [state, setState] = useState(initialState);
  const [dealerList, setDealerList] = useState([]);
  const [posSystemList, setPosSystemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const [drpLoading, setDroLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    handleFillDealerDropDown();
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.key) {
        var temp = state;
        temp.dealerId = location.state.key;
        setState(temp);
        handleGetMenuList(location.state.key);
      }
    }
  }, []);

  const onChange = (e, name) => {
    let tempState = state;

    if (e.target) {
      tempState[name] = e.target.value;
    } else {
      tempState[name] = e;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name === "dealerId") {
      handleGetMenuList(e);
    }
  };
  const handleFillDealerDropDown = () => {
    setDroLoading(true);
    AdminToolsDataService.FillDealerDropDown()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setDealerList(responseData);
          setDroLoading(false);
        } else {
          setDealerList([]);
          setDroLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setDroLoading(false);
      })
      .finally(() => {
        setDroLoading(false);
      });
  };
  const handleGetMenuList = (Id) => {
    let inputData = {
      dealerId: Id,
    };
    setLoading(true);
    AdminToolsDataService.GetMenuList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message === "Success") {
          setPosSystemList(responseData.posSystemResponse);
          let appActivationControl = {
            ...responseData.diamondSearchMenu,
            ...responseData.parentCheckbox,
          };
          const tempState = state;
          tempState["membershipType"] = responseData.membershipType;
          tempState["appActivationControl"] = appActivationControl;
          tempState["posSystem"] =
            responseData.diamondSearchMenu.posSystemsMasterId == 0
              ? ""
              : responseData.diamondSearchMenu.posSystemsMasterId;

          setState((prevState) => ({
            ...prevState,
            ...tempState,
          }));
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangeCheckBox = (e, name) => {
    let tempState = state;

    tempState.appActivationControl[name] = e.target.checked;

    if (
      name === "chkDiamondLink" ||
      name == "chkDiamondEcomm" ||
      name == "chkDiamondSsl" ||
      name == "chkDSSPBy" ||
      name == "chkLabGrownDiamonds"
    ) {
      if (name === "chkDiamondLink" && e.target.checked === false) {
        tempState.appActivationControl["chkDiamondEcomm"] = false;
        tempState.appActivationControl["chkDiamondSsl"] = false;
        tempState.appActivationControl["chkDSSPBy"] = false;
        tempState.appActivationControl["chkLabGrownDiamonds"] = false;
      }
      if (
        tempState.appActivationControl["chkDiamondEcomm"] === false &&
        tempState.appActivationControl["chkDiamondSsl"] === false &&
        tempState.appActivationControl["chkDSSPBy"] === false &&
        tempState.appActivationControl["chkLabGrownDiamonds"] === false
      ) {
        tempState.appActivationControl["chkDiamondLink"] = false;
      } else if (
        tempState.appActivationControl["chkDiamondEcomm"] === true ||
        tempState.appActivationControl["chkDiamondSsl"] === true ||
        tempState.appActivationControl["chkDSSPBy"] === true ||
        tempState.appActivationControl["chkLabGrownDiamonds"] === true ||
        e.target.checked === true
      ) {
        tempState.appActivationControl["chkDiamondLink"] = true;
      }
    }

    if (
      name === "chkRingBuilder" ||
      name == "chkRingBuilderEcom" ||
      name == "chkRingBuilderSsl" ||
      name == "chkRBSPBy" ||
      name == "chkTryon"
    ) {
      if (name === "chkRingBuilder" && e.target.checked === false) {
        tempState.appActivationControl["chkRingBuilderEcom"] = false;
        tempState.appActivationControl["chkRingBuilderSsl"] = false;
        tempState.appActivationControl["chkRBSPBy"] = false;
        tempState.appActivationControl["chkTryon"] = false;
      }
      if (
        tempState.appActivationControl["chkRingBuilderEcom"] === false &&
        tempState.appActivationControl["chkRingBuilderSsl"] === false &&
        tempState.appActivationControl["chkRBSPBy"] === false &&
        tempState.appActivationControl["chkTryon"] === false
      ) {
        tempState.appActivationControl["chkRingBuilder"] = false;
      } else if (
        tempState.appActivationControl["chkRingBuilderEcom"] === true ||
        tempState.appActivationControl["chkRingBuilderSsl"] === true ||
        tempState.appActivationControl["chkRBSPBy"] === true ||
        tempState.appActivationControl["chkTryon"] === true ||
        e.target.checked === true
      ) {
        tempState.appActivationControl["chkRingBuilder"] = true;
      }
    }

    if (
      name === "chkStudBuilder" ||
      name == "chkStudBuilderEcom" ||
      name == "chkStudBuilderSsl" ||
      name == "chkSBSPBy"
    ) {
      if (name === "chkStudBuilder" && e.target.checked === false) {
        tempState.appActivationControl["chkStudBuilderEcom"] = false;
        tempState.appActivationControl["chkStudBuilderSsl"] = false;
        tempState.appActivationControl["chkSBSPBy"] = false;
      }
      if (
        tempState.appActivationControl["chkStudBuilderEcom"] === false &&
        tempState.appActivationControl["chkStudBuilderSsl"] === false &&
        tempState.appActivationControl["chkSBSPBy"] === false
      ) {
        tempState.appActivationControl["chkStudBuilder"] = false;
      } else if (
        tempState.appActivationControl["chkStudBuilderEcom"] === true ||
        tempState.appActivationControl["chkStudBuilderSsl"] === true ||
        tempState.appActivationControl["chkSBSPBy"] === true ||
        e.target.checked === true
      ) {
        tempState.appActivationControl["chkStudBuilder"] = true;
      }
    }
    if (
      name == "chkPendantBuilder" ||
      name == "chkPendantBuilderEcom" ||
      name == "chkPendantBuilderSsl" ||
      name == "chkPBSPBy"
    ) {
      if (name === "chkPendantBuilder" && e.target.checked === false) {
        tempState.appActivationControl["chkPendantBuilderEcom"] = false;
        tempState.appActivationControl["chkPendantBuilderSsl"] = false;
        tempState.appActivationControl["chkPBSPBy"] = false;
      }
      if (
        tempState.appActivationControl["chkPendantBuilderEcom"] === false &&
        tempState.appActivationControl["chkPendantBuilderSsl"] === false &&
        tempState.appActivationControl["chkPBSPBy"] === false
      ) {
        tempState.appActivationControl["chkPendantBuilder"] = false;
      } else if (
        tempState.appActivationControl["chkPendantBuilderEcom"] === true ||
        tempState.appActivationControl["chkPendantBuilderSsl"] === true ||
        tempState.appActivationControl["chkPBSPBy"] === true ||
        e.target.checked === true
      ) {
        tempState.appActivationControl["chkPendantBuilder"] = true;
      }
    }

    if (name === "chkML3" && e.target.checked === false) {
      tempState.appActivationControl["chkMLEcomm"] = false;
      tempState.appActivationControl["chkMLSsl"] = false;
      tempState.appActivationControl["chkMLSPBy"] = false;
    }
    if (name === "chkML3" && e.target.checked === true) {
      tempState.appActivationControl["chkMLEcomm"] = true;
      tempState.appActivationControl["chkMLSsl"] = true;
      tempState.appActivationControl["chkMLSPBy"] = false;
    }
    if (
      name === "chkMLEcomm" ||
      name === "chkMLSsl" ||
      (name === "chkMLSPBy" && e.target.checked === true)
    ) {
      tempState.appActivationControl["chkML3"] = true;
    }
    if (e.target) {
      tempState.appActivationControl[name] = e.target.checked;
    } else {
      tempState[name] = e;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleSaveMenuList = () => {
    setVisibleConfirmModal(false);

    let inputData = {
      loginUserId: "",
      posId: state.posSystem.toString(),
      menus: state.appActivationControl,
      dealerId: state.dealerId,
    };
    setSubmitLoading(true);
    AdminToolsDataService.SaveMenuList(inputData)
      .then((response) => {
        var message = response.data.message;
        setSubmitLoading(false);
        if (message === "Success") {
          NotificationManager.success(
            "Designer Menu Preferences Submited Sucessfully."
          );
          handleGetMenuList(state.dealerId);
        } else {
          NotificationManager.error("Designer Menu Preferences Not Submited.");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  return (
    <React.Fragment>
      <div className="appermission__section ApplicationPermission">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("App Activation Control")}
              </h4>
            </div>
            <div className="col-lg-3 col-md-6 mt-4 mobile-m-0">
              <div className="input__block">
                <label> {traslate("Select Dealer")}:</label>
                <Select
                  showSearch
                  className={"border__grey"}
                  placeholder="Select Dealer"
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={state.dealerId}
                  onChange={(e) => {
                    onChange(e, "dealerId");
                  }}
                  loading={drpLoading}
                >
                  <Option value=""> {traslate("Select Dealer")} </Option>
                  {dealerList.map((item) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " (" + item.dealerID + ")"}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            {loading ? (
              <Spin size="large" />
            ) : state.dealerId ? (
              <React.Fragment>
                <div className="col-lg-12">
                  <h3 className="subheading">
                    {traslate("App Activation Control")}
                  </h3>
                </div>

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkDiamondLink"
                            checked={state.appActivationControl.chkDiamondLink}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkDiamondLink");
                            }}
                          />
                          <label htmlFor="chkDiamondLink">
                            {traslate("Diamond Link")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <span className="diamsrch__txt">
                              {traslate("Diamond Search Links")}
                            </span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDiamondEcomm"
                              checked={
                                state.appActivationControl.chkDiamondEcomm
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDiamondEcomm");
                              }}
                            />
                            <label htmlFor="chkDiamondEcomm">
                              {traslate("DS Ecommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDSSPBy"
                              checked={state.appActivationControl.chkDSSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDSSPBy");
                              }}
                            />
                            <label htmlFor="chkDSSPBy">
                              {traslate("DS Show PoweredBy")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkLabGrownDiamonds"
                              checked={
                                state.appActivationControl.chkLabGrownDiamonds
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkLabGrownDiamonds");
                              }}
                            />
                            <label htmlFor="chkLabGrownDiamonds">
                              {traslate("DS LabGrownDiamonds")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chckbox__subdiv">
                          <div>
                            <span className="diamsrch__txt">
                              {traslate("Jewel Cloud")}
                            </span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkApi"
                              checked={state.appActivationControl.chkApi}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkApi");
                              }}
                            />
                            <label htmlFor="chkApi">{traslate("API")}</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDirectFeed"
                              checked={state.appActivationControl.chkDirectFeed}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDirectFeed");
                              }}
                            />
                            <label htmlFor="chkDirectFeed">
                              {traslate("Direct feed")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkWindowsApp"
                              checked={state.appActivationControl.chkWindowsApp}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkWindowsApp");
                              }}
                            />
                            <label htmlFor="chkWindowsApp">
                              {traslate("Windows App")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkMasterlink"
                              checked={state.appActivationControl.chkMasterlink}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkMasterlink");
                              }}
                            />
                            <label htmlFor="chkMasterlink">
                              {traslate("Master Link")}
                            </label>
                          </div>
                          <div className="chckbox__subdiv">
                            <div>
                              <input
                                type="checkbox"
                                id="chkMLEcomm"
                                checked={state.appActivationControl.chkMLEcomm}
                                onChange={(e) => {
                                  handleChangeCheckBox(e, "chkMLEcomm");
                                }}
                              />
                              <label htmlFor="chkMLEcomm">
                                {traslate("ML Ecommerce")}
                              </label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="chkMLSPBy"
                                checked={state.appActivationControl.chkMLSPBy}
                                onChange={(e) => {
                                  handleChangeCheckBox(e, "chkMLSPBy");
                                }}
                              />
                              <label htmlFor="chkMLSPBy">
                                {traslate("ML Show PoweredBy")}
                              </label>
                            </div>
                            {state.membershipType == "VendorDiamond" ||
                              state.membershipType == "Vendor" ? (
                              <div>
                                <input
                                  type="checkbox"
                                  id="chkRetailerLocator"
                                  checked={
                                    state.appActivationControl
                                      .chkRetailerLocator
                                  }
                                  onChange={(e) => {
                                    handleChangeCheckBox(
                                      e,
                                      "chkRetailerLocator"
                                    );
                                  }}
                                />
                                <label htmlFor="chkRetailerLocator">
                                  {traslate("ML Retailer Locator")}
                                </label>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkRingBuilder"
                            checked={state.appActivationControl.chkRingBuilder}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkRingBuilder");
                            }}
                          />
                          <label htmlFor="chkRingBuilder">
                            {traslate("Ring Builder")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkRingBuilderEcom"
                              checked={
                                state.appActivationControl.chkRingBuilderEcom
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkRingBuilderEcom");
                              }}
                            />
                            <label htmlFor="chkRingBuilderEcom">
                              {traslate("RB Ecommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkRBSPBy"
                              checked={state.appActivationControl.chkRBSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkRBSPBy");
                              }}
                            />
                            <label htmlFor="chkRBSPBy">
                              {traslate("RB Show PoweredBy")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkTryon"
                              checked={state.appActivationControl.chkTryon}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkTryon");
                              }}
                            />
                            <label htmlFor="chkTryon">
                              {traslate("Tryon")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkStudBuilder"
                            checked={state.appActivationControl.chkStudBuilder}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkStudBuilder");
                            }}
                          />
                          <label htmlFor="chkStudBuilder">
                            {traslate("Stud Builder")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkStudBuilderEcom"
                              checked={
                                state.appActivationControl.chkStudBuilderEcom
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkStudBuilderEcom");
                              }}
                            />
                            <label htmlFor="chkStudBuilderEcom">
                              {traslate("SB Ecommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkSBSPBy"
                              checked={state.appActivationControl.chkSBSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkSBSPBy");
                              }}
                            />
                            <label htmlFor="chkSBSPBy">
                              {traslate("SB Show PoweredBy")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkPendantBuilder"
                            checked={
                              state.appActivationControl.chkPendantBuilder
                            }
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkPendantBuilder");
                            }}
                          />
                          <label htmlFor="chkPendantBuilder">
                            {traslate("Pendant Builder")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkPendantBuilderEcom"
                              checked={
                                state.appActivationControl.chkPendantBuilderEcom
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(
                                  e,
                                  "chkPendantBuilderEcom"
                                );
                              }}
                            />
                            <label htmlFor="chkPendantBuilderEcom">
                              {traslate("PB Ecommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkPBSPBy"
                              checked={state.appActivationControl.chkPBSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkPBSPBy");
                              }}
                            />
                            <label htmlFor="chkPBSPBy">
                              {traslate("PB Show PoweredBy")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkWatchBuilder"
                            checked={state.appActivationControl.chkWatchBuilder}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkWatchBuilder");
                            }}
                          />
                          <label htmlFor="chkWatchBuilder">
                            {traslate("Watch Customizer")}
                          </label>
                        </div>
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkBridalFashion"
                            checked={
                              state.appActivationControl.chkBridalFashion
                            }
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkBridalFashion");
                            }}
                          />
                          <label htmlFor="chkBridalFashion">
                            {traslate("Bridal/Fashion Separation")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="col-lg-3 col-md-6 mt-4">
                    <div className="input__block">
                      <label> {traslate("POS System")}</label>
                      <Select
                        showSearch
                        className="border__grey"
                        placeholder="POS System"
                        optionFilterProp="children"
                        notFoundContent="No Data Found"
                        value={state.posSystem}
                        onChange={(e) => {
                          onChange(e, "posSystem");
                        }}
                      >
                        <Option value=""> {traslate("Select")} </Option>
                        {posSystemList.map((item) => {
                          return (
                            <Option value={item.posSystemsMasterId}>
                              {item.posSystemsText}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>

                {/* For Pop Up  */}
                <div className="col-lg-12">
                  <Button
                    className="primary-btn"
                    loading={submitLoading}
                    onClick={() => setVisibleConfirmModal(true)}
                  >
                    {traslate("Submit")}
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>

      {/* Confirmation Popup */}
      <Modal
        title={traslate("Confirmation")}
        centered
        visible={visibleConfirmModal}
        onOk={handleSaveMenuList}
        onCancel={() => setVisibleConfirmModal(false)}
        width={400}
      >
        <div className="text-center">
          <h5> {traslate("Are you sure to update Permission?")} </h5>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ApplicationPermissions;
