import { httpDealer } from "../helper/http-common";

class ManageWhiteLabelService {
  GetCompanyWhiteLabel(inputData) {
    return httpDealer.get("/Dashboard/CompanyWhileLabel", inputData);
  }

  UpdateWhiteLabel(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpDealer.post("/Dashboard/UpdateWhiteLabel", inputData, {
      headers,
    });
  }
}

export default new ManageWhiteLabelService();
