import React, { useState, useEffect } from "react";
import { Slider, Spin, Button } from "antd";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import AppsDiamondLinksService from "../../../services/apps-diamondlinks.service";
import { GetAllNumbersBetween } from "../../../helper/commanFunction";
import { NotificationManager } from "react-notifications";
const InitialFilterSettings = () => {
  const [disableBtn, setDisableBtn] = useState(true);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [strColors, setStrColors] = useState("");
  const [strCutGrades, setStrCutGrades] = useState("");
  const [strclaritys, setStrClaritys] = useState("");
  const [strPolishs, setStrPolishs] = useState("");
  const [strSymmetrys, setStrSymmetrys] = useState("");
  const [strFluorescences, setStrFluorescences] = useState("");
  const [strShapes, setStrShapes] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(false);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetDiamondTypeList(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const [InitialFilterData, setInitialFilterData] = useState({
    Type: null,
    shapeList: [],
    colorList: [],
    cutList: [],
    clarityList: [],
    polishList: [],
    symmetryList: [],
    fluorescenceList: [],
    certificatesList: [],
    minCarat: 0,
    maxCarat: 22,
    minPrice: 0,
    maxPrice: 100000,
    FluoDepth: "",
    FluoTable: "",
    mainTableMin: 0,
    mainTableMax: 100,
    mainDepthMin: 0,
    mainDepthMax: 100,
    tableMin: 0,
    tableMax: 0,
    depthMin: 0,
    depthMax: 0,
  });

  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [PriceValue, setPriceValue] = useState([0, 100000]);

  const [validation, setValidation] = useState({
    depthMinVal: "",
    depthMaxVal: "",
    tableMaxVal: "",
    tableMinVal: "",
  });
  const [depthMinValue, setDepthMinVal] = useState(0);
  const [depthMaxValue, setDepthMaxValue] = useState(0);
  const [tableMinValue, setTableMinValue] = useState(0);
  const [tableMaxValue, setTableMaxValue] = useState(0);

  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency?.substring(currency.lastIndexOf("-") + 1);

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (isSelect === "select") {
      if (!e) return;
      setInitialFilterData({
        ...InitialFilterData,
        [name]: e,
      });
      handleGetDiamondLinkInitialFilterSetting(dealerId, e);
    } else {
      if (e?.target?.name === "depthMin") {
        if (
          Number(e.target.value) < depthMinValue ||
          Number(e.target.value) > depthMaxValue
        ) {
          e.preventDefault();
        } else {
          setInitialFilterData({
            ...InitialFilterData,
            [e.target.name]: e.target.value,
          });
        }
      } else if (e?.target?.name === "depthMax") {
        if (
          Number(e.target.value) > depthMaxValue ||
          Number(e.target.value) < depthMinValue
        ) {
          e.preventDefault();
        } else {
          setInitialFilterData({
            ...InitialFilterData,
            [e.target.name]: e.target.value,
          });
        }
      } else if (e?.target?.name === "tableMin") {
        if (
          Number(e.target.value) < tableMinValue ||
          Number(e.target.value) > tableMaxValue
        ) {
          e.preventDefault();
        } else {
          setInitialFilterData({
            ...InitialFilterData,
            [e.target.name]: e.target.value,
          });
        }
      } else if (e?.target?.name === "tableMax") {
        if (
          Number(e.target.value) > tableMaxValue ||
          Number(e.target.value) < tableMinValue
        ) {
          e.preventDefault();
        } else {
          setInitialFilterData({
            ...InitialFilterData,
            [e.target.name]: e.target.value,
          });
        }
      }
    }

    if (e?.target?.name === "depthMin") {
      if (Number(e.target.value) < depthMinValue) {
      }
      if (e.target.value > 100) {
        setValidation({
          ...validation,
          depthMinVal: traslate("Invalid range"),
        });
        setDisableBtn(true);
      } else {
        setValidation({
          ...validation,
          depthMinVal: "",
        });
      }
    }
    if (e?.target?.name === "depthMax") {
      if (e.target.value > 100) {
        setValidation({
          ...validation,
          depthMaxVal: traslate("Invalid range"),
        });
        setDisableBtn(true);
      } else {
        setValidation({
          ...validation,
          depthMaxVal: "",
        });
      }
    }
    if (e?.target?.name === "tableMin") {
      if (e.target.value > 100) {
        setValidation({
          ...validation,
          tableMinVal: traslate("Invalid range"),
        });
        setDisableBtn(true);
      } else {
        setValidation({
          ...validation,
          tableMinVal: "",
        });
      }
    }
    if (e?.target?.name === "tableMax") {
      if (e.target.value > 100) {
        setValidation({
          ...validation,
          tableMaxVal: traslate("Invalid range"),
        });
        setDisableBtn(true);
      } else {
        setValidation({
          ...validation,
          tableMaxVal: "",
        });
      }
    }
  };
  const handleSaveDiamondLinkInitialFilterSetting = async (e) => {
    try {
      var strCertificate = "";
      if (InitialFilterData.certificatesList.length > 0) {
        strCertificate = InitialFilterData.certificatesList.map((x) => {
          if (x.selectedDealerID === true || x.selectedDealerID === "true") {
            if (x.value) {
              return x.value;
            }
          }
        });

        var results = "";
        results = strCertificate.filter((element) => {
          return element !== undefined;
        });
      }
      let inputData = {
        id: 0,
        dealerID: dealerId,
        caratMin: caratSlideValue[0],
        caratMax: caratSlideValue[1],
        cutGradeMin: Math.min.apply(null, strCutGrades.split(",")),
        cutGradeMax: Math.max.apply(null, strCutGrades.split(",")),
        colorMin: Math.min.apply(null, strColors.split(",")),
        colorMax: Math.max.apply(null, strColors.split(",")),
        clarityMin: Math.min.apply(null, strclaritys.split(",")),
        clarityMax: Math.max.apply(null, strclaritys.split(",")),
        priceMin: PriceValue[0],
        priceMax: PriceValue[1],
        polishMin: Math.min.apply(null, strPolishs.split(",")),
        polishMax: Math.max.apply(null, strPolishs.split(",")),
        symmetryMin: Math.min.apply(null, strSymmetrys.split(",")),
        symmetryMax: Math.max.apply(null, strSymmetrys.split(",")),
        depthMin: InitialFilterData.depthMin,
        depthMax: InitialFilterData.depthMax,
        tableMin: InitialFilterData.tableMin,
        tableMax: InitialFilterData.tableMax,
        fluorescenceMin: Math.min.apply(null, strFluorescences.split(",")),
        fluorescenceMax: Math.max.apply(null, strFluorescences.split(",")),
        culetMin: 0,
        culetMax: 0,
        diamondType: InitialFilterData.Type,
        certificate: results.join("|"),
        shapelist: strShapes,
      };
      setLoading(true);
      await AppsDiamondLinksService.SaveDiamondLinkInitialFilterSetting(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message === "Success") {
            NotificationManager.success(
              "Initial Filter Settings Updated Successfully."
            );
            handleGetDiamondLinkInitialFilterSetting(
              dealerId,
              InitialFilterData.Type
            );
          } else {
            NotificationManager.success("Initial Filter Settings Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setCaratSlideValue([
      InitialFilterData.minCarat,
      InitialFilterData.maxCarat,
    ]);
    setPriceValue([InitialFilterData.minPrice, InitialFilterData.maxPrice]);
  };

  const onShapeClick = (shape) => {
    setDisableBtn(false);

    var strShape = strShapes || "";
    if (!strShape.includes(shape)) {
      strShape += "," + shape;
    } else {
      strShape = strShape.replace(shape, "");
      strShape = strShape
        .split(",")
        .filter((x) => x.trim() !== "")
        .join(",");
    }
    setStrShapes(strShape);
  };

  const fnCutGradeList = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    var strPolish = strCutGrades || "";
    var arrPolish = strCutGrades.split(",") || [];
    var arrPolishList = strCutGrades.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrCutGrades(finalstr);
  };
  const fnColorList = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strColors || "";
    var arrPolish = strColors.split(",") || [];
    var arrPolishList = strColors.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrColors(finalstr);
  };

  const onClarityClick = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    var strPolish = strclaritys || "";
    var arrPolish = strclaritys.split(",") || [];
    var arrPolishList = strclaritys.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrClaritys(finalstr);
  };

  const fnPolishList = (Polishval) => {
    //Change Polish

    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    var strPolish = strPolishs || "";
    var arrPolish = strPolish.split(",") || [];
    var arrPolishList = strPolish.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrPolishs(finalstr);
  };

  const onSymmetryClick = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    var strPolish = strSymmetrys || "";
    var arrPolish = strSymmetrys.split(",") || [];
    var arrPolishList = strSymmetrys.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrSymmetrys(finalstr);
  };

  const onFluorescenceClick = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strFluorescences || "";
    var arrPolish = strFluorescences.split(",") || [];
    var arrPolishList = strFluorescences.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrFluorescences(finalstr);
  };

  const handleGetDiamondTypeList = async (Id) => {
    try {
      let inputData = {
        dealerId: Id,
      };
      setLoading(true);
      await AppsDiamondLinksService.GetDiamondTypeList(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData.result;
          if (message === "Success") {
            handleGetDiamondLinkInitialFilterSetting(
              Id,
              responseData[0].diamondTypeId
            );
            InitialFilterData.Type = responseData[0].diamondTypeId;
            setDisableBtn(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDiamondLinkInitialFilterSetting = async (Id, diamondId) => {
    var certificateList = [];
    try {
      let inputData = {
        dealerId: Id,
        diamondTypeId: diamondId,
      };
      setLoading(true);
      await AppsDiamondLinksService.GetDiamondLinkInitialFilterSetting(
        inputData
      )
        .then((response) => {
          setLoading(false);

          var message = response.data.message;
          var responseData = response.data.responseData.result;
          if (message === "Success") {
            setDepthMinVal(
              responseData.diamondLinkInitialFilterSettingDefaultValues.depthMin
            );
            setDepthMaxValue(
              responseData.diamondLinkInitialFilterSettingDefaultValues.depthMax
            );
            setTableMinValue(
              responseData.diamondLinkInitialFilterSettingDefaultValues.tableMin
            );
            setTableMaxValue(
              responseData.diamondLinkInitialFilterSettingDefaultValues.tableMax
            );
            var tempAvailableFilterData = InitialFilterData;

            var settingCer =
              responseData.diamondLinkInitialFilterSettingDefaultValues
                .settingsCertificates;
            var tempArray2 = [];
            if (responseData.certificate == null) {
              var bool = "false";
              for (let i = 0; i < settingCer.length; i++) {
                tempArray2.push(bool);
              }
            }
            const tempArray = responseData.certificate
              ? responseData.certificate.split("|")
              : tempArray2;
            if (responseData.diamondLinkInitialFilterSettingDefaultValues) {
              if (
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .settingsCertificates
              ) {
                const tempCertArray =
                  responseData.diamondLinkInitialFilterSettingDefaultValues
                    .settingsCertificates;
                const temp = tempCertArray.map((x) => {
                  return { ...x, selectedDealerID: 0 };
                });
                for (let i = 0; i < tempArray.length; i++) {
                  temp.filter((x, index) => i == index)[0].selectedDealerID =
                    tempArray[i];

                  certificateList = temp;
                }
              }
            }
            if (responseData.diamondLinkInitialFilterSettingDefaultValues) {
              tempAvailableFilterData.shapeList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsShape;
              tempAvailableFilterData.cutList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsCuts;
              tempAvailableFilterData.colorList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsColor;
              tempAvailableFilterData.clarityList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsClarity;
              tempAvailableFilterData.polishList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsPolish;
              tempAvailableFilterData.symmetryList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsSymmetry;
              tempAvailableFilterData.fluorescenceList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsFluorescence;
              tempAvailableFilterData.certificatesList = certificateList;
              tempAvailableFilterData.minCarat =
                responseData.diamondLinkInitialFilterSettingDefaultValues.caratMin;
              tempAvailableFilterData.maxCarat =
                responseData.diamondLinkInitialFilterSettingDefaultValues.caratMax;
              tempAvailableFilterData.minPrice =
                responseData.diamondLinkInitialFilterSettingDefaultValues.priceMin;
              tempAvailableFilterData.maxPrice =
                responseData.diamondLinkInitialFilterSettingDefaultValues.priceMax;

              tempAvailableFilterData.tableMin =
                responseData.tableMin == 0
                  ? responseData.diamondLinkInitialFilterSettingDefaultValues
                      .tableMin
                  : responseData.tableMin;
              tempAvailableFilterData.tableMax =
                responseData.tableMax == 0
                  ? responseData.diamondLinkInitialFilterSettingDefaultValues
                      .tableMax
                  : responseData.tableMax;
              tempAvailableFilterData.depthMin =
                responseData.depthMin == 0
                  ? responseData.diamondLinkInitialFilterSettingDefaultValues
                      .depthMin
                  : responseData.depthMin;
              tempAvailableFilterData.depthMax =
                responseData.depthMax == 0
                  ? responseData.diamondLinkInitialFilterSettingDefaultValues
                      .depthMax
                  : responseData.depthMax;
              var isSelectAll = false;

              if (
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsCertificates.filter(
                  (x) => x.isSelected == true
                ).length ===
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .settingsCertificates.length
              ) {
                isSelectAll = true;
              }
              setIsSelectAll(isSelectAll);
            }
            if (responseData.priceMin == 0 && responseData.priceMax == 0) {
              setPriceValue([
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .priceMin,
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .priceMax,
              ]);
            } else {
              setPriceValue([responseData.priceMin, responseData.priceMax]);
            }
            if (responseData.caratMin == 0 && responseData.caratMax == 0) {
              setCaratSlideValue([
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .caratMin,
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .caratMax,
              ]);
            } else {
              setCaratSlideValue([
                responseData.caratMin,
                responseData.caratMax,
              ]);
            }
            tempAvailableFilterData.Type = diamondId;

            if (responseData.cutGradeMin > 0) {
              setStrCutGrades(
                GetAllNumbersBetween(
                  responseData.cutGradeMin,
                  responseData.cutGradeMax
                ).join(",")
              );
            } else {
              setStrCutGrades("");
            }

            if (responseData.colorMin > 0) {
              setStrColors(
                GetAllNumbersBetween(
                  responseData.colorMin,
                  responseData.colorMax
                ).join(",")
              );
            } else {
              setStrColors("");
            }
            if (responseData.clarityMin > 0) {
              setStrClaritys(
                GetAllNumbersBetween(
                  responseData.clarityMin,
                  responseData.clarityMax
                ).join(",")
              );
            } else {
              setStrClaritys("");
            }
            if (responseData.polishMin > 0) {
              setStrPolishs(
                GetAllNumbersBetween(
                  responseData.polishMin,
                  responseData.polishMax
                ).join(",")
              );
            } else {
              setStrPolishs("");
            }
            if (responseData.symmetryMin > 0) {
              setStrSymmetrys(
                GetAllNumbersBetween(
                  responseData.symmetryMin,
                  responseData.symmetryMax
                ).join(",")
              );
            } else {
              setStrSymmetrys("");
            }

            if (responseData.fluorescenceMin > 0) {
              setStrFluorescences(
                GetAllNumbersBetween(
                  responseData.fluorescenceMin,
                  responseData.fluorescenceMax
                ).join(",")
              );
            } else {
              setStrFluorescences("");
            }

            setStrShapes(responseData.shapelist || "");
            setInitialFilterData(tempAvailableFilterData);
            setDisableBtn(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCertificateChange = (e, item) => {
    var tempInitialFilterData = InitialFilterData;
    tempInitialFilterData.certificatesList.filter(
      (x) => x.id == item.id
    )[0].selectedDealerID = e.target.checked;
    setDisableBtn(false);
    setInitialFilterData({ ...tempInitialFilterData });
  };
  const handleAllCertificate = (e) => {
    var tempInitialFilterData = InitialFilterData;
    tempInitialFilterData.certificatesList.forEach((element) => {
      element.selectedDealerID = e.target.checked;
      setIsSelectAll(e.target.checked);
    });
    setInitialFilterData({ ...tempInitialFilterData });
    setDisableBtn(false);
  };

  const handleSliderCaratValue = (e) => {
    setCaratSlideValue(e);
    setDisableBtn(false);
  };
  const handleSliderPriceValue = (e) => {
    setPriceValue(e);
    setDisableBtn(false);
  };

  // For textBox value Onchange price and carat
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstsetCaratSlideValue = (e, num) => {
    var regExp = /[a-zA-Z-!@#$%^&*)(]/g;
    var testString = e.target.value;

    if (regExp.test(testString)) {
      return false;
    }
    setDisableBtn(false);
    let newArr = [...caratSlideValue];

    if (Number(e.target.value) > InitialFilterData.maxCarat) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (e.target.value[0] == 0) {
          var a = e.target.value.replace("0", "");
        } else {
          var a = e.target.value;
        }

        newArr[0] = a;

        setCaratSlideValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (e.target.value[0] == 0) {
          var b = e.target.value.replace("0", "");
        } else {
          var b = e.target.value;
        }
        newArr[1] = b;
        setCaratSlideValue(newArr);
      }
    }
  };

  const firstSliderPriceValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...PriceValue];

    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      InitialFilterData.maxPrice
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(e.target.value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(e.target.value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    }
  };

  return (
    <div className="prodetail__section">
      <div className="form__fields">
        <div className="col-md-12">
          <div className="prod__maindiv app__preview p-0 border-0">
            <div className="row">
              {loading == false && (
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {" "}
                    {traslate("Set Initial Filter Settings")}{" "}
                  </h4>
                  <p>
                    {traslate(
                      "This will control default diamond filters when users initially visit your DiamondLink."
                    )}
                  </p>
                </div>
              )}
              <Spin
                spinning={loading}
                size={"large"}
                style={{ height: "100vh" }}
              >
                {loading == false && (
                  <div className="col-lg-12 col-md-12">
                    <div className="prodesc__div">
                      <div className="tabs__content">
                        <div className="diamond__details">
                          <div className="row">
                            <div className="col-md-10">
                              <div className="row text__block">
                                <div className="col-md-12">
                                  <label className="mb-2">
                                    {traslate("Shape")}
                                  </label>
                                </div>

                                <div className="diamond__imgs col-md-12">
                                  {InitialFilterData.shapeList &&
                                    InitialFilterData.shapeList.map(
                                      (item, i) => {
                                        return (
                                          <React.Fragment key={i}>
                                            <div className="img__block">
                                              {!item.isEnabled ? (
                                                <div
                                                  className={"imgselct__div"}
                                                >
                                                  <div
                                                    className={"any-disable"}
                                                  >
                                                    <img
                                                      src={item.shapeImage}
                                                      alt={"shape-Img"}
                                                    />
                                                    <span>
                                                      {traslate(item?.name)}
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className={"imgselct__div"}
                                                >
                                                  <div
                                                    className={
                                                      strShapes &&
                                                      strShapes.includes(
                                                        item.value
                                                      )
                                                        ? "selected"
                                                        : null
                                                    }
                                                    onClick={() =>
                                                      onShapeClick(item.value)
                                                    }
                                                  >
                                                    <img
                                                      src={item.shapeImage}
                                                      alt={"shape-Img"}
                                                    />
                                                    <span>
                                                      {traslate(item?.name)}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                </div>

                                <div className="col-lg-6 col-md-6 mb-4">
                                  <div className="sliderrange__maindiv">
                                    <label> {traslate("Carats")} </label>
                                    <div className="col-md-12 mt-3">
                                      <Slider
                                        tooltipVisible={false}
                                        range
                                        value={caratSlideValue}
                                        step={0.01}
                                        min={InitialFilterData.minCarat}
                                        max={InitialFilterData.maxCarat}
                                        onChange={(e) => {
                                          handleSliderCaratValue(e);
                                        }}
                                      />
                                      <div className="rangeSlider__values">
                                        <div className="input__block">
                                          <input
                                            type="text"
                                            value={caratSlideValue[0]}
                                            onChange={(e) => {
                                              firstsetCaratSlideValue(e, 0);
                                            }}
                                            onKeyPress={onKeyPressEvent1}
                                          />
                                        </div>
                                        <div className="input__block">
                                          <input
                                            type="text"
                                            value={caratSlideValue[1]}
                                            onChange={(e) => {
                                              firstsetCaratSlideValue(e, 1);
                                            }}
                                            onKeyPress={onKeyPressEvent1}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-4">
                                  <div className="sliderrange__maindiv">
                                    <label> {traslate("Price")} </label>
                                    <div className="col-md-12 mt-3">
                                      <Slider
                                        tooltipVisible={false}
                                        range
                                        value={PriceValue}
                                        min={InitialFilterData.minPrice}
                                        max={InitialFilterData.maxPrice}
                                        onChange={(e) => {
                                          handleSliderPriceValue(e);
                                        }}
                                      />
                                      <div className="rangeSlider__values">
                                        <div className="input__block avfilter">
                                          <input
                                            type="text"
                                            value={PriceValue[0].toLocaleString(
                                              "en-US"
                                            )}
                                            onChange={(e) => {
                                              firstSliderPriceValue(e, 0);
                                            }}
                                          />
                                          <span className="dollar__symbol">
                                            {currencySymbol}
                                          </span>
                                        </div>
                                        <div className="input__block avfilter">
                                          <input
                                            type="text"
                                            value={PriceValue[1].toLocaleString(
                                              "en-US"
                                            )}
                                            onChange={(e) => {
                                              firstSliderPriceValue(e, 1);
                                            }}
                                          />
                                          <span className="dollar__symbol">
                                            {currencySymbol}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="Diamond_Options col-md-12 mt-0">
                                  <label> {traslate("Cut")} </label>
                                  <div className="selection__block row">
                                    {InitialFilterData.cutList.map(
                                      (item, i) => {
                                        return !item.isEnabled ? (
                                          <React.Fragment>
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <div>
                                                <span>
                                                  {" "}
                                                  {traslate(item?.name)}{" "}
                                                </span>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className={
                                                strCutGrades.includes(
                                                  item.value
                                                )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                            >
                                              <div
                                                onClick={() =>
                                                  fnCutGradeList(item.value)
                                                }
                                              >
                                                <span>
                                                  {" "}
                                                  {traslate(item?.name)}{" "}
                                                </span>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className=" Diamond_Options col-md-12 mt-4">
                                  <label> {traslate("Color")} </label>
                                  <div className="selection__block row">
                                    {InitialFilterData.colorList.map(
                                      (item, i) => {
                                        return !item.isEnabled ? (
                                          <React.Fragment key={i}>
                                            <div
                                              className={
                                                "selection__box  col any-disable"
                                              }
                                            >
                                              <span>
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment key={i}>
                                            <div
                                              className={
                                                strColors.includes(item.value)
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                fnColorList(item.value)
                                              }
                                            >
                                              <span>
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className=" Diamond_Options col-md-12 mt-4">
                                  <label> {traslate("Clarity")} </label>
                                  <div className="selection__block row">
                                    {InitialFilterData.clarityList.map(
                                      (item, i) => {
                                        return !item.isEnabled ? (
                                          <React.Fragment>
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className={
                                                !item.isEnabled
                                                  ? "any-disable"
                                                  : strclaritys.includes(
                                                      item.value
                                                    )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                onClarityClick(item.value)
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className="Diamond_Options col-md-6 mt-4">
                                  <label> {traslate("Polish")} </label>
                                  <div className="selection__block row">
                                    {InitialFilterData.polishList.map(
                                      (item, i) => {
                                        return !item.isEnabled ? (
                                          <React.Fragment>
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className={
                                                strPolishs.includes(item.value)
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                fnPolishList(item.value)
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className=" Diamond_Options col-md-6 mt-4">
                                  <label> {traslate("Symmetry")} </label>
                                  <div className="selection__block row">
                                    {InitialFilterData.symmetryList.map(
                                      (item, i) => {
                                        return !item.isEnabled ? (
                                          <React.Fragment>
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <span>
                                                {traslate(item?.name)}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className={
                                                strSymmetrys.includes(
                                                  item.value
                                                )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                onSymmetryClick(item.value)
                                              }
                                            >
                                              <span>
                                                {traslate(item?.name)}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>

                                <div className=" Diamond_Options col-md-6 mt-4">
                                  <label> {traslate("Fluorescence")} </label>
                                  <div className="selection__block row">
                                    {InitialFilterData.fluorescenceList.map(
                                      (item, i) => {
                                        return !item.isEnabled ? (
                                          <React.Fragment>
                                            <div
                                              className={
                                                "any-disable  selection__box  col"
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div
                                              className={
                                                strFluorescences.includes(
                                                  item.value
                                                )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                onFluorescenceClick(item.value)
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {traslate(item?.name)}{" "}
                                              </span>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3 mt-4">
                                  <div className="input__block">
                                    <label> {traslate("Depth")} </label>
                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <div className="position-relative">
                                          <input
                                            type="number"
                                            name="depthMin"
                                            min={InitialFilterData.mainDepthMin}
                                            max={InitialFilterData.mainDepthMax}
                                            value={InitialFilterData.depthMin}
                                            onChange={(e) => {
                                              e.target.value =
                                                e.target.value.slice(0, 9);
                                              handleOnChange(e, "depthMin");
                                            }}
                                            onKeyPress={onKeyPressEvent}
                                            className={
                                              validation.depthMinVal &&
                                              "border__red"
                                            }
                                          />
                                          <span className="depth_ampcent__txt">
                                            %
                                          </span>
                                          {validation.depthMinVal && (
                                            <span className="error-color-red">
                                              {validation.depthMinVal}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-6">
                                        <div className="position-relative">
                                          <input
                                            type="number"
                                            name="depthMax"
                                            min={InitialFilterData.mainDepthMin}
                                            max={InitialFilterData.mainDepthMax}
                                            value={InitialFilterData.depthMax}
                                            onChange={(e) => {
                                              e.target.value =
                                                e.target.value.slice(0, 9);
                                              handleOnChange(e, "depthMax");
                                            }}
                                            onKeyPress={onKeyPressEvent}
                                            className={
                                              validation.depthMaxVal &&
                                              "border__red"
                                            }
                                          />
                                          <span className="depth_ampcent__txt">
                                            %
                                          </span>
                                          {validation.depthMaxVal && (
                                            <span className="error-color-red">
                                              {validation.depthMaxVal}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-3 mt-4">
                                  <div className="input__block">
                                    <label> {traslate("Table")} </label>
                                    <div className="row">
                                      <div className="col-md-6 col-6">
                                        <div className="position-relative">
                                          <input
                                            type="number"
                                            name="tableMin"
                                            min={InitialFilterData.mainTableMin}
                                            max={InitialFilterData.mainTableMax}
                                            value={InitialFilterData.tableMin}
                                            onChange={(e) => {
                                              e.target.value =
                                                e.target.value.slice(0, 9);
                                              handleOnChange(e, "tableMin");
                                            }}
                                            onKeyPress={onKeyPressEvent}
                                            className={
                                              validation.tableMinVal &&
                                              "border__red"
                                            }
                                          />
                                          <span className="depth_ampcent__txt">
                                            %
                                          </span>
                                          {validation.tableMinVal && (
                                            <span className="error-color-red">
                                              {validation.tableMinVal}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-6">
                                        <div className="position-relative">
                                          <input
                                            type="number"
                                            name="tableMax"
                                            min={InitialFilterData.mainTableMin}
                                            max={InitialFilterData.mainTableMax}
                                            value={InitialFilterData.tableMax}
                                            onChange={(e) => {
                                              e.target.value =
                                                e.target.value.slice(0, 9);
                                              handleOnChange(e, "tableMax");
                                            }}
                                            onKeyPress={onKeyPressEvent}
                                            className={
                                              validation.tableMaxVal &&
                                              "border__red"
                                            }
                                          />
                                          <span className="depth_ampcent__txt">
                                            %
                                          </span>
                                          {validation.tableMaxVal && (
                                            <span className="error-color-red">
                                              {validation.tableMaxVal}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mt-4">
                                  <div className="input__block row">
                                    <label> {traslate("Certificates")} </label>
                                    <div className="radio__block col-md-3 col-6">
                                      <input
                                        type="checkbox"
                                        name="All"
                                        id="All"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        checked={isSelectAll}
                                        onChange={(e) =>
                                          handleAllCertificate(e)
                                        }
                                      />
                                      <label className="mb-0" htmlFor="All">
                                        {" "}
                                        {traslate("All")}{" "}
                                      </label>
                                    </div>
                                    {InitialFilterData.certificatesList
                                      ? InitialFilterData.certificatesList.map(
                                          (item, i) => {
                                            return (
                                              <React.Fragment>
                                                <div className="radio__block col-md-3 col-6">
                                                  <input
                                                    id={i + item.value}
                                                    type="checkbox"
                                                    name="AGS"
                                                    checked={
                                                      item.selectedDealerID ==
                                                        "true" ||
                                                      item.selectedDealerID ==
                                                        true
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) =>
                                                      handleCertificateChange(
                                                        e,
                                                        item
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="mb-0"
                                                    htmlFor={i + item.value}
                                                  >
                                                    {item?.name}
                                                  </label>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                  <div className="col-lg-12 mt-4">
                                    <Button
                                      loading={submitLoading}
                                      onClick={
                                        handleSaveDiamondLinkInitialFilterSetting
                                      }
                                      className={
                                        disableBtn
                                          ? "disabled-btn "
                                          : "primary-btn "
                                      }
                                      disabled={disableBtn}
                                    >
                                      {" "}
                                      {/* {traslate("Update Settings")}{" "} */}
                                      {traslate("Update Filters")}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialFilterSettings;
