import React, { useState, useEffect, useRef } from "react";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import { Input, Select, Table } from "antd";

import traslate from "../../../i18n/translate";

const { Option } = Select;

export const ApiAccessToken = (props) => {
  const ref = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [btndisabled, setBtndisabled] = useState(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
    handleGetApiAccessTokenList(pageNo, pageSize);
  }, []);

  const handleGetApiAccessTokenList = (pageNo, size) => {
    let inputData = {
      pageNumber: pageNo,
      pageSize: size,
    };
    setLoading(true);
    AdminSettingsDataService.GetApiAccessTokenList(inputData)
      .then((response) => {
        var responseData = response.data.responseData;
        var message = response.data.message;
        if (message === "Success") {
          if (responseData) {
            setTotalCount(responseData[0].totalRecord);
          }
          setData(responseData);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSaveApiAccessToken = (Id) => {
    var selectedList = [];
    selectedList = data.filter((x) => x.dealerID === Id);
    setSubmitLoading(true);
    AdminSettingsDataService.SaveApiAccessToken(selectedList[0].apiAccessToken)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Added Successfully.");
          handleGetApiAccessTokenList(pageNo, pageSize);
          setBtndisabled(null);
        } else {
          NotificationManager.error("Not Added.");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleOnChange = (e, isSelect, Id, name) => {
    setBtndisabled(Id);
    if (isSelect === "select") {
      const checkData = data;
      if (
        checkData.filter((x) => x.dealerID === Id)[0].apiAccessToken === null
      ) {
        let ObjData = {
          dealerId: Id.toString(),
          apiType: e,
          createdBy: 0,
        };
        checkData.filter((x) => x.dealerID === Id)[0].apiAccessToken = ObjData;
      } else {
        checkData.filter((x) => x.dealerID === Id)[0].apiAccessToken.apiType =
          e;
      }
      setData([...checkData]);
    } else {
      setCursor(e.target.selectionStart);
      const checkData = data.filter((x) => x.dealerID === Id);
      if (checkData[0].apiAccessToken === null) {
        let ObjData = {
          dealerId: Id.toString(),
          accessToken: e.target.value,
          createdBy: 0,
        };
        data.filter((x) => x.dealerID === Id)[0].apiAccessToken = ObjData;
      } else {
        data.filter((x) => x.dealerID === Id)[0].apiAccessToken.accessToken =
          e.target.value;
      }
      setData(data);
    }
  };

  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetApiAccessTokenList(page, pageSize);
    }
  }
  function handlePageSizeChange(current, size) {
    if (current !== pageSize) {
      setPageSize(size);
      handleGetApiAccessTokenList(current, size);
    }
  }

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 class="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("API Access Token")}{" "}
            </h4>
          </div>
        </div>
        <div className="divForm form__fields border-0 bg-transparent p-0">
          <div className="table__scroll">
            <Table
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                onChange: handlePageNoChange,
                onShowSizeChange: handlePageSizeChange,
              }}
              scroll={{ y: 800 }}
              loading={loading}
              dataSource={data}
              columns={[
                {
                  title: traslate("Retailer ID"),
                  dataIndex: "dealerID",
                  key: "dealerID",
                  //fixed: "left",
                  width: 120,
                },
                {
                  title: traslate("Retailer Name"),
                  dataIndex: "dealerCompany",
                  key: "retailername",
                  //fixed: "left",
                },
                {
                  title: traslate("API Type"),
                  dataIndex: "apiAccessToken",
                  key: "apiAccessToken",

                  render: (data, item) => {
                    return (
                      <div className="input__block mb-0">
                        <Select
                          name="apiType"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(e) =>
                            handleOnChange(e, "select", item.dealerID)
                          }
                          className="border__grey"
                          value={
                            item.apiAccessToken?.apiType
                              ? item.apiAccessToken?.apiType
                              : ""
                          }
                        >
                          <Option className="" value={null}>
                            {traslate("Select")}
                          </Option>
                          <Option value="Diamond"> {"Diamond"} </Option>
                          <Option value="Setting"> {"Setting"} </Option>
                          <Option value="Jewelry"> {"Jewelry"} </Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: traslate("API Key"),
                  dataIndex: "accessToken",
                  key: "apiAccessToken",
                  render: (data, item) => {
                    return (
                      <Input.Password
                        ref={ref}
                        className="apikeytxt"
                        autoComplete="false"
                        value={
                          item?.apiAccessToken?.accessToken
                            ? item?.apiAccessToken?.accessToken
                            : ""
                        }
                        onChange={(e) =>
                          handleOnChange(e, "input", item.dealerID)
                        }
                      />
                    );
                  },
                },
                {
                  title: traslate("Update"),
                  dataIndex: "update",
                  key: "update",
                  render: (row, data) => {
                    return (
                      <button
                        className={
                          btndisabled == data.dealerID
                            ? "primary-btn"
                            : btndisabled == null
                            ? "disabled-btn"
                            : "disabled-btn"
                        }
                        onClick={() => handleSaveApiAccessToken(data.dealerID)}
                        loading={submitLoading}
                        disabled={btndisabled == data.dealerID ? false : true}
                      >
                        {traslate("Save")}
                      </button>
                    );
                  },
                  width: 120,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiAccessToken;
