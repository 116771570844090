import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Modal, Button, Spin } from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import { useLocation } from "react-router-dom";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";

import traslate from "../../i18n/translate";
import ManageBrandsService from "../../services/manage-brands.service";
import { NotificationManager } from "react-notifications";
import config from "../../helper/config";

const ManageBrands = () => {
	const [visible, setVisible] = useState(false);
	const [DeleteVisible, setDeleteVisible] = useState(false);
	const [dealerId, setDealerId] = useState();
	const [brandData, setBrandData] = useState([]);

	const [editBrandData, setEditBrandData] = useState({});
	const [selectedId, setSelectedId] = useState("");
	const [DesignerId, setDesignerId] = useState("");
	const [DesignerName, setDesignerName] = useState("");
	const [showLoader, setShowLoader] = useState(true);
	const loginDetials = useSelector((state) => state.loginReducer);
	const [isedit, setIsEdit] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [brandToggle, setBrandToggle] = useState(false);
	const [validations, setValidations] = useState({
		DesignerNameVal: "",
		EmailVal: "",
	});
	const location = useLocation();
	const data = [];

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					setDealerId(loginDetials.loginDetials.responseData.dealerId);

					if (location.state) {
						if (location.state.id) {
							getBrandDataHandler(location.state.id);
						}
					} else {
						getBrandDataHandler(
							loginDetials.loginDetials.responseData.dealerId
						);
					}
				}
			}
		}
	}, []);

	const handleOpenNewWindow = (url) => {
		if (url.includes("https")) {
			window.open(url, "_blank");
		} else if (url.includes("http")) {
			window.open(url, "_blank");
		} else {
			window.open("https://" + url, "_blank");
		}
	};
	const getBrandDataHandler = (id) => {
		let inputData = {
			dealerID: id.toString(),
		};
		setShowLoader(true);
		ManageBrandsService.GetBrandData(inputData).then((response) => {
			let message = response.data.message;
			let responseData = response.data.responseData;
			if (message === "Success") {
				responseData[""].map((elem, index) => {
					data.push({
						key: index,
						image: (
							<img
								className="brands-list-logo"
								src={elem.logoimg}
								width="50"
								alt=""
							/>
						),
						brandId: <span>{elem.DesignerId}</span>,
						brandName: <span>{elem.DesignerName}</span>,
						link: (
							<span
								className="linkText"
								onClick={() => {
									handleOpenNewWindow(elem.Url);
								}}>
								{elem.Url}
							</span>
						),
						action: (
							<div className="action__btns">
								<div className="image__block">
									<img
										src={Edit_icon}
										alt=""
										onClick={() => showEditBrand(elem.DesignerId)}
									/>
								</div>
								<div className="image__block">
									<img
										src={Delete_iconbl}
										alt=""
										onClick={() => {
											setDesignerId(elem.DesignerId);
											setDesignerName(elem.DesignerName);
											setDeleteVisible(true);
										}}
									/>
								</div>
							</div>
						),
					});
				});
				setBrandData(data);
				setShowLoader(false);
			} else {
				setBrandData([]);
				setShowLoader(false);
			}
		});
	};

	const showEditBrand = (id) => {
		setVisible(true);
		let inputData = {
			dealerID: loginDetials.loginDetials.responseData.dealerId.toString(),
			designerID: id.toString(),
		};
		setSelectedId(id);
		ManageBrandsService.LoadEditBrand(inputData).then((response) => {
			let message = response.data.message;
			let responseData = response.data.responseData;
			if (message === "Success") {
				setIsEdit(true);
				setEditBrandData((prevState) => ({
					...responseData,
					imgDesignerLogo: null,
					imgDesignerLogoLink: responseData.imgDesignerLogo,
					imgDesignerImage4: null,
					imgDesignerImage4Link: responseData.imgDesignerImage4,
					imgDesignerImage5: null,
					imgDesignerImage5Link: responseData.imgDesignerImage5,
					imgDesignerImage6: null,
					imgDesignerImage6Link: responseData.imgDesignerImage6,
					imgSmallBanner: null,
					imgSmallBannerLink: responseData.imgSmallBanner,
					showCaseLogo: responseData.showCaseLogo,
					showCaseImage: responseData.showCaseImage,
					showCaseText: responseData.showCaseText,
				}));
				if (
					responseData.radShowCaseImage1 == false &&
					responseData.radShowCaseImage2 == false &&
					responseData.radShowCaseImage3 == false
				) {
					setEditBrandData((prevdata) => ({
						...prevdata,
						radShowCaseImage1: true,
					}));
				}
			}
		});
	};
	const handleCancel = () => {
		setToggle(!toggle);
		setVisible(false);
		setBrandToggle(false);
		setIsEdit(false);
		setValidations({
			DesignerNameVal: "",
			EmailVal: "",
		});
	};
	const columns = [
		{
			title: "",
			dataIndex: "image",
			width: 50,
		},
		{
			title: traslate("Brand ID"),
			dataIndex: "brandId",
			width: 50,
		},
		{
			title: traslate("Brand Name"),
			dataIndex: "brandName",
			width: 100,
		},
		{
			title: traslate("Link"),
			dataIndex: "link",
			width: 300,
		},
		{
			title: traslate("Action"),
			dataIndex: "action",
			width: 100,
		},
	];
	const onEditChangeHandler = (e, name) => {
		let value = e.target.value;
		setEditBrandData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const onCheckBoxChangeHandler = (e, name) => {
		if (isedit) {
			if (name === "divLogo" && editBrandData.divLogo === true) {
				setEditBrandData((prevState) => ({
					...prevState,
					[name]: !editBrandData[name],
				}));
			}
		}
		if (
			name === "radShowCaseImage1" ||
			name === "radShowCaseImage2" ||
			name === "radShowCaseImage3"
		) {
			setEditBrandData((prevState) => ({
				...prevState,
				radShowCaseImage1: false,
				radShowCaseImage2: false,
				radShowCaseImage3: false,
			}));
			setEditBrandData((prevState) => ({
				...prevState,
				[name]: !editBrandData[name],
			}));
		} else {
			setEditBrandData((prevState) => ({
				...prevState,
				[name]: !editBrandData[name],
			}));
		}
	};
	const handleValidation = () => {
		const validations = {};
		let isValid = true;
		if (!editBrandData.txtdesignerName) {
			isValid = false;
			validations.DesignerNameVal = traslate("Designer name is compulsory");
		} else {
			validations.DesignerNameVal = "";
		}

		var validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!editBrandData.txtEmail) {
			isValid = false;
			validations.EmailVal = traslate("Email is compulsory");
		} else if (!editBrandData.txtEmail.match(validRegex)) {
			isValid = false;
			validations.EmailVal = "Email address is Invalid";
		} else {
			validations.EmailVal = "";
		}

		if (!isValid) {
			setValidations(validations);
		}
		return isValid;
	};

	const fileUpload = async (e, name) => {
		setEditBrandData((prevState) => ({
			...prevState,
			[name]: e.target.files[0],
		}));
	};

	const onDeleteImage = (name) => {
		if (name == "imgDesignerLogo") {
			setEditBrandData({
				...editBrandData,
				[name]: "",
				imgDesignerLogoLink: "",
			});
		} else if (name == "imgDesignerImage4") {
			setEditBrandData({
				...editBrandData,
				[name]: "",
				imgDesignerImage4Link: "",
			});
		} else if (name == "imgDesignerImage5") {
			setEditBrandData({
				...editBrandData,
				[name]: "",
				imgDesignerImage5Link: "",
			});
		} else if (name == "imgDesignerImage6") {
			setEditBrandData({
				...editBrandData,
				[name]: "",
				imgDesignerImage6Link: "",
			});
		} else if (name == "imgSmallBanner") {
			setEditBrandData({
				...editBrandData,
				[name]: "",
				imgSmallBannerLink: "",
			});
		}
	};
	/*Form Data Starts */
	const formData = new FormData();

	const onSubmitHandler = () => {
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		setBrandToggle(true);
		formData.append("txtdesignerName", editBrandData.txtdesignerName);
		formData.append("DesignerID", isedit ? selectedId.toString() : "0");
		formData.append("DealerID", dealerId.toString());
		formData.append("txtEmail", editBrandData.txtEmail);
		formData.append("txtUrl", editBrandData.txtUrl);
		formData.append("txtaboutDesigner", editBrandData.txtaboutDesigner);
		formData.append("txttagLine", editBrandData.txttagLine);
		formData.append("txtvideoUrl", editBrandData.txtvideoUrl);
		formData.append(
			"chkDesigner",
			editBrandData.chkDesigner ? editBrandData.chkDesigner : false
		);
		formData.append(
			"chkWatchBrand",
			editBrandData.chkWatchBrand ? editBrandData.chkWatchBrand : false
		);
		formData.append(
			"chkBridalDesigner",
			editBrandData.chkBridalDesigner ? editBrandData.chkBridalDesigner : false
		);
		formData.append(
			"radShowCaseImage1",
			editBrandData.radShowCaseImage1 ? editBrandData.radShowCaseImage1 : false
		);
		formData.append(
			"radShowCaseImage2",
			editBrandData.radShowCaseImage2 ? editBrandData.radShowCaseImage2 : false
		);
		formData.append(
			"radShowCaseImage3",
			editBrandData.radShowCaseImage3 ? editBrandData.radShowCaseImage3 : false
		);
		formData.append(
			"Fu_LogoImage",
			editBrandData.imgDesignerLogo ? editBrandData.imgDesignerLogo : null
		);
		formData.append(
			"Fu_LogoImage_flag",
			editBrandData.imgDesignerLogoLink
				? editBrandData.imgDesignerLogoLink.replace(
						`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
						""
				  )
				: null
		);
		formData.append(
			"Fu_ShowcaseImage1",
			editBrandData.imgDesignerImage4 ? editBrandData.imgDesignerImage4 : null
		);
		formData.append(
			"Fu_ShowcaseImage1_flag",
			editBrandData.imgDesignerImage4Link
				? editBrandData.imgDesignerImage4Link.replace(
						`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
						""
				  )
				: null
		);
		formData.append(
			"Fu_ShowcaseImage2",
			editBrandData.imgDesignerImage5 ? editBrandData.imgDesignerImage5 : null
		);
		formData.append(
			"Fu_ShowcaseImage2_flag",
			editBrandData.imgDesignerImage5Link
				? editBrandData.imgDesignerImage5Link.replace(
						`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
						""
				  )
				: null
		);
		formData.append(
			"Fu_ShowcaseImage3",
			editBrandData.imgDesignerImage6 ? editBrandData.imgDesignerImage6 : null
		);
		formData.append(
			"Fu_ShowcaseImage3_flag",
			editBrandData.imgDesignerImage6Link
				? editBrandData.imgDesignerImage6Link.replace(
						`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
						""
				  )
				: null
		);
		formData.append(
			"Fu_SmallImage",
			editBrandData.imgSmallBanner ? editBrandData.imgSmallBanner : null
		);
		formData.append(
			"Fu_SmallImage_flag",
			editBrandData.imgSmallBannerLink
				? editBrandData.imgSmallBannerLink.replace(
						`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
						""
				  )
				: null
		);
		formData.append("chkUseVendorProfile", editBrandData.divLogo);
		ManageBrandsService.SubmitEditBrandData(formData)
			.then((response) => {
				let message = response.data.message;
				if (message === "Success") {
					setVisible(false);
					NotificationManager.success("Brand Updated Successfully.");
					getBrandDataHandler(dealerId);
					setIsEdit(false);
				} else {
					NotificationManager.error(message);
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setBrandToggle(false);
				setValidations((prevdata) => ({
					...prevdata,
					DesignerNameVal: "",
					EmailVal: "",
				}));
			});
	};

	const onDeleteBrandData = (id) => {
		let inputData = {
			dealerID: dealerId.toString(),
			designerID: id.toString(),
		};
		ManageBrandsService.DeleteBrandData(inputData).then((response) => {
			let message = response.data.message;
			let responseData = response.data.responseData;
			if (message === "Success") {
				getBrandDataHandler(dealerId);
				setDeleteVisible(false);
				getBrandDataHandler(dealerId);
				NotificationManager.success("Brand has been deleted");
			} else {
				NotificationManager.error("Brand has not been deleted");
			}
		});
	};

	const emptyEditBrandData = () => {
		setVisible(true);
		setEditBrandData({
			txtdesignerName: "",
			hndDesigner_name: "",
			txtEmail: "",
			txtUrl: "",
			txtaboutDesigner: "",
			txttagLine: "",
			txtvideoUrl: "",
			chkDesigner: "",
			chkWatchBrand: "",
			chkBridalDesigner: "",
			radShowCaseImage1: "",
			radShowCaseImage2: "",
			radShowCaseImage3: "",
			radiobutton_image1: "",
			imgDesignerLogo: "",
			imgDesignerImage4: "",
			imgDesignerImage5: "",
			imgDesignerImage6: "",
			imgSmallBanner: "",
			showCaseImage: "",
			showCaseLogo: "",
			showCaseText: "",
			divLogo: false,
			divimage4: "",
			divimage5: "",
			divimage6: "",
			divimageSmallBanner: "",
			lbldesignerName: "",
			rightImg1: "",
			rightImg2: "",
			divrightimage2: "",
		});
	};

	return (
		<React.Fragment>
			<Spin spinning={showLoader}>
				<div className="form__fields">
					<div className="row magento__mapping permission__section">
						<div className="row">
							<div className="col-md-12">
								<div className="heading__block">
									<h4 class="workarea__heading mt-0 mb-0">
										{traslate("Manage Brand")}/{traslate("Designer")}
									</h4>
									<button className="primary-btn" onClick={emptyEditBrandData}>
										{" "}
										<img src={Create_icon} alt="" /> {traslate("Create Brand")}
									</button>
								</div>
							</div>
							<div className="col-md-9">
								<p>
									{traslate(
										"If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor)."
									)}
								</p>
							</div>
							<div className="col-lg-12">
								<div className="shipping_tbldiv">
									<div className="table__scroll">
										<Table
											columns={columns}
											dataSource={brandData}
											scroll={{ y: 800 }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Spin>
			<Modal
				title={isedit ? traslate("Update Brands") : traslate("Insert Brands")}
				className="modal__addcolor"
				centered
				key={toggle}
				visible={visible}
				onOk={() => setVisible(false)}
				onCancel={handleCancel}
				width={550}
				footer={[
					<Button key="back" onClick={handleCancel}>
						{traslate("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={brandToggle}
						onClick={onSubmitHandler}>
						{traslate("Submit")}
					</Button>,
				]}>
				<div className="modal__body">
					<div className="form__fields border-0 p-0">
						<div className="row">
							<div className="col-md-12">
								<div className="input__block">
									<label>
										{traslate("Designer Name")}{" "}
										<span className="text-red">*</span>{" "}
									</label>
									<input
										type="text"
										className={
											(editBrandData.txtdesignerName === undefined ||
												editBrandData.txtdesignerName === "") &&
											validations.DesignerNameVal
												? "border__red"
												: ""
										}
										value={editBrandData.txtdesignerName}
										onChange={(e) => {
											onEditChangeHandler(e, "txtdesignerName");
										}}
									/>
									{(editBrandData.txtdesignerName === undefined ||
										editBrandData.txtdesignerName === "") &&
									validations.DesignerNameVal ? (
										<div className="text-danger">
											{validations.DesignerNameVal}
										</div>
									) : null}
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block">
									<label> {traslate("Designer Logo")} </label>
									<div className="image__block image_block">
										<div className="divimage_block border-0 p-0">
											{editBrandData.imgDesignerLogo === undefined ||
											editBrandData.imgDesignerLogo === "" ? (
												<div className="">
													<label
														htmlFor="file-upload"
														class="primary-btn choose_file_upload">
														{traslate("Choose File")}
													</label>
													<input
														id="file-upload"
														type="file"
														onChange={(e) => fileUpload(e, "imgDesignerLogo")}
													/>

													<span class="mb-4">
														{" "}
														{traslate("No File Choosen")}{" "}
													</span>
													<span class=""> (Preferred size 500px x 500px)</span>
												</div>
											) : (
												<div class="img__showdiv manage-user-img-sp">
													{isedit ? (
														editBrandData.imgDesignerLogo ? (
															<img
																src={URL.createObjectURL(
																	editBrandData.imgDesignerLogo
																)}
																className="img-fluid"
															/>
														) : (
															<img
																src={
																	editBrandData.imgDesignerLogoLink ===
																	`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`
																		? `${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`
																		: editBrandData.imgDesignerLogoLink
																}
																className="img-fluid"
															/>
														)
													) : editBrandData.imgDesignerLogo ? (
														<img
															src={URL.createObjectURL(
																editBrandData.imgDesignerLogo
															)}
															className="img-fluid"
														/>
													) : null}
													{editBrandData.imgDesignerLogo ===
														`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/` && (
														<h6>No Image Selected</h6>
													)}
													<span
														className="close_image close-img-brands"
														onClick={() => onDeleteImage("imgDesignerLogo")}>
														x
													</span>
												</div>
											)}
										</div>
									</div>
									<div>
										{editBrandData.imgDesignerLogoLink ===
											`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/` &&
											editBrandData.divLogo && (
												<div class="text-danger">Please Select The Image</div>
											)}
									</div>
									<div className="radio__block mb-0">
										<input
											type="checkbox"
											checked={editBrandData.divLogo}
											onChange={(e) => onCheckBoxChangeHandler(e, "divLogo")}
										/>
										<label>{traslate("Use Vendor Profile Logo")}</label>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block">
									<label>{traslate("Navigation Category")}</label>
									<div className="row">
										<div className="col-md-12">
											<div className="radio__block mb-0">
												<input
													type="checkbox"
													id="chkDesigner"
													checked={editBrandData.chkDesigner}
													onChange={(e) =>
														onCheckBoxChangeHandler(e, "chkDesigner")
													}
												/>
												<label>{traslate("Fashion Designer")}</label>
											</div>
										</div>
										<div className="col-md-12">
											<div className="radio__block mb-0">
												<input
													type="checkbox"
													checked={editBrandData.chkWatchBrand}
													onChange={(e) =>
														onCheckBoxChangeHandler(e, "chkWatchBrand")
													}
												/>
												<label>{traslate("Watch Designer")}</label>
											</div>
										</div>
										<div className="col-md-12">
											<div className="radio__block mb-0">
												<input
													type="checkbox"
													checked={editBrandData.chkBridalDesigner}
													onChange={(e) =>
														onCheckBoxChangeHandler(e, "chkBridalDesigner")
													}
												/>
												<label>{traslate("Bridal Designer")}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block">
									<label>{traslate("Showcase Image 1")}</label>

									<div className="image__block image_block">
										<div className="divimage_block border-0 p-0">
											{editBrandData.imgDesignerImage4 === undefined ||
											editBrandData.imgDesignerImage4 === "" ? (
												<div className="">
													<label
														htmlFor="file-upload1"
														class="primary-btn choose_file_upload">
														{traslate("Choose File")}
													</label>
													<input
														id="file-upload1"
														type="file"
														onChange={(e) => fileUpload(e, "imgDesignerImage4")}
													/>
													<span class="mb-4">
														{" "}
														{traslate("No File Choosen")}{" "}
													</span>
													<span class=""> (Preferred size 200px x 200px)</span>
												</div>
											) : (
												<div class="img__showdiv manage-user-img-sp">
													{isedit ? (
														editBrandData.imgDesignerImage4 ? (
															<img
																src={URL.createObjectURL(
																	editBrandData.imgDesignerImage4
																)}
																className="img-fluid"
															/>
														) : (
															<img
																src={
																	editBrandData.imgDesignerImage4Link ===
																	`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
																		? `${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`
																		: editBrandData.imgDesignerImage4Link
																}
																className="img-fluid"
															/>
														)
													) : editBrandData.imgDesignerImage4 ? (
														<img
															src={URL.createObjectURL(
																editBrandData.imgDesignerImage4
															)}
															className="img-fluid"
															// alt="showcase1"
														/>
													) : null}
													{editBrandData.imgDesignerImage4Link === null &&
														editBrandData.imgDesignerImage4 === null && (
															<img
																src={`${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`}
																className="img-fluid"
															/>
														)}
													<span
														className="close_image close-img-brands"
														onClick={() => onDeleteImage("imgDesignerImage4")}>
														x
													</span>
												</div>
											)}
										</div>
									</div>
									<div className="radio__block">
										<input
											type="checkbox"
											checked={editBrandData.radShowCaseImage1}
											onChange={(e) =>
												onCheckBoxChangeHandler(e, "radShowCaseImage1")
											}
										/>
										<label>{traslate("Use In Showcase")}</label>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block">
									<label>{traslate("Showcase Image 2")}</label>
									<div class="image__block image_block">
										<div class="divimage_block border-0 p-0">
											{editBrandData.imgDesignerImage5 === undefined ||
											editBrandData.imgDesignerImage5 === "" ? (
												<div className="">
													<label
														htmlFor="file-upload3"
														class="primary-btn choose_file_upload">
														{traslate("Choose File")}
													</label>
													<input
														type="file"
														id="file-upload3"
														onChange={(e) => fileUpload(e, "imgDesignerImage5")}
													/>
													<span class="mb-4">
														{" "}
														{traslate("No File Choosen")}{" "}
													</span>
													<span class=""> (Preferred size 200px x 200px)</span>
												</div>
											) : (
												<div class="img__showdiv manage-user-img-sp">
													{isedit ? (
														editBrandData.imgDesignerImage5 ? (
															<img
																src={URL.createObjectURL(
																	editBrandData.imgDesignerImage5
																)}
																className="img-fluid"
															/>
														) : (
															<img
																src={
																	editBrandData.imgDesignerImage5Link ===
																	`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
																		? `${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`
																		: editBrandData.imgDesignerImage5Link
																}
																className="img-fluid"
															/>
														)
													) : editBrandData.imgDesignerImage5 ? (
														<img
															src={URL.createObjectURL(
																editBrandData.imgDesignerImage5
															)}
															className="img-fluid"
														/>
													) : null}
													{editBrandData.imgDesignerImage5Link === null &&
														editBrandData.imgDesignerImage5 === null && (
															<img
																src={`${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`}
																className="img-fluid"
															/>
														)}
													<span
														className="close_image close-img-brands"
														onClick={() => onDeleteImage("imgDesignerImage5")}>
														x
													</span>
												</div>
											)}
										</div>
									</div>
									<div className="radio__block">
										<input
											type="checkbox"
											checked={editBrandData.radShowCaseImage2}
											onChange={(e) =>
												onCheckBoxChangeHandler(e, "radShowCaseImage2")
											}
										/>
										<label>{traslate("Use In Showcase")}</label>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block">
									<label>{traslate("Showcase Image 3")}</label>
									<div class="image__block image_block">
										<div class="divimage_block border-0 p-0">
											{editBrandData.imgDesignerImage6 === undefined ||
											editBrandData.imgDesignerImage6 === "" ? (
												<div className="">
													<label
														htmlFor="file-upload4"
														class="primary-btn choose_file_upload">
														{traslate("Choose File")}
													</label>
													<input
														type="file"
														id="file-upload4"
														onChange={(e) => fileUpload(e, "imgDesignerImage6")}
													/>
													<span class="mb-4">
														{" "}
														{traslate("No File Choosen")}{" "}
													</span>
													<span class=""> (Preferred size 200px x 200px)</span>
												</div>
											) : (
												<div class="img__showdiv manage-user-img-sp">
													{isedit ? (
														editBrandData.imgDesignerImage6 ? (
															<img
																src={URL.createObjectURL(
																	editBrandData.imgDesignerImage6
																)}
																className="img-fluid"
															/>
														) : (
															<img
																src={
																	editBrandData.imgDesignerImage6Link ===
																	`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
																		? `${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`
																		: editBrandData.imgDesignerImage6Link
																}
																className="img-fluid"
															/>
														)
													) : editBrandData.imgDesignerImage6 ? (
														<img
															src={URL.createObjectURL(
																editBrandData.imgDesignerImage6
															)}
															className="img-fluid"
														/>
													) : null}
													{editBrandData.imgDesignerImage6Link === null &&
														editBrandData.imgDesignerImage6 === null && (
															<img
																src={`${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`}
																className="img-fluid"
																// alt="showcase1"
															/>
														)}

													<span
														className="close_image close-img-brands"
														onClick={() => onDeleteImage("imgDesignerImage6")}>
														x
													</span>
												</div>
											)}
										</div>
									</div>
									<div className="radio__block">
										<input
											type="checkbox"
											checked={editBrandData.radShowCaseImage3}
											onChange={(e) =>
												onCheckBoxChangeHandler(e, "radShowCaseImage3")
											}
										/>
										<label>{traslate("Use In Showcase")}</label>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block">
									<label>{traslate("Designer Small Banner")}</label>
									<div class="image__block image_block">
										<div class="divimage_block border-0 p-0">
											{editBrandData.imgSmallBanner === undefined ||
											editBrandData.imgSmallBanner === "" ? (
												<div className="">
													<label
														htmlFor="file-upload5"
														class="primary-btn choose_file_upload">
														{traslate("Choose File")}
													</label>
													<input
														type="file"
														id="file-upload5"
														onChange={(e) => fileUpload(e, "imgSmallBanner")}
													/>
													<span class="mb-4">
														{" "}
														{traslate("No File Choosen")}{" "}
													</span>
													<span class=""> (Preferred size 271px x 271px)</span>
												</div>
											) : (
												<div class="img__showdiv manage-user-img-sp">
													{isedit ? (
														editBrandData.imgSmallBanner ? (
															<img
																src={URL.createObjectURL(
																	editBrandData.imgSmallBanner
																)}
																className="img-fluid"
															/>
														) : (
															<img
																src={
																	editBrandData.imgSmallBannerLink ===
																	`${config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
																		? `${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`
																		: editBrandData.imgSmallBannerLink
																}
																className="img-fluid"
															/>
														)
													) : editBrandData.imgSmallBanner ? (
														<img
															src={URL.createObjectURL(
																editBrandData.imgSmallBanner
															)}
															className="img-fluid"
														/>
													) : null}
													{editBrandData.imgSmallBannerLink === null &&
														editBrandData.imgSmallBanner === null && (
															<img
																src={`${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`}
																className="img-fluid"
															/>
														)}
													<span
														className="close_image close-img-brands"
														onClick={() => onDeleteImage("imgSmallBanner")}>
														x
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="input__block">
									<label>{traslate("Video URL")}</label>
									<input
										type="text"
										value={editBrandData.txtvideoUrl}
										onChange={(e) => onEditChangeHandler(e, "txtvideoUrl")}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="input__block">
									<label>{traslate("URL")}</label>
									<input
										type="text"
										value={editBrandData.txtUrl}
										onChange={(e) => onEditChangeHandler(e, "txtUrl")}
									/>
								</div>
							</div>

							<div className="col-md-12">
								<div className="input__block">
									<label>
										{traslate("Email")} <span className="text-red">*</span>{" "}
									</label>
									<input
										type="text"
										className={validations.EmailVal ? "border__red" : ""}
										value={editBrandData.txtEmail}
										onChange={(e) => onEditChangeHandler(e, "txtEmail")}
									/>
									{validations.EmailVal ? (
										<div className="text-danger">{validations.EmailVal}</div>
									) : null}
								</div>
							</div>
							<div className="col-md-12">
								<div className="input__block">
									<label>{traslate("About Designer")} </label>
									<textarea
										value={editBrandData.txtaboutDesigner}
										onChange={(e) =>
											onEditChangeHandler(e, "txtaboutDesigner")
										}></textarea>
								</div>
							</div>

							{isedit ? (
								<div className="col-md-12">
									<h5>{traslate("Sample Showcase Preview")}</h5>
									<div className="showcase-review-box">
										<div className="row">
											<div className="col-md-6">
												<img
													src={
														editBrandData.showCaseLogo
															? editBrandData.showCaseLogo
															: null
													}
													alt="NO IMAGE AVAILABLE"
												/>
											</div>
											<div className="col-md-6">
												<img
													src={
														editBrandData.showCaseImage
															? editBrandData.showCaseImage
															: null
													}
													alt="NO IMAGE AVAILABLE"
												/>
											</div>
											<div className="col-md-12"></div>
										</div>
									</div>
								</div>
							) : (
								<div>
									<div className="col-md-12">
										<h5>{traslate("Sample Showcase Preview")}</h5>
										<div className="showcase-review-box">
											<div className="row">
												<div className="col-md-6">
													<img
														src={`${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`}
														alt="NO IMAGE AVAILABLE"
													/>
												</div>
												<div className="col-md-6">
													<img
														src={`${config.BlobURL}gemfind2staging/CollectionImages/No-Image.png`}
														alt="NO IMAGE AVAILABLE"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				className="modal__addcolor"
				centered
				visible={DeleteVisible}
				onOk={() => setDeleteVisible(false)}
				onCancel={() => setDeleteVisible(false)}
				width={400}
				footer={[
					<Button key="back" onClick={() => setDeleteVisible(false)}>
						{traslate("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							onDeleteBrandData(DesignerId);
						}}>
						{traslate("Delete")}
					</Button>,
				]}>
				<div className="col-lg-12">
					<div className="modal__body">
						<div className="col-lg-12">
							<div className="form__fields border-0 p-0">
								<div className="row">
									<div className="col-lg-12 text-center">
										<p className="subheading">
											{traslate("Delete The ")}"{DesignerName}"{" "}
											{traslate("Brand")}
										</p>
										<p>{traslate("THIS CANNOT BE UNDONE")}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default ManageBrands;
